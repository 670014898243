// мы передаём сюда ID группы 
import React from 'react';
import { AvatarRect } from 'src/ver2/containers/lowlavel/avatarRect'
import { withCapoeiraSportState } from 'src/ver2/context';
import { Link } from 'react-router-dom';

import GroupAvatar from './avatar';

const GroupRect = (props) => {
    const { width, GroupId, client, groupsLoaded, linkTo = 'default' } = props;

    let text = '';
    if (!groupsLoaded) {
        text = 'Загрузка'
    } else {
        const group = client.getGroupById(GroupId);
        if (group) {
            text = group.Name;
        } else {
            text = 'Нет группы'
        }
    }

    if (linkTo && GroupId)
        return <Link to={linkTo === 'default' ? `/group/${GroupId}` : linkTo}>
            <AvatarRect
                text={text}
                width={width}
                AvatarComponent={GroupAvatar}
                avatarComponentProps={{ groupId: GroupId }}
            />
        </Link>

    return <AvatarRect
        text={text}
        width={width}
        AvatarComponent={GroupAvatar}
        avatarComponentProps={{ groupId: GroupId }}
    />

}

export default React.memo(withCapoeiraSportState('client, groupsLoaded')(GroupRect));