// это прямоугольная штука, в которой посередине аватарка, а снизу какой то текст
// в одну или две строчки
import React from 'react';
import './style.css';

/**
 * 
 * AvatarComponent - что то, что принмает в себя size
 */
export const AvatarRect = ({ text, width, AvatarComponent, avatarComponentProps, selected, upperComponent = null }) => {

    const size = width * 0.7;

    return <div style={{ width, height: width * 1.2 }} className={'rectAvatar' + (selected ? ' rectAvatarSelected' : '')}>
        {upperComponent ? <div style={{ fontSize: width / 7, lineHeight: `${width / 8}px` }} className='rectAvatar_upper_absolute'>
            {upperComponent}
        </div> : null}
        <div className='rectAvatar_upper'>
            <AvatarComponent size={size} {...avatarComponentProps} />
        </div>
        <div className='rectAvatar_lower'>
            <div className='rectAvatar_lower_text' style={{ fontSize: width / 8, lineHeight: `${width / 8}px` }}>
                {text}
            </div>
        </div>
    </div>
}
