import React from 'react';

export default () => <svg width="24.25mm" height="24.25mm"
    viewBox="0 0 2425 2425" >
    <g id="Слой_x0020_1">
        <path class="ui_icon__fill" d="M64213 185544c-37,-4 -74,-6 -112,-6 -514,0 -931,416 -931,930 0,514 417,931 931,931 514,0 930,-417 930,-931 0,-122 -23,-238 -66,-345l-864 345 112 -924z" />
        <path class="ui_icon__fill" d="M64325 185398l-113 924 865 -345c-124,-310 -410,-537 -752,-579z" />
        <path class="ui_icon__fill" d="M637 1333c21,136 43,157 108,262 7,12 4,28 -3,54 -13,47 -84,63 -137,80 -187,61 -330,113 -333,343l-2 40 1213 -1c0,-91 51,-249 -251,-351l-56 -20c-24,-9 -102,-31 -114,-42 -26,-1 -47,-30 -53,-52 -4,-12 -8,-34 -4,-45 29,-75 70,-118 91,-215 3,-15 6,-26 9,-40 2,-12 3,-31 6,-41 0,-25 3,-43 3,-67 -2,-341 -546,-358 -477,95z" />
        <polygon class="ui_icon__fill" points="1495,984 1328,595 969,595 1312,1394 1671,1394 2155,313 1796,313 " />
    </g>
</svg>