import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import TrefaText from 'src/ver2/components/trefaText';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import MoneyComponent from 'src/ver2/components/money';
import ScoreComponent from 'src/ver2/components/scores';

import { groupBy } from 'lodash';

import { Link } from 'react-router-dom';

import './style.css';
import Loader from 'src/ver2/components/loader';

//getEventById

/**
 *  Это модальник с квестом, сюда приходит весь Quest
 *  и UserQuest, если он взят
 * 
 */

class FullQuest extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }
    state = {
        Tasks: []
    }

    componentDidMount = async () => {

        const { Quest, UserQuest, client, questProgress } = this.props;
        if (!UserQuest) {

            const Tasks = await client.getQuestTasks(Quest.Id);
            if (Tasks.tasks) {
                this.setState({ Tasks: Tasks.tasks })
            }

            return;
        }

        if (questProgress && questProgress.tasks) {
            this.setState({ Tasks: questProgress.tasks })
        }

    }

    render() {

        const { Quest, onAddClick, onRewardClick, onCancelClick, UserQuest = null, questProgress, mayITakeQuest } = this.props;
        const { Tasks } = this.state;

        console.log ( 'UserQuest', UserQuest );
        let RoundId = 0;
        if (UserQuest && UserQuest.Settings) {
            RoundId = JSON.parse(UserQuest.Settings).RoundId;
        }

        const {
            Title, Discription
        } = Quest;

        let imgSrc = "/../images/ver2/quest.png";
        let cantGet = null;
        if (!UserQuest && (!mayITakeQuest || !mayITakeQuest.mayITakeQuest)) {
            imgSrc = "/../images/ver2/quest_dashed.png";
            if (mayITakeQuest && mayITakeQuest.reason && mayITakeQuest.reason.Text)
            cantGet = <>
                <h1>НЕ МОЖЕТЕ ВЗЯТЬ:</h1>
                <div className='quest_full_tasks'>
                    {mayITakeQuest.reason.Text}
                </div>
            </>
        }
        let roundComponent = null;
        if (UserQuest && (RoundId)) {
            roundComponent = <>
                <h1>СВЯЗКА:</h1>
                <div className='quest_full_tasks'>
                    <a href = {`https://capoeirasport.com/round/${RoundId}`}>ПЕРЕЙТИ К СВЯЗКЕ</a>
                </div>
            </>
        }

        return <div className='quest_full'>
            <div className='quest_full_header'>
                <img alt='CapoeiraSport' src={imgSrc} />
                <span>{Title}</span>
            </div>
            <h1>ОПИСАНИЕ</h1>
            <div className='quest_full_discription'>
                <TrefaText trefaText={Discription} />
            </div>
            {cantGet}
            <h1>ЗАДАЧИ</h1>
            <div className='quest_full_tasks'>
                {Tasks.map(({ text, isDone }) => <span key={`qfts-${text}`}>{text} {isDone ? <img title='готово' src="/../images/ver2/quest_done.png" /> : null} </span>)}
            </div>
            {roundComponent}
            <h1>НАГРАДА</h1>
            <div className='quest_full_footer'>
                <div className='quest_full_footer_reward'>
                    <this.Reward />
                </div>
                {(!UserQuest && mayITakeQuest && mayITakeQuest.mayITakeQuest) && <ButtonPlashka onClick={onAddClick} upperStyle={{ width: 150, height: 42 }} text={'ВЗЯТЬ ЗАДАНИЕ'} />}
                {(UserQuest && questProgress && questProgress.allDone) && <ButtonPlashka onClick={onRewardClick} upperStyle={{ width: 150, height: 42 }} text={'ПОЛУЧИТЬ'} />}
                {(UserQuest && questProgress && !questProgress.allDone) && <ButtonPlashka onClick={onCancelClick} upperStyle={{ width: 150, height: 42 }} text={'ОТКАЗАТЬСЯ'} />}
            </div>
        </div>
    }

    Reward = () => {

        const { Quest } = this.props;

        const {
            Settings
        } = Quest;

        let Answer = [];

        const SettingsObj = JSON.parse(Settings);

        if (SettingsObj && SettingsObj.scores) {
            const moneyKeys = Object.keys(SettingsObj.scores)
            Answer.push(moneyKeys.map((scoreKey) => {
                return <ScoreComponent size={55} type={scoreKey} amount={SettingsObj.scores[scoreKey]} />
            })
            )
        }

        if (SettingsObj && SettingsObj.money) {
            const moneyKeys = Object.keys(SettingsObj.money)
            Answer.push(moneyKeys.map((moneyKey) => {
                return <MoneyComponent size={55} type={moneyKey} amount={SettingsObj.money[moneyKey]} />
            })
            )
        }

        return Answer
    }

}

export default withCapoeiraSportState(
    `
        client
    `
)(FullQuest);