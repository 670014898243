import React, { PureComponent } from 'react';
import { withPersonState } from 'src/ver2/pages/person/context';
import Loader from 'src/ver2/components/loader';
import GroupAvatar from 'src/ver2/components/group/avatar';

import Shine3D from 'src/ver2/components/3d/shine';

import { Link } from 'react-router-dom';
import { Progress, Tooltip } from 'antd';
import OperationsModal from './modal';

import MONEY from 'src/ver2/client/money';

import './style.css';

class PersonDataPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        operationModalData: null,
        lastHeight: -1
    }

    closeOperationModal = () => this.setState({ operationModalData: null })
    openOperationModal = (scores) => {
        this.setState({ operationModalData: scores })
    }

    setComponentSelfHeight = ({ height }) => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        const { lastHeight } = this.state;

        if (height === this.state.lastHeight) return;
        this.setState({ lastHeight: height })
        setComponentSelfHeight({ plashkaId, height });

    };

    render() {

        const { Person,

            client,

            PortalRoles, InstructorRoles, Scores, Money, KubokRaiting,

            isInstructorRolesReady,
            isScoresReady,
            isKubokRaitingReady,
            isPortalRolesReady,

            iAmPerson
        } = this.props;

        const { closeOperationModal, openOperationModal } = this;
        const { operationModalData } = this.state;
        if (!isScoresReady || !isKubokRaitingReady || !isPortalRolesReady || !isInstructorRolesReady) return <div className='person_data_plashka global-slide-from-right-loading'>
            <Loader text='Загрузка данных' />
        </div>

        const {
            Judge, Rank, GroupId, Id
        } = Person;

        let AllHeight = 0;

        const DeviderHeight = 10;
        const BlockHeight = 60;
        const BlockKubokHeight = 140;
        const PaddingsHeight = 20;


        let Answer = [];

        if (PortalRoles && PortalRoles.length > 0) {
            Answer.push(<this.OneDevider text='администрация' />)
            Answer.push(<this.OnePlashka type='portal' value={PortalRoles[0].Role} />)
            AllHeight += DeviderHeight + BlockHeight;
        }

        let wasSocial = false;

        if (GroupId) {
            Answer.push(<this.OneDevider text='социальный' />)
            wasSocial = true;
            Answer.push(<this.OnePlashka type='group' value={GroupId} />)
            AllHeight += DeviderHeight + BlockHeight;
        }

        if (InstructorRoles && InstructorRoles.length > 0) {
            if (!wasSocial) {
                Answer.push(<this.OneDevider text='социальный' />)
                AllHeight += DeviderHeight;
            }

            for (let i = 0; i < InstructorRoles.length; i++) {
                Answer.push(<this.OnePlashka text='инструктор' type='group' value={InstructorRoles[i].SubjectId} />)
                AllHeight += BlockHeight;
            }

        }

        if ((Rank || Judge)) {
            Answer.push(<this.OneDevider text='спортивный' />)
            AllHeight += DeviderHeight;

            if (Rank) {
                Answer.push(<this.OnePlashka type='sport' value={Rank} />)
                AllHeight += BlockHeight;
            }
            if (Judge) {
                Answer.push(<this.OnePlashka type='judge' value={Judge} />)
                AllHeight += BlockHeight;
            }
        }

        // const Kuboks = [
        //     {
        //         type: 'kubok',
        //         value: {
        //             season: '2019-2021',
        //             place: 1,
        //             raiting: 'comp_pro'
        //         }
        //     },
        //     {
        //         type: 'kubok',
        //         value: {
        //             season: '2019-2021',
        //             place: 2,
        //             raiting: 'comp_pro'
        //         }
        //     }
        // ]

        if ((KubokRaiting && KubokRaiting.length)) {
            Answer.push(<this.OneDevider text='кубки' />)
            AllHeight += DeviderHeight;

            for (let i = 0; i < KubokRaiting.length; i++) {
                const { type, value } = KubokRaiting[i];
                Answer.push(<this.OnePlashka {...{ type, value }} />)
                AllHeight += BlockKubokHeight;
            }

        }

        if (Scores && Object.keys(Scores).length > 0) {
            Answer.push(<this.OneDevider text='статусы' />)
            AllHeight += DeviderHeight;

            const scoresKeys = Object.keys(Scores);
            const padding = 20;

            const style = {
                width: '100%',
                paddingLeft: padding,
                paddingRight: padding,
                paddingTop: 5,
                paddingBottom: 5
            }

            // const oneWidth = ((this.props.upperStyle.width - padding * 2) / 4) - 6;
            const oneWidth = 50;

            const Inside = <div className='person_data_status_container' style={style}>
                {scoresKeys.map((type) => {
                    const score = Scores[type];
                    const lvls = client.scoresLvlByType({ type, score });
                    return <ScoresStatus
                        type={type}
                        width={oneWidth}
                        showInfoModal={this.props.showInfoModal}
                        score={score}
                        openOperationModal={openOperationModal}
                        {...lvls} />
                })}
            </div>

            Answer.push(Inside)
            AllHeight += BlockHeight;
        }
        if (iAmPerson)
            if (Money && Object.keys(Money).length > 0) {
                Answer.push(<this.OneDevider text='ресурсы' />)
                AllHeight += DeviderHeight;

                const moneyKeys = Object.keys(Money);
                const padding = 20;

                const style = {
                    width: '100%',
                    paddingLeft: padding,
                    paddingRight: padding,
                    paddingTop: 5,
                    paddingBottom: 5
                }

                const oneWidth = 50;

                const Inside = <div className='person_data_status_container' style={style}>
                    {moneyKeys.map((type) => {
                        const amount = Money[type].all;

                        if (MONEY[type] && MONEY[type].dontMainShow) return null;

                        if (amount > 0)
                            return <MoneyStatus
                                type={type}
                                width={oneWidth}
                                showInfoModal={this.props.showInfoModal}
                                amount={amount}
                                openOperationModal={openOperationModal}
                            />
                        return null;
                    })}
                </div>

                Answer.push(Inside)
                AllHeight += BlockHeight;
            }


        if (AllHeight === 0) {
            this.setComponentSelfHeight({ height: 0 });
            return null;
        }

        AllHeight += PaddingsHeight;

        this.setComponentSelfHeight({ height: AllHeight });

        const { width, height } = this.props.upperStyle;

        return <div style={{ width, height }} className='person_data_plashka global-slide-from-right'>
            {Answer}
            {operationModalData ? <OperationsModal {...{ Person, operationModalData, closeOperationModal }} /> : null}
        </div>
    }

    OnePlashka = ({ type, value, text }) => {
        let data = {
            title: '',
            text: '',
            imgUrl: '',
            imgComponent: null
        }

        let mainClassName = 'person_data_plasha_oneplashka';

        if (type === 'sport') {
            data.imgUrl = `/../images/ver2/cats/${value}.png`;
            data.title = 'Спортивный разряд';
            data.text = this.props.client.sportRanks[value];
            mainClassName += ' person_data_plasha_oneplashka_clicked';
        }
        if (type === 'judge') {
            data.imgUrl = `/../images/ver2/cats/${value}.png`;
            data.title = 'Судейская категория';
            data.text = this.props.client.judgeRanks[value];
            mainClassName += ' person_data_plasha_oneplashka_clicked';
        }
        if (type === 'group') {
            data.imgComponent = <GroupAvatar size={50} groupId={value} />

            const { client } = this.props;
            const group = client.getGroupById(value);

            data.title = 'Группа';

            if (group) {
                data.title += ' ' + group.Name;
            }

            data.text = text || 'Создатель группы'
        }
        if (type === 'portal') {
            data.imgUrl = `/../images/ver2/portal_icons/${value}.png`;
            data.title = 'ПОРТАЛ';
            data.text = this.props.client.portalRoles[value];
            mainClassName += ' person_data_plasha_oneplashka_clicked';
        }
        if (type === 'kubok') {
            data.imgComponent = <div className={`person_data_kubok_${value.place}`}>
                <div className='person_data_kubok_img'>
                    <img src={`/../images/ver2/kubok/${value.season}_${value.place}_comp_pro.png`} />
                </div>
                <div className='person_data_kubok_shine'>
                    {/* <Shine3D drawWidth={100} drawHeight={100} Id={777 + '_' + value.season + value.place} Place={value.place} /> */}
                </div>
            </div>

            data.title = 'Сезон ' + value.season;

            data.text = value.place + ' место в рейтинге';
            mainClassName += ' person_data_plasha_oneplashka_kubok';
        }

        if (!data.title) return;

        return <div
            onClick={() => {
                if (type === 'judge') {
                    this.props.showInfoModal({ type: 'judge', value });
                }
                if (type === 'sport') {
                    this.props.showInfoModal({ type: 'sport', value });
                }
                if (type === 'portal') {
                    this.props.showInfoModal({ type: 'portal', value });
                }
            }
            }
            className={mainClassName}>

            {
                type === 'group' ?

                    <Link to={'/group/' + value}>
                        <div className='person_data_plasha_oneplashka_left'>
                            {data.imgComponent || <img src={data.imgUrl} />}
                        </div>
                    </Link>
                    :
                    <div className='person_data_plasha_oneplashka_left'>
                        {data.imgComponent || <img src={data.imgUrl} />}
                    </div>
            }
            <div className='person_data_plasha_oneplashka_right'>
                <div className='person_data_plasha_oneplashka_right_upper'>
                    {data.title}
                </div>
                <div className='person_data_plasha_oneplashka_right_downed'>
                    {data.text}
                </div>
            </div>
        </div>

    }

    OneDevider = ({ text }) => {
        return <div className='person_data_plasha_devider'>
            <div className='person_data_plasha_devider_line' />
            <div className='person_data_plasha_devider_text' > {text}</div>
            <div className='person_data_plasha_devider_line' />
        </div>
    }


}


const ScoresStatus = ({ type, width, lvl, now, needed, title, showInfoModal, score, openOperationModal }) => {

    if (!lvl || lvl === 0) return null;
    const progress = now / needed;

    const onClick = () => {
        showInfoModal({ type: 'scores', value: { type, score } });
    }

    const onOperationsClick = () => {
        openOperationModal(type);
    }

    return <div className='person_data_scoresstatus' style={{ width, height: width }}>
        <Tooltip title={title} placement='top'>
            <img onClick={onClick} src={`/../images/ver2/scores/${type}_${lvl}.png`} />
        </Tooltip>
        <Tooltip title={`${now} / ${needed} очков до уровня ${lvl + 1}`} placement='top'>
            <Progress
                onClick={onOperationsClick}
                strokeColor={{
                    '100%': '#0000ff',
                }}
                strokeLinecap="square"
                type="circle"
                percent={progress * 100}
                width={width * 0.34}
                format={() => lvl} />
        </Tooltip>
    </div>

}

const MoneyStatus = ({ type, width, amount, showInfoModal, openOperationModal }) => {


    const onClick = () => {
        showInfoModal({ type: 'money', value: { type } });
    }

    const onOperationsClick = () => {
        openOperationModal(type);
    }

    return <div className='person_data_moneystatus' style={{ width, height: width }}>
        <img onClick={onClick} src={`/../images/ver2/scores/money/${type}.png`} />
        <div onClick={onOperationsClick} className='person_data_moneystatus_amount'>{amount}</div>
    </div>

}

export default withPersonState(`

    Person,

    client,
    showInfoModal,

    Scores,
    Money,
    PortalRoles,
    KubokRaiting,
    InstructorRoles,    

    isInstructorRolesReady,
    isScoresReady,
    isKubokRaitingReady,
    isPortalRolesReady,

    iAmPerson
`
)(PersonDataPlashka);

// const PersonDataPlashkaWrapped = (props) => <PersonWrapper Wrapped={PersonDataPlashka} personId={props.personId} withPortalRoles={true} doLoadKubokRaiting = {true} withScores={true} componentProps={{ ...props }} />
// export default withCapoeiraSportState('iamuser, client, showInfoModal')(PersonDataPlashkaWrapped);