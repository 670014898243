
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';

import './inventar.css';
import { Tooltip, Icon } from 'antd';
import EventAvatar from 'src/ver2/components/event/avatar';
import Shine3D from 'src/ver2/components/3d/shine';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

/* 

    // заводим сюда
    width (это всегда квадрат)
    name 
    uid (чтобы дать индивидуальность)

    withType (иконка снизу)
    withSubtype (ионнка снизу)

    Item, чтобы была возможность считать Modyfied

    clickable = [
        {
            iconType: 'look' // use
            title:
            onClick
        }
    ]

*/

class ItemInventarComponent extends PureComponent {

    render() {

        const { width, name, client, plashkaType, isUsed, clickable, Item = {}, innerStyle = {}, showSubtype = false } = this.props;
        const style = {
            width,
            height: width,
            ...innerStyle
        }

        const item = client.getAbstractItem(name || Item.name);
        const { src, srcType, subtype, place, subtypeObj } = item;

        let EventComponent = null;
        const { ModyfiedObj, ServedTo } = Item || {};

        // смотрим чё к нам такое пришло
        if (ModyfiedObj) {
            if (ModyfiedObj.EventId) {
                if (subtype === 'medal_shalve') {


                    let eventWidth = 0.45;
                    let left = width * 0.5 - width * eventWidth / 2;
                    let top = width * 0.15;

                    // if ( ['','medal_bb1', 'medal_bb2', 'medal_bb3'].indexOf(name) > 0 ) {
                    //     left = width * 0.521 - width * 0.5 / 2;
                    //     top = width * 0.15;
                    // }

                    if (['', 'medal_l1', 'medal_l2', 'medal_l3'].indexOf(name) > 0) {
                        eventWidth = 0.25;
                        left = width * 0.5 - width * eventWidth / 2;
                        top = width * 0.2;
                    }

                    if (['', 'medal_ll1', 'medal_ll2', 'medal_ll3'].indexOf(name) > 0) {
                        top = width * 0.22;
                    }

                    if (['', 'medal_bbbb1', 'medal_bbbb2', 'medal_bbbb3'].indexOf(name) > 0) {
                        eventWidth = 0.23;
                        left = width * 0.492 - width * eventWidth / 2;
                        top = width * 0.125;
                    }

                    if (['', 'medal_bbbbcr1', 'medal_bbbbcr2', 'medal_bbbbcr3'].indexOf(name) > 0) {
                        eventWidth = 0.23;
                        left = width * 0.492 - width * eventWidth / 2;
                        top = width * 0.125;
                    }

                    if (['', 'medal_r1', 'medal_r2', 'medal_r3', 'medal_rr1', 'medal_rr2', 'medal_rr3'].indexOf(name) > 0) {
                        eventWidth = 0;
                    }

                    const style = {
                        left,
                        top
                    }
                    EventComponent = <div style={style} className='item_inventar_components_event'>
                        {(eventWidth > 0) && <EventAvatar size={width * eventWidth} eventId={ModyfiedObj.EventId} />}
                    </div>
                }
            }
        }

        if (plashkaType === 'inventar') {

            let className = 'item_inventar_components';
            if (isUsed) className += ' item_inventar_components_used';
            // if (clickable) className += ' item_inventar_components_used_clickable'
            let last = null;
            let lastClassName = 'item_inventar_components_last';
            if (ServedTo) {

                const dateEnd = moment(ServedTo, 'YYYY.MM.DDTHH:mm');
                const nowMoment = moment();

                const daysLeft = dateEnd.diff(nowMoment, 'days', true);
                last = {
                    days: daysLeft.toFixed()
                }
                if (last.days < 5) lastClassName = 'item_inventar_components_last item_inventar_components_last_ended'
            }

            return <div onClick={clickable ? clickable.onClick : null} style={style} className={className}>
                <img src={src} />
                {EventComponent}
                <div className='item_inventar_components_type'>
                    <img src={(showSubtype && subtypeObj) ? subtypeObj.subtypeSrc : srcType} />
                </div>
                {last && <div className={lastClassName}>
                    {`${last.days} д`}
                </div>}
                {clickable ? <div className='item_inventar_components_upper'>
                    {clickable.map(({ iconType, title, onClick }) => <Tooltip key={`ffrg1-${title}`} title={title}>
                        <Icon type={iconType} onClick={onClick} />
                    </Tooltip>
                    )}
                </div> : null}
            </div>
        }


        if (plashkaType === 'infomodal' || plashkaType === 'showinfomodal') {

            let EventComponent = null;
            const { ModyfiedObj } = Item || {};

            // смотрим чё к нам такое пришло
            if (ModyfiedObj) {
                if (ModyfiedObj.EventId) {
                    if (subtype === 'medal_shalve') {
                        // const style = {
                        //     left: width * 0.507 - width * 0.45 / 2,
                        //     top: width * 0.15
                        // }

                        let isShine = false;
                        let eventWidth = 0.45;
                        let left = width * 0.502 - width * eventWidth / 2;
                        let top = width * 0.17;

                        if (['', 'medal_bb1', 'medal_bb2', 'medal_bb3', 'medal_an1', 'medal_an2', 'medal_an3'].indexOf(name) > 0) {
                            left = width * 0.503 - width * eventWidth / 2;
                            top = width * 0.165;
                        }

                        if (['', 'medal_bbbb1', 'medal_bbbb2', 'medal_bbbb3'].indexOf(name) > 0) {
                            eventWidth = 0.23;
                            left = width * 0.492 - width * eventWidth / 2;
                            top = width * 0.125;
                        }

                        if (['', 'medal_bbbbcr1', 'medal_bbbbcr2', 'medal_bbbbcr3'].indexOf(name) > 0) {
                            eventWidth = 0.23;
                            left = width * 0.492 - width * eventWidth / 2;
                            top = width * 0.125;
                        }

                        if (['', 'medal_l1', 'medal_l2', 'medal_l3'].indexOf(name) > 0) {
                            eventWidth = 0.3;
                            left = width * 0.5 - width * eventWidth / 2;
                            top = width * 0.18;
                        }

                        if (['', 'medal_ll1', 'medal_ll2', 'medal_ll3'].indexOf(name) > 0) {
                            left = width * 0.505 - width * eventWidth / 2;
                            top = width * 0.23;
                        }

                        if (['', 'medal_cr1', 'medal_cr2', 'medal_cr3'].indexOf(name) > 0) {
                            isShine = true;
                        }

                        if (['', 'medal_r1', 'medal_r2', 'medal_r3', 'medal_rr1', 'medal_rr2', 'medal_rr3'].indexOf(name) > 0) {
                            eventWidth = 0;
                        }

                        if ([0, 2, 101].indexOf(ModyfiedObj.EventId) > 0) {
                            isShine = true;
                        }

                        const style = {
                            left,
                            top
                        }

                        EventComponent = <>
                            <div style={style} className='item_inventar_components_event'>
                                {(eventWidth > 0) && <EventAvatar size={width * eventWidth} eventId={ModyfiedObj.EventId} />}
                            </div>
                            {isShine && <div style={{ left: -width * 0.15, top: -width * 0.22 }} className='item_inventar_components_event_shine'>
                                <Shine3D drawWidth={width * 1.3} drawHeight={width * 1.3} Id={699} Place={place} />
                            </div>}
                        </>
                    }
                }
            }

            if (name === 'cabaca_yy5' || name === 'cabaca_yb5') {
                EventComponent = <>
                    <div style={{ left: -0.15 * width, top: -0.15 * width }} className='item_onwall_components_event_shine'>
                        <Shine3D drawWidth={width * 1.3} drawHeight={width * 1.3} Id={12} Place={1} />
                    </div>
                </>
            }

            if (name === 'plant_donate_1') {
                EventComponent = <>
                    <div style={{ left: width * 0.25, top: -0.12 * width, zIndex: 0 }} className='item_onwall_components_event_shine'>
                        <Shine3D drawWidth={width * 0.5} drawHeight={width * 0.5} Id={12} Place={4} />
                    </div>
                </>
            }

            if (name === 'toy_donate_5') {
                EventComponent = <>
                    <div style={{ left: -0.15 * width, top: -0.15 * width }} className='item_onwall_components_event_shine'>
                        <Shine3D drawWidth={width * 1.3} drawHeight={width * 1.3} Id={13} Place={4} />
                    </div>
                </>
            }

            if (name === 'cabaca_g5') {
                EventComponent = <>
                    <div style={{ left: -0.15 * width, top: -0.15 * width }} className='item_onwall_components_event_shine'>
                        <Shine3D drawWidth={width * 1.3} drawHeight={width * 1.3} Id={13} Place={5} />
                    </div>
                </>
            }

            if (plashkaType === 'showinfomodal') {
                return <div
                    style={style}
                    className='item_inventar_components item_inventar_components_infomodal'
                    onClick={() => this.props.showInfoModal({ type: 'item', Item: { ...item, name } })}

                >
                    <img src={src} />
                    {EventComponent}
                </div>
            }

            return <div style={style} className='item_inventar_components item_inventar_components_infomodal'>
                <img src={src} />
                {EventComponent}
            </div>
        }

        return <div style={style} className='item_inventar_components'>
            <img src={src} />
            {EventComponent}
            <div className='item_inventar_components_type'>
                <img src={srcType} />
            </div>
        </div>
    }

}

export default withCapoeiraSportState('client, showInfoModal')(ItemInventarComponent);