import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { TitledPlashka } from 'src/ver2/containers/lowlavel/title';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import { Link } from 'react-router-dom';
import Loader from 'src/ver2/components/loader';
import './style.css';

class StatisticsPlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            monthShift: 0,
            statistics: null
        }
    }

    componentDidMount = async () => {
        const { client } = this.props;
        const statistics = await client.getStatistics('[ "au", "nu" ]');
        this.setState({ statistics });
    }

    render() {

        const { upperStyle, lowTitle, client, isMobile } = this.props;

        const { statistics } = this.state;

        if (!statistics) return <TitledPlashka titleComponent={'Статистика портала'} upperStyle={upperStyle} lowTitle={lowTitle}>
            <div style={{ width: upperStyle.width }} className='statistics_plashka'>
                <Loader />
            </div>
        </TitledPlashka>

        const {
            allUsersCount,
            newWeekUsersCount
        } = statistics;

        const groupsCount = client.state.groups ? Object.keys(client.state.groups).length : 0;
        const schoolsCount = client.state.schools ? Object.keys(client.state.schools).length : 1;

        const { height } = upperStyle;

        if (!isMobile) return <Link to='/statistics'>
            <TitledPlashka titleComponent={'Статистика портала'} upperStyle={upperStyle} lowTitle={lowTitle}>
                <div className='statistics_plashka'>
                    <NumberText count={allUsersCount} text={'Участников'} height={height * 0.5} />
                    <NumberText count={schoolsCount - 1} text={'Кол-во школ'} height={height * 0.5} />
                    <NumberText count={groupsCount} text={'Кол-во групп'} height={height * 0.5} />
                    <div className='statistics_plashka_next'>
                        {`>`}
                    </div>
                </div>
            </TitledPlashka>
        </Link>

        return <TitledPlashka titleComponent={'Статистика портала'} upperStyle={upperStyle} lowTitle={lowTitle}>
            <div className='statistics_plashka'>
                <NumberText count={allUsersCount} text={'Участников'} height={height * 0.5} />
                <NumberText count={`+${newWeekUsersCount}`} text={'За неделю'} height={height * 0.5} />
                <NumberText count={schoolsCount - 1} text={'Кол-во школ'} height={height * 0.5} />
                <NumberText count={groupsCount} text={'Кол-во групп'} height={height * 0.5} />
                <Link to='/statistics'>
                    <div className='statistics_plashka_next'>
                        к полной <br />
                        {`статистике > `}
                    </div>
                </Link>
            </div>
        </TitledPlashka>

    }

}

export default withCapoeiraSportState('client, isMobile')(StatisticsPlashka);