import React, { Component } from 'react';
import { Drawer } from 'antd';
import { withRouter } from 'react-router-dom';
import './style.css';

export class DriverButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            driverVisible: false
        }
    }

    onOpen = () => { this.setState({ driverVisible: true }) }
    onClose = () => { this.setState({ driverVisible: false }) }

    render() {
        const { type = 'menu', ButtonComponent, title, DriverComponent } = this.props;
        const { driverVisible } = this.state;
        const { onOpen, onClose } = this;

        console.log('DriverButton', this.props);

        if (type === 'menu') {
            return <div className='driver_button_menu'>
                <ButtonComponent onClick={onOpen} />
                <Drawer
                    class='driver_button_menu_driver'
                    title={<span className='driver_button_menu_driver_title'>{title}</span>}
                    placement="left"
                    onClose={onClose}
                    visible={driverVisible}>
                         {driverVisible ? <DriverWithRoute onChangeLocation={onClose} component={DriverComponent} /> : null}
                </Drawer>
            </div>
        }

        return <div>

        </div>
    }
}

class DriverInside extends Component {

    componentDidUpdate({ location }) {
        if (location !== this.props.location) {
            this.props.onChangeLocation()
        }
    }

    render() {
        console.log('DriverInside', this.props);
        const { component } = this.props;
        return <span>
            {component}
        </span>
    }
}

const DriverWithRoute = withRouter(DriverInside);

// const DriverWithRoute = (ownProps) => withRouter (<DriverInside {...ownProps}/>)

//export const DriverButton = withRouter(props => (selfProps) => <DriverButton_  {...props} {...selfProps} />);