// это посто сама по себе аввтарка чувака
import React, { PureComponent } from 'react';

import LittlePerson from 'src/ver2/components/person/little';
import LittleEvent from 'src/ver2/components/event/little';

import VideoWrapper from 'src/ver2/components/video/videoWrapper';
import Loader from 'src/ver2/components/loader';

import LinkButton from 'src/ver2/components/ui/buttons/link'



import './style.css';

/*

   Большая штука, которая показывает плашку с видосом

*/

class BigPlate_ extends PureComponent {

    state = {
        playedTime: 0,
        firtsStart: false,
        status: 'loading',
    }

    ref = React.createRef();
    vkPlayer;

    render() {

        const { Video, height = 100, isMobile, client } = this.props;
        const { YouTubeKey } = this.state;

        const width = height * 0.75;

        if (!Video) return <div style={{ height, width }} className='person_place_rect_mini'>
            <Loader />
        </div>


        const { Title, Url, Id, red_user_id, blue_user_id, CompetitionId } = Video;

        const Events = client.getAllEvents();

        const Event = (Events && Events.find(x => x.ChampionshipId === CompetitionId));

        const videoWidth = isMobile ? (isMobile.width - 34) : 1000;
        const videoHeight = videoWidth * (720 / 1280);

        const linkButtonStyle = {
            position: 'absolute',
            top: 0,
            left: isMobile ? 0 : 'calc(20px)'
        }

        const { status } = this.state;

        return <div className='videoplate_main'>
            <div className='videoplate_main_container'>
                <LinkButton style={linkButtonStyle} type={'clipboard'} saveText={`https://capoeirasport.com/?videoId=${Id}`} size={isMobile ? 'mini' : 'usual'} />

                <div className='videoplate_main_competition' style={{ height: 40 }} >
                    {Event ? <LittleEvent height={40} eventId={Event.Id} mode='bigvideo' /> : null}
                </div>

                <div className='videoplate_main_competitors'>
                    <LittlePerson personId={red_user_id} height={videoWidth / 5} mode='videoplate_left' />
                    <LittlePerson personId={blue_user_id} height={videoWidth / 5} mode='videoplate' />
                </div>
                {status === 'loaded' && <this.VKPlayer />}
                {status === 'loaded' && YouTubeKey && <div id={`bigplate-youtube-player-1`} style={{ width: videoWidth, height: videoHeight }} />}
            </div>
        </div >
    }

    VKPlayer = () => {
        const { VK_Key } = this.state;
        if (!VK_Key) return null;
        const VK = window.VK;
        const VK_Key_ = VK_Key.split('_');

        return <iframe
            ref={this.ref}
            src={`https://vk.com/video_ext.php?oid=-${VK_Key_[0]}&id=${VK_Key_[1]}&hd=2&js_api=1`}
            width="100%"
            height="480"
            allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
            frameborder="0"
            allowfullscreen >
        </iframe>

    }

    componentDidMount() {
        // this.loadVideoFromServer();
        // console.log ('BigPlate_ DID MOUNT')
    }

    componentDidUpdate({ Video }) {
        if (!Video && this.props.Video) {
            this.loadVideoFromServer();
        }

        if (!this.vkPlayer && this.ref && this.ref.current) {
            this.vkPlayer = window.VK.VideoPlayer(this.ref.current);
            this.vkPlayer.on( 'inited', this.vkPlayerInited )

            // player && player.play && player.play();
        }

    }

    vkPlayerInited = () => {
        const { startSeconds } = this.state;
        this.vkPlayer.seek( startSeconds || 0); 
        this.vkPlayer.play();
    }

    componentWillUnmount() {
        // console.log('BigPlate_ UNMOUNT')
    }

    loadVideoFromServer = async () => {
        const { client, videoId } = this.props;

        const videoResult = await client.getVideoByIdFromServer(videoId);

        if (videoResult && (videoResult.YouTubeUrl || videoResult.VK_Key)) {

            const { YouTubeUrl, VK_Key, TimeStart, TimeEnd } = videoResult;

            this.setState({
                YouTubeKey: YouTubeUrl,
                VK_Key,
                startSeconds: TimeStart,
                TimeEnd: TimeEnd || (TimeStart + 130),
                status: 'loaded'
            })

            if (YouTubeUrl) {
                if (!window.YT) { // If not, load the script asynchronously
                    const tag = document.createElement('script');
                    tag.src = 'https://www.youtube.com/iframe_api';
                    // onYouTubeIframeAPIReady will load the video after the script is loaded
                    window.onYouTubeIframeAPIReady = this.loadYouTube;
                    const firstScriptTag = document.getElementsByTagName('script')[0];
                    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                } else { // If script is already there, load the video directly
                    this.loadYouTube();
                }
            }

            if (VK_Key) {

            }

        } else {

        }
    }

    loadYouTube = () => {

        const { YouTubeKey } = this.state;

        // the Player object is created uniquely based on the id in props
        this.player = new window.YT.Player(`bigplate-youtube-player-1`, {
            videoId: YouTubeKey,
            playerVars: {
                'autoplay': 1,
                'controls': 1,
                'mute': 1,
                'showinfo': 0,
                'modestBranding': 1,
                'iv_load_policy': 0,
                'rel': 0
            },
            events: {
                onReady: this.onPlayerReady,
                onStateChange: this.onStateChange
            },
        });
    };

    onPlayerReady = event => {
        const { startSeconds } = this.state;
        event.target.seekTo(startSeconds);
        // event.target.playVideo()
    };

    onStateChange = ({ data, target }) => {

        const { firtsStart } = this.state;
        if (firtsStart) return;

        if (data === 1) {
            const { startSeconds } = this.state;
            this.setState({ firtsStart: true })
            target.seekTo(startSeconds);
        }
    }

    seekTo = (time) => {
        this.player && this.player.seekTo(time);
    }
}

const componentWrapped = (props) => <VideoWrapper Wrapped={BigPlate_} videoId={props.videoId} componentProps={{ ...props }} />
export const BigPlate = componentWrapped;