
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { Tooltip } from 'antd';

/* 

    Мы отправляем сюда itemName предмета
    Получаем картинку с тултипом сверху типа или сабтипа

    Но можем и прямо отправить type и subtype

    size заводим

*/

class ItemType extends PureComponent {

    render() {

        const { size, client, itemName, type, className } = this.props;
        let style = {}
        if (size) {
            style.width = size;
            style.height = size;
        }

        const item = type ? client.getCosmeticType(type) : client.getAbstractItem(itemName);
        const { typeObj: { title }, srcType } = item;

        return <Tooltip title={title}>
            <img src={srcType} {...{ style, className }} />
        </Tooltip>
    }

}

class ItemSubtype extends PureComponent {

    render() {

        const { size, client, itemName, subtype, className } = this.props;
        let style = {}
        if (size) {
            style.width = size;
            style.height = size;
        }

        const item = subtype ? client.getCosmeticSubtype (subtype) : client.getAbstractItem(itemName);
        const { subtypeObj } = item;

        if (!subtypeObj) return null;

        const { subtypeSrc, subtypeTitle } = subtypeObj;

        return <Tooltip title={subtypeTitle}>
            <img src={subtypeSrc} {...{ style, className }} />
        </Tooltip>
    }

}

export const ItemTypeComponent = withCapoeiraSportState('client')(ItemType);
export const ItemSubtypeComponent = withCapoeiraSportState('client')(ItemSubtype);