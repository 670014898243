export default {

    shalve_usual_t1: {
        type: 'stuff',
        subtype: 'shalve',
        grade: 1,
        discription: '',
        src: `/../images/ver2/scores/stuff/shalve_usual_t1.png`,

        w: 4,
        h: 1,
        shiftX: 0,
        shiftY: 0   
    },
    shalve_white_t1: {
        type: 'stuff',
        subtype: 'shalve',
        grade: 1,
        discription: '',
        src: `/../images/ver2/scores/stuff/shalve_white_t1.png`,

        w: 4,
        h: 1,
        shiftX: 0,
        shiftY: 0   
    },
    shalve1_usual_t1: {
        type: 'stuff',
        subtype: 'shalve',
        grade: 1,
        discription: '',
        src: `/../images/ver2/scores/stuff/shalve1_usual_t1.png`,

        w: 1,
        h: 1,
        shiftX: 0,
        shiftY: 0   
    },
    shalve_orange_t1: {
        type: 'stuff',
        subtype: 'shalve',
        grade: 3,
        discription: '',
        src: `/../images/ver2/scores/stuff/shalve_orange_t1.png`,

        w: 2,
        h: 1,
        shiftX: 0,
        shiftY: 0   
    },


    shalve_br1_1: {
        grade: 1, src: `/../images/ver2/scores/stuff/shalve_br1_1.png`,
        title: 'Простая полка S',
        discription: 'Маленькая деревянная полка. Ничего необычного. Подойдёт для маленького растения, а может и для небольшой награды.',
        w: 1, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },

    shalve_br2_1: {
        grade: 1, src: `/../images/ver2/scores/stuff/shalve_br2_1.png`,
        title: 'Простая полка M',
        discription: 'Средняя деревянная полка. Всё еще ничего необычного, но можно поставить предметы побольше.',
        w: 2, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },

    shalve_br4_1: {
        grade: 1, src: `/../images/ver2/scores/stuff/shalve_br4_1.png`,
        title: 'Простая полка L',
        discription: 'Большая деревянная полка. Со скрипом, но исполняет свои функции: может держать нескоько действительно больших предметов.',
        w: 4, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },

    shalve_b1_2: {
        grade: 2, src: `/../images/ver2/scores/stuff/shalve_b1_2.png`,
        title: 'Обычная полка S',
        discription: 'Маленькая синяя полка. Добавляет уют интерьеру.',
        w: 1, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },

    shalve_b2_2: {
        grade: 2, src: `/../images/ver2/scores/stuff/shalve_b2_2.png`,
        title: 'Обычная полка M',
        discription: 'Средняя синяя полка. Вполне подойдёт для большой мебели или кубка.',
        w: 2, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },

    shalve_b4_2: {
        grade: 2, src: `/../images/ver2/scores/stuff/shalve_b4_2.png`,
        title: 'Обычная полка L',
        discription: 'Большая синяя полка. Самая универсальная. Она повидает твои взлёты и падения, награды и растения, от и до.',
        w: 4, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    shalve_r1_2: {
        grade: 2, src: `/../images/ver2/scores/stuff/shalve_r1_2.png`,
        title: 'Обычная полка S',
        discription: 'Маленькая красная полка. Даже без предмета на ней прекрасно смотрится.',
        w: 1, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },

    shalve_r2_2: {
        grade: 2, src: `/../images/ver2/scores/stuff/shalve_r2_2.png`,
        title: 'Обычная полка M',
        discription: 'Средняя красная полка. Интересно, зачем была совершена эта покупка?',
        w: 2, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },

    shalve_r4_2: {
        grade: 2, src: `/../images/ver2/scores/stuff/shalve_r4_2.png`,
        title: 'Обычная полка L',
        discription: 'Большая красная полка. Яркая, чёткая, а сколько на неё влезет!',
        w: 4, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    shalve_br2_2: {
        grade: 2, src: `/../images/ver2/scores/stuff/shalve_br2_2.png`,
        title: 'Бабушкина M',
        discription: 'Старая бабушкина полка, сколько всего она повидала! А теперь непременно будет держать твой Кубок России!',
        w: 2, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },


    shalve_y2_3: {
        grade: 3, src: `/../images/ver2/scores/stuff/shalve_y2_3.png`,
        title: 'Желтая M',
        discription: 'Нет, ну вы видели этот цвет! Надо срочно брать! Это полка не просится к Вам на стену - это вы упрашиваете её!',
        w: 2, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    shalve_g1_3: {
        grade: 3, src: `/../images/ver2/scores/stuff/shalve_g1_3.png`,
        title: 'Продвинутая S',
        discription: 'Какая интересная форма и цвет! Сочетается с белым, не благодарите.',
        w: 1, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    shalve_w2_3: {
        grade: 3, src: `/../images/ver2/scores/stuff/shalve_w2_3.png`,
        title: 'Современная M',
        discription: 'Пластиковая бежевая полка. Очень современная. Главное, чтобы с краёв ничего не упало.',
        w: 2, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    shalve_r2_3: {
        grade: 3, src: `/../images/ver2/scores/stuff/shalve_r2_3.png`,
        title: 'Бунтарская M',
        discription: 'Что? Полка сделана под лаву? Такое подойдёт только для бунтарей!',
        w: 2, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    

    shalve_w4_3: {
        grade: 3, src: `/../images/ver2/scores/stuff/shalve_w4_3.png`,
        title: 'Современная L',
        discription: 'Пластиковая бежевая полка. Очень современная. Главное, чтобы с краёв ничего не упало.',
        w: 4, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    shalve_r4_3: {
        grade: 3, src: `/../images/ver2/scores/stuff/shalve_r4_3.png`,
        title: 'Красная L',
        discription: 'Длинная красная пластиковая полка. Ну что о ней еще можно сказать, когда она говорит сама за себя',
        w: 4, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },

   
    shalve_g2_4: {
        grade: 4, src: `/../images/ver2/scores/stuff/shalve_g2_4.png`,
        title: 'Ультра M',
        discription: 'Всем полкам полка! Слишком хороша, чтобы быть правдой... но она есть, вот же!',
        w: 2, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    shalve_g2l_4: {
        grade: 4, src: `/../images/ver2/scores/stuff/shalve_g2l_4.png`,
        title: 'Ультра M',
        discription: 'Всем полкам полка! Слишком хороша, чтобы быть правдой... но она есть, вот же!',
        w: 2, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    shalve_g4_4: {
        grade: 4, src: `/../images/ver2/scores/stuff/shalve_g4_4.png`,
        title: 'Ультра L',
        discription: 'Всем полкам полка! Слишком хороша, чтобы быть правдой... но она есть, вот же!',
        w: 4, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    
    shalve_r1_4: {
        grade: 4, src: `/../images/ver2/scores/stuff/shalve_r1_4.png`,
        title: 'Красный Модерн S',
        discription: 'Рискнёте ли вы добавить такую продвинутую полку себе на стену?',
        w: 1, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    shalve_w1_4: {
        grade: 4, src: `/../images/ver2/scores/stuff/shalve_w1_4.png`,
        title: 'Белый Модерн S',
        discription: 'Рискнёте ли вы добавить такую продвинутую полку себе на стену?',
        w: 1, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },

    shalve_r2_4: {
        grade: 4, src: `/../images/ver2/scores/stuff/shalve_r2_4.png`,
        title: 'Белый Модерн M',
        discription: 'Сверхсовременный дизайн. Осталось придумать, куда её повесить.',
        w: 2, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    shalve_r2l_4: {
        grade: 4, src: `/../images/ver2/scores/stuff/shalve_r2l_4.png`,
        title: 'Белый Модерн M',
        discription: 'Сверхсовременный дизайн. Осталось придумать, куда её повесить.',
        w: 2, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    shalve_w2_4: {
        grade: 4, src: `/../images/ver2/scores/stuff/shalve_w2_4.png`,
        title: 'Красный Модерн M',
        discription: 'Сверхсовременный дизайн. Осталось придумать, куда её повесить. А она еще и красная!',
        w: 2, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },

    
    shalve_r4_4: {
        grade: 4, src: `/../images/ver2/scores/stuff/shalve_r4_4.png`,
        title: 'Красная L',
        discription: 'Полка, которая всегда напомнит, чем мы тут все занимаемся! Идеальна для любого приза!',
        w: 4, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },

    // КУБОК МОСКВЫ
    
    kmo_shalve_w2_4: {
        grade: 4, src: `/../images/ver2/scores/stuff/kmo_shalve_w2_4.png`,
        title: 'Стекло M',
        discription: 'Стеклянная полка, которую можно приобрести в рамках ивента в честь Кубка Москвы 2022!',
        w: 2, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    kmo_shalve_w4_4: {
        grade: 4, src: `/../images/ver2/scores/stuff/kmo_shalve_w4_4.png`,
        title: 'Стекло L',
        discription: 'Большая стеклянная полка, которую можно приобрести в рамках ивента в честь Кубка Москвы 2022!',
        w: 4, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    kmo_shalve_b2_4: {
        grade: 4, src: `/../images/ver2/scores/stuff/kmo_shalve_b2_4.png`,
        title: 'Черное стекло M',
        discription: 'Черная стеклянная полка, которую можно приобрести в рамках ивента в честь Кубка Москвы 2022!',
        w: 2, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },
    kmo_shalve_b4_5: {
        grade: 5, src: `/../images/ver2/scores/stuff/kmo_shalve_b4_5.png`,
        title: 'Черное стекло L',
        discription: 'Большая черная стеклянная полка, которую можно приобрести в рамках ивента в честь Кубка Москвы 2022!',
        w: 4, h: 1,shiftX: 0, shiftY: 0, type: 'stuff', subtype: 'shalve',
    },

}