import React, { Component } from 'react';
import './component.css';
import { SoloMiniSequence } from '../mini-sequence';
import LittlePerson from 'src/ver2/components/person/little';
import { venok } from 'src/ver2/pages/sequence/components/sequence/components/graphComponents/venok';
// import { PersonCard } from '../../../PersonCard';
import { Link } from 'react-router-dom';

import { ICON_VIDEO, ICON_TUTORIAL } from '../../icons';
import { Tooltip } from 'antd';

/*
  Это у нас элемент, который показывает небольшую плашку связки - 
  кто делал, сама связка и её статус ( потом может еще что-то добавится)
*/

export class SoloMiniSequenceRow extends Component {

  render() {
    const { client, sequence, color_status, may_alert, isMobile, noName, noIcon, isSelected, do5judges, linkFunc, noNameNoLink, onlySeqMode = false } = this.props;

    const { UserId, Sequence, Status, Points, RStars, Code, IsVideo, IsTutorial, Options } = sequence;

    let classNameUp = 'solo-minisequencerow-div';

    if (noName) classNameUp = 'solo-minisequencerow-div-noscale';
    if (isSelected) classNameUp += ' solo-minisequencerow-div-isSelected';

    let classNameText = 'solo-minisequencerow-div-status-waiting';

    if (color_status && Status === 'waiting') {
      classNameUp += ' solo-minisequencerow-div-dashed';
      classNameText += ' solo-minisequencerow-div-status-waiting-dashed';
    }

    let alert_mode = {};

    let waitingText = ['ожидает', <br />, 'оценки'];
    if (Status === 'waiting' && !IsVideo) {
      if (may_alert) alert_mode = { color: 'red', fontWeigth: 'bold' };
      waitingText = ['ожидает', <br />, 'видео'];
    }

    if (do5judges && Options) {
      const OptionsParse = JSON.parse(Options);
      if (OptionsParse && OptionsParse.judges5 && OptionsParse.judges5.result) {
        if (Status === 'waiting' && IsVideo) {
          alert_mode = { textAlign: 'center' }
          waitingText = [OptionsParse.judges5.result, <br />, 'на пояс'];
        }
      }
    }

    const PointsThing = (Points && RStars) ?

      <div className={`solo-minisequencerow-div-withvenok solo-minisequencerow-div-withvenok_${RStars}`}>
        <div className='solo-minisequencerow-div-withvenok_venok'>
          {venok}
        </div>
        <span>
          {Points}
        </span>
        <div className='solo-minisequencerow-div-withvenok_venok solo-minisequencerow-div-withvenok_venok-right'>
          {venok}
        </div>
      </div>

      : Points;

    const Content = onlySeqMode ?
      <div className={classNameUp}>
        {linkFunc ?
          <div style={{ cursor: 'pointer' }} onClick={linkFunc}>
            <div className='solo-minisequencerow-div-sequence'>
              <SoloMiniSequence elements_only {...{ Sequence, Options: '', client }} />
            </div>
          </div>
          :
          <Link to={`/sequence/${Code}`}>
            <div className='solo-minisequencerow-div-sequence'>
              <SoloMiniSequence elements_only {...{ Sequence, Options: '', client }} />
            </div>
          </Link>
        }
      </div>
      :
      <div className={classNameUp}>
        {
          (noIcon ? null :
            (noName || (isMobile && isMobile.width < 550))
              ?
              <div className='solo-minisequencerow-div-person solo-minisequencerow-div-person-noname'>
                <LittlePerson personId={UserId} height={60} mode={'no_name'} noNameNoLink={noNameNoLink} />
              </div>
              :
              <div className='solo-minisequencerow-div-person'>
                <LittlePerson personId={UserId} height={60} />
              </div>
          )
        }
        {linkFunc ?
          <div style={{ cursor: 'pointer' }} onClick={linkFunc}>
            <div className='solo-minisequencerow-div-sequence'>
              <SoloMiniSequence elements_only {...{ Sequence, Options: '', client }} />
            </div>
          </div>
          :
          <Link to={`/sequence/${Code}`}>
            <div className='solo-minisequencerow-div-sequence'>
              <SoloMiniSequence elements_only {...{ Sequence, Options: '', client }} />
            </div>
          </Link>
        }
        <div className='solo-minisequencerow-div-status'>
          {Status === 'waiting' ?
            <div className={classNameText} style={alert_mode}> {waitingText} </div>
            :
            <div className='solo-minisequencerow-div-status-confirm' > {PointsThing} </div>
          }
        </div>
        <div className='solo-minisequencerow-div-video'>
          {(IsVideo && IsVideo !== 'SENDED') ? IsTutorial ? ICON_TUTORIAL(true) : ICON_VIDEO(true) : null}
        </div>
      </div>

    if (IsVideo && IsVideo[0] === 'i') {
      return <Tooltip placement={'bottom'} title={
        <div style={{
          width: '200px',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }} >
          <video
            playsInline="true"
            id="my-player"
            class="video-js"
            preload="auto"
            autoPlay="true"
            muted="true"
            poster={`https://capoeirasport.com/graphics/video_technics/logo.png`}
            loop="true"
            width='200px'
            controls=""
            data-setup="{}">
            <source
              src={`https://capoeirasport.com/graphics/video_technics/sequences/${Code}.mp4`}
              type="video/mp4" />
          </video>
        </div>
      }>
        {Content}
      </Tooltip>
    }

    return (
      Content
    );
  }
}