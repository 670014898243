import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import PersonWrapper from 'src/ver2/components/person/personWrapper.js';
import Loader from 'src/ver2/components/loader';
import VideoLittlePlashka from 'src/ver2/plashki/video/little';

import './style.css';

/**
 * Эта компонент, который показывает все видео спортсмена, открывается в модале
 * 
 */

class PersonVideoBigPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    shouldComponentUpdate({ Person, Videos, upperStyle }) {
        if (Person !== this.props.Person) return true;
        if (Videos !== this.props.Videos) return true;
        return false;
    }

    render() {

        const { Videos } = this.props;

        if (!Videos || !Videos.length) return <div className='person_video_big_plashka'>
            <Loader />
        </div>

        return <div className='person_video_big_plashka'>

            {Videos.map((video) => {
                const { Id } = video;
                return <VideoLittlePlashka key={'pvbpv_' + Id} upperStyle={{ width: 330, height: 280 }} videoId={Id} />
            })}

        </div>
    }

}

const PersonVideoBigPlashkaWrapped = (props) => <PersonWrapper Wrapped={PersonVideoBigPlashka} personId={props.personId} withVideos={true} componentProps={{ ...props }} />
export default withCapoeiraSportState('iamuser, client')(PersonVideoBigPlashkaWrapped);