import React from 'react';

export default () => <svg width="24.25mm" height="24.25mm"
    viewBox="0 0 2425 2425" >
    <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_1478522088560">
            <g>
                <circle class="ui_icon__fill" cx="1227.94" cy="1964.03" r="181.18" />
                <path class="ui_icon__fill" d="M1452.5 523.53c29.34,-230.89 -167.66,-230.34 -226.48,-230.34 -58.81,0 -255.82,-0.55 -226.47,230.34 39.74,312.67 79.48,625.34 119.23,938 17.47,137.44 40.67,151.68 107.24,151.68 66.57,0 89.78,-14.24 107.25,-151.68 39.74,-312.66 79.49,-625.33 119.23,-938z" />
                <path class="ui_icon__fill" d="M998.07 1943.97c-342.58,-91.54 -594.88,-404.04 -594.88,-775.47 0,-228.69 95.65,-435.05 249.12,-581.24 -177.06,154.44 -288.95,381.71 -288.95,635.12 0,398.13 276.18,731.76 647.4,819.75 -8.77,-24.39 -13.55,-50.69 -13.55,-78.1 0,-6.76 0.29,-13.45 0.86,-20.06z" />
                <path class="ui_icon__fill" d="M486.82 1168.5c0,332.94 226.3,613.02 533.47,694.82 37.4,-76.96 116.33,-130.01 207.65,-130.01 86.74,0 162.31,47.87 201.71,118.63 287.48,-94.09 495.13,-364.52 495.13,-683.44 0,-280.46 -160.59,-523.42 -394.83,-641.93 -52.35,325.81 -104.7,651.63 -157.04,977.44 -23.93,148.92 -55.72,164.36 -146.89,164.36 -91.17,0 -122.95,-15.44 -146.88,-164.36 -53.29,-331.7 -106.59,-663.42 -159.88,-995.12 -254.49,110.71 -432.44,364.38 -432.44,659.61z" />
                <path class="ui_icon__fill" d="M2008.41 1168.5c0,355.78 -231.49,657.49 -552.06,762.73 1.52,10.72 2.31,21.67 2.31,32.8 0,22.59 -3.24,44.42 -9.29,65.05 346.51,-104.48 598.87,-426.12 598.87,-806.7 0,-253.41 -111.89,-480.68 -288.95,-635.12 153.47,146.19 249.12,352.55 249.12,581.24z" />
            </g>
        </g>
    </g>
</svg>