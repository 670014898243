import React, { Component } from 'react';
import { withPersonState } from 'src/ver2/pages/person/context';
import Loader from 'src/ver2/components/loader';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import { groupBy } from 'lodash';
import { Link } from 'react-router-dom';

import './style.css';

/**
 * Эта плашка показывает какое-то количество видео спортсмена
 * 
 */

class PersonRaitingPlashka extends Component {

    // constructor(props) {
    //     super(props);
    // }

    shouldComponentUpdate({ isRaitingReady, Raiting, upperStyle }) {
        if (isRaitingReady !== this.props.isRaitingReady) return true;
        if (Raiting !== this.props.Raiting) return true;
        if (upperStyle.height !== this.props.upperStyle.height) return true;
        return false;
    }

    render() {

        const { isRaitingReady, Raiting, setComponentSelfHeight, plashkaId, isMobile, client } = this.props;

        if (!isRaitingReady) return <div className='person_raiting_plashka'>
            <Loader text='Загрузка рейтинга' />
        </div>

        const { RussiaRaiting, RegionRaiting } = Raiting;

        // console.log ('PersonRaitingPlashka', {Raiting} );

        if (!RussiaRaiting || !RussiaRaiting.length) {
            setComponentSelfHeight({ plashkaId, height: 1 });
            return null;
        }

        this.AllHeight = isMobile ? 20 : 65;

        const OneSeason = ({ Season }) => {

            // сначала берём Российский рейтинг
            const RussiaSeason = RussiaRaiting.filter(x => x.Season === Season);
            // const RegionSeason = RegionRaiting ? RegionRaiting.filter(x => x.Season === Season) : [];

            const CategoryNames = client.CategoryNamesBySeason({ season: Season });

            const Devider = () => <div className='person_raiting_plashka_raiting_one_devider'>
                <div className='person_raiting_plashka_raiting_one_devider_inside' />
            </div>

            const blockHeight = isMobile ? 14 : 40;

            return <div className='person_raiting_plashka_oneseason'>
                <div className='person_raiting_plashka_season'>
                    {isMobile ? `Сезон ${Season} • ${CategoryNames[RussiaSeason[0].Category]}` : `Сезон ${Season} • Категория ${CategoryNames[RussiaSeason[0].Category]}`}
                </div>
                <div className='person_raiting_plashka_raiting'>
                    {RussiaSeason.map(({ PlaceName, Type, Category, Scores }) => {

                        if ( Type !== 'comp_pro' && Type !== 'comp_not_pro' ) {
                            return null;
                        }

                        let RegionPlace = null;
                        const regionSeason = RegionRaiting ? RegionRaiting.find(x => x.Season === Season && x.Type === Type) : null;
                        if (regionSeason) RegionPlace = regionSeason;
                        return <div className='person_raiting_plashka_raiting_one_row'>
                            <div className='person_raiting_plashka_raiting_one_info'>
                                <NumberText count={Type === 'comp_pro' ? 'ПРО' : 'НОВ'} text={'ТИП'} height={blockHeight} />
                                <NumberText count={Scores} text={'ОЧКИ'} height={blockHeight} />
                            </div>
                            <Devider />
                            <Link to={`/raiting?season=${Season}&type=${Type}&category=${Category}&raitingSubject=user`}>
                                <div className='person_raiting_plashka_raiting_one_info'>
                                    <NumberText count={PlaceName} text={'МЕСТО'} height={blockHeight} />
                                    <NumberText count={'РОССИЯ'} text={'РЕГИОН'} height={blockHeight} />
                                </div>
                            </Link>
                            <Devider />
                            {RegionPlace ?
                                <Link to={`/region/${RegionPlace.Region}?season=${Season}&type=${Type}&category=${Category}&raitingSubject=user&page=raiting`}>
                                    <div className='person_raiting_plashka_raiting_one_info'>
                                        <NumberText count={RegionPlace.PlaceName} text={'МЕСТО'} height={blockHeight} />
                                        <NumberText count={RegionPlace.Region} text={'РЕГИОН'} height={blockHeight} />
                                    </div>
                                </Link>
                                : <div className='person_raiting_plashka_raiting_one_info' />}
                        </div>
                    })}
                </div>
            </div>
        }

        const OneSeasonHeight = ( Season ) => {
            const RussiaSeason = RussiaRaiting.filter(x => (x.Season === Season) && (x.Type === 'comp_pro' || x.Type === 'comp_not_pro'));
            this.AllHeight += 35 + RussiaSeason.length * (isMobile ? 40 : 60);
        }


        // теперь надо собрать рейтинг по куска исходя из сезонов

        const seasons_ = groupBy(RussiaRaiting, 'Season');
        const seasons = Object.keys(seasons_);
        seasons.reverse();

        
        for ( let i = 0; i < seasons.length; i++) {
            OneSeasonHeight( seasons[i]);
        }


        setComponentSelfHeight({ plashkaId, height: this.AllHeight });

        const { width, height } = this.props.upperStyle;

        // console.log('video', Videos);

        return <div style={{ width }} className='person_raiting_plashka'>
            {seasons.map(Season => <OneSeason key={`prpp-${Season}`} Season={Season} />)}
        </div>
    }

}

export default withPersonState(`
    Raiting,
    isRaitingReady,
    client,
    isMobile
`
)(PersonRaitingPlashka);