import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { message } from 'antd';
import './style.css';

// import Pump from 'src/ver2/components/halloween';
// <Pump width={40} left={178} top={223}pumpCode = {'bbcsdf'}/>

class LittleOperation extends PureComponent {

    onClick = async () => {
        const { pumpCode, client, iamuser } = this.props;

        if (!iamuser || !iamuser.Id) {
            message.error(`Зарегистрируйтесь или войдите в аккаунт`)
            return
        }

        const res = await client.hwConstructor({ ActionData: 'find', Code: pumpCode })

        if (res && res.Message === 'OK') {
            message.success(`Вы нашли новую тыкву`)
        } else {
            message.error(`Вы уже находили эту тыкву`)
        }
    }

    render() {
        const { client, pumpCode, type } = this.props;
        if (!client || !client.helloweenMode || !pumpCode) return null;
        const { width, top, left } = this.props;

        let className = 'pumpcin_hw';
        if (top || left) className += ' pumpcin_hw_abs';

        const style = {
            width, height: width
        }

        if (top) style.top = top;
        if (left) style.left = left;

        let imgCmp = <img src="/../images/ver2/pump.png" />;
        if (type === 'white') imgCmp = <img src="/../images/ver2/pump_white.png" />;

        return <div onClick={this.onClick} style={style} className={className}>
            {imgCmp}
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile, showInfoModal')(LittleOperation);