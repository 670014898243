// это посто сама по себе аввтарка чувака
import React from 'react';

import PersonAvatar from 'src/ver2/components/person/avatar';
import GroupAvatar from 'src/ver2/components/group/avatar';
import SchoolAvatar from 'src/ver2/components/school/avatar';
import CordaoAvatar from 'src/ver2/components/cordao/avatar';
import EventAvatar from 'src/ver2/components/event/avatar';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import PlaceRect from 'src/ver2/containers/lowlavel/placeRect';
import { Link } from 'react-router-dom';

import Loader from 'src/ver2/components/loader';

import './style.css';

/*

    Мини-штука, показывающая прямоугольную плашку с результатом чего-либо

*/

const ResultsMiniComponent = ({ Gold = 0, Silver = 0, Bronze = 0, Title, EventId, Count, width, height }) => {

    let style = {};

    const AllScores = Count * 7;
    const thisScores = Gold * 7 + Silver * 5 + Bronze * 3;

    let place = 0;

    const resultative = thisScores / AllScores;

    if (resultative > 0.2) place = 3;
    if (resultative >= 0.35) place = 2;
    if (resultative >= 0.5 && Gold > Count * 0.15) place = 1;

    if (place === 1) {
        style.backgroundImage = `url('https://capoeirasport.com/graphics/gold.jpg')`;
        style.border = `1px solid #f7ff66`;
        style.filter = `drop-shadow( 0px 0px 0.15rem #fffb00dd )`;
    }
    if (place === 2) {
        style.backgroundImage = `url('https://capoeirasport.com/graphics/silver.png')`;
        style.border = `1px solid #ffffff`;
        style.filter = `drop-shadow( 0px 0px 0.15rem #5f9ea0dd )`;
    }
    if (place === 3) {
        style.backgroundImage = `url('https://capoeirasport.com/graphics/bronze.jpg')`;
        style.border = `1px solid #e7722e`;
        style.filter = `drop-shadow( 0px 0px 0.15rem #e7722edd )`;
    }

    return <Link to={'/event/' + EventId + '/?page=results'}>
        <div style={{ width, height, ...style }} className='results_mini_component'>

            <div style={{ minWidth: height, width: height, height }} className='results_mini_component_event'>
                <EventAvatar
                    withExtra={true}
                    eventId={EventId}
                    size={height * 0.85}
                />
            </div>
            <div style={{ height }} className='results_mini_component_right'>
                <div style={{ height: height * 0.4, width: width - height - 12 }} className='results_mini_component_right_eventtitle'>
                    <p>{Title}</p>
                </div>
                <div style={{ height: height * 0.5 }} className='results_mini_component_right_downed'>
                    <p>{`УЧАСТНИКОВ: ${Count}`}</p>
                    <MedalBlock Medals={{ Gold, Silver, Bronze }} height={height * 0.5} />
                </div>
            </div>
        </div >
    </Link>


}

const MedalBlock = ({ height, Medals }) => {

    if (!Medals) return null;

    // if (place === 1) {
    //     style.backgroundImage = `url('https://capoeirasport.com/graphics/gold.jpg')`;
    //     style.border = `2px solid #f7ff66`;
    //     style.filter = `drop-shadow( 0px 0px 0.45rem #fffb00dd )`;
    // }
    // if (place === 2) {
    //     style.backgroundImage = `url('https://capoeirasport.com/graphics/silver.png')`;
    //     style.border = `2px solid #ffffff`;
    //     style.filter = `drop-shadow( 0px 0px 0.45rem #5f9ea0dd )`;
    // }
    // if (place === 3) {
    //     style.backgroundImage = `url('https://capoeirasport.com/graphics/bronze.jpg')`;
    //     style.border = `2px solid #e7722e`;
    //     style.filter = `drop-shadow( 0px 0px 0.45rem #e7722edd )`;
    // }

    return <div className='group_big_plate_medals_inside'>
        <PlaceRect height={height} Place={1} PlaceName={Medals.Gold} asMedal={true} />
        <PlaceRect height={height} Place={2} PlaceName={Medals.Silver} asMedal={true} />
        <PlaceRect height={height} Place={3} PlaceName={Medals.Bronze} asMedal={true} />
    </div>
}

export default ResultsMiniComponent;