import React, { PureComponent } from 'react';
import LittleEvent from 'src/ver2/components/event/little';
import Loader from 'src/ver2/components/loader';

import './style.css';

//getEventById

/**
  Это маленький список ивентов
  Сюда приходит массив ивентов Events ([ {...Event}, ... ])
 */

export default class EventLittleListPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { Events, elementHeight, upperStyle, type, addClassName } = this.props;
        if (!Events) return <div className='events_little_plashka'>
            <Loader />
        </div>

        const { width, height } = upperStyle;

        /*const style = {
            ...height ? { height } : {}
        }*/

        // const { width, height } = this.props.upperStyle;

        return <div style={{ width, height }} className='events_little_plashka'>
            {Object.keys(Events).map((eventId) => {
                const event = Events[eventId];
                const { Id } = event;
                return <LittleEvent height={elementHeight || 50} key={'elt' + Id} Event={event} type = {type} />
            })}
        </div>
    }

}

// export default withCapoeiraSportState('client')(EventLittleListPlashka);