import React, { PureComponent } from 'react';

import { withGroupState } from 'src/ver2/pages/group/context';
import ResultsMiniComponent from 'src/ver2/components/results/mini';

import Loader from 'src/ver2/components/loader';

import './style.css';

// class GroupStructurePlashka
// classname: group_structure_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class GroupResultsPlashka extends PureComponent {

    groupResultHeight = 52;

    setHeight = () => {
        const { setComponentSelfHeight, plashkaId, GroupResults } = this.props;
        if (!GroupResults || !GroupResults.length) return;

        const height = Math.max(200, 50 + GroupResults.length * (this.groupResultHeight + 10));

        setComponentSelfHeight({ height, plashkaId });
    }

    componentDidMount() {
        this.setHeight();
    }

    componentDidUpdate({ GroupResults }) {

        const prevGroupResultsLength = (GroupResults && GroupResults.length) ? GroupResults.length : 0;
        const GroupResultsLength = (this.props.GroupResults && this.props.GroupResults.length) ? this.props.GroupResults.length : 0;

        if (prevGroupResultsLength !== GroupResultsLength)
            this.setHeight();
    }


    render() {

        const { isGroupResultsReady, upperStyle, GroupResults } = this.props;

        const { width, height } = upperStyle;

        if (!isGroupResultsReady || !GroupResults) return <div style={{ width, height }} className='group_results_plashka'>
            <Loader />
        </div>

        if (!GroupResults || GroupResults.length === 0)
            return <div style={{ width, height }} className='group_results_plashka'>
                Группа не выступала
            </div>

        let nowSeason = '';

        return <div style={{ width, height }} className='group_results_plashka'>
            {GroupResults.map(result => {

                if (nowSeason !== result.Season) {
                    nowSeason = result.Season;
                    return <>
                        <div className='group_results_plashka_season'>{nowSeason}</div>
                        <ResultsMiniComponent width={width} height={this.groupResultHeight} {...result} />
                    </>
                }

                return <ResultsMiniComponent width={width} height={this.groupResultHeight} {...result} />
            })
            }
        </div>
    }
}

export default withGroupState(`

Group,
GroupResults,
isGroupResultsReady,

client,
iamuser,
`)(GroupResultsPlashka)