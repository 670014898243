import React, { PureComponent } from 'react';
import { withPersonState } from 'src/ver2/pages/person/context';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import LittlePerson from 'src/ver2/components/person/little';
import Loader from 'src/ver2/components/loader';
import FightsModal from '../../containers/fightsModal';
import { Modal } from 'antd';

import { orderBy } from 'lodash';

import './style.css';

class PersonEnemysPlashka extends PureComponent {

    state = {
        listModalData: null,
        EnemyId: null,
        lastHeight: 1
    }

    listModalDataChange = (listModalData = true) => this.setState({ listModalData, EnemyId: listModalData ? this.state.EnemyId : null })
    listModalDataWithEnemyChange = (EnemyId) => this.setState({ listModalData: true, EnemyId })
    
    setComponentSelfHeight = ({ height }) => {
        const { setComponentSelfHeight, plashkaId } = this.props;

        if (height === this.state.lastHeight) return;
        this.setState({ lastHeight: height })
        setComponentSelfHeight({ plashkaId, height });

    };

    get enemyHeight () {
        const { isMobile } = this.props;
        if (isMobile) return 50;
        return 65;
    }

    componentDidMount () {
        const { FightsStatistics } = this.props;
        if (FightsStatistics) { 
            const { MyEnemys } = FightsStatistics;
            if (!MyEnemys || !MyEnemys.length) return;
            const height = 75 + Math.min(MyEnemys.length, 10) * this.enemyHeight;
            this.setComponentSelfHeight({ height });
        }
    }

    componentDidUpdate () {
        const { FightsStatistics } = this.props;
        if (FightsStatistics) { 
            const { MyEnemys } = FightsStatistics;
            if (!MyEnemys || !MyEnemys.length) return;
            const height = 75 + Math.min(MyEnemys.length, 10) * this.enemyHeight;
            this.setComponentSelfHeight({ height });
        } 
    }

    render() {

        const { isMobile, FightsStatistics } = this.props;
        const { width, height } = this.props.upperStyle;
        const { listModalData, EnemyId } = this.state;
        const { listModalDataChange } = this;

        if ( !FightsStatistics) {
            return <div style={{ width, height }} className='person_enemys_plashka'>
                <Loader text='Загрузка элементов' />
            </div>
        }

        const { MyEnemys } = FightsStatistics;
        
        if (!MyEnemys || !MyEnemys.length) return null;

        // const blockHeight = isMobile ? 30 : 40;

        console.log ('FightsStatistics', { FightsStatistics });

        return <div style={{ width, height }} className='person_enemys_plashka'>
            { MyEnemys.slice(0, 10).map ( (({ EnemyId, AllCount, WinCount, LoseCount}) => {

                let addedClassname = 'person_enemys_plashka_one_suffix_even';
                if ( WinCount > LoseCount ) addedClassname = 'person_enemys_plashka_one_suffix_win';
                if ( WinCount < LoseCount ) addedClassname = 'person_enemys_plashka_one_suffix_lose';

                return <div onClick={() => {this.listModalDataWithEnemyChange(EnemyId)}} className='person_enemys_plashka_one'>
                    <LittlePerson height={this.enemyHeight} key={'plttt' + EnemyId} personId={EnemyId} 
                    mode = 'suffix_down'
                    Suffix={<span className= {'person_enemys_plashka_one_suffix ' + addedClassname}>{`Боев: ${AllCount} / W: ${WinCount} / L: ${LoseCount}`}</span>} />    
                </div>
            }) )}
            <br/>
            <div className='person_enemys_plashka_bycompetitions' onClick = {listModalDataChange}>по соревнованиям</div>

            { listModalData ? 
                <Modal visible = {listModalData} onCancel={() => listModalDataChange(false)} footer = {null}>
                    <FightsModal EnemyId = {EnemyId}/>
                </Modal>
            : null}

        </div>
    }
}

export default withPersonState(
    `
    FightsStatistics,
    personId,
    client,
    isMobile
    `
)(PersonEnemysPlashka)