import React, { Component } from 'react';
import ButtonPlashka from 'src/ver2/plashki/ui/button';

import DropNCrop from '@synapsestudios/react-drop-n-crop';
import axios from 'axios';

import './style.css';
import '@synapsestudios/react-drop-n-crop/lib/react-drop-n-crop.min.css';

/*

    Этот компонент позволяет загрузить картинку и передать её куда-либо
    imageUrl // ссылка на то, что мы хотим получить

    header - хедр
    recomendations // типа "желательно квадратную картинку такую то такую то"
    upload // АСИНХРОННАЯ функция, которая принимает this.state.result - т.е. преобразованные в байты картинку

*/

export default class ImageUploader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ImageData: {
                result: null,
                filename: null,
                filetype: null,
                src: null,
                error: null,
            },
            thisImageStatus: 'decline', // 'decline', 'accept', 'loading',
            status: 'ok' // 'loading', 'ok'
        };
    }

    componentDidMount() {
        this.checkThisImage();
    }

    onImageChange = ImageData => {
        this.setState({ ImageData });
    };

    sendToServer = () => {
        const { upload } = this.props;
        const { ImageData } = this.state;
        this.setState({ status: 'loading' });

        upload( ImageData.result ).then ( () => {
            this.setState({ status: 'ok' });
        })

    }

    checkThisImage = () => {
        const { imageUrl } = this.props;
        this.setState({ thisImageStatus: 'loading' });

        try {
            axios.get(imageUrl).then((res) => {
                console.log('checkThisImage', res);
                if (res && res.status === 200) {
                    return this.setState({ thisImageStatus: 'accept' });
                }
                this.setState({ thisImageStatus: 'decline' });
            }, (reason) => {
                console.log('checkThisImage error', reason);
                this.setState({ thisImageStatus: 'decline' });
            })
        }
        catch (err) {
            this.setState({ thisImageStatus: 'decline' });
        }
    }

    render() {

        const { header, recomendations } = this.props;
        const { ImageData, status } = this.state;

        return <div className='image_uploader_container'>
            <div className='image_uploader_container_header'>{header}</div>
            <div className='image_uploader_container_title'>Картинка сейчас</div>
            <this.NowImage />
            <div className='image_uploader_container_title'>Рекомендации</div>
            <div className='image_uploader_container_text'>{recomendations}</div>
            <div className='image_uploader_container_title'>Загрузка картинки</div>
            <div className='image_uploader_changer'>
                <DropNCrop maxFileSize={15145728} onChange={this.onImageChange} value={ImageData} />
            </div>
            <ButtonPlashka
                upperStyle={{ width: 100, height: 40 }}
                disabled={!ImageData.result || status === 'loading'}
                disabledDiscription={'Добавьте картинку'}
                onClick={this.sendToServer}
                text={ status === 'loading' ? 'ЗАГРУЖАЕТСЯ' :'ОТПРАВИТЬ'}
            />
        </div>
    }

    NowImage = () => {
        const { imageUrl } = this.props;
        const { thisImageStatus } = this.state;

        if (thisImageStatus === 'decline') {
            return <div className='image_uploader_changer_nowimage'>
                <span>КАРТИНКА НЕ ЗАГРУЖЕНА</span>
            </div>
        }
        if (thisImageStatus === 'loading') {
            return <div className='image_uploader_changer_nowimage'>
                <span>КАРТИНКА ЗАГРУЖАЕТСЯ</span>
            </div>
        }
        if (thisImageStatus === 'accept') {
            return <div className='image_uploader_changer_nowimage'>
                <img src={imageUrl} />
            </div>
        }
    }

}