import React, { Component } from 'react';
import { Input, Icon } from 'antd';

export class AdderAdmission extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState();
    }

    initialState = () => {
        return {
            title: ''
        }
    }

    titleChange = (e) => {
        this.setState({ title: e.target.value })
    }

    onAddButtonClick = () => {
        const { title } = this.state;
        const { onAddData } = this.props;
        onAddData({ title });
        this.setState(this.initialState())
    }

    render() {

        const { title } = this.state;

        return <div className='data_changer_aps_main'>
            <Input
                value={title}
                onChange={this.titleChange}
                suffix={(title && title !== '') ? <Icon className='global-tooltip' data-tooltip={'добавить'} type='plus' onClick={this.onAddButtonClick} /> : <div />}
            />
        </div>
    }

}