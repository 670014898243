import { groupBy } from 'lodash';

export const kostylDouble = (Id) => (Id === 22 || Id === 23 || Id === 229 || Id === 230 || Id === 383 || Id === 384) ? true : false;

export const couplesInCircle = (circle_id) => {
    switch (circle_id) {
        case 1: return 1;
        case 2: return 2;
        case 3: return 4;
        case 4: return 8;
        case 5: return 16;
        case 6: return 32;
        case 7: return 64;
        default: return 1;
    }
}

export const couplesInCircleCategory = (competitorsNumber) => {
    switch (competitorsNumber) {
        case 1: return 1;
        case 2: return 1;
        case 3: return 3;
        case 4: return 6;
        case 5: return 10;
        case 6: return 15;
        case 7: return 25;
        default: return 1;
    }
}

export const coupleNameByCircle = (Circle_id, Couple_id, IsCircled, IsDouble, ElderCircle) => {
    if (IsCircled) return ['КРУГ'];

    if (!IsDouble) {
        if (Circle_id === 1) return ['ФИНАЛ'];
        if (Circle_id === 2) return ['ПОЛУ', 'ФИНАЛ'];
        return [
            `1 / ${couplesInCircle(Circle_id)}`,
            'ФИНАЛА'
        ]
    }

    // ЕСЛИ ДАБЛ И ЭТО НИЗ
    if (Circle_id > ElderCircle) {

        let NowPlace = 0;

        if (Couple_id > 0) NowPlace = 1;
        if (Couple_id > (0 + 1)) NowPlace = 2;
        if (Couple_id > (0 + 2 + 4)) NowPlace = 4;
        if (Couple_id > (0 + 2 + 4 + 8)) NowPlace = 8;
        if (Couple_id > (0 + 2 + 4 + 8 + 16)) NowPlace = 16;
        if (Couple_id > (0 + 2 + 4 + 8 + 16 + 32)) NowPlace = 32;


        if (Couple_id === 0) return ['ГРАНД', 'ФИНАЛ'];
        if (NowPlace === 1) return ['ФИНАЛ', 'НИЖН.'];
        return [
            `1/${NowPlace}`,
            'НИЖН.'
        ]
    }

    // ЕСЛИ ЭТО ВЕРХНЯЯ СЕТКА
    if (Circle_id === 1) return ['ФИНАЛ', 'ВЕРХ.'];
    return [
        `1/${couplesInCircle(Circle_id)}`,
        'ВЕРХ.'
    ]
}


// в чём тут фишка - в double-el вся нижняя сетка пишется в некий круг, который на 1 старше ElderCircle
// т.к. туда как раз помещается всё что нам нужно
// и его хистори делется на "блоки", среди которых и происходит перекидка участников
// блок идёт с конца (позиция 0 - это финал) и состоит из двух последовательных "кругов", левого и правого
// с одинаковым количеством пар. В правый падают люди с верхней сетки

// size - это количество всего пар в блоке, и в левой и в правой его части

export const getBlockIndexAndSizeByCircle = (circle_id) => {
    switch (circle_id) {
        case 1: return { index: 1, size: 2 };
        case 2: return { index: 3, size: 4 };
        case 3: return { index: 7, size: 8 };
        case 4: return { index: 15, size: 16 };
        case 5: return { index: 31, size: 32 };
        case 6: return { index: 63, size: 64 };
        case 7: return { index: 127, size: 128 };
        default: return { index: 1, size: 2 };
    }
}

export const getElderCircleByCompetitorsNumber = (competitorsNumber) => {
    // сначала опредялем старший круг
    let elderCircle = 1;
    if (competitorsNumber > 2) elderCircle = 2;
    if (competitorsNumber > 4) elderCircle = 3;
    if (competitorsNumber > 8) elderCircle = 4;
    if (competitorsNumber > 16) elderCircle = 5;
    if (competitorsNumber > 32) elderCircle = 6;
    if (competitorsNumber > 64) elderCircle = 7;
    return elderCircle;
}


// OLYMPIC OR DOUBLE ////////////////////////////
// добавить пустые хистори, если они не пришли из БД
export const addBlankNotCircleHistory = ({ category, story_ }) => {

    let story = [];
    // это для ДАБЛ ЭЛИМЕНЕЙШЕНА !
    const realElderCircle = (kostylDouble(category.Id) || category.IsDouble) ? category.ElderCircle + 1 : category.ElderCircle;

    for (let i = 0; i < realElderCircle; i++) {
        for (let j = 0; j < couplesInCircle(i + 1); j++) {

            const G = story_ ? story_.find(x => x.Circle_id === i + 1 && x.Couple_id === j) : undefined;

            if (G) {
                story.push(
                    {
                        StoryId: G.Id,
                        Circle_id: i + 1,
                        Couple_id: j,
                        WinTo: G.WinTo,
                        VideoId: G.VideoId ? G.VideoId : 0,
                        IsInquiry: G.IsInquiry ? G.IsInquiry : 0,
                        IsVideo1: G.IsVideo1 ? G.IsVideo1 : null,
                        IsVideo2: G.IsVideo2 ? G.IsVideo2 : null,
                        IsScores1: G.IsScores1 ? G.IsScores1 : null,
                        IsScores2: G.IsScores2 ? G.IsScores2 : null
                    }
                )

            } else
                story.push(
                    {
                        StoryId: 0,
                        Circle_id: i + 1,
                        Couple_id: j,
                        WinTo: 0,
                        VideoId: 0,
                        IsInquiry: 0,
                        IsVideo1: null,
                        IsVideo2: null,
                        IsScores1: null,
                        IsScores2: null
                    }
                )
        }
    }

    return story;
}

// в качестве вход у нас есть жребии компетиторов, и истории, кто выиграл в каких парах, красный или синий
// наша цель - в story расставить Red_Id и Blue_Id - это уже конкретно ЖРЕБИИ тех, кто в данной паре
// мы сначала раскидываем компетиторов по ElderCircle, а потом, начиная с конца, начинаем раскидывать их по кругам, вплоть до финала
export const setCompetitorsThrueBrikets = ({ competitors, category, story }) => {

    const couplesInEC = couplesInCircle(category.ElderCircle);
    const competitorsShouldBe = couplesInEC * 2;

    // добиваем пустых компетиторов

    const competitors_ = groupBy(competitors, 'Jrebiy');

    let NoJrebiys = [];
    let NoJrebiysObject = {}; // это нужно для понимания закрытия пар
    for (let i = 1; i <= competitorsShouldBe; i++) {
        if (!competitors_[i]) {
            NoJrebiys.push(i);
            NoJrebiysObject[i] = true;
        }
    }

    let CategoryIsDone = false;

    if (competitors.find(x => x.Place !== 0)) {
        CategoryIsDone = true;
    }

    const CouplesShouldBe = category.IsDouble ? (competitors.length - 1) * 2 : competitors.length - 1;
    let CouplesClosed = 0;

    let LastJrebiyIndex = 0;

    for (let i = competitors.length; i < competitorsShouldBe; i++) {
        competitors.push({
            Id: 0,
            Name: "",
            Surname: "",
            GroupIamInId: 0,
            SchoolId: 0,
            Category_id: category.Id,
            Jrebiy: NoJrebiys[LastJrebiyIndex++],
            Place: 0
        });
    }


    // const competitors__ = groupBy(competitors, 'Jrebiy');

    // супер-важно, распределяем компетиторов по их "местам", исходя из жребия
    for (let k = 0; k < couplesInEC; k++) {
        let Couple = story.find((x => (x.Circle_id === category.ElderCircle) && (x.Couple_id === k)))
        Couple.Red_Id = k + 1;
        Couple.Blue_Id = k + 1 + couplesInEC;
    }

    const DoubleCircleId = category.IsDouble ? (category.ElderCircle + 1) : null;
    let isReverse = 2;

    // а теперь начиная с дальнего круга, пишем кто должен переместиться в какой круг дальше
    if (category.ElderCircle > 1) {
        for (let NowCircle = category.ElderCircle; NowCircle > 1; NowCircle--) {

            let coupleTo = 0; // это в какую пару следующего круга мы будем кидать победителя.
            let coupleToHelper = 0; // а это по сути - будем кидать на место красного или на место синего

            // const couplesInNC = couplesInCircle(NowCircle);
            // const competitorsShouldBe = couplesInEC * 2;
            const { index, size } = getBlockIndexAndSizeByCircle(NowCircle);

            for (let k = 0; k < couplesInCircle(NowCircle); k++) {
                let Couple = story.find((x => (x.Circle_id === NowCircle) && (x.Couple_id === k)))

                if (Couple.WinTo > 0) {

                    // проверяем прогресс
                    if (Couple.Red_Id && Couple.Blue_Id && (!NoJrebiysObject[Couple.Red_Id] && !NoJrebiysObject[Couple.Blue_Id])) {
                        CouplesClosed++;
                    }

                    // а тут надо понимать - у нас круговая или нет система

                    let Winner = Couple.Red_Id;
                    let Looser = Couple.Blue_Id;
                    if (Couple.WinTo === 2) {
                        Winner = Couple.Blue_Id;
                        Looser = Couple.Red_Id;
                    }

                    // надо определить в какую пару мы кидаем своего победителя и на какое место
                    let CCoupleTo = story.find((x => (x.Circle_id === (NowCircle - 1)) && (x.Couple_id === coupleTo)))
                    if (coupleToHelper === 0) {
                        CCoupleTo.Red_Id = Winner;
                    } else
                        CCoupleTo.Blue_Id = Winner;

                    //а теперь, если мы дабл-надо понять куда идёт проигравший
                    if (DoubleCircleId) {
                        // для старшего круга - отдельное исключение
                        if (NowCircle === category.ElderCircle) {

                            const { index, size } = getBlockIndexAndSizeByCircle(NowCircle - 1);
                            const indexCouple = index + size / 2 + coupleTo;

                            let CCoupleTo = story.find((x => (x.Circle_id === (DoubleCircleId)) && (x.Couple_id === indexCouple)))
                            if (coupleToHelper === 0) {
                                CCoupleTo.Red_Id = Looser;
                            } else
                                CCoupleTo.Blue_Id = Looser;
                        } else {
                            // а тут для других кругов
                            const indexCouple = (isReverse === 1) ? (index + size / 2 - 1 - k) : index + k;
                            let CCoupleTo = story.find((x => (x.Circle_id === (DoubleCircleId)) && (x.Couple_id === indexCouple)))
                            CCoupleTo.Red_Id = Looser;
                        }
                    }

                }
                // один раз в две пары меняем пару
                coupleToHelper++;
                if (coupleToHelper === 2) {
                    coupleTo++;
                    coupleToHelper = 0;
                }
            }
            isReverse--;
            if (isReverse <= 0) isReverse = 1000;
        }

        // если круговая, то всё хитрее
        if (DoubleCircleId) {
            // во-первых, мы смотрим последнюю пару и раскидываем победителя и проигравшего
            let Couple = story.find((x => (x.Circle_id === 1) && (x.Couple_id === 0)))
            if (Couple.WinTo > 0) {
                let Winner = Couple.Red_Id;
                let Looser = Couple.Blue_Id;
                if (Couple.WinTo === 2) {
                    Winner = Couple.Blue_Id;
                    Looser = Couple.Red_Id;
                }
                let CCoupleToFinal = story.find((x => (x.Circle_id === DoubleCircleId) && (x.Couple_id === 0)))
                CCoupleToFinal.Red_Id = Winner;
                let CCoupleToPrev = story.find((x => (x.Circle_id === DoubleCircleId) && (x.Couple_id === 1)))
                CCoupleToPrev.Red_Id = Looser;
            }

            // а дальше начиная с конца мы заполняем нижнюю сетку
            const separations = (category.ElderCircle * 2) - 1; // сколько разных кругов у нас будет в дабле

            let CircleFromId = category.ElderCircle - 1;
            let isEven = -1;

            for (let i = separations - 1; i >= 0; i--) {

                const separationIndex = i;

                if (separationIndex === 0) {
                    // ничего не делаем - это просто тот, кто победил в финале, он молодец

                    let Couple = story.find((x => (x.Circle_id === DoubleCircleId) && (x.Couple_id === 0)))
                    if (Couple.WinTo > 0) {
                        // проверяем прогресс
                        if (Couple.Red_Id && Couple.Blue_Id && (!NoJrebiysObject[Couple.Red_Id] && !NoJrebiysObject[Couple.Blue_Id])) {
                            CouplesClosed++;
                        }
                    }
                    continue;
                }

                if (separationIndex === 1) {

                    let Couple = story.find((x => (x.Circle_id === DoubleCircleId) && (x.Couple_id === 1)))
                    if (Couple.WinTo > 0) {
                        let Winner = Couple.Red_Id;
                        if (Couple.WinTo === 2) {
                            Winner = Couple.Blue_Id;
                        }

                        // проверяем прогресс
                        if (Couple.Red_Id && Couple.Blue_Id && (!NoJrebiysObject[Couple.Red_Id] && !NoJrebiysObject[Couple.Blue_Id])) {
                            CouplesClosed++;
                        }

                        let CCoupleToThis = story.find((x => (x.Circle_id === DoubleCircleId) && (x.Couple_id === 0)))
                        if (CCoupleToThis) CCoupleToThis.Blue_Id = Winner;
                    }
                    continue;
                }

                // надо понять - это левая или правая часть
                const isRight = separationIndex % 2; // если есть остаток от деления, то это правая часть

                const { index, size } = getBlockIndexAndSizeByCircle(CircleFromId);
                const couplesCount = size / 2;
                const startIndex = isRight ? index : index + couplesCount;

                let coupleTo = 0; // это в какую пару следующего круга мы будем кидать победителя.
                let coupleToHelper = 0; // а это по сути - будем кидать на место красного или на место синего

                for (let j = startIndex; j < startIndex + couplesCount; j++) {
                    // теперь вопрос - куда их протащить                        
                    let Couple = story.find((x => (x.Circle_id === DoubleCircleId) && (x.Couple_id === j)))

                    if (!isRight) {
                        if (Couple.WinTo > 0) {
                            let Winner = Couple.Red_Id;
                            if (Couple.WinTo === 2) {
                                Winner = Couple.Blue_Id;
                            }

                            // проверяем прогресс
                            if (Couple.Red_Id && Couple.Blue_Id && (!NoJrebiysObject[Couple.Red_Id] && !NoJrebiysObject[Couple.Blue_Id])) {
                                CouplesClosed++;
                            }

                            let CCoupleToThis = story.find((x => (x.Circle_id === DoubleCircleId) && (x.Couple_id === j - couplesCount)))
                            if (CCoupleToThis) CCoupleToThis.Blue_Id = Winner;
                            // CCoupleToFinal.Blue_Id = Winner;
                        }
                    } else {
                        // иначе нам их надо прокинуть в следующий круг, но как с обычной сеткой
                        if (Couple.WinTo > 0) {
                            let Winner = Couple.Red_Id;
                            if (Couple.WinTo === 2) {
                                Winner = Couple.Blue_Id;
                            }

                            // проверяем прогресс
                            if (Couple.Red_Id && Couple.Blue_Id && (!NoJrebiysObject[Couple.Red_Id] && !NoJrebiysObject[Couple.Blue_Id])) {
                                CouplesClosed++;
                            }

                            // нам надо взять начало прыдыдущего индекса
                            const prevIndexAndSize = getBlockIndexAndSizeByCircle(CircleFromId - 1);
                            const startPrevIndex = prevIndexAndSize.index + prevIndexAndSize.size / 2;

                            let CCoupleToThis = story.find((x => (x.Circle_id === DoubleCircleId) && (x.Couple_id === (startPrevIndex + coupleTo))))
                            if (CCoupleToThis)
                                if (coupleToHelper === 0) {
                                    CCoupleToThis.Red_Id = Winner;
                                } else
                                    CCoupleToThis.Blue_Id = Winner;
                        }
                    }
                    // один раз в две пары меняем пару
                    coupleToHelper++;
                    if (coupleToHelper === 2) {
                        coupleTo++;
                        coupleToHelper = 0;
                    }
                }


                isEven++;
                if (isEven === 1) {
                    CircleFromId--;
                    isEven = -1;
                }
            }

        }

        // если старые костыльные круговые
        // ебаноя заглушище :D
        if (kostylDouble(category.Id)) {

            let Couple1 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 0)));
            Couple1.Red_Id = findCompetitorOf(story, 4, 0, false);
            Couple1.Blue_Id = findCompetitorOf(story, 4, 1, false);

            let Couple2 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 1)));
            Couple2.Red_Id = findCompetitorOf(story, 4, 2, false);
            Couple2.Blue_Id = findCompetitorOf(story, 4, 3, false);

            let Couple3 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 2)));
            Couple3.Red_Id = findCompetitorOf(story, 4, 4, false);
            Couple3.Blue_Id = findCompetitorOf(story, 4, 5, false);

            let Couple4 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 3)));
            Couple4.Red_Id = findCompetitorOf(story, 4, 6, false);
            Couple4.Blue_Id = findCompetitorOf(story, 4, 7, false);





            let Couple5 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 4)));
            Couple5.Red_Id = findCompetitorOf(story, 3, 1, false);
            Couple5.Blue_Id = findCompetitorOf(story, 5, 0, true);

            let Couple6 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 5)));
            Couple6.Red_Id = findCompetitorOf(story, 3, 0, false);
            Couple6.Blue_Id = findCompetitorOf(story, 5, 1, true);

            let Couple7 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 6)));
            Couple7.Red_Id = findCompetitorOf(story, 3, 3, false);
            Couple7.Blue_Id = findCompetitorOf(story, 5, 2, true);

            let Couple8 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 7)));
            Couple8.Red_Id = findCompetitorOf(story, 3, 2, false);
            Couple8.Blue_Id = findCompetitorOf(story, 5, 3, true);




            let Couple9 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 8)));
            Couple9.Red_Id = findCompetitorOf(story, 5, 4, true);
            Couple9.Blue_Id = findCompetitorOf(story, 5, 5, true);

            let Couple10 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 9)));
            Couple10.Red_Id = findCompetitorOf(story, 5, 6, true);
            Couple10.Blue_Id = findCompetitorOf(story, 5, 7, true);




            let Couple11 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 10)));
            Couple11.Red_Id = findCompetitorOf(story, 2, 1, false);
            Couple11.Blue_Id = findCompetitorOf(story, 5, 8, true);

            let Couple12 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 11)));
            Couple12.Red_Id = findCompetitorOf(story, 2, 0, false);
            Couple12.Blue_Id = findCompetitorOf(story, 5, 9, true);




            let Couple13 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 12)));
            Couple13.Red_Id = findCompetitorOf(story, 2, 0, true);
            Couple13.Blue_Id = findCompetitorOf(story, 5, 10, true);

            let Couple14 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 13)));
            Couple14.Red_Id = findCompetitorOf(story, 2, 1, true);
            Couple14.Blue_Id = findCompetitorOf(story, 5, 11, true);




            let Couple15 = story.find((x => (x.Circle_id === 5) && (x.Couple_id === 14)));
            Couple15.Red_Id = findCompetitorOf(story, 5, 12, true);
            Couple15.Blue_Id = findCompetitorOf(story, 5, 13, true);

        }

        let Couple = story.find((x => (x.Circle_id === 1) && (x.Couple_id === 0)));
        if (Couple && Couple.WinTo) {
            CouplesClosed++;
        }


    } else {
        // если категория из одного круга

        let Couple = story.find((x => (x.Circle_id === 1) && (x.Couple_id === 0)));
        if (Couple && Couple.WinTo) {
            CouplesClosed = 1;
        }
    }

    return {
        competitors,
        story,
        CategoryIsDone,
        CouplesClosed,
        CouplesShouldBe
    }

}

const findCompetitorOf = (story, Circle_id, Couple_id, isWinner) => {

    let Couple = findCircleBy(story, Circle_id, Couple_id);

    if (Couple.WinTo > 0) {
        if (Couple.WinTo === 1 && isWinner) {
            return Couple.Red_Id;
        }
        if (Couple.WinTo === 2 && isWinner) {
            return Couple.Blue_Id;
        }
        if (Couple.WinTo === 1 && !isWinner) {
            return Couple.Blue_Id;
        }
        if (Couple.WinTo === 2 && !isWinner) {
            return Couple.Red_Id;
        }
    }


}


const findCircleBy = (story, Circle_id, Couple_id) => {
    return story.find((x => (x.Circle_id === Circle_id) && (x.Couple_id === Couple_id)));
}



// CIRCLE ///////////////////////////////////
// добавить пустые хистори, если они не пришли из БД
export const addBlankCircleHistory = ({ story_, competitors }) => {
    // сначала всем расписываем очки исходя из жребия

    let story = [];
    let scores = {};
    let wins = {};


    let CategoryIsDone = false;
    let CouplesClosed = 0;
    let CouplesShouldBe = couplesInCircleCategory(competitors.length)

    for (let i = 0; i < competitors.length; i++) {
        scores[competitors[i].Jrebiy] = 0;
        wins[competitors[i].Jrebiy] = 0;
        if (competitors[i].Place > 0) CategoryIsDone = true;
    }

    for (let i = 1; i < competitors.length; i++) {
        for (let j = 0; j < i; j++) {

            const findStory = story_.find(x => x.Circle_id === i && x.Couple_id === j);
            if (findStory) {
                story.push(
                    {
                        StoryId: findStory.Id,
                        Circle_id: findStory.Circle_id,
                        Couple_id: findStory.Couple_id,
                        WinTo: findStory.WinTo,
                        VideoId: findStory.VideoId ? findStory.VideoId : 0,
                        IsInquiry: findStory.IsInquiry ? findStory.IsInquiry : 0,
                        Red_Id: findStory.Couple_id + 1, // Red_Id и Blue_Id - Это ЖРЕБИИ УЧАСТНИКОВ, синего и красного
                        Blue_Id: findStory.Circle_id + 1
                    }
                )
                if (findStory.WinTo > 0) {
                    CouplesClosed++;
                }
            } else

                story.push(
                    {
                        StoryId: 0,
                        Circle_id: i,
                        Couple_id: j,
                        WinTo: 0,
                        VideoId: 0,
                        IsInquiry: 0,
                        Red_Id: j + 1,
                        Blue_Id: i + 1
                    }
                );

            const addedStory = story[story.length - 1];

            if (addedStory.WinTo === 1) {
                scores[addedStory.Red_Id] += 3;
                wins[addedStory.Red_Id] += 1;
            }
            if (addedStory.WinTo === 2) {
                scores[addedStory.Blue_Id] += 3;
                wins[addedStory.Blue_Id] += 1;
            }
            if (addedStory.WinTo === 3) {
                scores[addedStory.Red_Id] += 2;
                scores[addedStory.Blue_Id] += 1;
                wins[addedStory.Red_Id] += 1;
            }
            if (addedStory.WinTo === 4) {
                scores[addedStory.Blue_Id] += 2;
                scores[addedStory.Red_Id] += 1;
                wins[addedStory.Blue_Id] += 1;
            }


        }
    }

    return {
        story,
        scores,
        wins,
        CategoryIsDone,
        CouplesClosed,
        CouplesShouldBe
    }
}



// ТЕХНИЧЕСКАЯ ВСЯКАЯ ХУЕТА

// id кто проиграл в данной паре
export const coupleLooserId = (oneStory, competitors) => {


    if (oneStory.WinTo === 0) return 0;

    if (oneStory.WinTo === 1 && oneStory.Blue_Id > 0) {
        const competitor = competitors.find(x => x.Jrebiy === oneStory.Blue_Id);
        if (competitor) return competitor.Id;
    }

    if (oneStory.WinTo === 2 && oneStory.Red_Id > 0) {
        const competitor = competitors.find(x => x.Jrebiy === oneStory.Red_Id);
        if (competitor) return competitor.Id;
    }

    return 0;

}

// id кто победил в данной паре
export const coupleWinnerId = (oneStory, competitors) => {

    if (oneStory.WinTo === 0) return 0;

    if (oneStory.WinTo === 1 && oneStory.Red_Id > 0) {
        const competitor = competitors.find(x => x.Jrebiy === oneStory.Red_Id);
        if (competitor) return competitor.Id;
    }

    if (oneStory.WinTo === 2 && oneStory.Blue_Id > 0) {
        const competitor = competitors.find(x => x.Jrebiy === oneStory.Blue_Id);
        if (competitor) return competitor.Id;
    }

    return 0;

}


export const getCategoryDuration = ({ count, IsCircled, MinutesPerCouple = 2 }) => {

    if (IsCircled) {
        switch (count) {
            case 0: return 3;
            case 1: return 3;
            case 2: return Math.round(3 * MinutesPerCouple);
            case 3: return Math.round(3 * MinutesPerCouple);
            case 4: return Math.round(6 * MinutesPerCouple);
            case 5: return Math.round(10 * MinutesPerCouple);
            case 6: return Math.round(6 * MinutesPerCouple); // 3 + 3
            case 7: return Math.round(9 * MinutesPerCouple); // 6 + 3
            case 8: return Math.round(12 * MinutesPerCouple); // 6 + 6
            case 9: return Math.round(9 * MinutesPerCouple); // 3 + 3 + 3
            case 10: return Math.round(12 * MinutesPerCouple); // 6 + 3 + 3
            case 11: return Math.round(15 * MinutesPerCouple); // 6 + 6 + 3
            case 12: return Math.round(18 * MinutesPerCouple); // 6 + 6 + 6
            case 13: return Math.round(15 * MinutesPerCouple); // 6 + 3 + 3 + 3
            case 14: return Math.round(18 * MinutesPerCouple); // 6 + 6 + 3 + 3
            case 15: return Math.round(21 * MinutesPerCouple); // 6 + 6 + 6 + 3
            default: return Math.round(22 * MinutesPerCouple);
        }
    }

    if (!IsCircled) {
        return Math.round(count * MinutesPerCouple);
    }

    return 10;
}

export const getDurationAsString = (duration) => {

    const ostatokMinutes = duration % 60;
    const hours = (duration - ostatokMinutes) / 60;

    if (hours === 0) return `${ostatokMinutes}`;

    return `${hours}:${ostatokMinutes < 10 ? '0' + ostatokMinutes : ostatokMinutes}`

}

export const getMinutesByString = (time) => {

    // time = '00:00'

    if (!time || time === '') return 0;

    const time_ar = time.split(':');
    const time_hours = Number(time_ar[0]) || 0;
    const time_minutes = Number(time_ar[1]) || 0;

    return time_hours * 60 + time_minutes;

}
