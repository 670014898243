/* В этой штуке мы делаем функцию, которая говорит о том, заполнены ли данные у данной персоны */

export const NO_AVATAR = 'NO_AVATAR';
export const NO_PATRONYMIC = 'NO_PATRONYMIC';
export const NO_TEL = 'NO_TEL';

export const personDataAttentions = ( Person ) => {
    if (!Person || !Person.Id) {
        return {
            checked: false,
            attentions: null
        }
    }

    let attentions = [];
    const { Patronymic, Tel } = Person;
    if (!Patronymic || Patronymic.length < 3) attentions.push(NO_PATRONYMIC);
    if (!Tel || Tel.length < 3) attentions.push(NO_TEL);

    return {
        checked: true,
        attentions
    }


}