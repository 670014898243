import React, { Component } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import './component.css';
import { getSequenceToFixed, getCardKey, getSequenceName } from '../../helpers';
import { Popover } from 'antd';

/* Это штука, которую мы показываем в плашке раунда 
   Т.е. в ней рисуются иконки типа any, kick, иконки элементов и т.д.
*/

export class SoloMiniSequence extends Component {

  constructor(props) {
    super(props);

    // все что нам нужно делаем тут - раскладываем связку и рисуем
    // elements_only говорит о том, что мы сюда загоняем элементы выполненной связки (которые кто-то сделал, и там будет не el_armada/ty_strike, а armada/hole)

    const { Sequence, Options, elements_only } = props;

    const fixed = getSequenceToFixed({ Sequence, Options, elements_only })

    this.state = {
      fixed
    }
  }

  render() {

    const { fixed } = this.state;
    const { client, roundRules, EndingText } = this.props;

    // если шаффл - мы смотрим сколько всего техник, рисуем карточки, и обводим столько штук, сколько надо
    if (EndingText === 'sp_shuffle' && roundRules.OnlyElements) {

      const elements_keys = Object.keys(roundRules.OnlyElements);
      let elements = [];
      for (let i = 0; i < elements_keys.length; i++) {
        for (let j = 0; j < roundRules.OnlyElements[elements_keys[i]].length; j++) {
          const element = roundRules.OnlyElements[elements_keys[i]][j];
          elements.push({ element })
        }
      }

      return (
        <Popover placement={"left"} content={this.SequencePoopoverShuffle({ elements })}>
          <div className='solo-minisequencte-div'>

            {elements.map((card, index) => {

              let card_key = getCardKey(card);

              let className = 'solo-minisequencte-div-card';
              if (index < fixed.length) {
                className += ' solo-minisequence-shuffle'
              }

              return <div className={className} key={`solo-miniseq-img-${index}`} >
                <img key={`solo-miniseq-img-${index}`} src={client.getTechnicLogoUrl(card_key)} />
              </div>
            })}

          </div>
        </Popover>
      );

    }

    return (
      <Popover placement={"left"} content={this.SequencePoopover()}>
        <div className='solo-minisequencte-div'>

          {fixed.map((card, index) => {

            let card_key = getCardKey(card);

            return <div className='solo-minisequencte-div-card' key={`solo-miniseq-img-${index}`} >
              <img key={`solo-miniseq-img-${index}`} src={client.getTechnicLogoUrl(card_key)} />
            </div>
          })}

        </div>
      </Popover>
    );
  }

  SequencePoopover = () => {

    const { fixed } = this.state;
    const { client } = this.props;

    const seq_name = getSequenceName({ fixed, client });

    return <div className='solo-minisequencte-div-seq-name'>
      {seq_name.map((item, index) => {
        return [
          item,
          index < seq_name.length - 1 ? ' / ' : ''
        ]
      })}
    </div>

  }

  SequencePoopoverShuffle = ({ elements }) => {

    const { fixed } = this.state;
    const { client } = this.props;

    const seq_name = getSequenceName({ fixed: elements, client });

    return <div className='solo-minisequencte-div-seq-name'>
      Сделать связку из {fixed.length} элементов. Доступны: <br />
      {seq_name.map((item, index) => {
        return [
          item,
          index < seq_name.length - 1 ? ' / ' : ''
        ]
      })}
    </div>

  }
}

const ElementCardPoopover = ({ element, isMobile, client }) => {

  const classNamePoopover = isMobile ? 'element-card-poopover-mobile' : 'element-card-poopover';

  return <div className={classNamePoopover}>

    <div style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }} >
      <video
        playsInline="true"
        id="my-player"
        class="video-js"
        preload="auto"
        autoPlay="true"
        muted="true"
        poster={`https://capoeirasport.com/graphics/video_technics/logo.png`}
        loop="true"
        width='100%'
        controls=""
        data-setup="{}">
        <source
          src={`https://capoeirasport.com/graphics/video_technics/${element}.mp4`}
          type="video/mp4" />
      </video>
      <div>
        {client.elements_as_object[element] && client.elements_as_object[element].title}
      </div>
    </div>

  </div>
}

const MiniSequenceOnlyTechnicsWrapped = ({ client, isMobile, height, elements = '', noPoopup = false }) => {
  return <div style={{ height }} className='mini_sequence_only_tech'>
    {elements.split('*').map(element => {

      if (noPoopup) return <div key={`msqo_${element}`} className='mini_sequence_only_tech_container'>
        <img key={`solo-miniseqonlytech-img-${element}`} src={client.getTechnicLogoUrl(element)} />
      </div>

      return <Popover key={`popover_msqo_${element}`} placement={"top"} content={ElementCardPoopover({ isMobile, element, client })}>
        <div className='mini_sequence_only_tech_container'>
          <img key={`solo-miniseqonlytech-img-${element}`} src={client.getTechnicLogoUrl(element)} />
        </div>
      </Popover>
    })}
  </div>
}
export const MiniSequenceOnlyTechnics = withCapoeiraSportState('client, iamuser')(MiniSequenceOnlyTechnicsWrapped);