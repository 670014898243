import React, { Component } from 'react';
import * as THREE from 'three';
import { createMedal, createBoxMedal } from './objects'
import { withCapoeiraSportState } from 'src/ver2/context';
import './component.css';


class Shine3D extends Component {


  /*
       props:
          Id = {goId}
          drawWidth = {widthCome}
          develop = {develop}
  */

  tTime = 0;

  componentDidMount() {

    this.canvas = document.getElementById(this.myCanvasId);

    this.prepareThree();
    this.three_render();

    /* СИСТЕМА ДВИЖЕНИЯ */
    this.interval = setInterval(this.rotate, 10);

  }

  prepareThree = () => {

    const { drawWidth, shape,
      // develop 
    } = this.props;

    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, alpha: true });

    const aspect = 1;

    this.renderer.setSize(drawWidth * 2, drawWidth * 2, false);

    // this.renderer.setPixelRatio(window.devicePixelRatio * 3);

    const fov = 60;
    //const aspect = 2;  // значение для canvas по умолчанию
    const near = 0.1;
    const far = 25;

    this.three_camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    this.three_camera.position.z = 9;

    this.three_scene = new THREE.Scene();


    this.three_light = new THREE.DirectionalLight(this.lightColor(), 0.9);
    this.three_light.position.set(0, 1, 2);

    this.three_light_2 = new THREE.DirectionalLight(0xFFFFFF, 0.7);
    this.three_light_2.position.set(0, 0, 4);

    this.three_light_3 = new THREE.SpotLight(0x0000FF, 1);
    this.three_light_3.position.set(0, 0, 1);

    this.three_scene.add(this.three_light);
    this.three_scene.add(this.three_light_2);
    this.three_scene.add(this.three_light_3);

    this.shines = [];

    // this.golden_medal = createBoxMedal(
    //   develop ?
    //     new THREE.TextureLoader().load('/../images/ver2/textures/' + this.materialTexture())
    //     :
    //     new THREE.TextureLoader().load('https://capoeirasport.com/graphics/' + this.materialTexture()),
    //   this.props.bg || 'silver'
    // )

    // this.golden_medal.position.z = 4;
    // this.golden_medal.position.x = 0;

    // this.three_scene.add(this.golden_medal);

    this.addShine(1, 0.005, 22, 1000, 1.2, 1.0, 0.2, 0.9, 1);
    this.addShine(3, 0.007, 2, 1000, 1.2, 1.0, 0.2, 0.9, 2);

  }

  addShine = (posAngle, speed, LifeTime, TimeShift, AngleRange, ShineKef, RangeMin, RangeMax, textureType) => {

    const develop = true;

    this.shines.push({

      item: createBoxMedal(
        develop ?
          new THREE.TextureLoader().load('/../images/ver2/textures/' + this.materialTexture(textureType))
          :
          new THREE.TextureLoader().load('https://capoeirasport.com/graphics/' + this.materialTexture(textureType))
        ,
        { TimeShift, AngleRange, ShineKef, RangeMin, RangeMax, LifeTime }
      ),
      posAngle,
      speed,
      LifeTime

    }
    )

    this.shines[this.shines.length - 1].item.position.z = 4;
    this.shines[this.shines.length - 1].item.position.x = 0;

    this.shines[this.shines.length - 1].item.rotation.z = posAngle;

    this.three_scene.add(this.shines[this.shines.length - 1].item);
  }

  removeShine = (index) => {
    this.three_scene.remove(this.shines[index].item);
    this.shines.splice(index, 1);
  }

  materialTexture = (bg) => {

    const { Place } = this.props;

    if (Place === 1) {
      if (bg === 1) return 'gold5.jpg'
      if (bg === 2) return 'silver.png'
      if (bg === 3) return 'shine1.jpg'
    }

    if (Place === 2) {
      if (bg === 1) return 'silver.png'
      if (bg === 2) return 'shine1.jpg'
      if (bg === 3) return 'shine2.jpg'
    }

    if (Place === 3) {
      if (bg === 1) return 'gold4.jpg'
      if (bg === 2) return 'silver.png'
      if (bg === 3) return 'gold5.jpg'
    }

    if (Place === 4) {
      if (bg === 1) return 'shine2.jpg'
      if (bg === 2) return 'shine2.jpg'
      if (bg === 3) return 'shine1.jpg'
    }

    if (Place === 5) {
      if (bg === 1) return 'green1.jpg'
      if (bg === 2) return 'green2.jpg'
      if (bg === 3) return 'green1.jpg'
    }

    return 'shine1.jpg'
  }

  lightColor = () => {
    return '#ffffff';
  }

  rotate = () => {
    // if (!this.golden_medal) return;

    if (!this.three_camera) return;

    // this.golden_medal.children[0].material.uniforms.Time.value = (this.tTime++) * 0.03;

    if (!this.shines) return;

    for (let i = 0; i < this.shines.length; i++) {
      this.shines[i].item.children[0].material.uniforms.Time.value += 0.03;
      this.shines[i].item.children[0].rotation.z += this.shines[i].speed;

      if (this.shines[i].item.children[0].material.uniforms.Time.value > this.shines[i].LifeTime) {
        this.removeShine(i);
      }

    }

    const { Place } = this.props;

    let maxShines = 3;
    if (Place === 1) maxShines = 6;
    if (Place === 2) maxShines = 5;
    if (Place === 3) maxShines = 3;
    if (Place === 4) maxShines = 3;

    if (this.shines.length <= maxShines) {
      const ver = Math.random() * 100;

      if (ver > 92) {

        let textType = 1;
        if ((Math.random() * 100) > 55) {
          textType = 2;
        }
        if ((Math.random() * 100) > 85) {
          textType = 3;
        }

        switch (Place) {

          case 1:
          case 2:
          case 3:

            //posAngle, speed, LifeTime, TimeShift, AngleRange, ShineKef, RangeMin, RangeMax
            this.addShine(
              Math.random() * 7, //posAngle
              -0.005 + Math.random() * 0.012, //speed
              10 + Math.random() * 10, //LifeTime
              Math.random() * 1500, //TimeShift
              0.2 + Math.random() * 1.6, //AngleRange
              1.0, //ShineKef
              0.20 + Math.random() * 0.1,
              0.55 + Math.random() * 0.4, //RangeMax
              textType
            );
            break;

          case 4:
            //posAngle, speed, LifeTime, TimeShift, AngleRange, ShineKef, RangeMin, RangeMax
            this.addShine(
              Math.random() * 7, //posAngle
              -0.005 + Math.random() * 0.009, //speed
              10 + Math.random() * 5, //LifeTime
              Math.random() * 1500, //TimeShift
              0.6 + Math.random() * 1.2, //AngleRange
              2.0, //ShineKef
              0.1,
              0.55 + Math.random() * 0.4, //RangeMax
              textType
            );
            break;

          case 5:

            //posAngle, speed, LifeTime, TimeShift, AngleRange, ShineKef, RangeMin, RangeMax
            this.addShine(
              Math.random() * 7, //posAngle
              -0.005 + Math.random() * 0.009, //speed
              10 + Math.random() * 5, //LifeTime
              Math.random() * 1500, //TimeShift
              0.6 + Math.random() * 1.2, //AngleRange
              2.0, //ShineKef
              0.4,
              0.65 + Math.random() * 0.32, //RangeMax
              textType
            );
            break;


          default: {

          }
        }
      }
    }

    this.three_render();

  }

  componentWillUnmount() {


    for (let i = 0; i < this.shines.length; i++) {
      this.three_scene.remove(this.shines[i].item);
    }

    this.shines = undefined;
    this.golden_medal = undefined;
    this.three_scene = undefined;

    clearInterval(this.interval);
    this.interval = undefined;
  }

  three_render = () => {
    if (this.props.noRender) return;
    this.renderer.render(this.three_scene, this.three_camera);
  }

  get myCanvasId() {
    return `3d-shine-canvas-${this.props.Id}`
  }

  render() {
    const {
      drawWidth,
      drawHeight
    } = this.props;

    const style = {
      width: drawWidth,
      height: drawHeight
    }

    return (
      <div style={style} className='shine-div' onMouseMove={this.onMouseMove}>
        <canvas style={style} id={this.myCanvasId} className='shine-div-canvas'>
        </canvas>
      </div>
    );
  }

}

export default React.memo(withCapoeiraSportState('client')(Shine3D));