import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { Link } from 'react-router-dom';
import GroupAvatar from 'src/ver2/components/group/avatar';

import './style.css';

/**
  Это маленький список новостей
  Сюда приходит массив новостей News ([ {...News}, ... ])
 */

class KMOQuestPlashka extends PureComponent {

    state = {
        loadingQuest: null
    }

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount = async () => {

        const { client } = this.props;

        const  loadingQuest =  await client.getKMOQuest ( 5 );

        this.setState({
            loadingQuest
        })
    }

    render() {

        const { upperStyle, groupsLoaded, client } = this.props;
        const { loadingQuest } = this.state;

        if (!loadingQuest || !groupsLoaded) return null;


        const { width, height } = upperStyle;

        return <Link to={'/shop/5/?page=results'}>
            <div style={{ width, height }} className='kmo_quest_plashka'>
                {loadingQuest.map(({ GroupIamInId, Amount }) => {

                    const group = client.getGroupById(GroupIamInId);
                    const { Name } = group;
                    return <div className='kmo_quest_plashka_group'>
                        <div className='kmo_quest_plashka_groupavatar'>
                            <GroupAvatar groupId={GroupIamInId} size={30} />
                        </div>
                        <div className='kmo_quest_plashka_groupname'>
                            {Name}
                        </div>
                        <div className='kmo_quest_plashka_amount'>
                            <span>{Amount}</span> <img src="/../images/ver2/scores/money/kmo.png"/>
                        </div>
                    </div>
                })
                }
            </div>
        </Link >
    }

}

export default withCapoeiraSportState('client, groupsLoaded')(KMOQuestPlashka);