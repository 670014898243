import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import VideoWrapper from 'src/ver2/components/video/videoWrapper';

import LittlePerson from 'src/ver2/components/person/little';
import LinkButton from 'src/ver2/components/ui/buttons/link'

import './style.css';

class VideoLittlePlashka extends PureComponent {

    // constructor(props) {
    //     super(props);

    // }

    ref = React.createRef();
    vkPlayer;

    render() {

        const { upperStyle, mode, onLinkClick, autoplay } = this.props;

        const { width, height } = upperStyle;

        const { Video } = this.props;

        if (!Video || (!Video.Url && (!Video.VK_Key))) return <div className='video_little_plashka'>
            загрузка...
        </div>

        const { Title, Url, Id, red_user_id, blue_user_id, VK_Key } = Video;
        const VK_Key_ = VK_Key ? VK_Key.split('_') : [0, 0];

        // ЧАСТЬ СТАЙЛА
        const padding = 10;
        const widthForElement = width - padding * 2;
        const heightForIframe = widthForElement * 9 / 16;

        const heightFroDiscription = height - heightForIframe - padding * 3;

        let linkStuff = {
            link: `/?videoId=${Id}`,
            style: {
                position: 'absolute',
                top: 10,
                left: 'calc(100% - 120px)'
            }
        }

        if (mode === 'trefatext') {
            linkStuff.type = 'button';
            linkStuff.onClick = () => onLinkClick(`/?videoId=${Id}`)
        }

        return <div className='video_little_plashka' style={{ width, height }}>
            <LinkButton {...linkStuff} />
            <div style={{ width: widthForElement, height: heightForIframe }} className='video_little_plashka_video'>
                {VK_Key ?
                    <iframe
                        ref={this.ref}
                        src={`https://vk.com/video_ext.php?oid=-${VK_Key_[0]}&id=${VK_Key_[1]}&hd=2&js_api=1`}
                        width={widthForElement}
                        height={heightForIframe}
                        allow={ autoplay ? "autoplay; encrypted-media; fullscreen; picture-in-picture;" : "encrypted-media; fullscreen; picture-in-picture;" }
                        frameborder="0"
                        allowfullscreen >
                    </iframe>
                    :
                    <iframe
                        id={'video-iframe-' + Id}
                        title={Title}
                        width={widthForElement}
                        height={heightForIframe}
                        src={autoplay ? (Url + '&autoplay=1&mute=1') : Url}
                        frameBorder="1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen>
                    </iframe>
                }
            </div>
            {mode === 'trefatext' ?
                <div style={{ width: widthForElement, height: heightFroDiscription }} className='video_little_plashka-discription'>
                    <LittlePerson mode='video_trefatext' onLinkClick={onLinkClick} height={(heightFroDiscription - 2) / 2} personId={red_user_id} />
                    <LittlePerson mode='video_trefatext' onLinkClick={onLinkClick} height={(heightFroDiscription - 2) / 2} personId={blue_user_id} />
                </div>
                :
                <div style={{ width: widthForElement, height: heightFroDiscription }} className='video_little_plashka-discription'>
                    <LittlePerson mode='categorys' height={(heightFroDiscription - 2) / 2} personId={red_user_id} />
                    <LittlePerson mode='categorys' height={(heightFroDiscription - 2) / 2} personId={blue_user_id} />
                </div>
            }
        </div>

    }

    componentDidMount() {
        if (!this.vkPlayer && this.ref && this.ref.current) {
            this.vkPlayer = window.VK.VideoPlayer(this.ref.current);
            this.vkPlayer.on( 'inited', this.vkPlayerInited )
        } 
    }

    componentDidUpdate() {
        if (!this.vkPlayer && this.ref && this.ref.current) {
            this.vkPlayer = window.VK.VideoPlayer(this.ref.current);
            this.vkPlayer.on( 'inited', this.vkPlayerInited );

            const { Video } = this.props;
            const TimeStart = ( Video && Video.TimeStart ) ? Video.TimeStart : 0;
            this.vkPlayer.seek && this.vkPlayer.seek( TimeStart ); 
        }
    }
    vkPlayerInited = () => {
        const { Video } = this.props;
        const TimeStart = ( Video && Video.TimeStart ) ? Video.TimeStart : 0;
        this.vkPlayer.seek( TimeStart ); 
    }

}

const VideoLittlePlashkaWrapped = (props) => <VideoWrapper Wrapped={VideoLittlePlashka} videoId={props.videoId} componentProps={{ ...props }} />
export default withCapoeiraSportState('client')(VideoLittlePlashkaWrapped);