import React from 'react';

export default () => <svg width="24.25mm" height="24.25mm"
    viewBox="0 0 2425 2425" >
    <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_2228782653328">
            <path class="ui_icon__fill" d="M64213 185544c-37,-4 -74,-6 -112,-6 -514,0 -931,416 -931,930 0,514 417,931 931,931 514,0 930,-417 930,-931 0,-122 -23,-238 -66,-345l-864 345 112 -924z" />
            <path class="ui_icon__fill" d="M64325 185398l-113 924 865 -345c-124,-310 -410,-537 -752,-579z" />
        </g>
        <g id="_2228782649744">
            <path class="ui_icon__fill" d="M597 504l1121 0c199,0 207,-19 207,74l0 654c0,176 -3,387 1,560 0,31 5,58 7,87 2,39 -19,41 -53,40 -370,-4 -752,1 -1124,0 -81,0 -297,13 -353,-21 -48,-28 -50,-69 -50,-140l0 -1121c0,-97 -10,-127 58,-132 57,-4 127,-1 186,-1zm1420 276c0,-28 -8,-143 11,-154 12,-6 26,1 30,11 6,15 4,102 4,121l-2 825c0,55 0,110 0,165 0,28 0,57 0,84 0,18 8,65 -10,72 -34,13 -25,-35 -27,-56 -11,-106 -6,-137 -6,-243l0 -825zm142 -152c-4,-71 -42,-104 -103,-121 -47,-13 -35,-16 -55,-54 -11,-21 -34,-42 -59,-53 -47,-19 -151,-12 -209,-12l-1108 0c-65,0 -158,-5 -220,2 -157,19 -141,154 -141,303l0 884c0,74 -1,148 0,221 2,142 74,224 208,237 65,6 151,2 218,2l888 -1c73,-1 147,0 221,0 73,0 152,4 224,2 78,-3 107,-28 125,-96 16,-67 12,-138 12,-209l0 -884c0,-71 4,-152 -1,-221z" />
            <g>
                <path class="ui_icon__fill" d="M1027 655c-14,-111 -100,-94 -203,-94 -52,0 -256,-3 -293,3 -101,17 -84,113 -85,210 0,49 0,98 0,146 1,44 -2,106 4,148 13,100 114,84 209,84 53,0 256,4 291,-3 90,-16 82,-110 86,-207 3,-91 2,-198 -9,-287z" />
                <path class="ui_icon__fill" d="M1246 571c-79,12 -65,92 -27,110 32,16 148,9 191,9 63,0 357,4 393,-1 70,-10 70,-91 24,-112 -31,-14 -329,-8 -386,-8 -53,0 -148,-5 -195,2z" />
                <path class="ui_icon__fill" d="M1822 813c-22,-7 -417,-3 -487,-3 -27,0 -73,-4 -96,0 -29,5 -46,21 -48,51 -3,34 11,56 36,65 23,8 338,4 388,4 46,0 159,5 198,-2 59,-11 59,-98 9,-115z" />
                <path class="ui_icon__fill" d="M1631 1044c-57,0 -364,-4 -394,4 -69,17 -58,96 -13,114 31,14 333,7 391,8 44,0 160,5 196,-3 62,-14 63,-96 13,-116 -29,-12 -153,-7 -193,-7z" />
                <path class="ui_icon__fill" d="M1637 1272l-953 0c-57,0 -137,-4 -191,1 -74,7 -68,86 -36,111 28,23 132,15 174,15l953 0c60,0 131,3 190,-2 84,-8 61,-92 39,-111 -26,-22 -135,-14 -176,-14z" />
                <path class="ui_icon__fill" d="M1012 1511c-28,-15 -302,-9 -353,-9 -41,0 -142,-4 -176,3 -26,6 -40,16 -44,45 -12,94 88,73 195,73 52,0 334,3 359,-2 58,-11 54,-91 19,-110z" />
                <path class="ui_icon__fill" d="M1165 1547c-10,33 3,62 23,75 26,16 150,9 191,9 66,0 354,4 395,-1 69,-9 72,-91 29,-114 -33,-17 -142,-10 -188,-10 -70,0 -348,-4 -393,1 -29,2 -51,16 -57,40z" />
                <path class="ui_icon__fill" d="M1020 1755c-27,-27 -117,-18 -161,-18 -61,0 -121,-1 -181,0 -55,0 -129,-4 -181,1 -73,7 -66,85 -43,107 28,27 117,18 162,18 67,0 317,3 362,-1 82,-8 61,-89 42,-107z" />
                <path class="ui_icon__fill" d="M1209 1748c-29,7 -43,21 -46,53 -3,33 9,56 36,65 30,10 335,5 391,5 41,0 167,5 198,-3 51,-14 61,-97 5,-117 -31,-11 -332,-6 -390,-6 -41,0 -163,-5 -194,3z" />
            </g>
        </g>
    </g>
</svg>