import React, { PureComponent } from 'react';

import { withPersonState } from 'src/ver2/pages/person/context';
import PersonWrapper from 'src/ver2/components/person/personWrapper.js';
// import PersonFrkAccredApplicationModal from './sendApplcation';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import { Modal } from 'antd';
import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';
import { Link } from 'react-router-dom';

import './style.css';

// class PersonFrkAccredPlashka
// classname: person_fkraccred_plashka

/**
  Это плашка, которая показывает, что у нас не заполнены какие-то поля
  и даёт возможность их заполнить
 */

// это было до НГ

const DiscriptionObj = (Values) => ({
    title: 'Оплата членства ФКР',
    // header: (Values === '1h' ? 'Ожидается оплата за полсезона 1000р' : 'Ожидается оплата за сезон 1500р'),
    header: 'Ожидается оплата 1000р',
    Body: `
    ^a_^text^a_^Порядок действий при оплате членства ФКР:^n_^
    1) Совершить перевод на карту Сбербанк ^n_^
    5469 3800 9498 4456 ^n_^
    Получатель Криворуцкий Григорий Евгеньевич.^n_^^n_^
    2) Отправить подтверждение перевода в WhatsApp по номеру ^n_^
    +7-903-007-74-72.^n_^
    В сообщении обязательно указать ФИО спортсмена за которого производится оплата.^n_^^n_^
    3) В течение максимум 15 часов вы получаете подтверждение оплаты, о чем свидетельствует плашка ФКР у Вас на странице^n_^^n_^
    4) В случае отсутствия подтверждения – свяжитесь с администратором портала по указанным на сайте контактам.^n_^^n_^
    `,
})

// const DiscriptionObj = (Values) => ({
//     title: 'Оплата членства ФКР',
//     header: 'Ожидается оплата за полсезона 750р',
//     Body: `
//     ^a_^text^a_^Порядок действий при оплате членства ФКР:^n_^
//     1) Совершить перевод на карту Сбербанк ^n_^
//     5469 3800 9498 4456 ^n_^
//     Получатель Криворуцкий Григорий Евгеньевич.^n_^^n_^
//     2) Отправить подтверждение перевода в WhatsApp по номеру ^n_^
//     +7-903-007-74-72.^n_^
//     В сообщении обязательно указать ФИО спортсмена за которого производится оплата.^n_^^n_^
//     3) В течение максимум 15 часов вы получаете подтверждение оплаты, о чем свидетельствует зеленая галочка в пункте Регистрация.^n_^^n_^
//     4) В случае отсутствия подтверждения – свяжитесь с администратором портала по указанным на сайте контактам.^n_^^n_^
//     `,
// })

class PersonFrkAccredPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        componentHeight: null,
        modalApplicationVisible: false
    }

    componentDidMount() {
        if (this.props.isFkrAccredReady)
            this.setComponentHeight(65);

        if (this.props.searchObj && this.props.searchObj.goFkr) {
            this.setModalApplicationVisible();
        }
    }

    componentDidUpdate({ isFkrAccredReady }) {
        if (!isFkrAccredReady && this.props.isFkrAccredReady)
            this.setComponentHeight(65);
    }

    setComponentHeight = (componentHeight) => {
        if (componentHeight === this.state.componentHeight) return;
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ plashkaId, height: componentHeight });
        this.setState({ componentHeight })
    }

    setModalApplicationVisible = (modalApplicationVisible = true) => {
        this.setState({ modalApplicationVisible })
    }

    onAdmitClick = (Values) => {


        let downText = '';
        if (Values === '1h') downText = 'полсезона'; //'полсезона';
        if (Values === '2h') downText = 'сезон';

        const { sendFkrAdmission } = this.props;

        Modal.info({
            title: 'Заявка принята',
            content: <div>
                Ваша заявка на членство в Федерации Капоэйра России на {downText} принята. <br />
                Для подтверждения заявки внесите оплату. Для этого нажмите на кнопку ОПЛАТА в
                разделе аккредитации на Вашей персональной странице и следуйте инструкциям. <br /><br />
                Спасибо, что Вы с нами!
            </div>,
            onOk: () => { },
            okText: 'Ок',
            closable: true,
            centered: true,
        })
        this.setModalApplicationVisible(false);
        sendFkrAdmission(Values);
    }

    render() {

        const { modalApplicationVisible } = this.state;
        const { upperStyle, FkrAccred, isFkrAccredReady, showInfoModal } = this.props;
        const { width, height } = upperStyle;

        if (!isFkrAccredReady) {
            return null;
        }

        const { status, Item, Application } = FkrAccred;
        const FkrLogo = () => <img src="/../images/ver2/fkr_logo.png" />

        if (status === 'no') {
            return <div style={{ width, height }} className='person_fkraccred_plashka'>
                <div className='person_fkraccred_plashka_top'>
                    НЕ ОФОРМЛЕНО ЧЛЕНСТВО В ФКР
                </div>
                <Link to='/fkraccred'>
                    <div className='person_fkraccred_plashka_bottom'>
                        перейти к оформлению
                    </div>
                </Link>
            </div>
        }

        if (status === 'application') {

            const ApplicationNow = Application[Application.length - 1];
            const { Values } = ApplicationNow;
            let downText = '';
            if (Values === '1h') downText = 'НА СЕЗОН';//'ПОЛСЕЗОНА';
            if (Values === '2h') downText = 'НА СЕЗОН';

            const Discription = DiscriptionObj(Values);
            const onClick = () => { showInfoModal({ type: 'eventDiscription', Discription }) }

            return <div style={{ width, height }} className='person_fkraccred_plashka person_fkraccred_plashka_yes'>
                <div className='person_fkraccred_plashka_top'>
                    <span> ЗАЯВКА НА ЧЛЕНСТВО ФКР <br /> ( {downText} ) </span>
                    <Link to='/fkraccred'>
                        <ButtonPlashka
                            upperStyle={{ width: 60, height: 32 }}
                            style={{ fontSize: 12 }}
                            onClick={() => { }}
                            text={'ОПЛАТА'}
                        />
                    </Link>
                </div>
                <Link to='/fkraccred'>
                    <div className='person_fkraccred_plashka_bottom'>
                        ожидается оплата и подтверждение
                    </div>
                </Link>
            </div>
        }

        if (status === 'yes') {

            if (!Item || !Item.length) {
                return <div style={{ width, height }} className='person_fkraccred_plashka person_fkraccred_plashka_yes'>
                    <div className='person_fkraccred_plashka_top'>
                        <FkrLogo />  <span> ОШИБКА АККРЕДИТАЦИИ ФКР </span>
                    </div>
                    <div className='person_fkraccred_plashka_bottom'>
                        произошла ошибка сервера, свяхитесь с администратором
                    </div>
                </div>
            }

            return <div style={{ width, height }} className='person_fkraccred_plashka person_fkraccred_plashka_yes'>
                <div className='person_fkraccred_plashka_top'>
                    <FkrLogo />  <span> ЧЛЕНСТВО В ФКР </span>
                </div>
                <Link to='/fkraccred'>
                    <div className='person_fkraccred_plashka_bottom'>
                        действует до {moment(Item[Item.length - 1].ServedTo, 'YYYY.MM.DDTHH:mm').format('DD.MM.YYYY')}
                    </div>
                </Link>
            </div>
        }

    }



}


export default withPersonState(`
sendFkrAdmission,
FkrAccred,
isFkrAccredReady,
showInfoModal,
searchObj
`)(PersonFrkAccredPlashka)