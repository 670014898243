// это посто сама по себе аввтарка группы
import React from 'react';

import { Avatar } from 'antd';
import { withCapoeiraSportState } from 'src/ver2/context';

const GroupAvatar = (props) => {
    const {size, groupId, client, style} = props;
    const avatarUrl = client ? client.getGroupUrl(groupId) : 'images/groups/' + groupId + '/logo.png';
    return <Avatar size = {size || 20} style = {style} src = {avatarUrl}/>
}

export default React.memo ( withCapoeiraSportState('client')(GroupAvatar) );