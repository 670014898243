import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import FullQuest from './full';

import PersonAvatar from 'src/ver2/components/person/avatar';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import { Link } from 'react-router-dom';

import './littleStyle.css';
import Loader from 'src/ver2/components/loader';
import { message, Modal, Input } from 'antd';

//getEventById

/**
 *  сюда приходит или
 *      QuestId (если это квест для взятия в теории)
 *      UserQuestId (если это именно квест чувака)
 * 
 *      notVisible - это ф-я, которую нужно вызывать
 * 
 *      Если приходит просто QuestId - мы его подгружаем
 *      Если приходит UserQuestId - то в state грузим QuestId
 * 
 */

class LittleQuest extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }
    state = {
        status: 'loading',
        Quest: {},
        isVisible: false,
        questModal: false,
        mayITakeQuest: {},
        questProgress: null,
        counter: 0
    }

    changeQuestModal = (questModal = false) => this.setState({ questModal });

    componentDidMount = () => {
        this.loadAll();
    }

    loadAll = async () => {

        const { QuestId, UserQuestId, client } = this.props;
        if (UserQuestId) {
            // загружаем данный юзер квест
            const UserQuest = await client.getUserQuests(null, null, UserQuestId);
            if (!UserQuest || !UserQuest[0]) {
                this.setState({ status: 'loaded', isVisible: false })
                return;
            }


            const { QuestId, Status } = UserQuest[0];

            // если квест выполнен - не отображаем его
            if (Status !== 'going') {
                this.setState({ status: 'loaded', isVisible: false })
                return;
            }

            // ищем сам квест
            const Quest = await client.getQuests({ QuestId });
            if (!Quest || !Quest[0]) {
                this.setState({ status: 'loaded', isVisible: false })
                return;
            }

            // всё в порядке, нам только нужно получить прогресс по квесту
            const questProgress = await client.questProgress(UserQuestId);
            this.setState({ status: 'loaded', isVisible: true, Quest: Quest[0], questProgress, UserQuest: UserQuest[0] })

        } else {
            if (QuestId) {
                const Quest = await client.getQuests({ QuestId });
                if (!Quest || !Quest[0]) {
                    this.setState({ status: 'loaded', isVisible: false })
                    return;
                }

                // теперь смотрим, можем ли мы его выполнить, а если нет, то по какой причине!
                const mayITakeQuest = await client.mayITakeQuest(QuestId);
                if (mayITakeQuest && mayITakeQuest.status) {

                    if (mayITakeQuest.mayITakeQuest) {
                        return this.setState({ status: 'loaded', isVisible: true, Quest: Quest[0], mayITakeQuest })
                    }

                    // если не можем получить, смотрим почему
                    if (mayITakeQuest.reason) {
                        if (mayITakeQuest.reason.Code === 'no_timeout') {
                            return this.setState({ status: 'loaded', isVisible: true, Quest: Quest[0], mayITakeQuest })
                        }
                        if (mayITakeQuest.reason.Code === 'see_cant_take') {
                            return this.setState({ status: 'loaded', isVisible: true, Quest: Quest[0], mayITakeQuest })
                        }
                        return this.setState({ status: 'loaded', isVisible: false })
                    }
                }
                this.setState({ status: 'loaded', isVisible: false })
            }
        }

    }

    onAddClick = async () => {
        const { QuestId, client, renewQuests } = this.props;
        const { Quest, counter } = this.state;
        const { QuestSettings } = Quest;
        if (QuestSettings) {
            const QuestSettingsObj = JSON.parse(QuestSettings);
            if (QuestSettingsObj.add) {
                this.setState({ counter: 0 });
                Modal.info({
                    // className: modalClassName,
                    title: QuestSettingsObj.add.text,
                    content: <Counter counter = {counter} changeCounter = {this.changeCounter} />,
                    okCancel: true,
                    closable: true,
                    centered: true,
                    cancelText: 'Отменить',
                    okText: 'Отправить',
                    onOk: this.onSendAdd
                })
                return;
            }
        }
        this.setState({ status: 'loading' });
        await client.takeQuest(QuestId);
        await renewQuests();
        this.loadAll();
    }

    onSendAdd = async () => {
        const { counter } = this.state;
        if (!counter) {
            return message.error('Ничего не добавлено');
        }
        const { QuestId, client, renewQuests } = this.props;
        const { Quest } = this.state;
        const { QuestSettings } = Quest;
        const QuestSettingsObj = JSON.parse(QuestSettings);

        const moneyType = QuestSettingsObj.add.money;
        if (!moneyType) return message.error('Ошибка данных');

        const AddSettingsObj = {
            add: { money: { [moneyType]: counter } }
        }
        const AddSettings = JSON.stringify(AddSettingsObj)

        this.setState({ status: 'loading' });
        await client.takeQuest(QuestId, AddSettings);
        await renewQuests();
        this.loadAll();
    }

    onRewardClick = async () => {
        const { UserQuestId, client, renewQuests, isReleased } = this.props;
        this.setState({ status: 'loading' });
        await client.releaseQuest(UserQuestId);
        await renewQuests();
        isReleased && isReleased();
        this.loadAll();
    }

    onCancelClick = async () => {
        const { UserQuestId, client, renewQuests, isReleased } = this.props;
        // this.setState({ status: 'loading'});
        const answer = await client.removeQuest(UserQuestId);

        if (answer && answer.reason) {
            message.error(answer.reason);
            return;
        } else {
            this.setState({ status: 'loading' });
            await renewQuests();
            isReleased && isReleased();
            this.loadAll();
        }
    }

    // СЧЕТЧИК ЧЕГО-ЛИБО

    changeCounter = (e) => {
        const isNumeric = (str) => {
            if (typeof str != "string") return false // we only process strings!
            return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
        }

        if (e.target.value === '' || e.target.value === '0') {
            this.setState({ counter: 0 });
            return 0;
        }

        if ( isNumeric(e.target.value)) {

            let result = e.target.value;
            if ((result.length > 1) && (result[0] === '0')) result = result.slice(1);

            const { Quest } = this.state;
            const { QuestSettings } = Quest;
            const QuestSettingsObj = JSON.parse(QuestSettings);
            if (QuestSettingsObj.add.max) {
                if (Number(result) > QuestSettingsObj.add.max) {
                    result = QuestSettingsObj.add.max;
                }
            }

            this.setState({ counter: result });
            return result
        }
        return this.state.counter;
    }

    render() {

        const { UserQuestId } = this.props;
        const { status, isVisible, Quest, UserQuest, questModal, mayITakeQuest, questProgress } = this.state;

        if (status === 'loading' || !isVisible || !Quest) return null;

        const {
            Title
        } = Quest;

        let imgData = {
            src: "/../images/ver2/quest.png",
        }
        let title = 'Взять квест';
        let className = 'quest_little';

        // т.е. у нас квест уже есть
        if (UserQuestId) {
            imgData = {
                src: "/../images/ver2/quest_taked.png",
            }
            title = 'Квест выполняется';
            if (questProgress && questProgress.allDone) {
                imgData = {
                    src: "/../images/ver2/quest_done.png",
                }
                title = 'Получить награду';
                className += ' quest_little_allDone';
            }
        } else {
            // если пришел извне
            if (mayITakeQuest) {
                if (!mayITakeQuest.mayITakeQuest) {
                    imgData = {
                        src: "/../images/ver2/quest_dashed.png",
                    }
                    title = mayITakeQuest.reason.Text;
                    className += ' quest_little_dashed';
                }
            }

        }

        return <React.Fragment>
            <div title={title} onClick={() => { this.changeQuestModal(true) }} className={className}>
                <img alt='CapoeiraSport' {...imgData} />
                {Title}
                {
                    questProgress &&
                    <div className='quest_little_progress'>
                        <NumberText count={`${questProgress.progress}%`} text={'ПРОГРЕСС'} height={34} />
                    </div>
                }
                {
                    (mayITakeQuest && !mayITakeQuest.mayITakeQuest && mayITakeQuest.reason && mayITakeQuest.reason.Code === 'no_timeout') &&
                    <div className='quest_little_progress'>
                        <NumberText count={`${mayITakeQuest.reason.TimeOut.toFixed()} Ч`} text={'ТАЙМАУТ'} height={34} />
                    </div>
                }
            </div>
            <Modal
                className='aaa person_page_inventar_modal'
                visible={questModal}
                onCancel={() => { this.changeQuestModal(false) }}
                footer={null}
                title={'Задание'}
                width={800}
            >
                {questModal ? <FullQuest onCancelClick={this.onCancelClick} onRewardClick={this.onRewardClick} onAddClick={this.onAddClick} {...{ Quest, UserQuest, mayITakeQuest, questProgress }} /> : null}
            </Modal>
        </React.Fragment>
    }

}

class Counter extends PureComponent {

    state = {
        counter: 0
    }

    componentDidMount = () => {
        const { counter } = this.props;
        this.setState({ counter })
    }

    render () {
        const { changeCounter } = this.props;
        const { counter } = this.state;
        return <div className='quest_little_counter'>
            <Input value={counter} onChange={(e) => {
                const counter = changeCounter(e);
                this.setState({ counter })
            }} />
        </div>
    }
}

export default withCapoeiraSportState(
    `
        client,
        renewQuests
    `
)(LittleQuest);