import React from 'react';

export default () => <svg width="80mm" height="80mm" version="1.1"
viewBox="0 0 8000 8000" >
 <g id="Слой_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="_2228862355792">
  <polygon className="starfill" points="5919,4974 7488,3217 5186,2718 4000,683 2814,2718 512,3217 2081,4974 1844,7317 4000,6368 6156,7317 "/>
  {/* <polygon className="starfill" style = {{fill: '#009846'}} points="5919,4974 7488,3217 5186,2718 4000,683 2814,2718 512,3217 2081,4974 1844,7317 4000,6368 6156,7317 "/> */}
  <polygon className="starstroke" style = {{fill: 'none', stroke: '#2B2A29', strokeWidth: 400 }} points="4000,683 5186,2718 7488,3217 5919,4974 6156,7317 4000,6368 1844,7317 2081,4974 512,3217 2814,2718 "/>
  </g>
 </g>
</svg>
