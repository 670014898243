import React from 'react';

export const ICON_VIDEO = (value) => {

    const className = value ? 'solo_icon_checked' : 'solo_icon';

    return <svg className = {className} xmlns="https://www.w3.org/2000/svg" width="80px" height="80px" version="1.1" viewBox="0 0 80 80">
    <g id="Слой_x0020_1">
     <metadata id="CorelCorpID_0Corel-Layer"/>
     <g id="_2155979352368">
      <rect class="fil0 str0" x="8" y="34" width="46.0409" height="31.8983" rx="6" ry="6"/>
      <g>
       <circle class="fil0 str0" cx="18" cy="21" r="8"/>
       <circle class="fil0 str0" cx="39" cy="21" r="8"/>
      </g>
      <polygon class="fil0 str0" points="74,38 58,42 58,58 74,62 74,50 "/>
     </g>
    </g>
   </svg>

}

export const ICON_MINE = (value) => {

    const className = value ? 'solo_icon_checked' : 'solo_icon';

    return <svg className = {className} xmlns="https://www.w3.org/2000/svg" width="80px" height="80px" version="1.1" viewBox="0 0 80 80">
        <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <g id="_1751378953552">
        <path class="fil0" d="M86 83c0,0 0,-1 0,-1 -7,-21 -10,-18 -24,-22 -5,-1 -7,-5 -7,-9 0,-1 0,-2 0,-2 -3,2 -6,4 -10,4 -4,0 -7,-2 -10,-4 0,0 0,1 0,2 0,4 -2,8 -7,9 -14,4 -17,1 -24,22 0,0 0,1 0,1 0,2 19,5 41,5 22,0 41,-3 41,-5z"/>
        <path class="fil0" d="M54 45c5,-4 8,-11 8,-19 0,-13 -8,-24 -17,-24 -9,0 -17,11 -17,24 0,8 3,15 8,19 2,3 6,4 9,4 3,0 7,-1 9,-4z"/>
        </g>
        </g>
   </svg>

}

export const ICON_ALL = (value) => {

    const className = value ? 'solo_icon_checked' : 'solo_icon';

    return <svg className = {className} xmlns="https://www.w3.org/2000/svg" width="80px" height="80px" version="1.1" viewBox="0 0 80 80">
        <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <path class="fil0" d="M41 67l-7 0c0,0 0,0 -1,0 0,-1 0,-1 0,-2l0 -7 -3 0 -1 6c0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,1 0,1 0,1 0,1 0,1l0 1 -5 0c-1,0 -1,0 -1,-2 0,0 0,-1 0,-1l7 -37c0,0 0,0 0,-1 0,0 0,-1 0,-1 0,-1 0,-1 0,-1l0 -1 7 0c1,0 1,0 1,1 1,0 1,1 1,2l1 38c0,0 0,1 0,1 0,1 1,1 1,1l0 1zm-8 -25l-2 8 2 0 0 -8zm19 25l-10 0c0,0 0,0 0,0 -1,-1 -1,-1 -1,-2 0,0 0,-1 0,-1l4 -38c0,0 0,-1 0,-1 -1,-1 -1,-1 -1,-1l0 -1 7 0c1,0 1,1 1,2 0,0 0,1 0,1l-3 31 3 0c1,0 1,-1 1,-1 0,0 1,-1 1,-1l0 0 -1 9c0,2 0,3 -1,3zm13 0l-10 0c0,0 0,0 0,0 -1,-1 -1,-1 -1,-2 0,0 0,-1 0,-1l4 -38c0,0 0,-1 0,-1 -1,-1 -1,-1 -1,-1l0 -1 7 0c1,0 1,1 1,2 0,0 0,1 0,1l-3 31 3 0c1,0 1,-1 1,-1 0,0 1,-1 1,-1l0 0 -1 9c0,2 -1,3 -1,3z"/>
        <path class="fil1" d="M7 45c0,-21 17,-38 38,-38 21,0 38,17 38,38 0,21 -17,38 -38,38 -21,0 -38,-17 -38,-38zm79 0c0,-23 -18,-41 -41,-41 -23,0 -41,18 -41,41 0,23 18,41 41,41 23,0 41,-18 41,-41z"/>
        </g>
   </svg>

}

export const ICON_TUTORIAL = (value) => {

    const className = value ? 'solo_icon_checked_tutorial' : 'solo_icon';

    return <svg className = {className} xmlns="https://www.w3.org/2000/svg" width="70px" height="70px" version="1.1" viewBox="0 0 50 50">
        <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <path class="fil0" d="M32 32l0 -10 -9 4 -10 -4 0 10c0,2 4,4 10,4 5,0 9,-2 9,-4z"/>
        <polygon class="fil0" points="23,24 38,18 38,25 36,26 38,29 41,26 39,25 39,18 43,16 23,9 2,16 "/>
        </g>
   </svg>

}

export const ICON_TIER1 = (value) => {

    const className = value ? 'solo_icon_checked' : 'solo_icon';

    return <svg className = {className} xmlns="https://www.w3.org/2000/svg" width="80px" height="80px" version="1.1" viewBox="0 0 80 80">
 <g id="Слой_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="_1751191610368">
   <path class="fil0" d="M6 24l10 0c0,0 0,-1 1,-1 0,0 0,-1 0,-1l1 0 -1 10c0,1 -1,2 -2,2l-1 0 -3 31c0,0 0,0 0,1 0,0 0,1 1,1l0 1 -7 0c-1,0 -1,-1 -1,-2 0,0 0,0 0,-1l3 -31 -2 0c0,0 -1,0 -1,0 0,1 0,1 0,2l-1 0 1 -11c0,0 0,-1 1,-1 0,0 0,0 1,0zm21 3l-4 38c0,0 0,0 0,1 1,0 1,1 1,1l0 1 -7 0c-1,0 -1,-1 -1,-2 0,0 0,0 0,-1l4 -38c0,0 -1,-1 -1,-1 0,-1 0,-1 0,-1l0 -1 7 0c0,0 0,0 1,0 0,0 0,1 0,2l0 1zm3 -3l13 0 -1 7c0,1 0,2 0,2 -1,1 -1,1 -2,1l-3 0 -1 6 4 0 0 5c0,1 0,2 -1,3 0,0 -1,1 -1,1l-2 0c0,0 -1,1 -1,2l0 7 4 0c0,0 0,0 0,-1 1,0 1,0 1,-1l0 0 0 10c-1,1 -1,1 -1,2 0,0 -1,0 -1,0l-10 0c-1,0 -1,0 -1,-1 0,0 0,0 0,-1 0,0 0,-1 0,-1l3 -38c0,0 0,-1 0,-1 0,-1 0,-1 -1,-1l1 -1zm27 26l1 6c0,0 0,1 1,1 0,1 0,1 0,1l1 0 -1 7c0,2 0,3 -1,3l-4 0c0,0 -1,0 -1,-1 0,0 0,-1 0,-2l-2 -13 -1 12 0 1c0,1 0,1 0,1 0,0 1,1 1,1l0 1 -7 0c-1,0 -1,0 -1,-1 0,0 0,0 0,-1 0,0 0,-1 0,-1l3 -38c0,0 0,-1 0,-1 0,-1 0,-1 -1,-1l1 -1 11 0c3,0 4,3 4,9 0,1 0,2 0,2 0,1 0,2 0,3 0,4 -1,6 -1,8 -1,2 -2,3 -3,4zm-4 -16l-1 8 1 0c1,0 1,-2 1,-4 0,-1 0,-1 0,-1 0,-1 0,-1 0,-2 0,-1 0,-1 0,-1l-1 0z"/>
   <path class="fil0" d="M71 65l4 -30 0 0c0,-1 -1,-2 -1,-2l-1 0c-1,0 -1,0 -1,-1l0 -1 1 -7 12 0c1,0 1,0 1,1 1,0 1,1 1,2 0,0 0,1 0,2l-5 36c0,0 0,0 0,1 0,0 1,1 1,1l0 1 -11 0c-1,0 -1,-1 -1,-2 0,0 0,0 0,-1z"/>
   <path class="fil0" d="M6 24l10 0c0,0 0,-1 1,-1 0,0 0,-1 0,-1l1 0 -1 10c0,1 -1,2 -2,2l-1 0 -3 31c0,0 0,0 0,1 0,0 0,1 1,1l0 1 -7 0c-1,0 -1,-1 -1,-2 0,0 0,0 0,-1l3 -31 -2 0c0,0 -1,0 -1,0 0,1 0,1 0,2l-1 0 1 -11c0,0 0,-1 1,-1 0,0 0,0 1,0zm21 3l-4 38c0,0 0,0 0,1 1,0 1,1 1,1l0 1 -7 0c-1,0 -1,-1 -1,-2 0,0 0,0 0,-1l4 -38c0,0 -1,-1 -1,-1 0,-1 0,-1 0,-1l0 -1 7 0c0,0 0,0 1,0 0,0 0,1 0,2l0 1zm3 -3l13 0 -1 7c0,1 0,2 0,2 -1,1 -1,1 -2,1l-3 0 -1 6 4 0 0 5c0,1 0,2 -1,3 0,0 -1,1 -1,1l-2 0c0,0 -1,1 -1,2l0 7 4 0c0,0 0,0 0,-1 1,0 1,0 1,-1l0 0 0 10c-1,1 -1,1 -1,2 0,0 -1,0 -1,0l-10 0c-1,0 -1,0 -1,-1 0,0 0,0 0,-1 0,0 0,-1 0,-1l3 -38c0,0 0,-1 0,-1 0,-1 0,-1 -1,-1l1 -1zm27 26l1 6c0,0 0,1 1,1 0,1 0,1 0,1l1 0 -1 7c0,2 0,3 -1,3l-4 0c0,0 -1,0 -1,-1 0,0 0,-1 0,-2l-2 -13 -1 12 0 1c0,1 0,1 0,1 0,0 1,1 1,1l0 1 -7 0c-1,0 -1,0 -1,-1 0,0 0,0 0,-1 0,0 0,-1 0,-1l3 -38c0,0 0,-1 0,-1 0,-1 0,-1 -1,-1l1 -1 11 0c3,0 4,3 4,9 0,1 0,2 0,2 0,1 0,2 0,3 0,4 -1,6 -1,8 -1,2 -2,3 -3,4zm-4 -16l-1 8 1 0c1,0 1,-2 1,-4 0,-1 0,-1 0,-1 0,-1 0,-1 0,-2 0,-1 0,-1 0,-1l-1 0z"/>
   <path class="fil0" d="M71 65l4 -30 0 0c0,-1 -1,-2 -1,-2l-1 0c-1,0 -1,0 -1,-1l0 -1 1 -7 12 0c1,0 1,0 1,1 1,0 1,1 1,2 0,0 0,1 0,2l-5 36c0,0 0,0 0,1 0,0 1,1 1,1l0 1 -11 0c-1,0 -1,-1 -1,-2 0,0 0,0 0,-1z"/>
  </g>
 </g>
   </svg>

}

export const ICON_TIER2 = (value) => {

    const className = value ? 'solo_icon_checked' : 'solo_icon';

    return <svg className = {className} xmlns="https://www.w3.org/2000/svg" width="80px" height="80px" version="1.1" viewBox="0 0 80 80">
 <g id="Слой_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="_1751376576624">
   <path class="fil0" d="M4 24l10 0c0,0 0,-1 1,-1 0,0 0,-1 0,-1l1 0 -1 10c0,1 -1,2 -2,2l-1 0 -3 31c0,0 0,0 0,1 0,0 1,1 1,1l0 1 -7 0c-1,0 -1,-1 -1,-2 0,0 0,0 0,-1l3 -31 -2 0c0,0 -1,0 -1,0 0,1 0,1 0,2l-1 0 1 -11c0,0 0,-1 1,-1 0,0 0,0 1,0zm21 3l-4 38c0,0 0,0 0,1 1,0 1,1 1,1l0 1 -7 0c-1,0 -1,-1 -1,-2 0,0 0,0 0,-1l4 -38c0,0 0,-1 -1,-1 0,-1 0,-1 0,-1l0 -1 7 0c0,0 0,0 1,0 0,0 0,1 0,2l0 1zm3 -3l13 0 -1 7c0,1 0,2 0,2 -1,1 -1,1 -2,1l-3 0 -1 6 4 0 0 5c0,1 0,2 -1,3 0,0 -1,1 -1,1l-2 0c0,0 -1,1 -1,2l0 7 4 0c0,0 0,0 0,-1 1,0 1,0 1,-1l0 0 0 10c-1,1 -1,1 -1,2 0,0 -1,0 -1,0l-10 0c-1,0 -1,0 -1,-1 0,0 0,0 0,-1 0,0 0,-1 0,-1l3 -38c0,0 0,-1 0,-1 0,-1 0,-1 -1,-1l1 -1zm27 26l1 6c0,0 0,1 1,1 0,1 0,1 0,1l1 0 -1 7c0,2 0,3 -1,3l-4 0c0,0 -1,0 -1,-1 0,0 0,-1 0,-2l-2 -13 -1 12 0 1c0,1 0,1 0,1 0,0 1,1 1,1l0 1 -7 0c-1,0 -1,0 -1,-1 0,0 0,0 0,-1 0,0 0,-1 0,-1l3 -38c0,0 0,-1 0,-1 0,-1 0,-1 -1,-1l1 -1 11 0c3,0 4,3 4,9 0,1 0,2 0,2 0,1 0,2 0,3 0,4 -1,6 -1,8 -1,2 -2,3 -3,4zm-4 -16l-1 8 1 0c1,0 1,-2 1,-4 0,-1 0,-1 0,-1 0,-1 0,-1 0,-2 0,-1 0,-1 0,-1l-1 0z"/>
   <path class="fil0" d="M4 24l10 0c0,0 0,-1 1,-1 0,0 0,-1 0,-1l1 0 -1 10c0,1 -1,2 -2,2l-1 0 -3 31c0,0 0,0 0,1 0,0 1,1 1,1l0 1 -7 0c-1,0 -1,-1 -1,-2 0,0 0,0 0,-1l3 -31 -2 0c0,0 -1,0 -1,0 0,1 0,1 0,2l-1 0 1 -11c0,0 0,-1 1,-1 0,0 0,0 1,0zm21 3l-4 38c0,0 0,0 0,1 1,0 1,1 1,1l0 1 -7 0c-1,0 -1,-1 -1,-2 0,0 0,0 0,-1l4 -38c0,0 0,-1 -1,-1 0,-1 0,-1 0,-1l0 -1 7 0c0,0 0,0 1,0 0,0 0,1 0,2l0 1zm3 -3l13 0 -1 7c0,1 0,2 0,2 -1,1 -1,1 -2,1l-3 0 -1 6 4 0 0 5c0,1 0,2 -1,3 0,0 -1,1 -1,1l-2 0c0,0 -1,1 -1,2l0 7 4 0c0,0 0,0 0,-1 1,0 1,0 1,-1l0 0 0 10c-1,1 -1,1 -1,2 0,0 -1,0 -1,0l-10 0c-1,0 -1,0 -1,-1 0,0 0,0 0,-1 0,0 0,-1 0,-1l3 -38c0,0 0,-1 0,-1 0,-1 0,-1 -1,-1l1 -1zm27 26l1 6c0,0 0,1 1,1 0,1 0,1 0,1l1 0 -1 7c0,2 0,3 -1,3l-4 0c0,0 -1,0 -1,-1 0,0 0,-1 0,-2l-2 -13 -1 12 0 1c0,1 0,1 0,1 0,0 1,1 1,1l0 1 -7 0c-1,0 -1,0 -1,-1 0,0 0,0 0,-1 0,0 0,-1 0,-1l3 -38c0,0 0,-1 0,-1 0,-1 0,-1 -1,-1l1 -1 11 0c3,0 4,3 4,9 0,1 0,2 0,2 0,1 0,2 0,3 0,4 -1,6 -1,8 -1,2 -2,3 -3,4zm-4 -16l-1 8 1 0c1,0 1,-2 1,-4 0,-1 0,-1 0,-1 0,-1 0,-1 0,-2 0,-1 0,-1 0,-1l-1 0z"/>
   <path class="fil0" d="M75 34l-4 0c0,0 -1,-1 -1,-2l0 -1 1 -8 10 0c2,0 4,1 6,4 1,2 2,4 2,8l0 1c0,4 -1,7 -3,10 -1,2 -2,3 -3,5l-4 5c-1,0 -1,1 -2,1l7 0c1,0 1,0 2,0 0,0 0,-1 0,-1l1 0 -1 9c0,1 0,2 -1,2 0,1 -1,1 -2,1l-18 0 1 -9c1,-2 1,-4 2,-6 1,-2 2,-3 2,-4l5 -5c1,-1 2,-2 2,-3 1,-1 1,-2 1,-3 0,-2 0,-2 0,-3 -1,-1 -1,-1 -3,-1z"/>
  </g>
 </g>
   </svg>

}

export const ICON_TIER3 = (value) => {

    const className = value ? 'solo_icon_checked' : 'solo_icon';

    return <svg className = {className} xmlns="https://www.w3.org/2000/svg" width="80px" height="80px" version="1.1" viewBox="0 0 80 80">
 <g id="Слой_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="_1751386178912">
   <path class="fil0" d="M3 24l11 0c0,0 0,-1 0,-1 1,0 1,-1 1,-1l0 0 -1 10c0,1 0,2 -1,2l-1 0 -3 31c0,0 0,0 0,1 0,0 0,1 0,1l0 1 -7 0c0,0 0,-1 0,-2 0,0 0,0 0,-1l2 -31 -1 0c-1,0 -1,0 -1,0 0,1 0,1 0,2l-1 0 1 -11c0,0 0,-1 0,-1 1,0 1,0 1,0zm21 3l-3 38c0,0 0,0 0,1 0,0 1,1 1,1l0 1 -7 0c-1,0 -1,-1 -1,-2 0,0 0,0 0,-1l3 -38c0,0 0,-1 0,-1 0,-1 0,-1 0,-1l0 -1 7 0c0,0 0,0 0,0 0,0 1,1 1,2l-1 1zm3 -3l14 0 -1 7c0,1 0,2 -1,2 0,1 0,1 -1,1l-3 0 -1 6 4 0 0 5c0,1 -1,2 -1,3 0,0 -1,1 -1,1l-2 0c0,0 -1,1 -1,2l-1 7 4 0c1,0 1,0 1,-1 0,0 1,0 1,-1l0 0 -1 10c0,1 0,1 0,2 0,0 -1,0 -1,0l-11 0c0,0 0,0 0,-1 0,0 0,0 0,-1 0,0 0,-1 0,-1l3 -38c0,0 0,-1 0,-1 0,-1 -1,-1 -1,-1l0 -1zm28 26l1 6c0,0 0,1 0,1 1,1 1,1 1,1l1 0 -1 7c0,2 -1,3 -2,3l-3 0c0,0 -1,0 -1,-1 0,0 -1,-1 -1,-2l-1 -13 -1 12 0 1c0,1 0,1 0,1 0,0 0,1 1,1l-1 1 -7 0c0,0 0,0 0,-1 0,0 0,0 0,-1 0,0 0,-1 0,-1l3 -38c0,0 0,-1 0,-1 0,-1 -1,-1 -1,-1l0 -1 12 0c3,0 4,3 4,9 0,1 0,2 0,2 0,1 0,2 0,3 -1,4 -1,6 -2,8 0,2 -1,3 -2,4zm-4 -16l-1 8 1 0c0,0 1,-2 1,-4 0,-1 0,-1 0,-1 0,-1 0,-1 0,-2 0,-1 0,-1 0,-1l-1 0z"/>
   <path class="fil0" d="M3 24l11 0c0,0 0,-1 0,-1 1,0 1,-1 1,-1l0 0 -1 10c0,1 0,2 -1,2l-1 0 -3 31c0,0 0,0 0,1 0,0 0,1 0,1l0 1 -7 0c0,0 0,-1 0,-2 0,0 0,0 0,-1l2 -31 -1 0c-1,0 -1,0 -1,0 0,1 0,1 0,2l-1 0 1 -11c0,0 0,-1 0,-1 1,0 1,0 1,0zm21 3l-3 38c0,0 0,0 0,1 0,0 1,1 1,1l0 1 -7 0c-1,0 -1,-1 -1,-2 0,0 0,0 0,-1l3 -38c0,0 0,-1 0,-1 0,-1 0,-1 0,-1l0 -1 7 0c0,0 0,0 0,0 0,0 1,1 1,2l-1 1zm3 -3l14 0 -1 7c0,1 0,2 -1,2 0,1 0,1 -1,1l-3 0 -1 6 4 0 0 5c0,1 -1,2 -1,3 0,0 -1,1 -1,1l-2 0c0,0 -1,1 -1,2l-1 7 4 0c1,0 1,0 1,-1 0,0 1,0 1,-1l0 0 -1 10c0,1 0,1 0,2 0,0 -1,0 -1,0l-11 0c0,0 0,0 0,-1 0,0 0,0 0,-1 0,0 0,-1 0,-1l3 -38c0,0 0,-1 0,-1 0,-1 -1,-1 -1,-1l0 -1zm28 26l1 6c0,0 0,1 0,1 1,1 1,1 1,1l1 0 -1 7c0,2 -1,3 -2,3l-3 0c0,0 -1,0 -1,-1 0,0 -1,-1 -1,-2l-1 -13 -1 12 0 1c0,1 0,1 0,1 0,0 0,1 1,1l-1 1 -7 0c0,0 0,0 0,-1 0,0 0,0 0,-1 0,0 0,-1 0,-1l3 -38c0,0 0,-1 0,-1 0,-1 -1,-1 -1,-1l0 -1 12 0c3,0 4,3 4,9 0,1 0,2 0,2 0,1 0,2 0,3 -1,4 -1,6 -2,8 0,2 -1,3 -2,4zm-4 -16l-1 8 1 0c0,0 1,-2 1,-4 0,-1 0,-1 0,-1 0,-1 0,-1 0,-2 0,-1 0,-1 0,-1l-1 0z"/>
   <path class="fil0" d="M71 24l13 0c1,0 3,0 4,2 1,1 1,3 1,5 0,1 0,1 0,2 0,0 0,0 0,1 -1,4 -2,7 -4,8 1,1 2,2 2,3 0,1 0,2 0,4 0,1 0,4 0,7 -1,5 -2,8 -3,10 -2,1 -3,2 -6,2l-12 0c0,0 -1,0 -1,-1 -1,0 -1,-1 -1,-2l0 -1 2 -9 0 0c0,1 1,1 1,2 0,0 1,1 1,1l6 0c1,0 1,-1 2,-1 0,-1 1,-2 1,-4 0,-1 0,-1 0,-2 0,-2 -1,-3 -2,-3l-4 0c-1,0 -1,-1 -1,-2l0 -1 0 -5 7 0c1,0 1,-1 1,-1 1,0 1,-1 1,-2l0 -2c0,-1 0,-1 -1,-1l-6 0c-1,0 -1,-1 -2,-1 0,0 0,-1 0,-1l0 -1 1 -7z"/>
  </g>
 </g>
   </svg>

}

export const ICON_INFO = (value) => {

    const className = value ? 'solo_menu_icon_checked' : 'solo_menu_icon';

    return <svg className = {className} xmlns="https://www.w3.org/2000/svg" width="112px" height="112px" version="1.1" viewBox="0 0 112 112">
        <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <g id="_2664916429360">
        <path class="fil0" d="M58 66c0,0 3,-8 4,-9l1 -3c0,-1 0,-2 0,-3 0,-1 0,-2 0,-3 0,0 -1,-1 -1,-1l-1 -1c-2,-1 -2,-1 -4,-1 -5,0 -9,2 -12,5l-1 1c0,0 1,2 1,2 1,-1 4,-3 6,-3 1,0 0,3 -1,4l0 1 0 1c0,0 -1,1 -1,2 0,0 0,1 -1,1l-3 8c0,1 0,2 -1,3 0,2 -1,3 0,5 0,1 0,2 0,2 1,0 1,0 1,1l0 0c1,0 2,1 2,1l1 0 2 1 2 -1 2 0c1,-1 1,-1 2,-1 0,0 0,0 0,-1 1,0 1,0 1,0l5 -4c0,0 0,0 1,0 0,-1 1,-2 2,-2l0 -1c-1,-1 -1,-1 -1,-2l-2 2c-1,0 -2,2 -3,2 0,0 0,0 0,0 -1,1 -2,1 -2,-1 0,-1 0,-2 1,-3l0 -1 0 0c0,-1 0,-1 0,-1z"/>
        <path class="fil0" d="M63 41l0 -1 1 0c0,0 0,0 1,0 0,0 1,-1 1,-1 0,0 1,-1 1,-1 0,-1 1,-1 1,-2l0 -2c0,-1 0,-2 0,-2 0,-1 -1,-1 -1,-2 0,0 0,0 0,0 -1,0 0,0 -1,0 0,0 0,-1 -1,-1 0,0 0,0 -1,0 -1,-1 -2,0 -3,0l-2 0c0,1 -1,1 -1,1 0,1 0,1 -1,1 0,0 0,1 -1,1 0,1 0,1 0,2l0 2c0,1 1,3 2,3 0,1 1,1 2,1 1,1 2,0 3,1z"/>
        <path class="fil0" d="M56 18c21,0 38,17 38,38 0,21 -17,38 -38,38 -21,0 -38,-17 -38,-38 0,-21 17,-38 38,-38zm45 38c0,-25 -20,-45 -45,-45 -25,0 -45,20 -45,45 0,25 20,45 45,45 25,0 45,-20 45,-45z"/>
        </g>
        </g>
   </svg>

}

export const ICON_ELEMENTS = (value) => {

    const className = value ? 'solo_menu_icon_checked' : 'solo_menu_icon';

    return <svg className = {className} xmlns="https://www.w3.org/2000/svg" width="112px" height="112px" version="1.1" viewBox="0 0 112 112">
        <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <g id="_2665445207104">
        <path class="fil0" d="M33 25l1 -6 0 -2c1,-5 5,-9 11,-8l34 4c5,0 9,5 8,10l-1 5 0 5 -3 22 -1 6 -2 23c-1,3 -2,6 -5,7 -2,1 -4,2 -6,2l-4 -1 -28 -3 -2 0c-1,0 -1,-1 -2,-1 0,0 -1,0 -1,0 -3,-2 -6,-6 -5,-10l0 0 2 -19 1 -5 0 -4 0 -1 0 -2 1 -3 0 -1 0 -2 2 -16zm53 49l5 -39 3 1c4,2 6,8 4,12l-12 26zm-66 -47l8 -1 -2 9c0,0 -1,-1 -1,-1 -3,0 -5,3 -5,5 0,3 2,5 5,5 0,1 0,0 0,0l0 1 -3 2c0,0 -1,1 -1,2 0,0 -1,1 -1,1 0,1 1,2 2,2l1 0 1 2 -3 26 -8 -43c-1,-4 2,-9 7,-10zm-13 8l12 61c1,6 7,9 12,8l17 -3 17 8c5,2 11,0 13,-5l5 -10c0,-1 1,-2 1,-3l20 -44c2,-6 0,-12 -5,-14l-7 -3 1 -10c0,-6 -4,-12 -10,-13l-41 -5c-6,0 -12,4 -13,10l-1 8 -13 3c-5,1 -9,6 -8,12zm25 59l15 2 -8 1 -6 2c-4,0 -9,-3 -10,-7l0 -3c2,3 4,5 8,5l1 0zm24 5l8 -1 8 1c1,0 2,0 3,0 -2,4 -7,6 -11,4l-8 -4z"/>
        <path class="fil0" d="M48 29l-7 1c-1,0 -2,1 -2,2 -1,0 -1,0 -1,1 -1,1 0,2 1,3l1 0 1 4 1 3 2 5 1 0 0 1 1 2 0 0 -1 2 -3 5 -2 4c0,1 0,1 0,1 0,1 0,1 0,1l-3 13c0,1 1,3 3,3 0,0 1,0 1,0 1,0 2,-1 2,-2l1 -2 2 -11 0 0 0 0 5 -11 2 -2c0,0 1,0 1,0l0 0 3 -2 3 -2 3 -2 0 0 0 0 2 -1 0 0 0 -1 0 0 2 0 1 0 0 0 9 -3c2,0 3,-2 2,-3 0,-2 -2,-3 -3,-2l-8 2 -1 0 -4 1c0,0 0,0 -1,0l0 0c0,0 -1,0 -1,1l-1 1 -2 1 -1 0 -1 1 -2 2 0 0 -1 -2 0 -1 -1 -8 0 -1 6 2 0 1c1,0 2,0 2,0 0,-1 1,-1 1,-1l0 0 3 -3 1 -1 4 -3c1,-1 1,-2 0,-2 0,-1 -1,-1 -2,0l-2 2 -5 3 0 1 0 0 -6 -2 -1 -1 0 0c1,-1 0,-2 -1,-2 0,-1 -1,0 -2,1l0 0c0,0 -1,0 -1,1l0 0c-1,0 -1,0 -1,0z"/>
        <path class="fil0" d="M44 28c2,1 4,1 5,0 1,-1 2,-2 2,-3 0,-1 1,-1 0,-2 0,-1 0,-1 0,-2 0,-1 -2,-3 -3,-3 -3,-1 -6,0 -7,3 -1,1 -1,2 -1,2 0,3 2,5 4,5z"/>
        </g>
        </g>
   </svg>

}

export const ICON_CHAMPIONSHIPS = (value) => {

    const className = value ? 'solo_menu_icon_checked' : 'solo_menu_icon';

    return <svg className = {className} xmlns="https://www.w3.org/2000/svg" width="112px" height="112px" version="1.1" viewBox="0 0 112 112">
        <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <g id="_2665207221872">
        <path class="fil0" d="M56 40c2,1 1,1 0,1 2,0 3,0 2,-1 0,0 1,-1 1,-1 1,0 1,0 2,-1 0,0 0,0 0,-1l1 0 0 0 2 1c0,1 0,3 -1,3 -2,0 -2,0 -1,0 0,0 1,0 2,0 1,-1 0,-1 0,-3l3 2 0 0c0,0 -1,0 -1,0 -1,1 -1,1 1,1 1,0 1,0 2,-1 0,0 0,-1 -1,-1 0,0 -1,0 -1,-1l0 0c-1,0 -1,0 -1,0 1,0 2,0 2,0 1,0 1,-1 1,-1 0,-1 0,-1 0,-1l0 -1c-1,-1 -4,-4 -5,-4l0 0 0 0c-1,0 -1,-1 -1,-1 0,0 0,-1 0,-1 -1,-1 -1,-2 -2,-2 -1,1 -1,2 -1,3 0,1 1,1 2,1 0,0 0,0 0,0l0 1c0,0 0,0 -1,0l0 1 -1 0 -3 -1 0 0c0,-1 0,-1 0,-1 0,0 -1,0 -1,0 0,0 0,0 0,0 -1,0 -1,0 -1,1l0 0 -2 1 -1 0 -1 -1c0,0 0,0 -1,0l0 -1c0,0 0,0 1,0 1,0 1,0 2,-1 0,-1 -1,-2 -1,-3 -1,0 -2,1 -2,2 0,0 0,1 0,1 0,0 -1,1 -1,1l0 0 -1 0c-1,0 -3,3 -4,4l0 1c-1,0 -1,0 -1,1 0,0 1,1 1,1 1,0 2,0 2,0 0,0 0,0 0,0l0 0c-1,1 -1,1 -2,1 0,0 -1,1 0,1 0,1 0,1 1,1 2,0 3,0 1,-1 0,0 0,0 -1,0l1 0 2 -2c0,2 -1,2 1,3 0,0 1,0 1,0 1,0 1,0 0,0 -2,0 -1,-2 -1,-3l2 -1 0 0 0 0c0,1 0,1 1,1 0,1 1,1 2,1 0,0 0,1 0,1 0,1 1,1 2,1 0,0 -1,0 0,-1zm-8 -5c1,0 2,-1 2,-1l0 0 1 0 0 0 1 1c0,0 1,0 1,0 0,0 0,0 0,-1l3 -2 3 2c0,1 1,1 1,1 0,0 0,0 0,0l1 -1 1 0 0 0 0 0c1,0 2,1 2,1 2,0 2,0 2,0l-2 0 -2 0 0 0c0,0 0,0 -1,0l0 0 0 0c0,0 0,0 -1,1 0,0 -1,1 -1,2 -1,1 -2,1 -2,2 0,0 0,0 -1,0l0 0 0 0c0,0 0,0 -1,0 0,-1 0,-1 -1,-2 -1,-1 -2,-2 -2,-2 0,-1 0,-1 0,-1l-1 0 0 0c0,0 0,0 0,0l-1 0 -1 0 -2 0c0,0 0,0 1,0z"/>
        <path class="fil0" d="M38 18l36 0c3,48 -39,46 -36,0zm-7 3c0,3 1,10 2,13 0,2 1,3 1,5l2 5c-2,0 -7,-2 -9,-4 -5,-4 -8,-10 -9,-17 -1,-4 -1,-8 -1,-13l12 4c0,1 0,0 -1,1l-3 2 4 5c1,0 0,0 2,-1zm-20 -16c-1,11 -1,24 6,33 1,2 2,4 4,5 4,5 11,8 17,9 4,0 2,-1 6,4l3 2 -3 0 0 7 5 0 0 3 1 0c0,7 -3,13 -8,19l-2 2c0,0 0,0 -1,0 0,1 -1,1 -1,2l0 13 -5 0 0 6 47 0 0 -6 -6 0 0 -13c0,-1 0,-1 0,-1l-2 -1c-2,-2 -4,-5 -5,-7 -2,-4 -4,-8 -4,-14 1,0 1,0 1,-3l4 0 0 -7 -3 0 3 -3c1,-1 3,-2 3,-3 4,0 7,-2 10,-3 4,-2 6,-3 9,-6 1,0 1,0 2,-1 7,-8 10,-17 10,-28 0,-4 -1,-9 -1,-12 -2,0 -11,2 -13,3 -2,0 -4,1 -6,2l-3 0 -2 1c0,0 -1,0 -1,1l0 3 -40 0 0 -4c-2,0 -21,-5 -25,-5 0,1 0,1 0,2zm67 34c1,-3 2,-8 3,-12l0 -6c1,0 1,1 2,1 1,-1 3,-5 4,-6l-4 -2c0,0 0,0 -1,-1 2,0 11,-3 13,-3 0,8 0,16 -5,23 -1,3 -3,5 -6,7 -1,1 -6,4 -8,4l2 -5z"/>
        </g>
        </g>
   </svg>

}

export const ICON_PERSON = (value) => {

    const className = value ? 'solo_menu_icon_checked' : 'solo_menu_icon';

    return <svg className = {className} xmlns="https://www.w3.org/2000/svg" width="112px" height="112px" version="1.1" viewBox="0 0 112 112">
        <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <g id="_2665379023120">
        <path class="fil0" d="M46 71l0 -2c3,3 6,4 10,4 4,0 7,-1 10,-4l0 2c0,4 2,8 7,9 14,3 17,0 23,20l0 1c0,2 -18,4 -40,4 -22,0 -40,-2 -40,-4l0 -1c6,-20 9,-17 23,-20 5,-1 7,-5 7,-9zm10 -5c-5,0 -9,-2 -12,-5l-1 2c0,6 -2,11 -8,12 -19,5 -22,1 -30,28l-1 1c0,3 24,6 52,6 28,0 52,-3 52,-6l-1 -1c-8,-27 -11,-23 -30,-28 -6,-1 -8,-6 -8,-12l-1 -2c-3,3 -7,5 -12,5z"/>
        <path class="fil0" d="M34 32c0,10 4,19 10,24 4,4 8,5 12,5 4,0 8,-1 12,-5 6,-5 10,-14 10,-24 0,-17 -10,-30 -22,-30 -12,0 -22,13 -22,30zm22 20c-3,0 -6,-1 -8,-4 -4,-3 -7,-9 -7,-16 0,-11 7,-20 15,-20 8,0 15,9 15,20 0,7 -3,13 -7,16 -2,3 -5,4 -8,4z"/>
        </g>
        </g>
   </svg>

}