import React from 'react';

export default () => <svg width="24.25mm" height="24.25mm"
    viewBox="0 0 2425 2425" >
    <g id="Слой_x0020_1">
        <g id="_2228782669328">
            <path class="ui_icon__fill" d="M64213 185544c-37,-4 -74,-6 -112,-6 -514,0 -931,416 -931,930 0,514 417,931 931,931 514,0 930,-417 930,-931 0,-122 -23,-238 -66,-345l-864 345 112 -924z" />
            <path class="ui_icon__fill" d="M64325 185398l-113 924 865 -345c-124,-310 -410,-537 -752,-579z" />
        </g>
        <g id="_2228782666864">
            <path class="ui_icon__fill" d="M619 2087l1248 0 1 -264c-14,-51 -39,-63 -99,-64 -55,0 -112,0 -168,1 -33,18 -46,58 -102,60 -28,26 -304,14 -364,14 -54,0 -138,5 -188,-3 -73,-11 -87,-54 -125,-72 -78,0 -225,-25 -258,42 -14,29 -11,241 -5,286l60 0z" />
            <path class="ui_icon__fill" d="M960 1761c100,-9 235,-1 339,-1 125,0 208,24 252,-80 4,-55 -9,-89 -41,-115 -47,-37 -78,-2 -117,-39 -40,-22 -84,-91 -54,-164 26,-62 63,-72 135,-100 163,-62 158,-113 214,-138 155,-71 230,-86 365,-239 37,-42 67,-84 86,-148 29,-93 11,-122 -17,-198 -101,-118 -129,-116 -294,-62l2 -138 -322 -1 -910 0 -1 138c-112,-37 -175,-63 -261,24 -189,193 70,482 335,597 55,23 75,19 112,60 115,126 279,91 310,223 13,55 -12,102 -37,130 -55,61 -76,14 -131,48 -34,21 -61,62 -52,112 11,59 44,64 87,91zm785 -744c53,-165 72,-252 81,-446 74,-53 174,-90 227,7 62,70 -2,180 -41,236 -30,42 -115,124 -167,149l-100 54zm-1143 -420c13,231 21,223 79,421 -106,-39 -168,-105 -235,-167 -22,-15 -65,-79 -79,-115 -48,-118 18,-237 129,-215 42,8 103,28 106,76z" />
        </g>
    </g>
</svg>