import React, { PureComponent } from 'react';
import GroupBigPlate from 'src/ver2/components/group/groupBigPlate';
import Loader from 'src/ver2/components/loader';
import { withCapoeiraSportState } from 'src/ver2/context';

import { orderBy } from 'lodash';

import './style.css';

//getEventById

/**
  Это полностью автономный список, который выводит группы.

  Входной параметр для него - это mode, лист сам всё подгружает и выставляет свою высоту

  mode - { name: 'school', schoolId } - 
  Мы берём группы, которые принадлежат данной школе, по каждой из них подгружаем Students
  После чего сортируем по количеству медалей ( Gold - 3, Silver - 2, Bronze - 1)


 */

class GroupsBigListPlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            Groups: [],
            status: 'loading'
        }

        this.oneHeight = 60;

        if (props.isMobile) this.oneHeight = 50;

        this.oneMargin = 4;
    }

    /*
            const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: height + 45, plashkaId });
    */

    componentDidMount() {
        if (this.props.groupsLoaded) {
            this.initialLoad();
        }
    }

    componentDidUpdate({ groupsLoaded, mode }) {
        if (!groupsLoaded && this.props.groupsLoaded) {
            this.initialLoad();
        }
        if (mode.region !== this.props.mode.region) {
            // this.setState({ status: 'loading' });
            this.initialLoad();
        }
    }


    initialLoad = async () => {
        const { client, mode } = this.props;

        if (!mode) return;

        let Groups = [];

        if (mode.name === 'school') {
            Groups = await client.getGroupsWithMedals({ SchoolId: mode.schoolId });
        }
        if (mode.name === 'region') {
            Groups = await client.getGroupsWithMedals({ Region: mode.region });
        }

        for (let i = 0; i < Groups.length; i++) {
            const Group = Groups[i];

            const { Amount, Gold, Silver, Bronze } = Group;

            const Medals = { Gold, Silver, Bronze, Scores: Gold * 25 + Silver * 7 + Bronze * 1 };

            Groups[i].Count = Amount;
            Groups[i].Medals = Medals;
            Groups[i].Scores = Medals.Scores;
        }

        Groups = orderBy(Groups, ['Scores'], ['desc']);

        this.setState({ Groups, status: 'loaded' });
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: Groups.length * (this.oneHeight + this.oneMargin) + 60, plashkaId });
    }

    medalsCount = (Students) => {

        if (!Students || !Students.length) return { Scores: 0 };

        let result = { Gold: 0, Silver: 0, Bronze: 0 };
        for (let i = 0; i < Students.length; i++) {
            if (Students[i].Gold) result.Gold += Students[i].Gold;
            if (Students[i].Silver) result.Silver += Students[i].Silver;
            if (Students[i].Bronze) result.Bronze += Students[i].Bronze;
        }

        if (!result.Gold && !result.Silver && !result.Bronze) return { Scores: 0 };

        return { ...result, Scores: result.Gold * 3 + result.Silver * 2 + result.Bronze * 1 };
    }

    render() {

        const { mode, upperStyle } = this.props;
        const { status, Groups } = this.state;


        const { width, height } = upperStyle;

        if (status === 'loading') return <div style={{ width, height }} className='groups_big_list_plashka'>
            <Loader />
        </div>

        if (status === 'nogroups') return <div style={{ width, height }} className='groups_big_list_plashka'>
            <div className='global_plashka_no_data'>
                <span>
                    НЕТ ГРУПП
                </span>
            </div>
        </div>


        return <div style={{ width, height, rowGap: this.oneMargin }} className='groups_big_list_plashka'>
            {Groups.map((group) => {
                const { Id } = group;
                return <GroupBigPlate key={'gblp' + Id} group={group} width={width} height={this.oneHeight} />
            })}
        </div>
    }

}

export default withCapoeiraSportState('client, groupsLoaded, isMobile')(GroupsBigListPlashka);