import React from 'react';

import EventAvatar from 'src/ver2/components/event/avatar';
import { UpperStatus } from 'src/ver2/components/event/upperStatus';
import { eventStatus as eventStatus_ } from 'src/ver2/pages/event/context';
import { eventLinkText } from 'src/ver2/components/event/little';
import { withCapoeiraSportState } from 'src/ver2/context';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

import './inlarge.css';

const InlargeEventRow = ({ Event, ShowPast = false, client, height = 50, lastBlock, isMobile }) => {

    const { Id, Title, Date: Date_, StartPreliminarilyDate, IsPreliminarily, Championship } = Event;
    // const { filter: { ShowPast } } = this.state;
    // const { client } = this.props;

    const eventStatus = () => eventStatus_(Event);

    if (!ShowPast && eventStatus() === 'past') return null;

    let region = '';
    if (Championship && Championship.Region && client.regions[Championship.Region]) {
        region = client.regions[Championship.Region].title;
    }

    if (isMobile) {
        return <div style={{ height }} className='inlarge_event_row'>
            <div className='inlarge_event_row_logo'>
                <EventAvatar
                    withExtra={true}
                    eventId={Id}
                    size={height * 0.75}
                />
            </div>
            <div className='inlarge_event_row_title'>
                <span className='inlarge_event_row_title__upper'>{eventLinkText(Event)}</span>
                <div className='inlarge_event_row_title__down'>
                    <div className='inlarge_event_row_dates'>
                        <span>{IsPreliminarily ? moment(StartPreliminarilyDate, 'MM.YYYY').locale('ru').format('MMMM') : moment(Date_, 'DD.MM.YYYY').locale('ru').format('DD MMMM')}</span>
                    </div>
                    <div className='inlarge_event_row_status'>
                        <UpperStatus {...{ client, eventStatus, noTooltip: true, className: '__status_default' }} />
                    </div>
                </div>
            </div>
            <div className='inlarge_event_row_region'>
                {lastBlock ? lastBlock :
                    <span>{region}</span>
                }
            </div>
        </div>
    }

    return <div style={{ height }} className='inlarge_event_row'>
        <div className='inlarge_event_row_logo'>
            <EventAvatar
                withExtra={true}
                eventId={Id}
                size={height * 0.9}
            />
        </div>
        <div className='inlarge_event_row_title'>
            <span>{eventLinkText(Event)}</span>
        </div>
        <div className='inlarge_event_row_dates'>
            <span>{IsPreliminarily ? moment(StartPreliminarilyDate, 'MM.YYYY').locale('ru').format('MMMM') : moment(Date_, 'DD.MM.YYYY').locale('ru').format('DD MMMM')}</span>
        </div>
        <div className='inlarge_event_row_status'>
            <UpperStatus {...{ client, eventStatus, noTooltip: true, className: '__status_default' }} />
        </div>
        <div className='inlarge_event_row_region'>
            {lastBlock ? lastBlock :
                <span>{region}</span>
            }
        </div>
    </div>

}

export default withCapoeiraSportState('client, isMobile')(InlargeEventRow);