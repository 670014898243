import React, { PureComponent } from 'react';
import { withPersonState } from 'src/ver2/pages/person/context';
// import { withCapoeiraSportState } from 'src/ver2/context';
import PersonWrapper from 'src/ver2/components/person/personWrapper.js';
import Loader from 'src/ver2/components/loader';
import PersonWallComponent from './components/wall';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'

import './style.css';

class PersonWall extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }
    /* 
        Это верхний компонент, он должен:
        1 - пропарсить предметы, которые есть у пользователя, и в том числе, которые у него на стене
        2 - пропарсить медали и выдать, какие медали есть, + добавить особые медали в список предметов
        3 - посмотреть кастомизацию стены

        Сформировать это дело и выдать status: 'ready'

        // методы
        removeItemFromWall { id }
        addItemToWall { id, x, y }
        moveItem { id, x, y }

        // прокидывает Items [] - это именно те объекты, которые могут быть на стене, или находятся на ней
        {
            uid,
            name,
            isUsed
        }

    */

    state = {
        status: 'loading'
    }

    componentDidMount = () => {
        // const Items = [
        //     {
        //         uid: 123,
        //         name: 'flag_brazil_t1',
        //         x: 1,
        //         y: 0,
        //         isUsed: true
        //     },
        this.checkForItems();
    }

    componentDidUpdate = ({ isItemsReady, Items }) => {
        if (Items !== this.props.Items || !isItemsReady && isItemsReady) {
            this.checkForItems();
        }
    }

    shouldComponentUpdate = ({ iamuser, Items, iAmPerson, isItemsReady, upperStyle: { height } }, { status, changes, Items: stateItems }) => {
        if (status !== this.state.status) return true;
        if (changes !== this.state.changes) return true;
        if (stateItems !== this.state.Items) return true;

        if (iamuser !== this.props.iamuser) return true;
        if (isItemsReady !== this.props.isItemsReady) return true;
        if (Items !== this.props.Items) return true;
        if (iAmPerson !== this.props.iAmPerson) return true;
        if (height !== this.props.upperStyle.height) return true;

        return false;
    }

    checkForItems = () => {

        const { Items } = this.props;

        if (!Items) {
            return this.setState({
                status: 'loading',
            })
        }

        let InitialItems = [];

        if (Items.stuff) InitialItems = [...InitialItems, ...Items.stuff];
        if (Items.nagrada) InitialItems = [...InitialItems, ...Items.nagrada];

        if (InitialItems.length > 0) {
            return this.setState({
                status: 'loaded',
                changes: false,
                Items: InitialItems,
                InitialItems: InitialItems
            })
        } else {
            this.setState({
                status: 'noitems'
            })
        }

        // if (this.props.Items.stuff) {
        //     return this.setState({
        //         status: 'loaded',
        //         changes: false,
        //         Items: this.props.Items.stuff,
        //         InitialItems: { ...this.props.Items.stuff }
        //     })
        // } else {
        //     this.setState({
        //         status: 'noitems'
        //     })
        // }
    }

    saveChanges = async () => {

        const { Items } = this.state;
        const { client } = this.props;

        this.setState({
            status: 'saving'
        })

        // пока всё сохраняем
        for (let i = 0; i < Items.length; i++) {

            const { Id, isUsed, x, y } = Items[i];

            if (isUsed) {
                await client.updateUserItem(Id, `{"x": ${x}, "y": ${y}}`);
            } else {
                await client.updateUserItem(Id, 'false');
            }
        }

        this.setState({
            status: 'loaded',
            changes: false,
            InitialItems: { ...Items }
        })

    }

    // пока через state
    moveItem = ({ uid, x, y }) => {
        let Items = [...this.state.Items];
        let draggedItem = null;

        let fromY = 0;
        let fromX = 0;

        for (let i = 0; i < Items.length; i++) {
            if (Items[i].uid === uid) {

                fromX = Items[i].x;
                fromY = Items[i].y;

                Items[i].x = x;
                Items[i].y = y;
                Items[i].isUsed = true;
                draggedItem = Items[i];
                break;
            }
        }

        const { subtype } = draggedItem || {};

        // теперь если это полка - нужно перетащить полностью всё
        if (subtype === 'shalve') {
            const PlantItems = Items.filter(x => (x.subtype === 'plant' || x.subtype === 'medal_shalve' || x.subtype === 'toy')) || [];
            for (let i = 0; i < PlantItems.length; i++) {
                const PlantItem = PlantItems[i];
                if (!PlantItem.isUsed) continue;

                // пока думаем, что все полки у нас по высоте 1
                if (PlantItem.y !== fromY) continue;

                if (
                    (PlantItem.x) >= fromX &&
                    (PlantItem.x) < (fromX + draggedItem.w)
                ) {
                    // пока вот так криво
                    for (let j = 0; j < Items.length; j++) {
                        if (Items[j].uid === PlantItem.uid) {
                            Items[j].x += x - fromX;
                            Items[j].y += y - fromY;
                            break;
                        }
                    }
                }
            }
        }

        this.setState({ Items, changes: true })
    }

    removeItemFromWall = ({ uid }) => {
        let Items = [...this.state.Items];
        for (let i = 0; i < Items.length; i++) {
            if (Items[i].uid === uid) {
                Items[i].isUsed = false;
                break;
            }
        }
        this.setState({ Items, changes: true })
    }

    render() {

        const { iamuser, Person, client, isItemsReady, iAmPerson, upperStyle, isMobile } = this.props;
        if (!isItemsReady) return <div className='person_wall'>
            <Loader text='Загрузка стены' />
        </div>

        const { status, changes } = this.state;

        const { width, height } = upperStyle;
        // это список объекторв
        const { Items } = this.state;

        const Person_ = iAmPerson ? iamuser : Person;

        let wallStyle = {};

        let CELLS_X = 8;
        let CELLS_Y = 11;

        if (Person_ && Person_.VSettings) {
            const VSettingsObj = JSON.parse(Person_.VSettings);
            if (VSettingsObj && VSettingsObj.paint && VSettingsObj.paint.oboi) {
                const oboiItem = client.getAbstractItem(VSettingsObj.paint.oboi);
                if (oboiItem && oboiItem.wallStyle) {
                    wallStyle = oboiItem.wallStyle;
                }
            }
            if (VSettingsObj && VSettingsObj.paint && VSettingsObj.paint.larger) {
                const oboiItem = client.getAbstractItem(VSettingsObj.paint.larger);
                if (oboiItem && oboiItem.CELLS_X  && oboiItem.CELLS_Y) {
                    CELLS_X = oboiItem.CELLS_X;
                    CELLS_Y = oboiItem.CELLS_Y;
                }
            }
        }

        // if (!iAmPerson && status === 'noitems') {
        if (status === 'noitems') {
            const { setComponentSelfHeight, plashkaId } = this.props;
            setComponentSelfHeight({ height: -1, plashkaId });
            return <div></div>
        }

        
        const backend = isMobile ? TouchBackend : HTML5Backend;

        return <div style={{ width, height }} className={'person_wall'}>
            <DndProvider backend={backend} options={{}}>
                <PersonWallComponent
                    Items={Items}
                    iamowner={iAmPerson}
                    upperStyle={upperStyle}
                    status={status}
                    changes={changes}
                    wallStyle={wallStyle}

                    saveChanges={this.saveChanges}

                    CELLS_X = {CELLS_X}
                    CELLS_Y = {CELLS_Y}

                    moveItem={this.moveItem}
                    removeItemFromWall={this.removeItemFromWall}
                />
            </DndProvider>
        </div>
    }
}

// const PersonWallWrapped = (props) => <PersonWrapper Wrapped={PersonWall} personId={props.personId} withItems={true} componentProps={{ ...props }} />
export default withPersonState(`
iamuser,
client,
isMobile,
Person,
Items,
isItemsReady,
iAmPerson
`

)(PersonWall);