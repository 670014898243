import React, { Component } from 'react';
import * as THREE from 'three';
import { createMedal, createBoxMedal } from './objects'
import { withCapoeiraSportState } from 'src/ver2/context';
import './component.css';


class Medal extends Component {


  /*
       props:
          Id = {goId}
          eventId = {eventId}
          drawWidth = {widthCome}
          bg = {Item.Item || 'bronze'}
          noRotate = {noRotate}
          shape = {Shape}
          develop = {develop}

          withCount
  */

  constructor(props) {
    super(props)

    const { eventId, 
      // develop, 
      client, schoolId } = props;

      const develop = false;

    if (schoolId) {
      this.medalImg = new THREE.TextureLoader().load('/../images/schools/' + schoolId + '/logo.png');
    } else {

      if (develop)
        this.medalImg = new THREE.TextureLoader().load('/../images/ver2/events/' + eventId + '_logo.png')
      else
        this.medalImg = new THREE.TextureLoader().load(client.getEventsUrl() + eventId + '_logo.png')
    }
  }

  componentDidMount(props) {

    this.canvas = document.getElementById(this.myCanvasId);

    this.prepareThree();
    this.three_render();

    /* СИСТЕМА ДВИЖЕНИЯ */

    this.camera_koef_now = 0.5; // это кэф от 0 до 1, движение влево-вправо
    this.camera_koef_i_want = 0.5;
    this.camera_go_left = true;

    this.last_mouse_move = 30;

    this.interval = setInterval(this.rotate, 10);

  }

  prepareThree = () => {

    const { drawWidth, shape, 
      // develop 
    } = this.props;

    const develop = false;

    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, alpha: true });

    const aspect = 1;

    this.renderer.setSize(drawWidth * 2, drawWidth * 2, false);

    // this.renderer.setPixelRatio(window.devicePixelRatio * 3);

    const fov = 60;
    //const aspect = 2;  // значение для canvas по умолчанию
    const near = 0.1;
    const far = 25;

    this.three_camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    this.three_camera.position.z = 11;

    this.three_scene = new THREE.Scene();


    this.three_light = new THREE.DirectionalLight(this.lightColor(), 0.9);
    this.three_light.position.set(0, 1, 2);

    this.three_light_2 = new THREE.DirectionalLight(0xFFFFFF, 0.7);
    this.three_light_2.position.set(0, 0, 4);

    this.three_light_3 = new THREE.SpotLight(0x0000FF, 1);
    this.three_light_3.position.set(0, 0, 1);

    this.three_scene.add(this.three_light);
    this.three_scene.add(this.three_light_2);
    this.three_scene.add(this.three_light_3);


    if (!shape || shape === 'circle') {
      this.golden_medal = createMedal(
        this.medalImg,
        //new THREE.TextureLoader().load('../../images/' +  this.materialTexture())
        develop ?
          new THREE.TextureLoader().load('/../images/ver2/textures/' + this.materialTexture())
          :
          new THREE.TextureLoader().load('https://capoeirasport.com/graphics/' + this.materialTexture())
      )
    }

    if (shape === 'box') {
      this.golden_medal = createBoxMedal(
        this.medalImg,
        //new THREE.TextureLoader().load('../../images/' +  this.materialTexture())
        develop ?
          new THREE.TextureLoader().load('/../images/ver2/textures/' + this.materialTexture())
          :
          new THREE.TextureLoader().load('https://capoeirasport.com/graphics/' + this.materialTexture())
      )
    }

    this.golden_medal.position.z = 4;
    this.golden_medal.position.x = 0;

    this.three_scene.add(this.golden_medal);

    if (this.props.noRotate) {

      let rotate = -4 + 0.3 * 8;
      if (this.props.noRotate.rotate) rotate = this.props.noRotate.rotate;

      this.three_camera.position.set(rotate, 0, 11);
      this.three_camera.lookAt(0, 0, 0);

      this.three_light.position.set(-3 + 0.3 * 6, 1, 2);
    }

    //this.three_scene.fog = new THREE.FogExp2(0xFFFFFF, 0.1);
  }

  materialTexture = () => {

    const { bg } = this.props;

    if (bg === 'gold') return 'gold.jpg'
    if (bg === 'silver') return 'silver.png'
    if (bg === 'bronze') return 'bronze.jpg'

  }

  lightColor = () => {

    const { bg } = this.props;

    if (bg === 'gold') return '#ffd700'
    if (bg === 'silver') return '#f2f2f2'
    if (bg === 'bronze') return '#cd7f32'

  }

  rotate = () => {
    if (!this.golden_medal) return;

    /* это местный таймер */

    /*

    
    this.camera_koef_now = 0.5; // это кэф от 0 до 1, движение влево-вправо
    this.camera_koef_i_want = 0.5;
    this.camera_go_left = true;

    */

    let camera_step = 0.0009;

    if (Math.abs(this.camera_koef_now - this.camera_koef_i_want) > 0.0009) {
      camera_step = Math.abs(this.camera_koef_now - this.camera_koef_i_want) / 45;
    }

    if (camera_step < 0.0009) camera_step = 0.0009;

    this.last_mouse_move--;

    // т.е. камера будет ходить сама по себе
    if (this.last_mouse_move < 0) {

      if (this.camera_go_left) this.camera_koef_i_want -= camera_step;
      if (!this.camera_go_left) this.camera_koef_i_want += camera_step;

      if (this.camera_koef_i_want > 1) this.camera_go_left = true;
      if (this.camera_koef_i_want < 0) this.camera_go_left = false;

    }

    if (this.camera_koef_now < this.camera_koef_i_want) this.camera_koef_now += camera_step;
    if (this.camera_koef_now > this.camera_koef_i_want) this.camera_koef_now -= camera_step;

    if (Math.abs(this.camera_koef_now - this.camera_koef_i_want) < camera_step) {
      this.camera_koef_now = this.camera_koef_i_want;
    }


    //this.golden_medal.material.uniforms.goldy.value += 0.005;

    if (!this.three_camera) return;

    if (!this.golden_medal) return;

    this.golden_medal.children[0].material.uniforms.Time.value = -4 + this.camera_koef_now * 8;
    
    this.three_light.position.set(-3 + this.camera_koef_now * 6, 1, 2);

    if (this.props.noRotate) {
      this.three_render();
      return;
    }

    this.three_camera.position.set(-4 + this.camera_koef_now * 8, 0, 11);
    this.three_camera.lookAt(0, 0, 0);

    this.three_render();

  }

  three_render = () => {
    if (this.props.noRender) return;
    this.renderer.render(this.three_scene, this.three_camera);
  }

  onMouseMove = (e) => {
    const { layerX } = e.nativeEvent;
    const { drawWidth } = this.props;
    if (layerX > 0 && layerX < drawWidth)
      this.camera_koef_i_want = layerX / drawWidth;
    this.last_mouse_move = 220;
  }

  get myCanvasId() {
    return `solo-medal-canvas-${this.props.Id}`
  }

  render() {

    //if (this.renderer)
    //this.three_render()

    const {
      drawWidth,
      withCount,
      withCountClassName
    } = this.props;

    const style = {
      width: drawWidth,
      height: drawWidth,
      ...this.props.onClick ? { cursor: 'pointer'} : {}
    }

    return (
      <div style={style} className='medal-div' onClick={this.props.onClick} onMouseMove={this.onMouseMove}>
        <canvas style={style} id={this.myCanvasId} className='medal-div-canvas'>
        </canvas>
        {withCount && <div className = {'medal-div-with-count ' + (withCountClassName || '')}> <span>{withCount}</span> </div>}
      </div>
    );
  }

}

export default React.memo(withCapoeiraSportState('client')(Medal));