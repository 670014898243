import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import SchoolWrapper from 'src/ver2/components/school/schoolWrapper';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import { TitledPlashka, TITLE_HEIGHT } from 'src/ver2/containers/lowlavel/title';
import Loader from 'src/ver2/components/loader';

import './style.css';

class SchoolRaitingPlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            keyToShow: 0,
            loadedRating: null
        }
    }

    //changeKey
    setKeyToShow = (keyToShow) => {
        this.setState({ keyToShow });
    }

    componentDidMount() {
        this.loadRaiting();
    }

    componentDidUpdate({ School }, { keyToShow }) {
        if (keyToShow !== this.state.keyToShow) {
            this.loadRaiting();
        }
        if (!School && this.props.School) {
            this.loadRaiting();
        }

    }

    loadRaiting = () => {
        const { keyToShow } = this.state;
        const { client, School } = this.props;
        const id = School ? School.Id : 0;
        if (!id) return null;
        const season = client.raitingSeasons[keyToShow];
        client.getSubjectRaitingFromServer({ subject: 'school', id, season, as_sum: true, type: 'comp_pro' }).then((loadedRating) => {
            this.setState({ loadedRating });
        });
        this.setState({ loadedRating: 'loading' });
    }

    get height() {
        const { upperStyle: { height } } = this.props;
        return height - TITLE_HEIGHT;
    }

    render() {

        const { School, upperStyle } = this.props;

        let Raiting = this.state.loadedRating;

        // const { width, height } = upperStyle;

        return <TitledPlashka titleComponent={<this.RaitingSwitch />} upperStyle={upperStyle}>
            <div className='school_raiting_plashka'>
                {
                    (Raiting && Raiting !== 'loading' && Raiting.length > 0)
                        ?
                        Raiting.map((currentRaiting, index) => {
                            return <this.OneRaitingBlock key={'srp' + (School ? School.Id : '0') + '-' + index} currentRaiting={currentRaiting} />
                        })
                        :
                        (Raiting === 'loading')
                            ?
                            <Loader/>
                            :
                            'Не было участия в соревнованиях'
                }
            </div>
        </TitledPlashka>

    }

    RaitingSwitch = () => {
        const { client: { raitingSeasons } } = this.props;
        const { keyToShow } = this.state;
        return <div style={{ display: 'flex', flexDirection: 'row' }}>
            Рейтинг
            {raitingSeasons.map((value, index) => {
                return <div onClick={() => { this.setKeyToShow(index) }} className={'school_raiting_plashka-switch-item ' + (keyToShow === index ? 'school_raiting_plashka-switch-item__selected' : '')} >
                    {value}
                </div>
            })}
        </div>
    }

    OneRaitingBlock = ({ currentRaiting }) => {
        const height = this.height;
        const { AllScores } = currentRaiting;
        return <div className='school_raiting_plashka__oneraiting'>
            <this.PlaceBlock currentRaiting={currentRaiting} />
            <NumberText count={AllScores} text={'ОЧКИ'} height={height * 0.8} />
        </div>
    }


    PlaceBlock = ({ currentRaiting }) => {
        const { client: { RaitingTypeNames } } = this.props;

        const height = this.height;

        const width = height * 3;
        const fontSize = height * 0.3;

        const {
            PlaceName,
            Category,
            Place,
            Type
        } = currentRaiting

        return <div style={{ width, height: height * 0.9 }} className={'school_raiting_plashka__oneraiting__place_container'}>

            <div style={{ fontSize: fontSize * 2, height: height * 0.8, minWidth: height * 0.8 }} 
            className= {'school_raiting_plashka__oneraiting__place_container_left ' 
            + `school_raiting_plashka__oneraiting__place_container_left_place_${Place}`}>
                {`${PlaceName || '-'}`}
            </div>
            <div className='school_raiting_plashka__oneraiting__place_container_right'>
                <div style={{ fontSize: fontSize * 1.5 }} className='school_raiting_plashka__oneraiting__place_container_middle'>
                    МЕСТО
                </div>
                <div style={{ fontSize: fontSize * 0.5 }} className='school_raiting_plashka__oneraiting__place_container_down'>
                    {`КАТЕГОРИЯ: ${RaitingTypeNames[Type]}`}
                </div>
            </div>

        </div>

    }

}

const SchoolRaitingPlashkaWrapped = (props) => <SchoolWrapper Wrapped={SchoolRaitingPlashka} schoolId={props.schoolId} componentProps={{ ...props }} />
export default withCapoeiraSportState('iamuser, client')(SchoolRaitingPlashkaWrapped);