import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';
import PersonWrapper from 'src/ver2/components/person/personWrapper.js';
import { personDataAttentions } from 'src/ver2/components/person/dataHelper';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class PersonNoDataPlashka
// classname: person_nodata_plashka

/**
  Это плашка, которая показывает, что у нас не заполнены какие-то поля
  и даёт возможность их заполнить
 */

class PersonNoDataPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        componentHeight: null,
        personDataAttentionsResult: null
    }

    /*
        Мы замонтировали компонент - он пока будет получать данные от враппера
    */

    checkPersonDataAttentions = () => {
        const { Person } = this.props;
        if (!Person) {
            return this.setState({personDataAttentionsResult: null})
        }

        const personDataAttentionsResult = personDataAttentions (Person);
        this.setState({
            personDataAttentionsResult
        })

    }

    setComponentHeight = (componentHeight) => {
        if (componentHeight === this.state.componentHeight) return;
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ plashkaId, height: componentHeight });
        this.setState({ componentHeight })
    }

    componentDidMount () { 
        this.checkPersonDataAttentions();
    }

    componentDidUpdate ( {Person} ) {
        if (this.props.Person !== Person) {
            this.checkPersonDataAttentions();
        }
    }

    render() {

        const { upperStyle, Person } = this.props;
        const { width, height } = upperStyle;

        const { personDataAttentionsResult } = this.state;

        if (!Person) {
            return null;
        }

        if (!personDataAttentionsResult || !personDataAttentionsResult.checked) return null;
        if (!personDataAttentionsResult.attentions || personDataAttentionsResult.attentions.length === 0) return null;

        const { attentions } = personDataAttentionsResult;

        this.setComponentHeight ( 50 + attentions.length*30 );

        return <div style={{ width, height }} className='person_nodata_plashka'>
            <img alt='CapoeiraSport' src="/../images/ver2/alert.png" />
            <div className='person_nodata_plashka_attentions'> <b>У ВАС НЕ ЗАПОЛНЕНО:</b>
                {attentions.map( type => <this.OneAttention key = {type} type = {type}/>)}
            </div>
        </div>
    }

    OneAttention = ( { type} ) => {

        const { changeDataFlag } = this.props;

        if (type === 'NO_PATRONYMIC') {
            return <span onClick={() => changeDataFlag('1')} >ОТЧЕСТВО</span>
        }
        if (type === 'NO_TEL') {
            return <span onClick={() => changeDataFlag('2')}>КОНТАКТНЫЙ ТЕЛЕФОН</span>
        }

        return null;
    }

}


const PersonNoDataWrapped = (props) => <PersonWrapper Wrapped={PersonNoDataPlashka} personId={props.personId} componentProps={{ ...props }} />

export default withCapoeiraSportState(`
client,
iamuser,
`)(PersonNoDataWrapped)