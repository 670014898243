// мы передаём сюда ID школы и названия кордао
import React from 'react';
import { AvatarPlate } from 'src/ver2/containers/lowlavel/avatarPlate'
import { withCapoeiraSportState } from 'src/ver2/context';
// import { Link } from 'react-router-dom';

import GroupAvatar from './avatar';

const GroupPlate = (props) => {
    const { width, height, GroupId, client, groupsLoaded } = props;


    let text = '';
    if (!groupsLoaded) {
        text = 'Загрузка'
    } else {
        const group = client.getGroupById(GroupId);
        if (group) {
            text = group.Name;
        } else {
            text = 'Нет группы'
        }
    }
    // if (linkTo)
    //     return <Link to={linkTo === 'default' ? `/school/${SchoolId}/info` : linkTo}>
    //         <AvatarRect
    //             text={school ? school.Name : 'Нет школы'}
    //             width={width}
    //             AvatarComponent={SchoolAvatar}
    //             avatarComponentProps={{ schoolId: SchoolId }}
    //         />
    //     </Link>

    return <AvatarPlate
        text={text}
        width={width}
        height={height}
        AvatarComponent={GroupAvatar}
        avatarComponentProps={{ groupId: GroupId }}
    />

}

export default React.memo(withCapoeiraSportState('client, groupsLoaded')(GroupPlate));