import React, { PureComponent } from 'react';

import { withGroupState } from 'src/ver2/pages/group/context';
import GroupStructurePlashka from '../structure';
import ShaduleComponent from 'src/ver2/pages/stats/components/shadule';
import TrefaText from 'src/ver2/components/trefaText';


import { OneRaitingBlock, BestRaitingBlock } from 'src/ver2/plashki/raiting/bigTable/blocks';
import { orderBy, groupBy } from 'lodash';

import { Select } from 'antd';

import './style.css';

const { Option } = Select;

class VisitsGroupPlashka extends PureComponent {

    state = {
        subgroupId: null,
        monthSelected: null
    }

    componentDidMount() {
        this.initFilters();
    }
    componentDidUpdate({ visits }) {
        if (!visits && this.props.visits) {
            this.initFilters();
        }
    }

    initFilters = () => {
        const { visits } = this.props;
        if (!visits || !visits.subgroups) return;
        const sbs = Object.values(visits.subgroups);
        if (!sbs.length) return;

        // тут надо выбрать, что мы первое можем смотреть
        for ( let i = 0; i < sbs.length; i++ ) {
            if (this.mayISeeIt(sbs[i])) {
                this.setState({ subgroupId: sbs[i].id })
                return;
            }
        }
        // this.setState({ subgroupId: sbs[0].id })
    }

    get selectedSb() {
        const { subgroupId } = this.state;
        const { visits } = this.props;
        if (!visits) return {};
        const { subgroups } = visits;
        if (!subgroupId || !subgroups) return {};
        return subgroups.find(x => x.id === subgroupId);
    }

    mayISeeIt = (subgroup) => {
        const { 
            iAmCreator,
            iAmInstructor,
            iAmStudent,
            iamuser
        } = this.props;

        if (iAmCreator || iAmInstructor) return true;

        const { settingsAsObj, id } = subgroup;
        if (!settingsAsObj) return false;

        const { showVisits } = settingsAsObj;
        if (!showVisits || showVisits === 'noone') {
            return false;
        }
        if (showVisits === 'ingroup') {
            if (iAmStudent) {
                return true;
            }
            return false;
        }
        if (showVisits === 'insubroup') {
            // тут надо замутить проверку в этой SB на посещения 
            const { visits } = this.props;
            if (!iamuser || !visits || !visits.data || !visits.data[id] || !visits.data[id].all) return false;
            if (visits.data[id].all[iamuser.Id]) return true;
            return false;
        }

        return true;
    }

    render() {
        const { client, isPersonVisits, visits } = this.props;

        const { nowRaiting } = client;

        if (!isPersonVisits) return <div className='group_visits_main'>
            <span>
                Не подключено к CRM или нет посещений
            </span>
        </div >

        const { subgroups = [] } = visits;
        // const { title } = this.selectedSb;
        const { subgroupId } = this.state;

        if (!subgroupId) return <div className='group_visits_main'>
            <span>
                Вы не можете смотреть посещения этой группы
            </span>
        </div >  

        return <div className='group_visits_main'>
            <h3> {`Посещения в сезоне ${nowRaiting}`} </h3>
            <span> {`ПОДГРУППА`} </span>
            <Select width={300} style={{ width: 300 }} value={subgroupId} onChange={(value) => { this.setState({ subgroupId: value }) }} >
                {
                    subgroups.map(({ id, title, settingsAsObj }) => {
                        if (settingsAsObj && settingsAsObj.notActive) return null;
                        if (!this.mayISeeIt({ id, title, settingsAsObj })) return null;
                        return <Option key={`subgroupId`} value={id}>
                            {title}
                        </Option>
                    })
                }
            </Select>
            {/* <span>{`Подгруппа ${title}`}</span> */}
            <this.BestBlock />
            <this.MonthBlock />
        </div >
    }

    BestBlock = () => {

        const { isMobile, visits } = this.props;
        const { subgroupId } = this.state;

        const { data } = visits;
        if (!data || !subgroupId || !data[subgroupId]) return null;

        const { all } = data[subgroupId];

        let databest = [];
        let lastPlace = 1;
        for (let i = 0; i < all.length; i++) {
            const { UserId, Place } = all[i];
            if (Place > 3) break;

            if (i >= 3 && lastPlace !== Place) break;
            lastPlace = Place;

            databest.push(
                {
                    UserId,
                    Place: Place || (i + 1)
                }
            )
        }

        const slicedAll = all.slice(0, 10);

        return <div className='group_visits_main_best'>
            <h2>ТОП ПОСЕЩЕНИЙ ЗА СЕЗОН</h2>
            <div className='group_visits_main_best_best'>
                <BestRaitingBlock isMobile={isMobile} data={databest} />
            </div>
            <div className='group_visits_main_best_table'>
                {slicedAll && !!slicedAll.length &&
                    slicedAll.map(({ UserId, Place, PlaceName, Count }, index) => {
                        return <OneRaitingBlock key={`_${UserId}_`} data={{
                            PlaceName: PlaceName || (index + 1),
                            Place: Place || (index + 1),
                            AllScores: Count,
                            UserId
                        }} />
                    })
                }
            </div>
        </div>
    }

    MonthBlock = () => {

        const { isMobile, visits } = this.props;
        const { subgroupId } = this.state;

        const { data } = visits;
        if (!data || !subgroupId || !data[subgroupId]) return null;


        const months = data[subgroupId]

        const MONTHS = [
            { key: 9, label: 'СЕНТЯБРЬ' },
            { key: 10, label: 'ОКТЯБРЬ' },
            { key: 11, label: 'НОЯБРЬ' },
            { key: 12, label: 'ДЕКАБРЬ' },
            { key: 1, label: 'ЯНВАРЬ' },
            { key: 2, label: 'ФЕВРАЛЬ' },
            { key: 3, label: 'МАРТ' },
            { key: 4, label: 'АПРЕЛЬ' },
            { key: 5, label: 'МАЯ' },
            { key: 6, label: 'ИЮНЬ' },
            { key: 7, label: 'ЮИЛЬ' },
            { key: 8, label: 'АВГУСТ' },
        ].reverse();

        let answer = [];

        for (const i in MONTHS) {
            const { key, label } = MONTHS[i];
            if (!months[key]) continue;

            let databest = [];
            let lastPlace = 1;
            for (let i = 0; i < months[key].length; i++) {
                const { UserId, Place } = months[key][i];
                if (Place > 3) break;

                if (i >= 3 && lastPlace !== Place) break;
                lastPlace = Place;

                databest.push(
                    {
                        UserId,
                        Place: Place || (i + 1)
                    }
                )
            }

            const slicedAll = months[key].slice(0, 10);

            answer.push(<div key={`gvmb_${key}`} className='group_visits_main_best'>
                <h2>{`ТОП ПОСЕЩЕНИЙ ЗА ${label}`}</h2>
                <div className='group_visits_main_best_table'>
                    {slicedAll && !!slicedAll.length &&
                        slicedAll.map(({ UserId, Place, PlaceName, Count }, index) => {
                            return <OneRaitingBlock key={`_${UserId}_`} data={{
                                PlaceName: PlaceName || (index + 1),
                                Place: Place || (index + 1),
                                AllScores: Count,
                                UserId
                            }} />
                        })
                    }
                </div>
            </div>
            )
        }
        return <div className='group_visits_main_months'>
            {answer}
        </div>
    }
}

export default withGroupState(`
    Group,

    iAmCreator,
    iAmInstructor,
    iAmStudent,
    iamuser,

    isPersonVisits,
    visits,
    client,
    isMobile
    `)(VisitsGroupPlashka)