import React, { PureComponent } from 'react';
import { withGroupState } from 'src/ver2/pages/group/context';
import { TitledPlashka } from 'src/ver2/containers/lowlavel/title';

import PersonAvatar from 'src/ver2/components/person/avatar';

import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import { TrefaLayout } from 'src/ver2/containers/layout';

import { Popover } from 'antd';
import './style.css';
import moment from 'moment';
import 'moment/locale/ru';

class CalendarPlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            monthShift: props.monthShift || 0
        }
    }

    //changeKey

    get lookedDate() {
        if (this.props.settedDate) {
            return moment(this.props.settedDate, 'DD.MM.YYYY');
        }
        return moment();
    }

    render() {

        const { upperStyle, noTitle, header } = this.props;

        // const { width, height } = upperStyle;
        // const { todayMonth, todayDay } = this.state;

        if (noTitle) {
            const { width, height } = upperStyle;
            return <div style={{ width, height }} className='calendar_plashka'>
                {header ?
                    <this.OwnHeader />
                    : <this.Header />}
                <this.DaysHeader />
                <this.Body />
            </div>
        }


        return <TitledPlashka titleComponent={''} upperStyle={upperStyle} notFromLayout>
            <div className='calendar_plashka'>
                <this.Header />
                <this.DaysHeader />
                <this.Body />
            </div>
        </TitledPlashka>

    }

    OwnHeader = () => {

        const { header } = this.props;
        const { monthShift } = this.state;

        const lookedMonth = this.lookedDate.subtract(monthShift, 'months');
        const lookedMonthEnding = lookedMonth.format('MM.YYYY');

        return <div className='calendar_plashka_outerheader'>
            <span>
                {header}
            </span>
        </div >
    }

    Header = () => {

        const { monthShift } = this.state;

        return <div className='calendar_header'>
            <div onClick={() => this.setState({ monthShift: monthShift + 1 })} className='calendar_header_item'> {moment().subtract(monthShift + 1, 'months').format('MMMM')} </div>
            <div className='calendar_header_item calendar_header_item___selected'> {moment().subtract(monthShift, 'months').format('MMMM')} </div>
            <div onClick={() => this.setState({ monthShift: monthShift - 1 })} className='calendar_header_item'> {moment().subtract(monthShift - 1, 'months').format('MMMM')} </div>
        </div>
    }

    DaysHeader = () => {
        const DAYS = ['пн', 'вт', 'ср', 'чт', 'пт', 'cб', 'вс'];
        return <div className='calendar_days_header'>
            {DAYS.map((value) => {
                return <div key={'cdhi' + value} className='calendar_days_header_item'> {value} </div>
            })}
        </div>
    }

    Body = () => {
        const { monthShift } = this.state;
        const { Students } = this.props;

        const lookedMonth = this.lookedDate.subtract(monthShift, 'months');
        const lookedMonthEnding = lookedMonth.format('MM');

        const daysCount = lookedMonth.daysInMonth();
        const firstDay = lookedMonth.startOf('month');


        let answer = [];
        let i = 1;

        const nowDay = Number(this.lookedDate.format('DD'));

        for (; i < daysCount + firstDay.isoWeekday(); i++) {
            let classNameDay = '';
            if (monthShift > 0) classNameDay = 'calendar_body_oneday__past';
            let dayNumber = i - firstDay.isoWeekday() + 1;

            // это мы выбираем в текущем месяце - что показывать серым, а что нет
            if (monthShift === 0) {
                if (dayNumber < nowDay) {
                    classNameDay = 'calendar_body_oneday__past';
                } else {
                    classNameDay = '';
                    if (dayNumber === nowDay) {
                        classNameDay = 'calendar_body_oneday__today'
                    }
                }
            }

            if (dayNumber < 1) {
                answer.push(
                    <div key={'cbo' + dayNumber} className='calendar_body_oneday calendar_body_oneday__blank' />
                );
                continue;
            }

            // пришли сюда, значит это уже нормальный день (от 1 до 31)
            const day2chars = dayNumber < 10 ? `0${dayNumber}` : dayNumber;
            const fullDate = `${day2chars}.${lookedMonthEnding}`;

            // есть события на нашей дате
            if (Students && Students[fullDate]) {
                classNameDay += ' calendar_body_oneday__event';

                // let classNameDay_ = '';
                // for (let z = 0; z < Students[fullDate].length; z++) {
                //     if (Events[fullDate][z].Type === 'championship') {
                //         classNameDay_ = ' calendar_body_oneday__event_championship';
                //     }
                // }

                // classNameDay += classNameDay_;

                answer.push(
                    <Popover placement='top' className={'calendar_popover'} key={'cbopo' + dayNumber + Math.random()}
                        content={this.popoverContent(fullDate)}>
                        <div key={'cbo' + dayNumber} className={'calendar_body_oneday ' + classNameDay} >
                            <span>
                                {dayNumber}
                            </span>
                            <div className='calendar_mini_events'>
                                {Students[fullDate].map(({ Id }) => {
                                    return <PersonAvatar
                                        withExtra={true}
                                        key={'cme_' + Id}
                                        personId={Id}
                                        size={22}
                                    />
                                })}
                            </div>
                        </div>
                    </Popover>
                )
                continue;
            }

            answer.push(
                <div key={'cbo' + dayNumber} className={'calendar_body_oneday ' + classNameDay} >
                    <span>
                        {dayNumber}
                    </span>
                </div>
            )
        }

        const left = (i - 1) % 7;
        for (let j = 0; j < (7 - left); j++) {
            answer.push(
                <div key={'cbo' + i + '_' + j} className='calendar_body_oneday calendar_body_oneday__blank' />
            );
        }

        return <div className='calendar_body'>
            {answer}
        </div>

    }

    popoverContent = (fullDate) => {

        const { Students } = this.props;
        const personsAtDate = Students[fullDate];

        return <div style={{ height: personsAtDate.length * 60 }} className='calendar-popover-content'>
            <TrefaLayout greedsX={[10, 200, 10]} greedsY={personsAtDate.length * 60}
                components={[
                    {
                        component: PersonsLittleListPlashka,
                        cell_x: 2,
                        collapse: 1,
                        componentProps: { height: personsAtDate.length * 60, Persons: personsAtDate }
                    }
                ]}
            />
        </div>
    }
}

export default withGroupState('client')(CalendarPlashka);