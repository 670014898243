// мы передаём сюда все нужные данные по группе
import React from 'react';
import { AvatarPlate } from 'src/ver2/containers/lowlavel/avatarPlate'
import { withCapoeiraSportState } from 'src/ver2/context';
// import { Link } from 'react-router-dom';

import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import PlaceRect from 'src/ver2/containers/lowlavel/placeRect';

import GroupAvatar from './avatar';
import Loader from 'src/ver2/components/loader';

import { Link } from 'react-router-dom';

import './groupBigPlate.css';

const GroupBigPlate = (props) => {
    const { group, width, height } = props;

    if (!group) return <div style={{ width, height }} className='group_big_plate'>
        <Loader />
    </div>

    const { Id, Name, Count, Medals } = group;

    return <Link to={'/group/' + Id} >
        <div style={{ width, height }} className='group_big_plate'>
            <div style = {{width: height * 0.75, minWidth: height * 0.75 }} className='group_big_plate_avatar'>
                <GroupAvatar groupId={Id} size={height * 0.75} />
            </div>
            <div className='group_big_plate_name'>
                {Name}
            </div>
            <div className='group_big_plate_count'>
                <NumberText count={Count} text={'СОСТАВ'} height={height * 0.7} />
            </div>
            <div className='group_big_plate_medals'>
                <MedalBlock Medals={Medals} height={height * 0.7} />
            </div>
        </div>
    </Link>

}

const MedalBlock = ({ height, Medals }) => {

    if (!Medals || !Medals.Scores) return null;

    const medals = <div className='group_big_plate_medals_inside'>
        <PlaceRect height={height * 0.60} Place={1} PlaceName={Medals.Gold} asMedal={true} />
        <PlaceRect height={height * 0.60} Place={2} PlaceName={Medals.Silver} asMedal={true} />
        <PlaceRect height={height * 0.60} Place={3} PlaceName={Medals.Bronze} asMedal={true} />
    </div>

    return <NumberText count={medals} text={'МЕДАЛИ'} height={height} />
}

export default React.memo(withCapoeiraSportState('client, iamuser')(GroupBigPlate));