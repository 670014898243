import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import moment from 'moment/min/moment-with-locales';
import LittleEvent from 'src/ver2/components/event/little';
import SchoolPlate from 'src/ver2/components/school/schoolPlate';
import { Icon } from 'antd';
import 'moment/locale/ru';


import './littleStyle.css';

class LittleOneNews extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { OneNews, type, height: comingHeight, showInfoModal } = this.props;
        if (!OneNews) return <div className='onenew_little'>
            Загрузка...
        </div>

        const {
            Data, Title, Lvl
        } = OneNews;

        const height = comingHeight || 50;

        let TitleComponent = null;
        let AfterDate = null;

        switch (type) {

            case 'eventNewNews': {
                TitleComponent = <div onClick={() => { showInfoModal({ type: 'eventNewNews', OneNews }) }} className='onenew_little__title onenew_little__title_event_newnews'>
                    {Title}
                </div>
                break;
            }

            case 'portalNewNews': {
                // TitleComponent = <div onClick={() => { showInfoModal({ type: 'eventNewNews', OneNews }) }} className='onenew_little__title onenew_little__title_event_newnews'>
                //     {Title}
                // </div>
                if (Lvl === 2) {
                    TitleComponent = <div onClick={() => { showInfoModal({ type: 'eventNewNews', OneNews }) }} className='onenew_little__title onenew_little__title_event_newnews onenew_little__title_hot'>
                        <Icon theme='filled' style = {{fontSize: 13, marginRight: 4}} type = 'fire'/>{Title}
                    </div>
                } else {
                    TitleComponent = <div onClick={() => { showInfoModal({ type: 'eventNewNews', OneNews }) }} className='onenew_little__title onenew_little__title_event_newnews'>
                        {Title}
                    </div>
                }
                const { Subject, SubjectId } = OneNews;
                if (Subject === 'event') {
                    AfterDate = <div> <LittleEvent eventId={SubjectId} height={18} addClassName={'event_little_for_news'} /> </div>
                }
                if (Subject === 'school') {
                    AfterDate = <div> <SchoolPlate SchoolId={SubjectId} height={18} /> </div>
                }
                break;
            }

            default: {

                if (Lvl === 2) {
                    TitleComponent = <div onClick={() => { showInfoModal({ type: 'eventNewNews', OneNews }) }} className='onenew_little__title onenew_little__title_event_newnews onenew_little__title_hot'>
                        {Title}
                    </div>
                } else {
                    TitleComponent = <div onClick={() => { showInfoModal({ type: 'eventNewNews', OneNews }) }} className='onenew_little__title onenew_little__title_event_newnews'>
                        {Title}
                    </div>
                }
            }
        }

        return <div style={{ height, minHeight: height }} className='onenew_little'>
            <div className='onenew_little__date'>
                <span>{moment(Data || OneNews.Date, 'YYYY.MM.DDTHH:mm').locale('ru').format('DD.MM.YYYY, HH:mm')}</span>
                {AfterDate}
            </div>
            {TitleComponent}
        </div>
    }
}

export default withCapoeiraSportState('iamuser, client, showInfoModal')(LittleOneNews);