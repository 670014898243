import React from 'react';
import PersonAvatar from 'src/ver2/components/person/avatar';
import PersonWrapper from 'src/ver2/components/person/personWrapper';

import SchoolAvatar from 'src/ver2/components/school/avatar';
import SchoolWrapper from 'src/ver2/components/school/schoolWrapper';

import EventAvatar from 'src/ver2/components/event/avatar';
import EventWrapper from 'src/ver2/components/event/eventWrapper';

import VideoLittlePlashka from 'src/ver2/plashki/video/little';

import SoloMiniSequenceRowWrapped from 'src/ver2/containers/challenges/components/mini-sequence-row/wrapped';

import { Popover } from 'antd';

const PersonIncludesWrapping = ({ Person, onLinkClick, link, avatarsSize }) => {

    if (!Person) return null;
    const { Id, Name, Surname } = Person;

    return <span className='person_includes'>
        <PersonAvatar personId={Id} size={avatarsSize} fromTrefaText={true} />
        <span style={{ marginLeft: avatarsSize / 3 }} className='person_includes_text'>
            <span style={{ cursor: 'pointer' }} onClick={() => { onLinkClick(link + Id) }} >
                {Surname} {Name}
            </span>
        </span>
    </span>

}
export const PersonIncludes = (props) => <PersonWrapper Wrapped={PersonIncludesWrapping} personId={props.personId} componentProps={{ ...props }} />




const SchoolIncludesWrapping = ({ School, onLinkClick, link, avatarsSize }) => {

    if (!School) return null;
    const { Id, Name } = School;

    return <span className='person_includes'>
        <SchoolAvatar schoolId={Id} size={avatarsSize} />
        <span style={{ marginLeft: avatarsSize / 3 }} className='person_includes_text'>
            <span style={{ cursor: 'pointer' }} onClick={() => { onLinkClick(link + Id + '/info') }} >
                {Name}
            </span>
        </span>
    </span>

}
export const SchoolIncludes = (props) => <SchoolWrapper Wrapped={SchoolIncludesWrapping} schoolId={props.schoolId} componentProps={{ ...props }} />





const EventIncludesWrapping = ({ Event, onLinkClick, link, avatarsSize }) => {

    if (!Event) return null;
    const { Id, Title } = Event;

    return <span className='person_includes'>
        <EventAvatar eventId={Id} size={avatarsSize} />
        <span style={{ marginLeft: avatarsSize / 3 }} className='person_includes_text'>
            <span style={{ cursor: 'pointer' }} onClick={() => { onLinkClick(link + Id) }} >
                {Title}
            </span>
        </span>
    </span>

}
export const EventIncludes = (props) => <EventWrapper Wrapped={EventIncludesWrapping} eventId={props.eventId} componentProps={{ ...props }} />


export const VideoIncludes = ({ videoId, onLinkClick, videoWidth }) => {

    const width = videoWidth > 600 ? 600 : videoWidth;
    const height = width * 280 / 330;

    return <span className='video_includes' style={{ width, height }}>
        <VideoLittlePlashka upperStyle={{ width, height }} videoId={videoId} onLinkClick={onLinkClick} mode={'trefatext'} />
    </span>

}
// export const VideoIncludes = (props) => <EventWrapper Wrapped={EventIncludesWrapping} eventId={props.eventId} componentProps={{ ...props }} />

export const ItemIncludes = ({ Item, onLinkClick, avatarsSize }) => {

    if (!Item) return null;
    const { title, srcUsed } = Item;

    return <span className='person_includes'>
        {/* <PersonAvatar personId={Id} size={avatarsSize} fromTrefaText = {true}/> */}
        <img src={srcUsed} style={{ height: avatarsSize, width: 'auto' }} />
        <span style={{ marginLeft: avatarsSize / 3 }} className='person_includes_text'>
            <span style={{ cursor: 'pointer' }} onClick={() => { onLinkClick() }} >
                {title}
            </span>
        </span>
    </span>

}

export const TechnicsIncludes = ({ elementTech, srcElement, avatarsSize, link, onLinkClick }) => {

    if (!elementTech) return null;

    const { key, title } = elementTech;

    return <span className='technics_includes'>
        <Popover placement={'top'} content={ElementCardPoopover(key, () => { onLinkClick(link + key) })}>

            <img src={srcElement} style={{ height: avatarsSize, width: 'auto' }} />
            <span style={{ marginLeft: avatarsSize / 3 }} className='person_includes_text'>
                <span>
                    {title}
                </span>
            </span>
        </Popover>
    </span>
}

export const ElementCardPoopover = (element_key, onLinkClick) => {

    const classNamePoopover = 'element-card-poopover-mobile';

    return <div className={classNamePoopover}>

        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            cursor: 'pointer'
        }}
            onClick={onLinkClick}
        >
            <video
                playsInline="true"
                id="my-player"
                class="video-js"
                preload="auto"
                autoPlay="true"
                muted="true"
                poster={`https://capoeirasport.com/graphics/video_technics/logo.png`}
                loop="true"
                width='100%'
                controls=""
                data-setup="{}">
                <source
                    src={`https://capoeirasport.com/graphics/video_technics/${element_key}.mp4`}
                    type="video/mp4" />
            </video>
        </div>

    </div>
}

export const SequenceIncludes = ({ sequenceCode, videoWidth, link, onLinkClick }) => {

    if (!sequenceCode) return null;
    const width = videoWidth > 600 ? 600 : videoWidth;
    const height = width * 280 / 330;

    return <span className='video_includes' style={{ width, height }}>
        <SoloMiniSequenceRowWrapped Code = {sequenceCode} linkFunc = {() => {onLinkClick(link + sequenceCode)}} noName noNameNoLink />
    </span>
}
