// это посто сама по себе аввтарка чувака
import React from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import PersonWrapper from './personWrapper';
import { StarIcon } from 'src/ver2/components/ui/icons';
import Shine3D from 'src/ver2/components/3d/shine';

import { Avatar, Tooltip } from 'antd';
import { orderBy } from 'lodash';
import './avatar.css';

const PersonAvatar = (props) => {
    const { size, client, personId, isBirthday, Raiting, showInfoModal, fromTrefaText, DrawRaiting, Person, iamuser, onClick = () => {}, onAvatarError = () => { } } = props;
    const avatarUrl = client.getUsersUrl() + personId + '.png';

    let achivments = null;

    let nededRaiting = null;
    if (Raiting && Raiting.length > 0) {
        nededRaiting = orderBy(Raiting, 'Place', 'asc')[0];
    }

    const size_ = size || 20;

    if (nededRaiting && nededRaiting.Place > 0 && nededRaiting.Place < 6) {

        let className = 'trefa_svg_icon_star_place5';
        if (nededRaiting.Place === 1) className = 'trefa_svg_icon_star_place1';
        if (nededRaiting.Place === 2) className = 'trefa_svg_icon_star_place2';
        if (nededRaiting.Place === 3) className = 'trefa_svg_icon_star_place3';

        achivments = {
            type: 'raitingPlace',
            data: {
                personId
            },
            node: <Tooltip title={`Топ-${nededRaiting.Place} рейтинга`} placement='top'>
                <span>
                    <StarIcon width={size_ / 2.5} height={size_ / 2.5} className={className} />
                </span>
            </Tooltip>
        }
    }

    if (isBirthday) {
        achivments = {
            type: 'birthday',
            data: {
                personId
            },
            node: <img src={"/../images/ver2/achivments/birthday.png"} />
        }
    }

    if (DrawRaiting && Raiting) {

        let answ = [];
        for (let i = 0; i < Raiting.length; i++) {
            if (Raiting[i].Type === 'comp_pro') {
                answ.push(<span className='avatar_container_raiting_pro'>{Raiting[i].Place}</span>)
            }
            if (Raiting[i].Type === 'comp_not_pro') {
                answ.push(<span className='avatar_container_raiting_notpro'>{Raiting[i].Place}</span>)
            }
        }

        achivments = {
            type: 'raiting',
            data: {
                personId
            },
            node: <span className='avatar_container_raiting'> {answ.length > 0 ? answ : '-'} </span>
        }
    }

    // это беримбао или какая-то там еще хуйня
    let visualNode = null;

    let VSettings_ = (Person) ? Person.VSettings : null;

    if (iamuser && Person) {
        if (iamuser.Id === Person.Id) {
            VSettings_ = iamuser.VSettings;
        }
    }

    // это просто наша штука для проверки рисования
    if (VSettings_) {

        /*
        {
            "berimbao": {
                "berimba": "berimba_t2",
                "cabaca": "cabaca_t2"
            }
        }
        */

        const VSettings = JSON.parse(VSettings_);
        if (VSettings && VSettings.berimbao) {

            const { berimba, cabaca } = VSettings.berimbao;

            const berimbaItem = berimba ? client.getAbstractItem(berimba) : null;
            const cabacaItem = cabaca ? client.getAbstractItem(cabaca) : null;

            const w = size * 0.76;
            const h = size * 1.14;
            const berimbaNode = berimbaItem ? <img src={berimbaItem.srcUsed} /> : null;
            const cabacaNode = cabacaItem ? <img src={cabacaItem.srcUsed} /> : null;

            visualNode = <div style={{ width: w, height: h, top: size * 0.047, left: -size * 0.108 }} className='avatar_container_achivment'>
                {berimbaNode}
                {(cabaca === 'cabaca_yy5') &&
                    <div style={{ left: -0.05 * w, top: h * 0.62 }} className='avatar_container_achivment_shine'>
                        <Shine3D drawWidth={w * 0.7} drawHeight={w * 0.7} Id={Math.random()} Place={1} />
                    </div>
                }
                {(cabaca === 'cabaca_yb5') &&
                    <div style={{ left: -0.05 * w, top: h * 0.62 }} className='avatar_container_achivment_shine'>
                        <Shine3D drawWidth={w * 0.7} drawHeight={w * 0.7} Id={Math.random()} Place={1} />
                    </div>
                }

                {(cabaca === 'cabaca_g5') &&
                    <div style={{ left: -0.05 * w, top: h * 0.62 }} className='avatar_container_achivment_shine'>
                        <Shine3D drawWidth={w * 0.7} drawHeight={w * 0.7} Id={Math.random()} Place={5} />
                    </div>
                }
                {cabacaNode}
            </div>
        }
    }

    const { type, data, node } = achivments || {};

    if (fromTrefaText) {
        return <span style={{ width: size, height: size }} className='avatar_container'>
            <Avatar size={size || 20} src={avatarUrl} onClick = {onClick} />
            <div onClick={() => { showInfoModal({ type, ...data }) }} style={{ width: size * 0.45, height: size * 0.45, top: -size * 0.45 * 0.33, left: -size * 0.45 * 0.33 }} className='avatar_container_achivment'>
                {node}
            </div>
        </span>
    }

    return <div style={{ width: size, height: size }} className='avatar_container'>
        <Avatar size={size || 20} src={avatarUrl} onClick = {onClick} onError={onAvatarError} />
        {node ? <div onClick={() => { showInfoModal({ type, ...data }) }} style={{ width: size * 0.45, height: size * 0.45, top: -size * 0.45 * 0.33, left: -size * 0.45 * 0.33 }} className='avatar_container_achivment'>
            {node}
        </div> : null}
        {visualNode ? visualNode : null}
    </div>
}

const componentWrapped = (props) => <PersonWrapper Wrapped={PersonAvatar} personId={props.personId} doNotLoadPerson={false} doNotLoadRaiting={false} withBirthday={true} componentProps={{ ...props }} />
export default withCapoeiraSportState('client, showInfoModal, iamuser')(componentWrapped);