import React, { PureComponent } from 'react';
import UiItem from 'src/ver2/components/ui/item';
import { Link } from 'react-router-dom';

import './style.css';

//getEventById

/**
  Это плашка, которая отображает меню
 */

export default class UiPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { UiItems, upperStyle, tab, isMobile } = this.props;

        const { width, height } = upperStyle;
        const size = isMobile ? height * 0.8 : Math.min(width, height) / 2;

        return <div style={{ width, height }} className='ui_plashka'>
            {UiItems.map((item) => {

                const {
                    icon, text, onClick
                } = item;

                return <Link to={`./${icon}`}>
                    <UiItem key={'ui-item' + icon} isMobile = {isMobile} isSelected = {tab === icon} {...{ size, icon, text, onClick }} />
                </Link>

            })}
        </div>
    }

}

// export default withCapoeiraSportState('client')(EventLittleListPlashka);