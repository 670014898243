import React, { PureComponent } from 'react';
import LittleOneNews from 'src/ver2/components/news/little';

import './style.css';

/**
  Это маленький список новостей
  Сюда приходит массив новостей News ([ {...News}, ... ])
 */

export default class NewsLittleListPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { News, type, upperStyle } = this.props;
        if (!News) return <div className='news_little_plashka'>
            Загрузка...
        </div>

        const { width, height } = upperStyle;

        return <div style={{ width, height }} className='news_little_plashka'>
                {News.map((onenews) => {
                    const { Id } = onenews;
                    return <LittleOneNews height = {60} key={'nlt' + Id} OneNews={onenews} type = {type} />
                })}
            </div>
    }

}

// export default withCapoeiraSportState('client')(EventLittleListPlashka);