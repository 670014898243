export default {
    plant_usual_t1: {
        grade: 1, src: `/../images/ver2/scores/stuff/plant_usual_t1.png`,
        title: 'Обычное растение',
        discription: 'Небольшое растение, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },

    plant_usual_g1: {
        grade: 1, src: `/../images/ver2/scores/stuff/plant_usual_g1.png`,
        title: 'Обычное растение',
        discription: 'Небольшое растение c зеленой лентой, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_usual_b1: {
        grade: 1, src: `/../images/ver2/scores/stuff/plant_usual_b1.png`,
        title: 'Обычное растение',
        discription: 'Небольшое растение c голубой лентой, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_usual_y1: {
        grade: 1, src: `/../images/ver2/scores/stuff/plant_usual_y1.png`,
        title: 'Обычное растение',
        discription: 'Небольшое растение c желтой лентой, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_struck_g1: {
        grade: 1, src: `/../images/ver2/scores/stuff/plant_struck_g1.png`,
        title: 'Обычное растение',
        discription: 'Небольшое растение c зеленой лентой, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_struck_b1: {
        grade: 1, src: `/../images/ver2/scores/stuff/plant_struck_b1.png`,
        title: 'Обычное растение',
        discription: 'Небольшое растение c голубой лентой, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_struck_y1: {
        grade: 1, src: `/../images/ver2/scores/stuff/plant_struck_y1.png`,
        title: 'Обычное растение',
        discription: 'Небольшое растение c желтой лентой, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_cactus_g1: {
        grade: 1, src: `/../images/ver2/scores/stuff/plant_cactus_g1.png`,
        title: 'Обычный кактус',
        discription: 'Кактус c зеленой лентой, который можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_cactus_b1: {
        grade: 1, src: `/../images/ver2/scores/stuff/plant_cactus_b1.png`,
        title: 'Обычный кактус',
        discription: 'Кактус c голубой лентой, который можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_cactus_y1: {
        grade: 1, src: `/../images/ver2/scores/stuff/plant_cactus_y1.png`,
        title: 'Обычный кактус',
        discription: 'Кактус c желтой лентой, который можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },


    plant_usual_w2: {
        grade: 2, src: `/../images/ver2/scores/stuff/plant_usual_w2.png`,
        title: 'Обычное растение',
        discription: 'Небольшое растение в белом горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_usual_br2: {
        grade: 2, src: `/../images/ver2/scores/stuff/plant_usual_br2.png`,
        title: 'Обычное растение',
        discription: 'Небольшое растение в горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_struck_w2: {
        grade: 2, src: `/../images/ver2/scores/stuff/plant_struck_w2.png`,
        title: 'Обычное растение',
        discription: 'Небольшое растение в белом горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_struck_br2: {
        grade: 2, src: `/../images/ver2/scores/stuff/plant_struck_br2.png`,
        title: 'Обычное растение',
        discription: 'Небольшое растение в горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_cactus_w2: {
        grade: 2, src: `/../images/ver2/scores/stuff/plant_cactus_w2.png`,
        title: 'Кактус',
        discription: 'Кактус в белом горшке, который можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_cactus_ww2: {
        grade: 2, src: `/../images/ver2/scores/stuff/plant_cactus_ww2.png`,
        title: 'Весёлый кактус',
        discription: 'Весёлый кактус в белом горшке, который можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_cactus_www2: {
        grade: 2, src: `/../images/ver2/scores/stuff/plant_cactus_www2.png`,
        title: 'Цветущий кактус',
        discription: 'Цветущий кактус в белом горшке, который можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_cactus_br2: {
        grade: 2, src: `/../images/ver2/scores/stuff/plant_cactus_br2.png`,
        title: 'Кактус',
        discription: 'Кактус в горшке, который можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_cactus_brbr2: {
        grade: 2, src: `/../images/ver2/scores/stuff/plant_cactus_brbr2.png`,
        title: 'Весёлый кактус',
        discription: 'Весёлый кактус в горшке, который можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_cactus_y2: {
        grade: 2, src: `/../images/ver2/scores/stuff/plant_cactus_y2.png`,
        title: 'Редкий цветущий кактус',
        discription: 'Редкий цветущий кактус в желтом горшке, который можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },



    plant_long_w3: {
        grade: 3, src: `/../images/ver2/scores/stuff/plant_long_w3.png`,
        title: 'Редкое растение',
        discription: 'Редкое растение в белом горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_long_br3: {
        grade: 3, src: `/../images/ver2/scores/stuff/plant_long_br3.png`,
        title: 'Редкое растение',
        discription: 'Редкое растение в горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_long_y3: {
        grade: 3, src: `/../images/ver2/scores/stuff/plant_long_y3.png`,
        title: 'Редкое растение',
        discription: 'Редкое растение в желтом горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_long_g3: {
        grade: 3, src: `/../images/ver2/scores/stuff/plant_long_g3.png`,
        title: 'Редкое растение',
        discription: 'Редкое растение в зелёном горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_mid_w3: {
        grade: 3, src: `/../images/ver2/scores/stuff/plant_mid_w3.png`,
        title: 'Редкое растение',
        discription: 'Редкое растение в белом горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_mid_br3: {
        grade: 3, src: `/../images/ver2/scores/stuff/plant_mid_br3.png`,
        title: 'Редкое растение',
        discription: 'Редкое растение в горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_mid_y3: {
        grade: 3, src: `/../images/ver2/scores/stuff/plant_mid_y3.png`,
        title: 'Редкое растение',
        discription: 'Редкое растение в желтом горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_mid_g3: {
        grade: 3, src: `/../images/ver2/scores/stuff/plant_mid_g3.png`,
        title: 'Редкое растение',
        discription: 'Редкое растение в зелёном горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_short_w3: {
        grade: 3, src: `/../images/ver2/scores/stuff/plant_mid_w3.png`,
        title: 'Компактное растение',
        discription: 'Компактное растение в белом горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 1, shiftX: 0, shiftY: 0
    },
    plant_short_r3: {
        grade: 3, src: `/../images/ver2/scores/stuff/plant_short_r3.png`,
        title: 'Компактное растение',
        discription: 'Компактное растение в красном горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 1, shiftX: 0, shiftY: 0
    },
    plant_short_b3: {
        grade: 3, src: `/../images/ver2/scores/stuff/plant_short_b3.png`,
        title: 'Компактное растение',
        discription: 'Компактное растение в синем горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 1, shiftX: 0, shiftY: 0
    },


    plant_long_p4: {
        grade: 4, src: `/../images/ver2/scores/stuff/plant_long_p4.png`,
        title: 'Уникальное растение',
        discription: 'Уникальное растение в фиолетовом горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_long_pp4: {
        grade: 4, src: `/../images/ver2/scores/stuff/plant_long_pp4.png`,
        title: 'Уникальное растение',
        discription: 'Уникальное растение в фиолетовом горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_mid_p4: {
        grade: 4, src: `/../images/ver2/scores/stuff/plant_mid_p4.png`,
        title: 'Уникальное растение',
        discription: 'Уникальное растение в фиолетовом горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_mid_pp4: {
        grade: 4, src: `/../images/ver2/scores/stuff/plant_mid_pp4.png`,
        title: 'Уникальное растение',
        discription: 'Уникальное растение в фиолетовом горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_mid_br4: {
        grade: 4, src: `/../images/ver2/scores/stuff/plant_mid_br4.png`,
        title: 'Уникальное растение',
        discription: 'Уникальное растение в горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_short_p4: {
        grade: 4, src: `/../images/ver2/scores/stuff/plant_short_p4.png`,
        title: 'Компактное уникальное растение',
        discription: 'Компактное уникальное растение в фиолетовом горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 1, shiftX: 0, shiftY: 0
    },


    plant_long_br5: {
        grade: 5, src: `/../images/ver2/scores/stuff/plant_long_br5.png`,
        title: 'Превосходное растение',
        discription: 'Превосходное растение в горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },
    plant_mid_br5: {
        grade: 5, src: `/../images/ver2/scores/stuff/plant_mid_br5.png`,
        title: 'Превосходное растение',
        discription: 'Превосходное растение в горшке, которое можно поставить на полку',
        type: 'stuff', subtype: 'plant', w: 1, h: 2, shiftX: 0, shiftY: 1
    },




    plant_donate_1: {
        type: 'stuff',
        subtype: 'plant',
        title: 'Индивидуальное растение',
        grade: 5,
        discription: 'Индивидуальное растение, которое можно приобрести только за донатные ресурсы. Можно поставить не более четёрых на стену',
        src: `/../images/ver2/scores/stuff/plant_donate_1.png`,

        w: 1,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },
}