// это стандартный тайтл, который везде используется
import React from 'react';
import './style.css';
import { Tabs, message } from 'antd';
import { withCapoeiraSportState } from 'src/ver2/context';

import DataChanger, { compareFunction } from 'src/ver2/containers/dataChanger';
import ImageUploader from 'src/ver2/containers/imageChanger';

import SportChanger from 'src/ver2/containers/sportChanger';

const TabPane = Tabs.TabPane;

/**
 * 
 * Это компонент смены данных для студента группы
 */

const StudentDataChange = ({ client, studentData, isMobile, onChangeData }) => {

    let recoveryData = '';

    const setSport = async (data) => {
        // мы подаём заявку
        const { SportRank } = data;
        await client.addApplication({ type: 'sport', values: SportRank, userId: studentData.Id })
    }
    const setJudge = async (data) => {
        // мы подаём заявку
        const { JudgeRank } = data;
        await client.addApplication({ type: 'judge', values: JudgeRank, userId: studentData.Id })
    }

    const onPrivateData = async (PrivateData) => {

        // тут у нас функция сравнения, которая заберёт только то, что поменялось
        const resultSend = compareFunction(studentData, PrivateData);

        if (Object.keys(resultSend).length > 0) {
            console.log('Собираюсь поменять', resultSend)
            onChangeData(resultSend).then(() => {
                message.success('Изменения приняты');
            })
        } else {
            message.warn('Нет изменений')
        }
    }

    // RecoveryPass(client, studentData.Id).then ( result => recoveryData = result);

    return <div className='person_data_change'>
        <Tabs className='person_data_change_tabs' tabPosition={isMobile ? 'top' : 'left'} style={{ color: 'white', heightMin: 500 }} defaultActiveKey="1">

            <TabPane tab={'Личные'} key="1">
                <DataChanger
                    header={<div>
                        Личные данные ученика
                    </div>}
                    data={studentData}
                    buttonText={'ИЗМЕНИТЬ'}
                    buttonState={'allNeeded'}
                    onButtonClick={onPrivateData}
                    components={[
                        {
                            name: 'Name',
                            title: 'Имя',
                            type: 'input',
                            neededText: ' '
                        },
                        {
                            name: 'Surname',
                            title: 'Фамилия',
                            text: 'Введите свою фамилию',
                            type: 'input',
                            neededText: ' '
                        },
                        {
                            name: 'Patronymic',
                            title: 'Отчество',
                            type: 'input',
                            text: 'Отчество не будет отображаться, но необходимо для составления документов на соревнованиях'
                        },
                        {
                            name: 'Apelido',
                            title: 'Апелидо',
                            text: 'Апелидо - это прозвище, дающееся в ряде школ капоэйристам. Необязательное поле',
                            type: 'input',
                            nextGroup: { title: '' }
                        },
                        {
                            name: 'BirthDay',
                            title: 'Дата рождения',
                            type: 'date',
                            nextGroup: { title: '' },
                            neededText: ' '
                        },
                        {
                            name: 'City',
                            title: 'Город проживания',
                            type: 'input',
                            text: 'Нужно только для статистики',
                        }
                    ]}
                />
            </TabPane>
            <TabPane tab={'Контакты'} key="2">
                <DataChanger
                    header={<div>
                        Контакты ученика
                    </div>}
                    data={studentData}
                    buttonState={'allNeeded'}
                    buttonText={'ИЗМЕНИТЬ'}
                    onButtonClick={onPrivateData}
                    components={[
                        {
                            name: 'Email',
                            title: 'E-mail',
                            type: 'input',
                            neededText: ' ',
                            nextGroup: { title: 'Контактные данные быстрой связи' }
                        },
                        {
                            name: 'Instagram',
                            title: 'Инастаграм',
                            type: 'input',
                        },
                        {
                            name: 'VK',
                            title: 'ВКонтакте',
                            type: 'input',
                        },
                        {
                            name: 'Tel',
                            title: 'Телефон',
                            type: 'input',
                            text: 'Телефон будет доступен только организаторам мероприятий и администрации Портала',
                        },
                    ]}
                />
            </TabPane>
            <TabPane tab={'Кордао'} key="4">
                <DataChanger
                    header={<div>
                        Изменить кордао ученика.
                    </div>}
                    data={studentData}
                    buttonState={'allNeeded'}
                    buttonText={'ИЗМЕНИТЬ'}
                    onButtonClick={onPrivateData}
                    SchoolId={studentData.SchoolId}
                    components={[
                        {
                            name: 'Cordao',
                            title: 'Кордао',
                            type: 'cordaos',
                            neededText: ' ',
                            text: 'Если у ученика еще нет кордао - выберите последний вариант ---'
                        }
                    ]}
                />
            </TabPane>
            <TabPane tab={'Спорт. категория'} key="7">
                <SportChanger
                    header={<div>
                        Выберите разряд ученика и подайте заявку
                    </div>}
                    data={studentData}
                    onSave={setSport}
                    saveText={'Подать заявку'}
                    type={'sport'}
                    userId={studentData.Id}
                    components={[
                        {
                            name: 'SportRank',
                            title: 'Спортивный разряд',
                            type: 'sport',
                            neededText: ''
                        }
                    ]}
                />
            </TabPane>
            <TabPane tab={'Суд. категория'} key="6">
                <SportChanger
                    header={<div>
                        Выберите судейскую категорию ученика и подайте заявку
                    </div>}
                    data={studentData}
                    onSave={setJudge}
                    saveText={'Подать заявку'}
                    type={'judge'}
                    userId={studentData.Id}
                    components={[
                        {
                            name: 'JudgeRank',
                            title: 'Судейская категория',
                            type: 'judge',
                            neededText: ''
                        }
                    ]}
                />
            </TabPane>
            <TabPane tab={'Восстановление'} key="8">
                <RecoveryPass client={client} userId={studentData.Id} />
            </TabPane>

        </Tabs>
    </div>
}

const copyToClipboard = (value) => {
    const copytext = document.getElementById('copyCopyText');
    if (copytext) {
      copytext.value = value;
      copytext.select();
      document.execCommand('copy');
      message.success('Скопировано ' + value);
    }
  }

class RecoveryPass extends React.PureComponent {
    state = {
        recoeredData: ''
    }

    componentDidMount = async () => {

        const { client, userId } = this.props;

        const result = await client.userRecoveryPassword(userId);
        if (result && !result.error && result.Login && result.Password) {
            const value = `https://capoeirasport.com?login=${result.Login}&password=${result.Password}`;
            return this.setState({
                recoeredData: <div className='person_data_change_recovery'>
                    ССЫЛКА ДЛЯ ВХОДА: <br />
                    {value}
                    <br/><br/>
                    Нажмите кнопку КОПИРОВАТЬ ниже и отправьте своему ученику как ссылку
                    <br/><br/>
                    <a href ='#' onClick={() => { copyToClipboard(value) }}>КОПИРОВАТЬ</a> 
                </div>
            });
        } else {
            return this.setState({
                recoeredData: <div>
                    НЕТ ВОЗМОЖНОСТИ ВОССТАНОВИТЬ ПАРОЛЬ: <br />
                    Сбой работы сервера, или Вы не является создателем данной группы
                </div>
            });
        }
    }

    render() {
        return this.state.recoeredData;
    }
}

export default withCapoeiraSportState('client, isMobile')(StudentDataChange);