// это стандартный тайтл, который везде используется
import React, { Component } from 'react';
import './style.css';
import { Tabs, message } from 'antd';
import { withCapoeiraSportState } from 'src/ver2/context';
import SchoolPlate from 'src/ver2/components/school/schoolPlate';
import CordaoPlate from 'src/ver2/components/cordao/cordaoPlate';
import GroupPlate from 'src/ver2/components/group/groupPlate';

import ButtonPlashka from 'src/ver2/plashki/ui/button';

import DataChanger, { compareFunction } from 'src/ver2/containers/dataChanger';

const TabPane = Tabs.TabPane;

/**
 * 
 * Это компонент смены данных для пользователя
 */

class PersonRegistration extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeKey: '1',
            privateData: {},
            contactsData: {},
            schoolData: {},
            groupData: {}
        }
    }

    changeTab = (activeKey) => { this.setState({ activeKey }) }

    onPrivateData = async (privateData) => {
        this.setState({ privateData })
        this.changeTab('2')
    }
    onContactsData = async (contactsData) => {
        this.setState({ contactsData })
        this.changeTab('3')
    }
    onSchoolData = async (schoolData) => {
        this.setState({ schoolData, groupData: {} });
        if (schoolData &&
            (
                (schoolData.SchoolId === 1 || schoolData.SchoolId === '1')
                || (schoolData.SchoolId === 3 || schoolData.SchoolId === '3')
                || (schoolData.SchoolId === 2 || schoolData.SchoolId === '2')
                || (schoolData.SchoolId === 7 || schoolData.SchoolId === '7')
                || (schoolData.SchoolId === 12 || schoolData.SchoolId === '12')
                || (schoolData.SchoolId === 13 || schoolData.SchoolId === '13')
                || (schoolData.SchoolId === 13 || schoolData.SchoolId === '13')
                || (schoolData.SchoolId === 15 || schoolData.SchoolId === '15')
            )
        ) {
            this.changeTab('4')
        } else
            this.changeTab('5')
    }
    onGroupData = async (groupData) => {
        this.setState({ groupData });
        this.changeTab('5')
    }

    AllData = () => {
        const { privateData, contactsData, schoolData, groupData } = this.state;
        if (
            !privateData.Login || privateData.Login === '' || privateData.Login === ' ' ||
            !privateData.Password || privateData.Password === '' || privateData.Password === ' ' ||
            !privateData.Name || privateData.Name === '' ||
            !privateData.Surname || privateData.Surname === '' ||
            !privateData.Patronymic || privateData.Patronymic === '' ||
            !privateData.Gender || privateData.Gender === '' ||
            !privateData.BirthDay ||

            !contactsData.Email || contactsData.Email === '' ||

            !schoolData.SchoolId || !schoolData.Cordao === ''
        ) return <div className='person_registrtion_notall'>
            Вы ввели не всю необходимую информацию!
        </div>

        let result = [];
        result.push(<span>{`Логин / Пароль: ${privateData.Login} ${privateData.Password}`}</span>);
        result.push(<span>{`ФИО: ${privateData.Surname} ${privateData.Name} ${privateData.Patronymic}`}</span>);
        if (privateData.Apelido) result.push(<span>{`Апелидо: ${privateData.Apelido}`}</span>);
        result.push(<span>{`Пол: ${privateData.Gender === '1' ? 'Мужской' : 'Женский'}`}</span>);
        result.push(<span>{`Дата рождения: ${privateData.BirthDay}`}</span>);

        result.push(<span>{` `}</span>);

        result.push(<span>{`E-mail: ${contactsData.Email}`}</span>);
        if (privateData.Tel) result.push(<span>{`Телефон: ${privateData.Tel}`}</span>);

        result.push(<span>{` `}</span>);

        result.push(<span>{`Школа: `} <SchoolPlate width={200} height={40} SchoolId={schoolData.SchoolId} linkTo={null} /> </span>);
        result.push(<span>{`Кордао: `} <CordaoPlate width={200} height={40} SchoolId={schoolData.SchoolId} Cordao={schoolData.Cordao} /> </span>);

        if (groupData.GroupId && groupData.GroupId !== '0')
            result.push(<span>{`Группа: `} <GroupPlate width={200} height={40} GroupId={groupData.GroupId} /> </span>);


        return <div className='person_registrtion_all'>
            {result}
            <br />
            Если всё верно, нажмите на кнопку регистрации
            <br />
            <br />
            <ButtonPlashka
                upperStyle={{ width: 180, height: 40 }}
                onClick={() => { this.props.register({ privateData, contactsData, schoolData, groupData }) }}
                text={'ЗАРЕГИСТРИРОВАТЬСЯ'}
            />
        </div>

    }

    render() {

        const { isMobile } = this.props;
        const { activeKey, privateData, contactsData, schoolData, groupData } = this.state;

        let showGroup = false;
        if (schoolData &&
            (
                (schoolData.SchoolId === 1 || schoolData.SchoolId === '1')
                || (schoolData.SchoolId === 3 || schoolData.SchoolId === '3')
                || (schoolData.SchoolId === 2 || schoolData.SchoolId === '2')
                || (schoolData.SchoolId === 7 || schoolData.SchoolId === '7')
                || (schoolData.SchoolId === 12 || schoolData.SchoolId === '12')
                || (schoolData.SchoolId === 13 || schoolData.SchoolId === '13')
                || (schoolData.SchoolId === 13 || schoolData.SchoolId === '13')
                || (schoolData.SchoolId === 15 || schoolData.SchoolId === '15')
            )
        ) {
            showGroup = true;
        }

        return <div className='person_registrtion'>
            <Tabs
                activeKey={activeKey} onChange={this.changeTab}
                className='person_registrtion_tabs'
                tabPosition={isMobile ? 'top' : 'left'}
                style={{ color: 'white', heightMin: 500 }}
                defaultActiveKey={activeKey}
            >

                <TabPane tab={'Личные'} key="1">
                    <DataChanger
                        header={<div>
                            Введите свои личные данные
                        </div>}
                        data={privateData}
                        buttonText={'ДАЛЕЕ'}
                        buttonState={'allNeeded'}
                        onButtonClick={this.onPrivateData}
                        components={[
                            {
                                name: 'Login',
                                title: 'Логин',
                                type: 'input',
                                neededText: ' '
                            },
                            {
                                name: 'Password',
                                title: 'Пароль',
                                type: 'input',
                                neededText: ' ',
                                nextGroup: { title: '' }
                            },
                            {
                                name: 'Surname',
                                title: 'Фамилия',
                                type: 'input',
                                neededText: ' '
                            },
                            {
                                name: 'Name',
                                title: 'Имя',
                                type: 'input',
                                neededText: ' '
                            },
                            {
                                name: 'Patronymic',
                                title: 'Отчество',
                                type: 'input',
                                text: 'Ваше отчество не будет отображаться, но необходимо для составления документов на соревнованиях',
                                neededText: ' '
                            },
                            {
                                name: 'Apelido',
                                title: 'Апелидо',
                                text: 'Апелидо - это прозвище, дающееся в ряде школ капоэйристам. Необязательное поле',
                                type: 'input',
                                nextGroup: { title: '' }
                            },
                            {
                                name: 'Gender',
                                title: 'Пол',
                                type: 'select',
                                options: [
                                    { title: 'Мужской', key: '1' },
                                    { title: 'Женский', key: '0' }
                                ],
                                neededText: ' '
                            },
                            {
                                name: 'BirthDay',
                                title: 'Дата рождения',
                                type: 'date',
                                nextGroup: { title: '' },
                                neededText: ' '
                            },
                            {
                                name: 'City',
                                title: 'Город проживания',
                                type: 'input',
                                text: 'Нужно только для статистики',
                            }
                        ]}
                    />
                </TabPane>
                <TabPane tab={'Контакты'} key="2">
                    <DataChanger
                        header={<div>
                            Введите свои контактные данные <br />
                            В ряде соревнований и мероприятий нельзя участвовать без указания контактных данных быстрой связи на тот случай,
                            если организаторам нужно будет связаться с вами.
                        </div>}
                        data={contactsData}
                        buttonState={'allNeeded'}
                        buttonText={'ДАЛЕЕ'}
                        onButtonClick={this.onContactsData}
                        components={[
                            {
                                name: 'Email',
                                title: 'E-mail',
                                type: 'input',
                                neededText: ' ',
                                nextGroup: { title: 'Контактные данные быстрой связи' }
                            },
                            {
                                name: 'Instagram',
                                title: 'Инастаграм',
                                type: 'input',
                            },
                            {
                                name: 'VK',
                                title: 'ВКонтакте',
                                type: 'input',
                            },
                            {
                                name: 'Tel',
                                title: 'Телефон',
                                type: 'input',
                                text: 'Ваш телефон будет доступен только организаторам мероприятий и администрации Портала',
                            },
                        ]}
                    />
                </TabPane>
                <TabPane tab={'Школа'} key="3">
                    <DataChanger
                        header={<div>
                            Выберите школу и кордао.
                        </div>}
                        data={schoolData}
                        buttonState={'allNeeded'}
                        buttonText={'ДАЛЕЕ'}
                        onButtonClick={this.onSchoolData}
                        components={[
                            {
                                name: 'SchoolId',
                                title: 'Школа',
                                type: 'schools',
                                neededText: ' ',
                                text: 'Если вы не состоите ни в какой школе, или вашей школы нет на портале, выберите последний вариант ---'
                            },
                            {
                                name: 'Cordao',
                                title: 'Кордао',
                                type: 'cordaos',
                                neededText: ' ',
                                text: 'Если у все еще нет кордао - выберите первый вариант ---'
                            }
                        ]}
                    />
                </TabPane>
                {showGroup ?
                    <TabPane tab={'Группа'} key="4">
                        <DataChanger
                            header={<div>
                                В выбранной вами школе есть группы, Вы можете выбрать свою, если знаете её название. <br />
                                Если Вы не уверены, то можете пока оставить этот пункт и продолжить регистрацию, а позже спросить про группу
                                у своего инструктора.
                            </div>}
                            data={groupData}
                            buttonState={'all'}
                            buttonText={'ДАЛЕЕ'}
                            onButtonClick={this.onGroupData}
                            SchoolId={schoolData.SchoolId}
                            components={[
                                {
                                    name: 'GroupId',
                                    title: 'Группа',
                                    type: 'groups',
                                    text: 'Если вы не состоите в группе, оставьте пустым, или выберите Нет группы'
                                }
                            ]}
                        />
                    </TabPane>
                    : null}
                <TabPane tab={'Регистрация'} key="5">
                    <this.AllData />
                </TabPane>
            </Tabs>
        </div>
    }
}

/* <TabPane tab={<span><Icon type="flag" theme="filled" />Хуй хуй</span>} key="2">
Копать
</TabPane> */

export default withCapoeiraSportState('getText, client, isMobile')(PersonRegistration);