import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import EventAvatar from './avatar';
import { Link } from 'react-router-dom';

import './littleStyle.css';
import EventWrapper from 'src/ver2/components/event/eventWrapper';

//getEventById

/**
 * ПОЛУЧАЕТ НА ВХОД Event
 *  Adress: "Малое Видное ТЛПХ Подлипки, ул.Спортивная, д.32"
    AuthorId: 2
    ChampionshipId: 41
    City: "МО"
    Date: "13.11.2021"
    Discription: "Чемпионат и Первенство Московской области 2021"
    FullData: "20211113"
    Id: 78
    TimeDiscription: ""
    Title: "Чемпионат и Первенство Московской области 2021"
    Type: "championship"
 */

class LittleEvent extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { Event, height: comingHeight, addClassName = '', mode = null, isMobile } = this.props;
        if (!Event) return <div className={'event_little ' + addClassName}>
            Загрузка...
        </div>

        const {
            Id, Title
        } = Event;

        const height = comingHeight || 40;

        if (mode === 'bigvideo') {
            return <div style={{ height, minHeight: height }} className={'event_bigvideo'}>
                <div style={{ width: height, minWidth: height, height }} className='event_bigvideo__avatar'>
                    <EventAvatar eventId={Id} size={height * 0.95} />
                </div>
                <Link to={'/event/' + Id}>
                    <div style={{ fontSize: height * (isMobile ? 0.23 : 0.6), lineHeight: height * 0.044 }} className='event_bigvideo__title'>
                        {Title}
                    </div>
                </Link>
            </div>
        }

        return <div style={{ height, minHeight: height }} className={'event_little ' + addClassName}>
            <div style={{ width: height, minWidth: height, height }} className='event_little__avatar'>
                <EventAvatar eventId={Id} size={height * 0.65} />
            </div>
            <Link to={'/event/' + Id}>
                <div style={{ fontSize: Math.max(height * 0.3, 14), lineHeight: Math.max(height * 0.022, 1) }} className='event_little__title'>
                    {Title}
                </div>
            </Link>
        </div>
    }

}

const componentWrapped = (props) => <EventWrapper Wrapped={LittleEvent} eventId={props.eventId} componentProps={{ ...props }} />
export default withCapoeiraSportState('iamuser, client, isMobile')(componentWrapped);

export const eventLinkText = ({ Id, Title }) => {
    return <Link to={'/event/' + Id}>
        {Title}
    </Link>
}