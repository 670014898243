export default {    // ICONS 
    crown_2: {
        type: 'icon',
        grade: 2,
        title: 'Базовая иконка',
        discription: 'Отлично подойдёт, если вы хотите немного выделить свой аккаунт среди остальных',
        src: `/../images/ver2/scores/achivments/crown_2.png`,
    },
    crown_3: {
        type: 'icon',
        grade: 3,
        title: 'Продвинутая иконка',
        discription: 'На такую иконку придётся накопить немало ресурсов, но глубокий пурпурный цвет стоит того!',
        src: `/../images/ver2/scores/achivments/crown_3.png`,
    },
    crown_4: {
        type: 'icon',
        grade: 4,
        title: 'Редкая иконка',
        discription: 'Ярко красная редкая иконка около вашего профиля прекрасно выделит Вас среди других капоэйристов.',
        src: `/../images/ver2/scores/achivments/crown_4.png`,
    },
    crown_5: {
        type: 'icon',
        grade: 5,
        title: 'Легендарная иконка',
        discription: 'Вы очень много копили ради того, чтобы приобрести такую иконку! Принимайте респект за вложенные труды!',
        src: `/../images/ver2/scores/achivments/crown_5.png`,
    },

    //апелидные

    raposa_1: {
        type: 'icon',
        grade: 4,
        title: 'Raposa',
        discription: 'ИКОНКА: Подойдёт, если Ваше апелидо Raposa, ну или Вы просто симпатизируете лисицам',
        src: `/../images/ver2/scores/achivments/raposa_1.png`,
    },
    raposa_2: {
        type: 'icon',
        grade: 5,
        title: 'Raposa',
        discription: 'ИКОНКА: Подойдёт, если Ваше апелидо Raposa, ну или Вы просто симпатизируете лисицам',
        src: `/../images/ver2/scores/achivments/raposa_2.png`,
    },
    trefa_1: {
        type: 'icon',
        grade: 5,
        title: 'Trefa',
        discription: 'ИКОНКА: Небольшая золотая трефа, отлично подойдёт, чтобы выделить Ваш аккаунт!',
        src: `/../images/ver2/scores/achivments/trefa_1.png`,
    },

    
    aubatido_1: {
        type: 'icon',
        grade: 4,
        title: 'Ау-батидо',
        discription: 'ИКОНКА: Визитная карточка капоэйра прямо около Вашего аккаунта!',
        src: `/../images/ver2/scores/achivments/aubatido_1.png`,
    },
    cobra_1: {
        type: 'icon',
        grade: 4,
        title: 'Cobra',
        discription: 'ИКОНКА: Кобра, гибкая и опасная!',
        src: `/../images/ver2/scores/achivments/cobra_1.png`,
    },
    cobra_2: {
        type: 'icon',
        grade: 5,
        title: 'Cobra',
        discription: 'ИКОНКА: Изысканная золотая кобра, гибкая и опасная!',
        src: `/../images/ver2/scores/achivments/cobra_2.png`,
    },
    
    fogo_1: {
        type: 'icon',
        grade: 5,
        title: 'Fogo',
        discription: 'ИКОНКА: Нет дыма без огня! Для тех, кто всегда с огоньком!',
        src: `/../images/ver2/scores/achivments/fogo_1.png`,
    },
    heart_1: {
        type: 'icon',
        grade: 4,
        title: 'Fogo',
        discription: 'ИКОНКА: Просто милое сердечка около Вашего аккаунта!',
        src: `/../images/ver2/scores/achivments/heart_1.png`,
    },
    melody_1: {
        type: 'icon',
        grade: 4,
        title: 'Melodia',
        discription: 'ИКОНКА: Для тех, кто с музыкой по жизни!',
        src: `/../images/ver2/scores/achivments/melody_1.png`,
    },
    melody_2: {
        type: 'icon',
        grade: 5,
        title: 'Melodia',
        discription: 'ИКОНКА: Изысканная иконка для тех, кто с музыкой по жизни!',
        src: `/../images/ver2/scores/achivments/melody_2.png`,
    },
    perereca_1: {
        type: 'icon',
        grade: 4,
        title: 'Perereca',
        discription: 'ИКОНКА: Эта лягушка очень опасна, если кто-то решит проверить её на прочность!',
        src: `/../images/ver2/scores/achivments/perereca_1.png`,
    },
    perereca_2: {
        type: 'icon',
        grade: 5,
        title: 'Perereca',
        discription: 'ИКОНКА: Эта лягушка очень опасна, если кто-то решит проверить её на прочность! А еще она золотая!',
        src: `/../images/ver2/scores/achivments/perereca_2.png`,
    },
    tartaruga_1: {
        type: 'icon',
        grade: 4,
        title: 'Tartaruga',
        discription: 'ИКОНКА: Прочная и устойчивая, в капоэйра черепахи еще скажут своё слово!',
        src: `/../images/ver2/scores/achivments/tartaruga_1.png`,
    },
    tartaruga_2: {
        type: 'icon',
        grade: 4,
        title: 'Tartaruga',
        discription: 'ИКОНКА: Прочная и устойчивая, в капоэйра черепахи еще скажут своё слово!',
        src: `/../images/ver2/scores/achivments/tartaruga_2.png`,
    },
    tartaruga_3: {
        type: 'icon',
        grade: 5,
        title: 'Tartaruga',
        discription: 'ИКОНКА: Золотая черепаха, прочная и устойчивая!',
        src: `/../images/ver2/scores/achivments/tartaruga_3.png`,
    },
    question_1: {
        type: 'icon',
        grade: 5,
        title: 'Ваш апелидо',
        discription: 'ИКОНКА: Если Вы хотите себе иконку под Ваш апелидо, но иконки пока нет в магазине - приобретайте этот предмет. Администратор портала разработает и добавит Вам иконку вместо этой. Ну а пока у Вас будет стоять стильная корона!',
        src: `/../images/ver2/scores/achivments/question_1.png`,
    },
    maximus_1: {
        type: 'icon',
        grade: 5,
        title: 'Maximus',
        discription: 'ИКОНКА: Золотая иконка Maximus',
        src: `/../images/ver2/scores/achivments/maximus_1.png`,
    },


    kmo: {
        type: 'icon',
        grade: 4,
        title: 'Кубок Москвы',
        discription: 'ИКОНКА: специальная иконка в честь Кубка Москвы 2022!',
        src: `/../images/ver2/scores/achivments/kmo.png`,
    },

    spring_3: {
        type: 'icon',
        grade: 4,
        title: 'Весенний лист',
        discription: 'Иконка за 3 место в Весеннем Челлендже 2023',
        src: `/../images/ver2/scores/achivments/spring_3.png`,
    },

    spring_1: {
        type: 'icon',
        grade: 5,
        title: 'Весенний лист',
        discription: 'Иконка за 1 место в Весеннем Челлендже 2023',
        src: `/../images/ver2/scores/achivments/spring_1.png`,
    },

    spring: {
        type: 'icon',
        grade: 3,
        title: 'Весенний лист',
        discription: 'Иконка за участие в Весеннем Челлендже 2023',
        src: `/../images/ver2/scores/achivments/spring.png`,
    },

}