import React from 'react';
import { groupBy, orderBy } from 'lodash'
import './styles.css';

const DAYS = {
    1: 'ПОНЕДЕЛЬНИК',
    2: 'ВТОРНИК',
    3: 'СРЕДА',
    4: 'ЧЕТВЕРГ',
    5: 'ПЯТНИЦА',
    6: 'СУББОТА',
    7: 'ВОСКРЕСЕНЬЕ',
}

// addRequest

export const Subgroup = (props) => {

    const { subgroup, index } = props;
    const { title, adres, city = '', color, settingsAsObj = {} } = subgroup;

    let agetext = ``;
    const { agefrom, ageto, metro, notOngoing } = settingsAsObj;

    if (agefrom) agetext += `от ${agefrom} `
    if (ageto) agetext += `до ${ageto}`
    if (agetext) agetext += ' лет'
    if (agetext) agetext = `( ${agetext} )`

    const color_ = color[0] === "#" ? color : `#${color}`

    console.log({ subgroup });

    return <div className='sh_subgroup-container' >
        <div className='sh_subgroup-container-marker' style={{ background: color_ }} />
        <div className='sh_subgroup-container-left'>
            <div className='___big-text'>
                {`${title} ${agetext}`}
            </div>
            {adres && <div className='___little-text'>
                <b>Адрес: </b> {city} {adres}
            </div>}
            {metro && <div className='___little-text'>
                <b>Метро: </b> {metro}
            </div>}
        </div>
        <div className='sh_subgroup-container-right'>
            {!notOngoing && <div> <span>ИДЁТ НАБОР</span> </div>}
        </div>
    </div>
}

export const SubgroupsByLocation = ({ subgroups = [], addRequest, isMobile }) => {

    let answer = [];

    const byCity = groupBy(subgroups, 'city');

    const citys = Object.keys(byCity);
    for (let i = 0; i < citys.length; i++) {
        const city = citys[i];
        const byAdres = groupBy(byCity[city], 'adres');
        const adreses = Object.keys(byAdres);
        for (let j = 0; j < adreses.length; j++) {
            const adres = adreses[j];
            const metro = (byAdres[adres][0].settingsAsObj && byAdres[adres][0].settingsAsObj.metro) || null;

            answer.push(<div className='___header'>
                <div className='____adres'>
                    {`${city}, ${adres}`}
                </div>
                {(metro && metro.length > 3) && <div className='____metro'>
                    {`м. ${metro}`}
                </div>
                }
            </div>)

            answer.push(<div className='__subgroups'>
                {byAdres[adres].map(subgroup => <SubgroupByAdres_ isMobile = {isMobile} addRequest={addRequest} subgroup={subgroup} />)}
            </div>)

        }
    }
    return answer;
}

const SubgroupInAdres = (props) => {

    const { subgroup, index } = props;
    const { title, adres, city = '', color, settingsAsObj = {}, id } = subgroup;

    let agetext = ``;
    const { agefrom, ageto, metro, notOngoing } = settingsAsObj;

    if (agefrom) agetext += `от ${agefrom} `
    if (ageto) agetext += `до ${ageto}`
    if (agetext) agetext += ' лет'
    if (agetext) agetext = `( ${agetext} )`

    const color_ = color[0] === "#" ? color : `#${color}`

    console.log({ subgroup });

    return <div className='sh_subgroup-container' >
        <div className='sh_subgroup-container-marker' style={{ background: color_ }} />
        <div className='sh_subgroup-container-left'>
            <div className='___big-text'>
                {`${title} ${agetext}`}
            </div>
            {adres && <div className='___little-text'>
                <b>Адрес: </b> {city} {adres}
            </div>}
            {metro && <div className='___little-text'>
                <b>Метро: </b> {metro}
            </div>}
        </div>
        <div className='sh_subgroup-container-right'>
            {!notOngoing && <div> <span>ИДЁТ НАБОР</span> </div>}
        </div>
    </div>
}

const SubgroupByAdres_ = ({ subgroup, addRequest, isMobile }) => {
    if (!subgroup) return null;

    const { id, title, color, shadule, settingsAsObj = {} } = subgroup;
    const { agefrom, ageto, payment, notOngoing } = settingsAsObj;

    if (!shadule || !shadule.length) return null;

    const TitleBlock = () => {

        let agetext = ``;

        if (agefrom) agetext += `${agefrom} `
        if (ageto) agetext += `- ${ageto}`
        if (agetext) agetext += ' лет'
        if (agetext) agetext = `${agetext} `

        return <div className='sh_subgroup_bycity_subgroup_title'>
            <div className='sh_subgroup_bycity_subgroup_title_upper'>
                <div className='sh_subgroup_bycity_subgroup_title_upper_left'>{agetext}</div>
                <div className='sh_subgroup_bycity_subgroup_title_upper_right'>{title}</div>
            </div>
        </div>
    }

    const ShaduleBlock = () => <div className='sh_subgroup_bycity_subgroup_shadule'>
        {shadule.map(({ day, timeStart, timeEnd }) => {
            return <div className='sh_subgroup_bycity_subgroup_shadule_one'>
                <span>{DAYS[day]}</span>
                <span>{`${timeStart} - ${timeEnd}`}</span>
            </div>
        })}
    </div>

    const PaymentBlock = () => {

        let agetext = ``;

        if (!payment) return null;

        const day = payment.find(x => x.type === 'day');
        const month = payment.find(x => x.type === 'month');

        if (!day && !month) return null;

        return <div className='sh_subgroup_bycity_subgroup_payments'>
            {month && <div className='sh_subgroup_bycity_subgroup_payment_one'>
                {`МЕСЯЦ - ${month.value} ₽`}
            </div>}
            {day && <div className='sh_subgroup_bycity_subgroup_payment_one'>
                {`РАЗОВОЕ - ${day.value} ₽`}
            </div>}
        </div>
    }

    const NaborBlock = () => <div className='sh_subgroup_bycity_subgroup_shadule'>
        <div className='sh_subgroup_bycity_subgroup_shadule_one'>
            <span>{notOngoing ? 'Нет набора' : 'Идёт набор'}</span>
        </div>
    </div>

    const RequestBlock = () => <div className='sh_subgroup_bycity_subgroup_shadule'>
        <div className='sh_subgroup_bycity_subgroup_request' onClick={() => { addRequest(id)}}>
            <span>ЗАПИСАТЬСЯ</span>
        </div>
    </div>

    return <div key={`ssccpp_${id}`} className='sh_subgroup_bycity_subgroup'>
        <TitleBlock />
        <ShaduleBlock />
        {!isMobile && <PaymentBlock />}
        {!isMobile && <NaborBlock />}
        {(addRequest) && <RequestBlock />}
    </div>

}