import React from 'react';
import PlaceRect from 'src/ver2/containers/lowlavel/placeRect';
import LittlePerson from 'src/ver2/components/person/little';
import SchoolPlate from 'src/ver2/components/school/schoolPlate';
import { PersonPlaceRectMini } from 'src/ver2/components/place/person';
import { SchoolPlaceRectMini } from 'src/ver2/components/place/school';

export const OneRaitingBlock = ({ data, category = '', raitingSubject = 'user' }) => {
    const { PlaceName, Place, Category, AllScores, UserId, SchoolId } = data;

    const height = 25;
    const fontSize = height * 0.75;
    let className = 'raiting_big_plashka__oneraiting raiting_big_plashka__oneraiting_row ';
    if (category === Category) {
        className += 'raiting_big_plashka__oneraiting_row_selected';
    }

    return <div className={className}>
        <div className='raiting_big_plashka__oneraiting_place'>
            <div className='raiting_big_plashka__oneraiting_place_inside'>
                <PlaceRect {...{ height, Place, PlaceName }} />
            </div>
        </div>
        <div style={{ fontSize }} className='raiting_big_plashka__oneraiting_category'>
            {
                (raitingSubject === 'user') ?
                    <LittlePerson height={50} personId={UserId} personsDirect = {false} />
                    :
                    <SchoolPlate height={50} width={'100%'} SchoolId={SchoolId} />
            }
        </div>
        <div style={{ fontSize }} className='raiting_big_plashka__oneraiting_scores'>
            <span>{AllScores}</span>
        </div>
    </div >
}


// data  - [ {Place, UserId} ]
export const BestRaitingBlock = ({ data, raitingSubject = 'user', isMobile }) => {

    const height = isMobile ? 120 : 260;

    if (raitingSubject === 'user')
        return <div className='raiting_big_plashka__best'>
            {data.map((subject) => {
                return <PersonPlaceRectMini key={'brbb-' + subject.UserId} personId={subject.UserId} height={height} place={subject.Place} />
            })}
        </div>


    if (raitingSubject === 'school')
        return <div className='raiting_big_plashka__best'>
            {data.map((subject) => {
                return <SchoolPlaceRectMini key={'brbb-' + subject.UserId} schoolId={subject.SchoolId} height={height} place={subject.Place} />
            })}
        </div>

}