
export const CROSS_ELEMENTS = {
    french : {martnochao: true},
    armada : { qexuada: true, pulada: true, armpuldouble: true, parafuso: true },
    martelo: {gancho: true, chapa: true, martnochao: true},
    compassa: {reversao: true, raiz: true, fintcomp: true, complokot: true},
    hole: {auusual: true},
    macaquinho: {macaco: true, chibata: true},
    macaco: {sdobrado: true, paulista: true},
    batido: {abatdupla: true},
    negativa: {cortacapim: true},
    acabesa: {auusual: true, cabesadupla: true},
    paulista: {strekosat: true},
    amazonia: {auusual: true},
    salback: {salforward: true, salside: true, blansh: true},
    bensao: {chapa: true},
    cocorinha: {negativa: true},
    parafuso: {kick540: true, raiz: true},
    semmao: {auusual: true},
    trocado: {trocprogib: true},
    pulada: {armpuldouble: true, jumpdupla: true},
    coluna: {trocprogib: true},
    bitwist: {semmao: true},
    blansh: {salback: true},
    handstand: {headstand: true, handstback: true, canivete: true},
    helicoptero: {reversao: true},
    ponte: {ponwh: true, ponlokot: true, perekid: true, resistencia: true},
    augirotoria: {fullgirotoria: true},
    beduino: {semmao: true},
    baza: {frontal: true}

}

export const STATISTICS_ELEMENTS = {
  typesByTierScores: {
    1: {allStrike: 7, allFlow: 7, allJump: 5, allFreeze: 7},
    2: {allStrike: 24, allFlow: 76, allJump: 61, allFreeze: 20},
    3: {allStrike: 23, allFlow: 53, allJump: 69, allFreeze: 30}
  },
  allMax: {
    allFlowMax: 136,
    allFreezeMax: 57,
    allJumpMax: 135,
    allStrikeMax: 54
  },
  TierCounts: {
    1: 23,
    2: 28,
    3: 14
  },
  allPool: 65
}

export const ELEMENTS = [
    {
        key: 'armada',
        title: ['Армада', 'Armada'],
        type: 'strike',    
        subtypes: ['round'],
        isLogo: true, 
        isVideo: true,  
        Tutorials: [
          {
            title: 'Encyclopedia of Capoeira',
            yt_tail: 'QCsodJtb_64'
          }
        ],      
        Discription: 
        `
          Классический круговой удар в капоэйра, наносится боковой поверхностью стопы./
          Поскольку, как и большая часть ударов с разворота, имеет слепую зону, особое внимание уделяется рукам. Они не только закручивают корпус, 
          но и постоянно защищают голову./
          Требует координации и отрабатывается капоэйристами постоянно - от самых младших до старших поясов./
          Может быть выполнен в прыжке (армада пулада), или же с двойным прокрутом в прыжке, что является невероятно технически сложным элементом.
        `,
        DiscriptionShort:
        `
          Базовый круговой удар в капоэйра, наносится боковой поверхностью стопы./
          Требует координации и отрабатывается капоэйристами постоянно - от самых младших до старших поясов./
        `,
        Tier: 1, 
        Points: 3
    },
    {
      key: 'qexuada',
      title: ['Кейшада', 'Queixada'],    
      subtypes: ['round'],
      type: 'strike',
      isLogo: true, 
      isVideo: true,
      Tutorials: [
        {
          title: 'Encyclopedia of Capoeira',
          yt_tail: '3rOKywRHtCo'
        }
      ],
      Discription: 
      `
        Используется как атакующий, так и контратакующий удар. Капоэйрист сходит с линии движения, после чего наносит удар в противоположную сторону./
        Наносится внешним ребром стопы. Используется чаще для развития игровой ситуации, чем для нанесения серьёзного урона противнику./
        Для новичков 
        является хорошей отправной точной в мир сложной координации капоэйра, так как во время выполнения кейшады используется много изначально сложных
        для начинающих базовых принципов: разгон тела руками, неотрывный вгляд на противника, вворот бёдер после доворота корпуса и т.д.
      `,
      DiscriptionShort:
      `
        Используется как атакующий, так и контратакующий удар./
        Совершая нырок в одну сторону, капоэйрист внешним ребром стопы наносит атаку в противоположную./
      `,
      Points: 2,
      Tier: 1
    },
    {
      key: 'french',
      title: ['Миа луи ди френч', 'Meia lua de frente'],
      subtypes: ['strick', 'round'],
      type: 'strike',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Базовый удар, с которого большинство начинает изучение капоэйра./
        Наносится внутренним ребром стопы. Имеет прикладное значение: таким ударом можно выбить предмет из рук противика. В капоэйра же несёт характер
        вылавливающего удара: можно преградить движение партнёра на фазе захода или выхода с элемента, не нанеся ему при этом серьёзного ушерба./
        Можно отнести как к прямым ударам (т.е бёдра в момент удара не перекручиваются), так и к круговым (т.к. нога проделывает половину круга, от чего 
          и оригинальное название: передний полумесяц)
      `,  
      DiscriptionShort:
      `
        Базовый удар, с которого большинство начинает изучение капоэйра./
        Наносится внутренним ребром стопы. Имеет прикладное значение: таким ударом можно выбить предмет из рук противика./
      `,
      Tier: 1, 
      Points: 1    
    },
    {
      key: 'martelo',
      title: ['Мартело', 'Martelo'],
      subtypes: ['strick'],
      type: 'strike',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Удар, аналог которого встречается почти в любом виде единоборств, где задействована техника ног. И неспроста - по совокупности силы и эффективности мартеле
        почти нет равных. Этот удар быстрый, точечный, может быть произведён чуть ли в не любую часть тела противника: от головы до голени./
        В капоэйра в основном наносится в голову, поэтому требует серьёзной проработки, хотя изначально кажется простым. Без должной растяжки и мобильности суставов шансы выполнить мартелу корректно стремятся к нулю./
        Изначально выполняется как прямой удар, но может быть выполнен и с проносом с использованием опорной руки или без.
      `, 
      DiscriptionShort:
      `
        Удар, аналог которого встречается почти в любом виде единоборств, где задействована техника ног./
        Наносится подъемом стопы, может быть выполнен в любую часть тела противника./
        Один из наиболее прикладных и мощных ударов капоэйра.
      `,
      Tier: 2, 
      Points: 3 
    },
    {
      key: 'compassa',
      title: ['Миа луи ди компасса', 'Meia lua de compassa'],    
      subtypes: ['round'],
      type: 'strike',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Одна из визитных карточек капоэйра. Является одновременно и защитой и атакой: капоэйрист нырком уводит тело с линии атаки, после чего сразу
        использует инерцию тела для кругового удара, используя руки в качестве опоры. Атакующая поверхность - пятка./
        Обычно выделяют два варианта исполнение: с опорой на две руки и на одну руку. Первый вариант даёт лучший контроль над ударом, второй - большую скорость и силу./
        Компасса прекрасно ложится на связки любых уровней: опора на руки позволяет использовать нижние перемещения и стойки, а разгон ногой - вывести удар на
        дальнейший прыжковый элемент, или серию ударов.
      `, 
      DiscriptionShort:
      `
      Одна из визитных карточек капоэйра. Является одновременно и защитой и атакой: капоэйрист нырком уводит тело с линии атаки, после чего сразу
      использует инерцию тела для кругового удара, используя руки в качестве опоры. Атакующая поверхность - пятка.
      `,
      Tier: 2, 
      Points: 4,
      Tutorials: [
        {
          title: 'CapoeiraVibe',
          yt_tail: '2PTkgrZ3Q7A'
        },
        {
          title: 'Howcast',
          yt_tail: 'IiLqVCyyoPs'
        }
      ]
    },
    {
      key: 'hole',
      title: ['Холе', 'Hole'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Базовое перемещение в капоэйра. Капоэйрист использует руки в качестве опоры и переставляет ноги. Как и большинство переходов, 
        может использоваться и в качестве защиты, и для смены позиции, и для разгона на дальнейший элемент./
        Часто используется как подготовительное упражнение для ау (колеса).
      `,
      DiscriptionShort: 
      `
        Базовое перемещение в капоэйра. Капоэйрист использует руки в качестве опоры и переставляет ноги. Как и большинство переходов, 
        может использоваться и в качестве защиты, и для смены позиции, и для разгона на дальнейший элемент./
      `,
      Tier: 1, 
      Points: 2 
    },
    {
      key: 'auusual',
      title: ['Ау','Au', 'Колесо'],
      subtypes: ['perekidka'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Tutorials: [
        {
          title: 'Encyclopedia of Capoeira',
          yt_tail: 'SNAhuN-iXcs'
        }
      ],
      Discription: 
      `
        Колесо. В отличие от гимнастического варианта служит не только для набора инерции, но и для позиционировании капоэйриста в кругу./
        Во многих игровых стилях ау - единственно допустимый вариант для выхода в роду./
        Имеет чуть ли не бесконечное количество вариантов (колеса с прогибом, с согнутыми ногами, с прямыми, с выходом в стойку и т.д. т.п),
        каждый из которых имеет своё название (au de frente, au de coluna ...).
      `,
      DiscriptionShort: 
      `
      Колесо. В отличие от гимнастического варианта, служит не только для набора инерции, но и для позиционировании капоэйриста во время игры./
      Во многих игровых стилях ау - единственно допустимый вариант акробатики для выхода в круг./
      `,
      Tier: 1, 
      Points: 3
    },
    {
      key: 'macaquinho',
      title: ['Макакинья', 'Macaquinho'],
      subtypes: ['perekidka', 'flow'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        По сути является перекидкой ног через голову, при этом тело капоэйриста опирается на локоть./
        Выполняется как с согнутыми, так и с прямыми ногами. В качестве начальной позиции используются негачива или кокоринья, хотя могут быть
        и более сложные заходы.
      `,
      Tier: 2, 
      Points: 5,
      Tutorials: [
        {
          title: 'AwesomeCapoeiraTutorials',
          yt_tail: '8JcxHmmjUE8'
        }
      ]
    },
    {
      key: 'macaco',
      title: ['Макака', 'Macaco'],
      subtypes: ['perekidka', 'flow'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,      
      Discription: 
      `
        Перекидка ног через голову, выполненная из сидячего положения. Отправная точка для огромного количества разных перемещений./
        Требует как хорошей координации, так и понимания физики перемещения тела./
        В основном выделяют исполнения данного элемента через одну или две руки, а также в разножку или со сведёнными ногами./
        Может использоваться не только как красивый переход, но и для атаки противника двумя ногами, или же выхода на некоторые броски.
      `,
      DiscriptionShort:
      `
        Перекидка ног через голову, выполненная из сидячего положения.
      `,
      Tier: 2, 
      Points: 7,
      Tutorials: [
        {
          title: 'AwesomeCapoeiraTutorials',
          yt_tail: 'WpGHhOprx_k'
        },
        {
          title: 'Encyclopedia of Capoeira',
          yt_tail: 'yjdu4bBjgDE'
        }
      ]
    },
    {
      key: 'batido',
      title: ['Ау батидо', 'Au batido'],
      subtypes: ['freeze'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Еще одна визитная карточка капоэйра. Иногда этот элемент носит название Клюв Попугая./
        Это стойка на одной руке, выполненная с махом ведующей ноги./
        Помимо непосредственно фриза на руке, имеет и ударный потенциал./
        Существует более сложный вариант выполнения - со сведенными вместе ногами.
      `,
      Tier: 2, 
      Points: 7
    },
    {
      key: 'diagonale',
      title: ['Эшкива диагонале', 'Esquiva diagonal'],
      type: 'defence',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Базовый защитный элемент в капоэйра. В разных школах капоэйра под диагональной эшкивой могут подразумеваться
        различные виды защиты.
      `, 
      DiscriptionShort:
      `
         Базовый защитный элемент в капоэйра. В разных школах капоэйра под диагональной эшкивой могут подразумеваться
         различные виды защиты.
      `,
      Tier: 1, 
      Points: 1
    },
    {
      key: 'queda',
      title: ['Кеда де ринс', 'Queda de rins'],
      subtypes: ['freeze'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Один из базовых нижних фризов в капоэйра. Всегда задействована рука с упором на локте, в остальном может использовать от одной точки опоры (самый сложный вариант)
        до трёх (две руки и голова)./
        Ноги могут находиться почти в любом положении: согнуты в коленях, выпрямлены, в разножку, сведены и т.д.
        `,
      Tier: 1,       
      Points: 4
    },
    {
      key: 'negativa',
      title: ['Негачива', 'Negativa'],
      type: 'defence',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Фактически, данный элемент используется для смены уровня игры: через пеложение негачива капоэйрист связывает нижние переходы./
        Однако, может быть использован и отдельно в качестве защиты, поэтому во время выполнения одна рука почти всегда прекрывает голову./
        Выполняется во всех четырёх направлениях: лицом к противнику, боком (боче) или спиной (инвертао).
        `,
        DiscriptionShort:
        `
        Фактически, данный элемент используется для смены уровня игры: через пеложение негачива капоэйрист связывает нижние переходы./
        Однако, может быть использован и отдельно в качестве защиты, поэтому во время выполнения одна рука почти всегда прекрывает голову./
        `,
      Tier: 1,       
      Points: 2
    },
    {
      key: 'lateral',
      title: ['Эшкива латерао', 'Esquiva lateral'],
      type: 'defence',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Один из базовых вариантов защит в капоэйра: капоэйрист из положения жинги поворачивает стопы на 90 градусов, поворачивает и опускает корпус, закрывая голову руками. 
        `,
        DiscriptionShort:
        `
        Один из базовых вариантов защит в капоэйра: капоэйрист из положения жинги поворачивает стопы на 90 градусов, поворачивает и опускает корпус, закрывая голову руками. 
        `,
      Tier: 1,       
      Points: 1
    },
    {
      key: 'frontal',
      title: ['Эшкива фронтао', 'Esquiva frontal'],
      type: 'defence',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Одна из базовых вариантов защит в капоэйра: капоэйрист опускается вниз из положения жинги (но не ставит колено на пол), вытягивает корпус вперёд и отворачивает голову от удара, закрывая её рукой.
        `,
        DiscriptionShort: 
        `
          Одна из базовых вариантов защит в капоэйра: капоэйрист опускается вниз из положения жинги (но не ставит колено на пол), вытягивает корпус вперёд и отворачивает голову от удара, закрывая её рукой.
          `,
      Tier: 1,       
      Points: 1
    },
    {
      key: 'resistencia',
      title: ['Резистенция', 'Resistencia'],
      subtypes: ['flow'],
      type: 'defence',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Один из продвинутых защит в капоэйра: капоэйрист откидывает корпус назад в прогиб, при этом одна рука защищает голову, а вторая ставится на пол для баланса и дальнейших перемещений`,
        DiscriptionShort: 
        `
          Одна из базовых вариантов защит в капоэйра: капоэйрист опускается вниз из положения жинги (но не ставит колено на пол), вытягивает корпус вперёд и отворачивает голову от удара, закрывая её рукой.
        `,
      Tier: 2,       
      Points: 2
    },
    {
      key: 'viracorpo',
      title: ['Вращение корпусом', 'Vira corpo'],
      subtypes: ['flow'],
      type: 'defence',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Один из продвинутых защит в капоэйра: капоэйрист откидывает корпус назад в прогиб и совершает им круговой движение. Руки при этом защищают голову`,
      Tier: 2,       
      Points: 2
    },
    {
      key: 'acabesa',
      title: ['Ау кабеса', 'Au de cabeca'],
      subtypes: ['perekidka'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Колесо, выполненное с опорой на голову. Имеет более сложную вариацию со сведёнными вместе ногами.
      `,
      Tier: 1,       
      Points: 4
    },
    {
      key: 'cabesadupla',
      title: ['Ау кабеса дупла', 'Au de cabeca dupla'],
      subtypes: ['perekidka'],
      type: 'movement',
      isLogo: false, 
      isVideo: true,
      Discription: 
      `
        Колесо, выполненное с опорой на голову со сведёнными вместе ногами.
      `,
      Tier: 1,       
      Points: 5
    },
    {
      key: 'paulista',
      title: ['Паулиста', 'Macaco em pe'],
      subtypes: ['perekidka', 'flow'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Колесо с прогибом себе за спину.
      `,
      Tier: 2,       
      Points: 8,
      Tutorials: [
        {
          title: 'AwesomeCapoeiraTutorials',
          yt_tail: 'kV8HmgrX32E'
        }
      ]
    },
    {
      key: 'amazonia',
      title: ['Ау амазония', 'Au de frente'],
      subtypes: ['perekidka', 'flow'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Колесо с прогибом вперёд, в основном известное как ау ди френче.
      `,        
      DiscriptionShort: 
      `
        Колесо с прогибом вперёд, в основном известное как ау ди френче.   
      `,
      Tier: 2,       
      Points: 7,
      Tutorials: [
        {
          title: 'AwesomeCapoeiraTutorials',
          yt_tail: 'hhN6Xsr6ltQ'
        }
      ]
    },
    {
      key: 'salback',
      title: ['Сальто назад'],
      subtypes: ['jump', 'perekidka'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Сальто назад.
      `,
      Tier: 2,       
      Points: 9
    },
    {
      key: 'salside',
      title: ['Боковое сальто'],
      subtypes: ['jump', 'perekidka'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Боковое сальто.
      `,
      Tier: 2,       
      Points: 10
    },
    {
      key: 'salforward',
      title: ['Сальто вперед'],
      subtypes: ['jump'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Сальто вперёд.
      `,
      Tier: 3,       
      Points: 13
    },
    {
      key: 'baza',
      title: ['Эшкива ди френч'],
      type: 'defence',
      isLogo: true, 
      isVideo: true,
      Tier: 1,       
      Points: 1
    },
    {
      key: 'bensao',
      title: ['Бенсао', 'Bencao'],
      subtypes: ['strick'],
      type: 'strike',
      isLogo: true, 
      isVideo: true,
      
      Discription:
      `
        Базовый прямой удар в капоэйра./ 
        На начальных этапах изучения чаще используется скорее как толчок. В таком случае поверхностью толчка является вся стопа./
        Однако, выделают и более прекладные варианты техники бенсао, когда капоэйрист наносит точечный удар передней частью стопы,
        тем самым минимизирую атакующую поверхность./
        Беносао производится как с задней, так и с передней ноги, может быть выполнен в прыжке.
      `,
      DiscriptionShort:
      `
      Базовый прямой удар в капоэйра. На начальных этапах изучения чаще используется скорее как толчок.
      `,
      Tutorials: [
        {
          title: 'Encyclopedia of Capoeira',
          yt_tail: '_9Sl9MRO05w'
        }
      ], 
      Tier: 1,       
      Points: 1
    },
    {
      key: 'cocorinha',
      title: ['Кокоринья', 'Cocorinha'],
      type: 'defence',
      Discription:
      `
        Базовая и одна из самых начальных защит в капоэйра./
        Капоэйрист садится на корточки и защищает голову рукой./
        На первых этапах изучается для того, чтобы капоэйрист привыкал уходить от ударов всем телом и быстро менять вертикальный уровень игры.
      `,
      DiscriptionShort:
      `
      Базовая и одна из самых начальных защит в капоэйра./
      Капоэйрист садится на корточки и защищает голову рукой./
      `,
      isLogo: true, 
      isVideo: true,
      Tier: 1,       
      Points: 1
    },
    {
      key: 'parafuso',
      title: ['Парафусо', 'Parafuso'],
      type: 'strike',    
      subtypes: ['jump', 'round'],
      Discription:
      `
        Один из первых прыжковых ударов, которым обучается капоэйрист./ 
        Ведущая нога может иметь как атакующий, так и разгонный потенциал. В последнем случае вся сила вкладывается во второй удар./
        Ударной поверхностью может выступать как ребро стопы (подобно удару армада), так и подъемная часть (как в мартеле).
      `,
      DiscriptionShort:
      `
      Один из первых прыжковых ударов, которым обучается капоэйрист./ 
      Ведущая нога может иметь как атакующий, так и разгонный потенциал. В последнем случае вся сила вкладывается во второй удар./
      `,
      isLogo: true, 
      isVideo: true,
      Tier: 2,       
      Points: 5
    },
    {
      key: 'sdobrado',
      title: ['С-добрадо', 'S-dobrado'],
      subtypes: ['perekidka', 'flow'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Tier: 3,       
      Points: 10,
      Tutorials: [
        {
          title: 'AwesomeCapoeiraTutorials',
          yt_tail: 'Zot6ja0joBo'
        }
      ]
    },
    {
      key: 'semmao',
      title: ['Ау сем мао', 'Au sem mao'],  
      subtypes: ['jump'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Tier: 2,       
      Points: 7
    },
    {
      key: 'trocado',
      title: ['Ау трокада', 'Au trocado'],
      subtypes: ['perekidka'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Один из вариантов колеса, где маховая и толчковая нога меняются ролями при заходе./
        Например, если при выполнении вправо обычного колеса капойрист выталкивает себя правой ногой,
        то при выполнении вправо трокады толчковой ногой будет выступать левая нога. Она же придёт
        первой на землю./
        Выделяется более сложный вид данного элемента - трокада с прогибом.
      `,
      Tier: 2,       
      Points: 5
    },
    {
      key: 'trocprogib',
      title: ['Ау трокада (прогиб)', 'Au trocado quebrada'],
      subtypes: ['perekidka', 'flow'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Более сложный вариант трокады, выполненный с прогибом и отличающейся характерным заходом - опорная рука выходит вперёд, а не вбок.
      `,
      Tier: 3,       
      Points: 10
    },
    {
      key: 'reversao',
      title: ['Ау реверсао', 'Au reversao'],
      subtypes: ['perekidka', 'flow'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Tier: 2,       
      Points: 9,
      Tutorials: [
        {
          title: 'AwesomeCapoeiraTutorials',
          yt_tail: 'vC0FPzIkhGw'
        }
      ]   
    },
    {
      key: 'pulada',
      title: ['Армада пулада', 'Armada pulada'],
      subtypes: ['round', 'jump'],
      type: 'strike',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Более сложное исполнение удара Армада. В момент удара капоэйрист подпрыгивает на опорной ноге.
      `,
      
      DiscriptionShort:
      `
        Более сложное исполнение удара Армада. В момент удара капоэйрист подпрыгивает на опорной ноге.
      `,
      Tier: 2,       
      Points: 4
    },
    {
      key: 'armpuldouble',
      title: ['Армада пулада 2х', 'Armada pulada 2х'],
      subtypes: ['round', 'jump'],
      type: 'strike',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Двойная армада в прыжке./
        Один из самых сложных с точки зрения техники выполнения ударов капоэйра./
        Находится на границе между ударной техникой и акробатикой./
        Один из нюансов выполнения заключается в том, что в отличие от 
        схожего элемента в трикинге ( kick 720 ) в капоэйра удар в воздухе выполняется два раза.
      `,
      
      DiscriptionShort:
      `
        Двойная армада в прыжке./
        Один из самых сложных с точки зрения техники выполнения ударов капоэйра./
        Находится на границе между ударной техникой и акробатикой./
      `,
      Tier: 3,       
      Points: 10
    },
    {
      key: 'abatdupla',
      title: ['Ау батида дупла', 'Au batido dupla'],
      subtypes: ['freeze'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Более сложный вариант исполнения элемента Ау батида, в котором капоэйрист сводит вместе ноги, 
        находясь в стойке на одной руке.
      `,
      Tier: 3,       
      Points: 10
    },
    {
      key: 'chibata',
      title: ['Ау чибата', 'Au chibata'],
      subtypes: ['perekidka'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Перекидка ног по направлению движения. При этом одна из рук капоэйриста находится в упоре на локте./
        В явном виде элемент выполняется из положения Негачива, однако может использоваться как выход из
        различных перекидочных элементов, таких как Ау, Макака, Фляк и так далее.
      `,
      Tier: 1,       
      Points: 4,
      Tutorials: [
        {
          title: 'AwesomeCapoeiraTutorials',
          yt_tail: 'ffVThHCBTUI'
        }
      ]   
    },
    {
      key: 'coluna',
      title: ['Ау с прогибом', 'Au de coluna'],
      subtypes: ['perekidka', 'flow'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Колесо с проогибом./
        Вариант выполнения элемента ау, когда ноги капоэйриста проходят фактически в горизонтальной плоскости позади него.      
        `,
        
      DiscriptionShort:
      `
         Колесо с проогибом./
         Вариант выполнения элемента ау, когда ноги капоэйриста проходят фактически в горизонтальной плоскости позади него.
      `,
      Tier: 2,       
      Points: 8
    },
    {
      key: 'bitwist',
      title: ['Винт'],
      subtypes: ['jump'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Элемент известный в акробатике и трикинге как битвист, или горизонтальный винт./
        Помимо того, что элемент сам по себе невероятно сложный, еще сложнее научиться выполнять его
        в связках и вставлять в игру в роде./
        Капоэйрист выпрыгивает в горизонтальной плоскости и совершает оборот вокруг своей оси.
      `,
      Tier: 3,       
      Points: 15,
      Tutorials: [
        {
          title: 'Pigmie',
          yt_tail: '0pHYOqVKOE4'
        }
      ]  
    },
    {
      key: 'raiz',
      title: ['Райз', 'Raiz'],
      subtypes: ['jump'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
        Прыжковый элемент, похожий на удар компасса, без постановки рук на пол.
      `,
      Tier: 2,       
      Points: 9
    },
    {
      key: 'blansh',
      title: ['Бланш'],
      subtypes: ['jump'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Один из самых завораживающих элементов акробатики, который можно встретить и в капоэйра.
       Чаще всего применяется для эффектного выхода в круг./
       По сути это сальто назад, при выполнении которого капоэйрист не совершает классической группировки, и тело находится
       в раскрытом состоянии./
       Выделают варианты исполнения со сведенными вместе ногами и в разножку.
      `,
      Tier: 3,       
      Points: 11
    },
    {
      key: 'chapa',
      title: ['Щапа', 'Chapa'],
      subtypes: ['strick'],
      type: 'strike',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Весьма эффективный и прикладной прямой удар, аналоги которого можно встретить во множесвте единоборств./
       Основным отличием от удара Бенсао здесь выступает вкрут бедра, придающий ноге большую силу и скорость./
       Помимо этого конкретно в капоэйра удар примечателен большим количеством положений, из которого применяется:
       с опорой на руки, с разворота, с подшагами и даже в прыжке.
      `,
      DiscriptionShort:
      `
        Прикладной прямой удар в капоэйра./
        Основным отличием от удара Бенсао здесь выступает вкрут бедра, придающий ноге большую силу и скорость./
      `,
      Tier: 2,       
      Points: 3
    },
    {
      key: 'cortacapim',
      title: ['Кортэ капимэ', 'Corta capim'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Движение, в котором капоэйрист, находясь в положении негачива, делает один или несколько оборотов ногой в горизонтальной плоскости./
       В более простом варианте вращение идёт в сторону опорной ноги, в более сложном - против.
      `,
      Tier: 1,       
      Points: 4
    },
    {
      key: 'jumpdupla',
      title: ['Дупла', 'Dupla'],
      type: 'movement',
      subtypes: ['jump'],
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Пржковый элемент: капоэйрист в воздухе делает оборот двумя сведенными ногами. Тело может быть
       как откинуто назад, так и находиться в вертикальном положении.
      `,
      Tier: 3,       
      Points: 12
    },
    {
      key: 'fintcomp',
      title: ['Финт компассы', 'Finta de compasso'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Финт - т.е. не выполнение непосредственной техники (обычно, ударной), а только заход на неё./
       Финты применяются с целью запутить противника, или же занять более выгодное положение и перейти на другой элемент./
       В финте компассы капоэрист выставляет вперёд ногу, скручивает корпус и опирается на руки, показывая первую фазу удара компасса.
      `,
      DiscriptionShort: 
      `
        Финт - т.е. не выполнение непосредственной техники (обычно, ударной), а только заход на неё./
        В финте компассы капоэрист выставляет вперёд ногу, скручивает корпус и опирается на руки, показывая первую фазу удара компасса.
      `,
      Tier: 1,       
      Points: 2
    },
    {
      key: 'flyak',
      title: ['Фляк', 'Flic flac'],
      type: 'movement',      
      subtypes: ['jump', 'flow'],
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Классический акробатический элемент: прыжок на руки себе за спину с перебросом ног./
       Выполняется как со сведенными ногами, так и в разножку./
       В капоэйра может комбинироваться с множеством элементов как в фазе приземления, так и непосрдественно
       во время прыжка.
      `,
      Tier: 2,       
      Points: 7
    },
    {
      key: 'strekosat',
      title: ['Стрекосат', 'Folha seca'],
      type: 'movement',      
      subtypes: ['jump'],
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Прыжок в разножку с сильным вымахом руками.
      `,
      Tier: 2,       
      Points: 10,
      Tutorials: [
        {
          title: 'Rustam Gamaev',
          yt_tail: 'w-AtFLFm_jw'
        }
      ]  
    },
    {
      key: 'gancho',
      title: ['Ганша', 'Gancho'],
      type: 'strike',      
      subtypes: ['strick'],
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Капоэйрист выносит колено вверх, как на удары мартело или щапа, но удар производит пяткой с выхлестом ноги./
       Таким образом данный удар сбивает с толку противника, т.к. наносится с неожиданного направления./
       Вся сложность прямых ударов заключается в том, что их используют на короткой дистанции, а сам факт их применения обычно
       показывает агрессивный настрой соперников, что может легко перерости из игры в полноконтактный бой, где соперник может применить
       как ударную технику рук, так и перевод в партер, что особенно эффективно при ближней дистанции./
       Именно поэтому такие удары требуют не только особой точечной отработки, но и выверенного положения рук, корпуса, контроля атакующей ноги
       не только до нанесения удара, но и после. В том числе отработки ситуации, когда проотивник захватил ногу./
       В целом не сложно выносить колено вверх и бить пяткой, но чтобы сделать ганшу по-настоящему сильным и, что важнее, надёжным оружием в арсенале капоэйриста,
       потребуются дни, месяцы и годы отработки. Именно поэтому удар ганша имеет столь высокую оценку.
      `,
      DiscriptionShort:
      `
        Прикладной обманный удар в капоэйра./
        Капоэйрист выносит колено вверх, как на удары мартело или щапа, но удар производит пяткой с выхлестом ноги.
      `,
      Tier: 3,       
      Points: 5
    },
    {
      key: 'handstand',
      title: ['Стойка', 'Bananeira'],
      type: 'movement',      
      subtypes: ['freeze'],
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Базовый фриз в капоэйра - стойка на руках./ 
       Имеет множество вариаций, разница которых в основном кроется в положении ног капоэйриста./
       Они могут быть закинуты за тело, согнуты в коленях, разведены в стороны и так далее.
      `,
      Tier: 2,       
      Points: 6
    },
    {
      key: 'handstback',
      title: ['Бэк'],
      type: 'movement',      
      subtypes: ['freeze', 'flow'],
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Один из сложнейших и невероятно эффектных фризов - стойка с прогибом (или бэк)./
       Требует хорошей растяжки и координации капоэйриста./
      `,
      Tier: 3,       
      Points: 10
    },
    {
      key: 'headstand',
      title: ['Стойка на голове', 'Parada de cabeca'],
      type: 'movement',      
      subtypes: ['freeze'],
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Стойка на голове./
       Ноги капоэйриста могут находиться в разных положениях: согнуты, выпрямлены, разведены в стороны./
       Один из наиболее эффектных и сложных вариантов - это стойка на голове с прогнутой спиной, когда ноги
       капоэйрист фактически могут касаться земли.
      `,
      Tier: 1,       
      Points: 3
    },
    {
      key: 'headstback',
      title: ['Прогиб', 'Prancha na cabeca'],
      type: 'movement',      
      subtypes: ['freeze', 'flow'],
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Стойка на голове с прогнутой спиной./
      `,
      Tier: 2,       
      Points: 7
    },
    {
      key: 'helicoptero',
      title: ['Геликоптер', 'Au helicoptero'],
      type: 'movement',      
      subtypes: ['perekidka', 'flow'],
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Один из самых сложных перекидочных элементов в капоэйра./
       Его суть заключается в том, что при выполнения колеса капоэйрист доводит идущую первой ногу до земли, но не опирается на неё, а совершает круговое
       движение и в итоге, набрав инерцию, приходит на другую ногу./
       Особую сложность данному элементу придаёт то, что его и без того трудно освоить, но самая главная проблема - выйти с него на одну ногу./
       Поэтому если у вас пока что получается сделать характерное круговое движение и даже прийти на две ноги, не спешите радоваться - 
       до финального корректного исполнения элемента еще много работы.
      `,
      Tier: 3,       
      Points: 13,
      Tutorials: [
        {
          title: 'AwesomeCapoeiraTutorials',
          yt_tail: '4Cf65YMDhOk'
        }
      ]  
    },
    {
      key: 'kick540',
      title: ['540', 'Kick 540'],
      type: 'strike',      
      subtypes: ['round', 'jump'],
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Данный элемент находится на границе ударной техники и акробатики, т.к. имеет очень слабое
       прикладное значение, тем не менее он очень эффектен./
       Капоэйрист выполняет парафусо и приземляется на ударную ногу.
      `,
      DiscriptionShort:
      `
      Данный элемент находится на границе ударной техники и акробатики, т.к. имеет очень слабое
      прикладное значение, тем не менее он очень эффектен./
      Капоэйрист выполняет парафусо и приземляется на ударную ногу.
      `,
      Tier: 3,       
      Points: 8
    },
    {
      key: 'ponte',
      title: ['Мостик', 'Ponte'],
      subtypes: ['flow'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,      
      Discription: 
      `
       Мостик в капоэйра не используется как фриз, а используется в основном как переход между положениями./
       В основном применяются более быстрые и эффектные переходы через мостик: с пробросом рук или переходом через локоть.
      `,
      DiscriptionShort:
      `
       Мостик в капоэйра не используется как фриз, а используется в основном как переход между положениями.
      `,
      Tier: 1,       
      Points: 3,
      Tutorials: [
        {
          title: 'AwesomeCapoeiraTutorials',
          yt_tail: 'LHMWYLjbaUY'
        },
        {
          title: 'Encyclopedia of Capoeira',
          yt_tail: '_U8PzbnpaW8'
        }
      ]  
    },
    {
      key: 'ponwh',
      title: ['Мостик c пробросом'],
      subtypes: ['flow'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Мостик в капоэйра не используется как фриз, а используется в основном как переход между положениями./
       Вариант мостика с пробросом рук.
      `,
      DiscriptionShort:
      `
      Вариант мостика с пробросом рук.
      `,
      Tier: 1,       
      Points: 4
    },
    {
      key: 'ponlokot',
      title: ['Мостик через локоть', 'Volta por cima'],
      subtypes: ['flow'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Мостик в капоэйра не используется как фриз, а используется в основном как переход между положениями./
       Вариант мостика с опорой на локоть.
      `,
      DiscriptionShort:
      `
      Вариант мостика с опорой на локоть.
      `,
      Tier: 2,       
      Points: 7
    },
    {
      key: 'perekid',
      title: ['Перекидка', 'Ponte alta'],
      type: 'movement',
      subtypes: ['perekidka', 'flow'],
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Перекидка из мостика через руки.
      `,
      Tier: 2,       
      Points: 7
    },
    {
      key: 'augirotoria',
      title: ['Жиротория', 'Au giratoria'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Элемент, похожий на свечку - прокрутку в стойке на одной руке, одна в данном элементе капоэйрист
       не делает непосредственное вращение на руке, и, по сути, меняет руки местами и прокручивает только ноги.
      `,
      Tier: 1,       
      Points: 5
    },
    {
      key: 'fullgirotoria',
      title: ['Свечка', 'Piao'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Свеча. Прокрутка, выполненная в стойке на одной руке. Засчитывается вариант, когда тело делает
       как минимум один полный оборот./
       Капоэйристы высокого уровня могут совершать более двух-трёх оборотов.
       `,
      Tier: 3,       
      Points: 12
    },
    {
      key: 'chflac',
      title: ['Китайский фляк'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Данная карточка не считается непосредственно элементом, это скорее окончание другого элемента./
       Капоэйрист опускается со стойки на руках на лопатки, после чего делает сход в нижнее перемещение,
       или выталкивает себя обратно в стойку на руках.
       `,
      Tier: 2,       
      Points: 7
    },
    {
      key: 'beduino',
      title: ['Бедуинское колесо', 'Au beduino'],
      type: 'movement',
      subtypes: ['jump'],
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Бедуинское колесо. Более простой вариант ау сем мао - в бедуинском колесе тело и голова капоэйриста
       не находятся в вертикальном положении.
       `,
      Tier: 1,       
      Points: 5
    },
    {
      key: 'complokot',
      title: ['Компасса через локоть', 'Meia lua cortada'],
      type: 'movement',
      isLogo: true, 
      isVideo: true,
      Discription: 
      `
       Компасса, выполненная через локоть. Данный элемент скорее относится к перемещениям, чем к ударам, хотя,
       теоретически, ведущей ногой можно попасть в противника.
       `,
       DiscriptionShort:
       `
       Компасса, выполненная через локоть. Данный элемент скорее относится к перемещениям, чем к ударам, хотя,
       теоретически, ведущей ногой можно попасть в противника.
       `,
      Tier: 2,       
      Points: 6
    },
    {
      key: 'martnochao',
      title: ['Мертело ди чао', 'Martelo no chao'],
      type: 'strike',   
      subtypes: ['round'], 
      isLogo: true, 
      isVideo: true,      
      Discription: 
      `
       Более сложный вариант относительно обычной мартелы. Капойрист ставит одну руку на пол для опоры и производит круговой удар./
       Может выполняться как из базовой стойки, так и из различных низких положений, с отрывом ведущей ноги или без него.
      `,
      DiscriptionShort:
      `
      Более сложный вариант относительно обычной мартелы. Капойрист ставит одну руку на пол для опоры и производит круговой удар./
      Может выполняться как из базовой стойки, так и из различных низких положений, с отрывом ведущей ноги или без него.
      `,
      Tier: 2,       
      Points: 5,
      Tutorials: [
        {
          title: 'Encyclopedia of Capoeira',
          yt_tail: 'mDnbV-KTjww'
        }
      ]  
    },
    {
      key: 'canivete',
      title: ['Чемоданчик', 'Canivete'],
      type: 'movement',   
      subtypes: ['freeze', 'flow'], 
      isLogo: true, 
      isVideo: true,      
      Discription: 
      `
       Невероятно красивый вериант стойки на руках: капоэйрист прогибает спину и подводит одну или обе ноги максимально близко к подбородку./
       Требует длительной отработки, очень хорошей мобильности плечей и общей координации.
      `,
      Tier: 3,       
      Points: 10,
      Tutorials: [
        {
          title: 'Mestre Koioty',
          yt_tail: 'uPXpRv8THwA'
        }
      ]  
    },


    ]