import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Popover, Icon } from 'antd';

import './component.css';


/*

  Сюда мы посылаем весь элемент, и плюс к этому его ширину относительную (в wv)

*/


export const TrefaCover = ({ sizes, element, client, children }) => {

    let style = {};

    let div_data = {}

    if (sizes.width) {

        if (sizes.dem) {
            style.width = `${sizes.width}${sizes.dem || ''}`
            style.height = `${sizes.width * 1.4}${sizes.dem || ''}`

            style.margin = sizes.nomargin ? 0 : `${sizes.width * 0.07}${sizes.dem || ''}`;
            style.padding = `${sizes.width * 0.07}${sizes.dem || ''}`

            style.fontSize = `${sizes.width / 8}${sizes.dem || ''}`
            style.lineHeight = `${sizes.width / 8}${sizes.dem || ''}`;
        } else {
            style.width = sizes.width;
            style.height = sizes.width * 1.4;

            style.margin = sizes.nomargin ? 0 : sizes.width * 0.07;
            style.padding = sizes.width * 0.07;

            style.fontSize = `${sizes.width / 7}px`;
            style.lineHeight = `${sizes.width / 6.5}px`;
        }
    }

    if (sizes.style) {
        style = { ...style, ...sizes.style }
    }

    if (sizes.reff) {
        div_data.ref = sizes.reff;
    }

    return <div className={'element-card card-trefa-cover '} style={style} {...div_data} >
        <img src={client.getTechnicNeededLogo('covers/trefa/bg')} />
        {children}
    </div>
}

export const TrefaContent = (props) => {

    const { client, sizes, element_key } = props;

    const element = client.getServerElementsAsObject()[element_key];

    const {
        key,
        title,
        Tier,
        Points,
    } = element;


    let minilogo_src = '';

    let title_style = {
        fontSize: '120%',
        color: 'white'
    }

    if (element.type === 'strike') {
        minilogo_src = 'strike';
    }
    if (element.type === 'movement') {
        minilogo_src = 'movement';
    }
    if (element.type === 'defence') {
        minilogo_src = 'defence';
    }
    if (element.Tier === 3) {
        minilogo_src += '_3tier';
        title_style.color = 'yellow'
    }
    minilogo_src += '_logo';

    let w_koef = sizes.width ? sizes.width / 10 : 1;

    if (title.length < 12) {
        //title_style.fontSize = `${ (12 / (title.length + 2)) * w_koef }vw`;
        title_style.fontSize = `${(1600 / (title.length + 2))}%`;
    }

    return <div className='element-card-content card-trefa-content'>

        <div className='card-trefa-content-logo-img'>
            <img src={client.getTechnicLogoUrl(key)} />
        </div>

        <div className='card-trefa-content-tier'>
            {`TIER ${Tier} / P: ${Points}`}
        </div>

        <div className='card-trefa-content-title' style={title_style}>
            {title}
        </div>

        <div className='card-trefa-content-mini-img'>
            <img src={client.getTechnicNeededLogo(minilogo_src)} />
        </div>


    </div>

    /*
            <div className = 'element-card-title' style = {title_style}>
               {title}
           </div>
   
           <div className = 'element-card-bottom_line' style = {bottom_line_style}>
               <div>TIER {Tier || 1}</div>
               <div>{Points || 1}</div>
           </div>
           */


}