import moment from 'moment/min/moment-with-locales';
import { message } from 'antd';
import 'moment/locale/ru';

export const datesRange = (StartDate, EndDate) => {
    const datesArray1 = StartDate.split('.');
    const datesArray2 = EndDate.split('.');

    if (datesArray1[2] !== datesArray2[2]) {
        return `
        ${moment(StartDate, 'DD.MM.YYYY').locale('ru').format('DD MMM YYYY')} 
        - 
        ${moment(EndDate, 'DD.MM.YYYY').locale('ru').format('DD MMM YYYY')} 
        `
    }

    if (datesArray1[1] !== datesArray2[1]) {
        return `
        ${moment(StartDate, 'DD.MM.YYYY').locale('ru').format('DD MMM')} 
        - 
        ${moment(EndDate, 'DD.MM.YYYY').locale('ru').format('DD MMM YYYY')} 
        `
    }

    return `
    ${moment(StartDate, 'DD.MM.YYYY').locale('ru').format('DD')} 
    - 
    ${moment(EndDate, 'DD.MM.YYYY').locale('ru').format('DD MMM YYYY')} 
    `
}

export const monthRange = (StartDate, EndDate) => {
    const datesArray1 = StartDate.split('.');
    const datesArray2 = StartDate.split('.');

    if (datesArray1[1] !== datesArray2[1]) {
        return `
        ${moment(StartDate, 'MM.YYYY').locale('ru').format('MMM YYYY')} 
        - 
        ${moment(EndDate, 'MM.YYYY').locale('ru').format('MMM YYYY')} 
        `
    }

    return `
    ${moment(StartDate, 'MM.YYYY').locale('ru').format('MMM')} 
    - 
    ${moment(EndDate, 'MM.YYYY').locale('ru').format('MMM YYYY')} 
    `
}

export const copyToClipboard = (value) => {
    const copytext = document.getElementById('copyCopyText');
    if (copytext) {
        copytext.value = value;
        copytext.select();
        document.execCommand('copy');
        message.success('Скопировано ' + value);
    }
}