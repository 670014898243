// это прямоугольная горизонтальная штука, в которой слева аватарка, а справа текст (м.б. в две строки)
import React from 'react';
import './style.css';

/**
 * 
 * AvatarComponent - что то, что принмает в себя size
 */
export const AvatarPlate = ({ width, height, text, AvatarComponent, avatarComponentProps }) => {

    const size = height * 0.8;

    return <div style={{ width, height }} className='plateAvatar'>
        <div className='plateAvatar_left' style={{ width: height, height, minWidth: height }}>
            <AvatarComponent size={size} {...avatarComponentProps} />
        </div>
        <div className='plateAvatar_right'>
            <div className='plateAvatar_right_text' style = {{fontSize: height/2.5, lineHeight: `${height/2.5}px`}}>
                {text}
            </div>
        </div>
    </div>
}
