import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import SchoolWrapper from 'src/ver2/components/school/schoolWrapper';
import CordaoRect from 'src/ver2/components/cordao/cordaoRect';
import GroupRect from 'src/ver2/components/group/groupRect';
import SchoolRect from 'src/ver2/components/school/schoolRect'
import SchoolAvatar from 'src/ver2/components/school/avatar';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import { Helmet } from 'react-helmet';

import { Tooltip } from 'antd';

import './style.css';

class SchoolBigPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    get Counts() {
        return this.props.Counts || { all: 0, competitors: 0 }
    }

    render() {

        const { School } = this.props;
        if (!School) return <div className='school_big_plashka'>
            Загрузка...
        </div>

        const {
            Id, Name
        } = School;

        const { width, height } = this.props.upperStyle;

        return <div style={{ width, height }} className='school_big_plashka'>

            <Helmet
                title={`Школа капоэйра ${Name}, портал Capoeira Sport`}
                meta={[
                    { "name": "description", "content": `Страничка школы капоэйра ${Name}` }
                ]}
            />

            <div style={{ width: height, height: height }} className='school_big_plashka___avatar_back'>
                <SchoolAvatar size={height} schoolId={Id} />
            </div>
            <div style={{ width: height, height: height }} className='school_big_plashka___avatar_container'>
                <SchoolAvatar size={height * 0.75} schoolId={Id} />
            </div>
            <div style={{ width: (width - height), height: height }} className='school_big_plashka___info_container'>
                <div className='school_big_plashka___info_container___name'>
                    {Name}
                </div>
                <div className='school_big_plashka___info_container___info'>
                    {/* {`Судья ${Judge} категории`} */}
                </div>
                <div className='school_big_plashka___info_container___school_group_cordao'>
                    <Tooltip placement='top' title='Всего людей в школе'>
                        <span>
                            <NumberText count={this.Counts.all} text={'СОСТАВ'} height={height * 0.2} />
                        </span>
                    </Tooltip>
                    <Tooltip placement='top' title='Хотя бы раз участвовали в соревнованиях'>
                        <span>
                            <NumberText count={this.Counts.competitors} text={'КОМПЕТИТОРЫ'} height={height * 0.2} />
                        </span>
                    </Tooltip>
                </div>
            </div>
        </div>
    }

}

const SchoolBigPlashkaWrapped = (props) => <SchoolWrapper Wrapped={SchoolBigPlashka} schoolId={props.schoolId} componentProps={{ ...props }} />
export default withCapoeiraSportState('iamuser')(SchoolBigPlashkaWrapped);