import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Popover, Icon } from 'antd';

import './component.css';


/*

  Сюда мы посылаем весь элемент, и плюс к этому его ширину относительную (в wv)

*/


export const MarkinCover = ({sizes, element, client, children}) => {

    let style = {};

    let div_data = {}

    if (sizes.width) {

        if (style.dem) {
            style.width = `${sizes.width}${style.dem || ''}`
            style.height = `${sizes.width * 1.4}${style.dem || ''}`

            style.margin = sizes.nomargin ? 0 : `${sizes.width * 0.07}${style.dem || ''}`;
            style.padding = `${sizes.width * 0.07}${style.dem || ''}`

            style.fontSize = `${sizes.width / 8}${style.dem || ''}`
            style.lineHeight = `${sizes.width / 8}${style.dem || ''}`;
        }
        style.width = sizes.width;
        style.height = sizes.width * 1.4;

        style.margin = sizes.nomargin ? 0 : sizes.width * 0.07;
        style.padding = sizes.width * 0.07;

        style.fontSize = `${sizes.width / 7}px`;
        style.lineHeight = `${sizes.width / 6.5}px`;
    }

    if (sizes.style) {
        style = { ...style, ...sizes.style }
    }

    if (sizes.reff) {
        div_data.ref = sizes.reff;
    }


    return <div className = {'element-card card-markin-cover '} style = {style} {...div_data} > 
      <img src = {client.getTechnicNeededLogo('covers/markin/bg')}/>
      {children}
    </div> 
}

export const MarkinContent = (props) => {

    const { client, sizes, element_key } = props;

    const element = client.getServerElementsAsObject()[element_key];

    const {
        key,
        title,
        Tier,
        Points,
    } = element;


    let minilogo_src = '';

    let title_style = {
        fontSize: '120%',
        color: 'white'
    }

    if (element.type === 'strike') {
        minilogo_src = 'strike';
    }
    if (element.type === 'movement') {
        minilogo_src = 'movement';
    }
    if (element.type === 'defence') {
        minilogo_src = 'defence';
    }
    if (element.Tier === 3) {
        minilogo_src += '_3tier';
        title_style.color = 'yellow'
    } 
    minilogo_src += '_logo';

    if (title.length < 12) {
        //title_style.fontSize = `${ (12 / (title.length + 2)) * w_koef }vw`;
        title_style.fontSize = `${ (1600 / (title.length + 2)) }%`;
    }

    return <div className = 'element-card-content card-markin-content'>

        <div className = 'card-markin-content-logo-img'>
             <img src = { client.getTechnicLogoUrl(key) }/>
        </div>

        <div className = 'card-markin-content-tier'>
             <div>{`T: ${Tier}`}</div>
             {`P: ${Points}`}
        </div>

        <div className = 'card-markin-content-title' style = {title_style}>
            {title}
        </div>
        
    </div>

 /*
         <div className = 'element-card-title' style = {title_style}>
            {title}
        </div>

        <div className = 'element-card-bottom_line' style = {bottom_line_style}>
            <div>TIER {Tier || 1}</div>
            <div>{markin || 1}</div>
        </div>
        */


}