import React, { PureComponent } from 'react';
import { withPersonState } from 'src/ver2/pages/person/context';
import { Statistic, Progress } from 'antd';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import Loader from 'src/ver2/components/loader';
import FightsModal from '../../containers/fightsModal';
import { Modal } from 'antd';

import { orderBy } from 'lodash';

import './style.css';

class PersonFightsPlashka extends PureComponent {

    state = {
        listModalData: null,
        lastHeight: -1,
    }

    listModalDataChange = (listModalData) => this.setState({ listModalData })

    render() {

        const { isMobile, FightsStatistics } = this.props;
        const { width, height } = this.props.upperStyle;

        if (!FightsStatistics) {
            return <div style={{ width, height }} className='person_fights_plashka'>
                <Loader text='Загрузка элементов' />
            </div>
        }

        const { MyFights, MyWinLoseStat, MyEnemys } = FightsStatistics;
        const { listModalData } = this.state;
        const { listModalDataChange } = this;

        if (!MyFights || !MyFights.length) {
            return <div style={{ width, height }} className='person_fights_plashka'>
                <span>НЕ БЫЛО ВЫСТУПЛЕНИЙ</span>
            </div>
        }

        const { AllCount, WinCount, LoseCount } = MyWinLoseStat[0] || {};

        const blockHeight = isMobile ? 30 : 40;


        return <div>
            <div onClick={() => { listModalDataChange(true) }} style={{ width, height }} className='person_fights_plashka'>
                <NumberText count={AllCount} text={'ВСЕГО БОЁВ'} height={blockHeight} />
                <NumberText count={WinCount} text={'ПОБЕДЫ'} height={blockHeight} />
                <NumberText count={LoseCount} text={'ПОРАЖЕНИЯ'} height={blockHeight} />
                <Progress width={blockHeight} type="circle" percent={(WinCount / AllCount * 100).toFixed()} format={() => `${(WinCount / AllCount * 100).toFixed()}%`} />
            </div>
            {listModalData ?
                <Modal visible={listModalData} onCancel={() => listModalDataChange(false)} footer={null}>
                    <FightsModal />
                </Modal>
                : null}
        </div>
    }
}

export default withPersonState(
    `
    FightsStatistics,
    personId,
    client,
    isMobile
    `
)(PersonFightsPlashka)