import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';
import NewsLittleListPlashka from 'src/ver2/plashki/news/littleList';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class SchoolNewNewsPlashka
// classname: school_new_news_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class SchoolNewNewsPlashka extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            NewNews: null,
            isNewNewsReady: false
        };
    }

    // NEWNEWS //////////////////////////////////////////
    loadNewNews = async () => {
        const { schoolId, client } = this.props;
        if (!schoolId) return;

        const NewNews = await client.getNewNews({ subject: 'school', subjectId: schoolId });
        return NewNews;
    }

    reloadNewNews = () => {
        this.setState({ isNewNewsReady: false });
        this.loadNewNews().then(
            (NewNews) => {
                this.setState({ isNewNewsReady: true, NewNews });
            }
        )
    }

    componentDidMount() {
        this.reloadNewNews();
    }

    render() {

        const { upperStyle } = this.props;
        const { NewNews, isNewNewsReady } = this.state;

        const { width, height } = upperStyle;

        if (!isNewNewsReady) return <div style={{ width, height }} className='school_new_news_plashka'>
            <Loader />
        </div>

        if (!NewNews || !NewNews.length) {
            return <div style={{ width, height }} className='school_new_news_plashka'>
                <div className='school_new_news_plashka_nonews'>
                    <span>
                        ПОКА НЕТ <br />ОПУБЛИКОВАННЫХ <br /> НОВОСТЕЙ
                    </span>
                </div>
            </div>
        }

        return <div style={{ width, height }} className='school_new_news_plashka'>
            <NewsLittleListPlashka upperStyle={{ width, height: height - 20 }} News={NewNews.reverse()} type={'eventNewNews'} />
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client')(SchoolNewNewsPlashka);