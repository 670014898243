import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withCapoeiraSportState } from 'src/ver2/context';
import { Button } from 'antd';

import './style.css';

/**
  Это маленький список кнопочек управления
 */

const LITTLE_THINGS = {
    'donate': {
        title: 'поддержка проекта',
        imgSrc: "/../images/ver2/shark.png",
        linkTo: '/shop/4'
    },
    'island': {
        title: 'online квест остров',
        imgSrc: "/../images/ver2/IslandMain.png",
        linkTo: '/island/1'
    },
    'fkcaccred': {
        title: 'оплата членства',
        imgSrc: "/../images/ver2/fkr_logo.png",
        linkTo: '/fkraccred'
    },
    'raiting': {
        title: 'лидеры регионов',
        imgSrc: "/../images/ver2/raiting.png",
        linkTo: '/seasonresult/russia'
    },
    'pumcs': {
        title: 'HALLOWEEN 2024',
        imgSrc: "/../images/ver2/pump.png",
        linkTo: '/article/22'
    },
    'shirts': {
        title: 'форма SoForca',
        imgSrc: "/../images/ver2/form.png",
        linkTo: '/shop/11'
    }
}

class NewsSomePlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        icons: [
            'donate', 'island', 'fkcaccred', 'raiting'
        ]
    };

    componentDidMount = async () => {
    }

    OneLittleThing = ({ iconKey }) => {
        const { upperStyle } = this.props;
        const { height } = upperStyle;

        // находим 

        const compHeight = height * 0.9;
        const width = compHeight * 0.667;

        const { title, imgSrc, linkTo } = LITTLE_THINGS[iconKey];

        return <Link key={`sspno_${iconKey}`} to={linkTo}>
            <div style={{ width, height: compHeight }} className='slider_some_plashka_one'>
                <div style={{ width, height: width, minHeight: width }} className='slider_some_plashka_one_top'>
                    <img src={imgSrc} />
                </div>
                <div className='slider_some_plashka_one_bottom'>
                    <span>
                        {title}
                    </span>
                </div>
            </div>
        </Link>
    }

    render() {

        const { upperStyle, iamuser } = this.props;

        const { width, height } = upperStyle;
        const { icons } = this.state;

        console.log('OneLittleThing', { iamuser })

        return <div style={{ width, height }} className='slider_some_plashka'>
            {icons.map(icon => <this.OneLittleThing key={icon} iconKey={icon} />)}
            {(iamuser && iamuser.SchoolId === 1) ? <this.OneLittleThing key={'shirts'} iconKey={'shirts'} /> : null}
        </div>
    }

    // render2() {

    //     const { ImgPart, Title, Text, LinkTo, upperStyle, maxWidth = null, special } = this.props;

    //     const { width, height } = upperStyle;

    //     const { whoOnIsland, whoPassed } = this.state;

    //     if (special) {
    //         const { showInfoModal } = this.props;
    //         return <div style={{ width, height }} className='slider_some_plashka'>
    //             <Link to={LinkTo}>
    //                 <div style={{ width: maxWidth || height, minWidth: maxWidth || height, height: maxWidth || height }} className='slider_some_plashka_left'>
    //                     {ImgPart}
    //                 </div>
    //             </Link>
    //             <div className='slider_some_plashka_right'>
    //                 <Link to={LinkTo}>
    //                     <div className='slider_some_plashka_right_title'>
    //                         {Title}
    //                     </div>
    //                 </Link>
    //                 <Link to={LinkTo}>
    //                     <div className='slider_some_plashka_right_text'>
    //                         {Text}
    //                     </div>
    //                 </Link>
    //                 {
    //                     (whoPassed && whoOnIsland) &&
    //                     <div className='slider_some_plashka_right_text'>
    //                         {`ИГРАЮТ: ${whoOnIsland.length} / ПРОШЛИ: ${whoPassed.length}`}
    //                     </div>
    //                 }
    //                 {
    //                     (whoPassed && whoOnIsland) &&
    //                     <div className='slider_some_plashka_right_text' onClick={() => {
    //                         showInfoModal({ type: 'personsList', Persons: whoPassed })
    //                     }}>
    //                         <Button type='primary' >КТО ПРОШЕЛ?</Button>
    //                     </div>
    //                 }
    //             </div>
    //         </div>
    //     }

    //     return <Link to={LinkTo}>
    //         <div style={{ width, height }} className='slider_some_plashka'>
    //             <div style={{ width: maxWidth || height, minWidth: maxWidth || height, height: maxWidth || height }} className='slider_some_plashka_left'>
    //                 {ImgPart}
    //             </div>
    //             <div className='slider_some_plashka_right'>
    //                 <div className='slider_some_plashka_right_title'>
    //                     {Title}
    //                 </div>
    //                 <div className='slider_some_plashka_right_text'>
    //                     {Text}
    //                 </div>
    //             </div>
    //         </div>
    //     </Link>
    // }

}

export default withCapoeiraSportState('client, iamuser, showInfoModal')(NewsSomePlashka);