import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import SchoolWrapper from 'src/ver2/components/school/schoolWrapper';
/*import CordaoRect from 'src/ver2/components/cordao/cordaoRect';
import GroupRect from 'src/ver2/components/group/groupRect';
import SchoolRect from 'src/ver2/components/school/schoolRect'
import PersonAvatar from 'src/ver2/components/person/avatar';
*/
import CordaoSubjectTable from 'src/ver2/plashki/cordao/subjectTable';
import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import { withRouter } from 'react-router-dom';
import { Drawer } from 'antd';

import { TrefaLayout } from 'src/ver2/containers/layout';
import './style.css';

/**
 * 
 *  Тут у нас лэйаут, куда прилетает schoolId или groupId
 *  И выбранный в данный момент кордао
 *  Плюс у него есть выборка для стейта - показывать все корды, или только те, которые есть в данный момент в школе
 * 
 * 
 */

// import './styles.css';

class CordaoSubjectLayout extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            keyWatchAll: '0',
            Persons: null
        }
    }

    changeWatchKey = (keyWatchAll) => this.setState({ keyWatchAll })

    linkTo = (param, value) => {
        let { cord } = this.props;
        let linkObject = { cord };
        linkObject[param] = value;
        return `?${Object.keys(linkObject).reduce((memo, key, index) => {
            if (!linkObject[key]) return memo;
            return memo + `${index > 0 ? '&' : ''}${key}=${linkObject[key]}`
        }, '')}`
    }

    componentDidMount() {

        const { schoolId, cord } = this.props;

        const { client } = this.props;

        client.getCordaoIdsFromServer(schoolId, cord).then((Persons) => {
            this.setState({ Persons });
        });
    }

    // nextProps
    componentWillReceiveProps({ schoolId, cord }) {

        if (schoolId === this.props.schoolId && cord === this.props.cord) {
            return;
        }

        const { client } = this.props;

        client.getCordaoIdsFromServer(schoolId, cord).then((Persons) => {
            this.setState({ Persons });
        });
    }

    redirect = (link) => {
        this.props.history.push(link);
    }

    render() {

        const width = 1200;
        const { School, GradationCount, cord, isMobile } = this.props;
        const { keyWatchAll, Persons } = this.state;
        const { changeWatchKey } = this;
        const schoolId = School ? School.Id : null;

        if (isMobile) {
            const { width, height } = isMobile;
            return <div style={{ width, height: 900 }} className='subject_layout_container'>
                <TrefaLayout greedsX={[width - 20]}
                    components={[{
                        component: CordaoSubjectTable,
                        cell_x: 1,
                        collapse: 1,
                        componentProps: { height: 900, yPadding: 'usual', School, GradationCount, keyWatchAll, changeWatchKey, cord, linkTo: this.linkTo }
                    }]}
                />
                <Drawer
                    class='driver_button_menu_driver'
                    title={<span className='driver_button_menu_driver_title'>Представители</span>}
                    placement="left"
                    onClose={() => {this.redirect('./cordao')}}
                    visible={Persons && Persons.length > 0}>
                    {Persons && Persons.length > 0 ?
                        <TrefaLayout greedsX={[252 - 24]}
                            components={[{
                                component: PersonsLittleListPlashka,
                                cell_x: 1,
                                collapse: 1,
                                componentProps: { height: height - 130, yPadding: 'usual', Persons }
                            }]}
                        />
                        : null}
                </Drawer>
            </div>
        }



        let components = [{
            component: CordaoSubjectTable,
            cell_x: 2,
            collapse: 1,
            componentProps: { height: 1200, yPadding: 'usual', School, GradationCount, keyWatchAll, changeWatchKey, cord, linkTo: this.linkTo }
        }];

        if (Persons && Persons.length > 0) {
            components.push(
                {
                    component: PersonsLittleListPlashka,
                    cell_x: 4,
                    collapse: 1,
                    componentProps: { height: 800, yPadding: 'usual', Persons }
                }
            )
        }

        return <div style={{ width, height: 1200 }} className='subject_layout_container'>
            <TrefaLayout greedsX={[12, 824, 12, 300, 12]} greedsY={1200}
                components={components}
            />
        </div>
    }
}

const CordaoSubjectLayoutWrapped = (props) => <SchoolWrapper Wrapped={CordaoSubjectLayout} schoolId={props.schoolId} witchGradation={true} componentProps={{ ...props }} />
// export default withCapoeiraSportState('iamuser, getText, client, isMobile')(CordaoSubjectLayoutWrapped);
export default withRouter(withCapoeiraSportState('iamuser, getText, client, isMobile')(CordaoSubjectLayoutWrapped))