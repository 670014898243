// это посто сама по себе аввтарка чувака
import React from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { CordaoCord } from './index';

const CordaoAvatar = (props) => {
    const {size, client, SchoolId, Cordao} = props;

    const schoolGradation = client.getSchoolGradation(SchoolId);
    const cordao = schoolGradation[Cordao];

    return <CordaoCord {...{size, cordao}} />
}

export default withCapoeiraSportState('client')(CordaoAvatar);