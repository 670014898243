export default {
    medal_b1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Золотая награда',
        src: `/../images/ver2/scores/stuff/medal_b1.png`,

        place: 1,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_b2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Серебряная награда',
        src: `/../images/ver2/scores/stuff/medal_b2.png`,

        place: 2,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_b3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Бронзовая награда',
        src: `/../images/ver2/scores/stuff/medal_b3.png`,

        place: 3,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_bb1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Золотая награда',
        src: `/../images/ver2/scores/stuff/medal_bb1.png`,

        place: 1,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_bb2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Серебряная награда',
        src: `/../images/ver2/scores/stuff/medal_bb2.png`,

        place: 2,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_bb3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Бронзовая награда',
        src: `/../images/ver2/scores/stuff/medal_bb3.png`,

        place: 3,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_bbb1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Золотая награда',
        src: `/../images/ver2/scores/stuff/medal_bbb1.png`,

        place: 1,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_bbb2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Серебряная награда',
        src: `/../images/ver2/scores/stuff/medal_bbb2.png`,

        place: 2,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_bbb3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Бронзовая награда',
        src: `/../images/ver2/scores/stuff/medal_bbb3.png`,

        place: 3,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_bbbb1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Золотая награда',
        src: `/../images/ver2/scores/stuff/medal_bbbb1.png`,

        place: 1,

        w: 1,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    medal_bbbb2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Серебряная награда',
        src: `/../images/ver2/scores/stuff/medal_bbbb2.png`,

        place: 2,

        w: 1,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    medal_bbbb3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Бронзовая награда',
        src: `/../images/ver2/scores/stuff/medal_bbbb3.png`,

        place: 3,

        w: 1,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    medal_bbbbcr1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Золотая награда за Кубок России 2022',
        title: 'Золотая награда',
        src: `/../images/ver2/scores/stuff/medal_bbbbcr1.png`,

        place: 1,

        w: 1,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    medal_bbbbcr2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Серебряная награда за Кубок России 2022',
        title: 'Серебряная награда',
        src: `/../images/ver2/scores/stuff/medal_bbbbcr2.png`,

        place: 2,

        w: 1,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    medal_bbbbcr3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Бронзовая награда за Кубок России 2022',
        title: 'Бронзовая награда',
        src: `/../images/ver2/scores/stuff/medal_bbbbcr3.png`,

        place: 3,

        w: 1,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },


    medal_cr1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Золотая награда',
        src: `/../images/ver2/scores/stuff/medal_cr1.png`,

        place: 1,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_cr2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Серебряная награда',
        src: `/../images/ver2/scores/stuff/medal_cr2.png`,

        place: 2,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_cr3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Бронзовая награда',
        src: `/../images/ver2/scores/stuff/medal_cr3.png`,

        place: 3,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_l1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Золотая награда',
        src: `/../images/ver2/scores/stuff/medal_l1.png`,

        place: 1,

        w: 1,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_l2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Серебряная награда',
        src: `/../images/ver2/scores/stuff/medal_l2.png`,

        place: 2,

        w: 1,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_l3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Бронзовая награда',
        src: `/../images/ver2/scores/stuff/medal_l3.png`,

        place: 3,

        w: 1,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_ll1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Золотая награда',
        src: `/../images/ver2/scores/stuff/medal_ll1.png`,

        place: 1,

        w: 1,
        h: 1,
        shiftX: 0,
        shiftY: 0
    },

    medal_ll2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Серебряная награда',
        src: `/../images/ver2/scores/stuff/medal_ll2.png`,

        place: 2,

        w: 1,
        h: 1,
        shiftX: 0,
        shiftY: 0
    },

    medal_ll3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Бронзовая награда',
        src: `/../images/ver2/scores/stuff/medal_ll3.png`,

        place: 3,

        w: 1,
        h: 1,
        shiftX: 0,
        shiftY: 0
    },

    medal_r1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Золотая награда',
        src: `/../images/ver2/scores/stuff/medal_r1.png`,

        place: 1,

        w: 1,
        h: 1,
        shiftX: 0,
        shiftY: 0
    },

    medal_r2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Серебряная награда',
        src: `/../images/ver2/scores/stuff/medal_r2.png`,

        place: 2,

        w: 1,
        h: 1,
        shiftX: 0,
        shiftY: 0
    },

    medal_r3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Бронзовая награда',
        src: `/../images/ver2/scores/stuff/medal_r3.png`,

        place: 3,

        w: 1,
        h: 1,
        shiftX: 0,
        shiftY: 0
    },

    medal_rr1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Золотая награда за рейтинг сезона 2024-2025',
        title: 'Золотая награда',
        src: `/../images/ver2/scores/stuff/medal_rr1.png`,

        place: 1,

        w: 1,
        h: 1,
        shiftX: 0,
        shiftY: 0
    },

    medal_rr2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Серебряная награда за рейтинг сезона 2024-2025',
        title: 'Серебряная награда',
        src: `/../images/ver2/scores/stuff/medal_rr2.png`,

        place: 2,

        w: 1,
        h: 1,
        shiftX: 0,
        shiftY: 0
    },

    medal_rr3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Бронзовая награда за рейтинг сезона 2024-2025',
        title: 'Бронзовая награда',
        src: `/../images/ver2/scores/stuff/medal_rr3.png`,

        place: 3,

        w: 1,
        h: 1,
        shiftX: 0,
        shiftY: 0
    },

    medal_an1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Золотая награда',
        src: `/../images/ver2/scores/stuff/medal_an1.png`,

        place: 1,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_an2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Серебряная награда',
        src: `/../images/ver2/scores/stuff/medal_an2.png`,

        place: 2,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    medal_an3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: '',
        title: 'Бронзовая награда',
        src: `/../images/ver2/scores/stuff/medal_an3.png`,

        place: 3,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1
    },

    kubok_2019_b1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Данный кубок даётся за 1 место, занятое во всероссийском рейтинге сезона 2019-2021 ',
        title: 'Золотой кубок рейтинга 2019',
        src: `/../images/ver2/scores/stuff/kubok_2019_b1.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    kubok_2019_b2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Данный кубок даётся за 2 место, занятое во всероссийском рейтинге сезона 2019-2021 ',
        title: 'Серебряный кубок рейтинга 2019',
        src: `/../images/ver2/scores/stuff/kubok_2019_b2.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    kubok_2019_b3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Данный кубок даётся за 3 место, занятое во всероссийском рейтинге сезона 2019-2021 ',
        title: 'Бронзовый кубок рейтинга 2019',
        src: `/../images/ver2/scores/stuff/kubok_2019_b3.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    kubok_2021_b1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Данный кубок даётся за 1 место, занятое во всероссийском рейтинге сезона 2021-2022 ',
        title: 'Золотой кубок рейтинга 2021',
        src: `/../images/ver2/scores/stuff/kubok_2021_b1.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    kubok_2021_b2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Данный кубок даётся за 2 место, занятое во всероссийском рейтинге сезона 2021-2022 ',
        title: 'Серебряный кубок рейтинга 2021',
        src: `/../images/ver2/scores/stuff/kubok_2021_b2.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    kubok_2021_b3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Данный кубок даётся за 3 место, занятое во всероссийском рейтинге сезона 2021-2022 ',
        title: 'Бронзовый кубок рейтинга 2021',
        src: `/../images/ver2/scores/stuff/kubok_2021_b3.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    kubok_2022_b1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Данный кубок даётся за 1 место, занятое во всероссийском рейтинге сезона 2022-2023 ',
        title: 'Золотой кубок рейтинга 2022',
        src: `/../images/ver2/scores/stuff/kubok_2022_b1.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    kubok_2022_b2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Данный кубок даётся за 2 место, занятое во всероссийском рейтинге сезона 2022-2023 ',
        title: 'Серебряный кубок рейтинга 2022',
        src: `/../images/ver2/scores/stuff/kubok_2022_b2.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    kubok_2022_b3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Данный кубок даётся за 3 место, занятое во всероссийском рейтинге сезона 2022-2023 ',
        title: 'Бронзовый кубок рейтинга 2022',
        src: `/../images/ver2/scores/stuff/kubok_2022_b3.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    kubok_2023_b1: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Данный кубок даётся за 1 место, занятое во всероссийском рейтинге сезона 2023-2024 ',
        title: 'Золотой кубок рейтинга 2023',
        src: `/../images/ver2/scores/stuff/kubok_2023_b1.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    kubok_2023_b2: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Данный кубок даётся за 2 место, занятое во всероссийском рейтинге сезона 2023-2024 ',
        title: 'Серебряный кубок рейтинга 2023',
        src: `/../images/ver2/scores/stuff/kubok_2023_b2.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 2
    },

    kubok_2023_b3: {
        type: 'nagrada',
        subtype: 'medal_shalve',
        grade: 5,
        discription: 'Данный кубок даётся за 3 место, занятое во всероссийском рейтинге сезона 2023-2024 ',
        title: 'Бронзовый кубок рейтинга 2023',
        src: `/../images/ver2/scores/stuff/kubok_2023_b3.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 2
    }

}