import React, { Fragment, PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { LinkButtonLight } from 'src/ver2/components/ui/buttons/link';

import './style.css';

const WORTHS = {
    base: 'Базовый',
    good: 'Продвинутый',
    rare: 'Редкий',
    legend: 'Легендарный',
    epic: 'Эпичный',
}

class AchivmentTooltip extends PureComponent {

    render() {
        const { client, achivment, noProgress } = this.props;
        const { Lvl, Name, Progress } = achivment;

        const { ACHIVMENTS } = client;
        if (!ACHIVMENTS || !ACHIVMENTS[Name]) return <div className='achivment_tooltip_container'>
            <span>НЕ УДАЛОСЬ НАЙТИ</span>
        </div>
        const achiv = ACHIVMENTS[Name];

        const { discription, lvls, label, type } = achiv;
        const ourLvl = lvls[Lvl - 1];
        const { worth, cost } = ourLvl;
        // discription: 'Выдаётся за коллекционирование музыкальных котиков',
        // lvls: [
        //     {
        //         lvl: 1, worth: 'base', cost: 3,
        //         conditions: [
        //             { looked: 'items', items_in: ['toy_catb_2', 'toy_catp_2'], count: 2, compare: 'count' }
        //         ]
        //     },
        return <div className='achivment_tooltip_container'>
            <img src={`/../images/ver2/achivs/${Name}/${Lvl}.png`} />
            <h2>{label}</h2>
            <div className='achivment_tooltip_container_text'>
                {discription}
            </div>
            <div className='achivment_tooltip_container_param'>
                {`УРОВЕНЬ: ${WORTHS[worth]}`}
            </div>
            <div className='achivment_tooltip_container_param'>
                {`ОЧКИ ПОРТАЛА: ${cost}`}
            </div>
            {!noProgress && <div className='achivment_tooltip_container_param'>
                {`ПРОГРЕСС: ${Progress}%`}
            </div>}
            {!noProgress && <LinkButtonLight width = {210} linkTo = {`/achivments?achivType=${type}&achivName=${Name}`} text = 'Подробнее'/>}
        </div>
    }
}

export default withCapoeiraSportState(
    `
    client,
    isMobile
    `
)(AchivmentTooltip)