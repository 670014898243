export default {
    // ОБОИ
    oboi_cream_1: {
        
        grade: 1, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_cream_1.png`,
        title: 'Кремовые обои',
        discription: 'Придают небольшой кремовой оттенок скучной серой стене',
        wallStyle: {
            background: `linear-gradient( #f5f5f5, #c7b9a9 )`
        } 
    },

    oboi_orange_1: {
        
        grade: 1, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_orange_1.png`,
        title: 'Светло-бурые обои',
        discription: 'Отличный вариант для начала, подчеркивающий ваши достижения',
        wallStyle: {
            background: `linear-gradient( #fad481, #774000 )`
        } 
    },

    oboi_blue_2: {
        
        grade: 2, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_blue_2.png`,
        title: 'Тёмно-синие обои',
        discription: 'Вы посмотрите на этот насыщенный цвет! Если Вам нравится по-темнее',
        wallStyle: {
            background: `linear-gradient( #1d3f57, #0b1620 )`
        } 
    },

    oboi_lightblue_2: {
        
        grade: 2, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_lightblue_2.png`,
        title: 'Обои Городское небо',
        discription: 'И пусть в окно Вы регулярно можете наблюдать этот цвет, но он так хорош на вашей стене!',
        wallStyle: {
            background: `linear-gradient( #5E7E7A, #9AB6B9 )`
        } 
    },

    oboi_roz_2: {
        
        grade: 2, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_roz_2.png`,
        title: 'Обои Дыня Крайола',
        discription: 'И пусть это не совсем цвет Дыни Крайола, но, надеемся, что она очень вкусная!',
        wallStyle: {
            background: `linear-gradient( #796566, #9C8688 )`
        } 
    },

    oboi_gorc_2: {
        
        grade: 2, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_gorc_2.png`,
        title: 'Обои светлая горчица',
        discription: 'Идеально подойдут с хот-догом и картофелем фри',
        wallStyle: {
            background: `linear-gradient( #BE9E39, #E0C76D )`
        } 
    },

    oboi_orange_3: {
        
        grade: 3, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_orange_3.png`,
        title: 'Обои красный апельсин',
        discription: 'Сочатся цветом! Ты захочешь такие обои себе в гостинную!',
        wallStyle: {
            background: `radial-gradient( #E46520, #B12B05 )`
        } 
    },

    oboi_green_3: {
        
        grade: 3, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_green_3.png`,
        title: 'Обои летняя трава',
        discription: 'Если Вам не хватает свежих тонов в жизни, эти обои идеально подойдут',
        wallStyle: {
            background: `radial-gradient( #AEAF16, #547200 )`
        } 
    },

    oboi_blue_3: {
        
        grade: 3, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_blue_3.png`,
        title: 'Обои залив Гуанабара',
        discription: 'Кричащие краски океанских волн второго по величине залива Бразилии',
        wallStyle: {
            background: `radial-gradient( #016777, #00909C )`
        } 
    },

    // oboi_blue_3: {
        
    //     grade: 3, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_blue_3.png`,
    //     title: 'Обои залив Гуанабара',
    //     discription: 'Кричащие краски океанских волн второго по величине залива Бразилии',
    //     wallStyle: {
    //         background: `radial-gradient( #AEAF16, #547200 )`
    //     } 
    // },

    oboi_purpblue_3: {
        
        grade: 3, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_purpblue_3.png`,
        title: 'Обои кринжовая фиалка',
        discription: 'Возможно Вам надоели классические сочетания цветов и хочется чего-то необычного',
        wallStyle: {
            background: `radial-gradient( #016777, #4A0D28 )`
        } 
    },

    oboi_roz_3: {
        
        grade: 3, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_roz_3.png`,
        title: 'Обои Фалунский красный',
        discription: 'Цвет, который невозможно описать, не оставит равнодушным гостей вашей стены',
        wallStyle: {
            background: `radial-gradient( #AD4C45, #5B1D20 )`
        } 
    },

    oboi_purple_4: {
        
        grade: 4, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_purple_4.png`,
        title: 'Обои Спелая черешня',
        discription: 'Невероятный глубокий цвет, смотрит в душу и просится к Вам на стену',
        wallStyle: {
            background: `radial-gradient( #AD4C45, #5B1D20 )`
        } 
    },

    oboi_brickw_4: {
        
        grade: 4, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_brickw_4.png`,
        title: 'Обои светлый кирпич',
        discription: 'Легкая кирпичная кладка, на фоне который так воздушно смотрятся ваши награды',
        wallStyle: {
            background: `url("/images/ver2/scores/wall/white_brick.jpg")`
        } 
    },

    oboi_stars_4: {
        
        grade: 4, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_stars_4.png`,
        title: 'Обои звездная ночь',
        discription: 'Звездопад в этом году не в августа, а на вашей стене',
        wallStyle: {
            background: `url("/images/ver2/scores/wall/stars.jpg")`
        } 
    },

    oboi_woodw_4: {
        
        grade: 4, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_woodw_4.png`,
        title: 'Обои мечта дачника',
        discription: 'Классика. Строгое белое дерево, прекрасно сочетаются с котикам-капоэйристами!',
        wallStyle: {
            background: `url("/images/ver2/scores/wall/white_wood.jpg")`
        } 
    },

    oboi_red_4: {
        
        grade: 4, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_red_4.png`,
        title: 'Обои фильм ужасов',
        discription: 'Яркие, кричащие обои. Возможно, именно их Вам не хватает в жизни?',
        wallStyle: {
            background: `url("/images/ver2/scores/wall/red_brick.jpg")`
        } 
    },

    oboi_orange_4: {
        
        grade: 4, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_orange_4.png`,
        title: 'Обои уютная гостинная',
        discription: 'Изысканные обои для ценителей роскоши и комфорта. И комфортной роскоши.',
        wallStyle: {
            background: `url("/images/ver2/scores/wall/wall_oboi_orange.jpg")`
        } 
    },

    oboi_rainbow_5: {
        
        grade: 5, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_rainbow_5.png`,
        title: 'Обои внезапный краш',
        discription: 'Если именно этими обоями Вы хотите подчеркнуть свою оригинальность, мы не можем сказать ничего против',
        wallStyle: {
            background: `url("/images/ver2/scores/wall/rainbow_brick.jpg")`
        } 
    },

    oboi_wood_5: {
        
        grade: 5, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_wood_5.png`,
        title: 'Обои тоска по лету',
        discription: 'Великолепные светлые обои, напоминающие о тепле летних дней',
        wallStyle: {
            background: `url("/images/ver2/scores/wall/yellow_wood.jpg")`
        } 
    },

    oboi_blue_5: {
        
        grade: 5, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_blue_5.png`,
        title: 'Обои безмятежность',
        discription: 'Легие, воздушные, умиротворяющие обои. Закажите такие себе в спальню.',
        wallStyle: {
            background: `url("/images/ver2/scores/wall/light_blue.jpg")`
        } 
    },

    oboi_woodd_5: {
        
        grade: 5, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_woodd_5.png`,
        title: 'Обои изысканное дерево',
        discription: 'Невероятно стройные и объемные. Такие обои можно приобрести только за донатный ресурс',
        wallStyle: {
            background: `url("/images/ver2/scores/wall/wall_wood.jpg")`
        } 
    },

    oboi_blued_5: {
        
        grade: 5, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_blued_5.png`,
        title: 'Обои спокойный сон',
        discription: 'Глубокие и насыщенные. Идеально подчеркнут все Ваши достижения на стене. Такие обои можно приобрести только за донатный ресурс',
        wallStyle: {
            background: `url("/images/ver2/scores/wall/wall_blue.jpg")`
        } 
    },

    oboi_kmo_5: {
        
        grade: 5, type: 'paint', subtype: 'oboi', src: `/../images/ver2/scores/paint/oboi_kmo_5.png`,
        title: 'Обои Кубка Москвы',
        discription: 'Эксклюзивные обои, выпущенные в честь Кубка Москвы 2022',
        wallStyle: {
            background: `url("/images/ver2/scores/wall/kmo.jpg")`
        } 
    },
}