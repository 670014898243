
import React from 'react';
import './style.css';

export const UpperStatus = ({ client, eventStatus, noTooltip, className, iAmAdmin, changeShowPublishedModalModal, changeShowRegistrationModalModal }) => {

    let addingClassName = '';

    // const { client, eventStatus, iAmAdmin } = this.props;
    // if (Event.NotAnnounced) {
    //     status = 'не опубликовано';
    //     addingClassName = 'event_big_plashka__status_notAnnounsed';
    // }

    const status_ = eventStatus() || 'undef';
    const status = client.eventStatuses[status_];
    addingClassName = `event_big_plashka__status_${status_}`;

    let onClick = null;
    if (status_ === 'not_published') {
        if (iAmAdmin) {
            onClick = changeShowPublishedModalModal;
            addingClassName += ' event_big_plashka__status_cliced';
        }
    }

    if (status_ === 'registration_not_open' || status_ === 'registration_open' || status_ === 'registration_closed' || status_ === 'past') {
        if (iAmAdmin) {
            onClick = changeShowRegistrationModalModal;
            addingClassName += ' event_big_plashka__status_cliced';
        }
    }

    return <div className={className + ' ' + addingClassName} >
        <div onClick={onClick} data-tooltip={status.text} className= { noTooltip ? 'event_big_plashka__status_text' : 'global-tooltip event_big_plashka__status_text'}>
            {status.title}
        </div>
    </div>
}