import * as THREE from 'three';

export const createBoxMedal = ( textureMaterial, uniforms ) => {

    /*Хуйня */

  const parent_Mesh =  new THREE.Mesh(new THREE.BoxGeometry(0, 0, 0), new THREE.MeshBasicMaterial({
      color: "#FFFFFF00",
      wireframe: true
  }));

  // const geometry = new THREE.CylinderBufferGeometry(
  //   2.5, 2.5, 0.2, 55);  

  // const material = new THREE.ShaderMaterial (
  // {
  //     uniforms: {
  //     u_texture: {value: textureMaterial},
  //     u_gold: {value: textureMaterial},
  //     Time: {value: 0},
  //     goldy : {value: 0}
  //     },
  //     fragmentShader: document.getElementById("FragmentShader_shine").innerHTML, 
  //     vertexShader: document.getElementById("spaceTestVertexShader").innerHTML
  // }
  // )

  // material.transparent = true;

  // const mesh = new THREE.Mesh(geometry, material);

  // mesh.rotation.y = 1.6;
  // mesh.rotation.x = 1.6;   
  // mesh.position.z = 0.1; 


  const geometry2 = new THREE.PlaneBufferGeometry(
  6, 6, 30, 30);

  //{ShineKef, RangeMin, RangeMax}
  const uniforms_ = Object.keys(uniforms).reduce ( ( memo, key) => {
    return {...memo, [key]: { value: uniforms[key] }}
  }, {})

  const material2 = new THREE.ShaderMaterial (
    {
        uniforms: {
          u_texture: {value: textureMaterial},
          u_gold: {value: textureMaterial},
          Time: {value: 0},
          goldy : {value: 0},
          ...uniforms_
        },
        fragmentShader: document.getElementById("FragmentShader_shine").innerHTML, 
        vertexShader: document.getElementById("spaceTestVertexShader").innerHTML
    }
    )

  const mesh2 = new THREE.Mesh(geometry2, material2);
  material2.transparent = true;

//   parent_Mesh.add(mesh);
  parent_Mesh.add(mesh2);


  return parent_Mesh;

}