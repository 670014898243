// мы передаём сюда ID школы и названия кордао
import React from 'react';
import { AvatarRect } from 'src/ver2/containers/lowlavel/avatarRect'
import { withCapoeiraSportState } from 'src/ver2/context';
import { Link } from 'react-router-dom';

import { CordaoCord } from './index';

const CordaoRect = (props) => {
    const { width, SchoolId, Cordao, client, selected, linkTo = 'default', count = null } = props;

    const schoolGradation = client.getSchoolGradation(SchoolId);
    const cordao = schoolGradation[Cordao];
    let lintTo_ = linkTo;

    if ((SchoolId === client.facialSchoolId) || (Cordao ==='- - -')) lintTo_ = null;

    if (lintTo_)
        return <Link to={lintTo_ === 'default' ? `/school/${SchoolId}/cordao?cord=${Cordao}` : lintTo_}>
            <AvatarRect
                selected={selected}
                text={Cordao}
                width={width}
                AvatarComponent={CordaoCord}
                avatarComponentProps={{ cordao }}
                upperComponent = {count}
            />
        </Link>

    return <AvatarRect
        selected={selected}
        text={Cordao}
        width={width}
        AvatarComponent={CordaoCord}
        avatarComponentProps={{ cordao }}
        upperComponent = {count}
    />

}

export default React.memo(withCapoeiraSportState('client')(CordaoRect));