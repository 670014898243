

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

// NT - News Template

export const NT_registrationOpened = ( Event, eventsTypes ) => {

    if (!Event) return '';
    const { Type, Date, City, Adress, Id, SettingsObj } = Event;

    let resultText = ``;
    resultText = `^a_^text^a_^Открыта регистрация на ^e_${Id}^!
    ^n_^^n_^ ${eventsTypes[Type] ? eventsTypes[Type].name : 'Мероприятие'} состоится ${moment(Event.Date, 'DD.MM.YYYY').locale('ru').format('DD MMM YYYY')}. ^n_^^n_^`


    if (!City && !Adress) {
        resultText += 'Город и адрес проведения в данный момент уточняются^n_^^n_^';
    } else {
        if (!Adress) {
            resultText += `Город проведения: ${City} ^n_^Адрес в данный момент уточняется^n_^^n_^`;
        } else {
            resultText += `Мероприятие пройдет по адресу: ${City + ', ' + Adress} ^n_^^n_^`;
        }
    }

    if (SettingsObj) {
        const { PermissionMain } = SettingsObj;
        if (PermissionMain) {
            const {
                FaceFrom,
                IsClosed,
                FaceId,
                WithApplication,
                FaceObject
            } = PermissionMain;
        
            if (FaceFrom === 'school') {
                resultText += `Мероприятие проходит от лица школы ^s_${FaceId}^`;
                if (IsClosed) {
                    resultText += '^n_^';
                    resultText += `Мероприятие - закрытое. Регистрация доступна только для учеников школы.^n_^`;
                }
            }     
        }
    }

    return {
        title: 'Открыта регистрация',
        template: resultText
    }
}

// NTT - Notification Template

export const NTT_participatorData = ( Event, Type ) => {
    let resultText = ``;

    if (!Event) return;
    const { Id, SettingsObj } = Event;

    let dataName = Type;

    if (Type === 'payment') {
        dataName = 'оплата'
    } else {
        if (SettingsObj && SettingsObj.AdmissionMain && SettingsObj.AdmissionMain.AdmissionData) {
            // добавляем остальных штук
            const data = SettingsObj.AdmissionMain.AdmissionData.find( x => x.key === Type  );
            if (data) {
                dataName = data.title;
            }
        }
    }

    resultText = `^a_^text^a_^Мероприятие: ^e_${Id}^ ^n_^Подтверждение: ${dataName}`;
    return resultText;
}