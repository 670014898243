// это компонент ui с иконкой и текстом
import React from 'react';
import './style.css'

const UiItem = (props) => {
    const { size, icon, text, onClick, isSelected, isMobile } = props;

    const fontSize = size * 0.16;
    const lineHeight = isMobile ?  size * 0.012 : size * 0.006;

    const src = `/../images/ver2/icon/${ isSelected ? icon : icon + '_dashed' }.png`

    return <div onClick = {onClick} style = {{width: size, height: size}} className='ui_item__container'>
        <div className='ui_item__icon'>
            <img alt = 'CapoeiraSport' src = {src} />
        </div>
        <div style = {{fontSize, lineHeight}} className={`ui_item__text ${isSelected? 'global_color_main' : ''}`}>
            {text}
        </div>
    </div>
}

export default UiItem;