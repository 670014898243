// это посто сама по себе аввтарка школы
import React from 'react';

import { Avatar } from 'antd';

const SchoolAvatar = (props) => {
    const {size, schoolId, style} = props;
    const avatarUrl = 'https://capoeirasport.com/graphics/logo_schools/' + schoolId + '/logo.png';
    return <Avatar size = {size || 20} style = {style} src = {avatarUrl}/>
}

export default SchoolAvatar;