import React, { Component } from 'react';
import { Input, Icon, DatePicker, Checkbox, Select } from 'antd';
import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

moment.locale('ru');
const { Option } = Select;

/*
    participatorsCategorys : [
        {
            name: 'tshirt',
            title: 'футболка',
            options: [
                {
                    key,
                    title
                }
            ]
            paymentSplit: false, // разделять ли по категориям
            payments: [
                {
                    date: '10.15.2022'
                    keysWithPayment: {
                        [key]: 1000
                    }
                    oncePayment: 1000
                }
            ],
        }
    ]

    participatorsSales: [
        {
             options: [
                    {
                        name, // tshirt
                        key // null или ключ
                    }
                ],
            value
        }
    ]

    renewPayment - ф-я изменения participatorsCategorys 
    renewSales - ф-я изменения participatorsSales 

*/

const _findAt = (collection, paramName, paramValue) => {
    for (let index = 0; index < collection.length; index++) {
        if (collection[index][paramName] === paramValue) {
            return {
                index,
                item: { ...collection[index] }
            }
        }
    }
    return null;
}

export class Payment extends Component {

    render() {

        const { participatorsCategorys, participatorsSales } = this.props;
        if (!participatorsCategorys || participatorsCategorys.length === 0) return <div />

        return <div className='data_changer_payment'>
            {participatorsCategorys.map(
                (participatorsCategory) => {
                    const { name } = participatorsCategory;
                    return <this.OneCategoryBlock key={'partcat' + name} {...participatorsCategory} />
                }
            )}
            {participatorsCategorys.length > 1 ? <span className='data_changer_payment_sales_title'>
                Скидки
            </span> : null}
            <div className='data_changer_payment_sales'>
                {
                    participatorsSales.map(
                        (participatorsSale, index) => {
                            return <this.Sale key={'partcatsale_' + index} participatorsSaleIndex={index} participatorsSale={participatorsSale} />
                        }
                    )
                }
                {
                    participatorsCategorys.length > 1 ? <this.SaleBlank /> : null
                }
            </div>
        </div>
    }

    changePaymentSplit = (participatorsCategorysName) => {
        let participatorsCategorys = [...this.props.participatorsCategorys];

        const { index, item } = _findAt(participatorsCategorys, 'name', participatorsCategorysName) || {};
        if (item && participatorsCategorys[index]) {
            participatorsCategorys[index].paymentSplit = !participatorsCategorys[index].paymentSplit;
        }

        const { renewPayment } = this.props;
        renewPayment(participatorsCategorys);
    }

    OneCategoryBlock = (participatorsCategory) => {

        const { name, title, paymentSplit, options, payments } = participatorsCategory;

        return <div className='data_changer_payment_oneblock'>
            <div className='data_changer_payment_oneblock_left'>
                <div className='data_changer_payment_oneblock_title'>
                    <span>{title}</span>
                    {options && options.length > 1
                        ? <div className='data_changer_payment_oneblock_checksplit'>
                            <Checkbox checked={paymentSplit} onChange={(e) => { this.changePaymentSplit(name) }} />
                            <span>раздельная оплата</span>
                        </div>
                        : null}
                </div>
                {
                    paymentSplit ?
                        <div className='data_changer_payment_oneblock_options'>
                            {options.map((option) => {
                                const { key, title } = option;
                                return <div key={'dcpooo-' + key} className='data_changer_payment_oneblock_options_oneoption'>
                                    {title}
                                </div>
                            })}
                        </div>
                        : null
                }
            </div>
            <div className='data_changer_payment_oneblock_right'>
                {
                    payments.map((payment, index) => {
                        return <this.OnePaymentBlock participatorsCategorysName={name} paymentIndex={index} options={options} payment={payment} paymentSplit={paymentSplit} />
                    })
                }
                {
                    payments.length < 3 ?
                        <this.BlankPaymentBlock name={name} />
                        :
                        null
                }
            </div>
        </div>
    }

    // это вертикальная штука, сверху - дата
    // OnePaymentBlock /////////////////////////
    changeDateAtPayment = (participatorsCategorysName, paymentIndex, date) => {
        let participatorsCategorys = [...this.props.participatorsCategorys];

        const { index, item } = _findAt(participatorsCategorys, 'name', participatorsCategorysName) || {};
        if (item && participatorsCategorys[index] && participatorsCategorys[index].payments && participatorsCategorys[index].payments[paymentIndex]) {
            participatorsCategorys[index].payments[paymentIndex].date = date;
        }

        const { renewPayment } = this.props;
        renewPayment(participatorsCategorys);
    }

    deletePayment = (participatorsCategorysName, paymentIndex) => {
        let participatorsCategorys = [...this.props.participatorsCategorys];

        const { index, item } = _findAt(participatorsCategorys, 'name', participatorsCategorysName) || {};
        if (item && participatorsCategorys[index] && participatorsCategorys[index].payments && participatorsCategorys[index].payments[paymentIndex]) {
            participatorsCategorys[index].payments.splice(paymentIndex, 1);
        }

        const { renewPayment } = this.props;
        renewPayment(participatorsCategorys);
    }

    /*
        payment
            date: '10.15.2022'
            keysWithPayment: {
                  [key]: 1000
            }
            oncePayment: 1000
    */
    changeValueAtPayment = (participatorsCategorysName, paymentIndex, value, optionKey = null) => {
        let participatorsCategorys = [...this.props.participatorsCategorys];

        const { index, item } = _findAt(participatorsCategorys, 'name', participatorsCategorysName) || {};
        if (item && participatorsCategorys[index] && participatorsCategorys[index].payments && participatorsCategorys[index].payments[paymentIndex]) {
            if (optionKey) {
                participatorsCategorys[index].payments[paymentIndex].keysWithPayment[optionKey] = value;
            }
            participatorsCategorys[index].payments[paymentIndex].oncePayment = value;
        }

        const { renewPayment } = this.props;
        renewPayment(participatorsCategorys);
    }

    // этот блок сверху показывает дату а снизу списком (или не списком) цены для изменения
    OnePaymentBlock = ({ participatorsCategorysName, options, payment, paymentIndex, paymentSplit }) => {
        const { date, oncePayment } = payment;

        let Inputs = [];
        if (paymentSplit) {
            const { keysWithPayment } = payment;
            Inputs = options.map(({ key }) => {
                return <Input
                    key={'opbbbb-' + key}
                    value={keysWithPayment[key]}
                    onChange={(e) => { this.changeValueAtPayment(participatorsCategorysName, paymentIndex, e.target.value, key) }}
                />
            })
        } else {
            Inputs = <Input
                value={oncePayment}
                onChange={(e) => { this.changeValueAtPayment(participatorsCategorysName, paymentIndex, e.target.value) }}
            />
        }

        return <div className='data_changer_payment_onepayment_block'>
            <div className='data_changer_payment_onepayment_date'>
                <div className='data_changer_payment_onepayment_date_left'>до</div>
                <DatePicker
                    defaultPickerValue={moment('', 'DD MMM YYYY')} 
                    locale = {locale}
                    format={'DD.MM.YYYY'}
                    value={date ? moment(date, 'DD.MM.YYYY').locale('ru') : null}
                    onChange={(value) => { this.changeDateAtPayment(participatorsCategorysName, paymentIndex, moment(value).locale('ru').format('DD.MM.YYYY')) }}
                />
                <Icon className='global-tooltip' data-tooltip={'удалить'} type='cross' onClick={() => { this.deletePayment(participatorsCategorysName, paymentIndex) }} />
            </div>
            <div className='data_changer_payment_onepayment_inputs'>
                {Inputs}
            </div>
        </div>
    }

    // пустой бланк, который позволяет добавить оплаты, если они пустые
    addPaymentBlock = (participatorsCategorysName) => {
        let participatorsCategorys = [...this.props.participatorsCategorys];

        const { index, item } = _findAt(participatorsCategorys, 'name', participatorsCategorysName) || {};
        if (item && participatorsCategorys[index] && participatorsCategorys[index].payments) {
            participatorsCategorys[index].payments.push(
                {
                    date: null,
                    keysWithPayment: {
                    },
                    oncePayment: 0
                }
            )
        }

        const { renewPayment } = this.props;
        renewPayment(participatorsCategorys);
    }

    BlankPaymentBlock = ({ name }) => {
        return <div onClick={() => { this.addPaymentBlock(name) }} className='data_changer_payment_oneblock_blank'>
            <spam>
                Добавить дату оплату
            </spam>
        </div>
    }

    // SALE - 

    // participatorsCategorys
    // name: 'tshirt',
    // title: 'футболка',
    // options: [
    //     {
    //         key,
    //         title
    //     }
    // ]

    // participatorsSales: [
    //     {
    //          options: [ key ],
    //         value
    //     }
    // ]

    changeSale = (participatorsSaleIndex, participatorsOneSaleOptionIndex, option = undefined, value = undefined) => {
        const { participatorsSales } = this.props;
        if (participatorsSales[participatorsSaleIndex] && participatorsSales[participatorsSaleIndex].options) {
            let participatorsSales_ = [...participatorsSales];
            if (option !== undefined) {
                participatorsSales_[participatorsSaleIndex].options[participatorsOneSaleOptionIndex] = option;
            }
            if (value !== undefined) {
                participatorsSales_[participatorsSaleIndex].value = value;
            }
            const { renewSales } = this.props;
            renewSales(participatorsSales_);
        }
    }

    Sale = ({ participatorsSale, participatorsSaleIndex }) => {

        const { participatorsCategorys } = this.props;

        const { options: participatorsOneSaleOptions, value } = participatorsSale;
        // для начала формируем все варианты
        // группируя их по name
        const alloptions = participatorsCategorys.reduce((memo, participatorsCategory) => {

            let optionsFromParticipatorsCategory = [{
                name: participatorsCategory.name,
                key: participatorsCategory.name,
                title: participatorsCategory.title + ' любой вариант'
            }];
            // сначла добавляем основной вариант

            for (let i = 0; i < participatorsCategory.options.length; i++) {
                const option = participatorsCategory.options[i];
                const { key, title } = option;
                optionsFromParticipatorsCategory.push({
                    name: participatorsCategory.name,
                    key: participatorsCategory.name + '@' + key,
                    title: participatorsCategory.title + '-' + title
                })
            }

            return [...memo, optionsFromParticipatorsCategory]
        }, [])


        return <div className='data_changer_payment_sales__one'>
            {alloptions.map((option, index) => {
                return <Select
                    style={{ width: 230 }}
                    value={participatorsOneSaleOptions[index] ? participatorsOneSaleOptions[index].toString() : null}
                    onChange={(value) => { this.changeSale(participatorsSaleIndex, index, value) }}
                >
                    {option.reduce((memo, { key, title }) => {
                        memo.push(
                            <Option value={key.toString()}>
                                {title}
                            </Option>
                        );
                        return memo;
                    }, [])}
                </Select>

            })}
            <div className='data_changer_payment_sales__one_right'>
                <span>скидка</span>
                <Input value={value} onChange={(e) => { this.changeSale(participatorsSaleIndex, 0, undefined, e.target.value) }} />
            </div>
        </div>
    }

    // пустой бланк, который позволяет добавить оплаты, если они пустые
    addSale = () => {
        let participatorsSales = [...this.props.participatorsSales];
        const { participatorsCategorys } = this.props;
        participatorsSales.push({
            options: participatorsCategorys.map((item) => { return null }),
            value: null
        })

        const { renewSales } = this.props;
        renewSales(participatorsSales);
    }

    SaleBlank = () => {
        return <div className='data_changer_payment_sales__blank' onClick={this.addSale}>
            <span> ДОБАВИТЬ СКИДКУ </span>
        </div>
    }
}