import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import AppCapoeira from './AppСapoeira';
import MainApp from './ver2';

// import { Zaglushka } from './zaglushka';
//import Alena from './Alena';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <BrowserRouter>
    {/* <Zaglushka/> */}
    <MainApp />
    {/* <AppCapoeira /> */}
  </BrowserRouter>,
  document.getElementById('root'));

//ReactDOM.render(<Alena />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
