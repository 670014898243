// это посто сама по себе аввтарка группы
import React from 'react';

import { Avatar, Tooltip } from 'antd';
import { withCapoeiraSportState } from 'src/ver2/context';
import { StarIcon } from 'src/ver2/components/ui/icons';
import './avatarStyle.css';

const EventAvatar = (props) => {
    const { size, eventId, client, iamuser, withExtra = false } = props;

    const finalSize = size || 20;;

    let extra = null;
    if (withExtra) {
        // console.log('EventAvatar withExtra', iamuser);
        if (iamuser && iamuser.myFollows && iamuser.myFollows.event && iamuser.myFollows.event[eventId]) {
            // console.log('EventAvatar withExtra yes', iamuser);
            extra = <Tooltip title='Вы планируете участие' placement='top'>
                <span>
                    <StarIcon width={size / 3} height={size / 3} className='trefa_svg_icon_star_follow' />
                </span>
            </Tooltip>
        }
        if (iamuser && iamuser.myParticipation && iamuser.myParticipation[eventId]) {
            // console.log('EventAvatar withExtra yes', iamuser);
            extra = <Tooltip title='Вы зарегистрированы' placement='top'>
                <span>
                    <StarIcon width={size / 3} height={size / 3} className='trefa_svg_icon_star_participation' />
                </span>
            </Tooltip>
        }
        if (iamuser && iamuser.myCompetitor && iamuser.myCompetitor[eventId]) {
            // console.log('EventAvatar withExtra yes', iamuser);
            extra = <Tooltip title='Вы зарегистрированы' placement='top'>
                <span>
                    <StarIcon width={size / 3} height={size / 3} className='trefa_svg_icon_star_participation' />
                </span>
            </Tooltip>
        }
    }

    const avatarUrl = client.getEventsUrl() + eventId + '_logo.png';

    if (!extra) return <Avatar size={finalSize} src={avatarUrl} />

    return <div style={{ width: finalSize, height: finalSize }} className='event_avatar_main'>
        {extra ?
            <div className='event_avatar_main_extra'>
                {extra}
            </div>
            : null}
        <Avatar size={finalSize} src={avatarUrl} />
    </div>
}

export default React.memo(withCapoeiraSportState('client, iamuser')(EventAvatar));