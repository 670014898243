import React, { Fragment, PureComponent } from 'react';
import AchivmentTooltip from 'src/ver2/components/achivments/tooltip';
import { Tooltip } from 'antd';
import './style.css';

export const LittleAchivment = ({ Name, Lvl, Progress, width = 110, noProgress }) =>
    <Tooltip key={`achtlt_${Name}`} placement={'top'} title={<AchivmentTooltip achivment={{ Lvl, Name, Progress}} noProgress = {noProgress} />}>
        <div className='little_achivments_plashka_achiv' key={`ach_${Name}`} style={{ width, height: width }} >
            <img src={`/../images/ver2/achivs/${Name}/${Lvl}.png`} />
            {!noProgress && <div className='little_achivments_plashka_achiv_bar'
                style={
                    {
                        left: width / 6,
                        top: width,
                        width: width * (2 / 3),
                        height: 6
                    }
                }

            >
                <div style={{ width: `${Progress}%` }} className='little_achivments_plashka_achiv_bar_inner' />
            </div>
            }
        </div>
    </Tooltip>