import React, { Component } from 'react';
import { withPersonState } from 'src/ver2/pages/person/context';
import { TrefaLayout } from 'src/ver2/containers/layout';
import Loader from 'src/ver2/components/loader';
import VideoLittlePlashka from 'src/ver2/plashki/video/little';

import './style.css';

/**
 * Эта плашка показывает какое-то количество видео спортсмена
 * 
 */

class PersonVideoMiniPlashka extends Component {

    // constructor(props) {
    //     super(props);
    // }

    shouldComponentUpdate({ isVideosReady, Videos, upperStyle }) {
        if (isVideosReady !== this.props.isVideosReady) return true;
        if (Videos !== this.props.Videos) return true;
        if (upperStyle.height !== this.props.upperStyle.height) return true;
        return false;
    }

    render() {

        const { isVideosReady, Videos, setComponentSelfHeight, plashkaId, videoHeight = 240 } = this.props;

        if (!isVideosReady) return <div className='person_video_mini_plashka'>
            <Loader text = 'Подгрузка видео' />
        </div>
        
        if (!Videos || !Videos.length) {
            setComponentSelfHeight({ plashkaId, height: 1 });
            return null;
        }

        let Videos_ = [...Videos];
        Videos_.reverse();

        setComponentSelfHeight({ plashkaId, height: 48 + videoHeight * Videos_.slice(0, 2).length });

        const { width, height } = this.props.upperStyle;

        // console.log('video', Videos);

        return <div className='person_video_mini_plashka'>
            <TrefaLayout greedsX={[width]}
                components={
                    Videos_.slice(0, 2).map((video) => {
                        const { Id } = video;
                        return {
                            component: VideoLittlePlashka,
                            cell_x: 1,
                            collapse: 1,
                            id: Id,
                            componentProps: { height: videoHeight, videoId: Id }
                        }
                    })
                }
            />
        </div>
    }

}

export default withPersonState(`
    Videos,
    isVideosReady
`
)(PersonVideoMiniPlashka);