
import React, { PureComponent } from 'react';
import GroupState, { withGroupState } from './context';
import { TrefaLayout } from 'src/ver2/containers/layout';
import { Modal } from 'antd';
import { Helmet } from 'react-helmet';

import BGP from './plashki/BGP';
import GroupStructurePlashka from './plashki/structure';
import CalendarPlashka from './plashki/calendar';
import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import GroupResultsPlashka from './plashki/results';
import VideoLittlePlashka from 'src/ver2/plashki/video/little';

import SeqMonitorPlashka from 'src/ver2/plashki/sequencesMonitor';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import UiChooser from 'src/ver2/plashki/ui/chooser';

import GroupDataChange from 'src/ver2/containers/groupDataChange';
import AddEventData from 'src/ver2/containers/addEventModal';
import MainTabsPlashka from './plashki/mainTabs';

import Loader from 'src/ver2/components/loader';

import { groupBy } from 'lodash';

import './style.css';

class GroupPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            dataFlag: false,
            showEventAddModal: false,
            page: 'info',
        }

        this.searchDefault = {
            page: 'info'
        }
    }

    changeShowEventAddModal = (showEventAddModal = true) => this.setState({ showEventAddModal })
    changeDataFlag = (dataFlag = true) => this.setState({ dataFlag })

    linkTo = (param, value) => {
        let { page } = this.state;
        let linkObject = { page };
        linkObject[param] = value;
        return `?${Object.keys(linkObject).reduce((memo, key, index) => {
            if (!linkObject[key]) return memo;
            return memo + `${index > 0 ? '&' : ''}${key}=${linkObject[key]}`
        }, '')}`
    }

    chooseTab = () => {
        const { location } = this.props;
        const { search } = location;

        // парсим search
        let searchObj = {
            ...this.searchDefault
        }

        const searchAsObj = search.split('?');
        if (searchAsObj && searchAsObj[1]) {
            const search_ = searchAsObj[1].split('&');
            for (let i = 0; i < search_.length; i++) {
                const search__ = search_[i].split('=');
                searchObj[search__[0]] = search__[1];
            }
        }

        this.setState({
            ...searchObj
        })

    }

    componentDidMount() {
        this.chooseTab();
    }
    componentDidUpdate() {
        this.chooseTab();
    }

    render() {

        const { Group, isGroupReady, isMobile, Students, Instructors, iAmCreator, iAmInstructor, ShopId } = this.props;
        const { dataFlag, showEventAddModal } = this.state;
        const { changeDataFlag } = this;

        if (!Group || !isGroupReady) return <div className='group_page'>
            <Loader />
        </div>

        const width = 1200;

        const Students_ = (Students && Students.length) ? groupBy(Students, 'BirthDate') : {};
        let mainPersons = [{ Id: Group.CreatorId, groupType: 'основатель' }]
        if (Instructors && Instructors.length > 0) {
            for (let i = 0; i < Instructors.length; i++) {
                mainPersons.push({
                    Id: Instructors[i].UserId, groupType: 'инструктор'
                })
            }
        }

        if (isMobile) {

            const { width } = isMobile;
            const { page } = this.state;

            let Items = [];
            Items.push({ Title: 'Состав', name: 'info', icon: 'info', linkTo: `/group/${Group.Id}/${this.linkTo('page', 'info')}` });
            Items.push({ Title: 'Результаты', name: 'raiting', icon: 'results', linkTo: `/group/${Group.Id}/${this.linkTo('page', 'raiting')}` });
            Items.push({ Title: 'Видео', name: 'video', icon: 'video', linkTo: `/group/${Group.Id}/${this.linkTo('page', 'video')}` });

            let component = [];
            component.push(
                {
                    component: BGP,
                    cell_x: 2,
                    collapse: 1,
                    id: 1,
                    componentProps: { _title: 'Группа', height: 100 }
                },
                {
                    cell_x: 2,
                    collapse: 1,
                    componentProps: { height: 24 }
                },
                {
                    component: PersonsLittleListPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 33,
                    componentProps: { _title: 'Инструкторы', yPadding: 'little', height: 54 + mainPersons.length * 50, Persons: mainPersons, mode: 'group_instructors' }
                },
                ((iAmCreator || iAmInstructor) ? {
                    component: ButtonPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 145,
                    componentProps: { height: 45, text: 'РЕДАКТИРОВАТЬ ДАННЫЕ', onClick: changeDataFlag }
                }
                    :
                    {
                        cell_x: 2,
                        collapse: 1,
                        componentProps: { height: 1 }
                    }
                ),
                (((iAmCreator || iAmInstructor) && Number(Group.SchoolId) === 1) ? {
                    component: ButtonPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 145,
                    componentProps: { height: 60, yPadding: 'little', text: 'CRM', linkTo: `/crm` }
                }
                    :
                    {
                        cell_x: 2,
                        collapse: 1,
                        componentProps: { height: 1 }
                    }
                ),
                ((ShopId) ? {
                    component: ButtonPlashka,
                    id: 6696669,
                    cell_x: 2,
                    collapse: 1,
                    componentProps: { height: 60, yPadding: 'little', text: 'МАГАЗИН ГРУППЫ', linkTo: `/shop/${ShopId}` }
                }
                    :
                    {
                        cell_x: 2,
                        collapse: 1,
                        componentProps: { height: 1 }
                    }
                ),
                {
                    component: UiChooser,
                    cell_x: 2,
                    collapse: 1,
                    id: 3,
                    componentProps: {
                        height: 130, yPadding: 'usual', tab: page, Items, mobileStyle: true, itemHeight: 75
                    }
                }
            )

            if (page === 'info') {
                component.push(
                    {
                        component: CalendarPlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 693,
                        componentProps: { _title: 'Дни рождения', yPadding: 'little', height: 300, Students: Students_ }
                    }
                    ,
                    // {
                    //     component: MainTabsPlashka,
                    //     cell_x: 2,
                    //     collapse: 1,
                    //     id: 692,
                    //     componentProps: { componentSelfHeight: true, _title: '', yPadding: 'little', height: 500 }
                    // },
                    {
                        component: GroupStructurePlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 692,
                        componentProps: { componentSelfHeight: true, _title: 'Структура группы', yPadding: 'little', height: 500 }
                    }
                )
            }

            if (page === 'video') {
                const { nowGroupVideoId } = this.props;
                if (nowGroupVideoId) {
                    component.push(
                        {
                            component: VideoLittlePlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 9992234,
                            componentProps: { height: 305, _title: 'Произвольный бой', videoId: nowGroupVideoId, autoplay: true }
                        },
                        {
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 10 }
                        }
                    )
                }
                component.push(
                    {
                        component: SeqMonitorPlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 3443234,
                        componentProps: { height: 420, _title: 'Связки группы', yPadding: true, maxWidthStretch: true, vertical: true, groupId: Group.Id }
                    }
                )
            }

            if (page === 'raiting') {
                component.push(
                    {
                        component: GroupResultsPlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 623,
                        componentProps: { _title: 'Результаты', yPadding: 'little', height: 200 }
                    }
                )
            }



            return <div style={{ width }} className='group_page'>
                <Modal
                    className='aaa'
                    visible={dataFlag}
                    onCancel={() => { this.changeDataFlag(false) }}
                    footer={null}
                    title={'Редактировать данные'}
                    width={300}
                >
                    {dataFlag ? <GroupDataChange dataFlag={dataFlag} /> : null}
                </Modal>
                <TrefaLayout greedsX={[5, width - 10, 5]}
                    components={component}
                />
            </div>
        } // IS MOBILE END

        // КОМПОНЕНТЫ НА ПК

        let component = [];
        component.push(
            {
                component: BGP,
                cell_x: 2,
                collapse: 1,
                id: 1,
                componentProps: { _title: 'Группа', height: 200 }
            },
            // {
            //     component: GroupStructurePlashka,
            //     cell_x: 2,
            //     collapse: 1,
            //     id: 'sp4',
            //     componentProps: { componentSelfHeight: true, _title: 'Структура группы', yPadding: 'little', height: 500 }
            // },
            {
                component: MainTabsPlashka,
                cell_x: 2,
                collapse: 1,
                id: 2,
                componentProps: { componentSelfHeight: true, _title: '', yPadding: 'little', height: 500 }
            },
            {
                component: PersonsLittleListPlashka,
                cell_x: 4,
                collapse: 1,
                componentProps: { _title: 'Инструкторы', height: 200, Persons: mainPersons, mode: 'group_instructors' }
            },
            ((iAmCreator || iAmInstructor) ? {
                component: ButtonPlashka,
                cell_x: 4,
                collapse: 1,
                id: 15,
                componentProps: { height: 45, text: 'РЕДАКТИРОВАТЬ ДАННЫЕ', onClick: changeDataFlag }
            }
                :
                {
                    cell_x: 2,
                    collapse: 1,
                    componentProps: { height: 1 }
                }
            ),
            (((iAmCreator || iAmInstructor) && Number(Group.SchoolId) === 1) ? {
                component: ButtonPlashka,
                cell_x: 4,
                collapse: 1,
                id: 145,
                componentProps: { height: 60, yPadding: 'little', text: 'CRM', linkTo: `/crm` }
            }
                :
                {
                    cell_x: 4,
                    collapse: 1,
                    componentProps: { height: 1 }
                }
            ),
            ((iAmCreator || iAmInstructor) ? {
                component: ButtonPlashka,
                id: 669,
                cell_x: 4,
                collapse: 1,
                componentProps: { height: 60, yPadding: 'little', text: 'СОЗДАТЬ МЕРОПРИЯТИЕ', onClick: this.changeShowEventAddModal }
            }
                :
                {
                    cell_x: 4,
                    collapse: 1,
                    componentProps: { height: 1 }
                }
            ),
            ((ShopId) ? {
                component: ButtonPlashka,
                id: 6696669,
                cell_x: 4,
                collapse: 1,
                componentProps: { height: 60, yPadding: 'little', text: 'МАГАЗИН ГРУППЫ', linkTo: `/shop/${ShopId}` }
            }
                :
                {
                    cell_x: 4,
                    collapse: 1,
                    componentProps: { height: 1 }
                }
            ),
            {
                component: CalendarPlashka,
                cell_x: 4,
                collapse: 1,
                id: 3781234,
                componentProps: { _title: 'Дни рождения', yPadding: 'little', height: 300, Students: Students_ }
            },
            {
                component: SeqMonitorPlashka,
                cell_x: 4,
                collapse: 1,
                id: 3443234,
                componentProps: { height: 420, _title: 'Связки группы', yPadding: true, maxWidthStretch: true, vertical: true, groupId: Group.Id }
            },
            ((this.props.nowGroupVideoId) ?
                {
                    component: VideoLittlePlashka,
                    cell_x: 4,
                    collapse: 1,
                    id: 92939495,
                    componentProps: { height: 305, _title: 'Произвольный бой', videoId: this.props.nowGroupVideoId, autoplay: true }
                }
                :
                {
                    cell_x: 4,
                    collapse: 1,
                    componentProps: { height: 1 }
                }
            ),
            {
                cell_x: 2,
                collapse: 1,
                componentProps: { height: 15 }
            },
            {
                component: GroupResultsPlashka,
                cell_x: 4,
                collapse: 1,
                id: 23,
                componentProps: { _title: 'Результаты', yPadding: 'little', height: 200 }
            }
        )

        return <div style={{ width }} className='group_page'>
            <Modal
                className='aaa'
                visible={dataFlag}
                onCancel={() => { this.changeDataFlag(false) }}
                footer={null}
                title={'Редактировать данные'}
                width={1200}
            >
                {dataFlag ? <GroupDataChange dataFlag={dataFlag} /> : null}
            </Modal>
            <Modal
                className='adminModalClass'
                visible={showEventAddModal}
                onCancel={() => { this.changeShowEventAddModal(false) }}
                footer={null}
                title={'Редактировать данные'}
                width={1200}
            >
                <AddEventData mode={'rodaonly'} />
            </Modal>
            <Helmet
                title={`Группа ${Group.Name}, капоэйра, портал Capoeira Sport`}
                meta={[
                    { "name": "description", "content": `Страничка группы капоэйра: ${Group.Name}` }
                ]}
            />
            <TrefaLayout greedsX={[20, 820, 20, 320, 20]}
                components={component}
            />
        </div>
    }

}

const GroupPageWithState = withGroupState(`
Group,
isGroupReady,

ShopId,

Students,
Instructors,

iAmCreator,
iAmInstructor,
iAmStudent,

nowGroupVideoId,

location,

isMobile,
client,
iamuser
`)(GroupPage);

const GroupPageWithContent = (props) => <GroupState {...props}> {GroupPageWithState()} </GroupState>

export default GroupPageWithContent;