// мы передаём сюда ID школы и названия кордао
import React from 'react';
import { AvatarPlate } from 'src/ver2/containers/lowlavel/avatarPlate'
import { withCapoeiraSportState } from 'src/ver2/context';
// import { Link } from 'react-router-dom';

import { CordaoCord } from './index';

const CordaoPlate = (props) => {
    const { width, height, SchoolId, Cordao, client } = props;

    const schoolGradation = client.getSchoolGradation(SchoolId);
    const cordao = schoolGradation[Cordao];

    // if (linkTo)
    //     return <Link to={linkTo === 'default' ? `/school/${SchoolId}/info` : linkTo}>
    //         <AvatarRect
    //             text={school ? school.Name : 'Нет школы'}
    //             width={width}
    //             AvatarComponent={SchoolAvatar}
    //             avatarComponentProps={{ schoolId: SchoolId }}
    //         />
    //     </Link>

    return <AvatarPlate
        text={Cordao}
        width={width}
        height={height}
        AvatarComponent={CordaoCord}
        avatarComponentProps={{ cordao }}
    />

}

export default React.memo(withCapoeiraSportState('client')(CordaoPlate));