export default {
    flag_orange_t2: {
        type: 'stuff',
        subtype: 'flag',
        grade: 3,
        discription: 'Эксклюзивный оранжевый флаг в рамках тестового мероприятия',
        src: `/../images/ver2/scores/stuff/flag_orange_t2.png`,

        w: 2,
        h: 5,
        shiftX: 0,
        shiftY: 0
    },
    
    flag_brazil1_t2: {
        type: 'stuff',
        subtype: 'flag',
        grade: 2,
        discription: 'Эксклюзивный синий флаг в рамках тестового мероприятия',
        src: `/../images/ver2/scores/stuff/flag_brazil1_t2.png`,

        w: 2,
        h: 5,
        shiftX: 0,
        shiftY: 0
    },

    
    plant_orange_t1: {
        type: 'stuff',
        subtype: 'plant',
        grade: 2,
        discription: 'Эксклюзивное растение в рамках тестового мероприятия',
        src: `/../images/ver2/scores/stuff/plant_orange_t1.png`,

        w: 1,
        h: 2,
        shiftX: 0,
        shiftY: 1   
    },

    cat_orange: {
        type: 'stuff',
        subtype: 'plant',
        grade: 4,
        discription: 'Экскулюзивный Котик-капоэйрист c пандейру',
        title: 'Котик-капоэйрист c беримбао',
        src: `/../images/ver2/scores/stuff/cat_orange.png`,

        w: 2,
        h: 2,
        shiftX: 0,
        shiftY: 1   
    },

    oboi_orange: {
        type: 'paint',
        subtype: 'oboi',
        grade: 4,
        discription: 'Эксклюзивные оранжевые обои',
        src: `/../images/ver2/scores/paint/oboi_orange.png`,
        wallStyle: {
            background: `linear-gradient( #FFDD00, #EF7F1A )`
        } 
    },
}