import React, { Fragment, PureComponent } from 'react';
import { withPersonState } from 'src/ver2/pages/person/context';
import AchivmentTooltip from 'src/ver2/components/achivments/tooltip';
import { LittleAchivment } from 'src/ver2/components/achivments/little';
import Loader from 'src/ver2/components/loader';
import ArticleLittle from 'src/ver2/pages/article/little';
import { Tooltip } from 'antd';

import { orderBy } from 'lodash';

import './style.css';

class PersonAchivmentsPlashka extends PureComponent {

    state = {
    }

    render() {

        const { isMobile, isAchivmentsReady, Achivments, innerId } = this.props;
        const { width, height } = this.props.upperStyle;

        if (!isAchivmentsReady) {
            return <div style={{ width, height }} className='soforca_plashka'>
                <div className='combo_scheme_plashka_inner' id={innerId}>
                    <div className='person_achivments_plashka'>
                        <Loader text='Загрузка достижеий' />
                    </div>
                </div>
            </div>
        }

        if (!Achivments || !Achivments.length) {
            return <div style={{ width, height }} className='soforca_plashka'>
                <div className='combo_scheme_plashka_inner' id={innerId}>
                    <div className='person_achivments_plashka'>
                        {`Пока нет достижений`}
                        <ArticleLittle articleId={13} />
                    </div>
                </div>
            </div>
        }

        console.log('Achivments', Achivments);

        const Achivments_ = orderBy(Achivments, ['Cost'], ['desc']);
        console.log('Achivments_', Achivments_);

        return <div style={{ width, height }} className='soforca_plashka'>
            <div className='combo_scheme_plashka_inner' id={innerId}>
                <div className='person_achivments_plashka'>
                    {Achivments_.map(({ Name, Lvl, Progress }) => <LittleAchivment key = {`lak_${Name}`} {...{ Name, Lvl, Progress, width: isMobile ? 80 : 110 }} />)}
                </div>
            </div>
        </div>

        // return <div className='person_achivments_plashka' id={innerId}>
        //     <div>
        //         ДОСТИЖЕНИЯ!
        //     </div>
        // </div>
    }
}

export default withPersonState(
    `
    Achivments,
    isAchivmentsReady,
    personId,
    client,
    isMobile
    `
)(PersonAchivmentsPlashka)