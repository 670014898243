import React from 'react';

import Star from './star.js';


import Admin from './admin.js';
import Categorys from './categorys';
import Info from './info.js';
import News from './news';
import Results from './results';
import Settings from './settings';
import Shadule from './shadule.js';
import Statistics from './statistics';
import Shop from './shop';
import Quest from './quests';
import PoolNav from './poolNav';


import Seq from './seq';
import Points from './points';
import Pool from './pool';
import Tech from './tech';

import Strike from './strike';
import Flow from './flow';
import Freeze from './freeze';
import Jump from './jump';

import Libra from './libra';
import Video from './video';
import Telega from './telega.js';

import Sides from './sides';
import Timer from './timer';

import './style.css';
// import './st.css';

// /*

//     classNames - trefa_svg_icon_star_orange
//     trefa_svg_icon__check
//

export const navIcon = (type, checked = false, style = {width: 24, height: 24}, className = '') => {

    let icon = () => {};

    switch (type) {
        case 'admin': {icon = Admin; break;}
        case 'categorys': {icon = Categorys; break;}
        case 'info': {icon = Info; break;}
        case 'news': {icon = News; break;}
        case 'results': {icon = Results; break;}
        case 'settings': {icon = Settings; break;}
        case 'shadule': {icon = Shadule; break;}
        case 'statistics': {icon = Statistics; break;}
        case 'shop': {icon = Shop; break;}
        case 'quests': {icon = Quest; break;}
        case 'poolnav': {icon = PoolNav; break;}
        case 'star': {icon = Star; break;}

        case 'seq': {icon = Seq; break;}
        case 'points': {icon = Points; break;}
        case 'pool': {icon = Pool; break;}
        case 'tech': {icon = Tech; break;}

        case 'strike': {icon = Strike; break;}
        case 'flow': {icon = Flow; break;}
        case 'freeze': {icon = Freeze; break;}
        case 'jump': {icon = Jump; break;}

        case 'libra': {icon = Libra; break;}
        case 'video': {icon = Video; break;}
        case 'telega': {icon = Telega; break;}

        case 'sides': {icon = Sides; break;}
        case 'timer': {icon = Timer; break;}
        default: {

        }
    }

    return <div style={style} className={'trefa_svg_icon ' + className + (checked ? ' trefa_svg_icon__check' : ' ')}>
        {icon()}
    </div>
}

export const InfoIcon = ({ width = 24, height = 24 , addStyle = {}, className = '', checked = false}) => {
    return <div style={{ width, height, ...addStyle }} className={'trefa_svg_icon ' + className + (checked ? 'trefa_svg_icon__check' : '')}>
        {Info()}
    </div>
}


export const StarIcon = ({ width = 50, height = 50 , addStyle = {}, className = ''}) => {
    return <div style={{ width, height, ...addStyle }} className={'trefa_svg_icon ' + className}>
        {Star()}
    </div>
}

export const SidesIcon = ({ width = 50, height = 50 , addStyle = {}, className = ''}) => {
    return <div style={{ width, height, ...addStyle }} className={'trefa_svg_icon ' + className}>
        {Sides()}
    </div>
}

export const TimerIcon = ({ width = 50, height = 50 , addStyle = {}, className = ''}) => {
    return <div style={{ width, height, ...addStyle }} className={'trefa_svg_icon ' + className}>
        {Timer()}
    </div>
}

export const CheckIcon = ({ width = 50, height = 50, addStyle = {}, className = '' }) => {
    return <div style={{ width, height, ...addStyle }} className={'trefa_svg_icon ' + className}>
        <img src='/../images/ver2/portal_icons/check.png' />
    </div>
}

export const CrossIcon = ({ width = 50, height = 50, addStyle = {}, className = '' }) => {
    return <div style={{ width, height, ...addStyle }} className={'trefa_svg_icon ' + className}>
        <img src='/../images/ver2/portal_icons/cross.png' />
    </div>
}

export const EditIcon = ({ width = 50, height = 50, addStyle = {}, className = '' }) => {
    return <div style={{ width, height, ...addStyle }} className={'trefa_svg_icon ' + className}>
        <img src='/../images/ver2/portal_icons/edit.png' />
    </div>
}

export const DeleteIcon = ({ width = 50, height = 50, addStyle = {}, className = '' }) => {
    return <div style={{ width, height, ...addStyle }} className={'trefa_svg_icon ' + className}>
        <img src='/../images/ver2/portal_icons/delete.png' />
    </div>
}

export const LockIcon = ({ width = 50, height = 50, addStyle = {}, className = '' }) => {
    return <div style={{ width, height, ...addStyle }} className={'trefa_svg_icon ' + className}>
        <img src='/../images/ver2/portal_icons/lock.png' />
    </div>
}

// export const CheckIcon = ({ width = 50, height = 50, addStyle = {}, className = '' }) => {
//     return 123
// }