// мы передаём сюда ID школы и названия кордао
import React from 'react';
import { AvatarRect } from 'src/ver2/containers/lowlavel/avatarRect'
import { withCapoeiraSportState } from 'src/ver2/context';
import { Link } from 'react-router-dom';

import SchoolAvatar from './avatar';

const SchoolRect = (props) => {
    const { width, SchoolId, client, linkTo = 'default' } = props;

    const school = client.getSchoolById(SchoolId);

    if (linkTo)
        return <Link to={linkTo === 'default' ? `/school/${SchoolId}/info` : linkTo}>
            <AvatarRect
                text={school ? school.Name : 'Нет школы'}
                width={width}
                AvatarComponent={SchoolAvatar}
                avatarComponentProps={{ schoolId: SchoolId }}
            />
        </Link>

    return <AvatarRect
        text={(school && !school.IsFacial) ? school.Name : 'Нет школы'}
        width={width}
        AvatarComponent={SchoolAvatar}
        avatarComponentProps={{ schoolId: SchoolId }}
    />

}

export default React.memo(withCapoeiraSportState('client')(SchoolRect));