import React, { Component } from 'react';
import { Input } from 'antd';
import ButtonPlashka from 'src/ver2/plashki/ui/button';

import './edit.css';

import TrefaText from './index';

const { TextArea } = Input;

/*

    Это составить текса портала. Он содержит некое количество блоков, каждый из которых это или input, или картинка, или видео
    или еще что-нибудь
*/

// это формирует наш текст (вынес из компонента, мб еще где понадобится)
// НО не формирует html - т.е. он просто как упаковывает текст
const formingTrefaText = (blocks) => {
    let answer = '';
    for (let i = 0; i < blocks.length; i++) {
        const block = blocks[i];
        if (block.type === 'input') {
            answer += `^a_^text^a_^`;
            answer += block.value ? block.value.replaceAll('\n', '^n_^') : '';
        }
        if (block.type === 'image') {
            answer += `^a_^image^a_^`;
            answer += `^s_${block.imgSrc}^`;
            answer += `^w_${block.width}^`;
            answer += `^h_${block.height}^`;
        }
    }
    return answer;
}

// А это - наоборот - делает блоки из того, что есть
const formingToBlocks = (text) => {

    const trefaText_ = text.split('^a_^');

    const { blocks } = trefaText_.reduce((memo, current) => {
        // тут мы обрабатываем каждый блок. если even - то там техническое описание, что нас ждёт
        const { blocks, even, nextType } = memo;

        if (even) {
            return { blocks, even: false, nextType: current }
        }

        // тут идёт обработка пришедшего типа
        if (!even) {
            if (nextType === 'text') {
                const newBlock = {
                    type: 'input',
                    value: current ? current.replaceAll('^n_^', '\n') : ''
                }
                return { blocks: [...blocks, newBlock], even: true, nextType: '' }
            }

            if (nextType === 'image') {

                // value: current ? current.replaceAll('^n_^', '\n') : '',
                // ^s_https://avatars.mds.yandex.net/get-pdb/788379/9abb261f-08e5-4521-a493-160c5ad2ad90/s1200^^w_100%^^h_auto^

                let imgSrc = current.split('^s_');
                imgSrc = imgSrc[1].split('^')[0];

                let width = current.split('^w_');
                width = width[1].split('^')[0];

                let height = current.split('^h_');
                height = height[1].split('^')[0];

                let newBlock = {
                    type: 'image',
                    imgSrc,
                    width,
                    height
                }          
                return { blocks: [...blocks, newBlock], even: true, nextType: '' }
            }
            return { blocks, even: true, nextType: '' }
        }

        return { blocks, even: !even, nextType }

    }, { blocks: [], even: false, nextType: '' });

    return blocks;
}

export default class TrefaTextEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formattingText: '',
            blocks: [
            ]
        }

        if (props.text) {
            this.state.blocks = formingToBlocks(props.text);
        }

        this.state.formattingText = formingTrefaText(this.state.blocks);

    }

    onImageChange = (i, type, value) => {

        let blocks = [...this.state.blocks];
        blocks[i][type] = value;

        const formattingText = formingTrefaText(blocks);
        this.setState({ blocks, formattingText })

    }

    imageComponent = ({ index, imgSrc, width, height }) => {
        return <div className='trefa_text_edit_image_component'>
            <div className='trefa_text_edit_input_component_x' onClick={() => { this.deleteBlock(index) }}>X</div>
            <Input style={{ width: '90%' }} onChange={(e) => { this.onImageChange(index, 'imgSrc', e.target.value) }} value={imgSrc} />
            <div className='trefa_text_edit_image_component_row'>
                Размер:
                <Input style={{ width: 70 }} onChange={(e) => { this.onImageChange(index, 'width', e.target.value) }} value={width} />
                x
                <Input style={{ width: 70 }} onChange={(e) => { this.onImageChange(index, 'height', e.target.value) }} value={height} />
            </div>
        </div>
    }

    onInputChange = (i, value) => {

        let blocks = [...this.state.blocks];
        blocks[i].value = value;
        const formattingText = formingTrefaText(blocks);
        this.setState({ blocks, formattingText })

    }

    inputId = index => `tte_ta_${index}`;

    inputComponent = ({ index, value }) => {
        return <div className='trefa_text_edit_input_component'>
            <div className='trefa_text_edit_input_component_x' onClick={() => { this.deleteBlock(index) }}>X</div>
            <TextArea id={this.inputId(index)} onChange={(e) => { this.onInputChange(index, e.target.value) }} value={value} autosize />
            <this.inputControls index={index} />
        </div>
    }

    inputControls = ({ index }) => {

        const getTextArea = () => {
            const id = this.inputId(index);
            const result = document.getElementById(id);
            return result || null;
        }

        const on_B_Click = () => {
            const textArea = getTextArea();
            if (textArea) {
                const start = textArea.selectionStart;
                const end = textArea.selectionEnd;
                const sel = textArea.value.substring(start, end);
                const value = textArea.value.substring(0, start) + '^b_' + sel + '^' + textArea.value.substring(end);
                this.onInputChange(index, value);
                textArea.focus();
            }
        }
        const on_H1_Click = () => {
            const textArea = getTextArea();
            if (textArea) {
                const start = textArea.selectionStart;
                const end = textArea.selectionEnd;
                const sel = textArea.value.substring(start, end);
                const value = textArea.value.substring(0, start) + '^1_' + sel + '^' + textArea.value.substring(end);
                this.onInputChange(index, value);
                textArea.focus();
            }
        }
        const on_H2_Click = () => {
            const textArea = getTextArea();
            if (textArea) {
                const start = textArea.selectionStart;
                const end = textArea.selectionEnd;
                const sel = textArea.value.substring(start, end);
                const value = textArea.value.substring(0, start) + '^2_' + sel + '^' + textArea.value.substring(end);
                this.onInputChange(index, value);
                textArea.focus();
            }
        }

        return <div className='trefa_text_edit_input_controls'>
            <div onClick={on_B_Click} className='trefa_text_edit_input_button'>
                <span>B</span>
            </div>
            <div onClick={on_H1_Click} className='trefa_text_edit_input_button'>
                <span>H1</span>
            </div>
            <div onClick={on_H2_Click} className='trefa_text_edit_input_button'>
                <span>H2</span>
            </div>
        </div>
    }

    onInputAdd = () => {

        let blocks = [...this.state.blocks];
        blocks.push(
            {
                type: 'input',
                formating: null
            }
        );

        this.setState({ blocks });
    }

    onImageAdd = () => {

        let blocks = [...this.state.blocks];
        blocks.push(
            {
                type: 'image',
                imgSrc: '',
                width: '100%',
                height: 'auto'
            }
        );

        this.setState({ blocks });
    }

    addedComponent = () => {
        return <div className='trefa_text_edit_added_component'>
            <div onClick={this.onInputAdd} className='trefa_text_edit_input_button'>
                <span>TEXT</span>
            </div>
            <div onClick={this.onImageAdd} className='trefa_text_edit_input_button'>
                <span>IMAGE</span>
            </div>
        </div>
    }

    deleteBlock = (index) => {
        const blocks_ = this.state.blocks;
        let blocks = [];
        for (let i = 0; i < blocks_.length; i++) {
            if (i !== index) {
                blocks.push({ ...blocks_[i] })
                continue;
            }
        }
        this.setState({ blocks })
    }

    setText = (text) => {
        const blocks = formingToBlocks(text);
        const formattingText = formingTrefaText(text);

        this.setState({ blocks, formattingText })
    }

    render() {

        const { blocks, formattingText } = this.state;
        const { buttonText, onButtonClick, buttonState, doNotShowTrefaText = false } = this.props;

        return <div className='trefa_text_edit_main_container'>
            <div className='trefa_text_edit_main_container_fields'>
                <div className='trefa_text_edit_left_container'>
                    {blocks.map((block, index) => {

                        const { type } = block;

                        if (type === 'input') {
                            const { value } = block;
                            return <this.inputComponent key={'tte-' + index} index={index} value={value} />
                        }
                        if (type === 'image') {
                            const { imgSrc, width, height } = block;
                            return <this.imageComponent key={'tte-' + index} index={index} {...{ imgSrc, width, height }} />
                        }
                        return null;

                    })}
                    <this.addedComponent />
                </div>

                {!doNotShowTrefaText && <TrefaText trefaText={formattingText} />}

            </div>

            {buttonText && <ButtonPlashka upperStyle={{ width: 180, height: 40 }} onClick={() => { onButtonClick(formattingText) }} text={buttonText} {...buttonState} />}

        </div>
    }

}