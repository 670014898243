import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';

/**
 * Это враппер, который обвешивается вокруг компонента,
 * принимает всякие штуки, чтобы вернуть список людей
 * schoolId, groupId, season, category, type
 */

class RaitingWrapper extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            Persons: null,
        };
    }

    allLoad = ({ schoolId, groupId, season, category, type }) => {

        if (!schoolId || !category) return this.setState({ Persons: null });
        this.setState({ Persons: 'loading' });
        this.props.client.getSubjectCompetitorsByTypes({ schoolId, groupId, season, category, type }).then((result) => {
            this.setState({ Persons: result });
        });

    }


    componentDidMount() {
        this.allLoad(this.props);
    }

    componentWillReceiveProps({ schoolId, groupId, season, category, type }) {
        if ((schoolId !== this.props.schoolId)
            || (groupId !== this.props.groupId)
            || (season !== this.props.season)
            || (category !== this.props.category)
            || (type !== this.props.type)
        ) {
            this.allLoad({ schoolId, groupId, season, category, type });
        }
    }

    render() {
        const {
            props: {
                Wrapped,
                personsDirect,
                componentProps
            },
            state: {
                Persons
            }
        } = this;

        return (
            <Wrapped {...{ Persons, personsDirect, ...componentProps }} />
        );
    }
}


export default withCapoeiraSportState('client')(RaitingWrapper);