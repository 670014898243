export default {
    island_1_liana: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_1_liana.png`,
        title: 'Кусок лианы',
        discription: 'Довольно большой кусок лианы, который Вы оторвали, пробирааясь сквозь проход.',
    },
    island_1_note: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_1_note.png`,
        title: 'Бумажка с рисунком',
        discription: 'На бумажке накорябан какай-то рисунок, похожий то ли на схему, то ли на карту.',
    },
    island_1_rock: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_1_rock.png`,
        title: 'Плоский камень',
        discription: 'Небольших размеров плоский камень, который почему-то Вам приглянулся',
    },
    island_1_machetebad: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_1_machetebad.png`,
        title: 'Тупой мачете',
        discription: 'Ржавый и тупой мачете, который Вы вытащили из скалы',
    },
    island_1_machetegood: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_1_machetegood.png`,
        title: 'Мачете',
        discription: 'Вероятно, этот мачете знавал и лушиие времене, но Вы хотя бы постарались его заточить',
    },
    island_1_board: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_1_board.png`,
        title: 'Крепкая доска',
        discription: 'Крепкая доска, которую Вы нашли возле гигантских скал',
    },
    island_1_flippers: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_1_flippers.png`,
        title: 'Ласты',
        discription: 'Самодельные ласты, которые вы соорудили из листвы и кусков лиан. И-и-и так сойдёт.',
    },
    island_1_leafs: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_1_leafs.png`,
        title: 'Крепкие листья',
        discription: 'Очень крепкие, но в меру гибкие листья. Где-нибудь пригодятся!',
    },
    island_1_pieces: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_1_pieces.png`,
        title: 'Куски лиан',
        discription: 'Небольшие куски лиан, который остались после Вашего триумфального выдёргивания мачете',
    },
    island_1_statua: {
        grade: 1, type: 'island', subtype: 'idea', src: `/../images/ver2/scores/island/island_1_statua.png`,
        title: 'Код статуи',
        discription: 'Пять затёртых символов, скорее всего это было какое-то слово',
    },
    island_1_face: {
        grade: 1, type: 'island', subtype: 'idea', src: `/../images/ver2/scores/island/island_1_face.png`,
        title: 'Загадка лица',
        discription: 'Восходит полумесяц, и засыпает орудие кузнеца. Вновь восходит полумесяц, засыпает и орудие рыбака.',
    },
    island_1_bro: {
        grade: 1, type: 'island', subtype: 'idea', src: `/../images/ver2/scores/island/island_1_bro.png`,
        title: 'Три брата',
        discription: 'ТРИ БРАТА УКАЖУТ ПУТЬ гласила надпись у входа в трещину башни',
    },
    island_1_path: {
        grade: 1, type: 'island', subtype: 'idea', src: `/../images/ver2/scores/island/island_1_path.png`,
        title: 'Путь во тьме',
        discription: 'Заученный наизусть маршрут, который Вам продиктовали три брата',
    },
    island_1_disk:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_1_disk.png`,
        title: 'Диск с письменами',
        discription: 'Вы соединили два диска в один, но значение написанных букв остаётся загадкой',
    },
    island_1_hole:  {
        grade: 1, type: 'island', subtype: 'idea', src: `/../images/ver2/scores/island/island_1_hole.png`,
        title: 'Загадка диска',
        discription: 'Странная головоломка, состоящая из цифр и букв',
    },
    island_1_diskpart1:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_1_diskpart1.png`,
        title: 'Большой диск с надписью',
        discription: 'На диске написано OLDUVREECCE. Что это значит вообще?',
    },
    island_1_diskpart2:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_1_diskpart2.png`,
        title: 'Малый диск с надписью',
        discription: 'На диске написаны буквы, Вы даже не понимаете с какой стороны это читать',
    },
    island_1_chok: {
        grade: 1, type: 'island', subtype: 'idea', src: `/../images/ver2/scores/island/island_1_chok.png`,
        title: 'Загадка в тоннеле',
        discription: '18 кнопок в три ряда. Ответ на поверхности.',
    },

    
    island_2_ballb:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_ballb.png`,
        title: 'Баскетбольный мяч',
        discription: 'Баскетбольный мяч, который Вы нашли под машиной',
    },
    island_2_ballf:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_ballf.png`,
        title: 'Футбольный мяч',
        discription: 'Футбольный мяч, который Вы нашли на волейбольной площадке',
    },
    island_2_ballw:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_ballw.png`,
        title: 'Волейбольный мяч',
        discription: 'Волейбольный мяч, который Вы достали с дерева',
    },
    island_2_basketring:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_basketring.png`,
        title: 'Баскетбольное кольцо',
        discription: 'Очень лёгкое, с небольшим креплением сбоку',
    },
    island_2_battery:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_battery.png`,
        title: 'Батарейка',
        discription: 'Мощь таится в этой маленькой штуке',
    },
    island_2_ffplafon:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_ffplafon.png`,
        title: 'Плафон со светлячками',
        discription: 'Маленькие букашки бьются о стекло и заставляют плафон ярко светиться',
    },
    island_2_magnet:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_magnet.png`,
        title: 'Самодельный магнит',
        discription: 'Вы собрали простейший электромагнит',
    },
    island_2_nail:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_nail.png`,
        title: 'Гвоздь',
        discription: 'Обычный гвоздь, ничем не примечателен',
    },
    island_2_otv:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_otv.png`,
        title: 'Отвёртка',
        discription: 'Небольшая отвёртка минус',
    },
    island_2_plafon:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_plafon.png`,
        title: 'Плафон',
        discription: 'Немного пыльный плафон',
    },
    island_2_pult:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_pult.png`,
        title: 'Пульт от телевизора',
        discription: 'Пульт от телевизора в номере',
    },
    island_2_setka:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_setka.png`,
        title: 'Кусок мелкой сетки',
        discription: 'Вы забрали её на волейбольном поле',
    },
    island_2_stick:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_stick.png`,
        title: 'Палка',
        discription: 'Большая палка, вытащенная из леса',
    },
    island_2_tapki:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_tapki.png`,
        title: 'Ваши шлепанцы',
        discription: 'Нашли Вы их случайно, как и потеряли до этого',
    },
    island_2_wire:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_wire.png`,
        title: 'Мотушка провода',
        discription: 'Отличный проводник, как Вы знаете',
    },
    island_2_wirenail:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_wirenail.png`,
        title: 'Гвоздь с проводом',
        discription: 'Плотно обмотанный куском провода гвоздь',
    },
    island_2_nosach:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_nosach.png`,
        title: 'Почти самодельный сачок',
        discription: 'Не хватает только палки, пока им мало что можно сделать',
    },
    island_2_sach:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_sach.png`,
        title: 'Сачок',
        discription: 'Самодельный сачок, какой же Вы мастер',
    },
    island_2_key:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_key.png`,
        title: 'Старинный ключ',
        discription: 'Старинный ключ. Интересно, что он открывает',
    },
    island_2_sphere:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_2_sphere.png`,
        title: 'Таинственная сфера',
        discription: 'Таинственная сфера. Светится и постоянно окутана туманом. Её можно применять в разных локациях.',
    },


    
    island_3_flashlight:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_3_flashlight.png`,
        title: 'Фонарик',
        discription: 'Мощный увесистый ручной фонарик. Еле помещается в руке. Если закончатся батареи - им можно колоть орехи.',
    },
    island_3_cryst:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_3_cryst.png`,
        title: 'Кристалл',
        discription: 'Небольшой кристалл бледно-зелёного цвета. Умеет впитывать в себя свет и испускать его.',
    },
    island_3_crystmoon:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_3_crystmoon.png`,
        title: 'Лунный кристалл',
        discription: 'Кристалл, заряженный лунным светом.',
    },
    island_3_rope:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_3_rope.png`,
        title: 'Верёвка',
        discription: 'Моток весьма крепкой на вид верёвки.',
    },
    island_3_spike:  {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_3_spike.png`,
        title: 'Лунный сталагмит',
        discription: 'Светящейся в лунном свете острый сталагмит.',
    },
    island_3_lock: {
        grade: 1, type: 'island', subtype: 'idea', src: `/../images/ver2/scores/island/island_3_lock.png`,
        title: 'Замок на двери',
        discription: 'На двери в пещере висит большой замок. Надо как-то его открыть.',
    },
    island_3_key: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_3_key.png`,
        title: 'Ключ',
        discription: 'Ключ, который отдал Вам Лисий шаман.',
    },
    island_3_warn: {
        grade: 1, type: 'island', subtype: 'idea', src: `/../images/ver2/scores/island/island_3_warn.png`,
        title: 'Отвлечь шамана',
        discription: 'Вам надо как-то отвлечь Шамана.',
    },
    island_3_catch: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_3_catch.png`,
        title: 'Листик с лисицей',
        discription: 'Листик с рунными знаками и рисунком лунной лисицы.',
    },
    island_3_wash: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_3_wash.png`,
        title: 'Листик с записью',
        discription: 'Листик с рунными знаками.',
    },
    island_3_box: {
        grade: 1, type: 'island', subtype: 'idea', src: `/../images/ver2/scores/island/island_3_box.png`,
        title: 'Схема',
        discription: 'На ваш взгляд это что-то вроде карты или схемы с указанем места.',
    },
    island_3_nuts: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_3_nuts.png`,
        title: 'Желуди',
        discription: 'Какие-то орешки, которые ела лунная лисица. Вы назваете их желудями.',
    },
    island_3_bufu: {
        grade: 1, type: 'island', subtype: 'idea', src: `/../images/ver2/scores/island/island_3_bufu.png`,
        title: 'Буфу',
        discription: 'Кто он такой? Надо это выяснить.',
    },
    island_3_thinkcatch: {
        grade: 1, type: 'island', subtype: 'idea', src: `/../images/ver2/scores/island/island_3_thinkcatch.png`,
        title: 'Поимка лисиц',
        discription: 'Лиса никогда не пойдёт туда, где её недавно пытались поймать. Обычно все гнёзда лис связаны в тройки, лиса перебегает по проходу даже если почует опасность в связанном гнезде. Есть несколько гнёзд только с одним входом и выходом. Лису заманивают в эту ловушку чем-то, не оставляя пути отступления.',
    },
    island_3_owltotem1: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_3_owltotem1.png`,
        title: 'I тотем Духа Совы',
        discription: 'Деревянный тотем Духа Совы',
    },
    island_3_flowers: {
        grade: 1, type: 'island', subtype: 'island', src: `/../images/ver2/scores/island/island_3_flowers.png`,
        title: 'Разноцветные цветы',
        discription: 'Цветы, которые Дух Совы поручил возложить к статуе',
    },
    island_3_schema: {
        grade: 1, type: 'island', subtype: 'idea', src: `/../images/ver2/scores/island/island_3_schema.png`,
        title: 'Схема на складе',
        discription: 'Странная схема, которую Вы запомнили на складе',
    },
    island_3_luck: {
        grade: 1, type: 'island', subtype: 'idea', src: `/../images/ver2/scores/island/island_3_luck.png`,
        title: 'Лисья удача',
        discription: 'У Вас ощущение самого удачливого человека на свете',
    },
    island_3_needle: {
        grade: 1, type: 'island', subtype: 'idea', src: `/../images/ver2/scores/island/island_3_needle.png`,
        title: 'Игла',
        discription: 'Иголка, которую Вы нашли в стоге сена',
    },
}



// {
//     "useItems": [
//         {
//             "itemName": "island_1_note",
//             "result": [
//                 { "type": "removeItem" },
//                 { "type": "addAction", "actionId": 6 },
//                 { "type": "moveToPoint", "pointId": 13 }
//             ]
//         }
//     ]
// }

// {
//     "useItems": [
//         {
//             "itemName": "island_1_liana",
//             "result": [
//                 { "type": "moveToPoint", "pointId": 15 }
//             ]
//         }
//     ]
// }

// {
//     "useItems": [
//         {
//             "itemName": "island_1_liana",
//             "result": [
//                 { "type": "moveToPoint", "pointId": 15 }
//             ]
//         }
//     ]
// }

// РЕЖЕМ ЛИАНЫ
// {
//     "useItems": [
//         {
//             "itemName": "island_1_machetebad",
//             "result": [
//                 { "type": "moveToPoint", "pointId": 18 }
//             ]
//         },
//         {
//             "itemName": "island_1_machetegood",
//             "result": [
//                 { "type": "removeItem" },
//                 { "type": "addAction", "actionId": 10 }
//             ]
//         }
//     ],
//         "changes": [
//             {
//                 "type": "yesaction",
//                 "actionId": 1,
//                 "data":
//                 {
//                     "Screen": "i1_8",
//                     "Text": "^a_^text^a_^Вы стоите у небольшого прохода. Сложно определить, он создан человеком или появился естественным образом, но он, без всяких сомнений, ведёт вглубь острова^n_^^n_^ Вы покромсали все лианы мачете, и теперь проход свободен"
//                 }
//             }
//         ]
// }

// {
//     "useItems": [
//         {
//             "itemName": "island_1_machetebad",
//             "result": [
//                 { "type": "moveToPoint", "pointId": 18 }
//             ]
//         },
//         {
//             "itemName": "island_1_machetegood",
//             "result": [
//                 { "type": "removeItem" },
//                 { "type": "addAction", "actionId": 10 }
//             ]
//         }
//     ]
// }

// ОТКРЫТИЕ ДВЕРИ
// {
//     "changes": [
//             {
//                 "type": "yesaction",
//                 "actionId": 11,
//                 "data":
//                 {
//                     "Screen": "i1_11",
//                     "Text": "^a_^text^a_^Вы открыли каменную дверь и прошли таким образом тестовое задание!^n_^^n_^Поздравляю!"
//                 }
//             }
//         ]
// }

// {
//     "useItems": [
//         {
//             "itemName": "island_1_statua",
//             "result": [             
//                 { "type": "moveToPoint", "pointId": 50 }
//             ]
//         },
//         {
//             "itemName": "island_1_face",
//             "result": [             
//                 { "type": "moveToPoint", "pointId": 51 }
//             ]
//         }
//     ]
// }