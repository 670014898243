import React from 'react';

export default () => <svg width="24.25mm" height="24.25mm"
    viewBox="0 0 2425 2425" >
    <g id="Слой_x0020_1">
        <g id="_2228864382368">
            <path class="ui_icon__fill" d="M64213 185544c-37,-4 -74,-6 -112,-6 -514,0 -931,416 -931,930 0,514 417,931 931,931 514,0 930,-417 930,-931 0,-122 -23,-238 -66,-345l-864 345 112 -924z" />
            <path class="ui_icon__fill" d="M64325 185398l-113 924 865 -345c-124,-310 -410,-537 -752,-579z" />
        </g>
        <g id="_2228864381184">
            <path class="ui_icon__fill" d="M1302 358c-37,-4 -74,-7 -112,-7 -514,0 -931,417 -931,931 0,514 417,931 931,931 514,0 930,-417 930,-931 0,-122 -23,-239 -66,-345l-864 345 112 -924z" />
            <path class="ui_icon__fill" d="M1414 212l-113 924 865 -345c-124,-310 -409,-537 -752,-579z" />
        </g>
    </g>
</svg>