// это стандартный тайтл, который везде используется
import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

/**
 * NumberText - title может быть как простым текстом, так и компонентом
 */

export const TITLE_HEIGHT = 24;

export const Title = ({ title, lowTitle, titleLinkTo, titleOnClick }) => {

    const upperFontSize = lowTitle ? 13 : 16;

    return <div style={{ height: TITLE_HEIGHT }} className='global_title'>
        {titleLinkTo ?
            <Link to = {titleLinkTo}>
                <div className='global_title_text' style={{ fontSize: upperFontSize, lineHeight: `${upperFontSize}px` }}>
                    {title}
                </div>
            </Link>
            :
            <div onClick = {titleOnClick} className= { titleOnClick ? 'global_title_text global_title_text_clicked' : 'global_title_text'} style={{ fontSize: upperFontSize, lineHeight: `${upperFontSize}px` }}>
                {title}
            </div>
        }
    </div>
}

export const TitledPlashka = ({ titleComponent, titleLinkTo, titleOnClick, children, upperStyle, lowTitle, notFromLayout }) => {

    const { width, height: height_ } = upperStyle;
    const height = notFromLayout ? height_ - TITLE_HEIGHT : height_;

    if (height < 0) return null;

    return <div style={{ width, height }} className='title_plashka'>
        <Title title={titleComponent} titleLinkTo={titleLinkTo} titleOnClick = {titleOnClick} lowTitle={lowTitle} />
        <div className='title_plashka_content' style={{ width, height: height - TITLE_HEIGHT }}>
            {children}
        </div>
    </div>
}