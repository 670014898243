// это прямоугольная штука, в которой сверху большое число, а снизу - небольшой текст
import React from 'react';
import './style.css';

/**
 * 
 * AvatarComponent - что то, что принмает в себя size
 */
export const NumberText = ({ count, text, height }) => {

    const upperFontSize = height / 1.4;
    const lowerFontSize = height / 4;

    return <div style={{ height, width: height * 2.3 }} className='numberText'>
        <div className='numberText_upper'>
            <div className='numberText_upper_text' style={{ fontSize: upperFontSize, lineHeight: `${upperFontSize}px` }}>
                {count}
            </div>
        </div>
        <div className='numberText_lower'>
            <div className='numberText_lower_text' style={{ fontSize: lowerFontSize, lineHeight: `${lowerFontSize}px` }}>
                {text}
            </div>
        </div>
    </div>
}
