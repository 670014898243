import React from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import Info from 'src/ver2/help/info';

const SomeInfo = ({ showInfoModal, className = '', size = '', infoKey, title, value, content, isMobile }) => {

    if (infoKey) {

        const Info_ = Info;
        console.log (Info_);
        const {title, value} = Info[infoKey];
        const content = title;

        return <div
            className={'global_info_text ' + className + ' ' + (size === 'mini' ? ' global_info_text_mini' : '')}
            onClick={() => showInfoModal({ type: 'some_info', title, value }, isMobile)}
        >
            {content}
        </div>
    }

    return <div
        className={'global_info_text ' + className + ' ' + (size === 'mini' ? ' global_info_text_mini' : '')}
        onClick={() => showInfoModal({ type: 'some_info', title, value }, isMobile)}
    >
        {content}
    </div>
}


export default withCapoeiraSportState('client, showInfoModal')(SomeInfo);