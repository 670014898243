// это посто сама по себе аввтарка чувака
import React, { Fragment } from 'react';

import SchoolAvatar from 'src/ver2/components/school/avatar';

import SchoolWrapper from 'src/ver2/components/school/schoolWrapper';
import Loader from 'src/ver2/components/loader';


import './style.css';

/*

    Мини-штука, показывающая прямоугольную плашку с школой

*/

const SchoolPlaceRectMini_ = ({ School, height = 100, place, downedContent }) => {

    const width = height * 0.75;

    if (!School) return <div style={{ height, width }} className='school_place_rect_mini'>
        <Loader />
    </div>

    const {
        Id, Name
    } = School;

    let style = { height, width };
    if (place === 1) {
        style.backgroundImage = `url('https://capoeirasport.com/graphics/gold.jpg')`;
        style.border = `2px solid #f7ff66`;
        style.filter = `drop-shadow( 0px 0px 0.45rem #fffb00dd )`;
    }
    if (place === 2) {
        style.backgroundImage = `url('https://capoeirasport.com/graphics/silver.png')`;
        style.border = `2px solid #ffffff`;
        style.filter = `drop-shadow( 0px 0px 0.45rem #5f9ea0dd )`;
    }
    if (place === 3) {
        style.backgroundImage = `url('https://capoeirasport.com/graphics/bronze.jpg')`;
        style.border = `2px solid #e7722e`;
        style.filter = `drop-shadow( 0px 0px 0.45rem #e7722edd )`;
    }
    return <div style={style} className='school_place_rect_mini'>
        <div style={{ height: height * 0.75, width }} className='school_place_rect_mini_upper'>
            <SchoolAvatar size={height * 0.68} {...{ schoolId: Id }} />
        </div>
        {
            downedContent ? 
            <>
                <div style={{ height: height * 0.13, width }} className='school_place_rect_mini_downed'>
                    <span>{Name}</span>
                </div>
                <div style={{ height: height * 0.12, width }} className='school_place_rect_mini_downed'>
                    {downedContent}
                </div>
            </>
            :
                <div style={{ height: height * 0.25, width }} className='school_place_rect_mini_downed'>
                    <span>{Name}</span>
                </div>
        }

    </div>

}

const componentWrapped = (props) => <SchoolWrapper Wrapped={SchoolPlaceRectMini_} schoolId={props.schoolId} componentProps={{ ...props }} />
export const SchoolPlaceRectMini = componentWrapped;