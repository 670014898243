import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import './style.css';

//getEventById

/**
  Это плашка, которая отображает кнопку
 */

export default class ButtonPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { upperStyle, linkTo, onClick, text, type, disabled, disabledText, disabledDiscription, style = {} } = this.props;

        const { width, height } = upperStyle;

        let className = 'ui_button_plashka global_bg_main';
        if (type === 'dashed') className += ' ui_button_plashka_type_dashed';
        if (type === 'danger') className += ' ui_button_plashka_type_danger';
        if (disabled) className += ' ui_button_plashka_type_disabled';
        if (disabled && disabledDiscription) className += ' global-tooltip';

        if (linkTo && !disabled) {
            return <Link to={linkTo}>
                <div style={{ width, height, ...style }} className={className}>
                    <div className='ui_button_plashka_text'>
                        {text}
                    </div>
                </div>
            </Link>
        }

        return <div
            data-tooltip={disabledDiscription}
            style={{ width, height, ...style }}
            onClick={disabled ? null : onClick}
            className={className}
        >
            <div className='ui_button_plashka_text'>
                {disabledText || text}
            </div>
        </div>
    }

}

// export default withCapoeiraSportState('client')(EventLittleListPlashka);