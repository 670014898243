import React, { PureComponent } from 'react';
import PersonWrapper from 'src/ver2/components/person/personWrapper.js';
import Loader from 'src/ver2/components/loader';
import PlaceRect from 'src/ver2/containers/lowlavel/placeRect';
import EventAvatar from 'src/ver2/components/event/avatar';
import { Link } from 'react-router-dom';

import './style.css';

/**
 * Эта плашка показывает рейтинг конкретного спортсмена и где он выступал
 * 
 */

class PersonRaitingHistoryPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    shouldComponentUpdate({ Person, RaitingHistory, upperStyle }) {
        if (Person !== this.props.Person) return true;
        if (RaitingHistory !== this.props.RaitingHistory) return true;
        if (upperStyle.height !== this.props.upperStyle.height) return true;
        return false;
    }

    render() {

        const { RaitingHistory, setComponentSelfHeight, plashkaId } = this.props;

        if (!RaitingHistory) return <div className='person_raiting_history_plashka'>
            <Loader />
        </div>

        if (!RaitingHistory.length) {
            setComponentSelfHeight({ plashkaId, height: 1 });
            return null;
        }

        // setComponentSelfHeight({ plashkaId, height: 48 + 240 * Videos.slice(0, 2).length });

        const { width, height } = this.props.upperStyle;

        let Answer = [];

        let thisSeason = '';

        for (let i = 0; i < RaitingHistory.length; i++) {
            const currentRaiting = RaitingHistory[i];
            const { Season } = currentRaiting;
            if (thisSeason !== Season) {
                thisSeason = Season;
                if (i !== 0) {
                    Answer.push(<div className='person_raiting_history_plashka__blank' />);
                }
                Answer.push(<this.RaitingSeasonHeader key={'prhphe_' + i} Season={Season} />);
            }

            Answer.push(
                <this.OneRaitingBlock key={'prhp_' + i} currentRaiting={currentRaiting} />
            )

        }

        setComponentSelfHeight({ plashkaId, height: 48 + 40 * Answer.length });

        return <div style={{ width, height }} className='person_raiting_history_plashka'>
            <this.RaitingHeader />
            {Answer}
        </div>
    }

    RaitingHeader = () => {

        const { isMobile } = this.props;
        
        const height = isMobile ? 10 : 25;
        const fontSize =isMobile ? height * 0.3 : height * 0.7;

        return <div className='person_raiting_history_plashka__oneraiting person_raiting_history_plashka__oneraiting_header'>

            <div className='person_raiting_history_plashka__oneraiting_avatar'>
            </div>
            <div style={{ fontSize }} className='person_raiting_history_plashka__oneraiting_competition'>
            </div>
            <div style={{ fontSize }} className='person_raiting_history_plashka__oneraiting_category'>
            </div>

            <div className='person_raiting_history_plashka__oneraiting_place'>
                <span>Место</span>
            </div>
            <div className='person_raiting_history_plashka__oneraiting_scores'>
                <span>Очки</span>
            </div>
        </div>
    }

    RaitingSeasonHeader = ({ Season }) => {

        const { isMobile } = this.props;

        const height = isMobile ? 10 : 45;
        const fontSize = height * 0.5;

        return <div className='person_raiting_history_plashka__oneraiting'>

            <div className='person_raiting_history_plashka__oneraiting_avatar'>
            </div>
            <div style={{ fontSize }} className='person_raiting_history_plashka__oneraiting_competition person_raiting_history_plashka__oneraiting_season'>
                <span>СЕЗОН {Season}</span>
            </div>
            <div style={{ fontSize }} className='person_raiting_history_plashka__oneraiting_category'>
            </div>

            <div style={{ fontSize }} className='person_raiting_history_plashka__oneraiting_place'>
            </div>
            <div style={{ fontSize }} className='person_raiting_history_plashka__oneraiting_scores'>
            </div>
        </div>
    }

    OneRaitingBlock = ({ currentRaiting }) => {

        /*
CategoryId: 709
CategoryName: "Женщины ПРО"
CompetitionId: 40
EventId: 76
IsPro: 1
Place: 1
Score: 50
Season: "2021-2022"
Title: "Чемпионат и Первенство Москвы 2021"
UserId: 83
        */

        const { UserId, Place, CategoryId, CategoryName, Score, Title, EventId } = currentRaiting;

        
        const { isMobile } = this.props;
        const height = isMobile ? 17 : 25;

        const fontSize = height * 0.65;
        let className = 'person_raiting_history_plashka__oneraiting person_raiting_history_plashka__oneraiting_row ';

        let PlaceCurrect = Place;
        if (Place === 4) PlaceCurrect = '4-8';

        if (Place > 4) PlaceCurrect = `${ Math.pow(2, Place - 2) + 1} - ${Math.pow(2, Place - 2)*2}`

        // if (Place === 5) PlaceCurrect = '9-16';
        // if (Place === 6) PlaceCurrect = '17-32';
        // if (Place === 7) PlaceCurrect = '17-32';

        return <div className={className}>

            <div className='person_raiting_history_plashka__oneraiting_avatar'>
                <EventAvatar size={20} eventId={EventId} />
            </div>
            <div style={{ fontSize }} className='person_raiting_history_plashka__oneraiting_competition'>
                <Link to={`/event/${EventId}/?page=results`}>
                    <span>{Title}</span>
                </Link>
            </div>
            <div style={{ fontSize }} className='person_raiting_history_plashka__oneraiting_category'>
                <Link to={`/event/${EventId}/?page=shadule&categoryId=${CategoryId}&lookedPersonId=${UserId}`}>
                    <span>{CategoryName}</span>
                </Link>
            </div>
            <div className='person_raiting_history_plashka__oneraiting_place'>
                <div className='person_raiting_history_plashka__oneraiting_place_inside'>
                    <PlaceRect {...{ height, Place, PlaceName: PlaceCurrect }} />
                </div>
            </div>
            <div style={{ fontSize }} className='person_raiting_history_plashka__oneraiting_scores'>
                <span>{Score}</span>
            </div>
        </div >
    }

}

const PersonRaitingHistoryPlashkaWrapped = (props) => <PersonWrapper Wrapped={PersonRaitingHistoryPlashka} personId={props.personId} withRaitingHistory={true} componentProps={{ ...props }} />
export default PersonRaitingHistoryPlashkaWrapped;