import React from 'react';

export default () => <svg width="24.25mm" height="24.25mm"
    viewBox="0 0 2425 2425" >
    <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_1479168262736">
            <g>
                <line class="ui_icon__fill" x1="567.99" y1="1016.59" x2="670.79" y2="598.08" />
                <line class="ui_icon__fill" x1="884.62" y1="598.08" x2="822.94" y2="1016.59" />
                <line class="ui_icon__fill" x1="1098.45" y1="598.08" x2="1077.89" y2="1016.59" />
                <line class="ui_icon__fill" x1="1312.27" y1="598.08" x2="1332.83" y2="1016.59" />
                <line class="ui_icon__fill" x1="1526.1" y1="598.08" x2="1587.78" y2="1016.59" />
                <line class="ui_icon__fill" x1="1739.93" y1="598.08" x2="1842.73" y2="1016.59" />
                <path class="ui_icon__fill" d="M313.04 1016.59l254.95 0 0 94.89c0,71.24 -57.36,129.54 -127.48,129.54l0 0c-70.11,0 -127.47,-58.29 -127.47,-129.54l0 -94.89z" />
                <path class="ui_icon__fill" d="M822.94 1016.59l254.94 0 0 94.89c0,71.24 -57.36,129.54 -127.47,129.54l0 0c-70.11,0 -127.47,-58.29 -127.47,-129.54l0 -94.89z" />
                <path class="ui_icon__fill" d="M1332.83 1016.59l254.95 0 0 94.89c0,71.24 -57.36,129.54 -127.47,129.54l0 0c-70.12,0 -127.48,-58.29 -127.48,-129.54l0 -94.89z" />
                <path class="ui_icon__fill" d="M1842.73 1016.59l254.95 0 0 94.89c0,71.24 -57.36,129.54 -127.47,129.54l0 0c-70.12,0 -127.48,-58.29 -127.48,-129.54l0 -94.89z" />
                <polygon class="ui_icon__fill" points="567.99,1016.59 670.79,598.08 518.72,598.08 456.96,598.08 313.04,1016.59 372.18,1016.59 " />
                <polygon class="ui_icon__fill" points="884.62,598.08 822.94,1016.59 1077.89,1016.59 1098.45,598.08 " />
                <polygon class="ui_icon__fill" points="1312.27,598.08 1332.83,1016.59 1587.78,1016.59 1526.1,598.08 " />
                <polygon class="ui_icon__fill" points="1739.93,598.08 1842.73,1016.59 2038.54,1016.59 2097.68,1016.59 1953.76,598.08 1871.74,598.08 " />
                <path class="ui_icon__fill" d="M625.81 1636.8l-57.82 -433.69 0.29 0c-0.2,-2.92 -0.29,-5.85 -0.29,-8.81 0,71.24 -57.36,129.54 -127.48,129.54 -14.86,0 -29.15,-2.62 -42.44,-7.43l60.29 505.55 20.35 170.56 1453.3 0 16.24 -136.11 64.41 -540c-13.3,4.81 -27.59,7.43 -42.46,7.43 -70.11,0 -127.47,-58.29 -127.47,-129.54 0,2.96 -0.09,5.89 -0.29,8.81l0.29 0 -12.02 90.19 -4.26 31.96 -2.75 20.59 -4.26 31.95 -45.78 343.36 -12.42 93.15 -1111.76 0 -13.23 -99.21 -2.33 -17.52 -1.62 -12.08 -2.54 -19.09 -3.95 -29.61z" />
            </g>
            <polygon class="ui_icon__fill" points="946.07,1767.92 1145.26,1320.38 684.08,1320.38 724.1,1620.5 727.37,1645.09 729.48,1660.94 730.82,1670.98 732.77,1685.53 743.74,1767.92 " />
            <polygon class="ui_icon__fill" points="1192.74,1320.38 993.55,1767.92 1044.45,1767.92 1243.64,1320.38 " />
            <polygon class="ui_icon__fill" points="1291.12,1320.38 1091.93,1767.92 1666.98,1767.92 1677.3,1690.56 1715.3,1405.43 1718.85,1378.9 1721.13,1361.8 1724.66,1335.27 1726.64,1320.38 " />
        </g>
    </g>
</svg>