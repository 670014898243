
import { getElementsScoresByJudging_arrays, getElementsScoresByJudgingOptions, parseSEquenceOptions } from 'src/ver2/containers/challenges/helpers';
import { groupBy, orderBy } from 'lodash';

export const SequenceProcessor = ({ Sequences = [], client }) => {

    // статистика по используемым элементам
    let UsedElements = {};
    let Sequencity = {
        max: 0,
        avarage: 0
    }

    const elements = client.elements_as_object;
    const SeqObjByStatus = groupBy(Sequences, 'Status');
    const ComnirmedSequences = SeqObjByStatus.confirm || [];

    // всё количество использованных элементов
    let elementsUsed = 0;

    // всё количество коэффициентов
    let performPoints = 0;

    // записываем связности
    let SequencityArray = [];

    // записываем очки
    let PointsArray = [];

    let allStrike = 0;
    let allFlow = 0;
    let allJump = 0;
    let allFreeze = 0;
    /*
    allFlow : 136
allFreeze: 57
allJump: 135
allStrike: 54
    */

    let TierTypesMax = {
        1: {
            allStrike: 0,
            allFlow: 0,
            allJump: 0,
            allFreeze: 0
        },
        2: {
            allStrike: 0,
            allFlow: 0,
            allJump: 0,
            allFreeze: 0
        },
        3: {
            allStrike: 0,
            allFlow: 0,
            allJump: 0,
            allFreeze: 0
        }
    }

    // Object.keys(elements).forEach(element_key => {
    //     const { type, subtypes, Tier, Points } = elements[element_key] || {};
    //     if (subtypes) {
    //         if (subtypes.find(x => x === 'flow')) TierTypesMax[Tier].allFlow += Points;
    //         if (subtypes.find(x => x === 'jump')) TierTypesMax[Tier].allJump += Points;
    //         if (subtypes.find(x => x === 'freeze')) TierTypesMax[Tier].allFreeze += Points;
    //     }
    //     if (type === 'strike') { TierTypesMax[Tier].allStrike += Points; }
    // });

    let SequencesCount = ComnirmedSequences.length;

    const Points = ComnirmedSequences.reduce((memo, sequence) => {

        const { JudgeOptions, Points } = sequence;
        // берём одну связку
        const { sequence_elements, sequence_options } = parseSEquenceOptions({ sequence })
        const { scores_element, scores_seq } = getElementsScoresByJudgingOptions({ sequence_elements, JudgeOptions });
        const elements_scores = getElementsScoresByJudging_arrays({ client, sequence_elements, scores_element, scores_seq, sequence_options })

        let JumpPoints = memo.JumpPoints;
        let AttackPoints = memo.JumpPoints;

        let SequenceSum = 0;
        // прочёсываем каждый элемент связки
        for (let j = 0; j < elements_scores.length; j++) {

            elementsUsed++;

            const { PerformPoints, SequencePoints, BasicPoints, element_key } = elements_scores[j];

            // добавляем в список используемых элементов
            if (!UsedElements[element_key]) UsedElements[element_key] = {
                element_key,
                usedCount: 0,
                goodCount: 0,
                SumPoints: 0,
                MaxPoints: 0,
                avaragePoints: 0,
                avarageKoef: 0,
                MaxKoef: 0,
                isGood: false,
                Tier,
                Sequences: [],
                BasicPoints
            }

            UsedElements[element_key].usedCount++;

            const perfKoef = PerformPoints / BasicPoints;
            if (perfKoef > 0.8) UsedElements[element_key].goodCount++;

            // добавляем связку и коэф
            UsedElements[element_key].Sequences.push({ perfKoef, sequence });

            if (UsedElements[element_key].MaxPoints < PerformPoints) UsedElements[element_key].MaxPoints = PerformPoints;
            UsedElements[element_key].SumPoints += PerformPoints;
            SequenceSum += SequencePoints;
            performPoints += PerformPoints / BasicPoints;

            const { Tier } = elements[element_key] || {};
            UsedElements[element_key].Tier = Tier;
        }

        // так вот хитрожопо считается связность связки
        SequenceSum = SequenceSum / (elements_scores.length - 2);
        if (SequenceSum > Sequencity.max) Sequencity.max = SequenceSum;

        SequencityArray.push(SequenceSum);
        PointsArray.push(Points);

        return {
            SequenceSum: memo.SequenceSum + SequenceSum,
            Points: memo.Points + Points,
            JumpPoints,
            AttackPoints
        }
    }
        , { SequenceSum: 0, Points: 0, JumpPoints: 0, AttackPoints: 0 });

    // пересчитываем поэлементно
    const elements_keys = Object.keys(UsedElements);

    let T1 = 0;
    let T2 = 0;
    let T3 = 0;

    let Pool = 0;

    let Diversity = 0;
    let DiversityGood = 0;
    const idealCountPerElement = elementsUsed / elements_keys.length;

    let PointsStatistics = 0; //Points.Points / ComnirmedSequences.length;
    let SequencityStatistics = 0; //Points.SequenceSum / ComnirmedSequences.length;

    PointsArray.sort(function (a, b) {
        return b - a;
    });
    let Points_ = 0;
    for (let i = 0; i < Math.min(10, PointsArray.length); i++) Points_ += PointsArray[i];
    PointsStatistics = Points_ / 10;

    SequencityArray.sort(function (a, b) {
        return b - a;
    });
    let Seq_ = 0;
    for (let i = 0; i < Math.min(10, SequencityArray.length); i++) Seq_ += SequencityArray[i];
    SequencityStatistics = Seq_ / 10;


    let PerformStatistics = performPoints / elementsUsed;

    for (let i = 0; i < elements_keys.length; i++) {
        const element_key = elements_keys[i];
        // дальше делаем что нужно и как нужно сортируем
        UsedElements[element_key].avaragePoints = UsedElements[element_key].SumPoints / UsedElements[element_key].usedCount;
        UsedElements[element_key].avarageKoef = UsedElements[element_key].avaragePoints / UsedElements[element_key].BasicPoints;
        UsedElements[element_key].MaxKoef = UsedElements[element_key].MaxPoints / UsedElements[element_key].BasicPoints;
        UsedElements[element_key].isGood = (UsedElements[element_key].goodCount / UsedElements[element_key].usedCount) >= 0.45;

        if (UsedElements[element_key].isGood) {
            const { Tier } = UsedElements[element_key];
            if (Tier === 1) T1++;
            if (Tier === 2) T2++;
            if (Tier === 3) T3++;
            Pool++;
        }

        if ((UsedElements[element_key].usedCount + 0.3) >= idealCountPerElement) {
            DiversityGood++;
        }

        const { type, subtypes, Tier } = elements[element_key] || {};
        if (subtypes) {
            if (subtypes.find(x => x === 'flow')) {allFlow += UsedElements[element_key].MaxPoints; TierTypesMax[Tier].allFlow += UsedElements[element_key].MaxPoints;}
            if (subtypes.find(x => x === 'jump')) {allJump += UsedElements[element_key].MaxPoints; TierTypesMax[Tier].allJump += UsedElements[element_key].MaxPoints;}
            if (subtypes.find(x => x === 'freeze')) {allFreeze += UsedElements[element_key].MaxPoints; TierTypesMax[Tier].allFreeze += UsedElements[element_key].MaxPoints;}
        }
        if (type === 'strike') { allStrike += UsedElements[element_key].MaxPoints; TierTypesMax[Tier].allStrike += UsedElements[element_key].MaxPoints; }
    }

    Diversity = DiversityGood / elements_keys.length;


    // средняя связность
    Sequencity.avarage = Points.SequenceSum / ComnirmedSequences.length;

    const FinalStatistics = {
        T1,
        T2,
        T3,

        Pool,

        Diversity,
        PointsStatistics,
        SequencityStatistics,
        PerformStatistics,

        allStrike,
        allFlow,
        allFreeze,
        allJump,
        TierTypesMax,

        SequenceSum: Points.SequenceSum,
        SequencesCount,
        AllPoints: Points.Points,
        SequencityMax: Sequencity.max
    }

    console.log(
        'СТАТИСТИКА СВЯЗОК',
        {
            Points,
            Sequencity,
            UsedElements,
            FinalStatistics
        }
    )

    return {
        Points,
        Sequencity,
        UsedElements,
        FinalStatistics
    }

}