import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { groupBy } from 'lodash';

/**
 * Это враппер, который обвешивается вокруг компонента,
 * принимает personId (кого брать)
 * withMedals - загружать ли медали
 * doNotLoadRaiting - НЕ НАДО загружать его рейтинг
 * doLoadKubokRaiting - подгружать ли рейтинги других сезонов
 * component - то куда запихивать
 * componentProps - он в component пихает все пропсы, которые нужны ему
 * 
 * и когда загрузит нужного Person - закидывает его как объект
 */

/*
 
   Загрузка Scores, Personalization, и загрузка Items:
   Scores: 
   [
       {
           Type: 'fun',
           All: 3200, // сколько всего
           Free 500 // сколько свободных для покупок или еще чего-то
       }
   ]
 
   Personalization: {
       UpperIcon: 'raposa' // перед ником
       
   }

*/


class PersonWrapper extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            Person: null,
            Raiting: null, // это конкретно рейтинг именно в этот сезон
            KubokRaiting: null, // это, наоборот, все рейтинги кроме текущего сезона где есть 1-3 места
            Medals: null,
            Videos: null,
            PortalRoles: null,
            RaitingHistory: null,
            Scores: null,
            Money: null,
            Items: null,
            itemsStatus: 'loading',
            fkrAccred: false,
            isBirthday: false
        };
    }

    allLoad = (id = null) => {

        const { doNotLoadPerson, doNotLoadRaiting, withMedals, withFkrAccred,
            withVideos, withPortalRoles, withRaitingHistory,
            withBirthday, withScores, withItems } = this.props;
        const personId = id || this.props.personId;

        if (!personId) return;

        // есть такая херня, когда надо просто прокинуть personId, например
        if (!doNotLoadPerson)
            this.loadPerson(personId);


        // это подгружает его текущий рейтинг в идущем сейчас сезоне
        if (!doNotLoadRaiting) {
            this.loadRaiting(personId);
        }
        if (withMedals)
            this.loadMedals(personId);

        if (withFkrAccred)
            this.loadFkrAccred(personId)

        if (withVideos)
            this.loadVideos(personId);

        if (withPortalRoles)
            this.loadPortalRoles(personId);

        if (withRaitingHistory)
            this.loadRaitingHistory(personId);

        if (withBirthday)
            this.loadIsBirthday(personId);

        if (withScores)
            this.loadScores(personId);

        if (withItems)
            this.loadItems(personId);

    }

    loadPerson = (id) => {
        if (!id) return;
        const { WithTel } = this.props;
        this.props.client.getUserFromServer(id, WithTel ? { WithTel: true } : {}).then((result) => {
            this.setState({ Person: result });
        });
    }

    // это подгружает его текущий рейтинг в идущем сейчас сезоне
    loadRaiting = (id) => {
        if (!id) return;
        const { doLoadKubokRaiting } = this.props;
        const { nowRaiting } = this.props.client;
        this.props.client.getSubjectRaitingFromServer({ subject: 'user', id, season: nowRaiting }).then((Raiting) => {
            this.setState({ Raiting });
        });

        if (doLoadKubokRaiting) {
            this.props.client.getSubjectRaitingFromServer({ subject: 'user', id }).then((Raiting) => {

                let KubokRaiting = [];

                if (!Raiting) return;

                for (let i = 0; i < Raiting.length; i++) {
                    const { Place, Season, Type } = Raiting[i];
                    if (Place >= 1 && Place <= 3 && Type === 'comp_pro' && Season !== nowRaiting) {
                        KubokRaiting.push({
                            type: 'kubok',
                            value: {
                                season: Season,
                                place: Place,
                                raiting: Type
                            }
                        });
                    }
                }

                this.setState({ KubokRaiting });
            });
        }



    }

    loadMedals = (id) => {
        if (!id) return;
        this.props.client.getUserMedals(id).then((Medals) => {
            this.setState({ Medals });
        });
    }

    loadFkrAccred = (id) => {
        if (!id) return;
        this.props.client.getUserFkrAccred(id).then((fkrAccred) => {
            if (fkrAccred)
                this.setState({ fkrAccred });
        });
    }

    loadVideos = (id) => {
        if (!id) return;
        this.props.client.getUserVideosFromServer(id).then((Videos) => {
            this.setState({ Videos });
        });
    }

    loadPortalRoles = (id) => {
        if (!id) return;
        this.props.client.getUserPortalRoles(id).then((PortalRoles) => {
            this.setState({ PortalRoles });
        });
    }

    loadRaitingHistory = (id) => {
        if (!id) return;
        this.props.client.getUserRaitingHistoryFromServer(id).then((RaitingHistory) => {
            this.setState({ RaitingHistory: RaitingHistory ? RaitingHistory.reverse() : [] });
        });
    }

    loadIsBirthday = async (id) => {
        const { client } = this.props;
        const birthdays = await client.state.birthdays;
        if (!birthdays || birthdays.length === 0) return null;
        const iam = birthdays.find(x => x.Id === id);
        if (iam) {
            this.setState({ isBirthday: true });
        }
    }

    loadScores = async (id) => {
        const { client } = this.props;
        const scoresResult = await client.getUserScores(id);
        if (!scoresResult) return null;
        let ScoresGroup = groupBy(scoresResult, 'Label');
        let Scores = {};
        let Money = {};

        for (let i = 0; i < Object.keys(ScoresGroup).length; i++) {
            const ScoreObj = ScoresGroup[Object.keys(ScoresGroup)[i]][0];
            if (ScoreObj.Type === 'scores') {
                Scores[Object.keys(ScoresGroup)[i]] = ScoreObj;
            }
            if (ScoreObj.Type === 'money') {
                Money[Object.keys(ScoresGroup)[i]] = ScoreObj;
            }
        }

        this.setState({ Scores, Money });
    }


    /*
        С сервера подгружаются ITEMS, и разбиваются по категориям stuff berimbao ...
        При этом дописываются их свойства (типа размер, вместимость, грейд и т.д.) с client
    */

    loadItems = async (id) => {
        const { client } = this.props;
        const itemsResult = await client.getAllUserItems(id);
        if (!itemsResult) return this.setState({ Items: {}, itemsStatus: null });

        let items = {};

        // теперь мы в каждый дописываем абстрактный объект с сервера
        for (let i = 0; i < itemsResult.length; i++) {
            const abstrItem = client.getAbstractItem(itemsResult[i].Name);
            if (abstrItem) {
                const { type } = abstrItem;
                if (!items[type]) items[type] = [];

                const { Modyfied } = itemsResult[i];
                if (Modyfied) {

                    const ModyfiedObj = JSON.parse(Modyfied);

                    items[type].push({
                        ...abstrItem,
                        ...itemsResult[i],
                        ModyfiedObj,
                        name: itemsResult[i].Name
                    })
                } else
                    items[type].push({
                        ...abstrItem,
                        ...itemsResult[i],
                        name: itemsResult[i].Name
                    })


                if (type === 'stuff') {

                    const { Id, IsOn } = itemsResult[i];

                    items[type][items[type].length - 1].uid = Id;
                    if (IsOn && IsOn !== 'false') {
                        const IsOnObj = JSON.parse(IsOn);
                        if (IsOnObj && "x" in IsOnObj && "y" in IsOnObj) {
                            items[type][items[type].length - 1].x = IsOnObj.x;
                            items[type][items[type].length - 1].y = IsOnObj.y;
                            items[type][items[type].length - 1].isUsed = true;
                        }
                    }
                }

            }
        }

        this.setState({ Items: items, itemsStatus: 'loaded' });
    }

    componentDidMount() {
        this.allLoad();
    }

    componentWillReceiveProps({ personId, birthdaysLoaded, iamuser }) {

        if (this.props.iamuser && this.props.iamuser.Id === this.props.personId) {
            if (iamuser !== this.props.iamuser) {
                this.loadPerson(personId);
            }
        }

        if (personId !== this.props.personId) {
            this.allLoad(personId);
        }
        if (birthdaysLoaded !== this.props.birthdaysLoaded && this.props.withBirthday) {
            this.loadIsBirthday(this.props.personId)
        }
    }

    render() {
        const {
            props: {
                Wrapped,
                componentProps
            },
            state: {
                Person,
                Raiting,
                KubokRaiting,
                Medals,
                Videos,
                PortalRoles,
                RaitingHistory,
                Scores,
                Money,
                Items,
                itemsStatus,
                isBirthday,
                fkrAccred
            }
        } = this;

        return (
            <Wrapped {...{ Person, Raiting, KubokRaiting, Medals, Videos, PortalRoles, RaitingHistory, isBirthday, Scores, Money, Items, itemsStatus, fkrAccred, ...componentProps }} />
        );
    }
}


export default withCapoeiraSportState('client, iamuser, birthdaysLoaded')(PersonWrapper);