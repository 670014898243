import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { TitledPlashka } from 'src/ver2/containers/lowlavel/title';
import CordaoRect from 'src/ver2/components/cordao/cordaoRect';
import { values } from 'lodash';

import './style.css';

//getEventById

/**
 * Эта плашка выводит список корд школы или группы
 */

class CordaoTablePlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        GradationCountAsObject: {}
    }

    componentDidMount() {
        this.setGradationCountAsObject();
    }

    componentDidUpdate({ GradationCount }) {
        if (GradationCount !== this.props.GradationCount) {
            this.setGradationCountAsObject();
        }
    }

    setGradationCountAsObject = () => {
        const { GradationCount } = this.props;
        if (!GradationCount || !GradationCount.length) return null;

        const GradationCountAsObject = GradationCount.reduce(
            (memo, item) => {
                return { ...memo, [item.cordao]: item.count }
            },
            {}
        )

        this.setState({
            GradationCountAsObject
        })
    }

    render() {

        const { School, upperStyle, cord, linkTo, isMobile } = this.props;
        const { GradationCountAsObject } = this.state;

        const { Gradation, Id } = School || { Gradation: {}, Id: 0 }

        // if (!(keyToShow === 0)) {
        //     Raiting = this.state.loadedRating;
        // }

        const { width, height } = upperStyle;

        console.log('КОРДАО!!!!', this.props);

        const widthCordao = isMobile ? width * 0.17 : width * 0.14;

        let CordaoSeparators = {};

        if (String(Id) === '1') {
            CordaoSeparators = {
                'mestre': { Name: 'Взрослые старшие', Title: '' },
                'aluno forca': { Name: 'Взрослые младшие', Title: '' },
                'kids graduado': { Name: 'Детские старшие', Title: '' },
                'kids aluno 1': { Name: 'Детские младшие', Title: '' }
            }
        }

        if (String(Id) === '15') {
            CordaoSeparators = {
                'mestre': { Name: 'Взрослые пояса (с 12 лет)', Title: '' },
                'adaptacao': { Name: 'Пояс адаптации', Title: '' },
                'infantil 6': { Name: 'Детские пояса (с 4 лет)', Title: '' }
            }
        }

        if (String(Id) === '28') {
            CordaoSeparators = {
                'young 3': { Name: 'Детские пояса 4-6 лет', Title: '' },
                'kids 10': { Name: 'Детские пояса 7-12 лет', Title: '' },
                'mestre 3': { Name: 'Взрослые пояса (от 13 лет)', Title: '' },
            }
        }

        if (String(Id) === '35') {
            CordaoSeparators = {
                'young 3': { Name: 'Детские пояса до 7 лет', Title: '' },
                'kids 9': { Name: 'Детские пояса 7-14 лет', Title: '' },
                'grange mestre': { Name: 'Взрослые пояса (от 14 лет)', Title: '' },
            }
        }

        if (String(Id) === '38') {
            CordaoSeparators = {
                'kids 8 melh': { Name: 'Детские до 18 лет', Title: '' },
                'mestre': { Name: 'Взрослые', Title: '' },
            }
        }

        if (String(Id) === '39') {
            CordaoSeparators = {
                'kids 6': { Name: 'Детские пояса', Title: '' },
                'adult 7': { Name: 'Взрослые пояса', Title: '' },
            }
        }

        return <TitledPlashka titleComponent={<this.RaitingSwitch />} upperStyle={upperStyle}>
            <div className='cordao_subject_table'>
                {

                    Object.keys(Gradation).reverse().reduce((memo, cordaoname, index) => {

                        if (CordaoSeparators[cordaoname])
                            return [...memo, <div className='cordao_subject_table-separator'> {CordaoSeparators[cordaoname].Name} </div>,
                            <CordaoRect
                                key={'cordao_' + Id + '_' + index}
                                width={widthCordao}
                                SchoolId={Id}
                                Cordao={cordaoname}
                                count={GradationCountAsObject ? GradationCountAsObject[cordaoname] : null}
                                selected={cord === cordaoname}
                                linkTo={linkTo('cord', cordaoname)}
                            />
                            ]

                        return [...memo,
                        <CordaoRect
                            key={'cordao_' + Id + '_' + index}
                            width={widthCordao}
                            SchoolId={Id}
                            Cordao={cordaoname}
                            count={GradationCountAsObject ? GradationCountAsObject[cordaoname] : null}
                            selected={cord === cordaoname}
                            linkTo={linkTo('cord', cordaoname)}
                        />
                        ]
                    }, [])
                }
            </div>
        </TitledPlashka>

    }

    RaitingSwitch = () => {

        const vars = [
            // 'градация',
            // 'состав'
        ]

        const { changeWatchKey, keyWatchAll } = this.props;
        return <div style={{ display: 'flex', flexDirection: 'row' }}>
            {vars.map((value, index) => {
                return <div onClick={() => { changeWatchKey(index) }} className={'cordao_subject_table-switch-item ' + (keyWatchAll === index ? 'cordao_subject_table-switch-item__selected' : '')} >
                    {value}
                </div>
            })}
        </div>
    }

    /*
                    <CordaoRect
                        width={height * 0.45}
                        SchoolId={SchoolId}
                        Cordao={Cordao}
                    />
    */

}

export default withCapoeiraSportState('iamuser, client, isMobile')(CordaoTablePlashka);