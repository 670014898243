import * as THREE from 'three';

export const createMedal = ( textureSchool, textureMaterial ) => {

      /*Хуйня */

    const parent_Mesh =  new THREE.Mesh(new THREE.BoxGeometry(0, 0, 0), new THREE.MeshBasicMaterial({
        color: "#FFFFFF00",
        wireframe: true
    }));

    const geometry = new THREE.CylinderBufferGeometry(
    3, 3, 0.2, 55);        

    const material = new THREE.ShaderMaterial (
    {
        uniforms: {
        u_texture: {value: textureSchool},
        u_gold: {value: textureMaterial},
        Time: {value: 0},
        goldy : {value: 0}
        },
        fragmentShader: document.getElementById("spaceTestFragmentShader2").innerHTML, 
        vertexShader: document.getElementById("spaceTestVertexShader").innerHTML
    }
    )

    material.transparent = true;

    const mesh = new THREE.Mesh(geometry, material);

    mesh.rotation.y = 1.6;
    mesh.rotation.x = 1.6;   


    const geometry2 = new THREE.RingBufferGeometry(
    3, 3.5, 55);

        

    const material2 = new THREE.MeshPhongMaterial (
        {
        map: textureMaterial,
        shininess: 2,
        }
    )

    const mesh2 = new THREE.Mesh(geometry2, material2);


    parent_Mesh.add(mesh);
    parent_Mesh.add(mesh2);


    return parent_Mesh;

}

export const createBoxMedal = ( textureSchool, textureMaterial ) => {

    /*Хуйня */

  const parent_Mesh =  new THREE.Mesh(new THREE.BoxGeometry(0, 0, 0), new THREE.MeshBasicMaterial({
      color: "#FFFFFF00",
      wireframe: true
  }));

  const geometry = new THREE.CylinderBufferGeometry(
    2.5, 2.5, 0.2, 55);  

  const material = new THREE.ShaderMaterial (
  {
      uniforms: {
      u_texture: {value: textureSchool},
      u_gold: {value: textureMaterial},
      Time: {value: 0},
      goldy : {value: 0}
      },
      fragmentShader: document.getElementById("spaceTestFragmentShader2").innerHTML, 
      vertexShader: document.getElementById("spaceTestVertexShader").innerHTML
  }
  )

  material.transparent = true;

  const mesh = new THREE.Mesh(geometry, material);

  mesh.rotation.y = 1.6;
  mesh.rotation.x = 1.6;   
  mesh.position.z = 0.1; 


  const geometry2 = new THREE.PlaneBufferGeometry(
  6, 6, 30, 30);

      

  const material2 = new THREE.MeshPhongMaterial (
      {
      map: textureMaterial,
      shininess: 2,
      }
  )

  const mesh2 = new THREE.Mesh(geometry2, material2);
  material2.transparent = true;

  parent_Mesh.add(mesh);
  parent_Mesh.add(mesh2);


  return parent_Mesh;

}