
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';

import './styles.css';

/* 


*/
const ItemIconInused_ = ({ client, Name, style }) => {
    const item = client.getAbstractItem(Name);
    const { srcUsed } = item;

    return <img src={srcUsed} style={style} />
}




export const ItemIconInused = withCapoeiraSportState('client')(ItemIconInused_);