export default {
    // РАСШИРИТЕЛИ
    larger_1: {
        grade: 3, type: 'paint', subtype: 'larger', src: `/../images/ver2/scores/paint/larger_1.png`,
        title: 'Расширитель 1',
        discription: 'Расширение стены до 9 ячеек по горизонтали. Надо применить в предметах после покупки.',
        CELLS_X: 9,
        CELLS_Y: 13      
    },    
    larger_2: {
        grade: 4, type: 'paint', subtype: 'larger', src: `/../images/ver2/scores/paint/larger_2.png`,
        title: 'Расширитель 2',
        discription: 'Расширение стены до 10 ячеек по горизонтали. Надо применить в предметах после покупки.',
        CELLS_X: 10,
        CELLS_Y: 15      
    },
}