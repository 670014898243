import React from 'react';

export default () => <svg width="24.25mm" height="24.25mm"
    viewBox="0 0 2425 2425" >
    <g id="Слой_x0020_1">
        <path class="ui_icon__fill" d="M720 1213l-85 117 -85 -117 -86 -118 171 0 170 0 -85 118zm1313 -247l-1641 0c-52,0 -95,35 -95,76l0 341c0,41 43,76 95,76l1641 0c52,0 95,-35 95,-76l0 -341c0,-41 -43,-76 -95,-76z" />
        <path class="ui_icon__fill" d="M720 587l-85 118 -85 -118 -86 -118 171 0 170 0 -85 118zm1313 -246l-1641 0c-52,0 -95,34 -95,76l0 340c0,42 43,76 95,76l1641 0c52,0 95,-34 95,-76l0 -340c0,-42 -43,-76 -95,-76z" />
        <path class="ui_icon__fill" d="M720 1838l-85 118 -85 -118 -86 -118 171 0 170 0 -85 118zm1313 -246l-1641 0c-52,0 -95,34 -95,76l0 340c0,42 43,76 95,76l1641 0c52,0 95,-34 95,-76l0 -340c0,-42 -43,-76 -95,-76z" />

    </g>
</svg>