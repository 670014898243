// это прямоугольная штука, в которой сверху большое число, а снизу - небольшой текст
import React from 'react';
import './style.css';

/**
 * Это штука, которая показывает место, и подсвечивает, если надо золото, серебро и т.д.
 */
const PlaceRect = ({ PlaceName, Place, height, asMedal, isBlank, style = {} }) => {

    const fontSize = (PlaceName && PlaceName.length > 3) ? height * 0.3 : height * 0.6;

    return <div style={{ fontSize, height: height * 0.95, width:  height * 0.95 , minWidth: height * 0.95, ...style }}
        className={'place_rect '
            + `place_rect_place_${Place} ` + (asMedal ? 'place_rect_as_medal ' : '') + ((asMedal && !PlaceName) ? 'place_rect_as_medal_blank' : '' )
            + (isBlank ? 'place_rect_blank' : '')
            }>
        <span>
            {`${ (asMedal && !PlaceName) ? '' : PlaceName}`}
        </span>
    </div>
}

export default PlaceRect;
