export default {
    // СУНДУКИ
    chest_gray_1: {
        grade: 1, type: 'chest', src: `/../images/ver2/scores/chest/chest_gray_1.png`,
        title: 'Серый сундук сокровищ',
        discription: 'Небольшой базовый сундук сокровищ',
    },
    chest_gray_2: {
        grade: 2, type: 'chest', src: `/../images/ver2/scores/chest/chest_gray_2.png`,
        title: 'Серый сундук сокровищ',
        discription: 'Небольшой продвинутый сундук сокровищ',
    },
}