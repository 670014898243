// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';
import { Tabs, message } from 'antd';
import { withCapoeiraSportState } from 'src/ver2/context';
import { withPersonState } from 'src/ver2/pages/person/context';

import DataChanger, { compareFunction } from 'src/ver2/containers/dataChanger';
import ImageUploader from 'src/ver2/containers/imageChanger';

import SportChanger from 'src/ver2/containers/sportChanger';
import Loader from 'src/ver2/components/loader';

const TabPane = Tabs.TabPane;

/**
 * 
 * Это компонент смены данных для пользователя
 */

const PersonDataChange = ({ iamuser, client, updateMyData, isMobile, dataFlag, isRegionDataReady, RegionData, setRegionData }) => {


    const setSport = async (data) => {
        // мы подаём заявку
        const { Rank } = data;
        await client.addApplication({ type: 'sport', values: Rank })
    }
    const setJudge = async (data) => {
        // мы подаём заявку
        const { Judge } = data;
        await client.addApplication({ type: 'judge', values: Judge })
    }

    const onPrivateData = async (PrivateData) => {

        // тут у нас функция сравнения, которая заберёт только то, что поменялось
        let resultSend = compareFunction(iamuser, PrivateData);

        if (Object.keys(resultSend).length > 0) {

            if (resultSend.GroupIamInId && resultSend.GroupIamInId === '0') {
                resultSend.GroupIamInId = null;
            }

            updateMyData(resultSend).then(() => {
                message.success('Изменения приняты');
            })
        } else {
            message.warn('Нет изменений')
        }
    }

    const uploadUserAvatar = async (data) => {
        const result = await client.updateAvatarUser(data);
        if (result) {
            message.success('Автар изменён, перегрузите страницу');
        } else {
            message.warn('Ошибка на стороне сервера');
        }
    }

    const uploadRegion = async ({ Region }) => {
        setRegionData(Region);
        return;
    }

    const onPassChange = async ({ Login, Password, NewPassword}) => {
        const UserId = iamuser.Id;
        const result = await client.changeUserPassword({ Login, Password, NewPassword, UserId});
        if ( result ) {
            message.success('Изменения приняты');
        } else {
            message.error('Неверные данные');
        }
    }

    let currentRegion = null;
    let GroupRegion = null;
    let ComponentRegionData = { Region: 'null' };
    if (isRegionDataReady && RegionData) {
        const {
            UserRegions,
            NowSeason
        } = RegionData;

        GroupRegion = (RegionData.GroupRegion && RegionData.GroupRegion.Region) ? RegionData.GroupRegion.Region : null;

        if (GroupRegion) {
            currentRegion = GroupRegion;
        }

        if (UserRegions && UserRegions.length) {
            const nowRegion = UserRegions.find(x => x.Season === NowSeason);
            // это значит, что указан регоин отдельно у пользователя в этом сезоне
            if (nowRegion && nowRegion.Region) {
                currentRegion = nowRegion.Region;
                ComponentRegionData.Region = currentRegion;
            }
        }

    }

    return <div className='person_data_change'>
        <Tabs className='person_data_change_tabs' tabPosition={isMobile ? 'top' : 'left'} style={{ color: 'white', heightMin: 500 }} defaultActiveKey={dataFlag}>

            <TabPane tab={'Личные'} key="1">
                <DataChanger
                    header={<div>
                        Введите свои личные данные
                    </div>}
                    data={iamuser}
                    buttonText={'ИЗМЕНИТЬ'}
                    buttonState={'allNeeded'}
                    onButtonClick={onPrivateData}
                    components={[
                        {
                            name: 'Name',
                            title: 'Имя',
                            type: 'input',
                            neededText: ' '
                        },
                        {
                            name: 'Surname',
                            title: 'Фамилия',
                            text: 'Введите свою фамилию',
                            type: 'input',
                            neededText: ' '
                        },
                        {
                            name: 'Patronymic',
                            title: 'Отчество',
                            type: 'input',
                            text: 'Ваше отчество не будет отображаться, но необходимо для составления документов на соревнованиях',
                            neededText: ' '
                        },
                        {
                            name: 'Apelido',
                            title: 'Апелидо',
                            text: 'Апелидо - это прозвище, дающееся в ряде школ капоэйристам. Необязательное поле',
                            type: 'input',
                            nextGroup: { title: '' }
                        },
                        {
                            name: 'BirthDay',
                            title: 'Дата рождения',
                            type: 'date',
                            nextGroup: { title: '' },
                            neededText: ' '
                        },
                        {
                            name: 'City',
                            title: 'Город проживания',
                            type: 'input',
                            text: 'Нужно только для статистики',
                        }
                    ]}
                />
            </TabPane>
            <TabPane tab={'Пароль'} key="11">
                <DataChanger
                    header={<div>
                        Смена пароля
                    </div>}
                    data={{Login: '', Password: '', NewPassword: ''}}
                    buttonText={'ИЗМЕНИТЬ'}
                    buttonState={'allNeeded'}
                    onButtonClick={onPassChange}
                    components={[
                        {
                            name: 'Login',
                            title: 'Логин',
                            text: 'Ваш текущий логин',
                            type: 'input',
                            nextGroup: { title: '' },
                            neededText: ' '
                        },
                        {
                            name: 'Password',
                            title: 'Пароль',
                            text: 'Ваш текущий пароль',
                            type: 'input',
                            nextGroup: { title: '' },
                            neededText: ' '
                        },
                        {
                            name: 'NewPassword',
                            title: 'Новый пароль',
                            type: 'input',
                            neededText: ' '
                        }
                    ]}
                />
            </TabPane>
            <TabPane tab={'Контакты'} key="2">
                <DataChanger
                    header={<div>
                        Введите свои контактные данные <br />
                        В ряде соревнований и мероприятий нельзя участвовать без указания контактных данных быстрой связи на тот случай,
                        если организаторам нужно будет связаться с вами.
                    </div>}
                    data={iamuser}
                    buttonState={'allNeeded'}
                    buttonText={'ИЗМЕНИТЬ'}
                    onButtonClick={onPrivateData}
                    components={[
                        {
                            name: 'Email',
                            title: 'E-mail',
                            type: 'input',
                            neededText: ' ',
                            nextGroup: { title: 'Контактные данные быстрой связи' }
                        },
                        {
                            name: 'Instagram',
                            title: 'Инастаграм',
                            type: 'input',
                        },
                        {
                            name: 'VK',
                            title: 'ВКонтакте',
                            type: 'input',
                        },
                        {
                            name: 'Tel',
                            title: 'Телефон',
                            type: 'input',
                            text: 'Ваш телефон будет доступен только организаторам мероприятий и администрации Портала',
                        },
                    ]}
                />
            </TabPane>
            <TabPane tab={'Аватарка'} key="3">
                <ImageUploader
                    imageUrl={client.getUsersUrl() + iamuser.Id + '.png'}
                    header='Установка аватара своего профиля'
                    recomendations='Добавьте квадратную аватарку'
                    upload={uploadUserAvatar} />
            </TabPane>
            <TabPane tab={'Школа'} key="4">
                <DataChanger
                    header={<div>
                        Выберите школу и кордао.
                    </div>}
                    data={iamuser}
                    buttonState={'allNeeded'}
                    buttonText={'ИЗМЕНИТЬ'}
                    onButtonClick={onPrivateData}
                    components={[
                        {
                            name: 'SchoolId',
                            title: 'Школа',
                            type: 'schools',
                            neededText: ' ',
                            text: 'Если вы не состоите ни в какой школе, или вашей школы нет на портале, выберите последний вариант ---'
                        },
                        {
                            name: 'Cordao',
                            title: 'Кордао',
                            type: 'cordaos',
                            neededText: ' ',
                            text: 'Если у все еще нет кордао - выберите последний вариант ---'
                        }
                    ]}
                />
            </TabPane>
            <TabPane tab={'Группа'} key="5">
                <DataChanger
                    header={<div>
                        Вы можете выбрать группу, если знаете её название. <br />
                        Если Вы не уверены, то лучше спросить про группу у своего инструктора.
                    </div>}
                    data={iamuser}
                    buttonState={'all'}
                    buttonText={'ДАЛЕЕ'}
                    onButtonClick={onPrivateData}
                    SchoolId={iamuser.SchoolId}
                    components={[
                        {
                            name: 'GroupIamInId',
                            title: 'Группа',
                            type: 'groups',
                            text: 'Если вы не состоите в группе, оставьте пустым, или выберите Нет группы'
                        }
                    ]}
                />
            </TabPane>
            <TabPane tab={'Регион'} key="9">
                {isRegionDataReady ?
                    <DataChanger
                        header={<div>
                            Текущий регион, от которого Вы выступаете: {client.getRegionName(currentRegion)}  <br />
                            {GroupRegion ? `Регион Вашей группы: ${client.getRegionName(GroupRegion)}` : null}
                        </div>}
                        data={ComponentRegionData}
                        buttonState={'all'}
                        buttonText={'УСТАНОВИТЬ'}
                        onButtonClick={uploadRegion}
                        components={[
                            {
                                name: 'Region',
                                title: 'Регион',
                                type: 'regions',
                                text: GroupRegion ? 'Если вы состоите в группе, но выступаете от другого региона, иначе оставьте поле НЕ УКАЗАН' : 'Укажите свой регион, или вступите в группу'
                            }
                        ]}
                    />
                    : <Loader text='Данные о регионе' />}
            </TabPane>
            <TabPane tab={'Спорт. категория'} key="7">
                <SportChanger
                    header={<div>
                        Выберите разряд, которым вы обладаете, и подайте заявку
                    </div>}
                    data={iamuser}
                    onSave={setSport}
                    saveText={'Подать заявку'}
                    type={'sport'}
                    neededParamName = 'Rank'
                    userId={iamuser.Id}
                    components={[
                        {
                            name: 'Rank',
                            title: 'Спортивный разряд',
                            type: 'sport',
                            neededText: ''
                        }
                    ]}
                />
            </TabPane>
            <TabPane tab={'Суд. категория'} key="6">
                <SportChanger
                    header={<div>
                        Выберите судейскую категорию, которой вы обладаете, и подайте заявку
                    </div>}
                    data={iamuser}
                    onSave={setJudge}
                    saveText={'Подать заявку'}
                    type={'judge'}
                    userId={iamuser.Id}
                    neededParamName = 'Judge'
                    components={[
                        {
                            name: 'Judge',
                            title: 'Судейская категория',
                            type: 'judge',
                            neededText: ''
                        }
                    ]}
                />
            </TabPane>

        </Tabs>
    </div>
}

export default withPersonState(`
  iamuser, getText, client, updateMyData, isMobile,
  isRegionDataReady, RegionData, setRegionData
`
)(PersonDataChange);

// export default withCapoeiraSportState('iamuser, getText, client, updateMyData, isMobile')(PersonDataChange);