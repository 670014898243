import React, { PureComponent } from 'react';
import Loader from 'src/ver2/components/loader';
import { withCapoeiraSportState } from 'src/ver2/context';
import { eventStatus, isTherePayment } from 'src/ver2/pages/event/context';
import { AdmissionBlock } from 'src/ver2/components/event/admissionBlock';

import InlargeEventRow from 'src/ver2/components/event/inlarge';

import { Tooltip } from 'antd';
import { orderBy } from 'lodash';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

import './style.css';

//getEventById

/**
  Это плашка показывает, в каких мероприятиях вы планируете принять участие.

 */

class PersonMyEventsList extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            dateStart: '01.09.2024',
            dateEnd: '01.09.2025',
            AllEvents: [],
            AllPreliminarilyEvents: [],
            status: 'loading'
        }

        this.oneHeight = 50;
        if (props.isMobile) this.oneHeight = 50;
    }

    /*
            const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: height + 45, plashkaId });
    */

    componentDidMount() {
        if (this.props.eventsLoaded) {
            this.initialLoad();
        }
    }

    componentDidUpdate({ eventsLoaded }) {
        if (!eventsLoaded && this.props.eventsLoaded) {
            this.initialLoad();
        }
    }

    initialLoad = () => {

        // console.log('calendar initial load');

        const { client, iamuser } = this.props;
        client.getEventsByRange(
            moment(this.state.dateStart, 'DD.MM.YYYY').format('YYYYMMDD'),
            moment(this.state.dateEnd, 'DD.MM.YYYY').format('YYYYMMDD')
        ).then(async (Events) => {

            // console.log('calendar initial load', Events);
            // сначала делаем препроцесс и убираем всё, что не опубликовано
            let AllEvents = [];
            let AllPreliminarilyEvents = [];
            for (let i = 0; i < Events.length; i++) {

                const { Id, NotAnnounsed } = Events[i];

                if (NotAnnounsed) {
                    continue;
                }

                // не окончено ли событие
                if (eventStatus(Events[i]) === 'past') continue;

                if (iamuser && iamuser.myParticipation && iamuser.myParticipation[Id]) {
                    if (Events[i].IsPreliminarily) {
                        AllPreliminarilyEvents.push({ ...Events[i] })
                    } else
                        AllEvents.push({ ...Events[i] })
                } else
                    if (iamuser && iamuser.myCompetitor && iamuser.myCompetitor[Id]) {
                        if (Events[i].IsPreliminarily) {
                            AllPreliminarilyEvents.push({ ...Events[i] })
                        } else
                            AllEvents.push({ ...Events[i] })
                    } else
                        if (iamuser && iamuser.myFollows && iamuser.myFollows.event && iamuser.myFollows.event[Id]) {
                            if (Events[i].IsPreliminarily) {
                                AllPreliminarilyEvents.push({ ...Events[i] })
                            } else
                                AllEvents.push({ ...Events[i] })
                        }


            }

            this.setState({
                AllEvents,
                AllPreliminarilyEvents,
                status: 'loaded'
            })

            const allHeight = (AllEvents.length + AllPreliminarilyEvents.length) * this.oneHeight;

            const { setComponentSelfHeight, plashkaId } = this.props;
            setComponentSelfHeight({ height: allHeight + 65, plashkaId });

        })
    }


    render() {

        const { upperStyle } = this.props;
        const { status, AllEvents, AllPreliminarilyEvents } = this.state;


        const { width, height } = upperStyle;

        if (status === 'loading') return <div style={{ width, height }} className='person_my_events_plashka'>
            <Loader />
        </div>

        if (!AllEvents.length && !AllPreliminarilyEvents.length) return <div style={{ width, height }} className='person_my_events_plashka'>
            <div className='global_plashka_no_data'>
                <Tooltip placement='top' title='тут будут данные по вашим регистрациям на мероприятия'>
                    <span>
                        НЕТ АКТУАЛЬНЫХ МЕРОПРИЯТИЙ
                    </span>
                </Tooltip>
            </div>
        </div>


        return <div style={{ width, height, rowGap: this.oneMargin }} className='person_my_events_plashka'>
            {AllEvents.map((event) => {
                const { Id } = event;

                let isTherePayment_ = null;
                let lastBlock = 'БЕСПЛАТНО';

                if ( eventStatus(event) === 'registration_not_open' ) {
                    lastBlock = '';
                }else
                if (event && event.Settings) {
                    const SettingsObj = JSON.parse(event.Settings);
                    isTherePayment_ = isTherePayment({ SettingsObj });

                    // тут нам надо узнать, есть ли у нас оплата по данному чуваку

                    if (isTherePayment_) {
                        const { iamuser } = this.props;
                        if (iamuser && iamuser.myParticipationData[Id] && iamuser.myParticipationData[Id].find(x => x.Type === 'payment')) {
                            lastBlock = <AdmissionBlock title={'Оплата'} checked={true} height={this.oneHeight * 0.8} />
                        } else {
                            lastBlock = <AdmissionBlock title={'Оплата'} checked={false} cross={true} height={this.oneHeight * 0.8} />
                        }
                    }

                }

                return <InlargeEventRow key={'pmlp' + Id} Event={event} height={this.oneHeight} lastBlock={lastBlock} />
            })}
            {AllPreliminarilyEvents.map((event) => {
                const { Id } = event;
                return <InlargeEventRow key={'pmlp' + Id} Event={event} height={this.oneHeight} />
            })}
        </div>
    }




}

export default withCapoeiraSportState('client, iamuser, eventsLoaded, isMobile')(PersonMyEventsList);