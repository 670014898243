
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { Tooltip } from 'antd';

import './style.css';

/* 

    Мы отправляем сюда 
    moneyType, 
    Count, 
    onClick

*/

class MoneyComponent extends PureComponent {

    render() {

        const { type, amount, size, showInfoModal, onClick, client, noAmount } = this.props;
        let style = {}

        const onClick_ = onClick ? onClick : () => {
            showInfoModal({ type: 'money', value: { type } });
        }

        const money = client.moneyTextByType(type);

        return <div className='money_component' style={{ width: size, height: size }}>
            <Tooltip title={money.title}>
                <img onClick={onClick_} src={`/../images/ver2/scores/money/${type}.png`} {...{ style }} />
            </Tooltip>
            {noAmount ? null : <div className='money_component_amount'>{amount}</div>}
        </div>
    }

}


export default withCapoeiraSportState('client, showInfoModal')(MoneyComponent);