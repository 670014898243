import React, { Component } from 'react';
import TrefaTextEdit from 'src/ver2/components/trefaText/edit';
/*
    ChildrenComponent - какой компонент использовать
    CholdrenProps - что туда прокинуть
    onCallback - функция, которую вызывать при триггере компонента
    callbackName - имя функции, которую тригерить у компонента
    callbackParam - имя параметра, в котором к компоненты улетит callbackStatus
*/

export class InfoAdder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callbackStatus: ''
        }
    }

    onCallback = (data) => {
        const { onCallback } = this.props;
        this.setState({ callbackStatus: 'loading' });
        onCallback(data).then(() => {
            this.setState({ callbackStatus: '' });
        })
    }

    render() {
        const { callbackStatus } = this.state;
        const { settedRef } = this.props;
        return <TrefaTextEdit ref={settedRef} buttonText={'Добавить'} buttonState={{ disabled: callbackStatus === 'loading', disabledText: 'Добавить' }} onButtonClick={this.onCallback} />
    }

}

export class InfoChanger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callbackStatus: ''
        }
    }

    onCallback = (data) => {
        const { onCallback } = this.props;
        this.setState({ callbackStatus: 'loading' });
        onCallback(data).then(() => {
            this.setState({ callbackStatus: '' });
        })
    }

    render() {
        const { text, settedRef } = this.props;
        const { callbackStatus } = this.state;
        return <TrefaTextEdit ref={settedRef} text={text} buttonText={'Сохранить'} buttonState={{ disabled: callbackStatus === 'loading', disabledText: 'Сохранение' }} onButtonClick={this.onCallback} />
    }

}