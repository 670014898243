export const LANGUAGE = {
    calendarTitle: {
       rus: 'КАЛЕНДАРЬ',
       eng: 'CALENDAR'
    },

    schoolsTitle: {
        rus: 'ШКОЛЫ',
        eng: 'SCHOOLS'
     },


    schoolsChooseSchool: {
        rus: 'Выберите школу',
        eng: 'Choose the school'
     },

     schoolsChooseGroup: {
        rus: 'Выберите группу',
        eng: 'Choose the group'
     },

     schoolsNoRegistred: {
        rus: 'нет зарегистрированных представтелей',
        eng: 'there are no registered representatives'
    },

    schoolsDev: {
        rus: 'Раздел в разработке',
        eng: 'Section in development'        
    },

    schoolsAll: {
        rus: 'ВСЕГО',
        eng: 'TOTAL'
    },

    schoolsMembers: {
        rus: 'Состав',
        eng: 'Members'
    },
    
    schoolsJudges: {
        rus: 'Судьи',
        eng: 'Judges'
    },

    schoolsGradation: {
        rus: 'Градация',
        eng: 'Gradation'
    },

    


    contactsTitle: {
        rus: 'КОНТАКТЫ',
        eng: 'CONTACTS'
     },
     contactsText: {
        rus: 'Все идеи и прежложение можно отправить сюда:',
        eng: 'All ideas and suggestions can be sent here:'
     },
     contactsChushev: {
        rus: 'Чушев Сергей',
        eng: 'Chushev Sergey'
     },


     registrationProfile: {
        rus: 'Открыть профиль',
        eng: 'Open profile'
     },

     registrationExit: {
        rus: 'Выход',
        eng: 'Exit'
     }   ,

     registrationLogin: {
        rus: 'Логин:',
        eng: 'Login:'
     } ,

     registrationPassword: {
        rus: 'Пароль:',
        eng: 'Password:'
     } ,

     registrationEnter: {
        rus: 'Войти',
        eng: 'Log in'
     } ,

     registrationForgot: {
        rus: 'Забыли',
        eng: 'Forgot'
     } ,

     registrationRegistration: {
        rus: 'Регистрация',
        eng: 'Registration'
     }    


     ,

     personRank: {
        rus: 'Разряд:',
        eng: 'Rank:'
     }   ,

     personScores: {
        rus: 'Очки:',
        eng: 'Scores:'
     },

     personScoresPRO: {
        rus: 'Очки ПРО:',
        eng: 'Scores PRO:'
     } ,

     personScoresJudge: {
        rus: 'Судейская категория:',
        eng: 'Judge category:'
     } ,

     personSchool: {
        rus: 'Школа:',
        eng: 'School:'
     } ,

     personCordao: {
        rus: 'Кордао:',
        eng: 'Cordao:'
     } ,

     personGroup: {
        rus: 'Группа:',
        eng: 'Group:'
     } ,

     personEdit: {
        rus: 'Редактировать данные',
        eng: 'Edit profile'
     } ,

     personAvatar: {
        rus: 'Смена аватарки',
        eng: 'Change avatar'
     } ,

     personGroups: {
        rus: 'Ваши группы',
        eng: 'Your groups'
     } ,

     personPassword: {
        rus: 'Смена пароля',
        eng: 'Change password'
     },





     
     menuABOUTU: {
        rus: 'О ПРОЕКТЕ',
        eng: 'ABOUT'
     } ,
     menuCALENDARU: {
        rus: 'КАЛЕНДАРЬ',
        eng: 'CALENDAR'
     }  ,
     menuSCHOOLSU: {
        rus: 'ШКОЛЫ',
        eng: 'SCHOOLS'
     } ,
     menuCONTACTSU: {
        rus: 'КОНТАКТЫ',
        eng: 'CONTACTS'
     } ,
     menuRAITINGU: {
        rus: 'РЕЙТИНГ',
        eng: 'RAITING'
     } ,


     menuABOUT: {
        rus: 'О проекте',
        eng: 'About'
     } ,
     menuCALENDAR: {
        rus: 'Календарь',
        eng: 'Calendar'
     }  ,
     menuSCHOOLS: {
        rus: 'Школы',
        eng: 'Schools'
     } ,
     menuCONTACTS: {
        rus: 'Контакты',
        eng: 'Contacts'
     } ,
     menuRAITING: {
        rus: 'Рейтинг',
        eng: 'Raiting'
     } ,



     persregistrationEnter: {
        rus: 'Заполните все обязательные поля',
        eng: 'Enter all fields'
     } ,

     persregistrationTitle: {
        rus: 'Регистрация',
        eng: 'Registration'
     },

     persregistrationLogin: {
        rus: 'Логин',
        eng: 'Login'
     },

     persregistrationPassword: {
        rus: 'Пароль',
        eng: 'Password'
     },

     persregistrationName: {
        rus: 'Имя',
        eng: 'Name'
     },

     persregistrationSurname: {
        rus: 'Фамилия',
        eng: 'Surname'
     },

     persregistrationGender: {
        rus: 'Пол',
        eng: 'Gender'
     },

     persregistrationFemale: {
        rus: 'Женский',
        eng: 'Female'
     },

     persregistrationMale: {
        rus: 'Мужской',
        eng: 'Male'
     },

     persregistrationBirthday: {
        rus: 'День рождения',
        eng: 'Birthday'
     },

     persregistrationSchool: {
        rus: 'Ваша школа',
        eng: 'Your school'
     },

     persregistrationCordao: {
        rus: 'Ваш кордао',
        eng: 'Your cordao'
     },
     
     persregistrationSchoolFirst: {
        rus: 'Сначала выберите школу',
        eng: 'Select school first'
     },
     
     persregistrationApelido: {
        rus: 'Апелидо (не обязательно)',
        eng: 'Apelio (not requared)'
     },

     persregistrationRegistr: {
        rus: 'Зарегистрироваться',
        eng: 'Sign up'
     },

     

     newsTitle: {
      rus: 'НОВОСТИ',
      eng: 'NEWS'
   }

}