import React, { Component } from 'react';
import { Input, Select } from 'antd';
import ButtonPlashka from 'src/ver2/plashki/ui/button';

import LittleApplication from 'src/ver2/components/application/little';

import { withCapoeiraSportState } from 'src/ver2/context';

import './style.css';


const { Option } = Select;

/**
 * 
 * Это контейнер изменения данных
 * Сюда приходит некий массив данных (data)
 * 
 * при этом сюда приходит набор компонентов - это один value
 * components [
 *      {
 *          neededText, // если undefined - то это не нужно, может быть '' или текст о том, почему необходим
 *          name // то что мы будем брать из данных (Surname)
 *          title // (Фамилия)
 *          text // введите свою фамилию
 *          type // input, select, checkbox, cordao, schools и т.д.
 *          options 
 *          group: {name, title} // если после этого компонента идёт разделитель
 *          
 *      }
 * ]
 * 
 * onSave - это функция, в которую передаётся весь стейт и далее что-то делается
 * 
 * 
 */

class SportChanger extends Component {

    constructor(props) {
        super(props);
        this.state = {
            application: null
        }
    }

    // перекидываем все values в стейт
    initialAction = () => {
        const { components, data } = this.props;

        let newState = {};

        for (let i = 0; i < components.length; i++) {
            const component = components[i];
            const { name } = component;
            newState[name] = data[name] || null;
        }

        this.setState({ ...newState });
    }

    sportChange = (name, value) => {
        this.setState({ [name]: value });
    }

    componentDidMount() {
        this.initialAction();
        this.getApplications();
    }

    getApplications = async () => {
        const { client, userId, type } = this.props;        
        const Applications = await client.getApplications({userId, type});
        if (!Applications || !Applications.length) return;

        const lastApplication = Applications[Applications.length - 1];
        if (lastApplication.Result === null) {
            this.setState({application: lastApplication});
        }
    }

    onSave = async () => {

        await this.props.onSave(this.state);
        this.getApplications();

    }

    render() {
        const { components, header, saveText, neededParamName } = this.props;
        const { application } = this.state;

        let showButton = true;
        if (application) showButton = false;
        if (neededParamName && !this.state[neededParamName]) showButton = false;

        return <div className='sport_changer_container'>
            <div className='sport_changer_header'>
                {header}
            </div>
            {
                application && <div className = ''>
                    Подана заявка на категорию <br/>
                    <LittleApplication Application = {application} />
                </div>
            }
            {!application && components.map((component) => {
                return <this.componentRender
                    key={'d_c_c' + component.name}
                    {...component}
                />
            })}
           { showButton && <div className='sport_changer_footer'>
                <ButtonPlashka upperStyle={{ width: 180, height: 40 }} onClick={this.onSave} text={saveText} />
            </div> }

        </div>
    }

    componentRender = (component) => {
        const {
            name,
            title,
            text,
            type
        } = component;

        const value = this.state[name];

        let ComponentRendered = null;
        switch (type) {
            case 'sport': {
                ComponentRendered = <this.sportCopmonent name={name} value={value} />
                break;
            }
            case 'judge': {
                ComponentRendered = <this.judgeCopmonent name={name} value={value} />
                break;
            }
            default: {
                ComponentRendered = <this.sportCopmonent name={name} value={value} />
                break;
            }
        }

        return <div className='sport_changer_component'>
            <div className='sport_changer_component_title'>
                {title}
            </div>
            <div className='sport_changer_component_comp'>
                {ComponentRendered}
            </div>
            {text && <div className='sport_changer_component_text'>{text}</div>}

        </div>

    }

    sportCopmonent = ({ name, value }) => {

        const { client } = this.props;

        const sports = client.sportRanks;

        return <Select
            style = {{width: 250}} size = 'large'
            value={value ? value.toString() : null}
            onChange={(value) => { this.sportChange(name, value) }}
        >
            {Object.keys(sports).reduce((memo, key) => {
                memo.push(
                    <Option value={key.toString()}>
                        {sports[key]}
                    </Option>
                );
                return memo;
            }, [])}
        </Select>
    }

    judgeCopmonent = ({ name, value }) => {

        const { client } = this.props;

        const sports = client.judgeRanks;

        return <Select
            style = {{width: 250}} size = 'large'
            value={value ? value.toString() : null}
            onChange={(value) => { this.sportChange(name, value) }}
        >
            {Object.keys(sports).reduce((memo, key) => {
                memo.push(
                    <Option value={key.toString()}>
                        {sports[key]}
                    </Option>
                );
                return memo;
            }, [])}
        </Select>
    }

}


export default withCapoeiraSportState('client')(SportChanger);