import React, { Component } from 'react';
import { TitledPlashka } from 'src/ver2/containers/lowlavel/title';
import { SelfHeightPlashka } from 'src/ver2/containers/lowlavel/selfHeightPlashka';


import './style.css';


// const STANDART_HEIGHT = 150;


/**
 * 
 * Сюда у нас приходит компонент Wrapped, прорсы в него
 * и прокидывает туда upperStyle, чтобы компонент знал свои "верхние корни"
 */

class Plashka extends Component {
    render() {
        const {
            component: ThisComponent,
            componentProps,
            top,
            left,
            width,
            height,
            plashkaId,
            setComponentSelfHeight
        } = this.props;


        let isHidden = false;
        if (height === 1) isHidden = true;

        let componentHeight = height;

        const { yPadding, _title, pl_marginBottom, componentSelfHeight = false } = componentProps || {};

        if (yPadding) {
            switch (yPadding) {
                case 'mini':
                    componentHeight -= 4; break;

                case 'little':
                    componentHeight -= 12; break;

                case 'usual':
                default:
                    componentHeight -= 24; break;
            }
        }

        componentHeight -= pl_marginBottom || 0;

        if (componentHeight < 1) componentHeight = 1;

        if (!ThisComponent) return <div className='trefa_plashka__' style={{ width, height, top, left }} />

        if (!componentSelfHeight) {
            if (_title) {
                // componentHeight -= 24;
                return <div className='trefa_plashka__' style={{ width, height, top, left, opacity: isHidden ? 0.001 : 1 }}>
                    <TitledPlashka titleComponent={componentProps._title} titleLinkTo={componentProps.titleLinkTo} titleOnClick={componentProps.titleOnClick} upperStyle={{ width, height: componentHeight, top, left }} lowTitle={componentProps.lowTitle} >
                        <ThisComponent {...{ upperStyle: { width, height: componentHeight - 24, top, left }, setComponentSelfHeight, plashkaId, ...componentProps }} />
                    </TitledPlashka>
                </div>
            }

            return <div className='trefa_plashka__' style={{ width, height, top, left, opacity: isHidden ? 0.001 : 1 }}>
                <ThisComponent {...{ upperStyle: { width, height: componentHeight, top, left }, setComponentSelfHeight, plashkaId, ...componentProps }} />
            </div>
        }
        if (componentSelfHeight) {
            const innerId = `trefa_sp_${plashkaId}`;
            if (_title) {
                return <div className='trefa_plashka__' style={{ width, height, top, left, opacity: isHidden ? 0.001 : 1 }}>
                    <SelfHeightPlashka {...{ upperStyle: { height }, innerId, componentHeight: componentHeight - 24, setComponentSelfHeight, plashkaId }}>
                        <TitledPlashka titleComponent={componentProps._title} titleLinkTo={componentProps.titleLinkTo} titleOnClick={componentProps.titleOnClick} upperStyle={{ width, height: componentHeight, top, left }} lowTitle={componentProps.lowTitle} >
                            <ThisComponent {...{ upperStyle: { width, height: componentHeight - 24, top, left }, innerId, setComponentSelfHeight, plashkaId, ...componentProps }} />
                        </TitledPlashka>
                    </SelfHeightPlashka>
                </div>
            }

            return <div className='trefa_plashka__' style={{ width, height, top, left, opacity: isHidden ? 0.001 : 1 }}>
                <SelfHeightPlashka {...{ upperStyle: { height }, innerId, componentHeight, setComponentSelfHeight, plashkaId }}>
                    <ThisComponent {...{ upperStyle: { width, height: componentHeight, top, left }, innerId, setComponentSelfHeight, plashkaId, ...componentProps }} />
                </SelfHeightPlashka>
            </div>
        }
    }
}

/**
 * 
 * Этот компонент определяет только расстановку компонентов 
 * 
 * Сюда приходит: 
 * greedsX: [ 100, 200, ... ] // ширина столбцов
 * greedsY:500 // высота (общая)
 * components: [
 * {
 *      component // какой компонент
 *      cell_x // от 1 до размерности greedX
 *      collapse: 1 // сколько по x штук он занимает
 *      componentProps // то что надо прокинуть в компонент
 * }
 * ]
 * 
 * 
 */

export class TrefaLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lyWidth: 0,
            lyHeight: 0,
            components: []
        }
        this.componentsSelfHeight = {}

    }

    setComponentSelfHeight = ({ plashkaId, height }) => {
        this.componentsSelfHeight = { ...this.componentsSelfHeight, [plashkaId]: { height } }
        this.setAllSizes(this.props);
    }

    componentDidMount() {
        this.setAllSizes(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.setAllSizes(nextProps);
    }

    setAllSizes = ({ greedsX, greedsY, components }) => {

        // сначала создаём заполненность сверху вниз для всех столбцов
        let maxGreedHeight = 0;
        let greedsYtop = []; // это массив размерностью в количество колонок, где выписываются все текущие высоты колонок
        let lyWidth = 0;
        for (let i = 0; i < greedsX.length; i++) { lyWidth += greedsX[i]; greedsYtop.push(0) };

        // далее - начинаем кидать сверху вниз компоненты
        let components_ = [];
        for (let i = 0; i < components.length; i++) {
            const componentItem = components[i]; // берём объект компонента
            const {
                component,
                cell_x,
                collapse,
                id,
                componentProps
            } = componentItem;

            // смотрим, какие столбцы мы занимаем и какие максимальные высоты
            // а заодно и ширину
            let maxTop = 0;
            let width = 0;
            let left = 0;
            let top = 0;
            for (let k = cell_x - 1; k < cell_x - 1 + (collapse || 1); k++) {
                if (greedsYtop[k] >= maxTop) maxTop = greedsYtop[k];
                width += greedsX[k];
            }

            top = maxTop;

            // берем или высоту, которую занёс сам компонент, или заданную компонентом высоту, или дефолтную

            let height = 0;
            if (this.componentsSelfHeight[id]) {
                height = this.componentsSelfHeight[id].height;
            } else {
                height = componentProps && componentProps.height ? componentProps.height : 0;
            }

            // теперь в этих столбах мы выставляем новый топ 
            for (let k = cell_x - 1; k < cell_x - 1 + (collapse || 1); k++) {
                greedsYtop[k] = maxTop + height;
                if (maxGreedHeight < greedsYtop[k]) maxGreedHeight = greedsYtop[k];
            }

            // определяем отступ слева
            for (let k = 1; k < cell_x; k++) {
                left += greedsX[k - 1];
            }

            // тут мы еще по идее должны сделать offerHeight - замыкать высоту или нет

            components_.push({
                plashkaId: id,
                component: component,
                width,
                height,
                top,
                left,
                componentProps
            });

        }

        this.setState({
            lyWidth,
            lyHeight: greedsY || maxGreedHeight,
            components: components_
        })

    }


    render() {

        const {
            lyWidth,
            lyHeight,
            components
        } = this.state;

        const {
            style,
            noTransition
        } = this.props;

        const className = 'trefa_layout__ ' 
        + (noTransition ? ' ___no_transition' : '' );

        return <div className={className} style={{ width: lyWidth, height: lyHeight, ...style }}>
            {components.map((value, index) => {
                return <Plashka key={'ly_plashka_' + index + '_' + (value.plashkaId || 0)} {...value} setComponentSelfHeight={this.setComponentSelfHeight} />
            })}
        </div>
    }
}