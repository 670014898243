// это стандартный тайтл, который везде используется
import React, { Component } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { Tabs, message } from 'antd';
import TrefaTextEdit from 'src/ver2/components/trefaText/edit';

import DataChanger from 'src/ver2/containers/dataChanger';

import { withCapoeiraSportState } from 'src/ver2/context';
import ButtonPlashka from 'src/ver2/plashki/ui/button';

import { cloneDeep } from 'lodash';

// import moment_ from 'moment';
// import 'moment/locale/ru';
// import 'moment/min/moment-with-locales.min';

// var localeoment = moment();
// moment.locale('ru');
// moment.lang('ru');

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';
// moment.locale('ru');
// moment.lang('ru');

const TabPane = Tabs.TabPane;

/**
 * 
 * Это компонент создания мероприятий
 */

const TYPES = [
    { key: 'roda', title: 'Рода' },
    { key: 'seminar', title: 'Семинар' },
    { key: 'championship', title: 'Соревнование' },
    { key: 'judge', title: 'Судейский семинар' },
    { key: 'batizado', title: 'Батизадо' },
    { key: 'challenge', title: 'Челлендж' }
]

const RODA_ONLY_TYPES = [
    { key: 'roda', title: 'Рода' }
]

const FACES = [
    { key: 'open', title: 'Общее мероприятие' },
    { key: 'school', title: 'Школа' },
    { key: 'group', title: 'Группа' },
    { key: 'person', title: 'Личное' }
]

const STUFF = {
    admin: { key: 'admin', title: 'Администратор', text: 'Администратор имеет такой же доступ, как и создатель мероприятия' },
    moderator: { key: 'moderator', title: 'Модератор', text: 'Модератор может добавлять новости, менять описание, подтверждать заявки, но не может менять основные настройки мероприятия' },
    contact: { key: 'contact', title: 'Контактное лицо', text: 'Не имеет функционала, но высвечивается в колонке контактов' },

    speaker: { key: 'speaker', title: 'Спикер', text: 'Те, кто выступают с презентациями или мастер-классами на мероприятии' }
}

const STUFF_JUDGE = {
    admin: true,
    _adminApplications: false,

    moderator: true,
    _moderatorApplications: false,

    contact: false,
    _contactApplications: false,

    speaker: false,
    _speakerApplications: false,
}

const ADMISSIONS = [
    { key: 'insurance', title: 'Страховка', text: 'Наличие страховки по виду спорта Капоэйра' },
    { key: 'medicine', title: 'Медицинская справка', text: 'Наличие медицинской справки' },
    { key: 'pcr', title: 'ПЦР-тест', text: 'Наличие ПЦР теста' },
    { key: 'presense', title: 'Явка', text: 'Выставляется организатором как подтверждение присутствия на мероприятии' },
]

class AddEventData extends Component {

    constructor(props) {
        super(props);

        this.state = {

            eventCreated: null,
            activeKey: '1',
            keysAllowed: {
                1: true,
                2: false
            },
            keysVisible: {
                1: true, // основные данные
                2: true, // доступ
                3: false, // организаторы
                4: false, // участники (что должны указать)
                5: false, // оплата
                6: false,
                7: false,
                8: false, // подтверждение инфы
            },

            EventMain: {
                Title: '',
                ShortTitle: '',
                Type: '',

                StartDate: null,
                EndDate: null,

                IsPreliminarily: false,
                StartPreliminarilyDate: null,
                EndPreliminarilyDate: null,

                City: '',
                Adress: ''
            },

            PermissionMain: {
                FaceFrom: 'open',
                IsClosed: false,
                FaceId: null,
                WithApplication: false
            },

            StuffDataMain: {},

            ParticipatorsMain: {
                IsAllowedViewer: true,
                ParticipatorsData: []
            },
            PaymentMain: {
                PaymentData: {
                }
            },

            AdmissionMain: {
                AdmissionData: [

                ]
            },

            SpecialMain: {

            }
        }
    }

    changeTab = (activeKey) => { this.setState({ activeKey }) }

    onMainEventData = (EventMain) => {
        this.setState({ EventMain });

        this.setState({
            keysAllowed: {
                '1': true,
                '2': true
            },
            keysVisible: {
                1: true,
                2: true,
            }
        })

        // тут, самое главное, к нам приходит тип мероприятия. От него заполняются многие вещи
        if (EventMain.Type === 'roda') {
            this.setState({ ParticipatorsMain: null, PaymentMain: null });
        }

        if (EventMain.Type === 'challenge') {

            this.setState({
                ParticipatorsMain: {
                    IsAllowedViewer: false,
                    ParticipatorsData: [{
                        name: 'participation',
                        title: 'Участие',
                        type: 'select',
                        isNecessery: true,
                        options: []
                    }
                        ,
                    // {
                    //     name: 'issilent',
                    //     title: 'Тихое участие',
                    //     type: 'select',
                    //     isNecessery: true,
                    //     options:
                    //         [
                    //             {
                    //                 key: 'yes',
                    //                 title: 'да'
                    //             },
                    //             {
                    //                 key: 'no',
                    //                 title: 'нет'
                    //             }
                    //         ],
                    //     defValue: 'no'
                    // },
                    {
                        name: 'tier',
                        title: 'Tier участника',
                        type: 'select',
                        isNecessery: true,
                        options:
                            [
                                {
                                    key: '1',
                                    title: 'ДЕРЕВЯННЫЙ ТОТЕМ'
                                },
                                {
                                    key: '2',
                                    title: 'БРОНЗОВЫЙ ТОТЕМ'
                                },
                                {
                                    key: '3',
                                    title: 'СЕРЕБРЯНЫЙ ТОТЕМ'
                                },
                                {
                                    key: '4',
                                    title: 'ЗОЛОТОЙ ТОТЕМ'
                                }
                            ],
                        defValue: '1'
                    }
                    ],
                },
                PaymentMain: null
            });


            this.changeTab('2')
        }

        if ((EventMain.Type === 'judge') || (EventMain.Type === 'seminar') || (EventMain.Type === 'batizado') || (EventMain.Type === 'championship')) {
            this.setState({
                ParticipatorsMain: {
                    IsAllowedViewer: false,
                    ParticipatorsData: [{
                        name: 'participation',
                        title: 'Участие',
                        type: 'select',
                        isNecessery: true,
                        options: []
                    }],
                },
                PaymentMain: null
            });
        }

        this.changeTab('2')
    }

    onPermissionMainData = async (PermissionMain_) => {

        let PermissionMain = { ...PermissionMain_ };

        if (PermissionMain.FaceFrom === 'school') {

            const { client, iamuser } = this.props;

            const { SchoolId } = iamuser;

            const School = client.getSchoolById(SchoolId);
            PermissionMain.FaceObject = School;
            PermissionMain.FaceId = SchoolId;
        }

        this.setState({ PermissionMain });
        const { EventMain } = this.state;

        if (EventMain.Type === 'roda') {
            this.setState({
                keysAllowed: {
                    ...this.state.keysAllowed,
                    '6': true
                },
                keysVisible: {
                    ...this.state.keysVisible,
                    6: true,
                }
            })
            this.changeTab('6')
        }


        if ((EventMain.Type === 'judge') || (EventMain.Type === 'challenge') || (EventMain.Type === 'seminar') || (EventMain.Type === 'batizado') || (EventMain.Type === 'championship')) {
            this.setState({
                keysAllowed: {
                    ...this.state.keysAllowed,
                    '3': true
                },
                keysVisible: {
                    ...this.state.keysVisible,
                    3: true
                }
            })
            this.changeTab('3')
        }
    }

    onStuffMainData = (StuffDataMain) => {

        this.setState({ StuffDataMain });
        const { EventMain } = this.state;

        if ((EventMain.Type === 'seminar') || EventMain.Type === 'challenge' || (EventMain.Type === 'batizado') || (EventMain.Type === 'championship')) {
            this.setState({
                keysAllowed: {
                    ...this.state.keysAllowed,
                    '4': true
                },
                keysVisible: {
                    ...this.state.keysVisible,
                    4: true
                }
            })
            this.changeTab('4');
        }

        if (EventMain.Type === 'judge') {


            let PaymentMain = { PaymentData: { participatorsCategorys: [], participatorsSales: [] } };
            PaymentMain.PaymentData.participatorsCategorys.push(
                {
                    name: 'participation',
                    title: 'Участие',
                    options: [],
                    paymentSplit: false, // разделять ли по категориям
                    payments: []
                }
            )

            this.setState({
                PaymentMain,
                keysAllowed: {
                    ...this.state.keysAllowed,
                    '5': true
                },
                keysVisible: {
                    ...this.state.keysVisible,
                    5: true
                }
            })
            this.changeTab('5');
        }
    }

    // это может быть только у судейских, семинаров и у соревнований
    onParticipatorsData = (ParticipatorsMain) => {

        // тут нам надо пропарсить эти данные
        this.setState({ ParticipatorsMain });

        let PaymentMain = { PaymentData: { participatorsCategorys: [], participatorsSales: [] } };
        const { ParticipatorsData } = ParticipatorsMain;
        for (let i = 0; i < ParticipatorsData.length; i++) {

            const {
                name,
                title,
                options,
            } = ParticipatorsData[i];

            PaymentMain.PaymentData.participatorsCategorys.push(
                {
                    name,
                    title,
                    options,
                    paymentSplit: false, // разделять ли по категориям
                    payments: []
                }
            )
        }

        this.setState({ PaymentMain });

        this.setState({
            keysAllowed: {
                ...this.state.keysAllowed,
                '5': true
            },
            keysVisible: {
                ...this.state.keysVisible,
                5: true
            }
        })
        this.changeTab('5');
    }

    // это может быть только у семинаров и у соревнований
    onPaymentData = (PaymentMain) => {

        // тут нам надо пропарсить эти данные
        this.setState({ PaymentMain });

        this.setState({
            keysAllowed: {
                ...this.state.keysAllowed,
                '6': true
            },
            keysVisible: {
                ...this.state.keysVisible,
                6: true
            }
        })
        this.changeTab('6');
    }

    onAdmissionData = (AdmissionMain) => {

        // тут нам надо пропарсить эти данные
        this.setState({ AdmissionMain });

        this.setState({
            keysAllowed: {
                ...this.state.keysAllowed,
                '8': true
            },
            keysVisible: {
                ...this.state.keysVisible,
                8: true
            }
        })
        this.changeTab('8');
    }

    render() {

        const { EventMain, PermissionMain, ParticipatorsMain, PaymentMain, AdmissionMain, activeKey, keysAllowed, keysVisible, eventCreated } = this.state;
        const { mode } = this.props;

        if (eventCreated) {
            return <div className='admin_modal'>
                <Link to={`/event/${eventCreated}`}>
                    {`Мероприятие создано, нажмите, чтобы перейти на него`}
                </Link>
            </div>
        }

        let THIS_TYPES = TYPES;
        if (mode === 'rodaonly') THIS_TYPES = RODA_ONLY_TYPES;

        return <div className='admin_modal'>
            <Tabs activeKey={activeKey} onChange={this.changeTab} className='admin_modal_tabs' tabPosition='left' style={{ color: 'white', heightMin: 500 }} defaultActiveKey={activeKey}>

                <TabPane tab={'Основные данные'} key="1">
                    <DataChanger
                        header={<div>
                            Основные данные мероприятия
                        </div>}
                        data={EventMain}
                        buttonText={'ДАЛЕЕ'}
                        buttonState={'allNeeded'}
                        onButtonClick={this.onMainEventData}
                        components={[

                            {
                                name: 'Type',
                                title: 'Тип мероприятия',
                                type: 'select',
                                options: THIS_TYPES,
                                nextGroup: { title: '' },
                                neededText: ' '
                            },
                            {
                                name: 'Title',
                                title: 'Название',
                                type: 'input',
                                style: {
                                    width: 600
                                },
                                neededText: ' '
                            },
                            {
                                name: 'ShortTitle',
                                title: 'Короткое название',
                                text: 'ПРИМЕР: Чемпионат России 2022 -> ЧР2022',
                                type: 'input',
                                nextGroup: { title: 'Даты мероприятия' }
                            },
                            {

                                name: 'IsPreliminarily',
                                title: 'Использовать предварительные даты',
                                text: 'Выберете, если вы еще не знаете точные даты мероприятия',
                                type: 'checkbox',
                                nextGroup: { title: 'Место проведения' },
                                style: {
                                    width: 600
                                },
                                blockByValue: {
                                    false: [
                                        {
                                            name: 'StartDate',
                                            title: 'Дата проведения',
                                            type: 'date',
                                            neededText: ' '
                                        },
                                        {
                                            name: 'EndDate',
                                            title: 'Дата окончания',
                                            type: 'date',
                                            text: 'Оставьте пустым, если мероприятие проходит в один день'
                                        },
                                    ]
                                    ,
                                    true: [
                                        {
                                            name: 'StartPreliminarilyDate',
                                            title: 'Начало периода',
                                            type: 'dateMonth',
                                            neededText: ' '
                                        },
                                        {
                                            name: 'EndPreliminarilyDate',
                                            title: 'Окончание периода',
                                            type: 'dateMonth',
                                            text: 'Оставьте пустым, если вы ожидаете мероприятие в конкретный месяц'
                                        },
                                    ]
                                }

                            },
                            {
                                name: 'City',
                                title: 'Город',
                                type: 'input',
                                text: 'Город проведения мероприятия',
                            },
                            {
                                name: 'Adress',
                                title: 'Адрес',
                                text: 'Можно не указывать в адресе город',
                                type: 'input',
                                style: {
                                    width: 500
                                },
                            }
                        ]}
                    />
                </TabPane>
                {keysVisible[2] ? <TabPane disabled={!keysAllowed[2]} tab={'Доступ'} key="2">
                    <DataChanger
                        header={<div>
                            Доступность мероприятия для пользователей Портала
                        </div>}
                        data={PermissionMain}
                        buttonText={'ДАЛЕЕ'}
                        buttonState={'all'}
                        onButtonClick={this.onPermissionMainData}
                        components={[
                            {
                                name: 'FaceFrom',
                                title: 'От чьего лица организовывается мероприятие',
                                type: 'select',
                                options: FACES,
                                nextGroup: { title: '' },
                                blockByValue: {
                                    'school': [
                                        {
                                            name: 'IsClosed',
                                            title: 'Закрытое мероприятие',
                                            type: 'checkbox',
                                            text: 'Закрытое мероприятие могут видеть и участовать только ученики школы или группы',
                                        }
                                    ],
                                    'group': [
                                        {
                                            name: 'IsClosed',
                                            title: 'Закрытое мероприятие',
                                            type: 'checkbox',
                                            text: 'Закрытое мероприятие могут видеть и участовать только ученики школы или группы',
                                        }
                                    ],
                                }
                            },
                            {
                                name: 'WithApplication',
                                title: 'Требуется подтверждение',
                                type: 'checkbox',
                                text: 'Участник подаёт заявку на участие, которую подтверждает или не подтверждает организатор',
                            },
                        ]}
                    />
                </TabPane> : null}

                {keysVisible[3] ? <TabPane disabled={!keysAllowed[3]} tab={'Организаторы'} key="3">
                    <DataChanger
                        header={<div>
                            Отметьте типы организаторов, которые нужны Вам на данном мероприятии <br />
                            Вы можете назначать на роли пользователей сами, или принимать от них заявки
                        </div>}
                        data={STUFF_JUDGE}
                        buttonText={'ДАЛЕЕ'}
                        buttonState={'all'}
                        onButtonClick={this.onStuffMainData}

                        components={
                            Object.keys(STUFF_JUDGE).reduce((components, value) => {
                                if (value[0] === '_') return components;
                                return [...components, {
                                    name: value,
                                    title: STUFF[value].title,
                                    text: STUFF[value].text,
                                    type: 'checkbox',
                                    nextGroup: { title: 'роль' },
                                    style: {
                                        width: 600
                                    },
                                    blockByValue: {
                                        true: [
                                            {
                                                name: `_${value}Application`,
                                                title: 'Принимать заявки?',
                                                type: 'checkbox',
                                                style: {
                                                    width: 450
                                                },
                                                text: 'Если галочка стоит, то пользователи могут подавать заявки на данную позицию.\nЕсли нет - то только избираться администратором',
                                            }
                                        ]
                                    }
                                }]
                            }, [])
                        }
                    />
                </TabPane> : null}

                {keysVisible[4] ? <TabPane disabled={!keysAllowed[4]} tab={'Участники'} key="4">
                    <DataChanger
                        header={<div>
                            Определите, какие данные ожидаются от участников в момент регистрации
                        </div>}
                        data={ParticipatorsMain}
                        buttonText={'ДАЛЕЕ'}
                        buttonState={'all'}
                        onButtonClick={this.onParticipatorsData}

                        components={[
                            {
                                name: 'IsAllowedViewer',
                                title: 'Ожидаете ли вы зрителей',
                                text: 'Отмечая этот пункт, вы даёте возможеость пользователям добавляться как зрителям.\nЗрители получают новости по обновлению мероприятия, но для них нельзя выставить конкретные требования по регистрации.',
                                type: 'checkbox',
                                nextGroup: { title: 'Основные участники' },
                                style: {
                                    width: 600
                                },
                            },
                            {
                                name: 'NecesserySchool',
                                title: 'Обязательна ли школа',
                                text: 'Могут зарегистрироваться только те участники, кто указал в своём профиле школу',
                                type: 'checkbox',
                                nextGroup: { title: '' },
                                style: {
                                    width: 600
                                },
                            },
                            {
                                name: 'NecesseryContacts',
                                title: 'Обязательна ли контактная информация',
                                text: 'Могут зарегистрироваться только те участники, кто указал в своём профиле контактную информацию',
                                type: 'checkbox',
                                nextGroup: { title: '' },
                                style: {
                                    width: 600
                                },
                            },
                            {
                                name: 'ParticipatorsData',
                                title: 'Что должны заполнить участники при регистрации',
                                type: 'participators',
                                style: {
                                    width: 800
                                },
                            }
                        ]}
                    />
                </TabPane> : null}

                {keysVisible[5] ? <TabPane disabled={!keysAllowed[5]} tab={'Оплата'} key="5">
                    <DataChanger
                        header={<div>
                            Задайте цену для разных вариантов регистрации в зависимости от даты и выбранного варианта <br />
                            или оставьте цену пустой, если данный вариант не оплачивается
                        </div>}
                        data={PaymentMain}
                        buttonText={'ДАЛЕЕ'}
                        buttonState={'allNeeded'}
                        onButtonClick={this.onPaymentData}

                        components={[
                            {
                                name: 'PaymentData',
                                title: 'Оплата мероприятия',
                                type: 'payment',
                                neededText: ' ',
                                text: 'Должны быть заполнены все поля дат или удалены пустые',
                                style: {
                                    width: 800
                                },
                            }
                        ]}
                    />
                </TabPane> : null}

                {keysVisible[6] ? <TabPane disabled={!keysAllowed[6]} tab={'Допуск'} key="6">
                    <DataChanger
                        header={<div>
                            Здесь вам нужно перечислить то, что нужно сделать участнику после прохождения регистрации (помимо оплаты, если она есть), чтобы
                            допуститься до участия в мероприятии. <br /> После создания мероприятия, вы сможете расписать подробную информацию по каждому пункту.
                            <br /> Добавьте предлагаемые системой пункты, или напишите свои
                        </div>}
                        data={AdmissionMain}
                        buttonText={'ДАЛЕЕ'}
                        buttonState={'all'}
                        onButtonClick={this.onAdmissionData}
                        admissionVars={ADMISSIONS}
                        components={[
                            {
                                name: 'AdmissionData',
                                title: 'Что будет проверять организатор',
                                type: 'admission',
                                style: {
                                    width: 800
                                },
                            }
                        ]}
                    />
                </TabPane> : null}

                {keysVisible[8] ? <TabPane disabled={!keysAllowed[8]} tab={'Создание'} key="8">
                    <div className='add_event_result'>
                        {this.ResultShow()}
                        <ButtonPlashka
                            upperStyle={{ width: 180, height: 40 }}
                            onClick={() => { this.createEvent() }}
                            text={'СОЗДАТЬ МЕРОПРИЯТИЕ'}
                        />
                    </div>
                </TabPane> : null}
            </Tabs>
        </div>
    }

    createEvent = async () => {
        const { client } = this.props;

        const fixedData = this.processDataToJSON();
        const {
            EventMain,
            PermissionMain,
            StuffDataMain,
            ParticipatorsMain,
            PaymentMain,
            AdmissionMain,
            SpecialMain
        } = fixedData;

        const Settings = JSON.stringify({
            PermissionMain,
            StuffDataMain,
            ParticipatorsMain,
            PaymentMain,
            AdmissionMain,
            SpecialMain
        })

        const lastEventId = await client.getLastEventId();

        await client.createEvent(
            {
                NotAnnounced: true,
                Title: EventMain.Title,
                ShortTitle: EventMain.ShortTitle || null,
                Type: EventMain.Type,
                Date: EventMain.StartDate || null,
                EndDate: EventMain.EndDate || null,
                IsPreliminarily: EventMain.IsPreliminarily,
                StartPreliminarilyDate: EventMain.StartPreliminarilyDate || null,
                EndPreliminarilyDate: EventMain.EndPreliminarilyDate || null,
                Discription: '',
                City: EventMain.City || null,
                Adress: EventMain.Adress || null,
                ChampionshipId: 0,
                TimeDiscription: '',
                MainEventId: null,
                Settings
            }
        )

        const newLastEventId = await client.getLastEventId();

        if (lastEventId === newLastEventId) {
            message.warn('Что то пошло не так, попробуйте еще раз или обратитесь к администратору');
            return;
        }

        this.setState({ eventCreated: newLastEventId })


    }

    // тут мы смотрим, все ли у нас нормально

    // EventMain: {
    //     Title: '',
    //     ShortTitle: '',
    //     Type: '',

    //     StartDate: null,
    //     EndDate: null,

    //     IsPreliminarily: false,
    //     StartPreliminarilyDate: null,
    //     EndPreliminarilyDate: null,

    //     City: '',
    //     Adress: ''
    // },

    // PermissionMain: {
    //     FaceFrom: 'open',
    //     IsClosed: false,
    //     FaceId: null,
    //     WithApplication: false
    // },

    // StuffDataMain: {},

    // ParticipatorsMain: {
    //     IsAllowedViewer: true,
    //     ParticipatorsData: []
    // },
    // PaymentMain: {
    //     PaymentData: {
    //     }
    // }

    processDataToJSON = () => {
        const {
            EventMain,
            PermissionMain,
            StuffDataMain,
            ParticipatorsMain,
            PaymentMain,
            AdmissionMain,
            SpecialMain
        } = this.state;

        let endedObj = cloneDeep({
            EventMain,
            PermissionMain,
            StuffDataMain,
            ParticipatorsMain,
            PaymentMain,
            AdmissionMain,
            SpecialMain
        });

        // процессим дату:
        // if (endedObj.EventMain.StartDate) {
        //     endedObj.EventMain.StartDate = moment(endedObj.EventMain.StartDate).locale('ru').format('DD.MM.YYYY');
        // }
        // if (endedObj.EventMain.EndDate) {
        //     endedObj.EventMain.EndDate = moment(endedObj.EventMain.EndDate).locale('ru').format('DD.MM.YYYY');
        // }
        // if (endedObj.EventMain.StartPreliminarilyDate) {
        //     endedObj.EventMain.StartPreliminarilyDate = moment(endedObj.EventMain.StartPreliminarilyDate).locale('ru').format('MM.YYYY');
        // }
        // if (endedObj.EventMain.EndPreliminarilyDate) {
        //     endedObj.EventMain.EndPreliminarilyDate = moment(endedObj.EventMain.EndPreliminarilyDate).locale('ru').format('MM.YYYY');
        // }

        if (endedObj.PermissionMain.FaceObject) {
            delete endedObj.PermissionMain.FaceObject;
        }

        // if (endedObj.PaymentMain && endedObj.PaymentMain.PaymentData && endedObj.PaymentMain.PaymentData.participatorsCategorys) {
        //     for (let i = 0; i < endedObj.PaymentMain.PaymentData.participatorsCategorys.length; i++) {
        //         const participatorCategorys = endedObj.PaymentMain.PaymentData.participatorsCategorys[i];
        //         if (participatorCategorys && participatorCategorys.payments && participatorCategorys.payments.length) {
        //             for (let k = 0; k < participatorCategorys.payments.length; k++) {
        //                 if (endedObj.PaymentMain.PaymentData.participatorsCategorys[i].payments[k].date) {
        //                     endedObj.PaymentMain.PaymentData.participatorsCategorys[i].payments[k].date = moment(endedObj.PaymentMain.PaymentData.participatorsCategorys[i].payments[k].date).locale('ru').format('DD.MM.YYYY');
        //                 }
        //             }
        //         }
        //     }
        // }

        return endedObj;
    }

    ResultShow = () => {

        const {
            EventMain,
            PermissionMain,
            ParticipatorsMain,
            PaymentMain
        } = this.state;

        this.processDataToJSON();

        let resultText = ``;

        const { Title, ShortTitle, StartDate, EndDate, IsPreliminarily, StartPreliminarilyDate, EndPreliminarilyDate, Type, City, Adress } = EventMain;

        if (IsPreliminarily) {
            if (EndPreliminarilyDate) {
                resultText += `Предварительно в период ${moment(StartPreliminarilyDate, 'MM.YYYY').locale('ru').format('MMMM YYYY')} - ${moment(EndPreliminarilyDate, 'MM.YYYY').locale('ru').format('MMMM YYYY')}`
            } else {
                resultText += `Предварительно в месяце ${moment(StartPreliminarilyDate, 'MM.YYYY').locale('ru').format('MMMM YYYY')}`
            }
        } else {
            if (EndDate) {
                resultText += `В период ${moment(StartDate, 'DD.MM.YYYY').locale('ru').format('DD MMMM YYYY')} - ${moment(EndDate, 'DD.MM.YYYY').locale('ru').format('DD MMMM YYYY')}`
            } else {
                resultText += `${moment(StartDate, 'DD.MM.YYYY').locale('ru').format('DD MMMM YYYY (dddd)')}`
            }
        }

        const type_ = TYPES.find(x => x.key === Type) ? TYPES.find(x => x.key === Type).title : '';
        resultText += ` состоится ${type_} "${Title}"${ShortTitle ? ' / "' + ShortTitle + '"' : ''}`;

        resultText += '<br/><br/>';

        if (!City && !Adress) {
            resultText += 'Город и адрес проведения в данный момент уточняются';
        } else {
            if (!Adress) {
                resultText += `Город проведения: ${City} <br/>Адрес в данный момент уточняется`;
            } else {
                resultText += `${type_} пройдет по адресу: ${City + ' / ' + Adress}`;
            }
        }

        //PermissionMain
        const {
            FaceFrom,
            IsClosed,
            WithApplication,
            FaceObject
        } = PermissionMain;

        if (FaceFrom === 'school') {
            resultText += '<br/><br/>';
            resultText += `Мероприятие проходит от лица школы ${FaceObject.Name}.`;
            if (IsClosed) {
                resultText += '<br/>';
                resultText += `Мероприятие - закрытое. Регистрация доступна только для учеников школы.`;
            }
        }

        if (WithApplication) {
            resultText += '<br/>';
            resultText += 'После прохождения регистрации, пользователю нужно получить подтверждение от администраторов мероприятия.';
        }


        if (ParticipatorsMain) {
            const { IsAllowedViewer, ParticipatorsData, NecesserySchool, NecesseryContacts } = ParticipatorsMain;
            resultText += '<br/><br/>';

            if (ParticipatorsData && ParticipatorsData.length > 0 && (ParticipatorsData.length === 1 && ParticipatorsData[0].name !== 'participation')) {
                resultText += 'При регистрации участники указывают следующие данные: ';
                resultText = ParticipatorsData.reduce((res, { name, title }) => {
                    if (name === 'participation') return res;
                    if (res === '') return res + title;
                    return res + ', ' + title;
                }, '')
            }

            if (NecesserySchool) resultText += '<br/>Участники без указания школы в своем профиле - не допускаются';
            if (NecesseryContacts) resultText += '<br/>Участники без указания контактных данных в своем профиле - не допускаются';

            if (IsAllowedViewer) {
                if (NecesseryContacts) resultText += '<br/>Зрители допускаются на мероприятие';
            } else {
                if (NecesseryContacts) resultText += '<br/>Зрители не допускаются на мероприятие';
            }

            resultText += 'После прохождения регистрации, пользователю нужно получить подтверждение от администраторов мероприятия.';
        }

        if (PaymentMain && PaymentMain.PaymentData && PaymentMain.PaymentData.participatorsCategorys) {

            const addBlankRows = (arr, count) => {
                const arr_ = [...arr];
                for (let i = 0; i < count; i++) {
                    arr_.push(null);
                }
                return arr_;
            }

            for (let i = 0; i < PaymentMain.PaymentData.participatorsCategorys.length; i++) {
                const participatorCategorys = PaymentMain.PaymentData.participatorsCategorys[i];

                let header = [null, null];
                let rows = [];

                // берём одну категорию оплаты
                const { title, payments, options, paymentSplit } = participatorCategorys;

                // rows[0][0] = title;

                const paymentsLength = payments.length || 1;
                if (paymentSplit) {
                    for (let u = 0; u < (options.length || 1); u++) {
                        rows.push(addBlankRows([], paymentsLength + 2));
                    }
                } else {
                    rows.push(addBlankRows([], paymentsLength + 2));
                }

                rows[0][0] = title;

                // resultText += '<div class = "payment_table_row">';
                // если !paymentSplit, то будет только один row
                // если paymentSplit, то будет rows размерностью options.length 

                if (payments && payments.length) {
                    for (let k = 0; k < payments.length; k++) {
                        const { date, keysWithPayment, oncePayment } = payments[k];
                        header.push(`до ${moment(date, 'DD.MM.YYYY').locale('ru').format('DD MMM')}`);

                        if (paymentSplit) {
                            for (let u = 0; u < options.length; u++) {
                                rows[u][1] = options[u].title;
                                rows[u][2 + k] = keysWithPayment[options[u].key];
                            }
                        } else {
                            rows[0][2 + k] = oncePayment;
                        }

                    }
                } else {
                    // раз цен нет, значит всё бесплатно
                    header.push(``);
                    rows[0][2] = 'Бесплатно';
                }

                // теперь мы всё это дело зашвыриваем 
                resultText += '<div class = "payment_table_row payment_table_header">';

                resultText += header.reduce((memo, item, index) => {
                    if (index === 0) return memo + `<div class = 'payment_table_first_column'>${item || ''}</div>`;
                    if (index === 1) return memo + `<div class = 'payment_table_second_column'>${item || ''}</div>`;
                    return memo + `<div class = 'payment_table_payment_column'>${item || ''}</div>`;
                }, '')

                resultText += '</div>';

                for (let z = 0; z < rows.length; z++) {
                    resultText += '<div class = "payment_table_row">';

                    resultText += rows[z].reduce((memo, item, index) => {
                        if (index === 0) return memo + `<div class = 'payment_table_first_column'>${item || ''}</div>`;
                        if (index === 1) return memo + `<div class = 'payment_table_second_column'>${item || ''}</div>`;
                        return memo + `<div class = 'payment_table_payment_column'>${item || 'бесплатно'}</div>`;
                    }, '')

                    resultText += '</div>';
                }

            }


        }

        // if (endedObj.PaymentMain && endedObj.PaymentMain.PaymentData && endedObj.PaymentMain.PaymentData.participatorsCategorys) {
        //     for (let i = 0; i < endedObj.PaymentMain.PaymentData.participatorsCategorys.length; i++) {
        //         const participatorCategorys = endedObj.PaymentMain.PaymentData.participatorsCategorys[i];
        //         if (participatorCategorys && participatorCategorys.payments && participatorCategorys.payments.length) {
        //             for (let k = 0; k < participatorCategorys.payments.length; k++) {
        //                 if (endedObj.PaymentMain.PaymentData.participatorsCategorys[i].payments[k].date) {
        //                     endedObj.PaymentMain.PaymentData.participatorsCategorys[i].payments[k].date = moment(endedObj.PaymentMain.PaymentData.participatorsCategorys[i].payments[k].date).locale('ru').format('DD.MM.YYYY');
        //                 }
        //             }
        //         }
        //     }
        // }

        return <div dangerouslySetInnerHTML={{ __html: resultText }} />;

    }

}



export default withCapoeiraSportState('client, iamuser')(AddEventData);