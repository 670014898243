import React, { Component } from 'react';
import { Input, Icon } from 'antd';
import ButtonPlashka from 'src/ver2/plashki/ui/button';

export class AdderParticipatorsSelect extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState();
    }

    initialState = () => {
        this.keyId = 1;
        return {
            title: '',
            type: 'select',
            isNecessery: false,
            options: [],

            addingOption: {
                title: '',
                limits: []
            }
        }
    }

    titleChange = (e) => {
        this.setState({ title: e.target.value })
    }

    addOption = ({ title, limits }) => {
        const { options: options_ } = this.state;
        let options = [...options_];
        options.push({ key: this.keyId++, title, limits });
        this.setState({
            options,
            addingOption: {
                title: '',
                limits: []
            }
        });
    }

    changeOption = ({ key, title, limits }) => {
        const { options: options_ } = this.state;
        let options = [];
        for (let i = 0; i < options_.length; i++) {
            if (key === options_[i].key) {
                options.push({ key, title, limits });
            } else
                options.push(options_[i]);
        }
        this.setState({ options });
    }

    removeOption = (key) => {
        const { options: options_ } = this.state;
        let options = [];
        for (let i = 0; i < options_.length; i++) {
            if (key === options_[i].key) {
                continue;
            }
            options.push(options_[i]);
        }
        this.setState({ options });
    }

    OneSelect = ({ option, index }) => {

        const { title, key } = option;
        const { options } = this.state;

        return <div className='data_changer_aps_oneselect'>
            <Input value={title} onChange={(e) => { this.changeOption({ ...option, title: e.target.value }) }} />
            {(options.length > 2 && index > 1) ? <Icon className='global-tooltip' data-tooltip={'удалить'} type='cross' onClick={() => { this.removeOption(key) }} /> : null}
        </div>
    }

    changeAddingOption = (addingOption) => {
        this.setState({ addingOption });
    }

    AddingOption = () => {

        const { addingOption } = this.state;
        const {
            title,
            limits
        } = addingOption;

        return <div className='data_changer_aps_oneselect_adding'>
            <Input
                value={title}
                onChange={(e) => { this.changeAddingOption({ ...addingOption, title: e.target.value }) }}
                suffix={(title && title !== '') ? <Icon className='global-tooltip' data-tooltip={'добавить'} type='plus' onClick={() => { this.addOption(addingOption) }} /> : <div />}
            />
        </div>
    }

    onAddButtonClick = () => {

        const { title, type, isNecessery, options } = this.state;
        const { onAddData } = this.props;

        onAddData({ title, type, isNecessery, options });

        this.setState(this.initialState())
    }

    render() {

        const { title, options } = this.state;

        return <div className='data_changer_aps_main'>
            <div className='data_changer_aps_main_options'>
                <div className='data_changer_aps_left'>
                    <Input value={title} onChange={this.titleChange} />
                </div>
                <div className='data_changer_aps_right'>
                    {options.map((value, index) => {
                        return <this.OneSelect key={'dcapsr-' + index} option={value} index={index} />
                    })}
                    <this.AddingOption />
                </div>
            </div>
            <div className='data_changer_footer'>
                <ButtonPlashka
                    upperStyle={{ width: 100, height: 30 }}
                    disabled={options.length < 2}
                    disabledDiscription={'Введены минимум два поля'}
                    onClick={this.onAddButtonClick}
                    text={'ДОБАВИТЬ'}
                />
            </div>
        </div>
    }

}