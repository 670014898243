import { createContext } from 'react';
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { sortBy, groupBy } from 'lodash';
import { message } from 'antd';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

const context = createContext(
    {
        defVal: 'FUUUCK YEAH'
    }
);

/*

  Это контекст, в который приходит groupId, он его подгружает и всё выдает наследникам контекста
  в пропсы ожидает groupId, client, iamuser

*/


const initialState = {

    Group: null, // это группа,самое главное
    isGroupReady: false,

    GroupResults: null,
    isGroupResultsReady: false,

    Students: null,
    isStudentsReady: false,

    Instructors: null,
    isInstructorsReady: false,

    Cordaos: null,
    isCordaosReady: false,

    //NewNews: null,
    //isNewNewsReady: false,

    Follows: null, // это то, за чем мы следим
    isFollowsReady: false,

    Results: null,
    isResultsReady: false,

    ShopId: null,

    iAmCreator: null,
    iAmInstructor: null,
    iAmStudent: null,

    nowGroupVideoId: null
}

class GroupState extends PureComponent {


    constructor(props) {
        super(props);

        this.state = initialState;

        if (props.client) {
            this.client = props.client;
        }

    }

    initialLoad = () => {
        const Group = this.loadGroup();
        console.log('Пробую подгрузить группу')
        if (!Group) return this.setState({ isGroupReady: false });
        this.setState({ isGroupReady: true, Group });

        // this.reloadDiscription();
        // this.reloadNewNews();
    }

    componentDidMount() {
        this.initialLoad();
    }

    reloadGroup = async () => {
        this.setState(initialState);
    }

    componentDidUpdate({ groupsLoaded, iamuser, match }, { isGroupReady, isStudentsReady, isInstructorsReady }) {

        if (match.params.groupId !== this.props.match.params.groupId) {
            this.reloadGroup().then(() => {
                this.initialLoad();
            })
        }

        //подгрузили группы
        if (!groupsLoaded && this.props.groupsLoaded && !this.state.isGroupReady) {
            this.initialLoad();
        }

        // подгрузили группу        
        if (!isGroupReady && this.state.isGroupReady) {
            this.checkIfAmCreatorAsync();
            this.reloadStudents();
            this.reloadInstructors();
            this.reloadGroupResults();
            this.reloadGroupShop();
            this.reloadGroupVideo();
            this.reloadGroupStatistics();
            this.reloadDiscription();
            // this.reloadDiscription();
            // this.reloadNewNews();
            // this.reloadParticipators();
            // this.reloadParticipatorsData();
            // this.reloadFollows();
        }

        // подгрузили студентов        
        if (!isStudentsReady && this.state.isStudentsReady) {
            this.checkIfAmStudentAsync();
        }

        if (!isInstructorsReady && this.state.isInstructorsReady) {
            this.checkIfAmInstructorAsync();
        }

        if (this.props.iamuser !== iamuser) {
            this.checkIfAmCreatorAsync();
            this.checkIfAmStudentAsync();
            this.checkIfAmInstructorAsync();
        }

    }

    loadGroup = () => {
        const { client } = this.props;
        const groupId = this.props.match.params.groupId;
        const Group = client.getGroupById(groupId);

        if (!Group) return null;

        const { Settings } = Group;
        if (!Settings) return Group;

        const SettingsObj = JSON.parse(Settings);

        return { ...Group, SettingsObj };
    }

    checkIfAmCreatorAsync = () => {
        this.checkIfAmCreator().then((iAmCreator) => { this.setState({ iAmCreator }) })
    }

    // потом это будет асинхронная хрен, т.к. будем подгружать роли
    checkIfAmCreator = async () => {
        const { iamuser } = this.props;
        const { Group } = this.state;

        if (!iamuser || !Group) return null;

        if (Group.CreatorId === iamuser.Id) return { role: 'creator' };
        return null;
    }

    // GROUP RESULTS //////////////////////////////////////////
    loadGroupResults = async () => {
        const { Group } = this.state;
        if (!Group) return;
        const { client } = this.props;
        const GroupResults = await client.getGroupResultsByDate(Group.Id);
        return GroupResults;
    }

    reloadGroupResults = () => {
        this.setState({ isGroupResultsReady: false });
        this.loadGroupResults().then(
            (GroupResults) => {
                this.setState({ isGroupResultsReady: true, GroupResults });
            }
        )
    }

    // SHOP
    reloadGroupShop = async () => {
        const { Group } = this.state;
        if (!Group) return;
        const { client } = this.props;
        const Shop = await client.getShop(null, 'group', Group.Id);

        if (Shop && Shop.Id) this.setState({ ShopId: Shop.Id });
        return;
    }

    // VIDEO

    reloadGroupStatistics = async () => {
        const { Group } = this.state;
        if (!Group) return;
        const { client } = this.props;
        const subgroups = await client.getSubgroups(Group.Id);

        if (!subgroups || !subgroups.length) {
            return this.setState({ isPersonVisits: null })
        }

        const visitsStatistics = await client.groupVisitsEasyStatistics(Group.Id, 9, 2024)
        if (!subgroups || !visitsStatistics || !visitsStatistics.personVisits || !visitsStatistics.personVisits.length) {
            return this.setState({ isPersonVisits: null })
        }

        const { personVisits } = visitsStatistics;

        let visitsRes = {}

        const res = groupBy(personVisits, 'SubgroupId');
        for (const sbId in res) {
            visitsRes[sbId] = {
                all: []
            }
            res[sbId] = groupBy(res[sbId], 'UserId');
            for (const usId in res[sbId]) {
                if (Number(usId) === Group.CreatorId) continue;
                let userAll = { UserId: usId, Count: 0 };
                res[sbId][usId] = groupBy(res[sbId][usId], 'Month');
                for (const month in res[sbId][usId]) {
                    const { Count } = res[sbId][usId][month][0];
                    userAll.Count += Count;

                    if (!visitsRes[sbId][month]) visitsRes[sbId][month] = [];
                    visitsRes[sbId][month].push({ UserId: usId, Count })
                }
                visitsRes[sbId].all.push(userAll);
            }
        }

        for (const sbId in visitsRes) {
            for (const month in visitsRes[sbId]) {
                visitsRes[sbId][month] = groupBy(visitsRes[sbId][month], 'Count');

                let resThing = [];
                const sbMonthVis = Object.values(visitsRes[sbId][month]).reverse();
                let Place = 1;
                let PlaceNameStart = 1;
                for (const placeId in sbMonthVis) {
                    for ( const usIndex in sbMonthVis[placeId]) {
                        const { UserId, Count } = sbMonthVis[placeId][usIndex];
                        resThing.push(
                            { 
                                UserId, 
                                Count, 
                                Place,
                                PlaceName: sbMonthVis[placeId].length < 2 ? PlaceNameStart : `${PlaceNameStart} - ${Place + sbMonthVis[placeId].length - 1}` 
                            });
                    }
                    PlaceNameStart += sbMonthVis[placeId].length;
                    Place ++;
                }
                visitsRes[sbId][month] = resThing;
            }
        }

        this.setState({ isPersonVisits: true, visits: { data: visitsRes, subgroups} });
        return;
    }

    reloadGroupVideo = async () => {
        const { Group } = this.state;
        if (!Group) return;
        const { client } = this.props;
        const Videos = await client.getGroupVideo({ GroupId: Group.Id });

        console.log('reloadGroupVideo', { Videos });

        let nowGroupVideoId = null;

        if (Videos && Videos.length) {
            const index = Number((Math.random() * (Videos.length - 1)).toFixed());
            if (Videos[index])
                nowGroupVideoId = Videos[index].VideoId;
        }

        this.setState({ nowGroupVideoId });
        return;
    }

    // STUDENTS //////////////////////////////////////////
    loadStudents = async () => {
        const { Group } = this.state;
        if (!Group) return;
        const { client } = this.props;
        const Students = await client.getStudentsFromServer(Group.Id);
        return Students;
    }

    reloadStudents = () => {
        this.setState({ isStudentsReady: false });
        this.loadStudents().then(
            (Students) => {
                this.setState({ isStudentsReady: true, Students });
            }
        )
    }

    // addParticipator = (UserId, participatorData = null) => {
    //     this.setState({ isParticipatorsReady: false });
    //     const { client } = this.props;
    //     const { Event } = this.state;
    //     client.addParticipator(
    //         {
    //             EventId: Event.Id, UserId, Settings: participatorData || '{}'
    //         }
    //     ).then(() => {
    //         this.reloadParticipators();
    //     })
    // }

    updateStudent = (UserId, data) => {
        this.setState({ isStudentsReady: false });
        const { client } = this.props;
        client.changeStudentData(UserId, data).then(() => {
            client.addNotification({
                UserId,
                Body: `^a_^text^a_^Инструктор изменил ваши контактные данные!`
            })
            this.reloadStudents();
        })
    }

    deleteStudent = (UserId) => {
        this.setState({ isStudentsReady: false });
        const { client } = this.props;
        const { Group } = this.state;
        client.changeStudentData(UserId, { deleteFromGroup: true }).then(() => {
            client.addNotification({
                UserId,
                Body: `^a_^text^a_^Вы были удалены из группы ${Group.Name}`
            })
            this.reloadStudents();
        })
    }

    checkIfAmStudentAsync = () => {
        this.checkIfAmStudent().then((iAmStudent) => { this.setState({ iAmStudent }) })
    }

    // потом это будет асинхронная хрен, т.к. будем подгружать участник ли ты
    checkIfAmStudent = async () => {
        const { iamuser } = this.props;
        const { Group, Students } = this.state;
        if (!iamuser || !Group || !Students) return null;

        if (iamuser.GroupIamInId === Group.Id) return true;

        return false;

        // console.log('checkIfAmStudent', Students);

        // if (!iamuser || !Group || !Students) return null;

        // const students_ = Students.find(x => x.Id === iamuser.Id);
        // if (students_) return participator_;

        // return null;
    }

    // INSTRUCTORS //////////////////////////////////////////
    loadInstructors = async () => {
        const { Group } = this.state;
        if (!Group) return;
        const { client } = this.props;
        const Instructors = await client.getGroupRoles(Group.Id);
        return Instructors;
    }

    reloadInstructors = () => {
        this.setState({ isInstructorsReady: false });
        this.loadInstructors().then(
            (Instructors) => {
                this.setState({ isInstructorsReady: true, Instructors });
            }
        )
    }

    checkIfAmInstructorAsync = () => {
        this.checkIfAmInstructor().then((iAmInstructor) => { this.setState({ iAmInstructor }) })
    }

    // потом это будет асинхронная хрен, т.к. будем подгружать участник ли ты
    checkIfAmInstructor = async () => {
        const { iamuser } = this.props;
        const { Group, Instructors } = this.state;
        if (!iamuser || !Group || !Instructors) return null;

        const instructors_ = Instructors.find(x => x.UserId === iamuser.Id);
        if (instructors_) return instructors_;

        return false;
    }

    //
    updateGroupInfo = async ({ Name }) => {
        const { client } = this.props;
        const { Group } = this.state;
        if (!Group) return;
        await client.updateGroupInfo({ GroupId: Group.Id, Name });
        await client.getGroupsFromServer();
        const Group_ = this.loadGroup();
        this.setState({ isGroupReady: true, Group: Group_ });
        message.success('Изменения сохранены');
    }

    // это добавить instructor именно как юзера
    addInstructor = ({ UserId, Role }) => {
        this.setState({ isInstructorsReady: false });
        const { client } = this.props;
        const { Group } = this.state;
        // ТУТ ХЗ ЧТО И КАК
        client.addGroupRoles(
            {
                GroupId: Group.Id,
                UserId,
                Role
            }
        ).then(() => {
            this.reloadInstructors();
            message.info(`Добавлен ${Role}`);
        })
    }

    deleteInstructor = (RoleId) => {
        this.setState({ isInstructorsReady: false });
        const { client } = this.props;
        // ТУТ ХЗ ЧТО И КАК
        client.removeRole(RoleId).then(() => {
            this.reloadInstructors();
        })
    }

    // DISCRIPTION //////////////////////////////////////////
    loadDiscription = async () => {
        const { client } = this.props;
        const { Group } = this.state;
        const Discription = await client.getDiscriptions({ subject: 'grp', subjectId: Group.Id, type: 'info' });
        return Discription ? Discription[0] : null;
    }

    reloadDiscription = () => {
        this.setState({ isDiscriptionReady: false });
        this.loadDiscription().then(
            (Discription) => {
                this.setState({ isDiscriptionReady: true, Discription });
            }
        )
    }

    addDiscription = async (body) => {
        const { client } = this.props;
        const { Group } = this.state;
        await client.addDiscription({ body, subject: 'grp', subjectId: Group.Id, type: 'info' });
        this.reloadDiscription();
        message.success('Добавлено инфо');
    }

    updateDiscription = async (body) => {
        const { client } = this.props;
        const { Discription } = this.state;
        if (!Discription) return;
        const discriptionId = Discription.Id;
        await client.updateDiscription({ discriptionId, body });
        this.reloadDiscription();
        message.success('Изменения сохранены');
    }

    setBackground = (clear = false) => {

        if (clear) {
            const back = document.getElementById('main-backimage-id');
            if (back)
                back.style.background = 'none';
            return;
        }

        const { Event } = this.state;
        if (!Event) return;

        // прокидка фона
        const back = document.getElementById('main-backimage-id');
        if (back) {
            const imageUrl = this.props.client.getEventsUrl() + Event.Id + '.png';
            const style = {
                background: `url(${imageUrl})`
            }
            back.style.background = style.background;
            return;
        }
    }

    redirect = (link) => {
        this.props.history.push(link);
    }

    render() {
        const {
            props: {
                children,
                location,
                client,
                iamuser,
                showInfoModal,
                isMobile
            },
            state: {
                Group, // это группа,самое главное
                isGroupReady,

                isGroupResultsReady,
                GroupResults,

                Students,
                isStudentsReady,

                Instructors,
                isInstructorsReady,

                Cordaos,
                isCordaosReady,

                nowGroupVideoId,

                //NewNews: null,
                //isNewNewsReady: false,

                Follows, // это то, за чем мы следим
                isFollowsReady,

                Results,
                isResultsReady,

                isDiscriptionReady,
                Discription,

                visits,
                isPersonVisits,

                ShopId,

                iAmCreator,
                iAmInstructor,
                iAmStudent
            },
            redirect,
            updateStudent,
            deleteStudent,
            addInstructor,
            deleteInstructor,
            updateGroupInfo,

            reloadDiscription,
            addDiscription,
            updateDiscription,

            setBackground
        } = this;

        return (
            <context.Provider value={{
                Group, // это группа,самое главное
                isGroupReady,

                isGroupResultsReady,
                GroupResults,

                Students,
                isStudentsReady,

                Instructors,
                isInstructorsReady,

                Cordaos,
                isCordaosReady,

                //NewNews: null,
                //isNewNewsReady: false,

                Follows, // это то, за чем мы следим
                isFollowsReady,

                Results,
                isResultsReady,

                visits,
                isPersonVisits,

                ShopId,

                nowGroupVideoId,

                iAmCreator,
                iAmInstructor,
                iAmStudent,

                redirect,

                reloadDiscription,
                addDiscription,
                updateDiscription,
                isDiscriptionReady,
                Discription,

                updateStudent,
                deleteStudent,

                addInstructor,
                deleteInstructor,

                updateGroupInfo,

                client,
                location,
                iamuser,
                isMobile
            }}>
                {children}
            </context.Provider>
        );
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile, groupsLoaded, showInfoModal')(GroupState);


// ТУТ У НАС ИДЁТ ДЕКОРАТОР, КОТОРЫЙ ПОЗВОЛЯЕТ ПОЛУЧАТЬ ИЗ СТЕЙТА НУЖНЫЕ ДАННОМУ КОМПОНЕНТУ ЗНАЧЕНИЯ

export const withGroupState = (withProps = '') => {
    return (
        (Wrapped) =>
            (props) => (
                <context.Consumer>
                    {
                        (context) => (
                            <Wrapped
                                {...props}
                                {...getWhatINeed(withProps, context)}
                            />
                        )
                    }
                </context.Consumer>
            )
    );
};

const getWhatINeed = (withProps, context) => {

    if (withProps === '') return context;

    return withProps.split(',').reduce((memo, value_) => {
        const value = value_.trim();
        if (!context[value]) {
            return memo;
        }
        return { ...memo, [value]: context[value] }
    }, {})


}

