import React, { PureComponent } from 'react';
import LittlePerson from 'src/ver2/components/person/little';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import Loader from 'src/ver2/components/loader';

import './style.css';

//getEventById

/**
  Это маленький список людей с очками
  Сюда приходит массив людей Persons ([ {...Person}, ... ])
 */

export default class PersonsLittleListWitchScoresPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { Persons, elementHeight, upperStyle, personsDirect } = this.props;

        if (!Persons) return null;
        const { width, height } = upperStyle;

        if (Persons === 'loading') return <div style={{ width, height: Math.max(height, 100) }}  className='persons_little_plashka'>
          <Loader/>
         </div>

        console.log ('PersonsLittleListWitchScoresPlashka', Persons);

        /*const style = {
            ...height ? { height } : {}
        }*/

        // const { width, height } = this.props.upperStyle;

        return <div id = {'persons_little_plashka_id'} style={{ width, height }} className='persons_little_plashka'>
            {Object.keys(Persons).map((personKey) => {
                const person = Persons[personKey];
                const { UserId, Scores } = person;

                if (personsDirect) {
                    return <LittlePerson personsDirect = {true} height={elementHeight || 50} key={'plt' + UserId} 
                    // personId={UserId}   
                    DirectPerson = {{...person, Id: person.UserId}}                  
                    Suffix = { <NumberText count={Scores} text={'ОЧКИ'} height={elementHeight || 50 * 0.7} />  } />                   
                }

                return <LittlePerson height={elementHeight || 50} key={'plt' + UserId} 
                personId={UserId}                     
                Suffix = { <NumberText count={Scores} text={'ОЧКИ'} height={elementHeight || 50 * 0.7} />  } />
            })}
        </div>
    }

}

// export default withCapoeiraSportState('client')(EventLittleListPlashka);