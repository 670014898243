import React from 'react';

export const GeksSVG = () => <svg
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    className='geks_svg'
>
    <path id='PPP' className="geks_svg_path" d="M72.53 10.98l-13.76 -7.95 -0.1 -0.06c-3.23,-1.85 -5.67,-2.97 -8.67,-2.97 -3,0 -5.44,1.12 -8.67,2.97l-0.1 0.06 -13.76 7.95 -13.76 7.94 -0.1 0.06c-3.22,1.87 -5.41,3.42 -6.91,6.02 -1.5,2.6 -1.76,5.27 -1.76,8.99l0 0.12 0 15.89 0 15.89 0 0.12c0,3.72 0.26,6.39 1.76,8.99 1.5,2.6 3.69,4.15 6.91,6.02l0.1 0.06 13.76 7.94 13.76 7.95 0.1 0.06c3.23,1.85 5.67,2.97 8.67,2.97 3,0 5.44,-1.12 8.67,-2.97l0.1 -0.06 13.76 -7.95 13.76 -7.94 0.1 -0.06 0.05 -0.03c3.2,-1.85 5.37,-3.41 6.86,-5.99 1.5,-2.6 1.76,-5.27 1.76,-8.99l0 -0.12 0 -15.89 0 -15.89 0 -0.12 0 -0.07c-0.01,-3.69 -0.27,-6.34 -1.76,-8.92 -1.5,-2.6 -3.69,-4.15 -6.91,-6.02l-0.1 -0.06 -13.76 -7.94z" />
</svg>

export const GeksSVGImage = ({ material }) => <svg
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    className='geks_svg'
>
    <defs>
        <mask id='mask-1' x='0' y='0' width='100' height='100'>
            <path className="geks_svg_path" fill='white' d="M72.53 10.98l-13.76 -7.95 -0.1 -0.06c-3.23,-1.85 -5.67,-2.97 -8.67,-2.97 -3,0 -5.44,1.12 -8.67,2.97l-0.1 0.06 -13.76 7.95 -13.76 7.94 -0.1 0.06c-3.22,1.87 -5.41,3.42 -6.91,6.02 -1.5,2.6 -1.76,5.27 -1.76,8.99l0 0.12 0 15.89 0 15.89 0 0.12c0,3.72 0.26,6.39 1.76,8.99 1.5,2.6 3.69,4.15 6.91,6.02l0.1 0.06 13.76 7.94 13.76 7.95 0.1 0.06c3.23,1.85 5.67,2.97 8.67,2.97 3,0 5.44,-1.12 8.67,-2.97l0.1 -0.06 13.76 -7.95 13.76 -7.94 0.1 -0.06 0.05 -0.03c3.2,-1.85 5.37,-3.41 6.86,-5.99 1.5,-2.6 1.76,-5.27 1.76,-8.99l0 -0.12 0 -15.89 0 -15.89 0 -0.12 0 -0.07c-0.01,-3.69 -0.27,-6.34 -1.76,-8.92 -1.5,-2.6 -3.69,-4.15 -6.91,-6.02l-0.1 -0.06 -13.76 -7.94z" />
        </mask>
    </defs>
    <image x='0' y='0' xlinkHref={`/../images/ver2/textures/gek_${material}.jpg`} width='100' height='100' mask={`url(#mask-1)`} />
</svg>

export const GeksSVGFilled = ({ pId }) => <svg
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    className='geks_svg'
>
    <defs>
        <clipPath id='mask-2'>
            <path className="geks_svg_path" fill='white' stroke='none' d="M72.53 10.98l-13.76 -7.95 -0.1 -0.06c-3.23,-1.85 -5.67,-2.97 -8.67,-2.97 -3,0 -5.44,1.12 -8.67,2.97l-0.1 0.06 -13.76 7.95 -13.76 7.94 -0.1 0.06c-3.22,1.87 -5.41,3.42 -6.91,6.02 -1.5,2.6 -1.76,5.27 -1.76,8.99l0 0.12 0 15.89 0 15.89 0 0.12c0,3.72 0.26,6.39 1.76,8.99 1.5,2.6 3.69,4.15 6.91,6.02l0.1 0.06 13.76 7.94 13.76 7.95 0.1 0.06c3.23,1.85 5.67,2.97 8.67,2.97 3,0 5.44,-1.12 8.67,-2.97l0.1 -0.06 13.76 -7.95 13.76 -7.94 0.1 -0.06 0.05 -0.03c3.2,-1.85 5.37,-3.41 6.86,-5.99 1.5,-2.6 1.76,-5.27 1.76,-8.99l0 -0.12 0 -15.89 0 -15.89 0 -0.12 0 -0.07c-0.01,-3.69 -0.27,-6.34 -1.76,-8.92 -1.5,-2.6 -3.69,-4.15 -6.91,-6.02l-0.1 -0.06 -13.76 -7.94z" />
        </clipPath>
    </defs>
    <g clipPath={`url(#mask-2)`} >
        <rect fill='none' stroke='none' id={`geks_svg_rect_${pId}`} x="0" y='0' width="100" height="100" />
    </g>
</svg>

export const GeksSVGFrame = ({ color1, color2, colorBorder, material }) => <svg
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    className='geks_svg'
>
    <defs>
        <linearGradient id={`grad_${material}`} x1={50} y1={0} x2={50} y2={100} gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color={color1} />
            <stop offset="1" stop-color={color2} />
        </linearGradient>
    </defs>
    <path style={{
        stroke: colorBorder,
        fill: `url(#grad_${material})`,
        strokeWidth: '2%'
    }}

        d="M50 5.59c2.66,0 4.83,0.99 7.7,2.64l0.09 0.05 12.22 7.06 12.22 7.05 0.1 0.06c2.86,1.65 4.8,3.03 6.13,5.34 1.33,2.3 1.56,4.65 1.57,7.92l0 0.07 0 0.11 0 14.11 0 14.11 0 0.11c-0.01,3.31 -0.23,5.68 -1.57,7.99 -1.32,2.29 -3.25,3.67 -6.09,5.32l-0.04 0.02 -0.1 0.06 -12.22 7.05 -12.22 7.06 -0.09 0.05c-2.87,1.65 -5.04,2.64 -7.7,2.64 -2.66,0 -4.83,-0.99 -7.7,-2.64l-0.09 -0.05 -12.22 -7.06 -12.22 -7.05 -0.1 -0.06c-2.86,-1.65 -4.8,-3.03 -6.13,-5.34 -1.34,-2.31 -1.56,-4.68 -1.57,-7.99l0 -0.11 0 -14.11 0 -14.11 0 -0.11c0.01,-3.31 0.23,-5.68 1.57,-7.99 1.33,-2.31 3.27,-3.69 6.13,-5.34l0.1 -0.06 12.22 -7.05 12.22 -7.06 0.09 -0.05c2.87,-1.65 5.04,-2.64 7.7,-2.64zm-8.67 91.44c3.23,1.85 5.67,2.97 8.67,2.97 3,0 5.44,-1.12 8.67,-2.97l0.1 -0.06 13.76 -7.95 13.76 -7.94 0.1 -0.06 0.05 -0.03c3.2,-1.85 5.37,-3.41 6.86,-5.99 1.5,-2.6 1.76,-5.27 1.76,-8.99l0 -0.12 0 -15.89 0 -15.89 0 -0.12 0 -0.07c-0.01,-3.69 -0.27,-6.34 -1.76,-8.92 -1.5,-2.6 -3.69,-4.15 -6.91,-6.02l-0.1 -0.06 -13.76 -7.94 -13.76 -7.95 -0.1 -0.06c-3.23,-1.85 -5.67,-2.97 -8.67,-2.97 -3,0 -5.44,1.12 -8.67,2.97l-0.1 0.06 -13.76 7.95 -13.76 7.94 -0.1 0.06c-3.22,1.87 -5.41,3.42 -6.91,6.02 -1.5,2.6 -1.75,5.27 -1.76,8.99l0 0.12 0 15.89 0 15.89 0 0.12c0.01,3.72 0.26,6.39 1.76,8.99 1.5,2.6 3.69,4.15 6.91,6.02l0.1 0.06 13.76 7.94 13.76 7.95 0.1 0.06z" />

</svg>