import React, { PureComponent } from 'react';
import { isEqual } from 'lodash';
import './style.css';

import { GeksSVG, GeksSVGFrame, GeksSVGImage, GeksSVGFilled } from './svg';
import { navIcon } from 'src/ver2/components/ui/icons';
import { gsap } from 'gsap';

/* 

    width - ширина 
    styleType = gold, none

*/

export class Geks extends PureComponent {
    constructor(props) {
        super(props);
        this.Id = (Math.random() * 100000).toFixed();
    }

    startAnimation = () => {
        const { fill = {} } = this.props;
        const {
            startColor,
            endColor,
            value,
            noAnimation
        } = fill;

        if (fill && value) {

            if (noAnimation) {
                gsap.fromTo(
                    `#geks_svg_rect_${this.Id}`,
                    { y: 100 },
                    {
                        duration: 0.01, y: 100 - 100 * value, fill: endColor, ease: "power1.inOut",
                    }
                );
                return;
            }

            let colorAnimation = gsap.fromTo(
                `#geks_svg_rect_${this.Id}`,
                { fill: startColor },
                { duration: 3, delay: 1, fill: endColor }
            );

            gsap.fromTo(
                `#geks_svg_rect_${this.Id}`,
                { y: 100 },
                {
                    duration: 3 * value, y: 100 - 100 * value, ease: "power1.inOut",
                    delay: 1,
                    onComplete: () => {
                        colorAnimation.pause();
                    }
                }
            );
        }
    }

    componentDidMount = () => {
        this.startAnimation();
    }

    componentDidUpdate = (nextProps) => {
        if (!isEqual(this.props.fill, nextProps.fill)) {
            this.startAnimation();
        }
    }

    render() {

        const { width = 100 } = this.props;

        return <div style={{ width }} id={this.Id} className='geks_container'>
            {this.geksRender()}
            {this.titleRender()}
        </div>
    }

    geksRender = () => {
        const { width = 100, styleType = 'none', icon, imgUrl, text, frameOnly, fill } = this.props;

        const iconKoef = 0.7;

        if (styleType === 'gold') {
            return <div style={{ width, height: width }} className='geks_container_svg'>
                {!frameOnly && <GeksSVGImage material={'gold'} />}
                {fill && <GeksSVGFilled pId={this.Id} />}
                <GeksSVGFrame color1={'#AD0C0C'} color2={'#360404'} colorBorder={'#FFDE96'} material={'gold'} />
                {
                    icon && navIcon(icon, false, { width: width * iconKoef, height: width * iconKoef }, 'geks_container_svg_icon geks_container_svg_icon_gold')
                }
                {
                    imgUrl && <img style={{ width: width * iconKoef, height: width * iconKoef }} src={imgUrl} />
                }
                {
                    text ? <div className='geks_container_svg_text geks_container_svg_text_gold'>{text}</div> : null
                }
            </div>
        }
        if (styleType === 'silver') {
            return <div style={{ width, height: width }} className='geks_container_svg'>
                {!frameOnly && <GeksSVGImage material={'silver'} />}
                {fill && <GeksSVGFilled pId={this.Id} />}
                <GeksSVGFrame color1={'#01345D'} color2={'#001322'} colorBorder={'#E6E6E6'} material={'silver'} />
                {
                    icon && navIcon(icon, false, { width: width * iconKoef, height: width * iconKoef }, 'geks_container_svg_icon geks_container_svg_icon_silver')
                }
                {
                    imgUrl && <img style={{ width: width * iconKoef, height: width * iconKoef }} src={imgUrl} />
                }
                {
                    text ? <div className='geks_container_svg_text geks_container_svg_text_silver'>{text}</div> : null
                }
            </div>
        }
        if (styleType === 'bronze') {
            return <div style={{ width, height: width }} className='geks_container_svg'>
                {!frameOnly && <GeksSVGImage material={'bronze'} />}
                {fill && <GeksSVGFilled pId={this.Id} />}
                <GeksSVGFrame color1={'#883600'} color2={'#321400'} colorBorder={'#AD5113'} material={'bronze'} />
                {
                    icon && navIcon(icon, false, { width: width * iconKoef, height: width * iconKoef }, 'geks_container_svg_icon geks_container_svg_icon_bronze')
                }
                {
                    imgUrl && <img style={{ width: width * iconKoef, height: width * iconKoef }} src={imgUrl} />
                }
                {
                    text ? <div className='geks_container_svg_text geks_container_svg_text_bronze'>{text}</div> : null
                }
            </div>
        }
        if (styleType === 'blank') {
            return <div style={{ width, height: width }} className='geks_container_svg geks_container_blank'>
                <GeksSVG />
                {
                    icon && navIcon(icon, false, { width: width * iconKoef, height: width * iconKoef }, 'geks_container_svg_icon geks_container_svg_icon_silver')
                }
                {
                    imgUrl && <img style={{ width: width * iconKoef, height: width * iconKoef }} src={imgUrl} />
                }
                {
                    text ? <div className='geks_container_svg_text'>{text}</div> : null
                }
            </div>
        }
        if (styleType === 'filled') {
            return <div style={{ width, height: width }} className='geks_container_svg geks_container_blank'>
                <GeksSVGFilled pId={this.Id} />
                <GeksSVG />
                {
                    icon && navIcon(icon, false, { width: width * iconKoef, height: width * iconKoef }, 'geks_container_svg_icon geks_container_svg_icon_silver')
                }
                {
                    imgUrl && <img style={{ width: width * iconKoef, height: width * iconKoef }} src={imgUrl} />
                }
                {
                    text ? <div className='geks_container_svg_text'>{text}</div> : null
                }
            </div>
        }

        return <div />

    }
    titleRender = () => {
        const { title, width = 100, lowTitleStyle } = this.props;

        if (!title) return null;

        let className = 'geks_container_title';

        if (lowTitleStyle) className += ' geks_container_title_low'

        return <div style={{ width, height: width / 3 }} className={className}>
            <span>{title}</span>
        </div>
    }

}