// Тут у нас всякие косметические штуки - предметы, беримбавы, горшки и т.д.
import testOrange from './cosmet/testOrange';
import plants from './cosmet/plants';
import icons from './cosmet/icons';
import nagrads from './cosmet/nagrads';
import shalves from './cosmet/shalves';
import oboi from './cosmet/oboi';
import toys from './cosmet/toys';
import flags from './cosmet/flags';
import berimbao from './cosmet/berimbao';
import covers from './cosmet/covers';
import larger from './cosmet/larger';
import island from './cosmet/island';
import chests from './cosmet/chests';

export const COSMETIC_TYPES = {
    default: {
        src: () => ``,
        discription: 'Нет описания для данного типа',
        title: 'Не определено'
    },
    berimbao: {
        src: (grade = 1) => `/../images/ver2/scores/types/berimbao_${grade}.png`,
        discription: 'Это беримбао, которые вы можете поставить себе под аватарку',
        title: 'Беримбао'
    },
    icon: {
        src: (grade = 1) => `/../images/ver2/scores/types/icon_${grade}.png`,
        discription: 'Это набольшая иконка, которую вы можете поставить себе перед именем и фамилией',
        title: 'Иконка'
    },
    stuff: {
        src: (grade = 1) => `/../images/ver2/scores/types/stuff_${grade}.png`,
        discription: '',
        title: 'На стену'
    },
    up: {
        src: (grade = 1) => `/../images/ver2/scores/types/up_${grade}.png`,
        title: ''
    },
    doc: {
        src: () => `/../images/ver2/scores/types/doc_1.png`,
        title: ''
    },
    paint: {
        src: (grade = 1) => `/../images/ver2/scores/types/paint_${grade}.png`,
        title: 'Окраска'
    },
    nagrada: {
        src: (grade = 1) => `/../images/ver2/scores/types/nagrada_${grade}.png`,
        title: 'Награды'
    },
    chest: {
        src: (grade = 1) => `/../images/ver2/scores/types/chest_${grade}.png`,
        title: 'Сундуки'
    },
    island: {
        src: () => `/../images/ver2/scores/subtypes/island.png`,
        title: 'Квест'
    },
}

export const COSMETIC_SUBTYPES = {
    default: {
        subtypeSrc: null,
        subtypeDiscription: 'Нет описания для данного типа',
        subtypeTitle: 'Не определено'
    },
    berimba: {
        subtypeSrc: `/../images/ver2/scores/subtypes/berimba.png`,
        subtypeDiscription: 'Беримба - это часть беримбао, которые вы можете поставить себе под аватарку',
        subtypeTitle: 'Беримба'
    },
    cabaca: {
        subtypeSrc: `/../images/ver2/scores/subtypes/cabaca.png`,
        subtypeDiscription: 'Кабаса - это часть беримбао, которые вы можете поставить себе под аватарку',
        subtypeTitle: 'Кабаса'
    },
    flag: {
        subtypeSrc: `/../images/ver2/scores/subtypes/flag.png`,
        subtypeDiscription: 'Флаг, который вы можете повесить на стену',
        subtypeTitle: 'Флаг'
    },
    plant: {
        subtypeSrc: `/../images/ver2/scores/subtypes/plant.png`,
        subtypeDiscription: 'Растение, который вы можете поставите на полку на стене',
        subtypeTitle: 'Растение'
    },
    toy: {
        subtypeSrc: `/../images/ver2/scores/subtypes/toy.png`,
        subtypeDiscription: 'Сувенир, который вы можете поставите на полку на стене',
        subtypeTitle: 'Сувенир'
    },
    medal_shalve: {
        subtypeSrc: `/../images/ver2/scores/subtypes/medal_shalve.png`,
        subtypeDiscription: 'Награда, которую вы можете поставите на полку на стене',
        subtypeTitle: 'Награда'
    },
    shalve: {
        subtypeSrc: `/../images/ver2/scores/subtypes/shalve.png`,
        subtypeDiscription: 'Полка. На неё можно ставить предметы и наградаы',
        subtypeTitle: 'Полка'
    },
    oboi: {
        subtypeSrc: `/../images/ver2/scores/subtypes/oboi.png`,
        subtypeDiscription: 'Обои, изменяющие задний вид стены',
        subtypeTitle: 'Обои'
    },
    cover: {
        subtypeSrc: `/../images/ver2/scores/subtypes/cover.png`,
        subtypeDiscription: 'Обложки, изменяющие вид ваших карточек в челленджах',
        subtypeTitle: 'Обложки карточек'
    },
    island: {
        subtypeSrc: `/../images/ver2/scores/subtypes/island.png`,
        subtypeDiscription: 'Предметы, полученные в ходе прохождения квестов',
        subtypeTitle: 'Квестовые предметы'
    },
    idea: {
        subtypeSrc: `/../images/ver2/scores/subtypes/idea.png`,
        subtypeDiscription: 'Мысли, пришедшие в ходе прохождения квестов',
        subtypeTitle: 'Квестовые мысли'
    },
    larger: {
        subtypeSrc: `/../images/ver2/scores/subtypes/larger.png`,
        subtypeDiscription: 'Расширяет стену на несколько ячеек',
        subtypeTitle: 'Расширитель стены'
    },
}

export const ITEMS = {
    default: {
        type: 'default',
        subtype: '',
        grade: 1,
        discription: 'Ошибка',
        src: ``
    },
    

    // STUFF

    diplom_qw_t1: {
        type: 'stuff',
        subtype: 'diplom',
        grade: 1,
        discription: '',
        src: `/../images/ver2/scores/stuff/diplom_qw_t1.png`,

        w: 2,
        h: 1,
        shiftX: 0,
        shiftY: 0   
    },

    // ДОКУМЕНТЫ

    fkr_accred: {
        type: 'doc',
        grade: 1,
        discription: 'Членство ФКР',
        src: `/../images/ver2/scores/stuff/fkr_accred.png`,
    },

    ...testOrange,
    ...plants,
    ...icons,
    ...nagrads,
    ...shalves,
    ...oboi,
    ...toys,
    ...flags,
    ...berimbao,
    ...covers,
    ...island,
    ...larger,
    ...chests

}