// это стандартный тайтл, который везде используется
import React from 'react';
import { Link } from 'react-router-dom';
import Pump from 'src/ver2/components/halloween';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';

const Footer = ({ isMobile, client, isFooterShowed }) => {

    const { regions, state: { schools } } = client;

    if (!isFooterShowed) return null;

    return <div className={isMobile ? 'global_footer main_center_container_mobile' : 'global_footer'}>
        <div className='global_footer_middle'>
            <div className='global_footer_lefter'>
                <p className='global_footer_p1 global_footer_p1_first'>
                        <Pump width={10} type = {'white'} pumpCode={'ifjfhs'} /> <span>CAPOEIRASPORT.COM 2019 - 2024</span>
                </p>
                <br />
                <p className='global_footer_p2'>Контакты:</p>
                <p className='global_footer_p1'>Разработчик: Чушев Сергей</p>
                <p className='global_footer_p1'>8 926 767 43 16</p>
                <p className='global_footer_p1'>Обращаться по всем вопросам и предложениям</p>
            </div>
            <div className='global_footer_righter'>
                <p className='global_footer_p2'>Регионы:</p>
                <div className='global_footer_p3'>
                    {Object.keys(regions).map(itemKey => {
                        if (itemKey === 'russia') return null;
                        return <a key={`gl_footer_${itemKey}`} href={`/region/${itemKey}`}>{` ${regions[itemKey].title} `}</a>;
                    })}
                </div>
                <br />
                <p className='global_footer_p2'>Школы:</p>
                <div className='global_footer_p3'>
                    {Object.keys(schools).map(schoolKey => {
                        return <a key={`gl_footer_p3_${schoolKey}`} href={`/school/${schoolKey}/info`}>{` ${schools[schoolKey].Name} `}</a>;
                    })}
                </div>
            </div>
        </div>
    </div >
}

export default withCapoeiraSportState('client, isMobile, isFooterShowed')(Footer);