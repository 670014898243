import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import SchoolWrapper from 'src/ver2/components/school/schoolWrapper';
/*import CordaoRect from 'src/ver2/components/cordao/cordaoRect';
import GroupRect from 'src/ver2/components/group/groupRect';
import SchoolRect from 'src/ver2/components/school/schoolRect'
import PersonAvatar from 'src/ver2/components/person/avatar';
*/
import UiChooser from 'src/ver2/plashki/ui/chooser';
import RaitingSubjectTableLayout from 'src/ver2/plashki/raiting/subjectTable';
import RaitingWrapper from 'src/ver2/components/raiting/raitingWrapper';
import PersonsLittleListWitchScoresPlashka from 'src/ver2/plashki/person/littleList/withScores';

import { TrefaLayout } from 'src/ver2/containers/layout';


// import './styles.css';
const PersonsLittleListWitchScoresPlashkaWrapped = (props) => <RaitingWrapper Wrapped={PersonsLittleListWitchScoresPlashka} {...props} componentProps={{ ...props }} />

class RaitingSubjectLayout extends PureComponent {

    setHeight = ( height ) => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height, plashkaId });
    }

    linkTo = (param, value) => {
        let { season, type, category } = this.props;
        let linkObject = { season, type };
        linkObject[param] = value;
        return `?${Object.keys(linkObject).reduce((memo, key, index) => {
            if (!linkObject[key]) return memo;
            return memo + `${index > 0 ? '&' : ''}${key}=${linkObject[key]}`
        }, '')}`
    }

    componentDidMount () {
        if ( this.props.isMobile ) {
            this.setHeight(1500);
        }
    }

    render() {

        const width = 1200;
        const { School, season, type, category, isMobile } = this.props;
        const schoolId = School ? School.Id : null;

        if (isMobile) {
            const { upperStyle } = this.props;
            // const { width, height } = upperStyle;
            const width = upperStyle.width;
            return <div style={{ width }} className=''>
            <TrefaLayout greedsX={[5, 25, (width - 85)/2, 25, (width - 85)/2 , 25, 5]}
                components={[
                    {
                        component: UiChooser,
                        cell_x: 3,
                        collapse: 1,
                        componentProps: {
                            height: 145, yPadding: 'usual', _title: 'Сезон', tab: season, Items: [
                                { Title: '2024-2025', name: '2024-2025', linkTo: `./raiting${this.linkTo('season', '2024-2025')}` },
                                { Title: '2023-2024', name: '2023-2024', linkTo: `./raiting${this.linkTo('season', '2023-2024')}` },
                                { Title: '2022-2023', name: '2022-2023', linkTo: `./raiting${this.linkTo('season', '2022-2023')}` },
                                { Title: '2021-2022', name: '2021-2022', linkTo: `./raiting${this.linkTo('season', '2021-2022')}` },
                                { Title: '2019-2021', name: '2019-2021', linkTo: `./raiting${this.linkTo('season', '2019-2021')}` }
                            ]
                        }
                    },                    {
                        component: UiChooser,
                        cell_x: 5,
                        collapse: 1,
                        componentProps: {
                            height: 115, yPadding: 'usual', _title: 'Тип рейтига', tab: type, Items: [
                                { Title: 'Основной(ПРО)', name: 'comp_pro', linkTo: `./raiting${this.linkTo('type', 'comp_pro')}` },
                                { Title: 'Новички', name: 'comp_not_pro', linkTo: `./raiting${this.linkTo('type', 'comp_not_pro')}` }
                            ]
                        }
                    },
                    {
                        component: RaitingSubjectTableLayout,
                        cell_x: 2,
                        collapse: 5,
                        id: 666,
                        componentProps: { height: 600, yPadding: 'usual', School, season, type, category, linkTo: this.linkTo }
                    },
                    {
                        component: PersonsLittleListWitchScoresPlashkaWrapped,
                        cell_x: 2,
                        collapse: 5,
                        componentProps: { height: 600, yPadding: 'usual', schoolId, season, type, category, personsDirect: true }
                    }
                ]
                }
            />
        </div>        
        }

        return <div style={{ width }} className=''>
            <TrefaLayout greedsX={[12, 112, 12, 700, 12, 300, 12]} greedsY={600}
                components={[
                    {
                        component: UiChooser,
                        cell_x: 2,
                        collapse: 1,
                        componentProps: {
                            height: 145, yPadding: 'usual', _title: 'Сезон', tab: season, Items: [
                                { Title: '2024-2025', name: '2024-2025', linkTo: `./raiting${this.linkTo('season', '2024-2025')}` },
                                { Title: '2023-2024', name: '2023-2024', linkTo: `./raiting${this.linkTo('season', '2023-2024')}` },
                                { Title: '2022-2023', name: '2022-2023', linkTo: `./raiting${this.linkTo('season', '2022-2023')}` },
                                { Title: '2021-2022', name: '2021-2022', linkTo: `./raiting${this.linkTo('season', '2021-2022')}` },
                                { Title: '2019-2021', name: '2019-2021', linkTo: `./raiting${this.linkTo('season', '2019-2021')}` }
                            ]
                        }
                    },                    {
                        component: UiChooser,
                        cell_x: 2,
                        collapse: 1,
                        componentProps: {
                            height: 115, yPadding: 'usual', _title: 'Тип рейтига', tab: type, Items: [
                                { Title: 'Основной(ПРО)', name: 'comp_pro', linkTo: `./raiting${this.linkTo('type', 'comp_pro')}` },
                                { Title: 'Новички', name: 'comp_not_pro', linkTo: `./raiting${this.linkTo('type', 'comp_not_pro')}` }
                            ]
                        }
                    },
                    {
                        component: RaitingSubjectTableLayout,
                        cell_x: 4,
                        collapse: 1,
                        id: 667,
                        componentProps: { height: 600, yPadding: 'usual', School, season, type, category, linkTo: this.linkTo }
                    },
                    {
                        component: PersonsLittleListWitchScoresPlashkaWrapped,
                        cell_x: 6,
                        collapse: 1,
                        componentProps: { height: 600, yPadding: 'usual', schoolId, season, type, category, personsDirect: true }
                    }
                ]
                }
            />
        </div>
    }
}

const RaitingSubjectLayoutWrapped = (props) => <SchoolWrapper Wrapped={RaitingSubjectLayout} schoolId={props.schoolId} componentProps={{ ...props }} />
export default withCapoeiraSportState('iamuser, getText, isMobile')(RaitingSubjectLayoutWrapped);