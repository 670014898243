import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Popover, Icon } from 'antd';

import './component.css';


/*

  Сюда мы посылаем весь элемент, и плюс к этому его ширину относительную (в wv)

*/


export const SamuraiCover = ({sizes, element, client, children}) => {

    let style = {};

    let div_data = {}

    if (sizes.width) {
        style.width = `${sizes.width}vw`
        style.height = `${sizes.width * 1.4}vw`

        style.margin = sizes.nomargin ? 0 :`${sizes.width * 0.07}vw`;
        style.padding = `${sizes.width * 0.07}vw`

        style.fontSize = `${sizes.width / 8}vw`
        style.lineHeight = `${sizes.width / 8}vw`;    
    }

    if (sizes.style) {
        style = {...style, ...sizes.style}
    }

    if (sizes.reff) {
        div_data.ref = sizes.reff;
    }

    let bg_type = 'bg_red';

    if (element.type === 'strike') {
        bg_type = 'bg_red';
    }
    if (element.type === 'movement') {
        bg_type = 'bg_green';
    }
    if (element.type === 'defence') {
        bg_type = 'bg_blue';
    }
    if (element.Tier === 3) {
        bg_type = 'bg_yellow';
    } 


    return <div className = {'element-card card-samurai-cover '} style = {style} {...div_data} > 
      <img src = {client.getTechnicNeededLogo('covers/samurai/'+ bg_type)}/>
      {children}
    </div> 
}

export const SamuraiContent = (props) => {

    const { client, sizes, element_key } = props;

    const element = client.getServerElementsAsObject()[element_key];

    const {
        key,
        title,
        Tier,
        Points,
    } = element;


    let minilogo_src = '';

    let title_style = {
        fontSize: '1vw',
        color: 'white'
    }

    if (element.type === 'strike') {
        minilogo_src = 'strike';
    }
    if (element.type === 'movement') {
        minilogo_src = 'movement';
    }
    if (element.type === 'defence') {
        minilogo_src = 'defence';
    }
    if (element.Tier === 3) {
        minilogo_src += '_3tier';
        title_style.color = 'yellow'
    } 
    minilogo_src += '_logo';

    let w_koef = sizes.width ? sizes.width / 10 : 1;

    if (sizes.width) { 
        //title_style.marginTop = `${(sizes.width / 18) * w_koef}vw`;
        //title_style.fontSize = `${ w_koef }vw`;
        title_style.fontSize = `${ 100 }%`;
    }
    
    if (title.length < 12) {
        //title_style.fontSize = `${ (12 / (title.length + 2)) * w_koef }vw`;
        title_style.fontSize = `${ (1600 / (title.length + 2)) }%`;
    }

    if (title.length < 5) {
        //title_style.fontSize = `${ (12 / 7) * w_koef }vw`;
        title_style.fontSize = `${ (1300 / 7) }%`;
    }

    return <div className = 'element-card-content card-samurai-content'>

        <div className = 'card-samurai-content-logo-img'>
             <img src = { client.getTechnicLogoUrl(key) }/>
        </div>

        <div className = 'card-samurai-content-tier'>
             <div>{`T: ${Tier}`}</div>
             {`P: ${Points}`}
        </div>

        <div className = 'card-samurai-content-title' style = {title_style}>
            {title}
        </div>
        
    </div>

 /*
         <div className = 'element-card-title' style = {title_style}>
            {title}
        </div>

        <div className = 'element-card-bottom_line' style = {bottom_line_style}>
            <div>TIER {Tier || 1}</div>
            <div>{samurai || 1}</div>
        </div>
        */


}