import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';

import EventAvatar from 'src/ver2/components/event/avatar';
import ShopAvatar from 'src/ver2/components/shop/avatar';
import moment from 'moment/min/moment-with-locales';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import { TrefaLayout } from 'src/ver2/containers/layout';

import GroupAvatar from 'src/ver2/components/group/avatar';

import { Link } from 'react-router-dom';

import { EditIcon, DeleteIcon } from 'src/ver2/components/ui/icons';

import { Tooltip, Modal } from 'antd';

import Loader from 'src/ver2/components/loader';
import './littleStyle.css';
import 'moment/locale/ru';


/**
 * ПОЛУЧАЕТ НА ВХОД или Operation (с данными) или OperationCode
 *  
 */

class LittleOperation extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        Operation: null,
        UsersFromOperation: []
    }

    componentDidMount = async () => {
        const { OperationCode, Operation, client, withPersons } = this.props;
        if (!Operation && OperationCode) {
            const Operation_ = await client.getOperationByCode(OperationCode);
            if (Operation_ && Operation_[0])
                this.setState({ Operation: Operation_[0] })
        }

        if (withPersons) {
            const UsersFromOperation = await client.getUsersFromOperation( Operation ? Operation.Code : OperationCode);
            this.setState({ UsersFromOperation })
        }

    }

    ResoursePlashka = ({ name, value, isplus, type }) => {

        let imgPart = null;
        let title = '';
        if (type === 'scores') {
            imgPart = <img src={`/../images/ver2/scores/${name}_${1}.png`} />
            title = this.props.client.scoresTitleByType(name);
        }
        if (type === 'money') {
            imgPart = <img src={`/../images/ver2/scores/money/${name}.png`} />
            title = this.props.client.moneyTitleByType(name);
        }

        return <div className='operation_little_one_resourse'>
            <Tooltip title={title} placement='top'>
                <div className='operation_little_one_resourse_img'>
                    {imgPart}
                </div>
            </Tooltip>

            {
                isplus
                    ?
                    <div className='operation_little_one_resourse_value' >
                        {`+${value}`}
                    </div>
                    :
                    <div className='operation_little_one_resourse_value operation_little_one_resourse_value_minus' >
                        {`${value}`}
                    </div>
            }
        </div>
    }
    
    personsClick = () => {
        const { UsersFromOperation } = this.state;
        const { showInfoModal } = this.props;

        showInfoModal({type: 'personsList', Persons: UsersFromOperation })
    }

    render() {

        const { Operation: OperationDirect, height: comingHeight, Suffix, client, withPersons } = this.props;
        const { UsersFromOperation } = this.state;

        const height = comingHeight || 60;

        const Operation = OperationDirect || this.state.Operation;

        if (!Operation) return <div style={{ height, minHeight: height }} className='operation_little'>
            <Loader />
        </div>

        const {
            Discription,
            SubjectType,
            SubjectId,
            ReleaseDate,
            Settings,
            Status,
            Type
        } = Operation;

        let SubjectElement = null;
        if (SubjectType === 'event') {
            SubjectElement = <div className='operation_little_subject'>
                <EventAvatar size={height * 0.8} eventId={SubjectId} />
            </div>
        }
        if (SubjectType === 'shop') {
            SubjectElement = <div className='operation_little_subject'>
                <ShopAvatar size={height * 0.8} shopId={SubjectId} />
            </div>
        }

        let Resourses = [];
        let Items = []
        const SettingsObj = JSON.parse(Settings);
        if (SettingsObj) {
            if (SettingsObj.scores) {
                const keys = Object.keys(SettingsObj.scores);
                Resourses = [...Resourses,
                ...keys.map(name => { return { name, value: SettingsObj.scores[name], isplus: true, type: 'scores' } })
                ]
            }
            if (SettingsObj.money) {
                const keys = Object.keys(SettingsObj.money);
                Resourses = [...Resourses,
                ...keys.map(name => { return { name, value: SettingsObj.money[name], isplus: SettingsObj.money[name] > 0, type: 'money' } })
                ]
            }
            if (SettingsObj.item) {
                const keys = Object.keys(SettingsObj.item);
                Items = [...Items,
                ...keys.map(name => { return name })
                ]
            }
        }

        let ResoursesElement = null;
        if (Resourses && Resourses.length > 0) {
            ResoursesElement = <div className='operation_little_resourses'>
                {Resourses.map(item => <this.ResoursePlashka key={item.name + item.type} {...item} />)}
            </div>
        }

        let ItemsElement = null;
        if (Items && Items.length > 0) {
            ItemsElement = <div className='operation_little_resourses'>
                {
                    Items.map(name => {
                        const Item = client.getAbstractItem(name);
                        const { src } = Item;
                        return <img src={src}></img>
                    })
                }
            </div>
        }

        return <div style={{ height, minHeight: height }} className='operation_little'>
            <div className='operation_little_title'>
                <div className='operation_little_title_date'>
                    {ReleaseDate ? moment(ReleaseDate, 'YYYY.MM.DDTHH:mm').locale('ru').format('DD.MM.YYYY, HH:mm') : 'Ожидает подтверждения'}
                </div>
                <div className='operation_little_title_text'>
                    {Discription}
                </div>
            </div>
            {SubjectElement}
            {ResoursesElement}
            {ItemsElement}
            { (withPersons === true) && <div>{Type}</div>}
            { (withPersons === true && Status === 'done' ) && <ButtonPlashka
                upperStyle={{ width: 150, height: 35 }}
                text={`КОЛ-ВО ${UsersFromOperation ? UsersFromOperation.length : 'нет'}`}
                onClick={ this.personsClick }
            />}
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile, showInfoModal')(LittleOperation);