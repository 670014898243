import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';

/**
 * Это враппер, который обвешивается вокруг компонента,
 * принимает videoId (кого брать)
 * component - то куда запихивать
 * componentProps - он в component пихает все пропсы, которые нужны ему
 * 
 * и когда загрузит нужный Video - закидывает его как объект
 */

class VideoWrapper extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            Video: null
        };
    }

    componentDidMount () {
        const {videoId} = this.props;
        this.getVideo(videoId); 
    }


    getVideo = async (id) => {
        if (!id) return;
        const Video = await this.props.client.getVideoFromServer(id);
        this.setState({Video})
    }

    componentWillReceiveProps({videoId}) {
        if (videoId !== this.props.videoId) {
            this.getVideo(videoId)
        }
    }

    render() {
        const {
            props: {
                Wrapped,
                client,
                componentProps
            },
            state: {
                Video
            }
        } = this;

        return (
            <Wrapped {...{ Video, client, ...componentProps }} />
        );
    }
}


export default withCapoeiraSportState('client')(VideoWrapper);