import React, { Component } from 'react';
import './styles.css';
// import { Header } from 'src/ver2/pages/crm/GroupCRM/components/sgHeader';
import { Subgroup, SubgroupsByLocation } from './components/subgroup';
import { withCapoeiraSportState } from 'src/ver2/context';
import Loader from 'src/ver2/components/loader';

class ShaduleComponent extends Component {

    /**
     * props
     * 
     * или groupId и грузим сами, или
     * 
     * subgroups
     * shadule
     * 
     * width - могут быть переданы, а могу и нет
     * height
     * 
     * onSubgroupClick
     * orientation = horizontal (def) / vertical
     * optimalSize = false / true (занять оптимальный размер)
     */

    constructor(props) {
        super(props);

        this.id = (Math.random() * 10000).toFixed()

        const { width, height } = props;

        // тут мы вводим разные штуки для расписания
        this.state = {
            selectedSubgroup: undefined,
            shaduleHeight: height || 600,
            shaduleWidth: width || 700,
            loaded: 'loading' // loaded, error
        };

        // this.sizeTimer = setInterval(this.timerFunc, 50);
        this.mainNode = null;
    }

    get shaduleHeight() { return this.state.shaduleHeight }
    get shaduleWidth() { return this.state.shaduleWidth }
    get rightWidth() {
        return this.state.shaduleWidth;//- 350;
    }
    get tableWidth() {
        return this.state.shaduleWidth - 40;//- 350;
    }
    get tableHeight() {
        return this.state.shaduleHeight - 40;

    }

    timerFunc = () => {
        if (!this.mainNode) {
            this.mainNode = document.getElementById(this.id);
            if (!this.mainNode) return;
        }

        console.log('timerFunc');

        const { width: shaduleWidth, height: shaduleHeight } = this.mainNode.getBoundingClientRect()
        if (shaduleWidth === this.shaduleWidth && shaduleHeight === this.shaduleHeight) return;
        this.setState({ shaduleWidth, shaduleHeight })
    }

    componentDidMount = async () => {
        const { shadule, subgroups, groupId } = this.props;

        let shadule__ = shadule;

        if (!shadule || !subgroups) {
            if (!groupId) {
                this.setState({ loaded: 'error' })
                return null;
            }
            const { shadule: shadule_, subgroups: subgroups_ } = await this.initLoad();
            if (!subgroups_ || !shadule_) {
                this.setState({ loaded: 'error' })
                return null;
            }
            shadule__ = shadule_
        } else {
            this.setState({ subgroups, shadule });
        }

        shadule__ = shadule__ || shadule;

        this.createShaduleState(shadule__);
        this.setState({ loaded: 'loaded' })
    }

    initLoad = async () => {
        const { client, groupId } = this.props;
        const subgroupsLoaded = await client.getSubgroups(groupId);
        if (!subgroupsLoaded || !subgroupsLoaded.length) return {};

        // console.log ('initLoad')

        let subgroups = [];
        let shadule = [];
        for (let i = 0; i < subgroupsLoaded.length; i++) {

            // console.log ('subgroups', subgroups[i]);
            const { settingsAsObj } = subgroupsLoaded[i];
            if (settingsAsObj && settingsAsObj.notActive) continue;
            const sb_shadule = await client.getShadule(subgroupsLoaded[i].id);
            subgroupsLoaded[i].shadule = sb_shadule;
            subgroups.push({ ...subgroupsLoaded[i] });
            shadule.push(...sb_shadule);
        }
        this.setState({ subgroups, shadule })
        return { subgroups, shadule };
    }

    componentWillUnmount() {
        this.sizeTimer && clearInterval(this.sizeTimer);
        this.sizeTimer = null;
        delete this.sizeTimer;
    }

    createShaduleState = (shadule) => {

        let firstHour = 30;
        let lastHour = 0;

        for (let i = 0; i < shadule.length; i++) {

            const { timeStart, timeEnd } = shadule[i];

            const startHour_ = Number(timeStart.slice(0, 2));
            //const startMinutes = Number(timeStart.slice(3));

            const endHour = Number(timeEnd.slice(0, 2));
            const endMinutes = Number(timeEnd.slice(3));

            if (firstHour > startHour_) firstHour = startHour_;
            if (lastHour <= endHour) {
                lastHour = endHour;
                if (endMinutes > 0) lastHour++;
            }

        }

        const startHour = Math.min(16, firstHour);//firstHour;
        const range = lastHour - startHour;
        const oneHourHeight = this.tableHeight / range;

        /*let shaduleState = {

            range: 0, // рендж между самой первой треной и последней в часах
            startHour: 0, // самый первый час
            oneHourHeight: 0, // высота одного часа

        }*/

        let shaduleState = {
            range,
            startHour,
            oneHourHeight
        }

        this.setState({ shaduleState });

    }

    render() {
        const { loaded } = this.state;
        const { width, height } = this.props;
        const { shaduleWidth, shaduleHeight } = this;

        let style = {};
        if (width) { style.width = width }
        // if (height) { style.height = height }

        if (loaded === 'error') {
            return <div id={this.id} style={style} className='shadule-comp-container'>
                <span>Не задано расписание</span>
            </div>
        }
        if (loaded === 'loading') {
            return <div id={this.id} style={style} className='shadule-comp-container'>
                <Loader text='Загрузка расписания' />
            </div>
        }

        return <div style={style} className='shadule-comp-container'>
            <this.SubgroupsColumn />
            <div id={this.id} style={{ width: shaduleWidth, height: shaduleHeight }} className='shadule-comp-main'>
                <this.ShaduleMain />
            </div>
        </div>
    }

    SubgroupsColumn = () => {

        const { byCitys, addRequest, isMobile } = this.props;
        const { subgroups } = this.state;

        if (byCitys) return <div className='shadule-comp-left-container'>
            <SubgroupsByLocation isMobile={isMobile} addRequest={addRequest} subgroups={subgroups} />
        </div>

        return <div className='shadule-comp-left-container'>
            {
                subgroups.map((subgroup, index_) => {
                    const index = index_ + 1;
                    return <Subgroup {...{ subgroup, index }} />
                })
            }
        </div>
    }

    ShaduleMain = () => {
        const style = {
            width: this.rightWidth,
            height: this.shaduleHeight
        }
        return <div style={style} className='shadule-comp-right-container'>
            <this.ShaduleMainHeader />
            <div className='shadule-comp-right-container-shadule'>
                <this.ShaduleMainHoursColumn />
                <this.ShaduleMainTable />
            </div>
        </div>
    }

    // тут у нас просто сверху пн-вс
    ShaduleMainHeader = () => {

        const days = [
            'ПН',
            'ВТ',
            'СР',
            'ЧТ',
            'ПТ',
            'СБ',
            'ВС',
        ]

        return <div className='shadule-comp-right-container-header' style={{ width: this.tableWidth }}>
            {days.map((day, index) => {

                return <div key={'srchd' + index} className='shadule-comp-right-container-header-day'>
                    <p>
                        {day}
                    </p>
                </div>

            })}
        </div>
    }

    ShaduleMainHoursColumn = () => {

        const { shaduleState } = this.state;
        if (!shaduleState) return null;
        const {
            range,
            startHour,
            oneHourHeight
        } = shaduleState;

        let hours = [];
        for (let i = 0; i < range + 1; i++) {

            const hourNumber = startHour + i;
            const hourString = hourNumber < 10 ? `0${hourNumber}` : `${hourNumber}`;

            hours.push(`${hourString}:00`)
        }

        return <div className='shadule-comp-right-container-hours'>
            {hours.map((hour, index) => {

                const style = {
                    top: oneHourHeight * index
                }

                return <div key={'srchh' + index} className='shadule-comp-right-container-hours-hour' style={style}>
                    {hour}
                </div>
            })}
        </div>
    }

    ShaduleMainTable = () => {

        const { shaduleState } = this.state;
        if (!shaduleState) return null;
        const {
            range,
            oneHourHeight,
            startHour
        } = shaduleState;

        let row = [];
        let column = [];

        for (let i = 0; i < range - 1; i++) {
            row.push(1)
        }

        for (let i = 0; i < 7; i++) {
            column.push(1)
        }

        const { shadule, subgroups } = this.state;

        let subgroups_styles = [];
        for (let i = 0; i < shadule.length; i++) {


            const { timeStart, timeEnd, day, subgroupId } = shadule[i];
            const subgroup = subgroups.find(x => x.id === subgroupId) || {};
            const { title, color } = subgroup;

            const startHour_ = Number(timeStart.slice(0, 2));
            const startMinutes = Number(timeStart.slice(3));

            const endHour = Number(timeEnd.slice(0, 2));
            const endMinutes = Number(timeEnd.slice(3));

            const hoursLength = ((endHour * 60 + endMinutes) - (startHour_ * 60 + startMinutes)) / 60;
            const hourTop = ((startHour_ * 60 + startMinutes) - startHour * 60) / 60;

            subgroups_styles.push({
                width: this.tableWidth / 7,
                height: oneHourHeight * hoursLength,
                top: oneHourHeight * hourTop,
                left: (day - 1) * this.tableWidth / 7,
                background: color[0] === "#" ? color : `#${color}`,
                timeStart,
                timeEnd,
                title
            })
        }

        const rowStyle = {
            width: this.tableWidth,
            height: oneHourHeight
        }

        const columnStyle = {
            width: this.tableWidth / 7,
            height: this.tableHeight
        }

        return <div className='shadule-comp-right-container-table'>
            {row.map((oneRow, index) => {
                return <div style={{ top: index * oneHourHeight, ...rowStyle }} key={'srctr-r-' + index} className='shadule-comp-right-container-table-row' />
            })}
            {column.map((oneRow, index) => {
                return <div style={{ left: index * columnStyle.width, ...columnStyle }} key={'srctr-c-' + index} className='shadule-comp-right-container-table-column' />
            })}
            {subgroups_styles.map((subgroups_style, index) => {

                const { timeStart, timeEnd, title } = subgroups_style;

                return <div style={subgroups_style} key={'srctr-sg-' + index} className='shadule-comp-right-container-table-supgroup'>
                    <div className='___title_block'>
                        <p>{title}</p>
                    </div>
                    <div className='___time_block'>
                        <p> {timeStart} </p>
                        <p className='___center-tire'> - </p>
                        <p> {timeEnd} </p>
                    </div>
                </div>
            })}
        </div>
    }

}


export default withCapoeiraSportState('iamuser, client, isMobile')(ShaduleComponent);