
import React from 'react';
import { Modal } from 'antd';
// import ItemInventarComponent from 'src/ver2/components/item/inventar';
// import { PDFReader } from 'reactjs-pdf-reader';

// import Medal from 'src/ver2/components/medal';
// import TrefaText from 'src/ver2/components/trefaText';

import './modals.css';

/*
    сюда приходит
    type - "judge"

    value - '1' '2' '3' 'vseross'


    type some_info - это просто какая то инфа
    title - Как натянуть беримбао
    value - node с текстом

*/

export const showInfoModal = (client, { type, value, component, title: title_  }, isMobile) => {

    let content = null;
    let title = title_ || '';
    let modalClassName = 'showInfoModal';
    if (type === 'judge') {
        title = 'СУДЕЙСКАЯ КАТЕГОРИЯ';
        content = <CategorysContent imgUrl={`/../images/ver2/cats/${value}.png`} title={client.judgeRanks[value]}
            text={'Это классная судейская категория !'} />
    }
    if (type === 'sport') {
        title = 'СПОРТИВНАЯ КАТЕГОРИЯ';
        content = <CategorysContent imgUrl={`/../images/ver2/cats/${value}.png`} title={client.sportRanks[value]}
            text={'Это классная спортивная категория !'} />
    }
    if (type === 'portal') {
        title = 'АДМИНИСТРАЦИЯ ПОРТАЛА';
        content = <CategorysContent imgUrl={`/../images/ver2/portal_icons/${value}.png`} title={client.portalRoles[value]}
            text={client.portalCategorys[value]} />
    }
    if (type === 'scores') {
        title = 'СТАТУСЫ';
        const { type: type_, score } = value;

        console.log ('scores СТАТУСЫ');

        const lvls = client.scoresLvlByType({ type: type_, score });
        const { lvl, text } = lvls;

        content = <CategorysContent imgUrl={`/../images/ver2/scores/${type_}_${lvl}.png`} title={`${lvls.title} (уровень ${lvl})`}
            text={text} />
    }
    if (type === 'money') {
        title = 'РЕСУРСЫ';
        const { type } = value;

        const money = client.moneyTextByType(type);

        content = <CategorysContent imgUrl={`/../images/ver2/scores/money/${type}.png`} title={`${money.title}`}
            text={money.text} />
    }
    if (type === 'medal') {
        title = 'СПОРТИВНАЯ МЕДАЛЬ';
        content = component;
    }
    
    if (type === 'some_info') {
        content = <div className = { isMobile ? 'global_discribe_mobile_text' : 'global_discribe_text'}>
            {value}
        </div>
    }
    // if (type === 'pdfrules') {
    //     title = 'ПРАВИЛА СОРЕВНОВАНИЙ';
    //     modalClassName = 'showPDFRulesModal'
    //     // content = <PDFRulesContent isMobile = {isMobile}/>;
    // }

    if (isMobile) modalClassName += ' infoModal_mobile';

    if (content)
        Modal.info({
            className: modalClassName,
            title,
            content: content,
            okCancel: false,
            closable: true,
            centered: true
        })
}

const CategorysContent = ({ imgUrl, title, text }) => {
    return <div className='modal_categorys_content'>
        <div className='modal_categorys_left'>
            <img src={imgUrl} />
        </div>
        <div className='modal_categorys_right'>
            <div className='modal_categorys_title'>
                {title}
            </div>
            <div className='modal_categorys_content'>
                {text}
            </div>
        </div>
    </div>
}

// const PDFRulesContent = ({isMobile}) => {
//     return <div className='modal_categorys_content'>
//         <PDFReader url={"/../images/ver2/pravila.pdf"} showAllPage width = { 300 }/>
//     </div>
// }