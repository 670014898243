import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { Link } from 'react-router-dom';
import Loader from 'src/ver2/components/loader';
import { UpperStatus } from 'src/ver2/components/event/upperStatus';
import { eventStatus as eventStatus_ } from 'src/ver2/pages/event/context';
import { Icon } from 'antd';

import './style.css';
import EventWrapper from 'src/ver2/components/event/eventWrapper';

//getEventById

/**
 * ПОЛУЧАЕТ НА ВХОД Event
 *  Adress: "Малое Видное ТЛПХ Подлипки, ул.Спортивная, д.32"
    AuthorId: 2
    ChampionshipId: 41
    City: "МО"
    Date: "13.11.2021"
    Discription: "Чемпионат и Первенство Московской области 2021"
    FullData: "20211113"
    Id: 78
    TimeDiscription: ""
    Title: "Чемпионат и Первенство Московской области 2021"
    Type: "championship"
 */

class EventUsualPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        count: 0
    }

    loadCount = async () => {
        const { Event, client } = this.props;
        if (!Event) return this.setState({ count: 0 });

        
        const { ChampionshipId } = Event;
        if (!ChampionshipId) return this.setState({ count: 0 });

        const Competitors = await client.getCompetitors(ChampionshipId);
        if (Competitors && Competitors.length) {
            this.setState({ count: Competitors.length })
        } else {
            this.setState({ count: 0 });
        }

    }

    componentDidMount ( ) {
        this.loadCount();
    }

    componentDidUpdate ( prevProps ) {
        if (prevProps.Event !== this.props.Event) {
            this.loadCount();
        }
    }

    render() {

        const { Event, client } = this.props;
        const { count } = this.state;
        if (!Event) return <div className='event_usual_plashka'>
            <Loader />
        </div>

        const {
            Id, Date: _Date, Title
        } = Event;

        // console.log(Event)

        const { height } = this.props.upperStyle;

        const imageUrl = client.getEventsUrl() + Id + '.png';
        const style = {
            background: `url(${imageUrl})`,
            ...height ? { height } : {}
        }

        const eventStatus = () => eventStatus_(Event);

        // const { width, height } = this.props.upperStyle;

        return <div style={{height}} className='event_usual_plashka'>
            <Link to={'/event/' + Id}>

               
                <div style = {{height}} className='event_usual_plashka_fon_usual'>
                    <span>{Title}</span>
                </div>
                <div style = {style} className='event_usual_plashka_fon'/>

                <div className='event_usual_plashka event_usual_plashka__date' >
                    <div className='event_usual_plashka__date_text'>
                        {_Date}
                    </div>
                </div>
                {(count && count > 0) ?
                        <div className='event_usual_plashka__count'>
                            <Icon type="user" />
                            <span>{count}</span>
                        </div>
                        : null
                    }
                <div className='event_usual_plashka__status'>
                    <UpperStatus {...{ client, eventStatus, noTooltip: true, className: '__status_default' }} />
                </div>
                <div className='event_usual_plashka__downed_title'>
                    <div style={{ fontSize: Math.max(20, height * 0.13) }} className='event_usual_plashka__downed_title_text'>
                        {Title}
                    </div>
                </div>
            </Link>
        </div>

    }

}

const componentWrapped = (props) => <EventWrapper Wrapped={EventUsualPlashka} eventId={props.eventId} componentProps={{ ...props }} />
export default withCapoeiraSportState('iamuser, client')(componentWrapped);