export default {    // ICONS 

    // BERIMBAO

    cabaca_br1: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 1,
        title: 'Обычная кабаса',
        discription: 'Самая обычная кабаса! Ничего необычного, но звучит, если что, отлично. Кабасу можно ставить на аватарку только вместе с вергой',
        src: `/../images/ver2/scores/berimbao/cabaca_br1.png`
    },

    cabaca_bry1: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 1,
        title: 'Кабаса с флагом',
        discription: 'Обычная кабаса с небольшим рисунком! Ничего необычного, но звучит, если что, отлично. Кабасу можно ставить на аватарку только вместе с вергой',
        src: `/../images/ver2/scores/berimbao/cabaca_bry1.png`
    },

    cabaca_g2: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 2,
        title: 'Зелёная кабаса',
        discription: 'Кабаса зелёного цвета с небольшим рисунком! Позволит Вам немного выделиться. Кабасу можно ставить на аватарку только вместе с вергой',
        src: `/../images/ver2/scores/berimbao/cabaca_g2.png`
    },

    cabaca_gg2: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 2,
        title: 'Зелёная кабаса 2',
        discription: 'Кабаса зелёного цвета! Позволит Вам немного выделиться. Кабасу можно ставить на аватарку только вместе с вергой',
        src: `/../images/ver2/scores/berimbao/cabaca_gg2.png`
    },

    cabaca_ggg2: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 2,
        title: 'Зелёная кабаса 3',
        discription: 'Кабаса зелёного цвета с этническим рисунком! Позволит Вам немного выделиться. Кабасу можно ставить на аватарку только вместе с вергой',
        src: `/../images/ver2/scores/berimbao/cabaca_ggg2.png`
    },
    
    cabaca_b3: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 3,
        discription: '',
        title: 'Редкая кабаса',
        src: `/../images/ver2/scores/berimbao/cabaca_b3.png`
    },
        
    cabaca_bb3: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 3,
        discription: '',
        title: 'Редкая кабаса',
        src: `/../images/ver2/scores/berimbao/cabaca_bb3.png`
    },

    cabaca_bbb3: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 3,
        discription: '',
        title: 'Редкая кабаса',
        src: `/../images/ver2/scores/berimbao/cabaca_bbb3.png`
    },

    cabaca_p4: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 4,
        discription: '',
        title: 'Изысканная кабаса',
        src: `/../images/ver2/scores/berimbao/cabaca_p4.png`
    },

    cabaca_pp4: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 4,
        discription: '',
        title: 'Изысканная кабаса',
        src: `/../images/ver2/scores/berimbao/cabaca_pp4.png`
    },

    cabaca_ppp4: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 4,
        discription: '',
        title: 'Изысканная кабаса',
        src: `/../images/ver2/scores/berimbao/cabaca_ppp4.png`
    },

    cabaca_r5: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 5,
        discription: '',
        title: 'Безупречная кабаса',
        src: `/../images/ver2/scores/berimbao/cabaca_r5.png`
    },

    cabaca_w5: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 5,
        discription: '',
        title: 'Безупречная кабаса',
        src: `/../images/ver2/scores/berimbao/cabaca_w5.png`
    },
    
    cabaca_wr5: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 5,
        discription: '',
        title: 'Безупречная кабаса',
        src: `/../images/ver2/scores/berimbao/cabaca_wr5.png`
    },

    cabaca_y5: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 5,
        discription: '',
        title: 'Безупречная кабаса',
        src: `/../images/ver2/scores/berimbao/cabaca_y5.png`
    },





    berimba_br1: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 1,
        discription: '',
        title: 'Обычная верга',
        src: `/../images/ver2/scores/berimbao/berimba_br1.png`
    },

    berimba_brg1: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 1,
        discription: '',
        title: 'Обычная верга',
        src: `/../images/ver2/scores/berimbao/berimba_brg1.png`
    },

    berimba_g2: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 2,
        discription: '',
        title: 'Раскрашенная верга',
        src: `/../images/ver2/scores/berimbao/berimba_g2.png`
    },

    berimba_gg2: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 2,
        discription: '',
        title: 'Раскрашенная верга',
        src: `/../images/ver2/scores/berimbao/berimba_gg2.png`
    },

    berimba_b3: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 3,
        discription: '',
        title: 'Редкая верга',
        src: `/../images/ver2/scores/berimbao/berimba_b3.png`
    },

    berimba_bb3: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 3,
        discription: '',
        title: 'Редкая верга',
        src: `/../images/ver2/scores/berimbao/berimba_bb3.png`
    },

    
    berimba_bbb3: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 3,
        discription: '',
        title: 'Редкая верга',
        src: `/../images/ver2/scores/berimbao/berimba_bbb3.png`
    },

    berimba_p4: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 4,
        discription: '',
        title: 'Изысканная верга',
        src: `/../images/ver2/scores/berimbao/berimba_p4.png`
    },

    berimba_pp4: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 4,
        discription: '',
        title: 'Изысканная верга',
        src: `/../images/ver2/scores/berimbao/berimba_pp4.png`
    },

    berimba_r5: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 5,
        discription: '',
        title: 'Безупречная верга',
        src: `/../images/ver2/scores/berimbao/berimba_r5.png`
    },

    berimba_w5: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 5,
        discription: '',
        title: 'Безупречная верга',
        src: `/../images/ver2/scores/berimbao/berimba_w5.png`
    },

    berimba_bl5: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 5,
        discription: '',
        title: 'Безупречная верга',
        src: `/../images/ver2/scores/berimbao/berimba_bl5.png`
    },



    // ДОНАТНЫЕ !

    cabaca_yb5: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 5,
        title: 'Легендарная кабаса',
        discription: '',
        src: `/../images/ver2/scores/berimbao/cabaca_yb5.png`
    },

    cabaca_yy5: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 5,
        title: 'Легендарная кабаса',
        discription: '',
        src: `/../images/ver2/scores/berimbao/cabaca_yy5.png`
    },

    cabaca_g5: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 5,
        title: 'Легендарная кабаса',
        discription: '',
        src: `/../images/ver2/scores/berimbao/cabaca_g5.png`
    },

    berimba_y5: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 5,
        discription: '',
        title: 'Легендарная верга',
        src: `/../images/ver2/scores/berimbao/berimba_y5.png`
    },

    berimba_yb5: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 5,
        discription: '',
        title: 'Легендарная верга',
        src: `/../images/ver2/scores/berimbao/berimba_yb5.png`
    },

    berimba_g5: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 5,
        discription: '',
        title: 'Легендарная верга',
        src: `/../images/ver2/scores/berimbao/berimba_g5.png`
    },

    // КУБОК МОСКВЫ
    
    cabaca_kmo1: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 5,
        title: 'Золотая кабаса КМ 2022',
        discription: 'Кабаса, выпущенная специально в честь Кубка Москвы 2022',
        src: `/../images/ver2/scores/berimbao/cabaca_kmo1.png`
    },
    cabaca_kmo2: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 5,
        title: 'Синяя кабаса КМ 2022',
        discription: 'Кабаса, выпущенная специально в честь Кубка Москвы 2022',
        src: `/../images/ver2/scores/berimbao/cabaca_kmo2.png`
    },

    
    // ВЕСЕННИЙ ЧЕЛЛЕНДЖ
    berimba_spring_5: {
        type: 'berimbao',
        subtype: 'berimba',
        grade: 5,
        discription: 'Достается топам Весеннего челленджа. Выдаётся вместе с Цветастой кабасой',
        title: 'Цветастая верга',
        src: `/../images/ver2/scores/berimbao/berimba_spring_5.png`
    },    
    cabaca_spring_5: {
        type: 'berimbao',
        subtype: 'cabaca',
        grade: 5,
        title: 'Цветастая кабаса',
        discription: 'Кабаса, выпущенная специально в честь Весеннего челленджа 2023',
        src: `/../images/ver2/scores/berimbao/cabaca_spring_5.png`
    },

}