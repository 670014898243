import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';

/**
 * Это враппер, который обвешивается вокруг компонента,
 * принимает schoolId (кого брать)
 * component - то куда запихивать
 * componentProps - он в component пихает все пропсы, которые нужны ему
 * 
 * и когда загрузит нужный School - закидывает его как объект
 */

class SchoolWrapper extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            School: null,
            Counts: null,
            GradationCount: null
        };
    }

    componentDidMount () {
        this.loadAll();
    }

    componentWillReceiveProps({ schoolId }) {
        if (schoolId !== this.props.schoolId) {
            this.loadAll(schoolId);
        }
    }

    loadAll = (id) => {

        const schoolId = id || this.props.schoolId;
        const { client, witchGradation } = this.props;
        const School = client.getSchoolById(schoolId);
        this.setState ({School});
        this.loadCounts(schoolId);
        if (witchGradation) {
            this.loadGradationCount(schoolId);
        }
    }

    loadCounts = async (id) => {
        const { client } = this.props;
        const all = await client.getSchoolPersonCount(id);
        const competitors = await client.getSubjectCompetitorsCount({id});

        this.setState({Counts: {all, competitors}})
    }

    loadGradationCount = async (id) => {
        const { client } = this.props;
        const GradationCount = await client.getSchoolGradationCountFromServer(id);

        this.setState({GradationCount})
    }


    render() {
        const {
            props: {
                Wrapped,
                componentProps
            },
            state: {
                School,
                Counts,
                GradationCount
            }
        } = this;

        return (
            <Wrapped {...{ School, Counts, GradationCount, ...componentProps }} />
        );
    }
}


export default withCapoeiraSportState('client')(SchoolWrapper);