import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { TitledPlashka } from 'src/ver2/containers/lowlavel/title';

import EventAvatar from 'src/ver2/components/event/avatar';

import EventLittleListPlashka from 'src/ver2/plashki/event/littleList';
import { TrefaLayout } from 'src/ver2/containers/layout';

import { Popover } from 'antd';
import './style.css';
import moment from 'moment';
import 'moment/locale/ru';


const EVENTS_STYLES = {
    championship: { background: '#ffbb84' },
    roda: { background: '#ff848e' },
    judge: { background: '#dffcb8' },
    batizado: { background: '#ff84f5' },
    challenge: { background: '#ffbb00' },
}

class CalendarPlashka extends PureComponent {

    constructor(props) {
        super(props);


        /*const todayMonth = moment().format('MMMM');
        const todayDay = moment().subtract(1, 'months').format('MMMM')
*/
        this.state = {
            monthShift: props.monthShift || 0
        }

        // this.lookedDate = moment();

        // if (props.settedDate) {
        //     this.lookedDate = moment(props.settedDate, 'DD.MM.YYYY');
        // }

    }

    //changeKey

    componentDidUpdate = ( {monthShift}) => {
        if (monthShift !== this.props.monthShift) {
            this.setState({monthShift: this.props.monthShift })
        }
    }

    get lookedDate() {
        if (this.props.settedDate) {
            return moment(this.props.settedDate, 'DD.MM.YYYY');
        }
        return moment();
    }

    render() {

        const { upperStyle, noTitle, header } = this.props;
        console.log('CalendarPlashka', this.props.Events);

        // const { width, height } = upperStyle;
        // const { todayMonth, todayDay } = this.state;

        if (noTitle) {
            const { width, height } = upperStyle;
            return <div style={{ width, height }} className='calendar_plashka'>
                {header ?
                    <this.OwnHeader />
                    : <this.Header />}
                <this.DaysHeader />
                <this.Body />
            </div>
        }


        return <TitledPlashka titleComponent={'Календарь'} upperStyle={upperStyle} notFromLayout>
            <div className='calendar_plashka'>
                <this.Header />
                <this.DaysHeader />
                <this.Body />
            </div>
        </TitledPlashka>

    }

    OwnHeader = () => {

        const { header, EventsPreliminarily } = this.props;
        const { monthShift } = this.state;

        const lookedMonth = this.lookedDate.subtract(monthShift, 'months');
        const lookedMonthEnding = lookedMonth.format('MM.YYYY');

        const lookedEvents = EventsPreliminarily[lookedMonthEnding] || [];

        return <div className='calendar_plashka_outerheader'>
            <span>
                {header}
            </span>
            {lookedEvents.map(({ Id }) => {
                return <Popover placement = 'top' className={'calendar_popover'} key={'cbopoop' + lookedMonthEnding} content={this.popoverPrimContent(lookedMonthEnding)}>
                    <span>
                        <EventAvatar
                            withExtra = {true}
                            key={'cmepr_' + Id}
                            eventId={Id}
                            size={22}
                        />
                    </span>
                </Popover>
            })
            }
        </div >
    }

    Header = () => {

        const { monthShift } = this.state;

        return <div className='calendar_header'>
            <div onClick={() => this.setState({ monthShift: monthShift + 1 })} className='calendar_header_item'> {moment().subtract(monthShift + 1, 'months').format('MMMM')} </div>
            <div className='calendar_header_item calendar_header_item___selected'> {moment().subtract(monthShift, 'months').format('MMMM')} </div>
            <div onClick={() => this.setState({ monthShift: monthShift - 1 })} className='calendar_header_item'> {moment().subtract(monthShift - 1, 'months').format('MMMM')} </div>
        </div>
    }

    DaysHeader = () => {
        const DAYS = ['пн', 'вт', 'ср', 'чт', 'пт', 'cб', 'вс'];
        return <div className='calendar_days_header'>
            {DAYS.map((value) => {
                return <div key={'cdhi' + value} className='calendar_days_header_item'> {value} </div>
            })}
        </div>
    }

    Body = () => {
        const { monthShift } = this.state;
        const { Events } = this.props;

        const lookedMonth = this.lookedDate.subtract(monthShift, 'months');
        const lookedMonthEnding = lookedMonth.format('MM.YYYY');

        const daysCount = lookedMonth.daysInMonth();
        const firstDay = lookedMonth.startOf('month');


        let answer = [];
        let i = 1;

        const nowDay = Number(this.lookedDate.format('DD'));

        for (; i < daysCount + firstDay.isoWeekday(); i++) {
            let classNameDay = '';
            if (monthShift > 0) classNameDay = 'calendar_body_oneday__past';
            let dayNumber = i - firstDay.isoWeekday() + 1;

            // это мы выбираем в текущем месяце - что показывать серым, а что нет
            if (monthShift === 0) {
                if (dayNumber < nowDay) {
                    classNameDay = 'calendar_body_oneday__past';
                } else {
                    classNameDay = '';
                    if (dayNumber === nowDay) {
                        classNameDay = 'calendar_body_oneday__today'
                    }
                }
            }

            if (dayNumber < 1) {
                answer.push(
                    <div key={'cbo' + dayNumber} className='calendar_body_oneday calendar_body_oneday__blank' />
                );
                continue;
            }

            // пришли сюда, значит это уже нормальный день (от 1 до 31)
            const day2chars = dayNumber < 10 ? `0${dayNumber}` : dayNumber;
            const fullDate = `${day2chars}.${lookedMonthEnding}`;


            // ПРОВЕРКА НАЛИЧИЯ СОБЫТИЙ
            let eventStyle = {};

            // есть события на нашей дате
            if (Events && Events[fullDate]) {
                classNameDay += ' calendar_body_oneday__event';

                let classNameDay_ = '';
                let isThereChampionship = false;
                for (let z = 0; z < Events[fullDate].length; z++) {
                    if ( EVENTS_STYLES[Events[fullDate][z].Type] && !isThereChampionship ) {
                        eventStyle = {...eventStyle, ...EVENTS_STYLES[Events[fullDate][z].Type] }
                        if ( Events[fullDate][z].Type === 'championship') isThereChampionship = true;
                    }
                }

                classNameDay += classNameDay_;

                answer.push(
                    <Popover placement = 'top' className={'calendar_popover'} key={'cbopo' + dayNumber} content={this.popoverContent(fullDate)}>
                        <div key={'cbo' + dayNumber} style = {eventStyle} className={'calendar_body_oneday ' + classNameDay} >
                            <span>
                                {dayNumber}
                            </span>
                            <div className='calendar_mini_events'>
                                {Events[fullDate].map(({ Id }) => {
                                    return <EventAvatar
                                        withExtra = {true}
                                        key={'cme_' + Id}
                                        eventId={Id}
                                        size={22}
                                    />
                                })}
                            </div>
                        </div>
                    </Popover>
                )
                continue;
            }

            // нет событий

            answer.push(
                <div key={'cbo' + dayNumber} className={'calendar_body_oneday ' + classNameDay} >
                    <span>
                        {dayNumber}
                    </span>
                </div>
            )
        }

        const left = (i - 1) % 7;
        for (let j = 0; j < (7 - left); j++) {
            answer.push(
                <div key={'cbo' + i + '_' + j} className='calendar_body_oneday calendar_body_oneday__blank' />
            );
        }

        return <div className='calendar_body'>
            {answer}
        </div>

    }

    popoverContent = (fullDate) => {

        const { Events } = this.props;
        const eventsAtDate = Events[fullDate];

        return <div style = {{height: eventsAtDate.length * 60 }} className='calendar-popover-content'>

            <TrefaLayout greedsX={[10, 200, 10]} greedsY={eventsAtDate.length * 60}
                components={[
                    {
                        component: EventLittleListPlashka,
                        cell_x: 2,
                        collapse: 1,
                        componentProps: { height: eventsAtDate.length * 60, Events: eventsAtDate }
                    }
                ]}
            />
        </div>
    }


    popoverPrimContent = (fullDate) => {

        const { EventsPreliminarily } = this.props;
        const eventsAtDate = EventsPreliminarily[fullDate];

        return <div style = {{height: eventsAtDate.length * 60 }} className='calendar-popover-content'>

            <TrefaLayout greedsX={[10, 200, 10]} greedsY={eventsAtDate.length * 60}
                components={[
                    {
                        component: EventLittleListPlashka,
                        cell_x: 2,
                        collapse: 1,
                        componentProps: { height: eventsAtDate.length * 60, Events: eventsAtDate }
                    }
                ]}
            />
        </div>
    }

}

export default withCapoeiraSportState('client')(CalendarPlashka);