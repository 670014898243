import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import PersonWrapper from 'src/ver2/components/person/personWrapper.js';
import CordaoRect from 'src/ver2/components/cordao/cordaoRect';
import GroupRect from 'src/ver2/components/group/groupRect';
import SchoolRect from 'src/ver2/components/school/schoolRect'
import PersonAvatar from 'src/ver2/components/person/avatar';
import SocialIcon from 'src/ver2/components/ui/social';
import LittlePerson from 'src/ver2/components/person/little';

import Background from 'src/ver2/components/3d/medal';
import { message } from 'antd';

import './style.css';

class PersonBigPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }    

    state = {
        avatarAddMode: false
    }

    onAvatarAddMode = () => {
        if (!this.state.avatarAddMode) {
            this.setState({ avatarAddMode: true })
        }
    }

    render() {

        const { Person, iamuser, Raiting, changeDataFlag, client } = this.props;
        if (!Person) return <div className='person_big_plashka'>
            Загрузка...
        </div>

        let wherePerson = Person;
        let avatarAddMode = false;
        if (iamuser && iamuser.Id === Person.Id) {
            wherePerson = iamuser;
            if (this.state.avatarAddMode) avatarAddMode = true;
        }
        const onAvatarClick = avatarAddMode ? () => { changeDataFlag("3") } : () => { };

        const {
            Id, Name, Surname, Apelido, Cordao, SchoolId, GroupIamInId, VK, Instagram
        } = wherePerson;

        const { width, height } = this.props.upperStyle;


        let bg = null;
        let nededRaiting = (Raiting && Raiting.length) ? Raiting[0] : null;

        if (nededRaiting) {
            if (nededRaiting.Place === 1) bg = 'gold';
            if (nededRaiting.Place === 2) bg = 'silver';
            if (nededRaiting.Place === 3) bg = 'bronze';
        }

        return <div style={{ width, height }} className={'person_big_plashka person_big_plashka_rank_' + bg}>
            {bg ? <div style={{ width, height }} className='bgbgbg'>
                <Background
                    Id={123}
                    drawWidth={width}
                    drawHeight={height}
                    bg={bg}
                />
            </div> : null}

            <div style={{ width: height, height: height }} className='person_big_plashka___avatar_container' title={avatarAddMode ? 'добавить аватарку' : ''}>
                <PersonAvatar size={height * 0.75} personId={Id} onAvatarError={this.onAvatarAddMode} onClick={onAvatarClick} />
            </div>
            <div style={{ width: (width - height), height: height }} className='person_big_plashka___info_container'>
                <div className='person_big_plashka___info_container___name'>
                    <LittlePerson personsDirect={true} mode='fio' DirectPerson={wherePerson} height={40} />
                    {/* {`${Surname} ${Name} ${(Apelido && Apelido !== ' ' && Apelido !== '') ? ('/ ' + Apelido) : ''}`} */}
                </div>
                <div className='person_big_plashka___info_container___info'>
                    {VK && <SocialIcon type='vk' src={VK} size={24} />}
                    {Instagram && <SocialIcon type='instagram' src={Instagram} size={24} />}
                </div>
                <div className='person_big_plashka___info_container___school_group_cordao'>
                    <div onClick={() => { if (SchoolId === client.facialSchoolId && iamuser && iamuser.Id === Person.Id) { changeDataFlag("4") } }}>
                        <SchoolRect
                            width={height * 0.45}
                            SchoolId={SchoolId}
                            linkTo={SchoolId === client.facialSchoolId ? null : 'default'}
                        />
                    </div>
                    <div onClick={() => {

                        if (iamuser && iamuser.Id === Person.Id && !GroupIamInId) {
                            if (SchoolId === client.facialSchoolId) {
                                message.warning('Сначала установите школу')
                            } else {
                                changeDataFlag("5")
                            }
                        }
                    }}>
                        <GroupRect
                            width={height * 0.45}
                            GroupId={GroupIamInId}
                        />
                    </div>
                    <div onClick={() => {
                        if (iamuser && iamuser.Id === Person.Id) {
                            if (SchoolId === client.facialSchoolId) {
                                message.warning('Сначала установите школу')
                            } else {
                                if (Cordao === '- - -')
                                    changeDataFlag("4")
                            }
                        }
                    }}>
                        <CordaoRect
                            width={height * 0.45}
                            SchoolId={SchoolId}
                            Cordao={Cordao}
                        />
                    </div>
                </div>
            </div>
        </div>
    }
}

const PersonBigPlashkaWrapped = (props) => <PersonWrapper Wrapped={PersonBigPlashka} personId={props.personId} componentProps={{ ...props }} />
export default withCapoeiraSportState('iamuser, client')(PersonBigPlashkaWrapped);