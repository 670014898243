
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';

import { TrefaLayout } from 'src/ver2/containers/layout';
import { Input, Icon, message } from 'antd';

import './style.css';

/* 

    Это компенент назначения
    От содержит поиск, выбранного чувака и кнопку с текстов добавить или введённогм

    allreadyPersons: [{Id}, ...] - список тех Id, кто уже выбран
    selectedId: 17
    onPersonClick - то что надо выполнить по нажанию кнопки, после чего selectedId скидывается


*/

class PurposerComponent extends PureComponent {


    constructor(props) {
        super(props);
        this.state = {
            value: '',
            resultLoading: false,
            results: null
        }
    }
    //prevState
    componentDidUpdate({ }, { value }) {
        // что-то поменялось
        if (this.state.value !== value) {
            if (!this.state.value || this.state.value.length < 3) return this.setState({ results: null, resultLoading: false });

            this.getResultFromServer();
            this.setState({ resultLoading: true })

        }

    }

    getResultFromServer = () => {
        const { client } = this.props;
        client.getSearch(this.state.value).then(
            results => {
                ;
                this.setState({ results, resultLoading: false })
            }
        )
    }

    onChange = (e) => { this.setState({ value: e.target.value }) };
    // onBlur = () => { this.setState({ value: '' }) }

    render() {

        const { value } = this.state;

        return <div className='purpose_search_conatiner'>
            <Input onChange={this.onChange} onBlur={this.onBlur} value={value} placeholder='поиск...' height={22} className='purpose_search_conatiner__input' />
            <this.ResultsContainer />
        </div>
    }

    onPersonClick = (Id) => {
        const { allreadyPersons, onPersonClick } = this.props;

        if (allreadyPersons && allreadyPersons.length) {
            for ( let i = 0; i < allreadyPersons.length; i++) {
                if ( String(Id) === String(allreadyPersons[i].Id)) {
                    message.warn('Этот пользователь уже выбран');
                    return;
                }
            }
        }

        this.setState({ value: '' });
        onPersonClick ( Id );

    }

    ResultsContainer = () => {
        const { results } = this.state;
        const { mode } = this.props;
        const { onPersonClick } = this;
        if (!results
            || (
                (!results.persons || !results.persons.length)
            )
        ) return null;

        const { persons } = results;

        //if (events.length === 0) return null;

        const elementHeight = 36;

        let componentsHeight = 0;
        let components = [];

        if (persons.length > 0) {

            const componentHeight = Math.min(200, persons.length * elementHeight) + 68;
            componentsHeight += componentHeight;

            components.push({
                component: PersonsLittleListPlashka,
                cell_x: 2,
                collapse: 1,
                componentProps: { _title: 'Капоэйристы', lowTitle: true, yPadding: 'little', height: componentHeight, Persons: persons, mode, onPersonClick}
            })
        }

        return <div className='purpose_search_conatiner__results'>
            <div className='purpose_search_conatiner__results_close' onClick = {() => { this.setState({ value: '' }) }}>
                <Icon type='cross' />
            </div>
            {components.length && <TrefaLayout greedsX={[10, 200, 10]} greedsY={componentsHeight}
                components={components}
            />}
        </div>

    }

}


export default withCapoeiraSportState('client')(PurposerComponent);