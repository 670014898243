import React, { Component } from 'react';
import CapoeiraSportState from './context';
import CapoeiraSportModalState from './context/modal';
import Menu from './containers/menu';
import CapoeiraRoute from './pages';
import Footer from './containers/footer';


import './techstyle.css';
import './globalstyle.css';
import './style.css';
import 'antd/dist/antd.css';

export default class MainApp extends Component {

    constructor(props) {
        super(props);
        this.mainCenterContainerStyle = 'main-center-container';
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
            this.isMobile = true;
            this.mainCenterContainerStyle = 'main_center_container_mobile'
        }
        // this.isMobile = true;
        // this.mainCenterContainerStyle = 'main_center_container_mobile'
    }

    render() {
        return <CapoeiraSportState>
            <div className='main-container'>
                <input style={{ position: 'fixed', top: -30, color: 'white', height: 0 }} id='copyCopyText' value='' />
                <div id='main-backimage-id' className='main-backimage' />
                <div className={this.mainCenterContainerStyle}>
                    <Menu />
                    <CapoeiraRoute />
                </div>
                <Footer />
            </div>
            <CapoeiraSportModalState />
        </CapoeiraSportState>
    }


}