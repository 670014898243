import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import PersonAvatar from 'src/ver2/components/person/avatar';
import LittlePerson from 'src/ver2/components/person/little';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import { Link } from 'react-router-dom';

import './littleStyle.css';
import Loader from 'src/ver2/components/loader';

//getEventById

/**
 * ПОЛУЧАЕТ НА ВХОД Application
 *  
 */

class LittleApplication extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { Application, height = 40, onAccept, onDecline } = this.props;

        if (!Application) return <div style={{ height, minHeight: height }} className='application_little'>
            <Loader />
        </div>

        const {
            Id, UserId, Type, Values, DateAdd, Result, DataResult, ApplicatorId, Comment
        } = Application;


        return <div style={{ height, minHeight: height }} className='application_little'>
            <div style={{ width: height * 3, minWidth: height * 3, height }} className='application_little__avatar'>
                {/* <Link to={'/person/' + UserId}>
                    <PersonAvatar personId={UserId} size={height * 0.65} />
                </Link> */}
                <LittlePerson personId={UserId} height={height} />
            </div>
            <NumberText count={Type} text={'ТИП'} height={height * 0.6} />
            <NumberText count={Values} text={'СОДЕРЖАНИЕ'} height={height * 0.6} />
            <NumberText count={'ПОДАЧА'} text={DateAdd} height={height * 0.6} />
            <NumberText count={Result} text={'ОТВЕТ'} height={height * 0.6} />
            {(onAccept && !Result) ? <ButtonPlashka upperStyle={{ width: 70, height: height*0.8 }} onClick={() => onAccept(Id)} text={'принять'} /> : null}
            {(onDecline && !Result) ? <ButtonPlashka upperStyle={{ width: 70, height: height*0.8 }} onClick={() => onDecline(Id)} text={'отклонить'} /> : null}
        </div>
    }

}

export default LittleApplication;