// это компонент ui с иконкой и текстом
import React from 'react';
import './style.css'

const Loader = ({ style, text }) => {

    const src = `/../images/ver2/loading.png`

    return <div className='loader__container' style={style}>
        <div className='loader__img'>
            <img src={src} />
        </div>
        { text ? <div className='laoder__text'>
            {text}
        </div> : null }
    </div>
}

export default Loader;