
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import LittleOperation from 'src/ver2/components/operations/little';
import { Button } from 'antd';
import './style.css';
class ModalNotification extends PureComponent {

    goOk = () => {
        const { actualNotifications, closeModal, iSawNotification } = this.props;
        if (!actualNotifications || !actualNotifications.length) {
            return null;
        }

        const actual = actualNotifications.filter(x => x.OperationCode);
        if (!actual || !actual.length) {
            return null;
        }

        for ( let i = 0; i < actual.length; i++) {
            iSawNotification(actual[i].Id)
        }

        closeModal();
    }

    render() {

        const { actualNotifications } = this.props;
        if (!actualNotifications || !actualNotifications.length) {
            return null;
        }

        const actual = actualNotifications.filter(x => x.OperationCode);
        if (!actual || !actual.length) {
            return null;
        }

        return <div className='modal_notification_body'>
            {actual.map(({ OperationCode }) => {
                return <LittleOperation OperationCode={OperationCode} />
            })}
            <div className='modal_notification_button'>
                <Button type='primary' onClick={this.goOk}>ПРОЧИТАНО</Button>
            </div>
        </div>
    }
}

export default withCapoeiraSportState('iamuser, actualNotifications, iSawNotification, client, isMobile')(ModalNotification);