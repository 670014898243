import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { navIcon } from 'src/ver2/components/ui/icons';

import './style.css';

//getEventById

/**
  Это плашка, которая представялет из себя некоторый вертикальный выбор элементов
  приходит [Items: { Title, name, tab, onClick, linkTo }]
 */

export default class UiChooserPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { Items, upperStyle, tab, mobileStyle, mobileRaitingStyle, itemHeight = 50 } = this.props;

        const { width, height } = upperStyle;

        return <div style={{ width, height }} className={'ui_chooser_plashka ' + (mobileStyle ? 'ui_chooser_plashka_mobile ' : ' ') + (mobileRaitingStyle ? 'ui_chooser_plashka_raitingmobile' : '')}>
            {Items.map((item) => {

                const {
                    Title, onClick, linkTo, name, icon
                } = item;

                if (linkTo) {

                    if (mobileRaitingStyle) {

                        const square_style = { width: itemHeight, height: itemHeight }

                        return <Link to={`${linkTo}`}>
                            <div className={`ui_chooser_plashka_item_mobile ${tab === name ? 'ui_chooser_plashka_item_mobile_selected' : ''}`}>
                                <span className='ui_chooser_plashka_item_mobile_down'>{Title}</span>
                            </div>
                        </Link>
                    }

                    if (mobileStyle) {

                        const square_style = { width: itemHeight, height: itemHeight }
                        const icon_style = { width: itemHeight*0.8, height: itemHeight*0.8 }

                        return <Link to={`${linkTo}`}>
                            <div className={`ui_chooser_plashka_item_mobile ${tab === name ? 'ui_chooser_plashka_item_mobile_selected' : ''}`}>
                                {icon ? <span className='ui_chooser_plashka_item_mobile_up' style={square_style}>{navIcon(icon, tab === name, icon_style)}</span> : null}
                                <span className='ui_chooser_plashka_item_mobile_down'>{Title}</span>
                            </div>
                        </Link>
                    }

                    return <Link to={`${linkTo}`}>
                        <div className={`ui_chooser_plashka_item ${tab === name ? 'ui_chooser_plashka_item_selected' : ''}`}>
                            {icon ? <span>{navIcon(icon, tab === name)}</span> : null}
                            <span>{Title}</span>
                        </div>
                    </Link>
                }

                return null;

            })}
        </div>
    }

}

// export default withCapoeiraSportState('client')(EventLittleListPlashka);