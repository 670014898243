import React, { Component } from 'react';
import { Input, Select, Checkbox, DatePicker, Icon } from 'antd';
import ButtonPlashka from 'src/ver2/plashki/ui/button';

import SchoolPlate from 'src/ver2/components/school/schoolPlate';
import CordaoPlate from 'src/ver2/components/cordao/cordaoPlate';
import GroupPlate from 'src/ver2/components/group/groupPlate';

import { AdderParticipatorsSelect } from './adderParticipatorsSelect';
import { Payment } from './paymentComponent';
import { AdderAdmission } from './adderAdmission';

import MoneyComponent from 'src/ver2/components/money';
import ScoreComponent from 'src/ver2/components/scores';

import { withCapoeiraSportState } from 'src/ver2/context';

import './style.css';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

import { isEqual } from 'lodash';

moment.locale('ru');

const { Option } = Select;
const { MonthPicker } = DatePicker;


/**
 * 
 * Это контейнер изменения данных
 * Сюда приходит некий массив данных (data)
 * 
 * при этом сюда приходит набор компонентов - это один value
 * components [
 *      {
 *          neededText, // если undefined - то это не нужно, может быть '' или текст о том, почему необходим
 *          name // то что мы будем брать из данных (Surname)
 *          title // (Фамилия)
 *          text // введите свою фамилию
 *          type // input, select, checkbox, cordao, schools и т.д.
 *          options 
 *          group: {name, title} // если после этого компонента идёт разделитель
 *          
 *      }
 * ]
 * 
 * onButtonClick - это функция, в которую передаётся весь стейт и далее что-то делается
 * changeData- функция, куда передаются текущие изменения
 * header, - над всем
 * footer, - над кнопкой
 * 
 * buttonText что в кнопку (дефолт - СОХРАНИТЬ)
 * buttonState - можно ли нажать на сохранить. если allNeeded, то обязательно ввести поля с 'neededText' 
 * noButton - если кнопка не нужна (например, если фигачим данные через changeData )
 */

export const compareFunction = (oldState, newState) => {
    const keys = Object.keys(newState);

    let returnState = {};
    for (let i = 0; i < keys.length; i++) {
        if (newState[keys[i]] !== oldState[keys[i]]) {
            returnState[keys[i]] = newState[keys[i]];
        }
    }
    return returnState;
}

class DataChanger extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    // перекидываем все values в стейт
    initialAction = () => {
        const { components, data } = this.props;

        let newState = {};

        for (let i = 0; i < components.length; i++) {
            const component = components[i];
            const { name } = component;
            newState[name] = data[name] !== undefined ? data[name] : null;

            if (component.blockByValue) {
                const keys = Object.keys(component.blockByValue);
                for (let z = 0; z < keys.length; z++) {
                    const blockComponent = component.blockByValue[keys[z]];
                    for (let j = 0; j < blockComponent.length; j++) {
                        newState[blockComponent[j].name] = data[blockComponent[j].name] !== undefined ? data[blockComponent[j].name] : null;
                    }
                }
            }

        }

        this.setState({ ...newState });
    }

    componentDidUpdate({ }, prevState) {
        if (this.props.changeData) {
            if (!isEqual(prevState, this.state))
                this.props.changeData(this.state);
        }
    }

    inputChange = (name, value) => {
        this.setState({ [name]: value });
    }

    schoolChange = (name, value) => {
        this.setState({ [name]: value, Cordao: null });
    }

    scoreChange = (name, type, subtype, value ) => {
        let nowValue = {...this.state[name]};
        nowValue[type][subtype] = value;
        this.setState({ [name]: nowValue });
    }

    participatorsDeleteItem = (name, itemName) => {
        const participators = this.state[name] ? [...this.state[name]] : [];
        const participators_ = participators.reduce((memo, item) => {
            if (item.name === itemName) return memo;
            return [...memo, item];
        }, [])

        this.setState({ [name]: participators_ });
    }

    participatorsChange = (name, itemName, value) => {
        let participators = this.state[name] ? { ...this.state[name] } : {};
        participators[itemName] = value;
        this.setState({ [name]: participators });
    }


    onAddParticipatorsData = ({ title, type, isNecessery, options }) => {
        const { components } = this.props;
        let name = null;

        for (let i = 0; i < components.length; i++) {
            const component = components[i];
            if (component.type === 'participators') {
                name = component.name;
                continue;
            }
        }

        if (!name) return;

        const participatorsComponent = this.state[name];
        if (!participatorsComponent) return;

        this.setState({
            [name]: [
                ...participatorsComponent,
                {
                    name: 'orgvalue_' + Math.round(Math.random() * 1000), title, type, isNecessery, options
                }
            ]
        })

    }


    admissionDeleteItem = (name, itemKey) => {
        const admission = this.state[name] ? [...this.state[name]] : [];
        const admission_ = admission.reduce((memo, item) => {
            if (item.key === itemKey) return memo;
            return [...memo, item];
        }, [])

        this.setState({ [name]: admission_ });
    }

    onAddAdmissionData = ({ title, key = 'org_adm_' + Math.round(Math.random() * 1000), text = '' }) => {
        const { components } = this.props;
        let name = null;

        for (let i = 0; i < components.length; i++) {
            const component = components[i];
            if (component.type === 'admission') {
                name = component.name;
                continue;
            }
        }

        if (!name) return;

        const admissionComponent = this.state[name];
        if (!admissionComponent) return;

        this.setState({
            [name]: [
                ...admissionComponent,
                {
                    key, title, text
                }
            ]
        })

    }



    // проверяем, всё ли необходимые поля заполнены
    isAllNeededOk = () => {
        const { components } = this.props;

        for (let i = 0; i < components.length; i++) {
            const component = components[i];
            const { name, neededText, type } = component;

            if (component.blockByValue &&
                (component.blockByValue[this.state[component.name]]
                    || (!this.state[component.name] && component.blockByValue[false])
                    || (this.state[component.name] === 1 && component.blockByValue[true])
                )) {

                let block = component.blockByValue[this.state[component.name]]
                if (!block && !this.state[component.name] && component.blockByValue[false]) block = component.blockByValue[false];
                if (!block && this.state[component.name] === 1 && component.blockByValue[true]) block = component.blockByValue[true];

                if (block)
                    for (let j = 0; j < block.length; j++) {
                        const blockComponent = block[j];
                        const ourValue = this.state[blockComponent.name];
                        if (blockComponent.neededText && (!ourValue || ourValue === '')) return false;
                    }
            }

            if (!neededText) continue;

            const ourValue = this.state[name];

            // отдельная проверка payment
            if (type === 'payment' && ourValue) {
                const { participatorsCategorys } = ourValue;
                for (let i = 0; i < participatorsCategorys.length; i++) {
                    const participatorsCategory = participatorsCategorys[i];
                    const { payments } = participatorsCategory;
                    for (let j = 0; j < payments.length; j++) {
                        if (!payments[j].date) { return false };
                    }
                }
            }

            if (!ourValue || ourValue === '') return false;
        }

        return true;
    }

    componentDidMount() {
        this.initialAction();
    }


    render() {
        const { components, header, buttonText, buttonState = 'all', onButtonClick = () => { }, footer = null, noButton, isMobile } = this.props;

        let componentsRender = [];

        components.map((component) => {
            componentsRender.push(<this.componentRender
                key={'d_c_c' + component.name}
                {...component}
            />);

            if (component.blockByValue &&
                (component.blockByValue[this.state[component.name]]
                    || (!this.state[component.name] && component.blockByValue[false])
                    || (this.state[component.name] === 1 && component.blockByValue[true])
                )) {

                componentsRender.push(<this.Devider key={'dev_c_c' + component.name} />)

                let block = component.blockByValue[this.state[component.name]]
                if (!block && !this.state[component.name] && component.blockByValue[false]) block = component.blockByValue[false];
                if (!block && this.state[component.name] === 1 && component.blockByValue[true]) block = component.blockByValue[true];

                if (block)
                    for (let i = 0; i < block.length; i++) {
                        const blockComponent = block[i];
                        componentsRender.push(<this.componentRender
                            key={'d_c_c' + blockComponent.name}
                            {...blockComponent}
                        />);
                    }
            }

            if (component.nextGroup) {
                componentsRender.push(<this.Devider key={'devv_c_c' + component.name} text={component.nextGroup.title} />)
            }

            return '';
        })

        let buttonDisabled = false;
        if (buttonState === 'allNeeded') {
            buttonDisabled = !this.isAllNeededOk();
        }

        return <div className={'data_changer_container ' + (isMobile ? 'data_changer_container_mobile' : '')}>
            <div className='data_changer_header'>
                {header}
            </div>
            {componentsRender}
            <div className='data_changer_footer'>
                {footer}
                {noButton ? null : <ButtonPlashka
                    upperStyle={{ width: 180, height: 40 }}
                    disabled={buttonDisabled}
                    disabledDiscription={'Введены не все обязательные поля'}
                    onClick={() => { onButtonClick(this.state) }}
                    text={buttonText || 'СОХРАНИТЬ'}
                />}
            </div>

        </div>
    }

    Devider = ({ text }) => {
        if (!text || text === ' ') {
            return <div className='data_changer_devider' />
        }

        return <div className='data_changer_devider_withtext'>
            <div className='data_changer_devider_withtext_line_first' />
            <div className='data_changer_devider_withtext_text'>{text}</div>
            <div className='data_changer_devider_withtext_line_second' />
        </div>
    }

    componentRender = (component) => {
        const {
            name,
            title,
            text,
            type,
            style,
            neededText
        } = component;

        const value = this.state[name];
        const { buttonState = 'all' } = this.props;

        let ComponentRendered = null;
        let AdditionComponents = null;
        switch (type) {
            case 'input': {
                ComponentRendered = <this.inputCopmonent {...{ name, value, style }} />
                break;
            }
            case 'select': {
                const { options } = component;
                ComponentRendered = <this.selectCopmonent {...{ name, value, options }} />
                break;
            }
            case 'checkbox': {
                ComponentRendered = <this.checkboxCopmonent {...{ name, value }} />
                break;
            }
            case 'date': {
                ComponentRendered = <this.dateCopmonent {...{ name, value }} />
                break;
            }
            case 'dateMonth': {
                ComponentRendered = <this.dateCopmonent {...{ name, value, isMonth: true }} />
                break;
            }

            case 'participators': {
                ComponentRendered = <this.dataParticipators {...{ name, value }} />
                AdditionComponents = <AdderParticipatorsSelect onAddData={this.onAddParticipatorsData} />
                break;
            }
            case 'payment': {
                ComponentRendered = <this.paymentComponent {...{ name, value }} />
                break;
            }
            case 'admission': {
                ComponentRendered = <this.admissionComponent {...{ name, value }} />
                AdditionComponents = [
                    <this.AdmissionTypicalSelect key={'aaad1'} {...{ name, value }} />,
                    <AdderAdmission key={'aaad2'} onAddData={this.onAddAdmissionData} />
                ]
                break;
            }
            case 'scores': {
                ComponentRendered = <this.scoresComponent {...{ name, value }} />
                break;
            }
            case 'schools': {
                ComponentRendered = <this.schoolsCopmonent {...{ name, value }} />
                break;
            }
            case 'cordaos': {
                ComponentRendered = <this.cordaoCopmonent {...{ name, value }} />
                break;
            }
            case 'groups': {
                ComponentRendered = <this.groupCopmonent {...{ name, value }} />
                break;
            }
            case 'regions': {
                ComponentRendered = <this.regionCopmonent {...{ name, value }} />
                break;
            }
            default: {
                ComponentRendered = <this.inputCopmonent {...{ name, value }} />
                break;
            }
        }

        let titleClassName = 'data_changer_component_title';
        if (buttonState === 'allNeeded') {
            if (!neededText && (!value || value === '')) {
                titleClassName += ' data_changer_component_title_noneed';
            }
        }


        return <div style={style} className='data_changer_component'>
            <div className={titleClassName}>
                {title}
            </div>
            <div className='data_changer_component_comp'>
                {ComponentRendered}
            </div>
            {AdditionComponents}
            {text && <div className='data_changer_component_text'>{text}</div>}
        </div>

    }

    inputCopmonent = ({ name, value, style }) => {

        return <Input style={style} onChange={(e) => { this.inputChange(name, e.target.value) }} value={value} />

    }

    selectCopmonent = ({ name, value, options }) => {

        return <Select
            style={{ width: 250 }}
            value={value ? value.toString() : null}
            onChange={(value) => { this.inputChange(name, value) }}
        >
            {options.reduce((memo, { key, title }) => {
                memo.push(
                    <Option value={key.toString()}>
                        {title}
                    </Option>
                );
                return memo;
            }, [])}
        </Select>

    }

    checkboxCopmonent = ({ name, value }) => {
        return <Checkbox checked={value} onChange={(e) => { this.inputChange(name, e.target.checked) }} />
    }

    dateCopmonent = ({ name, value, isMonth = false }) => {
        if (isMonth) {
            return <MonthPicker
                defaultPickerValue={moment(moment().format('YYYY-MM-DD'), 'DD MMM YYYY')}
                locale={locale}
                format={'MMM YYYY'}
                value={(value && value !== 'invalid date' && value !== 'null') ? moment(value, 'MM.YYYY').locale('ru') : null}
                onChange={(value) => { this.inputChange(name, value ? moment(value).locale('ru').format('MM.YYYY') : null) }} />
        }

        return <DatePicker
            defaultPickerValue={moment('', 'DD MMM YYYY')}
            locale={locale}
            format={'DD MMM YYYY'}
            value={(value && value !== 'invalid date' && value !== 'null') ? moment(value, 'DD.MM.YYYY').locale('ru') : null}
            onChange={
                (value) => {
                    this.inputChange(name, value ? moment(value).locale('ru').format('DD.MM.YYYY') : null)
                }} />
    }

    dataParticipators = ({ name, value }) => {
        /*
                мы парсим value - там находятся компоненты выбора: {
                    name: 'tshirts',
                    title: 'футболка',
                    type: 'select'
                    options: [
                        {
                            key: '34-35',
                            title: '34-35 размер'
                        }
                    ],
                    defValue: '34-35'
                }
        */

        if (!value) return <div />

        return value.map((item, index) => {

            const { type, title, name: itemName, isNecessery } = item;
            if (type === 'select' && itemName !== 'participation') {
                const { options } = item;
                return <div key={'dcgn' + index}>
                    <div className='data_changer_component_title data_changer_component_title_participators'>{title} {!isNecessery ? <Icon onClick={() => { this.participatorsDeleteItem(name, itemName) }} type='cross' /> : null}</div>
                    <Select
                        style={{ width: 350 }}
                        onChange={() => { }}
                        value={'Посмотреть варианты'}
                        placeholder='Посмотреть варианты'
                    >
                        {options.reduce((memo, { key, title }) => {
                            memo.push(
                                <Option value={key.toString()}>
                                    {title}
                                </Option>
                            );
                            return memo;
                        }, [])}
                    </Select>
                </div>
            }

            return null;
        })
    }

    paymentComponent = ({ name, value }) => {
        return <Payment
            participatorsCategorys={value ? value.participatorsCategorys : null}
            participatorsSales={value ? value.participatorsSales : null}
            renewPayment={(participatorsCategorys) => { this.inputChange(name, { ...value, participatorsCategorys }) }}
            renewSales={(participatorsSales) => { this.inputChange(name, { ...value, participatorsSales }) }}
        />
    }

    admissionComponent = ({ name, value }) => {
        if (!value) return <div />

        return value.map(({ key, title, text }, index) => {
            return <div key={'dcac-' + index}>
                <div className='data_changer_component_title data_changer_component_title_participators'>
                    <span className='global-tooltip' data-tooltip={text}> {title} </span>
                    <Icon onClick={() => { this.admissionDeleteItem(name, key) }} type='cross' />
                </div>
            </div>
        });
    }

    AdmissionTypicalSelect = ({ name, value }) => {
        const { admissionVars } = this.props;

        if (!admissionVars || admissionVars.length === 0) return null;

        const AdmissionAddingComponent = ({ itemKey, title, text }) => {
            return <div
                onClick={() => { this.onAddAdmissionData({ key: itemKey, title, text }) }}
                data-tooltip={text}
                className='data_change_admission_adding_component global-tooltip'>
                {title}
            </div>
        }

        // мы смотрим какие добавлены, а какие - нет
        const componentsToAdd = admissionVars.reduce((memo, { key, title, text }) => {
            // пробуем найти данный компонент
            if (!value) return [...memo, <AdmissionAddingComponent {...{ itemKey: key, title, text }} />]
            const finded_ = value.find(x => x.key === key);
            if (finded_) return memo;
            return [...memo, <AdmissionAddingComponent {...{ itemKey: key, title, text }} />]
        }, []);

        if (componentsToAdd.length === 0) return null;

        return <div className='data_change_admission_add_typical_main'>
            <div className='data_change_admission_add_typical_title'>Добавить типовые компоненты</div>
            <div className='data_change_admission_add_typical_components'>
                {componentsToAdd}
            </div>
        </div>

    }

    /*
        scores: {
            social: 0,
        },
        money: {
         tri: 0,
         squ: 0,
         gek: 0
        }
    */

    scoresComponent = ({ name, value }) => {
        const { scores, money } = value || {};
        const scoresKeys = scores ? Object.keys(scores) : [];
        const moneyKeys = money ? Object.keys(money) : [];

        // это один компонентик с инпутом
        const OneComponent = ( { type, subtype, value } ) => {
            return <div className='dataChanger_scoresComponent_one'>
                {type === 'money' && <MoneyComponent size={55} type={subtype} noAmount />}
                {type === 'scores' && <ScoreComponent size={55} type={subtype} noAmount />}
                <Input style = {{width: 70, height: 30}} size ='small' value = {value} onChange ={(e) => this.scoreChange(name, type, subtype, e.target.value)} />
            </div>
        }

        return <div className='dataChanger_scoresComponent'>
            {scoresKeys.map (name => <OneComponent key = {`dcsc-${name}`} type = 'scores' subtype = {name} value = {scores[name]} />)}
            {moneyKeys.map (name => <OneComponent key = {`dcsc-${name}`} type = 'money' subtype = {name} value = {money[name]} />)}
        </div>
    }

    schoolsCopmonent = ({ name, value }) => {

        const { client } = this.props;

        const schools = client.getAllSchools();

        return <Select
            style={{ width: 250 }} size='large'
            value={value ? value.toString() : null}
            onChange={(value) => { this.schoolChange(name, value) }}
        >
            {Object.keys(schools).reduce((memo, key) => {
                memo.push(
                    <Option value={key.toString()}>
                        <SchoolPlate width={200} height={40} SchoolId={key} linkTo={null} />
                    </Option>
                );
                return memo;
            }, [])}
        </Select>
    }

    cordaoCopmonent = ({ name, value }) => {

        const { client } = this.props;
        // const { SchoolId } = this.state;
        let SchoolId = this.state.SchoolId;

        if (!SchoolId) SchoolId = this.props.SchoolId;
        if (!SchoolId) return null;

        const school = client.getSchoolById(SchoolId.toString());
        if (!school) return null;
        const gradation = school.Gradation; // это ассоциативный массив

        return <Select
            style={{ width: 250 }} size='large'
            value={value ? value.toString() : null}
            onChange={(value) => { this.inputChange(name, value) }}
        >
            {Object.keys(gradation).reduce((memo, key) => {
                memo.push(
                    <Option value={key.toString()}>
                        <CordaoPlate width={200} height={40} SchoolId={SchoolId} Cordao={key} />
                    </Option>
                );
                return memo;
            }, [])}
        </Select>
    }

    groupCopmonent = ({ name, value }) => {

        const { client } = this.props;
        // const { SchoolId } = this.state;
        let SchoolId = this.state.SchoolId;

        if (!SchoolId) SchoolId = this.props.SchoolId;
        if (!SchoolId) return null;

        const groups = client.getAllGroups(SchoolId)

        return <Select
            style={{ width: 250 }} size='large'
            value={value ? value.toString() : null}
            onChange={(value) => { this.inputChange(name, value) }}
        >
            <Option value={'0'}>
                <span>НЕТ ГРУППЫ</span>
            </Option>
            {Object.keys(groups).reduce((memo, key) => {
                memo.push(
                    <Option value={key.toString()}>
                        <GroupPlate width={200} height={40} GroupId={key} />
                    </Option>
                );
                return memo;
            }, [])}
        </Select>
    }

    regionCopmonent = ({ name, value }) => {

        const { client: { regions } } = this.props;
        const regionKeys = Object.keys(regions);
        let options = [{
            key: 'null',
            title: 'НЕ УКАЗАН'
        }]
        for (let i = 1; i < regionKeys.length; i++) {
            options.push(regions[regionKeys[i]]);
        }

        return <Select
            style={{ width: 250 }}
            value={value ? value.toString() : null}
            onChange={(value) => { this.inputChange(name, value) }}
        >
            {options.reduce((memo, { key, title }) => {
                memo.push(
                    <Option value={key.toString()}>
                        {title}
                    </Option>
                );
                return memo;
            }, [])}
        </Select>
    }

}


export default withCapoeiraSportState('client, isMobile')(DataChanger);