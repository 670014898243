import React, { Component } from 'react';
import Medal from 'src/ver2/components/medal';
import { withCapoeiraSportState } from 'src/ver2/context';
import Loader from 'src/ver2/components/loader';
import { Tooltip } from 'antd';

import './style.css';

//getEventById

/**
  Это плашка, которая отображает медали, сюда приходит массив медалей, мы их показываем,
  но может быть вариант "as count" - Тогда приходит 
  Medals: [10, 5, 15] - количество бронзы и т.д.
 */

class MedalShalvePlashka extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            asCountMedalsLoaded: false,
            counts: {
                1: 0,
                2: 0,
                3: 0
            }
        }
    }

    shouldComponentUpdate({ Medals, upperStyle }, { showModal, asCountMedalsLoaded }) {
        if (Medals !== this.props.Medals) return true;
        if (upperStyle.height !== this.props.upperStyle.height) return true;
        if (showModal !== this.state.showModal) return true;
        if (asCountMedalsLoaded !== this.state.asCountMedalsLoaded) return true;
        return false;
    }

    componentDidMount() {
        if (this.props.schoolId) {
            const { client } = this.props;
            client.getSubjectMedalsCount(this.props.schoolId).then((result) => {
                let counts = {
                    1: 0,
                    2: 0,
                    3: 0
                }

                for (let i = 0; i < result.length; i++) {
                    if (result[i].Season === '2021-2022') {
                        counts[result[i].Place] = result[i].Medals;
                    }
                }

                console.log('componentDidUpdate MEDALS');
                this.setState({ counts, asCountMedalsLoaded: true })
            })
        }
    }


    componentDidUpdate({ schoolId }) {

        if (this.props.schoolId && !this.state.asCountMedalsLoaded) {
            const { client } = this.props;
            client.getSubjectMedalsCount(this.props.schoolId).then((result) => {
                let counts = {
                    1: 0,
                    2: 0,
                    3: 0
                }

                for (let i = 0; i < result.length; i++) {
                    if (result[i].Season === '2021-2022') {
                        counts[result[i].Place] = result[i].Medals;
                    }
                }

                console.log('componentDidUpdate MEDALS');
                this.setState({ counts, asCountMedalsLoaded: true })
            })
        }
    }

    render() {

        const { Medals, elementHeight, upperStyle, asCount, schoolId, setComponentSelfHeight, plashkaId, isMobile } = this.props;
        const { asCountMedalsLoaded, counts } = this.state;
        // if (!schoolId) return <div className='medal_shalve_plashka'>
        //     Загрузка...
        // </div>

        const { width, height } = upperStyle;

        if (asCount && !asCountMedalsLoaded) {
            return <div style={{ width, height }} className='medal_shalve_plashka medal_shalve_plashka_ascount global-slide-from-right'>
                <Loader />
            </div>
        }

        if (asCount) {
            const medals = [
                <Medal
                    key={'med' + 1}
                    Id={1}
                    eventId={0}
                    schoolId={schoolId}
                    drawWidth={74}
                    bg={'gold'}
                    noRotate={{ rotate: 1 }}
                    shape={'circle'}
                    develop={true}
                    withCount={counts[1]}
                    withCountClassName={'medal_shalve_plashka_medal_ascount'}
                />,
                <Medal
                    key={'med' + 2}
                    Id={2}
                    eventId={0}
                    schoolId={schoolId}
                    drawWidth={74}
                    bg={'silver'}
                    noRotate={{ rotate: 1 }}
                    shape={'circle'}
                    develop={true}
                    withCount={counts[2]}
                    withCountClassName={'medal_shalve_plashka_medal_ascount'}
                />,
                <Medal
                    key={'med' + 3}
                    Id={3}
                    eventId={0}
                    schoolId={schoolId}
                    drawWidth={74}
                    bg={'bronze'}
                    noRotate={{ rotate: 1 }}
                    shape={'circle'}
                    develop={true}
                    withCount={counts[3]}
                    withCountClassName={'medal_shalve_plashka_medal_ascount'}
                />
            ]
            return <Tooltip placement='top' title='Заработано медалей за текущий сезон'>
                <div style={{ width, height }} className='medal_shalve_plashka medal_shalve_plashka_ascount global-slide-from-right'>
                    {medals}
                </div>
            </Tooltip>
        }

        let medals = [];
        if (!Medals || !Medals.length) {
            setComponentSelfHeight({ height: 1, plashkaId });
            return <div style={{ width, height }} className='medal_shalve_plashka global-slide-from-right'></div>;
        }

        let medalsMaxCount = isMobile ? 6 : 100;

        for (let i = Medals.length - 1; i >= 0; i--) {
            medalsMaxCount--;
            const medal = Medals[i];
            const { EventId, Place, UserId } = medal;
            let bg = '';
            switch (Place.toString()) {
                case '1': bg = 'gold'; break;
                case '2': bg = 'silver'; break;
                case '3': bg = 'bronze'; break;

                default: bg = 'bronze';
            }
            medals.push(
                <Medal
                    onClick={() => { this.props.showInfoModal({ type: 'medal', medal }) }}
                    key={'med-' + UserId + '-' + i}
                    Id={UserId + '-' + i}
                    eventId={EventId}
                    drawWidth={95}
                    bg={bg}
                    noRotate={true}
                    shape={'circle'}
                    develop={true}
                />
            )
            if (medalsMaxCount === 0) break;
        }

        const MedalLength = isMobile ? 
             (Medals.length) > 6 ? 6 : Medals.length
        : Medals.length

        setComponentSelfHeight({ plashkaId, height: 40 + 115 * (Math.round((MedalLength - MedalLength % 3) / 3) + 1) });

        return <div style={{ width, height }} className='medal_shalve_plashka global-slide-from-right'>
            {medals}
        </div>
    }

}

export default (withCapoeiraSportState('showInfoModal, client, isMobile')(MedalShalvePlashka));