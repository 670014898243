import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { Popover } from 'antd';
import { PersonIncludes, SchoolIncludes, EventIncludes, VideoIncludes, ItemIncludes, TechnicsIncludes, SequenceIncludes } from './includes';
import CapoeiraSportState from 'src/ver2/context';
import { withCapoeiraSportState } from 'src/ver2/context';
import { BrowserRouter } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import './style.css';

/*

    Это интерпритатор текста на Портале. Сюда приходит текст длинной строкой (мб файлом), у него есть ключевые символы, которые делят его 
    на "абзацы" и вставляют различные метаданные
    ^a_^ - это абзац, т.е. новый div
    ^n_^ - это перенос строки 
    ^b_text^ - это font-weight: bold

    ^u_2^ - это юзер, id = 2
    ^s_2^ - школа
    ^e_2^ - мероприятие
    ^v_2^ - видео
    ^q_yybw0e1e^ - связка
    ^t_

    onRender - колбэк, куда возвращается высота текста

    style.fontSize
          minWidth
          avatarsSize
          color
*/

const processText = (blockText, containerWidth = 300) => {
    // делим текст на куски    
    const blockText_ = blockText.split('^');

    const blockHTML = blockText_.reduce((memo, current) => {

        const { html, even } = memo;

        // это идёт просто текст и мы просто добавляем current
        if (!even) {
            return { html: html + current, even: true }
        }

        // тут идёт обработка какого-то текста
        if (even) {

            if (current[0] === 'b') {
                const addedText = `<b style = 'color: black'>${current.slice(2)}</b>`;
                return { html: html + addedText, even: false }
            }
            if (current[0] === '1') {
                const addedText = `<h1 style = 'color: black'>${current.slice(2)}</h1>`;
                return { html: html + addedText, even: false }
            }
            if (current[0] === '2') {
                const addedText = `<h2 style = 'color: black'>${current.slice(2)}</h2>`;
                return { html: html + addedText, even: false }
            }

            if (current[0] === 'u') {
                const addedText = `<span class='include_person' attr = '${current.slice(2)}'></span>`;
                return { html: html + addedText, even: false }
            }

            if (current[0] === 's') {
                const addedText = `<span class='include_school' attr = '${current.slice(2)}'></span>`;
                return { html: html + addedText, even: false }
            }

            if (current[0] === 'e') {
                const addedText = `<span class='include_event' attr = '${current.slice(2)}'></span>`;
                return { html: html + addedText, even: false }
            }

            if (current[0] === 'v') {
                let videoWidth = Math.max(containerWidth, 330);
                if (videoWidth > 600) videoWidth = 600;

                const addedText = `<div class='include_video' style="height: ${videoWidth * 280 / 330}px;" attr = '${current.slice(2)}'> video </div>`;
                return { html: html + addedText, even: false }
            }

            if (current[0] === 'q') {
                const addedText = `<div class='include_sequence' style="height: 80px;" attr = '${current.slice(2)}'> video </div>`;
                return { html: html + addedText, even: false }
            }

            if (current[0] === 'i') {
                const addedText = `<span class='include_item' attr = '${current.slice(2)}'></span>`;
                return { html: html + addedText, even: false }
            }


            if (current[0] === 'n') {
                const addedText = `</br>`;
                return { html: html + addedText, even: false }
            }

            // техника
            if (current[0] === 't') {
                const addedText = `<span class='include_technics' attr = '${current.slice(2)}'></span>`;
                return { html: html + addedText, even: false }
            }

            return { html, even: !even }
        }

        return { html, even: !even }

    }, { html: `<div class = 'trefa_text_container_textblock'>`, even: false }).html + `</div>`;

    return blockHTML;

}

const processImg = (blockText) => {
    // делим текст на куски    
    const blockText_ = blockText.split('^');

    const blockHTML = blockText_.reduce((memo, current) => {

        const { html, even } = memo;

        // это идёт просто текст и мы просто добавляем current
        if (!even) {
            return { html: html, even: true }
        }

        // тут идёт обработка какого-то текста
        if (even) {

            if (current[0] === 's') {
                const addedText = ` src='${current.slice(2)}' `;
                return { html: html + addedText, even: false }
            }

            if (current[0] === 'w') {
                const addedText = ` width='${current.slice(2)}' `;
                return { html: html + addedText, even: false }
            }

            if (current[0] === 'h') {
                const addedText = ` height='${current.slice(2)}' `;
                return { html: html + addedText, even: false }
            }

            return { html, even: !even }
        }

        return { html, even: !even }

    }, { html: `<div class = 'trefa_text_container_imgblock'><img `, even: false }).html + `/></div>`;

    return blockHTML;

}

class TrefaText extends PureComponent {

    constructor(props) {
        super(props);
        this.id = Math.floor(Math.random() * 10000);
    }

    get componentId() { return ('trefaText-' + this.id) }

    componentDidMount() {
        this.renewAll();
    }

    componentDidUpdate() {
        this.renewAll();
    }

    redirect = (link) => {
        this.props.history.push(link);
        this.props.closeInfoModal(); // на всякий случай
    }

    renewAll = () => {
        this.processAll();

        const personElements = document.getElementsByClassName('include_person');
        const targetDiv = document.getElementById(this.componentId);

        const { style = {} } = this.props;
        const { client, showInfoModal } = this.props;
        const avatarsSize = style.avatarsSize || 25;

        if (personElements) {
            for (let i = 0; i < personElements.length; i++) {
                const element = personElements[i];
                if (element.attributes.attr && element.attributes.attr.value)
                    ReactDOM.render(
                        <CapoeiraSportState client={this.props.client}>
                            <PersonIncludes personId={element.attributes.attr.value} link='/person/' onLinkClick={this.redirect} avatarsSize={avatarsSize} />
                        </CapoeiraSportState>,
                        // <Popover content = {<div>А это поповер</div>} ><span>ИМЯ БЛЯТЬ</span></Popover>,
                        element
                    );
            }
        }

        const schoolElements = document.getElementsByClassName('include_school');

        if (schoolElements) {
            for (let i = 0; i < schoolElements.length; i++) {
                const element = schoolElements[i];
                if (element.attributes.attr && element.attributes.attr.value)
                    ReactDOM.render(
                        <CapoeiraSportState client={this.props.client}>
                            <SchoolIncludes schoolId={element.attributes.attr.value} link='/school/' onLinkClick={this.redirect} avatarsSize={avatarsSize} />
                        </CapoeiraSportState>,
                        element
                    );
            }
        }

        const eventElements = document.getElementsByClassName('include_event');

        if (eventElements) {
            for (let i = 0; i < eventElements.length; i++) {
                const element = eventElements[i];
                if (element.attributes.attr && element.attributes.attr.value)
                    ReactDOM.render(
                        <CapoeiraSportState client={this.props.client}>
                            <EventIncludes eventId={element.attributes.attr.value} link='/event/' onLinkClick={this.redirect} avatarsSize={avatarsSize} />
                        </CapoeiraSportState>,
                        element
                    );
            }
        }

        const videolements = document.getElementsByClassName('include_video');

        if (videolements) {
            for (let i = 0; i < videolements.length; i++) {
                const element = videolements[i];

                let videoWidth = targetDiv.clientWidth;
                if (videoWidth < 330) videoWidth = Math.min(330 / 0.95, document.documentElement.clientWidth / 0.95);

                if (element.attributes.attr && element.attributes.attr.value)
                    ReactDOM.render(
                        <CapoeiraSportState client={this.props.client}>
                            <VideoIncludes videoId={element.attributes.attr.value} videoWidth={videoWidth} onLinkClick={this.redirect} />
                        </CapoeiraSportState>,
                        element
                    );
            }
        }

        const sequenceElements = document.getElementsByClassName('include_sequence');

        if (sequenceElements) {
            for (let i = 0; i < sequenceElements.length; i++) {
                const element = sequenceElements[i];

                let videoWidth = targetDiv.clientWidth;
                if (videoWidth < 330) videoWidth = Math.min(330 / 0.95, document.documentElement.clientWidth / 0.95);

                if (element.attributes.attr && element.attributes.attr.value)
                    ReactDOM.render(
                        <CapoeiraSportState client={this.props.client}>
                            <SequenceIncludes sequenceCode={element.attributes.attr.value} link='/sequence/' videoWidth={videoWidth} onLinkClick={this.redirect} />
                        </CapoeiraSportState>,
                        element
                    );
            }
        }

        const itemelements = document.getElementsByClassName('include_item');

        if (itemelements) {
            for (let i = 0; i < itemelements.length; i++) {
                const element = itemelements[i];
                if (element.attributes.attr && element.attributes.attr.value) {
                    const Item = client.getAbstractItem(element.attributes.attr.value);
                    ReactDOM.render(
                        <CapoeiraSportState client={this.props.client}>
                            <ItemIncludes Item={Item} onLinkClick={() => { showInfoModal({ type: 'item', Item }) }} avatarsSize={avatarsSize} />
                        </CapoeiraSportState>
                        ,
                        element
                    );
                }
            }
        }

        const technicsEelements = document.getElementsByClassName('include_technics');

        if (technicsEelements) {
            for (let i = 0; i < technicsEelements.length; i++) {
                const element = technicsEelements[i];
                if (element.attributes.attr && element.attributes.attr.value) {

                    const elementTech = client.getServerElementsAsObject()[element.attributes.attr.value];
                    const srcElement = client.getTechnicLogoUrl(element.attributes.attr.value);

                    ReactDOM.render(
                        <CapoeiraSportState client={this.props.client}>
                            <TechnicsIncludes onLinkClick={this.redirect} link='/technic/' srcElement = {srcElement} elementTech={elementTech} avatarsSize={avatarsSize} />
                        </CapoeiraSportState>
                        ,
                        element
                    );
                }
            }
        }

        const { onRender } = this.props;
        if (onRender) {
            if (targetDiv) {
                onRender(targetDiv.clientHeight);
            }
        }


    }

    processAll = () => {

        const targetDiv = document.getElementById(this.componentId);
        if (!targetDiv) return;

        let trefaHTML = '';

        // начинаем процессить специальные символы
        // разделяем абзацы

        const trefaText_ = this.props.trefaText.split('^a_^');

        trefaHTML = trefaText_.reduce((memo, current) => {
            // тут мы обрабатываем каждый блок. если even - то там техническое описание, что нас ждёт
            const { html, even, nextType } = memo;

            if (even) {
                return { html, even: false, nextType: current }
            }

            // тут идёт обработка пришедшего типа
            if (!even) {
                if (nextType === 'text')
                    return { html: html + processText(current, targetDiv.clientWidth), even: true, nextType: '' }

                if (nextType === 'image')
                    return { html: html + processImg(current), even: true, nextType: '' }

                return { html, even: true, nextType: '' }
            }

            return { html, even: !even, nextType }

        }, { html: '', even: false, nextType: '' }).html;

        targetDiv.innerHTML = `${trefaHTML}`
    }

    render() {

        const { style } = this.props;
        let style_ = {};
        if (style) {
            if (style.fontSize) style_.fontSize = style.fontSize;
            if (style.minWidth) style_.minWidth = style.minWidth;
            if (style.color) style_.color = style.color;
        }
        return <div id={this.componentId} className='trefa_text_container' style={style_} />
    }

}

export default withRouter(withCapoeiraSportState('iamuser, client, closeInfoModal, isMobile, showInfoModal')(TrefaText));