
import axios from 'axios';

async function articleConstructor({ ActionData = 'get', TargetId = null, Data = null }) {
    let uri = 'https://' + this.ServerUrl + `/articleConstructor`;

    let bodyFormData = new FormData();
    if (ActionData) bodyFormData.set('ActionData', ActionData);
    if (TargetId) bodyFormData.set('TargetId', TargetId);
    if (Data) bodyFormData.set('Data', JSON.stringify(Data));
    bodyFormData.set('sessionId', this.lastSessionId);


    const res = await axios({
      method: 'post',
      url: uri,
      data: bodyFormData,
      config: {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      }
    });

    if (res && res.data && res.data.result && res.data.result.data && res.data.result.status === "Sucess") {
      return res.data.result.data;
    }

    return null;
}

export default {
    articleConstructor
}
