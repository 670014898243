import React from 'react';

export default () => <svg width="24.25mm" height="24.25mm"
    viewBox="0 0 2425 2425" >
    <g id="Слой_x0020_1">
        <g id="_2228900429360">
            <path class="ui_icon__fill" d="M64213 185544c-37,-4 -74,-6 -112,-6 -514,0 -931,416 -931,930 0,514 417,931 931,931 514,0 930,-417 930,-931 0,-122 -23,-238 -66,-345l-864 345 112 -924z" />
            <path class="ui_icon__fill" d="M64325 185398l-113 924 865 -345c-124,-310 -410,-537 -752,-579z" />
        </g>
        <path class="ui_icon__fill" d="M1195 843c63,0 120,5 178,36 36,19 74,42 103,73l15 17c24,24 42,58 57,86 47,88 46,228 -1,317 -36,67 -44,73 -90,119l-31 24c-109,77 -256,91 -375,31 -22,-11 -41,-21 -58,-34 -38,-28 -34,-31 -60,-53l-20 -25c-100,-128 -96,-316 2,-443l20 -25c14,-12 27,-29 34,-34 5,-4 8,-6 13,-10l12 -9c50,-37 136,-70 201,-70zm188 1053l27 -27c4,-4 13,-10 17,-13 11,-8 22,-13 36,-19 55,-27 126,-23 183,1 27,12 49,24 72,39 11,8 22,15 32,23 31,25 28,29 41,18 2,-2 3,-2 5,-4l33 -27c5,-5 2,-5 8,-10 7,-5 13,-9 20,-15 21,-21 37,-42 50,-54l17 -18c-6,-14 -27,-38 -37,-51 -39,-55 -75,-116 -75,-191 0,-45 19,-103 49,-134 5,-5 4,-3 9,-9 5,-7 10,-10 16,-16 5,-6 5,-5 10,-8 56,-43 155,-67 227,-67 5,-78 5,-119 0,-197 -3,-2 -1,-1 -5,-3 -25,-8 -148,-6 -227,-75 -91,-79 -100,-194 -39,-298 18,-31 39,-63 63,-88 5,-6 6,-7 9,-15 -13,-10 -21,-24 -32,-36 -6,-7 -12,-9 -18,-17 -11,-13 -24,-21 -34,-35l-58 -49c-9,5 -15,11 -23,18 -4,3 -7,6 -11,9 -21,14 -40,29 -64,43 -88,50 -180,55 -265,-5 -6,-4 -3,-2 -9,-7l-40 -46c-37,-51 -59,-134 -61,-208 -43,-4 -74,-8 -116,-7l-80 6c1,74 -30,181 -74,228l-12 12c-17,16 -22,22 -44,35 -32,19 -75,31 -117,31 -66,0 -127,-36 -177,-72 -7,-5 -17,-12 -24,-18l-24 -19 -38 31c-22,17 -62,63 -72,71 -9,7 -23,24 -30,34l-3 4c5,9 3,5 9,12 54,60 103,141 103,224 0,43 -24,115 -58,142 -12,9 -15,17 -36,33 -22,16 -60,33 -92,41 -33,10 -88,21 -122,21 -5,80 -5,120 0,200 81,0 174,27 231,71 12,9 38,41 47,56 62,113 16,220 -57,314 -3,4 -2,3 -5,6l-20 25c13,18 26,33 41,47 15,13 28,31 44,43 2,2 7,6 10,8l37 33c1,1 5,4 5,4l6 4 63 -47c20,-14 44,-29 71,-40 65,-26 119,-27 183,-2 14,6 23,11 35,19 6,4 9,6 15,11l15 14c14,16 12,11 26,30 7,9 15,21 22,34 21,42 43,127 43,179 77,5 115,6 192,0 4,-6 3,-15 4,-23 1,-8 2,-16 3,-24 9,-53 31,-142 70,-180z" />
    </g>
</svg>