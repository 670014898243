import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';

import './styles.css';


class ArticleLittle extends PureComponent {

    state = {
        article: null
    }

    componentDidMount() {
        this.initArticle();
    }

    initArticle = async () => {
        const { article, articleId, client } = this.props;
        if (article) {
            this.setState({ article })
            return;
        }

        const articlesData = await client.articleConstructor({ ActionData: 'get', TargetId: articleId });
        if (articlesData && articlesData[0]) {
            this.setState({ article: articlesData[0] })
            return;
        }

        this.setState({ article: null })

    }

    render() {
        const { article } = this.state;
        if (!article) return null;

        const { Id, Title } = article;

        return <div className='article_little_container'>
            <Link to={`/article/${Id}`}>
                <span>
                    {Title}
                </span>
                <Icon type='info-circle' style = {{ fontSize: 14 }}/>
            </Link>
        </div>
    }

}

export default withCapoeiraSportState('client, isMobile')(ArticleLittle);