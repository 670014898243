import React from 'react';
import { CheckIcon, CrossIcon } from 'src/ver2/components/ui/icons';
// import { Link } from 'react-router-dom';
import './style.css';


export const AdmissionBlock = ({ title, text, cross = false, checked, height = 60, onClick = null }) => {

    let className = 'event_block_admission';

    // let thisOurData = ourData.find(x => x.Type === itemKey);

    let icon = null;

    if (checked) {
        className += ' event_block_admission_check';
        icon = <CheckIcon width={height*0.44} height={height*0.44} />
    }

    if (cross) {
        className += ' event_block_admission_cross';
        icon = <CrossIcon width={height*0.44} height={height*0.44} />
    }

    if (onClick) {
        className += ' event_block_admission_clicked';
    }

    return <div onClick = {onClick} style = {{width: height, height}} className={className}>
        <div className='event_block_admission_upper'>
            {icon}
        </div>
        <div className='event_block_admission_downed'>
            <span>
                {title}
            </span>
        </div>
    </div>
}