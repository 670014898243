import React, { PureComponent } from 'react';

import { withGroupState } from 'src/ver2/pages/group/context';
import GroupAvatar from 'src/ver2/components/group/avatar';
import SchoolRect from 'src/ver2/components/school/schoolRect';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import PlaceRect from 'src/ver2/containers/lowlavel/placeRect';
import { Tooltip } from 'antd';

import Loader from 'src/ver2/components/loader';
import { Link } from 'react-router-dom';

import './style.css';

// class BigGroupPlashka
// classname: big_group_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class BigGroupPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    medalsCount = () => {

        const { Students } = this.props;
        if (!Students || !Students.length) return null;

        let result = { Gold: 0, Silver: 0, Bronze: 0 };
        for (let i = 0; i < Students.length; i++) {
            if (Students[i].Gold) result.Gold += Students[i].Gold;
            if (Students[i].Silver) result.Silver += Students[i].Silver;
            if (Students[i].Bronze) result.Bronze += Students[i].Bronze;
        }

        if (!result.Gold && !result.Silver && !result.Bronze) return null;

        return result;
    }

    MedalBlock = ({ height }) => {

        const medalsCount_ = this.medalsCount();

        if (!medalsCount_) return null;

        const medals = <div className='big_group_plashka_medals'>
            <PlaceRect height={height * 0.20} Place={1} PlaceName={medalsCount_.Gold} asMedal={true} />
            <PlaceRect height={height * 0.20} Place={2} PlaceName={medalsCount_.Silver} asMedal={true} />
            <PlaceRect height={height * 0.20} Place={3} PlaceName={medalsCount_.Bronze} asMedal={true} />
        </div>

        return <NumberText count={medals} text={'МЕДАЛИ'} height={height * 0.3} />
    }

    render() {

        const { Group, upperStyle, Students } = this.props;
        const { width, height } = upperStyle;

        if (!Group) return <div style={{ width, height }} className='big_group_plashka'>
            <Loader />
        </div>

        const { Id, Name, SchoolId, Region } = Group;
        console.log('Group', Group);

        return <div style={{ width, height }} className='big_group_plashka'>
            <div style={{ width: height, height: height }} className='big_group_plashka___avatar_back'>
                <GroupAvatar size={height} groupId={Id} />
            </div>
            <div style={{ width: height, height: height }} className='big_group_plashka___avatar_container'>
                <GroupAvatar size={height * 0.75} groupId={Id} />
            </div>
            <div style={{ width: (width - height), height: height }} className='big_group_plashka___info_container'>
                <div style={{ fontSize: height * 0.3 }} className='big_group_plashka___info_container___name'>
                    {Name}
                </div>
                {/* <div className='big_group_plashka___info_container___info'>
                </div>*/}
                <div className='big_group_plashka___info_container___school_group_cordao'>
                    <SchoolRect
                        width={height * 0.3}
                        SchoolId={SchoolId}
                    />
                    {(Students && Students.length) ? <NumberText count={Students.length} text={'СОСТАВ'} height={height * 0.3} /> : null}
                    <this.MedalBlock height={height} />
                    {(Region) ?

                        <Tooltip placement='top' title={'Регион, от которого в основном выступает группа: ' + this.props.client.regions[Region].title}>
                            <Link to={`/region/${Region}`}>
                                <span className='big_group_plashka__region'>
                                    <NumberText count={Region} text={'РЕГИОН'} height={height * 0.3} />
                                </span>
                            </Link>
                        </Tooltip>
                        : null}
                </div>
            </div>
        </div >
    }

}

export default withGroupState(`
Group,
isGroupReady,

Students,

client,
iamuser,
`)(BigGroupPlashka)