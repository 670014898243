import { createContext } from 'react';
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { sortBy, groupBy, orderBy } from 'lodash';
import { message } from 'antd';
import { SequenceProcessor } from './sequence';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

const context = createContext(
    {
        defVal: 'FUUUCK YEAH'
    }
);

/*

  Это контекст, в который приходит groupId, он его подгружает и всё выдает наследникам контекста
  в пропсы ожидает groupId, client, iamuser

*/


const initialState = {

    Person: null,
    isPersonReady: false,
    noSuchPerson: false,

    Raiting: null,
    isRaitingReady: false,

    KubokRaiting: null,
    isKubokRaitingReady: false,

    RegionData: null,
    isRegionDataReady: false,

    Medals: null,
    isMedalsReady: false,

    Videos: null,
    isVideosReady: false,

    PortalRoles: null,
    isPortalRolesReady: false,

    Elements: null,
    isElementsReady: false,

    InstructorRoles: null,
    isInstructorRolesReady: false,

    Scores: null,
    isScoresReady: false,

    Money: null,
    isMoneyReady: false,

    Items: null,
    isItemsReady: false,

    Sequences: null,
    isSequencesReady: false,

    ServerQuests: null,
    isServerQuestsReady: false,

    Achivments: null,
    isAchivmentsReady: false,

    isBirthday: false,

    FightStatistics: null,

    FkrAccred: null, // { status: 'no', 'yes', 'application', Application, Item }
    isFkrAccredReady: false,

    iAmPerson: false
}

class PersonState extends PureComponent {


    constructor(props) {
        super(props);

        this.state = initialState;

        if (props.client) {
            this.client = props.client;
        }

    }

    initialLoad = async () => {
        const Person = await this.loadPerson();
        if (!Person) return this.setState({ isPersonReady: false });
        this.setState({ isPersonReady: true, Person });
    }

    loadPerson = async () => {
        const personId = this.props.match.params.personId;
        const { client } = this.props;
        const Person = await client.getUserFromServer(personId, { WithTel: true });

        if (!Person) {
            this.setState({ noSuchPerson: true, Person: null });
        }

        return Person;
    }

    componentDidMount() {
        this.initialLoad();
        this.getSearchObj();
    }

    reloadPerson = async () => {
        this.setState(initialState);
    }

    componentDidUpdate({ iamuser, match }, { isPersonReady, isRegionDataReady }) {

        if (match.params.personId !== this.props.match.params.personId) {
            this.reloadPerson().then(() => {
                this.initialLoad();
            })
        }


        // подгрузили группу        
        if (!isPersonReady && this.state.isPersonReady) {
            this.checkIfIAmPersonAsync();

            this.reloadRegionData();

            this.reloadKubokRaiting();
            this.reloadMedals();

            this.reloadVideos();
            this.reloadPortalRoles();
            this.reloadInstructorRoles();
            this.reloadScores();
            this.reloadItems();
            this.reloadFkrAccred();
            this.reloadElements();
            this.reloadSequences();
            this.reloadServerQuests();
            this.reloadAchivments();
            this.reloadFightsStatistics();
        }

        if (!isRegionDataReady && this.state.isRegionDataReady) {
            this.reloadRaiting();
        }

        if (this.props.iamuser !== iamuser) {
            this.checkIfIAmPersonAsync();
        }

    }

    checkIfIAmPersonAsync = () => {
        this.checkIfAmPerson().then((iAmPerson) => { this.setState({ iAmPerson }) })
    }

    // потом это будет асинхронная хрен, т.к. будем подгружать роли
    checkIfAmPerson = async () => {
        const { iamuser } = this.props;
        const { Person } = this.state;

        if (!iamuser || !Person) return false;

        if (Person.Id === iamuser.Id) return true;

        return false;
    }

    // RAITING //////////////////////////////////////////
    // loadRaiting = async () => {
    //     const { Person } = this.state;
    //     if (!Person) return;
    //     const { Id } = Person;
    //     const { client } = this.props;
    //     const { nowRaiting } = client;
    //     const Raiting = await client.getSubjectRaitingFromServer({ subject: 'user', id: Id, season: nowRaiting });
    //     return Raiting;
    // }

    // reloadRaiting = () => {
    //     this.setState({ isRaitingReady: false });
    //     this.loadRaiting().then(
    //         (Raiting) => {
    //             this.setState({ isRaitingReady: true, Raiting });
    //         }
    //     )
    // }

    loadRaiting = async () => {
        const { Person, RegionData } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const { client } = this.props;
        // const { nowRaiting } = client;
        const RussiaRaiting = await client.getSubjectRaitingFromServer({ subject: 'user', id: Id });
        let RegionRaiting = [];
        // теперь надо подгрузить рейтинг по регионам, если у чела стоит регион
        if (RussiaRaiting && RegionData) {
            // смотрим, какой рейтинг за какие года
            for (let i = 0; i < client.raitingSeasons.length; i++) {
                const Season = client.raitingSeasons[i];
                // смотрим, в какой категории чел выступал в этом сезоне во всероссе
                const russiaRaiting = RussiaRaiting.find(x => x.Season === Season);
                // если российского нет, то нет и регионального
                if (russiaRaiting) {
                    const category = russiaRaiting.Category;
                    let region = null;
                    if (RegionData.GroupRegion) {
                        region = RegionData.GroupRegion.Region;
                    }
                    if (RegionData.UserRegions && RegionData.UserRegions.length) {
                        const regD = RegionData.UserRegions.find(x => x.Season === Season);
                        if (regD && regD.Region) {
                            region = regD.Region;
                        }
                    }
                    // мы тут собрали всё что нужно
                    if (region) {
                        // тут берём как новичковый, так и обычный
                        const regionRaitingPro = await client.getSubjectRaitingFromServer({ subject: 'user', id: Id, as_sum: true, season: Season, type: 'comp_pro', category, region });
                        const regionRaitingNotPro = await client.getSubjectRaitingFromServer({ subject: 'user', id: Id, as_sum: true, season: Season, type: 'comp_not_pro', category, region });
                        if (regionRaitingPro && regionRaitingPro[0]) RegionRaiting.push({ ...regionRaitingPro[0], Region: region })
                        if (regionRaitingNotPro && regionRaitingNotPro[0]) RegionRaiting.push({ ...regionRaitingNotPro[0], Region: region })
                    }
                }
            }
        }



        console.log('loadRaiting', { RussiaRaiting, RegionRaiting });
        return { RussiaRaiting, RegionRaiting };
    }

    reloadRaiting = () => {
        this.setState({ isRaitingReady: false });
        this.loadRaiting().then(
            (Raiting) => {
                this.setState({ isRaitingReady: true, Raiting });
            }
        )
    }

    // REGIONDTA //////////////////////////////////////////
    loadRegionData = async () => {
        const { Person } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const { client } = this.props;
        const { nowRaiting } = client;
        const RegionData = await client.getUserRegionData(Id);
        if (RegionData) {
            const { GroupRegion, UserRegions } = RegionData;
            return {
                GroupRegion,
                UserRegions,
                NowSeason: nowRaiting
            };
        }
        return null;
    }

    reloadRegionData = () => {
        this.setState({ isRegionDataReady: false });
        this.loadRegionData().then(
            (RegionData) => {
                this.setState({ isRegionDataReady: true, RegionData });
            }
        )
    }

    setRegionData = async (Region = null) => {
        const { Person } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const { client } = this.props;
        await client.setUserRegionData(Region);
        message.info('Изменены настройки региона');
        this.reloadRegionData();
    }

    // KUBOK RAITING //////////////////////////////////////////
    loadKubokRaiting = async () => {
        const { Person } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const { client } = this.props;
        const { nowRaiting } = client;
        const Raiting = await client.getSubjectRaitingFromServer({ subject: 'user', id: Id });

        let KubokRaiting = [];

        if (!Raiting) return;

        for (let i = 0; i < Raiting.length; i++) {
            const { Place, Season, Type } = Raiting[i];
            if (Place >= 1 && Place <= 3 && Type === 'comp_pro' && Season !== nowRaiting) {
                KubokRaiting.push({
                    type: 'kubok',
                    value: {
                        season: Season,
                        place: Place,
                        raiting: Type
                    }
                });
            }
        }

        return KubokRaiting;
    }

    reloadKubokRaiting = () => {
        this.setState({ isKubokRaitingReady: false });
        this.loadKubokRaiting().then(
            (KubokRaiting) => {
                this.setState({ isKubokRaitingReady: true, KubokRaiting });
            }
        )
    }

    // MEDALS //////////////////////////////////////////
    loadMedals = async () => {
        const { Person } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const { client } = this.props;
        const Medals = await client.getUserMedals(Id);
        return Medals;
    }

    reloadMedals = () => {
        this.setState({ isMedalsReady: false });
        this.loadMedals().then(
            (Medals) => {
                this.setState({ isMedalsReady: true, Medals });
            }
        )
    }

    // VIDEOS //////////////////////////////////////////
    loadVideos = async () => {
        const { Person } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const { client } = this.props;
        const Videos = await client.getUserVideosFromServer(Id);
        return Videos;
    }

    reloadVideos = () => {
        this.setState({ isVideosReady: false });
        this.loadVideos().then(
            (Videos) => {
                this.setState({ isVideosReady: true, Videos });
            }
        )
    }

    // PORTAL ROLES //////////////////////////////////////////
    loadPortalRoles = async () => {
        const { Person } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const { client } = this.props;
        const PortalRoles = await client.getUserPortalRoles(Id);
        return PortalRoles;
    }

    reloadPortalRoles = () => {
        this.setState({ isPortalRolesReady: false });
        this.loadPortalRoles().then(
            (PortalRoles) => {
                this.setState({ isPortalRolesReady: true, PortalRoles });
            }
        )
    }

    // INSTRUCTOR ROLES //////////////////////////////////////////
    loadInstructorRoles = async () => {
        const { Person } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const { client } = this.props;
        const InstructorRoles = await client.getUserInstructorRoles(Id);
        return InstructorRoles;
    }

    reloadInstructorRoles = () => {
        this.setState({ isInstructorRolesReady: false });
        this.loadInstructorRoles().then(
            (InstructorRoles) => {
                this.setState({ isInstructorRolesReady: true, InstructorRoles });
            }
        )
    }

    // SCORES AND MONEY //////////////////////////////////////////
    loadScores = async () => {
        const { Person } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const { client } = this.props;
        const scoresResult = await client.getUserScores(Id);
        if (!scoresResult) return null;
        let ScoresGroup = groupBy(scoresResult, 'Label');
        let Scores = {};
        let Money = {};

        for (let i = 0; i < Object.keys(ScoresGroup).length; i++) {
            const ScoreObj = ScoresGroup[Object.keys(ScoresGroup)[i]][0];
            if (ScoreObj.Type === 'scores') {
                Scores[Object.keys(ScoresGroup)[i]] = ScoreObj;
            }
            if (ScoreObj.Type === 'money') {
                Money[Object.keys(ScoresGroup)[i]] = ScoreObj;
            }
        }
        return { Scores, Money }
    }

    reloadScores = () => {
        this.setState({ isScoresReady: false, isMoneyReady: false });
        this.loadScores().then(
            ({ Scores, Money }) => {
                this.setState({ isScoresReady: true, isMoneyReady: true, Scores, Money });
            }
        )
    }

    // ITEMS //////////////////////////////////////////
    loadItems = async () => {
        const { Person } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const { client } = this.props;
        const itemsResult = await client.getAllUserItems(Id);
        if (!itemsResult) return null;

        let items = {};

        // теперь мы в каждый дописываем абстрактный объект с сервера
        for (let i = 0; i < itemsResult.length; i++) {
            const abstrItem = client.getAbstractItem(itemsResult[i].Name);
            if (abstrItem) {
                const { type } = abstrItem;
                if (!items[type]) items[type] = [];

                const { Modyfied } = itemsResult[i];
                if (Modyfied) {

                    const ModyfiedObj = JSON.parse(Modyfied);

                    items[type].push({
                        ...abstrItem,
                        ...itemsResult[i],
                        ModyfiedObj,
                        name: itemsResult[i].Name
                    })
                } else
                    items[type].push({
                        ...abstrItem,
                        ...itemsResult[i],
                        name: itemsResult[i].Name
                    })


                if (type === 'stuff' || type === 'nagrada') {

                    const { Id, IsOn } = itemsResult[i];

                    items[type][items[type].length - 1].uid = Id;
                    if (IsOn && IsOn !== 'false') {
                        const IsOnObj = JSON.parse(IsOn);
                        if (IsOnObj && "x" in IsOnObj && "y" in IsOnObj) {
                            items[type][items[type].length - 1].x = IsOnObj.x;
                            items[type][items[type].length - 1].y = IsOnObj.y;
                            items[type][items[type].length - 1].isUsed = true;
                        }
                    }
                }

            }
        }

        return items;
    }

    reloadItems = () => {
        this.setState({ isItemsReady: false });
        this.loadItems().then(
            (Items) => {
                this.setState({ isItemsReady: true, Items });
            }
        )
    }

    // FKR ACCRED //////////////////////////////////////////
    loadFkrAccred = async () => {
        const { Person } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const { client } = this.props;

        //{ status: 'no', 'yes', 'application', Application, Item }

        const Item = await client.getUserFkrAccred(Id);
        const Application = await client.getApplications({ userId: Id, type: 'fkr_accred' });

        let status = 'no';
        if (Item && Item.length) {

            // проверяем, до какого числа действует последний предмет
            const lastItem = Item[Item.length - 1];
            const lastItemServed = moment(lastItem.ServedTo, 'YYYY.MM.DDTHH:mm');

            // проверяем, действует ли на проверочную дату
            // if (moment(client.checkFkrAccredDate, 'DD.MM.YYYY').isAfter(lastItemServed)) {
            if (moment().isAfter(lastItemServed)) {
                status = 'no';
            } else {
                status = 'yes';
            }
        }
        if (Application && Application.length && Application[Application.length - 1].Result !== 'accept') {
            if (status === 'no')
                status = 'application';
        }

        return {
            status,
            Item,
            Application
        };
    }

    reloadFkrAccred = () => {
        this.setState({ isFkrAccredReady: false });
        this.loadFkrAccred().then(
            (FkrAccred) => {
                this.setState({ isFkrAccredReady: true, FkrAccred });
            }
        )
    }

    sendFkrAdmission = async (values) => {
        const { client } = this.props;
        await client.addApplication({ type: 'fkr_accred', values });
        this.reloadFkrAccred();
    }

    // ELEMENTS ///////////////

    loadElements = async () => {
        const { Person } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const { client } = this.props;
        const InstructorRoles = await client.getUserElements(Id);
        return InstructorRoles;
    }

    reloadElements = () => {
        this.setState({ isElementsReady: false });
        this.loadElements().then(
            (Elements) => {
                this.setState({ isElementsReady: true, Elements });
            }
        )
    }


    // SEQUENCES  ///////////////
    loadSequences = async () => {
        const { Person } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const { client } = this.props;
        let Sequences = await client.getSoloUserSequences({ UserId: Id });
        let SequencesStatistics = null;
        if (Sequences) {
            Sequences = orderBy(Sequences, 'Points', 'desc');
            SequencesStatistics = SequenceProcessor({ Sequences, client });
        }
        return { Sequences, SequencesStatistics };
    }

    reloadSequences = () => {
        this.setState({ isSequencesReady: false });
        this.loadSequences().then(
            ({ Sequences, SequencesStatistics }) => {
                this.setState({ isSequencesReady: true, Sequences, SequencesStatistics });
            }
        )
    }

    // SERVER QUESTS (Это просто те квесты, которые приходят с сервера для person) /////
    loadServerQuests = async () => {
        const { client } = this.props;
        const ServerQuests = await client.getQuests({ PlaceType: 'person' });
        return ServerQuests;
    }

    reloadServerQuests = () => {
        this.setState({ isServerQuestsReady: false });
        this.loadServerQuests().then(
            (ServerQuests) => {
                this.setState({ isServerQuestsReady: true, ServerQuests });
            }
        )
    }

    // ACHIVMENTS
    loadServerAchivments = async () => {
        const { client } = this.props;
        const { Person } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const Achivments = await client.getAchivments({ UserId: Id });
        return Achivments;
    }

    reloadAchivments = () => {
        this.setState({ isAchivmentsReady: false });
        this.loadServerAchivments().then(
            (Achivments) => {
                this.setState({ isAchivmentsReady: true, Achivments });
            }
        )
    }

    reloadFightsStatistics = async () => {
        const { client } = this.props;
        const { Person } = this.state;
        if (!Person) return;
        const { Id } = Person;
        const FightsStatistics = await client.getStatistics('["compstat"]', Id);
        this.setState({ FightsStatistics });
    }

    redirect = (link) => {
        this.props.history.push(link);
    }

    getSearchObj = () => {
        const { location } = this.props;
        const { search } = location || { search: '' };

        // парсим search
        let searchObj = {}

        const searchAsObj = search.split('?');
        if (searchAsObj && searchAsObj[1]) {
            const search_ = searchAsObj[1].split('&');
            for (let i = 0; i < search_.length; i++) {
                const search__ = search_[i].split('=');
                searchObj[search__[0]] = search__[1];
            }
        }

        this.setState({ searchObj });

    }

    render() {
        const {
            props: {
                children,
                location,
                client,
                iamuser,
                showInfoModal,
                isMobile,
                updateMyData
            },
            state: {
                Person,
                isPersonReady,
                noSuchPerson,

                Raiting,
                isRaitingReady,

                RegionData,
                isRegionDataReady,

                KubokRaiting,
                isKubokRaitingReady,

                Medals,
                isMedalsReady,

                Videos,
                isVideosReady,

                PortalRoles,
                isPortalRolesReady,

                InstructorRoles,
                isInstructorRolesReady,

                Scores,
                isScoresReady,

                Money,
                isMoneyReady,

                Items,
                isItemsReady,

                Elements,
                isElementsReady,

                Sequences,
                SequencesStatistics,
                isSequencesReady,

                ServerQuests,
                isServerQuestsReady,

                Achivments,
                isAchivmentsReady,

                isBirthday,

                FkrAccred,
                isFkrAccredReady,

                FightsStatistics,

                iAmPerson,

                searchObj
            },
            redirect,
            sendFkrAdmission,
            setRegionData,
            reloadScores,
            reloadServerQuests,
            reloadElements,
            reloadItems
        } = this;

        return (
            <context.Provider value={{
                Person,
                isPersonReady,
                noSuchPerson,

                RegionData,
                isRegionDataReady,

                Raiting,
                isRaitingReady,

                KubokRaiting,
                isKubokRaitingReady,

                Medals,
                isMedalsReady,

                Videos,
                isVideosReady,

                PortalRoles,
                isPortalRolesReady,

                InstructorRoles,
                isInstructorRolesReady,

                Scores,
                isScoresReady,

                Money,
                isMoneyReady,

                Items,
                isItemsReady,
                reloadItems,

                Elements,
                isElementsReady,
                reloadElements,

                isBirthday,

                FkrAccred,
                isFkrAccredReady,

                Sequences,
                SequencesStatistics,
                isSequencesReady,

                ServerQuests,
                isServerQuestsReady,

                Achivments,
                isAchivmentsReady,

                iAmPerson,

                FightsStatistics,

                redirect,

                setRegionData,
                sendFkrAdmission,

                reloadScores,
                reloadServerQuests,

                client,
                iamuser,
                isMobile,
                showInfoModal,
                updateMyData,

                searchObj
            }}>
                {children}
            </context.Provider>
        );
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile, updateMyData, showInfoModal')(PersonState);


// ТУТ У НАС ИДЁТ ДЕКОРАТОР, КОТОРЫЙ ПОЗВОЛЯЕТ ПОЛУЧАТЬ ИЗ СТЕЙТА НУЖНЫЕ ДАННОМУ КОМПОНЕНТУ ЗНАЧЕНИЯ

export const withPersonState = (withProps = '') => {
    return (
        (Wrapped) =>
            (props) => (
                <context.Consumer>
                    {
                        (context) => (
                            <Wrapped
                                {...props}
                                {...getWhatINeed(withProps, context)}
                            />
                        )
                    }
                </context.Consumer>
            )
    );
};

const getWhatINeed = (withProps, context) => {

    if (withProps === '') return context;

    return withProps.split(',').reduce((memo, value_) => {
        const value = value_.trim();
        if (!context[value]) {
            return memo;
        }
        return { ...memo, [value]: context[value] }
    }, {})


}

