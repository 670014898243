// это стандартный тайтл, который везде используется
import React, { Fragment, PureComponent } from 'react';
/**
 *  Это хитрованская плашка, которая сама устанавливает высоту в зависимости от того, что в ней содержится
 *  Используется для какого-либо динамического контекста
 */

export class SelfHeightPlashka extends PureComponent {

    timer = null;
    element_ = null;

    // height - это ВНУТРЕННОСТЬ плашки
    // plashaHeight - это ВСЯ высотка, вместе с отступа, титлом и т.д., именно его мы устанавливаем
    timerFunc = () => {
        const { upperStyle: { height }, innerId, componentHeight, setComponentSelfHeight, plashkaId } = this.props;
        
        // это если мы жестко сказали - не показывать эту херню
        if ( height <= 1 ) return;

        const element_ = this.element_ || document.getElementById(innerId);
        if (!element_) return;
        if (!this.element_) this.element_ = element_;

        const otsups = height - componentHeight;
        const innerHeight = element_.clientHeight;
        if ( componentHeight !== innerHeight ) {
            setComponentSelfHeight({ height: innerHeight + otsups, plashkaId });
        }
    }

    componentDidMount () {
        this.timer = setInterval( this.timerFunc ,10 );
    }
    componentWillUnmount () {
        clearInterval( this.timer );
        this.timer = null;
        delete this.timer;
    }

    render() {
        const { children } = this.props;
        return <Fragment>
            {children}
        </Fragment>
    }
}