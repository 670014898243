
import { sortBy, toArray, filter, groupBy, clone } from 'lodash';

import axios from 'axios';

import { schools } from './schools';
// import { RAITING_AGES } from './raiting.js';
import { ELEMENTS, CROSS_ELEMENTS, STATISTICS_ELEMENTS } from './elements.js';
import { LANGUAGE } from './language.js';

import { PORTAL_CATEGORYS_TEXTES } from './categorys';

import {
  NT_registrationOpened,
  NTT_participatorData
} from './templates';

import SCORES from './scores';
import MONEY from './money';

import CRMClient from './crm';

import { ITEMS, COSMETIC_TYPES, COSMETIC_SUBTYPES } from './cosmetic';
import achivments from './https/achivments.js';
import articles from './https/articles.js';
import likes from './https/likes.js';

var moment = require('moment');

/*
 
events/id/

Размеры: logo.png 300х300
         big.png 1960х ( 800 min )
         middle.png 300х190
         small.png 300х100

*/

export default class Client {

  async initialize(ServerUrl, reloadPage, withInit = true) {

    this.ServerUrl = ServerUrl;
    this.reloadPage = reloadPage;

    this.state = {
      events: [],
      eventsAsObject: {},
      schools,
      groups: {},
      persons: {},
      exercises: {},
      combos: {},
      eventsToGo: [],
      articles: [],
      news: [],
      eventsForCalendar: [],
      raiting: {},
      translation: {},
      competitorsRaiting: {},
      onlineTrainings: [],
      onlineGroups: {},
      allOnlineInstructors: [],
      birthdays: [],
      subjectRaitings: {}
    }

    this.CategoryNames = {
      'm4-5': 'Мальчики 4-5 лет',
      'f4-5': 'Девочки 4-5 лет',
      'm6-7': 'Мальчики 6-7 лет',
      'f6-7': 'Девочки 6-7 лет',
      'm8-9': 'Мальчики 8-9 лет',
      'f8-9': 'Девочки 8-9 лет',
      'm10-12': 'Мальчики 10-12 лет',
      'f10-12': 'Девочки 10-12 лет',
      'm13-15': 'Юноши 13-15 лет',
      'f13-15': 'Девушки 13-15 лет',
      'm16-17': 'Юниоры 16-17 лет',
      'f16-17': 'Юниорки 16-17 лет',
      'm18': 'Мужчины 18+ лет',
      'f18': 'Женщины 18+ лет'
    }
    this.CategoryNewNames = {
      'm4-5': 'Мальчики 4-5 лет',
      'f4-5': 'Девочки 4-5 лет',
      'm6-7': 'Мальчики 6-7 лет',
      'f6-7': 'Девочки 6-7 лет',
      'm8-9': 'Мальчики 8-9 лет',
      'f8-9': 'Девочки 8-9 лет',
      'm10-11': 'Мальчики 10-11 лет',
      'f10-11': 'Девочки 10-11 лет',
      'm12-13': 'Мальчики 12-13 лет',
      'f12-13': 'Девочки 12-13 лет',
      'm14-15': 'Юноши 14-15 лет',
      'f14-15': 'Девушки 14-15 лет',
      'm16-17': 'Юниоры 16-17 лет',
      'f16-17': 'Юниорки 16-17 лет',
      'm18': 'Мужчины 18+ лет',
      'f18': 'Женщины 18+ лет'
    }

    this.CategoryNamesMobile = {
      'm4-5': 'М 4-5',
      'f4-5': 'Д 4-5',
      'm6-7': 'М 6-7',
      'f6-7': 'Д 6-7',
      'm8-9': 'М 8-9',
      'f8-9': 'Д 8-9',
      'm10-12': 'М 10-12',
      'f10-12': 'Д 10-12',
      'm13-15': 'М 13-15',
      'f13-15': 'Д 13-15',
      'm16-17': 'М 16-17',
      'f16-17': 'Д 16-17',
      'm18': 'М 18+',
      'f18': 'Д 18+'
    }
    this.CategoryNewNamesMobile = {
      'm4-5': 'М 4-5',
      'f4-5': 'Д 4-5',
      'm6-7': 'М 6-7',
      'f6-7': 'Д 6-7',
      'm8-9': 'М 8-9',
      'f8-9': 'Д 8-9',
      'm10-11': 'М 10-11',
      'f10-11': 'Д 10-11',
      'm12-13': 'М 12-13',
      'f12-13': 'Д 12-13',
      'm14-15': 'М 14-15',
      'f14-15': 'Д 14-15',
      'm16-17': 'М 16-17',
      'f16-17': 'Д 16-17',
      'm18': 'М 18+',
      'f18': 'Д 18+'
    }
    // использовать просто для названий
    this.CategoryNamesAllTitles = {
      ...this.CategoryNames,
      ...this.CategoryNewNames
    }
    this.CategoryNamesAllTitlesMobile = {
      ...this.CategoryNamesMobile,
      ...this.CategoryNewNamesMobile
    }

    // использовать для получения конкретного рейтинга от сезона
    this.CategoryNamesBySeason = ({ season, isMobile }) => {
      switch (season) {
        case '2024-2025': {
          if (isMobile) return this.CategoryNewNamesMobile;
          return this.CategoryNewNames;
        }
        default: {
          if (isMobile) return this.CategoryNamesMobile;
          return this.CategoryNames;
        }
      }
    }


    this.RaitingTypeNames = {
      'comp_pro': 'ПРО',
      'comp_not_pro': 'НОВИЧКИ'
    }

    this.raitingSeasons = [
      '2024-2025',
      '2023-2024',
      '2022-2023',
      '2021-2022',
      '2019-2021'
    ]

    this.sportRanks = {
      '3y': '3 юношеский',
      '2y': '2 юношеский',
      '1y': '1 юношеский',
      '3s': '3 взрослый',
      '2s': '2 взрослый',
      '1s': '1 взрослый',
      'kms': 'Кандидат в мастера спорта',
      'ms': 'Мастер спорта',
    }

    this.judgeRanks = {
      '3': '3 категория',
      '2': '2 категория',
      '1': '1 категория',
      'vseross': 'Всероссийская категория',
    }

    this.rankInfo = (rank = '') => {
      const rank_ = rank.toString();
      if (rank_ === '') return {}
      if (this.sportRanks[rank_]) {
        return { imgSrc: `/../images/ver2/cats/${rank_}.png`, text: this.sportRanks[rank_] }
      }
      if (this.judgeRanks[rank_]) {
        return { imgSrc: `/../images/ver2/cats/${rank_}.png`, text: this.judgeRanks[rank_] }
      }
      return {}
    }

    this.portalRoles = {
      'creator': 'Разработчик портала',
      'admin': 'Администратор портала',
      'sportmanager': 'Спортивный администратор',
    }

    this.eventsTypes = {
      'roda': { name: 'Рода', imgUrl: "/../images/ver2/event_types/type_roda.png" },
      'competition': { name: 'Соревнование', imgUrl: "/../images/ver2/event_types/type_competition.png" },
      'championship': { name: 'Соревнование', imgUrl: "/../images/ver2/event_types/type_competition.png" },
      'seminar': { name: 'Семинар', imgUrl: "/../images/ver2/event_types/type_seminar.png" },
      'judge': { name: 'Судейский', imgUrl: "/../images/ver2/event_types/type_judge.png" },
      'batizado': { name: 'Батизадо', imgUrl: "/../images/ver2/event_types/type_batizado.png" },
      'challenge': { name: 'Челлендж', imgUrl: "/../images/ver2/event_types/type_seminar.png" },
    }

    this.eventStatuses = {
      'undef': { title: 'не определено', text: 'Нет возможности определить статус' },
      'not_published': { title: 'не опубликовано', text: 'Мероприятие не опубликовано, его видит только администрация' },
      'is_preliminarily': { title: 'предварительно', text: 'Мероприятие опубликовано с предварительными датами, регистрация не доступна' },
      'registration_not_open': { title: 'ожидает регистрации', text: 'Мероприятие ожидает открытия регистрации' },
      'registration_open': { title: 'открыта регистрация', text: 'Регистрация официально открыта' },
      'registration_closed': { title: 'регистрация окончена', text: 'Регистрация официально закрыта, свяжитесь с администрацией, если хотите участвовать' },
      'past': { title: 'окончено', text: 'Мероприятие завершено' },
      'online': { title: 'идёт сейчас', text: 'Мероприятие идёт в данный момент' },
    }

    this.regions = {
      'russia': { key: 'russia', title: 'Россия' },
      'moscow': { key: 'moscow', title: 'Москва' },
      'mo': { key: 'mo', title: 'Московская область' },
      'spb': { key: 'spb', title: 'Санкт-Петербург' },
      'krym': { key: 'krym', title: 'Крым' },
      'sev': { key: 'sev', title: 'Севастополь' },
      'belgorod': { key: 'belgorod', title: 'Белгород' },
      'kaluga': { key: 'kaluga', title: 'Калужская область' },
      'karelia': { key: 'karelia', title: 'Республика Карелия' },
      'krasnoyarsk': { key: 'krasnoyarsk', title: 'Красноярский край' },
      'samara': { key: 'samara', title: 'Самарская область' },
      'kazan': { key: 'kazan', title: 'Казань' },
      'omsk': { key: 'omsk', title: 'Омская область' },
      'voron': { key: 'voron', title: 'Воронеж' },
      'tula': { key: 'tula', title: 'Тула' },
      'rostov': { key: 'rostov', title: 'Ростовская область' },
      'dnr': { key: 'dnr', title: 'ДНР' },
      'vladimir': { key: 'vladimir', title: 'Владимирская область' },
    }

    this.getRegionName = (key) => {
      if (!key || key === 'null') return 'Не указан';
      return (this.regions[key] || { title: 'ошибка сервера' }).title;
    }

    this.competitionTypes = {
      'cip': 'Чемпионат и Первенство',
      'kubok': 'Кубок',
      'megreg': 'Межрегиональные',
      'local': 'Местные',
      'raiting': 'Рейтинг',
      'solo': 'Соло'
    }

    /*
        "Федерация капоэйры Республики Крым"
    "Федерация капоэйра России" Калужской области
    "Федерация капоэйра Московской области"
    "Федерация капоэйра в городе Москве"
    "Федерация капоэйра России" в г. Санкт-Петербург
    "Федерация капоэйра города Севастополя"
    "Федерация капоэйра Красноярского края"
    "Федерация капоэйра Республики Карелия"
    "Федерация капоэйра Белгородской области" - не вносить, имхо, чтобы они зачесались
    "Федерация капоэйра Тверской области"  - по факту, можно вносить
    */

    function getPlaceName(Place) {
      if (!Place) return '';
      if (Place > 3) return `${Place} место`;
      switch (Place) {
        case 1: return 'Золото';
        case 2: return 'Серебро';
        case 3: return 'Бронза';

        default: return '';
      }
    }

    const facialSchool = Object.values(schools).find(x => x.IsFacial);
    this.facialSchoolId = facialSchool.Id;

    this.getPlaceName = getPlaceName;

    this.portalCategorys = PORTAL_CATEGORYS_TEXTES;

    this.nowRaiting = this.raitingSeasons[0];
    this.checkFkrAccredDate = '30.12.2023';

    // объявляем функции CRM
    this.CRMClient = new CRMClient({ standartDataResult: this.standartDataResult, getLastSessionId: this.getLastSessionId });

    this.getSubgroup = this.CRMClient.getSubgroup;
    this.getSubgroups = this.CRMClient.getSubgroups;
    this.updateSubgroup = this.CRMClient.updateSubgroup;
    this.addSubgroup = this.CRMClient.addSubgroup;
    this.deleteSubgroup = this.CRMClient.deleteSubgroup;
    this.groupVisitsStatistics = this.CRMClient.groupVisitsStatistics;
    this.groupVisitsEasyStatistics = this.CRMClient.groupVisitsEasyStatistics;

    this.getSubgroupUsers = this.CRMClient.getSubgroupUsers;
    this.addSubgroupUser = this.CRMClient.addSubgroupUser;
    this.deleteSubgroupUser = this.CRMClient.deleteSubgroupUser;

    this.getShadule = this.CRMClient.getShadule;
    this.updateShadule = this.CRMClient.updateShadule;
    this.addShadule = this.CRMClient.addShadule;
    this.deleteShadule = this.CRMClient.deleteShadule;

    this.getSubgroupVisits = this.CRMClient.getSubgroupVisits;
    this.addSubgroupVisit = this.CRMClient.addSubgroupVisit;
    this.deleteSubgroupVisit = this.CRMClient.deleteSubgroupVisit;

    this.getSubgroupsForeigns = this.CRMClient.getSubgroupsForeigns;
    this.getCRMPersonStat = this.CRMClient.getCRMPersonStat;

    this.getSubgroupComments = this.CRMClient.getSubgroupComments;
    this.addSubgroupComment = this.CRMClient.addSubgroupComment;
    this.updateSubgroupComment = this.CRMClient.updateSubgroupComment;
    this.deleteSubgroupComment = this.CRMClient.deleteSubgroupComment;

    this.addCRMRequest = this.CRMClient.addRequest;
    this.getCRMRequests = this.CRMClient.getRequests;
    this.updateCRMRequest = this.CRMClient.updateRequest;

    Object.keys(achivments).map(item => this[item] = achivments[item].bind(this));
    Object.keys(articles).map(item => this[item] = articles[item].bind(this));
    Object.keys(likes).map(item => this[item] = likes[item].bind(this));

    // this.getAchivments = achivments.getAchivments.bind(this);

    // TEMPLATES
    const todayDate = moment();

    this.NT_registrationOpened = (Event) => NT_registrationOpened(Event, this.eventsTypes);
    this.NTT_participatorData = NTT_participatorData;




    this.lastSessionId = ''; // мы запоминаем последнее ID сессии, для того чтобы подгружать данные

    this.language = 'rus';

    this.helloweenMode = false;

    // инициализурем элементы в виде объекта
    this.formServerElementsAsObjectWithKeys()

    if (withInit) {
      await this.loadFromServer();
    }


  }

  getLastSessionId = () => {
    return this.lastSessionId;
  }

  // ITEMS
  getAbstractItem = (name) => {
    const item = ITEMS[name] || ITEMS.default;

    const { grade, type, subtype, title } = item;

    const typeObj = COSMETIC_TYPES[type] || { src: () => '' };
    const subtypeObj = (subtype && subtype !== '') ? COSMETIC_SUBTYPES[subtype] : null;

    let result = { ...item };

    result.typeObj = typeObj;

    result.srcType = typeObj.src(grade);
    result.srcUsed = item.src.slice(0, item.src.length - 4) + '_used.png';

    result.subtypeObj = subtypeObj;
    if (subtypeObj) result.srcSubtype = subtypeObj.src;

    let title_ = '';
    if (title && title !== '') {
      title_ = title;
    } else {
      if (subtypeObj) {
        title_ = subtypeObj.subtypeTitle
      } else {
        title_ = typeObj.title || '';
      }
    }

    result.title = title_;
    result.name = name;


    return result;
  }

  getCosmeticType = (type) => {
    const typeObj = COSMETIC_TYPES[type] || { src: () => '' };
    const srcType = typeObj.src(1);
    return { typeObj, srcType };
  }

  getCosmeticSubtype = (subtype) => {
    const subtypeObj = (subtype && subtype !== '') ? COSMETIC_SUBTYPES[subtype] : {};
    return { subtypeObj };
  }

  loadFromServer_old = async () => {
    // сама инициализация собственно
    await this.getEventsFromServer();
    // await this.getEventsFromServerForCalendar(todayDate.format('YYYYMMDD'));
    //await this.getArticlesFromServer();
    await this.getGroupsFromServer();
    // await this.getNewsFromServer();
    // await this.getRaitingFromServer(todayDate);
    // await this.getTranslationFromServer();
    //await this.getOnlineFromServer();
    await this.getBirthdays();
    // if (birthdays && birthdays.length > 0) this.state.birthdays = birthdays;
    const championships = await this.getChampionshipsFromServer();
    this.state.championships = championships;
  }
  loadFromServer = async () => {
    // сама инициализация собственно
    await this.getEventsFromServer();
    // await this.getEventsFromServerForCalendar(todayDate.format('YYYYMMDD'));
    //await this.getArticlesFromServer();
    await this.getGroupsFromServer();
    // await this.getNewsFromServer();
    // await this.getRaitingFromServer(todayDate);
    // await this.getTranslationFromServer();
    //await this.getOnlineFromServer();
    await this.getBirthdays();
    await this.getAchivmentsFromServer();
    // if (birthdays && birthdays.length > 0) this.state.birthdays = birthdays;
    const championships = await this.getChampionshipsFromServer();
    this.state.championships = championships;
    this.reloadPage('all');
  }

  moneyTitleByType = type => {
    const MONEY_ = MONEY[type] || MONEY.default;
    const { title } = MONEY_;
    return title;
  }
  moneyTextByType = type => {
    const MONEY_ = MONEY[type] || MONEY.default;
    const { title, text } = MONEY_;
    return { title, text };
  }

  scoresTitleByType = type => {
    const SCORE = SCORES[type] || SCORES.default;
    const { title } = SCORE;
    return title;
  }

  scoresTextByType = type => {
    const SCORES_ = SCORES[type] || SCORES.default;
    const { title, text } = SCORES_;
    return { title, text };
  }

  scoresLvlByType = ({ type, score }) => {

    // console.log('scoresLvlByType', { type, score });

    const SCORE = SCORES[type] || SCORES.default;
    const { lvls, title } = SCORE;

    const text = SCORE.text || SCORES.default.text;

    if (!score) return { lvl: 0 };

    const { all } = score;

    if (!all) return {
      lvl: 1,
      now: 1,
      needed: 1,
      title,
      text
    };

    let lvl = 0;
    let allScores = all;
    let lastScore = 0;

    while (allScores > 0) {
      allScores -= lvls[lvl];
      lastScore = lvls[lvl];
      lvl++;
    }

    return {
      lvl,
      now: lastScore + allScores,
      needed: lastScore,
      title,
      text
    }
  }

  loggedUserId = () => {
    if (this.iamuser && this.iamuser.data && this.iamuser.data.Id) {
      return this.iamuser.data.Id
    }
    return null;
  }

  changeLanguage = (language) => {
    this.language = language;
    this.reloadPage('language');
  }

  getText = (caption) => {
    if (!LANGUAGE[caption]) return 'no text';
    if (LANGUAGE[caption][this.language]) return LANGUAGE[caption][this.language];
    if (LANGUAGE[caption].rus) return LANGUAGE[caption].rus;
    return 'no rus text';
  }

  /* Это те события, куда идёт наш зарегистрированные пользователь */

  async getEventsToGoForAuthPersonServer(sessionId = this.lastSessionId) {
    this.lastSessionId = sessionId;
    const uri = 'https://' + this.ServerUrl + '/myEventsToGo?sessionId=' + sessionId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    // console.log('events set');
    this.state.eventsToGo = result_;
    this.reloadPage();
  }

  async getOnlineFromServer() {

    const uri = 'https://' + this.ServerUrl + '/getOnlineTrainings';
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = {};
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data;
        } else {
          result_ = {};
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = {};
      return;
    }


    let {
      onlineTrainings,
      onlineGroups
    } = result_;


    let onlineGroups_ = {};

    for (let i = 0; i < onlineGroups.length; i++) {
      onlineGroups_[onlineGroups[i].onlineGroupId] = {
        ...onlineGroups[i]
      }
    }


    let instructors = {};
    let instructors_ = [];

    let onlineGoId = 1;

    for (let i = 0; i < onlineTrainings.length; i++) {

      if (!onlineTrainings[i].onlineTrainingId) {
        onlineTrainings[i].onlineTrainingId = onlineGoId++;
      }

      if (onlineTrainings[i].onlineGroupId && onlineGroups_[onlineTrainings[i].onlineGroupId]) {
        onlineTrainings[i] = { ...onlineTrainings[i], ...onlineGroups_[onlineTrainings[i].onlineGroupId] }
      }

      if (!instructors[onlineTrainings[i].userId]) {
        instructors[onlineTrainings[i].userId] = onlineTrainings[i].userId;
        instructors_.push(onlineTrainings[i].userId);
      }

    }


    this.state.allOnlineInstructors = instructors_;
    this.state.onlineGroups = onlineGroups_;
    this.state.onlineTrainings = onlineTrainings;

    this.reloadPage();
  }

  // ОЧЕНЬ ВАЖНО, что тут iamuser содержит data, в котором уже лежит всё по юзеру
  setNewiamuser = async (iamuser, sessionId = null) => {
    this.iamuser = iamuser;
    this.lastSessionId = sessionId;

    // что нам надо занести в iamuser
    /*
      myParticipations -- список events, где я - участник
      myEvents -- список events, где я - creator
      myStuff - список events, где я где-то в администрации (контакты, админ, спикер)
      myPortalRoles - мои роли на портале
      mySchoolRole - {SchoolId, role: responsible/main }
      myFollows - список мероприятий, которые я фолловлю
    */

    let myParticipation = {};
    let myParticipationData = {};
    let myCompetitor = {};
    let myCreationEvents = [];
    let myStuff = {};
    let myPortalRoles = [];
    let mySchoolRole = null;
    let myGroupRole = [];
    let myFollows = {};
    let myResourses = {};
    let myQuests = {};
    let myCRMgoes = null;

    if (iamuser && iamuser.data) {
      const myParticipation_ = await this.getParticipators({ UserId: this.iamuser.data.Id });
      myParticipation = myParticipation_ ? groupBy(myParticipation_, 'EventId') : {};

      const myParticipationData_ = await this.getParticipatorData({ UserId: this.iamuser.data.Id, Accept: 'success' });
      myParticipationData = myParticipationData_ ? groupBy(myParticipationData_, 'EventId') : {};

      const myCompetitor_ = await this.getUserCompetitions({ UserId: this.iamuser.data.Id });
      myCompetitor = myCompetitor_ ? groupBy(myCompetitor_, 'EventId') : {};

      myCreationEvents = await this.getMyCreationEvents() || [];

      const myStuff_ = await this.getUserStuffRoles(this.iamuser.data.Id);
      myStuff = myParticipation_ ? groupBy(myStuff_, 'SubjectId') : {};

      const myPortalRoles_ = await this.getUserPortalRoles(this.iamuser.data.Id);
      myPortalRoles = myPortalRoles_ ? groupBy(myPortalRoles_, 'Role') : {};

      const myGroupRole_ = await this.getUserInstructorRoles(this.iamuser.data.Id);
      myGroupRole = myGroupRole_ || {};

      const mySchoolRole_ = await this.getUserSchoolRoles(this.iamuser.data.Id);
      mySchoolRole = mySchoolRole_ ? mySchoolRole_[0] : null;

      const myFollows_ = await this.getFollows({ Subject: 'user', SubjectId: this.iamuser.data.Id });
      myFollows = myFollows_ ? groupBy(myFollows_, 'ToSubject') : {};

      const myCRMgoes_ = await this.getCRMPersonStat(this.iamuser.data.Id);
      myCRMgoes = (myCRMgoes_ && myCRMgoes_.subgroups) ? myCRMgoes_ : null;

      myResourses = await this.renewResourses();

      myQuests = await this.renewQuests();

      if (myFollows.event) {
        myFollows.event = groupBy(myFollows.event, 'ToSubjectId');
      }

    }

    // this.getEventsFromServer();

    return {
      myParticipation,
      myParticipationData,
      myCompetitor,
      myCreationEvents,
      myStuff,
      myGroupRole,
      myPortalRoles,
      mySchoolRole,
      myFollows,
      myResourses,
      myQuests,
      myCRMgoes
    }

  }

  renewResourses = async () => {
    const UserId = (this.iamuser && this.iamuser.data) ? this.iamuser.data.Id : null;

    if (!UserId) return { Scores: {}, Money: {} };

    const scoresResult = await this.getUserScores(UserId);
    if (!scoresResult) return null;
    let ScoresGroup = groupBy(scoresResult, 'Label');
    let Scores = {};
    let Money = {};

    for (let i = 0; i < Object.keys(ScoresGroup).length; i++) {
      const ScoreObj = ScoresGroup[Object.keys(ScoresGroup)[i]][0];
      if (ScoreObj.Type === 'scores') {
        Scores[Object.keys(ScoresGroup)[i]] = ScoreObj;
      }
      if (ScoreObj.Type === 'money') {
        Money[Object.keys(ScoresGroup)[i]] = ScoreObj;
      }
    }

    return { Scores, Money };
  }

  renewElements = async () => {
    const UserId = (this.iamuser && this.iamuser.data) ? this.iamuser.data.Id : null;

    if (!UserId) return null;

    const myElements = await this.getUserElements(UserId);
    // console.log('renewElements', myElements);
    return myElements;
  }

  renewQuests = async () => {
    const UserId = (this.iamuser && this.iamuser.data) ? this.iamuser.data.Id : null;

    if (!UserId) return null;

    const myQuests = await this.getUserQuests(UserId, 'going');
    // console.log('renewElements', myElements);
    return myQuests;
  }

  renewFollows = async () => {
    let myFollows = {};
    const myFollows_ = await this.getFollows({ Subject: 'user', SubjectId: this.iamuser.data.Id });
    myFollows = myFollows_ ? groupBy(myFollows_, 'ToSubject') : {};
    if (myFollows.event) {
      myFollows.event = groupBy(myFollows.event, 'ToSubjectId');
    }

    return myFollows
  }

  renewParticipation = async () => {
    let myParticipation = {};

    const myParticipation_ = await this.getParticipators({ UserId: this.iamuser.data.Id });
    myParticipation = myParticipation_ ? groupBy(myParticipation_, 'EventId') : {};

    return myParticipation;
  }

  renewCompetitor = async () => {
    let myCompetitor = {};

    const myCompetitor_ = await this.getUserCompetitions({ UserId: this.iamuser.data.Id });
    myCompetitor = myCompetitor_ ? groupBy(myCompetitor_, 'EventId') : {};

    return myCompetitor;
  }

  async goingToCompetitionServer(userId, competitionId, categoryId) {

    const sessionId = this.lastSessionId || '';

    if (!userId || !competitionId || !categoryId) return;
    const uri = 'https://' + this.ServerUrl + '/userGoToCompetition?userId=' + userId + '&competitionId=' + competitionId + '&categoryId=' + categoryId + '&sessionId=' + sessionId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    console.log(result_);
    //this.reloadPage();
  }




  async goingToServer(userId, eventId) {
    if (!userId || !eventId) return;
    const uri = 'https://' + this.ServerUrl + '/userGoTo?userId=' + userId + '&eventId=' + eventId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    console.log('goingToServer');

    console.log(result_);
    this.getEventsToGoForAuthPersonServer();
    //this.reloadPage();
  }


  async willNotGoToServer(userId, eventId) {
    if (!userId || !eventId) return;
    const uri = 'https://' + this.ServerUrl + '/userWillNotGoTo?userId=' + userId + '&eventId=' + eventId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    console.log('willNotGoToServer');

    console.log(result_);
    this.getEventsToGoForAuthPersonServer();
    //this.reloadPage();
  }

  async willNotGoToCompetitionServer(userId, competitionId) {

    const sessionId = this.lastSessionId || '';

    if (!userId || !competitionId) return;
    const uri = 'https://' + this.ServerUrl + '/userWillNotGoToCompetition?userId=' + userId + '&competitionId=' + competitionId + '&sessionId=' + sessionId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    console.log('willNotGoToCompetitionServer', result_);
    //this.reloadPage();
  }


  async setPaymantAndInsuranceOnServer(competition_id, userId, isPayed, isInsurance, sessionId = this.lastSessionId) {
    this.lastSessionId = sessionId;
    let uri = 'https://' + this.ServerUrl + '/setpaymantandinsurance?sessionId=' + sessionId;
    uri += '&competition_id=' + competition_id;
    uri += '&userId=' + userId;
    uri += '&isPayed=' + isPayed;
    uri += '&isInsurance=' + isInsurance;

    try {
      const res = await axios.get(uri);
      const result = res.data || null;
      return result;
    }
    catch (err) {
      console.log('server error');
      return null;
    }

  }

  async updateCompetitor(competition_id, userId, categoryId, jrebiy, sessionId = this.lastSessionId) {
    this.lastSessionId = sessionId;
    let uri = 'https://' + this.ServerUrl + '/updatecompetitor?sessionId=' + sessionId;
    uri += '&competition_id=' + competition_id;
    uri += '&userId=' + userId;
    if (categoryId) uri += '&CategoryId=' + categoryId;
    if (jrebiy) uri += '&Jrebiy=' + jrebiy;

    try {
      const res = await axios.get(uri);
      const result = res.data || null;
      return result;
    }
    catch (err) {
      console.log('server error');
      return null;
    }
  }

  async deleteCompetitor(competition_id, userId, categoryId, sessionId = this.lastSessionId) {
    this.lastSessionId = sessionId;
    let uri = 'https://' + this.ServerUrl + '/updatecompetitor?sessionId=' + sessionId;
    uri += '&competition_id=' + competition_id;
    uri += '&userId=' + userId;
    uri += '&CategoryId=' + categoryId;
    uri += '&Delete=true';

    try {
      const res = await axios.get(uri);
      const result = res.data || null;
      return result;
    }
    catch (err) {
      console.log('server error');
      return null;
    }
  }

  async updateCategory(competition_id, categoryId, elderCircle, timeStart = undefined, isCircled = undefined, sessionId = this.lastSessionId, FieldId = undefined, Name = undefined) {
    this.lastSessionId = sessionId;
    let uri = 'https://' + this.ServerUrl + '/updatecategory?sessionId=' + sessionId;
    uri += '&competition_id=' + competition_id;
    uri += '&CategoryId=' + categoryId;
    if (elderCircle) uri += '&ElderCircle=' + elderCircle;
    if (timeStart) uri += '&TimeStart=' + timeStart;
    if (FieldId) uri += '&FieldId=' + FieldId;
    if (Name) uri += '&Name=' + Name;
    if (isCircled !== undefined) uri += '&IsCircled=' + isCircled;

    try {
      const res = await axios.get(uri);
      const result = res.data || null;
      return result;
    }
    catch (err) {
      console.log('server error');
      return null;
    }
  }

  async updateCategoryIsPro(competition_id, categoryId, IsPro = 0, sessionId = this.lastSessionId) {
    this.lastSessionId = sessionId;
    let uri = 'https://' + this.ServerUrl + '/updatecategory?sessionId=' + sessionId;
    uri += '&competition_id=' + competition_id;
    uri += '&CategoryId=' + categoryId;
    uri += '&IsPro=' + IsPro;

    try {
      const res = await axios.get(uri);
      const result = res.data || null;
      return result;
    }
    catch (err) {
      console.log('server error');
      return null;
    }
  }

  async updateCategoryProgress(competition_id, categoryId, Progress, IsDone, sessionId = this.lastSessionId) {
    this.lastSessionId = sessionId;

    let Progress_ = Progress;
    if (!Progress || Progress === null || isNaN(Progress)) Progress = 0;

    let uri = 'https://' + this.ServerUrl + '/updatecategory?sessionId=' + sessionId;
    uri += '&competition_id=' + competition_id;
    uri += '&CategoryId=' + categoryId;
    uri += '&Progress=' + Progress;
    uri += '&IsDone=' + (IsDone ? 1 : 0);

    try {
      const res = await axios.get(uri);
      const result = res.data || null;
      return result;
    }
    catch (err) {
      console.log('server error');
      return null;
    }
  }

  async deleteCategory(competition_id, categoryId) {
    let uri = 'https://' + this.ServerUrl + '/updatecategory?sessionId=' + this.lastSessionId;
    uri += '&competition_id=' + competition_id;
    uri += '&CategoryId=' + categoryId;
    uri += '&Delete=true'
    try {
      const res = await axios.get(uri);
      const result = res.data || null;
      return result;
    }
    catch (err) {
      console.log('server error');
      return null;
    }
  }

  async copyCategory(category) {
    let uri = 'https://' + this.ServerUrl + '/addCategory?';

    uri += this.dataProcessToSearch(category);
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async addRaitingCategorys(CompetitionId) {
    let uri = 'https://' + this.ServerUrl + '/addRaitingCategorys?';

    uri += `sessionId=${this.lastSessionId}`;
    uri += '&CompetitionId=' + CompetitionId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }
  async addMainCategorys(CompetitionId) {
    let uri = 'https://' + this.ServerUrl + '/addMainCategorys?';

    uri += `sessionId=${this.lastSessionId}`;
    uri += '&CompetitionId=' + CompetitionId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }
  async addMainCategorysNew(CompetitionId) {
    let uri = 'https://' + this.ServerUrl + '/addMainCategorysNew?';

    uri += `sessionId=${this.lastSessionId}`;
    uri += '&CompetitionId=' + CompetitionId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getCategoryInfo(CategoryId, UserId, SchoolId, GroupIamInId, GroupId, CompetitionId = null) {
    let uri;
    if (CompetitionId)
      uri = 'https://' + this.ServerUrl + '/fieldCategorysInfoByCompetition?';
    else {
      uri = 'https://' + this.ServerUrl + '/fieldCategorysInfo?';
      uri += 'CategoryId=' + CategoryId;
    }

    uri += '&UserId=' + UserId;

    if (SchoolId) uri += '&SchoolId=' + SchoolId;
    if (GroupIamInId) uri += '&GroupIamInId=' + GroupIamInId;
    if (GroupId) uri += '&GroupId=' + GroupId;
    if (CompetitionId) uri += '&CompetitionId=' + CompetitionId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return null;

  }


  /**
   * ChampionshipId, Started, ShaduleReady, Finished, ShowResults, BriketsReady
   */
  async updateCompetitionData(data) {
    let uri = 'https://' + this.ServerUrl + '/updateCompetitionData?';
    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }





  async getTranslationFromServer() {
    const uri = 'https://' + this.ServerUrl + '/getTranslation';
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    if (result_ && result_.length > 0)
      this.state.translation = { ...result_[0] };
    this.reloadPage();
  }



  async getGroupsFromServer() {
    const uri = 'https://' + this.ServerUrl + '/getGroups';
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    // console.log('groups set');
    let groups = {};
    for (let i = 0; i < result_.length; i++) {
      const { Id } = result_[i];
      groups[Id] = {
        ...result_[i]
      }
    }
    this.state.groups = { ...groups };
    this.reloadPage('groups');
  }

  async getNewsFromServer() {
    const uri = 'https://' + this.ServerUrl + '/getNews';
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    console.log('news set');
    this.state.news = result_;
    this.reloadPage('news');
  }

  async getArticlesFromServer() {
    const uri = 'https://' + this.ServerUrl + '/getArticles';
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    console.log('articles set');
    this.state.articles = result_;
    this.reloadPage();
  }

  async reloadOneEvent(eventId) {
    let uri = 'https://' + this.ServerUrl + '/getEvents?';

    uri += `sessionId=${this.lastSessionId}`;
    uri += '&eventId=' + eventId;

    const answer = await this.standartDataResult(uri);

    console.log('answer', { answer });

    if (!answer.error) {
      return (answer.result && answer.result.result && answer.result.result[0]) || null;
    }
    return null;
  }

  async getEventsFromServer() {
    const uri = 'https://' + this.ServerUrl + '/getEventsOrderedByDate';
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    // console.log('events set');
    this.state.events = result_;
    const eventsAsObject = {};

    for (let i = 0; i < result_.length; i++) {
      const { Id } = result_[i];
      eventsAsObject[Id] = { ...result_[i] };
    }

    this.state.events = result_;
    this.state.eventsAsObject = eventsAsObject;
    this.reloadPage('events');
  }

  // todayDate.format('YYYYMMDD')
  async getEventsByRange(dateStart, dateEnd) {
    let uri = 'https://' + this.ServerUrl + '/getEventsOrderedByDate?dateStart=' + dateStart;
    uri += '&dateEnd=' + dateEnd;

    const answer = await this.standartDataResult(uri);
    // if (!answer.error) {
    //   return answer.result;
    // }
    // return answer.result;
    console.log('getEventsByRange');

    const { events } = this.state;
    if (!events || !answer.result || !answer.result.result) return [];

    let result = [];

    for (let i = 0; i < answer.result.result.length; i++) {
      const { Id } = answer.result.result[i];
      const event_ = events.find(x => x.Id === Id);
      if (event_) {
        result.push({ ...answer.result.result[i], ...event_ })
      }
    }

    return result;
  }


  // мы показываем наше отноешние к данному мероприятию
  // исходя из iamuser

  processEventsRelations = () => {

  }

  // больше не нужно вроде как
  // async getEventsFromServerForCalendar(dateStart) {
  //   const uri = 'https://' + this.ServerUrl + '/getEventsOrderedByDate?dateStart=' + dateStart;
  //   let result_;

  //   try {
  //     const res = await axios.get(uri);
  //     const { result } = res.data || {};
  //     if (!result) {
  //       result_ = [];
  //     } else {

  //       if (result.status === 'Sucess') {
  //         result_ = result.data.result;
  //       } else {
  //         result_ = [];
  //       }
  //     }


  //   }
  //   catch (err) {
  //     console.log('server error');
  //     result_ = [];
  //     return;
  //   }

  //   //return competitors;
  //   console.log('eventsForCalendar set');
  //   this.state.eventsForCalendar = result_;
  //   this.reloadPage('eventsForCalendar');
  // }

  async getChampionshipFromServer(championshipId, forced = false) {

    if (!forced)
      if (this.state.championships && this.state.championships[championshipId] && this.state.championships[championshipId][0]) {
        return this.state.championships[championshipId][0];
      }

    const uri = 'https://' + this.ServerUrl + '/getChampionship?championshipId=' + championshipId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = {};
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result[0];
        } else {
          result_ = {};
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = {};
      return;
    }

    if (forced) {
      if (!this.state.championships[championshipId]) this.state.championships[championshipId] = [{}];
      this.state.championships[championshipId][0] = { ...result_ };
    }

    //return competitors;
    return result_;
  }

  async getChampionshipsFromServer() {
    let uri = 'https://' + this.ServerUrl + '/getChampionships';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return groupBy(answer.result, 'Id');
    }
    return {};
  }

  async getCompetittionStatistics(competitionId) {
    let uri = 'https://' + this.ServerUrl + '/getCompetitionStatistic?competitionId=' + competitionId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    return result_;
  }

  async getCompetitorsCountInCategorys(championshipId) {
    const uri = 'https://' + this.ServerUrl + '/getCompetitorsCountInCategorys?championshipId=' + championshipId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = {};
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = {};
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = {};
      return;
    }

    //return competitors;
    return result_;
  }

  async getUserRaitingHistoryFromServer(userId) {
    const uri = 'https://' + this.ServerUrl + '/getUserRaitingHistory?userId=' + userId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || [];
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    return result_;
  }

  async getCompetitors(championshipId, categoryId = null, schoolId = null, groupId = null) {
    let uri = 'https://' + this.ServerUrl + '/getCompetitors?championshipId=' + championshipId;
    if (categoryId) uri += '&categoryId=' + categoryId;
    if (schoolId) uri += '&schoolId=' + schoolId;
    if (groupId) uri += '&groupId=' + groupId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    return result_;
  }


  async changeOnePersonDataToServer(userId, telefon, shirt) {

    let uri = 'https://' + this.ServerUrl + '/changeOneUserData?userId=' + userId + '&tel=' + telefon + '&shirt=' + shirt;

    try {
      await axios.get(uri);
    }
    catch (err) {
      console.log('server error');
      return 0;
    }

    //return competitors;
    return 1;

  }

  async changeOnePersonDataToServerByInstructor(userId, data, deleteFromGroup = false) {

    const uri = deleteFromGroup
      ?
      'https://' + this.ServerUrl + '/changeOneUserData?userId=' + userId + '&groupIamInId=0'
      :
      'https://' + this.ServerUrl + '/changeOneUserData?userId=' + userId +
      (data.nameValue ? '&name=' + data.nameValue : '') +
      (data.surnameValue ? '&surname=' + data.surnameValue : '') +
      (data.cordaoValue ? '&cordao=' + data.cordaoValue : '') +
      (data.apelidoValue ? '&apelido=' + data.apelidoValue : '') +
      (data.groupIamInId ? '&groupIamInId=' + data.groupIamInId : '') +
      '';

    try {
      await axios.get(uri);
    }
    catch (err) {
      console.log('server error');
      return 0;
    }

    return 1;
  }


  async sendPlaceToServer(competitionId, categoryId, userId, place) {

    let uri = 'https://' + this.ServerUrl + '/setCompetitorPlace?championshipId=' + competitionId + '&categoryId=' + categoryId + '&userId=' + userId + '&place=' + place;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    return result_;

  }


  async sendVideoUsersToServer(videoId, redUserId, blueUserId, categoryId, title) {

    let uri = 'https://' + this.ServerUrl + '/setvideousers?videoId=' + videoId + '&redUserId=' + redUserId + '&blueUserId=' + blueUserId + '&categoryId=' + categoryId + '&title=' + title;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    return result_;

  }


  async getCategorysFromServer(championshipId) {
    const uri = 'https://' + this.ServerUrl + '/getCategorys?championshipId=' + championshipId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = {};
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = {};
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = {};
      return;
    }

    //return competitors;
    return result_;
  }

  async getStudentsFromServer(groupId) {
    let uri = 'https://' + this.ServerUrl + '/getStudents?groupId=' + groupId + '&allStudentsMode=true';
    uri += `&nowSeason=${this.nowRaiting}`;
    uri += `&lastSeason=${this.raitingSeasons[1]}`;

    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = {};
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.persons;
        } else {
          result_ = {};
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = {};
      return;
    }

    //return competitors;
    return result_;
  }

  async getUserFromServer(userId, { WithTel } = {}) {

    if (!userId) return;

    if (!WithTel && this.state.persons[userId]) {
      return this.state.persons[userId];
    }

    let uri = 'https://' + this.ServerUrl + '/getUser?userId=' + userId;
    if (WithTel) { uri += '&WithTel=true' }
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = null;
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data;
          this.state.persons[userId] = { ...result_ };
        } else {
          result_ = null;
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = null;
      return;
    }

    //return competitors;
    return result_;
  }

  async getUserToRecoveryPasswordFromServer(login) {

    if (!login) return { status: 'error' };

    const uri = 'https://' + this.ServerUrl + '/getUserToRecoveryPassword?login=' + login;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = {
          status: 'error'
        };
      } else {

        if (result.status === 'sucess') {
          const { Id, Name, Surname } = result.data;
          result_ = {
            status: 'sucess',
            userId: Id,
            userName: Surname + ' ' + Name,
          }
        } else {
          result_ = {
            status: 'error'
          };
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = null;
      return;
    }

    //return competitors;
    return result_;
  }

  async getUserToRecoveryPasswordFromServerBySurname(Surname) {

    if (!Surname) return { status: 'error' };

    const uri = 'https://' + this.ServerUrl + '/getUserToRecoveryPasswordBySurname?Surname=' + Surname;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = {
          status: 'error'
        };
      } else {

        if (result.status === 'sucess') {
          result_ = {
            status: 'sucess',
            ids: result.data
          }
        } else {
          result_ = {
            status: 'error'
          };
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = null;
      return;
    }

    //return competitors;
    return result_;
  }

  async getWhoGoiesFromServer(eventId) {
    const uri = 'https://' + this.ServerUrl + '/whoGoiesTo?eventId=' + eventId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    return (result_);
  }

  async getVideoByIdFromServer(videoId) {
    let uri = 'https://' + this.ServerUrl + '/getVideoById?VideoId=' + videoId;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result[0];
    }
    return {};
  }

  async getVideoFromServer(videoId) {
    let uri = 'https://' + this.ServerUrl + '/getvideo?videoId=' + videoId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        return ({ Title: '', Url: undefined });
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          return ({ Title: '', Url: undefined });
        }
      }


    }
    catch (err) {
      console.log('video error');
      return ({ Title: '', Url: undefined });
    }

    //return competitors;
    return (result_[0] ? result_[0] : { Title: '', Url: undefined });
  }

  async getUserVideosFromServer(userId) {
    let uri = 'https://' + this.ServerUrl + '/getuservideos?userId=' + userId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    return result_;
  }


  async getSchoolGradationCountFromServer(schoolId, groupId = null) {
    let uri = 'https://' + this.ServerUrl + '/getSchoolGradationCount?schoolId=' + schoolId;
    if (groupId) uri += '&groupId=' + groupId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    return (result_);
  }


  async getCordaoIdsFromServer(schoolId, cordao, groupId = null) {
    let uri = 'https://' + this.ServerUrl + '/getCordaoIds?schoolId=' + schoolId + '&cordao="' + cordao + '"';
    if (groupId) uri += '&groupId=' + groupId;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      if (!result) {
        result_ = [];
      } else {

        if (result.status === 'Sucess') {
          result_ = result.data.result;
        } else {
          result_ = [];
        }
      }


    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    //return competitors;
    return (result_);
  }

  async recoveryPassword(userId) {
    const uri = 'https://' + this.ServerUrl + '/sendPassword?userId=' + userId;

    try {
      axios.get(uri)
    }
    catch (err) {
      console.log('server error');
      return;
    }

    return;
  }

  getEventsUrl() {
    return 'https://' + this.ServerUrl + '/graphics/banner_events/';
  }

  getSchoolsUrl() {
    return 'https://' + this.ServerUrl + '/graphics/logo_schools/';
  }

  getSchoolUrl(schoolId) {
    return 'https://' + this.ServerUrl + '/graphics/logo_schools/' + schoolId + '/logo.png';
  }

  getUsersUrl() {
    return 'https://' + this.ServerUrl + '/graphics/logo_users/';
  }

  geGroupsUrl() {
    return 'https://' + this.ServerUrl + '/graphics/logo_groups/';
  }

  getShopUrl() {
    return 'https://' + this.ServerUrl + '/graphics/logo_shop/';
  }

  getSchoolGradation(schoolId) {

    if (this.state.schools[schoolId]) {
      return this.state.schools[schoolId].Gradation;
    }

    return null;
  }

  getCompetitorsRaiting(userId) {
    return this.state.competitorsRaiting[userId] || null;
  }

  getPersonById(personId) {

    if (this.state.persons[personId]) {
      return this.state.persons[personId]
    }

    return null;
  }

  getSchoolById(schoolId) {

    if (this.state.schools[schoolId]) {
      return this.state.schools[schoolId]
    }

    return null;
  }

  getEventById(eventId) {

    if (!this.state.events) return null;

    if (this.state.eventsAsObject && this.state.eventsAsObject[eventId]) {
      return this.state.eventsAsObject[eventId];
    }

    const event = this.state.events.find(ev => ev.Id.toString() === eventId.toString())

    if (event) {
      return event;
    }

    return null;
  }

  getGroupById(groupId) {

    if (this.state.groups[groupId]) {
      return this.state.groups[groupId]
    }

    return null;
  }

  getAllGroups(schoolId = null) {
    if (!schoolId)
      return this.state.groups;

    let answer = {};
    Object.keys(this.state.groups).reduce((memo, key) => {
      if (this.state.groups[key].SchoolId.toString() === schoolId.toString()) {
        answer[this.state.groups[key].Id] = { ...this.state.groups[key] }
      }
      return [];
    }, []);

    return answer;

  }

  // EXERCISES
  getExerciseById(exerciseId) {
    if (!this.state.exercises) return null;
    return this.state.exercises[exerciseId] || null;
  }
  setExercise(exercise) {
    if (!exercise || !exercise.Id) return;
    this.state.exercises[exercise.Id] = { ...exercise };
  }

  // COMBOS
  getComboById(comboId) {
    if (!this.state.combos) return null;
    return this.state.combos[comboId] || null;
  }
  setCombo(combo) {
    if (!combo || !combo.Id) return;
    this.state.combos[combo.Id] = { ...combo };
  }


  getMainLogoUrl() {
    return {
      big: 'https://' + this.ServerUrl + '/graphics/logo_big.png',
      small: 'https://' + this.ServerUrl + '/graphics/logo_micro.png',
    }
  }

  getGroupUrl(groupId) {
    return 'https://' + this.ServerUrl + '/graphics/logo_groups/' + groupId + '.png';
  }

  getTechnicLogoUrl(technicKey) {

    const arr = toArray(technicKey);

    if (arr.length < 2)
      return 'https://' + this.ServerUrl + '/graphics/logo_technics/' + technicKey + '.png';

    if (arr[0] === '_') {
      if (arr[1] === 's')
        return 'https://' + this.ServerUrl + '/graphics/logo_technics/strike.png';
      if (arr[1] === 'd')
        return 'https://' + this.ServerUrl + '/graphics/logo_technics/defence.png';
      if (arr[1] === 'm')
        return 'https://' + this.ServerUrl + '/graphics/logo_technics/movement.png';
    }

    return 'https://' + this.ServerUrl + '/graphics/logo_technics/' + technicKey + '.png';
  }

  getTechnicNeededLogo(needed, rez = 'png') {

    return 'https://' + this.ServerUrl + '/graphics/logo_technics/' + needed + '.' + rez;
  }

  getTechnicLogoAddUrl() {

    return 'https://' + this.ServerUrl + '/graphics/logo_technics/add.png';
  }

  getVideoById(videoId) {

    if (this.state.videos[videoId]) {
      return this.state.videos[videoId]
    }

    return null;
  }

  getArticleById(articleId) {

    const article = this.state.articles.find(x => x.Id === articleId);
    if (article) return article;

    return null;
  }

  getNewsById(newsId) {

    const onenews = this.state.news.find(x => x.Id === newsId);
    if (onenews) return onenews;

    return null;
  }

  getAllNews() {
    return this.state.news;
  }

  getAllVideos() {
    return this.state.videos;
  }

  getAllSchools() {
    return this.state.schools;
  }

  getAllPersons() {
    return this.state.persons;
  }

  getAllEvents() {
    return this.state.events;
  }

  // getEventsForSlider() {
  //   return this.state.eventsForCalendar;
  // }



  getOneOnlineTraining(onlineTrainingId) {
    const Res = this.state.onlineTrainings.find(x => { return x.onlineTrainingId.toString() === onlineTrainingId });

    return Res || undefined;

  }

  getOnlineTrainings() {
    return {
      onlineGroups: this.state.onlineGroups,
      onlineTrainings: this.state.onlineTrainings
    }
  }

  getOnlineMKs() {

    const result = filter(this.state.onlineTrainings, (item) => { return (item.onlineGroupId === null) });

    return result;

  }

  getOnlineTrainingsToShadule(userId = null) {

    const result = filter(this.state.onlineTrainings, (item) => { return (item.onlineGroupId !== null) });

    let users = {};
    let users_ = [];

    let tel = '';

    let shadule = [
      [], [], [], [], [], [], [], []
    ];

    for (let i = 0; i < result.length; i++)
      if (result[i].dayOfWeek) {

        if (!users[result[i].userId]) {
          users[result[i].userId] = { userId: result[i].userId }
          users_.push(result[i].userId);
        }

        if (!userId || userId === result[i].userId) {

          if (!tel && result[i].tel) {
            tel = result[i].tel;
          }

          shadule[result[i].dayOfWeek].push({ ...result[i] });

        }
      }


    return { shadule, users: users_, tel };

  }

  getFirstThreeRaiting(type) {
    switch (type) {

      case 'absolute_male': {
        return this.getRaiting('RA_10_12', 1, 1);
      }

      case 'absolute_female': {
        return this.getRaiting('RA_10_12', 1, 0);
      }

      case 'absolute_teams': {
        return this.getRaiting('RA_13_15', 1, 1);
      }

      default: {
        return ['', '', ''];
      }

    }

  }

  getRaiting(key, isPro, Gender) {

    /*
        RA_4_5: sortBy(RA_4_5, x => x.ScoresPro).reverse(),
        RA_6_7: sortBy(RA_6_7, x => x.ScoresPro).reverse(),
        RA_8_9: sortBy(RA_8_9, x => x.ScoresPro).reverse(),
        RA_10_12: sortBy(RA_10_12, x => x.ScoresPro).reverse(),
        RA_13_15: sortBy(RA_13_15, x => x.ScoresPro).reverse(),
        RA_16_17: sortBy(RA_16_17, x => x.ScoresPro).reverse(),
        RA_18: sortBy(RA_18, x => x.ScoresPro).reverse(),
    */

    const { raiting } = this.state;
    if (!raiting[key] || raiting[key].length === 0) return [];

    if (key === 'RA_4_5') {
      return sortBy(raiting[key], x => x.ScoresNotPro).reverse();
    }

    if (key === 'RA_6_7') {

      if (Gender === null) return [];

      const competitors = raiting[key].filter((value) => { return value.Gender === Gender });

      return sortBy(competitors, x => x.ScoresNotPro).reverse();
    }

    if (Gender === null) return [];
    if (isPro === null) return [];

    const competitors_ = raiting[key].filter((value) => { return value.Gender === Gender });
    if (isPro) {
      const competitors = competitors_.filter((value) => { return value.ScoresPro > 0 });
      return sortBy(competitors, x => x.ScoresPro).reverse();
    }

    const competitors = competitors_.filter((value) => { return value.ScoresNotPro > 0 });
    return sortBy(competitors, x => x.ScoresNotPro).reverse();
  }


  /* Вернуть последние события на неделе */
  getWeekEvents() {

    const { eventsForCalendar } = this.state;

    let answer = [];

    for (let i = 0; i < eventsForCalendar.length && i < 3; i++) {
      answer.push({
        type: eventsForCalendar[i].Type,
        title: eventsForCalendar[i].Title,
        city: eventsForCalendar[i].City,
        date: eventsForCalendar[i].Date,
      })
    }

    return answer;
  }

  async getArticle(articleId) {

    const uri = 'https://' + this.ServerUrl + '/getArticle?articleId=' + articleId;
    let result_;

    try {
      const res = await axios.get(uri);
      const result = res.data;
      if (!result) {
        result_ = [];
      } else {

        result_ = result;
      }
    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    const element = document.getElementById('article-div-body');

    if (element)
      element.innerHTML = result_;

    const header = document.getElementById('article-header');

    const article = this.getArticleById(articleId);

    if (header)
      header.innerHTML = article ? article.Title : '';

    const data = document.getElementById('article-data');
    if (data)
      data.innerHTML = article ? article.Data : '';

    //return competitors;
    //this.state.eventsToGo = result_; 
    //this.reloadPage();
  }


  async getEventHTML(eventId) {

    const uri = 'https://' + this.ServerUrl + '/getEvent?eventId=' + eventId;
    let result_;

    try {
      const res = await axios.get(uri);
      const result = res.data;
      if (!result) {
        result_ = [];
      } else {

        result_ = result;
      }
    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    const element = document.getElementById('event-html-div-body');

    if (element)
      element.innerHTML = result_;

    console.log('event set');
  }

  async getEventHTMLText(eventId) {

    const uri = 'https://' + this.ServerUrl + '/getEvent?eventId=' + eventId;
    let result_;

    try {
      const res = await axios.get(uri);
      const result = res.data;
      if (!result) {
        result_ = [];
      } else {

        result_ = result;
      }
    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    return result_ || null;
  }

  async setEventHTML(eventId, text) {

    const uri = 'https://' + this.ServerUrl + '/setEventText?event_id=' + eventId + '&infotext=' + text;

    try {
      const res = await axios.get(uri);
      const result = res.data;
      return result;
    }
    catch (err) {
      console.log('server error');
      return;
    }

  }

  async getEventSpecial(eventId, special, divId) {

    const uri = 'https://' + this.ServerUrl + '/getEvent?eventId=' + eventId + '&special=' + special;
    let result_;

    try {
      const res = await axios.get(uri);
      const result = res.data;
      if (!result) {
        result_ = [];
      } else {

        result_ = result;
      }
    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    const element = document.getElementById(divId);

    if (element)
      element.innerHTML = result_;

    console.log('event set');
  }


  async getOneNews(newsId) {

    const uri = 'https://' + this.ServerUrl + '/getNew?newsId=' + newsId;
    let result_;

    try {
      const res = await axios.get(uri);
      const result = res.data;
      if (!result) {
        result_ = [];
      } else {

        result_ = result;
      }
    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    const element = document.getElementById('onenews-div-body');

    if (element)
      element.innerHTML = result_;

    const header = document.getElementById('onenews-header');

    const onenews = this.getNewsById(newsId);

    if (header)
      header.innerHTML = onenews ? onenews.Title : '';

    const data = document.getElementById('onenews-data');
    if (data)
      data.innerHTML = onenews ? onenews.Discription + ' / ' + onenews.Data : '';

    //return competitors;
    //this.state.eventsToGo = result_; 
    //this.reloadPage();
  }


  getOneNewsLogoSrc(newsId) {
    return 'https://' + this.ServerUrl + '/graphics/news/' + newsId + '.png'
  }


  /*  <h1 id = 'article-header'></h1>
    <div id = 'article-data' className = 'article-data'>
    </div>
  */

  /////////////////////////////////////////////////// ELEMENTS  ЕЛЕМЕНТЫ И ВСЯКОЕ СОЛО */

  // получить все раунда (или у конкретного соревнования)

  /*
  
   ТУТ ОЧЕНЬ ЕБАНУТАЯ ТЕМА ! ЕСЛИ КИНУТЬ DateOnly - он возвращает ПРАВИЛЬНУЮ ДАТУ !
  
  */

  async getServerTime(DateOnly = false) {

    if (DateOnly) {
      try {
        const res = await axios.get('https://' + this.ServerUrl + '/serverTime?DateOnly=true');
        return res.data;
      }
      catch (err) {
        return '2020.10.10';
      }
    }

    try {
      const res = await axios.get('https://' + this.ServerUrl + '/serverTime');
      return res.data;
    }
    catch (err) {
      return null;
    }

  }

  async getSoloRounds(soloCompetitionId = null, RoundId = null, justActual = null) {


    let date = '';
    if (justActual) date = await this.getServerTime(true);




    let uri = 'https://' + this.ServerUrl + '/getSoloRounds';
    if (soloCompetitionId) uri += '?SoloCompetitionId=' + soloCompetitionId;
    if (RoundId) uri += '?RoundId=' + RoundId;
    if (justActual) uri += '?DateEnd=' + date;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async getSoloCompetitions(Id = null) {
    let uri = 'https://' + this.ServerUrl + '/getSoloCompetitions';

    if (Id) uri += `?Id=${Id}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }


  async getSoloCompetitionHTML(Id) {

    const uri = 'https://' + this.ServerUrl + '/getSoloCompetition?SoloCompetitionId=' + Id;
    let result_;

    try {
      const res = await axios.get(uri);
      const result = res.data;
      if (!result) {
        result_ = [];
      } else {

        result_ = result;
      }
    }
    catch (err) {
      console.log('server error');
      result_ = [];
      return;
    }

    const element = document.getElementById('solo-competition-html-div-body');

    if (element)
      element.innerHTML = result_;

    console.log('solo competition set');
  }

  async getSoloCompetitionPoints({ SoloCompetitionId, Tier }) {
    let uri = 'https://' + this.ServerUrl + '/getSoloCompetitionPoints?';
    uri += 'SoloCompetitionId=' + SoloCompetitionId;
    uri += '&Tier=' + Tier;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }


  async getSoloUserSequences({ UserId, RoundId, Code }) {
    let uri = 'https://' + this.ServerUrl + '/getSoloUserSequences?';
    if (UserId) uri += 'UserId=' + UserId;
    if (RoundId) uri += (UserId ? '&' : '') + 'RoundId=' + RoundId;
    if (Code) uri += 'Code=' + Code;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }
  async getSoloUserSequencesAllConfirmed() {
    let uri = 'https://' + this.ServerUrl + '/getSoloUserSequences?AllConfirmed=true';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async getSoloUserSequencesLastGoodNumber(LastGoodNumber) {
    let uri = 'https://' + this.ServerUrl + `/getSoloUserSequences?LastGoodNumber=${LastGoodNumber}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getSoloUserSequencesLastConfirmed(LastConfirmed) {
    let uri = 'https://' + this.ServerUrl + `/getSoloUserSequences?LastConfirmed=${LastConfirmed}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getSoloSequencesBySettings({ Elements, RStars, SStars, Points }) {
    let uri = 'https://' + this.ServerUrl + `/getSoloSequencesBySettings?sessionId=${this.lastSessionId}`;
    if (Elements) {
      const el = JSON.stringify(Elements);
      uri += '&Elements=' + el;
    }

    if (RStars) uri += '&RStars=' + RStars;
    if (SStars) uri += '&SStars=' + SStars;
    if (Points) uri += '&Points=' + Points;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getSoloPageStatistics(UserId = null) {
    let uri = 'https://' + this.ServerUrl + `/getSoloPageStatistics`;
    if (UserId) uri += `?UserId=${UserId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getSoloPageAchivmentsTop(UserId = null) {
    let uri = 'https://' + this.ServerUrl + `/getSoloPageAchivmentsTop`;
    if (UserId) uri += `?UserId=${UserId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getSoloCompetitionSequences({ SoloCompetitionId, Status }) {

    let uri = 'https://' + this.ServerUrl + '/getSoloCompetitionSequences?';
    uri += 'SoloCompetitionId=' + SoloCompetitionId;
    uri += '&Status=' + Status;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async getSoloUserCompetitionSequences({ SoloCompetitionId, UserId }) {

    let uri = 'https://' + this.ServerUrl + '/getSoloUserCompetitionSequences?';
    uri += 'SoloCompetitionId=' + SoloCompetitionId;
    uri += '&UserId=' + UserId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async getSoloGroupSequences({ GroupId, Status }) {

    let uri = 'https://' + this.ServerUrl + '/getSoloGroupSequences?';
    uri += 'GroupId=' + GroupId;
    uri += '&Status=' + Status;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async getSoloItems(UserId, SoloCompetitionId = null) {
    let uri = 'https://' + this.ServerUrl + '/getSoloItems?';
    if (UserId) uri += 'UserId=' + UserId;
    if (SoloCompetitionId) uri += 'SoloCompetitionId=' + SoloCompetitionId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }



  async getSoloCompetitors({ UserId, SoloCompetitionId, Tier }) {
    let uri = 'https://' + this.ServerUrl + '/getSoloCompetitors?';
    if (UserId) uri += 'UserId=' + UserId + '&';
    if (SoloCompetitionId) uri += 'SoloCompetitionId=' + SoloCompetitionId + (Tier ? '&' : '');
    if (Tier) uri += 'Tier=' + Tier;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async setSoloCompetitor({ UserId, SoloCompetitionId, Tier }) {
    let uri = 'https://' + this.ServerUrl + '/setSoloCompetitor?';
    if (UserId) uri += 'UserId=' + UserId + '&';
    if (SoloCompetitionId) uri += 'SoloCompetitionId=' + SoloCompetitionId + '&';
    if (Tier) uri += 'Tier=' + Tier;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  // мы забираем или объект this.soloUserOptions или берём с сервера его
  async getMySoloUserOptionsProxy(reTake = false) {

    if (this.soloUserOptions && !reTake) return this.soloUserOptions;

    if (!this.iamuser || !this.iamuser.data || !this.iamuser.data.Id) {
      return null
    }

    let uri = 'https://' + this.ServerUrl + '/getSoloUserOptions?';
    uri += 'UserId=' + this.iamuser.data.Id;

    let answer = await this.standartDataResult(uri);
    if (!answer.error && answer.result && answer.result[0]) {
      this.soloUserOptions = answer.result[0];
      this.serverToSoloUserOptions(answer.result[0]);
      return this.soloUserOptions;
    }

    // если мы сюда пришли, значит не создан еще для данного юзера, надо его создать
    let uri_ = 'https://' + this.ServerUrl + '/createSoloUserOptions?UserId=' + this.iamuser.data.Id;
    await this.standartDataResult(uri_);

    // и снова пробуем взять
    answer = await this.standartDataResult(uri);
    if (!answer.error && answer.result && answer.result[0]) {
      this.soloUserOptions = answer.result[0];
      this.serverToSoloUserOptions(answer.result[0]);
      return this.soloUserOptions;
    }

    // а вот что тут делать я пока хз :(
    return answer.result;

  }

  // это из строки типа armada/bensao сделать объект
  serverToSoloUserOptions = (Options) => {
    const { MyCover, MyTranslate, MyElements } = Options;

    let myElements = {};
    if (MyElements) {
      MyElements.split('/').map(element_key => {
        myElements[element_key] = true;
        return true;
      })
    }

    let myCover = 'usual';
    if (MyCover) myCover = MyCover;

    let myTranslate = 0;
    if (MyTranslate) myTranslate = MyTranslate;

    this.soloUserOptions = {
      ...this.soloUserOptions,
      myElements,
      myCover,
      myTranslate
    }

  }

  // установить какое-либо свойство (или все вместе)
  // после этого нужно будет снова запросить их

  async setMySoloUserOptions({ MyCover, MyTranslate, MyElements, ShowComments, ShowSertificats }) {

    if (!this.iamuser || !this.iamuser.data || !this.iamuser.data.Id) {
      return null
    }

    let uri = 'https://' + this.ServerUrl + '/setSoloUserOptions?';
    uri += 'UserId=' + this.iamuser.data.Id;

    if (MyCover) uri += '&MyCover=' + MyCover;
    if (MyElements) uri += '&MyElements=' + MyElements;
    if (MyTranslate) uri += '&MyTranslate=' + MyTranslate;
    if (ShowComments !== undefined) uri += '&ShowComments=' + ShowComments;
    if (ShowSertificats !== undefined) uri += '&ShowSertificats=' + ShowSertificats;

    // обязательно отсылать это на сервер!
    if (!MyTranslate) uri += '&MyTranslate=' + this.soloUserOptions.MyTranslate;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async setMySoloUserOptionsLvl({ MyLvl }) {

    if (!this.iamuser || !this.iamuser.data || !this.iamuser.data.Id) {
      return null
    }

    let uri = 'https://' + this.ServerUrl + '/setSoloUserOptionsLvl?';
    uri += 'UserId=' + this.iamuser.data.Id;

    if (MyLvl === undefined) return;

    uri += '&MyLvl=' + MyLvl;
    uri += '&JudgeId=' + 2;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async setMySoloUserOptionsLvlTeq({ MyStrikeLvl = 0, MyMovementLvl = 0, MyDefenceLvl = 0, MyElementsLvl = 0 }) {

    if (!this.iamuser || !this.iamuser.data || !this.iamuser.data.Id) {
      return null
    }

    let uri = 'https://' + this.ServerUrl + '/setSoloUserOptionsLvlTeq?';
    uri += 'UserId=' + this.iamuser.data.Id;

    uri += '&MyStrikeLvl=' + MyStrikeLvl;
    uri += '&MyMovementLvl=' + MyMovementLvl;
    uri += '&MyDefenceLvl=' + MyDefenceLvl;
    uri += '&MyElementsLvl=' + MyElementsLvl;
    uri += '&JudgeId=' + 2;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  // это взять чьи-то (чтобы например вытащит обложку оттуда)
  async getSomeSoloUserOptions(UserId) {

    let uri = 'https://' + this.ServerUrl + '/getSoloUserOptions?';
    if (UserId) uri += 'UserId=' + UserId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error && answer.result && answer.result[0]) {
      return answer.result[0];
    }
    return {};
  }

  async createMySoloUserOptions() {

    if (!this.iamuser || !this.iamuser.data || !this.iamuser.data.Id) {
      return null
    }

    let uri = 'https://' + this.ServerUrl + '/createSoloUserOptions?';
    uri += 'UserId=' + this.iamuser.data.Id;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async setSoloUserSequences({ UserId, RoundId, Sequence, Options }) {
    let uri = 'https://' + this.ServerUrl + '/setSoloUserSequences?';
    uri += 'UserId=' + UserId + '&';
    uri += 'RoundId=' + RoundId + '&';
    uri += 'Sequence=' + Sequence + '&';
    uri += 'Options=' + Options;

    try {
      const res = await axios.get(uri);
      return res.data;
    }
    catch (err) {
      return 'ERROR';
    }
  }

  async judgeSoloUserSequences({ JudgeUserId, JudgeOptions, Points, Code, SPoints, TPoints, SStars, TStars, CStars, RStars }) {
    let uri = 'https://' + this.ServerUrl + '/judgeSoloUserSequences?';
    uri += 'JudgeUserId=' + JudgeUserId + '&';
    uri += 'JudgeOptions=' + JudgeOptions + '&';
    uri += 'Points=' + Points + '&';
    uri += 'Code=' + Code + '&';
    uri += 'SPoints=' + SPoints + '&';
    uri += 'TPoints=' + TPoints + '&';
    uri += 'SStars=' + SStars + '&';
    uri += 'TStars=' + TStars + '&';
    uri += 'CStars=' + CStars + '&';
    uri += 'RStars=' + RStars;

    try {
      const res = await axios.get(uri);
      return res.data;
    }
    catch (err) {
      return 'ERROR';
    }
  }

  async userSendVideoSequence({ IsVideo, Code }) {
    let uri = 'https://' + this.ServerUrl + '/judgeSoloUserSequences?';
    uri += 'IsVideo=' + IsVideo + '&';
    uri += 'Code=' + Code;
    try {
      const res = await axios.get(uri);
      return res.data;
    }
    catch (err) {
      return 'ERROR';
    }
  }

  async judgeSendOptionsSequence({ Options, Code }) {
    let uri = 'https://' + this.ServerUrl + '/judgeSoloUserSequences?';
    uri += 'Options=' + Options + '&';
    uri += 'Code=' + Code;
    try {
      const res = await axios.get(uri);
      return res.data;
    }
    catch (err) {
      return 'ERROR';
    }
  }


  // получить свои штуки
  async getISawItSolo() {

    if (!this.iamuser || !this.iamuser.data || !this.iamuser.data.Id) {
      return null
    }

    let uri = 'https://' + this.ServerUrl + '/getISawIt?';
    uri += 'UserId=' + this.iamuser.data.Id;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  // (для судьи - типа я что-то сделал)
  async setISawItSolo({ UserId, Type, ThingId }) {

    if (!this.iamuser || !this.iamuser.data || !this.iamuser.data.Id) {
      return null
    }

    let uri = 'https://' + this.ServerUrl + '/setISawIt?';
    uri += 'UserId=' + UserId;
    uri += '&Type=' + Type;
    uri += '&ThingId=' + ThingId;
    uri += '&JudgeId=' + 2;
    // ЭТОООО ЗАГЛУУУУШКА :)

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  // (для судьи - типа я что-то сделал)
  async deleteISawItSolo(Id) {

    let uri = 'https://' + this.ServerUrl + '/deleteISawIt?';
    uri += 'Id=' + Id;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async getSoloSequenceByElement(Elements) {
    let uri = 'https://' + this.ServerUrl + '/getSoloSequenceByElements?';
    uri += 'Elements=' + Elements;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async getSoloUserPoints(UserId) {
    let uri = 'https://' + this.ServerUrl + '/getSoloUserPoints?';
    uri += 'UserId=' + UserId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async setSoloUserPoints(CompetitiorId, Points = 0) {
    let uri = 'https://' + this.ServerUrl + '/setSoloUserPoints?';
    uri += 'Id=' + CompetitiorId;
    uri += '&Points=' + Points;
    uri += '&JudgeId=2';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async setSoloUserItem({ UserId, Type, Item, Title }) {
    let uri = 'https://' + this.ServerUrl + '/setSoloItem?';
    uri += 'UserId=' + UserId;
    uri += '&Type=' + Type;
    uri += '&Item=' + Item;
    uri += '&Title=' + Title;
    uri += '&JudgeId=2';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  getSoloCompetitionsLogoUrl(Id) {
    return 'https://' + this.ServerUrl + '/graphics/logo_solo_competitions/' + Id + '/logo.png';
  }

  getTypeDiscription(type, isMany = false) {

    switch (type) {
      case 'strike': return isMany ? 'Удары' : 'Удар'
      case 'movement': return isMany ? 'Перемещения' : 'Перемещение'
      case 'defence': return isMany ? 'Защита' : 'Защита'

      default: return type
    }

  }


  getSubtypesByType(type) {

    if (type === 'strike') {
      return ['round', 'strick', 'jump'];
    }

    if (type === 'movement') {
      return ['perekidka', 'freeze', 'jump'];
    }

    return [];

  }

  getSubtypeName(subtype) {
    switch (subtype) {
      case 'round': return 'Круговые';
      case 'strick': return 'Прямые';
      case 'jump': return 'С прыжком';
      case 'perekidka': return 'Перекидки';
      case 'freeze': return 'Фризы';

      default: return 'Нет названия'
    }

  }


  getCardName({ type, subtype, element }) {

    if (element) {

      const elements = this.getServerElementsAsObject();
      if (elements[element]) {
        return elements[element].title;
      }

      return 'ОШИБКА ЭЛЕМЕНТА';

    }


    if (type === 'any') {

      if (subtype === 'jump') return 'Любой прыжок';

      return 'Любое движение'

    }

    if (type === 'strike') {

      if (subtype === 'strick') return 'Прямой удар';
      if (subtype === 'round') return 'Круговой удар';
      if (subtype === 'jump') return 'Прыжковый удар';

      return 'Любой удар'

    }

    if (type === 'movement') {

      if (subtype === 'perekidka') return 'Любая перекидка';
      if (subtype === 'freeze') return 'Фриз';
      if (subtype === 'jump') return 'Перемещение с прыжком';

      return 'Любое перемещение'

    }

    if (type === 'defence') {

      return 'Любая защита';

    }

    return 'ОШИБКА ЭЛЕМЕНТА';

  }

  getElement = (key) => {
    const element = this.getServerElements().find(x => x.key === key)
    if (!element || !(element.Points)) return { Points: 1 }
    return element;
  }

  formServerElementsAsObjectWithKeys() {
    const elements = this.getServerElements();

    let MyTranslate = this.lastMyTranslate || 2;
    // если у нас есть опции, тупим туда
    if (this.soloUserOptions) {
      if (this.soloUserOptions.MyTranslate && this.soloUserOptions.MyTranslate !== MyTranslate)
        MyTranslate = this.soloUserOptions.MyTranslate;
    }

    this.lastMyTranslate = MyTranslate;

    const elements_ = Object.keys(elements).reduce(
      (result, element_key) => {

        const element = elements[element_key]

        return {
          ...result,
          [element.key]: {
            ...element,
            titles: element.title,
            title: MyTranslate === 2 ? element.title[1] || element.title[0] : element.title[0],
            Points: element.Points || 1,
            element_id: element.key
          }

        }

      },
      {}
    )

    this.elements_as_object = { ...elements_ };

  }

  getServerElementsAsObject() {

    // смотрим, составляли ли мы уже это дело
    if (this.elements_as_object) {

      // тут мы проверяем какой у нас последний раз стоял MyTranslation (когда мы это делали)
      // если стоял не такой как сейчас - делаем поновой !
      if (this.soloUserOptions) {
        if (this.soloUserOptions.MyTranslate && this.soloUserOptions.MyTranslate !== this.lastMyTranslate) {
          this.formServerElementsAsObjectWithKeys();
        }
      }
      return this.elements_as_object;
    }

    this.formServerElementsAsObjectWithKeys();

    return this.elements_as_object;
  }

  setLastMyTranslate = (lastMyTranslate) => this.lastMyTranslate = lastMyTranslate;

  getCrossElements() {
    if (this.elements_cross) return this.elements_cross;

    let final_cross_obj = {};

    // формируем кроссы
    const first_keys = Object.keys(CROSS_ELEMENTS);

    //first_keys - это массив названий типа armada, 
    first_keys.map(first_key => {

      if (final_cross_obj[first_key]) {
        final_cross_obj[first_key] = { ...final_cross_obj[first_key], ...CROSS_ELEMENTS[first_key] }
      } else
        final_cross_obj[first_key] = { ...CROSS_ELEMENTS[first_key] }

      const second_keys = Object.keys(CROSS_ELEMENTS[first_key]);

      second_keys.map(second_key => {
        if (final_cross_obj[second_key]) {
          final_cross_obj[second_key] = { ...final_cross_obj[second_key], [first_key]: true }
        } else
          final_cross_obj[second_key] = { [first_key]: true }

        return true;
      })

      return true;
    })

    this.elements_cross = { ...final_cross_obj };

    return this.elements_cross;
  }

  getServerElements() {
    return ELEMENTS;
  }

  getClientElementsStatisctics() {
    return STATISTICS_ELEMENTS;
  }


  // получить свои штуки
  async getRaitingSolo() {

    let uri = 'https://' + this.ServerUrl + '/getSoloRaiting';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async getSoloNews() {
    let uri = 'https://' + this.ServerUrl + '/getSoloNews';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }


  async getUserAsCompetitor(userId = 0) {
    let uri = 'https://' + this.ServerUrl + '/getUserAsCompetitor?userId=' + userId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return null;
  }

  async getFields() {
    let uri = 'https://' + this.ServerUrl + '/getFields';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async updateField(eventId, fieldId, isNow, categoryId, coupleId, circleId, redId, blueId) {
    //let uri = 'https://' + this.ServerUrl + '/updateField?'; 
    let uri = `https://${this.ServerUrl}/updateField?event_id=${eventId}&field_id=${fieldId}`;
    if (isNow) uri += '&now=true'; else uri += '&next=true';
    uri += `&category_id=${categoryId}&couple_id=${coupleId}&circle_id=${circleId}&red_id=${redId}&blue_id=${blueId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async updateFieldVK(eventId, fieldId, VK_Key) {
    //let uri = 'https://' + this.ServerUrl + '/updateField?'; 
    let uri = `https://${this.ServerUrl}/updateField?event_id=${eventId}&field_id=${fieldId}`;
    uri += `&VK_Key=${VK_Key}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // ПОИСК

  async getSearch(text) {
    let uri = 'https://' + this.ServerUrl + '/getSearch?text=' + text;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // МЕДАЛИ ЮЗЕРА

  async getUserMedals(userId) {
    let uri = 'https://' + this.ServerUrl + '/getUserMedals?userId=' + userId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // РОЛИ ЮЗЕРА

  async getUserPortalRoles(userId) {
    let uri = 'https://' + this.ServerUrl + '/getUserRoles?userId=' + userId + '&portal=1';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getUserInstructorRoles(userId) {
    let uri = 'https://' + this.ServerUrl + '/getUserRoles?userId=' + userId + '&SubjectType=group';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getUserStuffRoles(userId) {
    let uri = 'https://' + this.ServerUrl + '/getUserRoles?userId=' + userId + '&SubjectType=event';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getUserSchoolRoles(userId) {
    let uri = 'https://' + this.ServerUrl + '/getUserRoles?userId=' + userId + '&SubjectType=school';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getGroupRoles(groupId) {
    let uri = 'https://' + this.ServerUrl + '/getUserRoles?SubjectId=' + groupId + '&SubjectType=group';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getSchoolRoles(schoolId) {
    let uri = 'https://' + this.ServerUrl + '/getUserRoles?SubjectId=' + schoolId + '&SubjectType=school';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getEventRoles(eventId) {
    let uri = 'https://' + this.ServerUrl + '/getUserRoles?SubjectId=' + eventId + '&SubjectType=event';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async addEventRoles({ EventId, UserId, Role }) {
    let uri = 'https://' + this.ServerUrl + `/addEventRoles?EventId=${EventId}&UserId=${UserId}&Role=${Role}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async removeRole(RoleId) {
    let uri = 'https://' + this.ServerUrl + `/removeRole?RoleId=${RoleId}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getSomeRoles({ SubjectType, SubjectId }) {
    let uri = 'https://' + this.ServerUrl + '/getUserRoles?SubjectId=' + SubjectId + '&SubjectType=' + SubjectType;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // КОЛИЧЕСТВО ЛЮДЕЙ В ШКОЛЕ

  async getSchoolPersonCount(id) {
    let uri = id ? ('https://' + this.ServerUrl + '/getSchoolPersonCount?schoolId=' + id) : ('https://' + this.ServerUrl + '/getSchoolPersonCount');

    if (!id) {
      const answer = await this.standartDataResult(uri);
      if (!answer.error && answer.result) {
        return answer.result;
      }
      return null;
    }

    const answer = await this.standartDataResult(uri);
    if (!answer.error && answer.result) {
      return answer.result.PersonsCount;
    }
    return null;
  }

  async getSubjectMedalsCount(SchoolId) {
    let uri = 'https://' + this.ServerUrl + '/getSubjectMedalsCount?SchoolId=' + SchoolId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getSubjectCompetitorsCount({ subject = 'school', id, season, type }) {
    let uri = 'https://' + this.ServerUrl + '/getSubjectCompetitorsCount?subject=' + subject;
    if (season) uri += `&season=${season}`;
    if (id) uri += `&id=${id}`;
    if (type) uri += `&type=${type}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      if (answer.result[0])
        return answer.result[0].UsersCount;
    }
    return null;
  }

  async getSubjectCompetitorsByTypes({ schoolId, groupId, season, category, type }) {
    let uri = 'https://' + this.ServerUrl + '/getSubjectCompetitorsByTypes?';
    if (season) uri += `&season=${season}`;
    if (schoolId) uri += `&schoolId=${schoolId}`;
    if (groupId) uri += `&groupId=${groupId}`;
    if (type) uri += `&type=${type}`;
    if (category) uri += `&category=${category}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // RAITING

  async deleteRaiting(season) {

    if (!season) return {
      error: 'No season'
    };

    let uri = 'https://' + this.ServerUrl + '/deleteRaiting?season=' + season;
    uri += `&sessionId=${this.lastSessionId}`;
    let result_ = {};

    try {
      const res = await axios.get(uri);
      const result = res.data;
      if (!result) {
        result_ = { error: 'no result' };
      } else {
        result_ = result;
      }
    }
    catch (err) {
      return {
        error: 'Sever error'
      };
    }

    return result_;
  }

  async renewRaiting({ SeasonYStart, SeasonYEnd, LookedY, LookedMD, type }) {


    let uri = 'https://' + this.ServerUrl + `/renewRaiting?sessionId=${this.lastSessionId}`;
    if (SeasonYStart) uri += `&SeasonYStart=${SeasonYStart}`;
    if (SeasonYEnd) uri += `&SeasonYEnd=${SeasonYEnd}`;
    if (LookedY) uri += `&LookedY=${LookedY}`;
    if (LookedMD) uri += `&LookedMD=${LookedMD}`;
    if (type) uri += `&type=${type}`;

    let result_ = {};

    try {
      const res = await axios.get(uri);
      const result = res.data;
      if (!result) {
        result_ = { error: 'no result' };
      } else {
        result_ = result;
      }
    }
    catch (err) {
      return {
        error: 'Sever error'
      };
    }

    return result_;
  }

  async getSubjectRaitingFromServer({ subject = 'user', id, season, as_sum = false, type, category, region, limit = null }) {

    // это кейс простановки мест на стороне фронта
    if (as_sum) {

      let uri = '';
      if (limit) {
        uri = 'https://' + this.ServerUrl + '/getSubjectLimitRaiting?subject=' + subject;
        uri += `&limit=${limit}`;
      } else
        uri = 'https://' + this.ServerUrl + '/getSubjectRaiting?subject=' + subject;

      if (season) uri += `&season=${season}`;
      if (as_sum) uri += `&as_sum=${true}`;
      if (type) uri += `&type=${type}`;
      if (category) uri += `&category=${category}`;
      if (region) uri += `&region=${region}`;

      const answer = await this.standartDataResult(uri);
      let result = [...answer.result].reverse();
      // теперь будем расставлять Place и PlaceName
      // if ( subject === 'user') return result;

      const placesAllResult = this.setPlacesToSubject(result, id, subject);
      const { result: placesResult, ourId_i } = placesAllResult;

      if (!placesResult) return [];

      // если запросили весь рейтинг, то вот так
      if (!id) {
        return placesResult;
      }

      // если не весь, а конкретно нас (типа место конкретной группы), но такого нет в результата - увы
      if (!placesResult[ourId_i]) return [];

      return [placesResult[ourId_i]];

    }

    const raitingKey = `${subject}_${id}_${season}_${type}`;
    if (this.state.subjectRaitings[raitingKey] !== undefined) {
      return this.state.subjectRaitings[raitingKey];
    }

    let uri = 'https://' + this.ServerUrl + '/getSubjectRaiting?subject=' + subject;
    if (id) uri += `&id=${id}`;
    if (season) uri += `&season=${season}`;
    if (as_sum) uri += `&as_sum=${true}`;
    if (type) uri += `&type=${type}`;
    const answer = await this.standartDataResult(uri);

    this.state.subjectRaitings[raitingKey] = answer.result;
    return answer.result;
  }

  setPlacesToSubject = (data, subjectId, subject) => {

    let lookedSubjectName = 'UserId';
    if (subject === 'group') lookedSubjectName = 'GroupId';
    if (subject === 'school') lookedSubjectName = 'SchoolId';

    const result = [...data];

    let i_start = 0; // это тот i, с которого мы начали смотреть подгруппу мест
    // и мы будем смотреть до тех пор, пока не найдём отличный результат от начального
    let now_looked_scores = 0;
    let now_top_place = 1;
    let ourId_i = -1; // порядковые номер в массиве, который вернём

    for (let i = 0; i <= result.length; i++) {

      if (i < result.length) {

        if (result[i][lookedSubjectName] === subjectId) {
          ourId_i = i;
        }

        if (now_looked_scores === 0) {
          now_looked_scores = result[i].AllScores;
          i_start = i;
          continue;
        }

        // т.е. следующее место такое же, как и предыдущее
        if (result[i].AllScores === now_looked_scores) {
          continue;
        }
      }

      // нашли отличающейся результат - надо расставить предыдущим места
      if (i === result.length || result[i].AllScores !== now_looked_scores) {

        // смотрим, сколько мы промотали 
        const count = i - i_start;

        const PlaceName = count === 1 ? now_top_place : `${now_top_place} - ${now_top_place + count - 1}`;
        const Place = now_top_place;

        // всем промотанным выставляем текущее топовое место
        for (let j = 0; j < count; j++) {
          result[i_start + j].Place = Place;
          result[i_start + j].PlaceName = PlaceName;
        }

        now_top_place += count;
        now_looked_scores = i === result.length ? 0 : result[i].AllScores;
        i_start = i;

      }
    }
    // вышли из цикла
    return { result, ourId_i };
  }


  async setUserRaiting({ userId, season, type, category, place, placeName }) {
    let uri = 'https://' + this.ServerUrl + '/updateUserRaiting?';
    if (userId) uri += `&userId=${userId}`;
    if (season) uri += `&season=${season}`;
    if (category) uri += `&category=${category}`;
    if (type) uri += `&type=${type}`;
    if (place) uri += `&place=${place}`;
    if (placeName) uri += `&placeName=${placeName}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  ////// JUDGING

  async setVideoStartOrEnd({ VideoId, TimeStart, TimeEnd }) {
    let uri = 'https://' + this.ServerUrl + '/setVideoStartOrEnd?';
    uri += 'VideoId=' + VideoId;
    if (TimeStart) uri += '&TimeStart=' + TimeStart;
    if (TimeEnd) uri += '&TimeEnd=' + TimeEnd;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getVideoJudgings(VideoId) {

    let uri = 'https://' + this.ServerUrl + '/getVideoJudgings?';
    uri += 'VideoId=' + VideoId;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;

  }

  async getJudgings() {
    let uri = 'https://' + this.ServerUrl + '/getJudgings';
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async addJudging(VideoId, isMain = false) {
    let uri = 'https://' + this.ServerUrl + '/addJudging?';
    uri += 'VideoId=' + VideoId;
    uri += '&isMain=' + (isMain ? 1 : 0);
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async updateJudgingStatus(judgingId, Status) {
    let uri = 'https://' + this.ServerUrl + '/updateJudgingStatus?';
    uri += 'Id=' + judgingId;
    uri += '&Status=' + Status;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getJudgingActions(JudgeResultId) {
    let uri = 'https://' + this.ServerUrl + '/getJudgingActions?';
    uri += 'JudgeResultId=' + JudgeResultId;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async addJudgeAction({ JudgeResultId, Type, Subtype = 'usual', Color, Shift = 0, Time = 0, Target = null }) {
    let uri = 'https://' + this.ServerUrl + '/addJudgeAction?';
    uri += 'JudgeResultId=' + JudgeResultId;
    uri += '&Type=' + Type;
    uri += '&Subtype=' + Subtype;
    uri += '&Color=' + Color;
    uri += '&Shift=' + Shift;
    uri += '&Time=' + Time;
    if (Target)
      uri += '&Target=' + JSON.stringify(Target);
    uri += `&sessionId=${this.lastSessionId}`;
    await this.standartDataResult(uri);
  }

  async deleteJudgeAction(Id) {
    let uri = 'https://' + this.ServerUrl + '/deleteJudgeAction?';
    uri += 'Id=' + Id;
    uri += `&sessionId=${this.lastSessionId}`;
    await this.standartDataResult(uri);
  }

  ////// APPLICATIONS

  async getApplications({ userId = null, type = null, result = null } = {}) {
    let uri = 'https://' + this.ServerUrl + '/getApplications?';
    if (userId) uri += `&userId=${userId}`;
    if (type) uri += `&type=${type}`;
    if (result) uri += `&result=${result}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async updateApplication({ applicationId, result, comment = undefined }) {
    let uri = 'https://' + this.ServerUrl + '/updateApplication?';
    if (applicationId) uri += `&applicationId=${applicationId}`;
    if (result) uri += `&result=${result}`;
    if (comment !== undefined) uri += `&comment=${comment}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async addApplication({ type, values = null, subjectId = null, userId = null }) {
    let uri = 'https://' + this.ServerUrl + '/addApplication?';
    if (type) uri += `&type=${type}`;
    if (values) uri += `&values=${values}`;
    if (subjectId) uri += `&subjectId=${subjectId}`;
    if (userId) uri += `&userId=${userId}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async processApplication({ applicationId }) {
    let uri = 'https://' + this.ServerUrl + '/processApplication?';
    if (applicationId) uri += `&applicationId=${applicationId}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getBirthdays() {
    let uri = 'https://' + this.ServerUrl + '/getBirthdays?';

    const answer = await this.standartDataResult(uri);
    // if (!answer.error) {
    //   return answer.result;
    // }
    // return answer.result;

    this.state.birthdays = (answer.result && answer.result.length) ? [...answer.result] : [];
    this.reloadPage('birthdays');

  }


  // Discriptions
  async getDiscriptions({ subject = null, subjectId = null, type = null }) {
    let uri = 'https://' + this.ServerUrl + '/getDiscriptions?';
    if (type) uri += `&type=${type}`;
    if (subject) uri += `&subject=${subject}`;
    if (subjectId) uri += `&subjectId=${subjectId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async updateDiscription({ discriptionId, body }) {

    let uri = 'https://' + this.ServerUrl + '/updateDiscription';

    let bodyFormData = new FormData();
    bodyFormData.set('body', body);
    bodyFormData.set('discriptionId', discriptionId);
    bodyFormData.set('sessionId', this.lastSessionId);


    const res = await axios({
      method: 'post',
      url: uri,
      data: bodyFormData,
      config: {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      }
    });

    console.log('Статья установлена', res);
  }

  async addDiscription({ body, subject, subjectId, type }) {

    let uri = 'https://' + this.ServerUrl + '/addDiscription';

    let bodyFormData = new FormData();
    bodyFormData.set('body', body);
    bodyFormData.set('subject', subject);
    bodyFormData.set('subjectId', subjectId);
    bodyFormData.set('type', type);
    bodyFormData.set('sessionId', this.lastSessionId);


    const res = await axios({
      method: 'post',
      url: uri,
      data: bodyFormData,
      config: {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      }
    });

    console.log('Статья установлена', res);
  }


  // NewNews 
  async getNewNews({ subject = null, subjectId = null, limit = 0 }) {
    let uri = 'https://' + this.ServerUrl + '/getNewNews?';
    if (subject) uri += `&subject=${subject}`;
    if (subjectId) uri += `&subjectId=${subjectId}`;
    if (limit) uri += `&limit=${limit}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  /**
   *  subject
   *  subjectId
   *  title
   *  body
   *  lvl
   */

  async addNewNews(data) {
    let uri = 'https://' + this.ServerUrl + '/addNewNews';

    let bodyFormData = new FormData();
    Object.keys(data).reduce((memo, key) => {
      bodyFormData.set(key, data[key]);
      return memo;
    }, '')

    bodyFormData.set('sessionId', this.lastSessionId);

    const res = await axios({
      method: 'post',
      url: uri,
      data: bodyFormData,
      config: {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      }
    });
  }


  // GROUP
  async getGroupResultsByDate(GroupId) {
    let uri = 'https://' + this.ServerUrl + '/getGroupResultsByDate';
    uri += '?GroupId=' + GroupId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async updateGroupInfo({ GroupId, Name }) {
    let uri = 'https://' + this.ServerUrl + '/updateGroupInfo';
    uri += '?GroupId=' + GroupId;
    uri += `&Name=${Name}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async updateAvatarGroup(GroupId, data) {
    let uri = 'https://' + this.ServerUrl + '/changeGroupAvatar';

    let bodyFormData = new FormData();

    bodyFormData.set('data', data);
    bodyFormData.set('GroupId', GroupId);
    bodyFormData.set('sessionId', this.lastSessionId);

    const res = await axios({
      method: 'post',
      url: uri,
      data: bodyFormData,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Access-Control-Allow-Origin": "*",
        }
      }
    });

    if (res && res.status === 200) {
      return true;
    }

    return false;

  }

  async addGroupRoles({ GroupId, UserId, Role }) {
    let uri = 'https://' + this.ServerUrl + `/addGroupRoles?GroupId=${GroupId}&UserId=${UserId}&Role=${Role}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getGroupVideo({ GroupId }) {
    let uri = 'https://' + this.ServerUrl + `/getGroupVideo?GroupId=${GroupId}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // LOGGER
  async getLogger(Options) {
    let uri = 'https://' + this.ServerUrl + `/getLogger?sessionId=${this.lastSessionId}`;
    if (Options) {
      const OptionsObj = JSON.stringify(Options);
      uri += `&Options=${OptionsObj}`;
    }
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }
  async addLogger({ Code, Data, UserId }) {
    let uri = 'https://' + this.ServerUrl + `/addLogger?Code=${Code}`;
    if (Data) {
      const DataObj = JSON.stringify(Data);
      uri += `&Data=${DataObj}`;
    }
    if (UserId) uri += `&UserId=${UserId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // STATISTICS
  async getStatistics(Types, PersonId = null, Season = null, MinFights = 1, MaxFights = 1, Region = null, EventsArray = null) {
    let uri = 'https://' + this.ServerUrl + `/getStatistics?Types=${Types}`;
    if (PersonId) uri += `&PersonId=${PersonId}`;
    if (Season) uri += `&Season=${Season}`;
    if (MinFights) uri += `&MinFights=${MinFights}`;
    if (MaxFights) uri += `&MaxFights=${MaxFights}`;
    if (Region) uri += `&Region=${Region}`;
    if (EventsArray) uri += `&EventsArray=${EventsArray}`;
    // uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // TRANSFER

  async getTransfer(data) {
    let uri = 'https://' + this.ServerUrl + '/getTransfer?';

    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // GROUP
  async getSubgroupAllStat() {
    let uri = 'https://' + this.ServerUrl + '/getSubgroupAllStat';

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // SHOP
  // просто получить один Shop  
  async getShop(shopId = null, SubjectName = null, SubjectId = null) {
    let uri = 'https://' + this.ServerUrl + '/getShop';
    if (shopId) uri += '?ShopId=' + shopId;
    if (SubjectName) uri += '?SubjectName=' + SubjectName;
    if (SubjectId) uri += '&SubjectId=' + SubjectId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result[0];
    }
    return null;
  }

  async getShopItems(ShopId) {
    let uri = 'https://' + this.ServerUrl + '/getShopItems';
    uri += '?ShopId=' + ShopId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async buyShopItems(ShopItemId) {
    let uri = 'https://' + this.ServerUrl + '/buyShopItem';
    uri += '?ShopItemId=' + ShopItemId;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async exchangeMoney({ ShopId, MoneyFrom, Amount }) {
    let uri = 'https://' + this.ServerUrl + '/exchangeMoney';
    uri += '?ShopId=' + ShopId;
    uri += '&MoneyFrom=' + MoneyFrom;
    uri += '&Amount=' + Amount;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getKMOQuest(Limit = 0) {
    let uri = 'https://' + this.ServerUrl + '/getKMOQuest';
    if (Limit) uri += '?Limit=' + Limit;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }


  // ?ShopId=1&MoneyFrom=squ&Amount=5

  // EVENTS


  // просто получить один Event  
  async getEventData({ EventId }) {
    let uri = 'https://' + this.ServerUrl + '/getEventData';
    uri += '?EventId=' + EventId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getMyCreationEvents() {
    let uri = 'https://' + this.ServerUrl + '/getMyCreationEvents';
    uri += `?sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getLastEventId() {
    let uri = 'https://' + this.ServerUrl + '/getLastEventId';

    try {
      const res = await axios.get(uri);
      return (res && res.data && res.data.Id) ? res.data.Id : null;
    }
    catch (err) {
      console.log('server error');
      return null;
    }
  }

  async createEvent(data) {
    let uri = 'https://' + this.ServerUrl + '/createEvent';

    let bodyFormData = new FormData();
    Object.keys(data).reduce((memo, key) => {

      if (key === 'NotAnnounced') { bodyFormData.set(key, data[key] ? 1 : 0); return memo }
      if (key === 'IsPreliminarily') { bodyFormData.set(key, data[key] ? 1 : 0); return memo }
      if (key === 'StartPreliminarilyDate') { bodyFormData.set(key, data[key] || null); return memo }
      if (key === 'EndPreliminarilyDate') { bodyFormData.set(key, data[key] || null); return memo }

      bodyFormData.set(key, data[key]);
      return memo;
    }, '')

    bodyFormData.set('sessionId', this.lastSessionId);

    const res = await axios({
      method: 'post',
      url: uri,
      data: bodyFormData,
      config: {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      }
    });
  }

  async updateEventSettings(Settings, eventId) {
    let uri = 'https://' + this.ServerUrl + '/updateEventSettings';

    let bodyFormData = new FormData();
    bodyFormData.set('Settings', Settings);
    bodyFormData.set('eventId', eventId);
    bodyFormData.set('sessionId', this.lastSessionId);

    const res = await axios({
      method: 'post',
      url: uri,
      data: bodyFormData,
      config: {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      }
    });
  }

  async updateEventMain(data, eventId) {
    let uri = 'https://' + this.ServerUrl + '/updateEvent?eventId=' + eventId;
    const query = Object.keys(data).reduce(
      (memo, key) => {
        return memo + `&${key}=${data[key]}`
      },
      ''
    )
    uri += query;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async updateAvatarShop(ShopId, data) {
    let uri = 'https://' + this.ServerUrl + '/changeShopAvatar';

    let bodyFormData = new FormData();

    bodyFormData.set('data', data);
    bodyFormData.set('ShopId', ShopId);
    bodyFormData.set('sessionId', this.lastSessionId);

    const res = await axios({
      method: 'post',
      url: uri,
      data: bodyFormData,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Access-Control-Allow-Origin": "*",
        }
      }
    });

    if (res && res.status === 200) {
      return true;
    }

    return false;

  }

  async updateAvatarEvent(EventId, data, AsBackground = false) {
    let uri = 'https://' + this.ServerUrl + '/changeEventAvatar';

    let bodyFormData = new FormData();

    bodyFormData.set('data', data);
    bodyFormData.set('EventId', EventId);
    if (AsBackground)
      bodyFormData.set('AsBackground', true);

    bodyFormData.set('sessionId', this.lastSessionId);

    const res = await axios({
      method: 'post',
      url: uri,
      data: bodyFormData,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Access-Control-Allow-Origin": "*",
        }
      }
    });

    if (res && res.status === 200) {
      return true;
    }

    return false;

  }

  async updateAvatarUser(data) {
    let uri = 'https://' + this.ServerUrl + '/changeAvatar';

    let bodyFormData = new FormData();

    bodyFormData.set('data', data);
    bodyFormData.set('sessionId', this.lastSessionId);

    const res = await axios({
      method: 'post',
      url: uri,
      data: bodyFormData,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Access-Control-Allow-Origin": "*",
        }
      }
    });

    if (res && res.status === 200) {
      return true;
    }

    return false;

  }

  // ПОЛУЧИТЬ МОИ СОРЕВНОВАНИЯ
  async getUserCompetitions({ UserId }) {
    let uri = 'https://' + this.ServerUrl + '/getUserCompetitions?UserId=' + UserId;

    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }


  // ВСЯКОЕ ПО PARTICIPETORS
  /**
   * 
   * @param 
   * ParticipationId,
    EventId,
    UserId 
  } data 
   */
  async getParticipators(data) {
    let uri = 'https://' + this.ServerUrl + '/getParticipators?';

    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  /**
   * 
   * @param {*} data 
   * EventId, UserId, Settings
   */

  async addParticipator(data) {
    let uri = 'https://' + this.ServerUrl + '/addParticipator?';

    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getParticipatorStatistic(EventId) {
    let uri = 'https://' + this.ServerUrl + '/getParticipatorStatistic?EventId=' + EventId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }


  /**
   * 
   * @param {*} data 
   * ParticipatorId
   */
  async deleteParticipator(data) {
    let uri = 'https://' + this.ServerUrl + '/deleteParticipator?';

    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // competitors

  /*
    UserId,
  SchoolId,
  GroupIamInId,
  CompetitionId,
  CategoryId,
  Settings,

  */

  async addCompetitor(data) {
    let uri = 'https://' + this.ServerUrl + '/addCompetitor?';

    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  /*
  CompetitorId 
  */

  async deleteCompetitorNew(data) {
    let uri = 'https://' + this.ServerUrl + '/deleteCompetitor?';

    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  //participatorData

  async addParticipatorData(data) {
    let uri = 'https://' + this.ServerUrl + '/addParticipatorData?';

    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }


  async getParticipatorData(data) {
    let uri = 'https://' + this.ServerUrl + '/getParticipatorData?';

    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async deleteParticipatorData(ParticipatorDataId) {
    let uri = 'https://' + this.ServerUrl + '/deleteParticipatorData?';

    uri += `ParticipatorDataId=${ParticipatorDataId}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  //OPERATIONS

  async getOperationByCode(Code) {
    let uri = 'https://' + this.ServerUrl + '/getOperationByCode?';

    uri += `Code=${Code}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getOperationsByShop(ShopId) {
    let uri = 'https://' + this.ServerUrl + '/getOperationsByShop?';

    uri += `ShopId=${ShopId}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getOperationsByEvent(EventId) {
    let uri = 'https://' + this.ServerUrl + '/getOperationsByEvent?';

    uri += `EventId=${EventId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getUsersOperationsByScores(UserId = null, Scores, OperationType = null) {
    let uri = 'https://' + this.ServerUrl + '/getUsersOperationsByScores?';

    uri += `Scores=${Scores}`;
    if (UserId) uri += `&UserId=${UserId}`;
    if (OperationType) uri += `&OperationType=${OperationType}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getUsersFromOperation(Code) {
    let uri = 'https://' + this.ServerUrl + '/getUsersFromOperation?';
    uri += `Code=${Code}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async releaseOperation(Code) {
    let uri = 'https://' + this.ServerUrl + '/releaseOperation?';

    uri += `Code=${Code}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async addOperation({ EventId, Type, Settings, AllowedTo, Discription }) {
    let uri = 'https://' + this.ServerUrl + '/addOperation?';

    uri += `Type=${Type}`;
    uri += `&Settings=${Settings}`;
    if (AllowedTo) uri += `&AllowedTo=${AllowedTo}`;
    uri += `&Discription=${Discription}`;


    if (EventId) uri += `&EventId=${EventId}`;

    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async islandPointOperation({ UserId, Settings, IslandPointId, AllowedTo, Type, Discription }) {
    let uri = 'https://' + this.ServerUrl + '/islandPointOperation?';

    uri += `Type=${Type}`;
    uri += `&Settings=${Settings}`;
    uri += `&UserId=${UserId}`;
    uri += `&IslandPointId=${IslandPointId}`;
    uri += `&AllowedTo=${AllowedTo}`;
    uri += `&Discription=${Discription}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // REGIONS //////////////////////////////////////////
  async amountInRegion(Region) {
    let uri = 'https://' + this.ServerUrl + '/amountInRegion?';

    uri += `&sessionId=${this.lastSessionId}`;
    if (Region) uri += `&Region=${Region}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async schoolsInRegion(Region) {
    let uri = 'https://' + this.ServerUrl + '/schoolsInRegion?';

    uri += `&sessionId=${this.lastSessionId}`;
    if (Region) uri += `&Region=${Region}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async ranksInRegion({ Region, Judges, Sport, JudgeRank, SportRank }) {
    let uri = 'https://' + this.ServerUrl + '/ranksInRegion?';

    uri += `&sessionId=${this.lastSessionId}`;
    if (Region) uri += `&Region=${Region}`;
    if (Judges) uri += `&Judges=${1}`;
    if (Sport) uri += `&Sport=${1}`;
    if (JudgeRank) uri += `&JudgeRank=${JudgeRank}`;
    if (SportRank) uri += `&SportRank=${SportRank}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async eventsInRegion(Region) {
    let uri = 'https://' + this.ServerUrl + '/eventsInRegion?';

    uri += `&sessionId=${this.lastSessionId}`;
    if (Region) uri += `&Region=${Region}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // USERS //////////////////////////////////////////
  async updateMe(data) {
    let uri = 'https://' + this.ServerUrl + '/updateMe?';

    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // USERS //////////////////////////////////////////
  async updateMyVSettings(VSettings) {
    let uri = 'https://' + this.ServerUrl + '/updateUserVSettings?';

    uri += `VSettings=${VSettings}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getMyVSettings() {
    let uri = 'https://' + this.ServerUrl + '/getMyVSettings?';

    uri += `sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async changeStudentData(UserId, data) {
    let uri = 'https://' + this.ServerUrl + '/changeStudentData?UserId=' + UserId + '&';

    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getUserRegionData(id) {
    let uri = 'https://' + this.ServerUrl + '/getUserRegionData?';

    uri += `UserId=${id}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getUserWithRegion({ UserId, Season }) {
    let uri = 'https://' + this.ServerUrl + '/getUserWithRegion?a=b';

    if (UserId) uri += `&UserId=${UserId}`;
    if (Season) uri += `&Season=${Season}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async setUserRegionData(Region) {
    let uri = 'https://' + this.ServerUrl + '/setUserRegionData?';

    uri += `Region=${Region || 'null'}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  //PASSWORD { Login, Password, NewPassword, UserId }
  async changeUserPassword(data) {
    let uri = 'https://' + this.ServerUrl + '/changeUserPassword?';
    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (answer && !answer.error && answer.result && answer.result.message) {
      return true;
    }
    return null;
  }

  // GROUPS ////////////////////////
  async getGroupsWithMedals({ SchoolId, Region }) {
    let uri = 'https://' + this.ServerUrl + '/getGroupsWithMedals?';
    uri += `sessionId=${this.lastSessionId}`;
    if (SchoolId) uri += `&SchoolId=${SchoolId}`;
    if (Region) uri += `&Region=${Region}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // STORY COMPETITORS //////////////////////////////////////////
  async addStoryCompetitor({ StoryId, RedId, BlueId }) {
    let uri = 'https://' + this.ServerUrl + '/addStoryCompetitor?';
    uri += `sessionId=${this.lastSessionId}`;
    uri += `&StoryId=${StoryId}`;
    uri += `&RedId=${RedId}`;
    uri += `&BlueId=${BlueId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }


  // NOTIFICATIONS //////////////////////////////////////////
  async getActualNotifications() {
    let uri = 'https://' + this.ServerUrl + '/getActualNotifications?';
    uri += `sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getAllNotifications() {
    let uri = 'https://' + this.ServerUrl + '/getAllNotifications?';
    uri += `sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async iSawNotification(NotificationId) {
    let uri = 'https://' + this.ServerUrl + '/iSawNotification?NotificationId=' + NotificationId;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  /*UserId, Body*/
  async addNotification(data) {
    let uri = 'https://' + this.ServerUrl + '/addNotification';
    let bodyFormData = new FormData();
    Object.keys(data).reduce((memo, key) => {
      bodyFormData.set(key, data[key]);
      return memo;
    }, '')

    bodyFormData.set('sessionId', this.lastSessionId);

    const res = await axios({
      method: 'post',
      url: uri,
      data: bodyFormData,
      config: {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      }
    });
  }


  // BETS ///////////////////////////////////////////

  // getAllBets,
  // getUserBet,
  // updateUserBet,
  // addUserBet


  //EventId
  async getAllBets(data) {
    let uri = 'https://' + this.ServerUrl + '/getAllBets?';
    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  //EventId
  async getUserBet(data) {
    let uri = 'https://' + this.ServerUrl + '/getUserBet?';
    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  //BetId, Settings
  async updateUserBet(data) {
    let uri = 'https://' + this.ServerUrl + '/updateUserBet?';
    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  //EventId, Settings
  async addUserBet(data) {
    let uri = 'https://' + this.ServerUrl + '/addUserBet?';
    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // Season
  async getSeasonBets(Season = null) {
    let uri = 'https://' + this.ServerUrl + '/getSeasonBets?';
    uri += `sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }


  // NEW V2.0 GAME
  // ELEMENTS
  async getUserElements(UserId) {
    let uri = 'https://' + this.ServerUrl + '/getUserElements?';
    uri += `UserId=${UserId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async addUserElement(ElementKey) {
    let uri = 'https://' + this.ServerUrl + '/addUserElement?';
    uri += `ElementKey=${ElementKey}`;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // ISLAND ////////////////////////////////////////////

  async islandLogOnIsland({ IslandId = 1 }) {
    let uri = 'https://' + this.ServerUrl + '/islandLogOnIsland?IslandId=' + IslandId;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getIslandWhereAmI(IslandId = 1) {
    let uri = 'https://' + this.ServerUrl + '/islandWhereAmI?IslandId=' + IslandId;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async islandMakeAChoose({ IslandId = 1, ChooseId, Special }) {
    let uri = 'https://' + this.ServerUrl + '/islandMakeAChoose?IslandId=' + IslandId;
    if (ChooseId) uri += `&ChooseId=${ChooseId}`;
    if (Special) uri += `&Special=${JSON.stringify(Special)}`;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async islandUseItem({ IslandId = 1, ItemId }) {
    let uri = 'https://' + this.ServerUrl + '/islandUseItem?IslandId=' + IslandId;
    if (ItemId) uri += `&ItemId=${ItemId}`;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async islandCombineItems({ IslandId = 1, Item1Id, Item2Id }) {
    let uri = 'https://' + this.ServerUrl + '/islandCombineItems?IslandId=' + IslandId;
    if (Item1Id) uri += `&Item1Id=${Item1Id}`;
    if (Item2Id) uri += `&Item2Id=${Item2Id}`;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async islandGetUserActions({ IslandId = 1 }) {
    let uri = 'https://' + this.ServerUrl + '/islandGetUserActions?IslandId=' + IslandId;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async islandGetWhoPassed({ ActionId = 1 }) {
    let uri = 'https://' + this.ServerUrl + '/islandGetWhoPassed?ActionId=' + ActionId;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async islandGetWhoOnIsland({ IslandId = 1 }) {
    let uri = 'https://' + this.ServerUrl + '/islandGetWhoOnIsland?IslandId=' + IslandId;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async islandGetUsersData({ IslandId = 1, WithTeams }) {
    let uri = 'https://' + this.ServerUrl + '/islandGetUsersData?IslandId=' + IslandId;
    if (WithTeams) uri += `&WithTeams=${1}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async islandGetTeamResults({ IslandId = 1, Money }) {
    let uri = 'https://' + this.ServerUrl + '/islandGetTeamResults?IslandId=' + IslandId;
    if (Money) uri += `&Money=${Money}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async islandConstructor({ TypeData, ActionData, IslandId, TargetId, Data }) {
    let uri = 'https://' + this.ServerUrl + '/islandConstructor';

    let bodyFormData = new FormData();
    if (TypeData) bodyFormData.set('TypeData', TypeData);
    if (ActionData) bodyFormData.set('ActionData', ActionData);
    if (IslandId) bodyFormData.set('IslandId', IslandId);
    if (TargetId) bodyFormData.set('TargetId', TargetId);
    if (Data) bodyFormData.set('Data', JSON.stringify(Data));
    bodyFormData.set('sessionId', this.lastSessionId);


    const res = await axios({
      method: 'post',
      url: uri,
      data: bodyFormData,
      config: {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      }
    });

    if (res && res.data && res.data.result && res.data.result.data && res.data.result.status === "Sucess") {
      return res.data.result.data;
    }

    return null;
  }

  // COMBO CONSTRUCOTR
  async comboConstructor({ TypeData, ActionData, TargetId, Data, DataExercises }) {
    let uri = 'https://' + this.ServerUrl + '/comboConstructor';

    let bodyFormData = new FormData();
    if (TypeData) bodyFormData.set('TypeData', TypeData);
    if (ActionData) bodyFormData.set('ActionData', ActionData);
    if (TargetId) bodyFormData.set('TargetId', TargetId);
    if (Data) bodyFormData.set('Data', JSON.stringify(Data));
    if (DataExercises) bodyFormData.set('DataExercises', JSON.stringify(DataExercises));
    bodyFormData.set('sessionId', this.lastSessionId);


    const res = await axios({
      method: 'post',
      url: uri,
      data: bodyFormData,
      config: {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      }
    });

    if (res && res.data && res.data.result && res.data.result.data && res.data.result.status === "Sucess") {
      return res.data.result.data;
    }

    return null;
  }

  // COMBO CONSTRUCOTR Title, Date, CreatorId, PredvDate, RegistrationDate, CloseDate, Season
  async competitionConstructor({ ActionData, CompetitionId, Data }) {
    let uri = 'https://' + this.ServerUrl + '/competitionConstructor';

    let bodyFormData = new FormData();
    if (ActionData) bodyFormData.set('ActionData', ActionData);
    if (CompetitionId) bodyFormData.set('CompetitionId', CompetitionId);
    if (Data) bodyFormData.set('Data', JSON.stringify(Data));
    bodyFormData.set('sessionId', this.lastSessionId);


    const res = await axios({
      method: 'post',
      url: uri,
      data: bodyFormData,
      config: {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      }
    });

    if (res && res.data && res.data.result && res.data.result.data && res.data.result.status === "Sucess") {
      return res.data.result.data;
    }

    return null;
  }

  // REAL SHOP
  async realshopItems(ShopId) {
    let uri = 'https://' + this.ServerUrl + '/realshopItems?ShopId=' + ShopId;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async realshopArticules(ShopId) {
    let uri = 'https://' + this.ServerUrl + '/realshopArticules?ShopId=' + ShopId;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async realshopArrivals(ShopId) {
    let uri = 'https://' + this.ServerUrl + '/realshopArrivals?ShopId=' + ShopId;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async addRealshopArrival({ RealArticuleId, Count }) {
    let uri = 'https://' + this.ServerUrl + '/addRealshopArrival?RealArticuleId=' + RealArticuleId;
    uri += `&Count=${Count}`;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async realshopOrders(ShopId) {
    let uri = 'https://' + this.ServerUrl + '/realshopOrders?ShopId=' + ShopId;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async addRealshopItem({ ShopId, Title, Type, Cost, ArticuleTitle }) {
    let uri = 'https://' + this.ServerUrl + '/addRealshopItem?';
    uri += this.dataProcessToSearch({ ShopId, Title, Type, Cost, ArticuleTitle })
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async addRealshopOrder({ RealArticuleId, Count = 1, InnerCode }) {
    let uri = 'https://' + this.ServerUrl + '/addRealshopOrder?';
    uri += this.dataProcessToSearch({ RealArticuleId, Count })
    uri += `&sessionId=${this.lastSessionId}`;
    if (InnerCode) uri += `&InnerCode=${InnerCode}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // { RealOrderId, Status ?, Payment ?}
  async updateRealshopOrder(data) {
    let uri = 'https://' + this.ServerUrl + '/updateRealshopOrder?';
    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  //dataProcessToSearch

  // PAYMENTS ////////////////////////////////////////////
  // { PaymentKey, ObjectId, ObjectType }
  async getPayments(data) {
    let uri = 'https://' + this.ServerUrl + '/getPayments?';
    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // { SubjectType, SubjectId, ObjectType, ObjectId, Day, Month, Year, Type, PaymentKey, Value }
  async addPayment(data) {
    let uri = 'https://' + this.ServerUrl + '/addPayment?';
    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri, { timeout: 2500 });
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  //PaymentId
  async deletePayment(data) {
    let uri = 'https://' + this.ServerUrl + '/deletePayment?';
    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri, { timeout: 2500 });
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }


  // QUESTS ////////////////////////////////////////////

  async getUserQuests(UserId = null, Status = null, UserQuestId = null) {
    let uri = 'https://' + this.ServerUrl + '/getUserQuests?qqq=qqq';
    if (UserQuestId) uri += `&UserQuestId=${UserQuestId}`;
    if (UserId) uri += `&UserId=${UserId}`;
    if (Status) uri += `&Status=${Status}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getQuests({ QuestId = null, PlaceType = null, PlaceId }) {
    let uri = 'https://' + this.ServerUrl + '/getQuests?qqq=123';
    if (QuestId) uri += `&QuestId=${QuestId}`;
    if (PlaceType) uri += `&PlaceType=${PlaceType}`;
    if (PlaceId) uri += `&PlaceId=${PlaceId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getQuestTasks(QuestId) {
    let uri = 'https://' + this.ServerUrl + '/getQuestTasks?';
    uri += `QuestId=${QuestId}`;
    const answer = await this.standartQuestResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return {};
  }

  async mayITakeQuest(QuestId) {
    let uri = 'https://' + this.ServerUrl + '/mayITakeQuest?';
    uri += `QuestId=${QuestId}`;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartQuestResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return {};
  }

  async questProgress(UserQuestId) {
    let uri = 'https://' + this.ServerUrl + '/questProgress?';
    uri += `UserQuestId=${UserQuestId}`;
    const answer = await this.standartQuestResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return {};
  }

  async takeQuest(QuestId, AddSettings) {
    let uri = 'https://' + this.ServerUrl + '/takeQuest?';
    uri += `QuestId=${QuestId}`;
    if (AddSettings) uri += `&AddSettings=${AddSettings}`;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartQuestResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return {};
  }

  async removeQuest(UserQuestId) {
    let uri = 'https://' + this.ServerUrl + '/removeQuest?';
    uri += `UserQuestId=${UserQuestId}`;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartQuestResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return {};
  }

  async releaseQuest(UserQuestId) {
    let uri = 'https://' + this.ServerUrl + '/releaseQuest';
    uri += `?UserQuestId=${UserQuestId}`;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // FOLLOWS ///////////////////////////////////////////
  //Subject, ToSubject, ToSubjectId
  async addFollow(data) {
    let uri = 'https://' + this.ServerUrl + '/addFollow?';
    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }


  // SCORES
  async getUserScores(UserId) {
    let uri = 'https://' + this.ServerUrl + '/getUserScores?';
    uri += `&UserId=${UserId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getScoresInEvent(EventId, Label) {
    let uri = 'https://' + this.ServerUrl + '/getScoresInEvent?';
    uri += `EventId=${EventId}`;
    uri += `&Label=${Label}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getFizuhaScores() {
    let uri = 'https://' + this.ServerUrl + '/getFizuhaScores';
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  // RECOVERY
  async userRecoveryPassword(userId) {
    let uri = 'https://' + this.ServerUrl + '/recoveryPassword?';
    uri += `userId=${userId}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const res = await axios.get(uri);
    return (res && res.data) ? res.data : {};
  }

  // ITEMS
  async getAllUserItems(UserId, ItemName = null) {
    let uri = 'https://' + this.ServerUrl + '/getAllUserItems?';
    uri += `&UserId=${UserId}`;
    if (ItemName) uri += `&ItemName=${ItemName}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async updateUserItem(ItemId, IsOn) {
    let uri = 'https://' + this.ServerUrl + '/updateUserItem?';
    uri += `&ItemId=${ItemId}`;
    uri += `&IsOn=${IsOn}`;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async getUserFkrAccred(UserId) {
    let uri = 'https://' + this.ServerUrl + '/getUserFkrAccred?';
    uri += `&UserId=${UserId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async mayUserParticipateByFkr({ UserId, EventId }) {
    let uri = 'https://' + this.ServerUrl + '/mayUserParticipateByFkr?';
    uri += `&UserId=${UserId}`;
    uri += `&EventId=${EventId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  async openChest( ChestId) {
    let uri = 'https://' + this.ServerUrl + '/openChest?';
    uri += `&ChestId=${ChestId}`;
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  //Subject, ToSubject, ToSubjectId
  async getFollows(data) {
    let uri = 'https://' + this.ServerUrl + '/getFollows?';
    uri += this.dataProcessToSearch(data);
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }

  //FollowId
  async deleteFollow({ FollowId }) {
    let uri = 'https://' + this.ServerUrl + '/deleteFollow?';
    uri += this.dataProcessToSearch({ FollowId });
    uri += `&sessionId=${this.lastSessionId}`;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return answer.result;
  }


  async hwConstructor({ ActionData, Code }) {
  let uri = 'https://' + this.ServerUrl + `/hwConstructor?sessionId=${this.lastSessionId}`;
  if (ActionData) uri += '&ActionData=' + ActionData;
  if (Code) uri += '&Code=' + Code;

  const answer = await this.standartDataResult(uri, { timeout: 1500 });
  if (!answer.error) {
    return answer.result;
  }
  return [];
}

  async serverRegisterNewPerson(data) {
  //const sessionId = sessionId_ ? sessionId_ : this.state.sessionId ? this.state.sessionId : null;
  let uri = 'https://' + this.ServerUrl + '/insert' +
    '?login=' + data.login +
    '&password=' + data.password +
    '&name=' + data.name +
    '&surname=' + data.surname +
    '&patronymic=' + data.patronymic +
    '&gender=' + data.gender +
    '&birthday=' + data.birthday +
    '&email=' + data.email +
    '&cordao=' + data.cordao +
    '&schoolId=' + data.schoolId +
    (data.city ? '&city=' + data.city : '') +
    (data.apelido ? '&apelido=' + data.apelido : '') +
    (data.vk ? '&vk=' + data.vk : '') +
    (data.instagram ? '&instagram=' + data.instagram : '') +
    (data.tel ? '&tel=' + data.tel : '') +
    (data.groupId ? '&groupId=' + data.groupId : '') +
    '';

  let result;

  try {
    const res = await axios.get(uri);
    const { data } = res.data || {};
    if (!data) {
      result = null;
    } else {
      result = data;
    }
  }
  catch (err) {
    console.log('server error');
    result = null;
    return;
  }

  return result;
  //return competitors;
}



  // BRIKETS - запросы, нужные для брикетов

  // competitors_first- это просто список тех, кто в данной категории
  async briketsFirstCompetitiors({ competitionId, selectedCategory }) {
  const uri1 = 'https://' + this.ServerUrl + '/getCompetitionBrikets?championshipId=' + competitionId + '&categoryId=' + selectedCategory;
  let competitors_first_;

  try {
    const res = await axios.get(uri1);
    const { data } = res || [];
    if (!data) {
      competitors_first_ = [];
    }

    if (data.status === 'error') {
      competitors_first_ = [];
    }

    competitors_first_ = data.result.data.result ? clone(data.result.data.result) : [];
  }
  catch (err) {
    competitors_first_ = []
  }

  return competitors_first_;

}

  // story - это собственно массив объектов из BD
  async briketsGetStory({ competitionId, selectedCategory }) {
  const uri = 'https://' + this.ServerUrl + '/getstory?competitionId=' + competitionId + '&categoryId=' + selectedCategory;
  let story_;

  try {
    const res = await axios.get(uri);
    const { data } = res || [];
    if (!data) {
      story_ = [];
    }

    if (data.status === 'error') {
      story_ = [];
    }

    story_ = data.result.data.result || [];
  }
  catch (err) {
    story_ = [];
  }

  return story_;

}



// UTILS ВСЯКИЕ !!!!!!!!!!!!!!!!!!!!!! /////////////////////////

dataProcessToSearch = (data) => {
  const query = Object.keys(data).reduce(
    (memo, key, index) => {
      if (index === 0) return `${key}=${data[key]}`;
      return memo + `&${key}=${data[key]}`
    },
    ''
  )
  return query;
}

  async standartQuestResult(uri) {
  try {
    const res = await axios.get(uri);
    const { data } = res || {};

    if (!data || data.error) {
      return {
        result: {},
        error: data.error
      };
    }

    return {
      result: data,
      error: null
    };

  }
  catch (err) {
    console.log('server error');
    return {
      result: {},
      error: 'Some error'
    };
  }
}

  async standartDataResult(uri, options = {}) {

  let result_ = [];

  try {
    const res = await axios.get(uri, options);
    const { result } = res.data || {};
    if (!result) {
      result_ = [];
    } else {

      if (result.status === 'Sucess') {
        result_ = result.data;
      } else {
        result_ = [];
      }
    }


  }
  catch (err) {
    console.log('server error');
    result_ = [];
    return {
      result: result_,
      error: 'Some error'
    };
  }

  return {
    result: result_,
    error: null
  };

}

addNewEvent(data) {
  //let events = Object.create(this.state.events);
  //this.state = { ...this.state, events : events };
  /*this.state.events[6] = {
      FirstDate : '30.12.2018',
      SecondDate : null,  
      Name : 'Тестовые соревнования',
      Id : 6, 
      Type: 'championship',
      City: 'Москва',
      Place: 'ул. Маршала Бирюзова, 2'
  };*/
}

}