import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';

/**
 * Это враппер, который обвешивается вокруг компонента,
 * принимает eventId (кого брать)
 * component - то куда запихивать
 * componentProps - он в component пихает все пропсы, которые нужны ему
 * 
 * и когда загрузит нужный Event - закидывает его как объект
 * 
 * withChampionship - подгрузить соревнование (если оно есть)
 * withCategorys - подгрузить еще и категории
 * withStatistics - закинуть в EventChampionship статистику CompetitorsCount
 * 
 */

class EventWrapper extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            Event: null,
            EventChampionship: null,
            EventCategorys: null,

        };
    }

    componentDidMount() {
        const { eventId } = this.props;
        this.getEvent(eventId);
    }


    getEvent = async (id) => {
        if (!id) return;
        const Event = this.props.client.getEventById(id);

        if (!Event) return null;

        let result = { Event };

        if (this.props.withChampionship && Event.ChampionshipId) {

            const { client } = this.props;

            const EventChampionship = await client.getChampionshipFromServer(Event.ChampionshipId);

            if (this.props.withCategorys) {
                const EventCategorys = await client.getCategorysFromServer(Event.ChampionshipId);
                result.EventCategorys = EventCategorys;
            }

            result.EventChampionship = EventChampionship;

            if (this.props.withStatistics) {
                const CompetitorsCount = await this.countStatistics (Event.ChampionshipId);
                result.EventChampionship.CompetitorsCount = CompetitorsCount;
            }


        }

        this.setState(result)
    }

    countStatistics = async (ChampionshipId) => {
        const { client } = this.props;
        const Statistics = await client.getCompetittionStatistics(ChampionshipId);
        if (!Statistics || !Statistics.length) return null;

        const result = Statistics.reduce( (sum, item) => { return sum + item.Count }, 0 );
        return result;
    }

    componentWillReceiveProps({ eventId, eventsLoaded }) {
        if (eventId !== this.props.eventId || eventsLoaded !== this.props.eventsLoaded) {
            this.getEvent(eventId)
        }
    }

    render() {
        const {
            props: {
                Wrapped,
                componentProps
            },
            state: {
                Event,
                EventChampionship,
                EventCategorys
            }
        } = this;

        return (
            <Wrapped {...{ Event, EventChampionship, EventCategorys, ...componentProps }} />
        );
    }
}


export default withCapoeiraSportState('client, eventsLoaded')(EventWrapper);