import React from 'react';

const NO_MY_SCHOOL_IN_REGION = {
    title: 'Почему в регионе не указана моя школа?',
    value: <>
        В регоине отображаются только те школы, в состав которых входят группы, выступающие от данного региона.<br />
        Для создания группы Вам необходимо обратиться к администратору портала:<br />
        <br />
        Чушев Сергей <br />
        <a href='https://wa.me/79267674316?text=По+вопросу+добавления+группы+на+портал'>8 926 767 43 16</a> <br />
    </>
}

export default { NO_MY_SCHOOL_IN_REGION }