export default {
    toy_cash_1: {
        grade: 1, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_cash_1.png`,
        title: 'Парочка кашишей',
        discription: 'У каждого уважающего себя капоэйриста всегда есть парочка кашишей на полке',
        
        w: 1, h: 1, shiftX: 0, shiftY: 0   
    },

    toy_catb_2: {
        grade: 2, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_catb_2.png`,
        title: 'Котик-капоэйрист с беримбао',
        discription: 'Милый котик, умеет играть бенгелу и анголу',
        
        w: 2, h: 2, shiftX: 0, shiftY: 1 
    },

    toy_catp_2: {
        grade: 2, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_catp_2.png`,
        title: 'Котик-капоэйрист с пандейро',
        discription: 'Милый котик, умеет играть бенгелу и анголу',
        
        w: 2, h: 2, shiftX: 0, shiftY: 1 
    },

    toy_man1_2: {
        grade: 2, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_man1_2.png`,
        title: 'Игрушка-капоэйрист',
        discription: 'Маленький человечек с беримбао отлично зайдёт Вам на стену',
        
        w: 1, h: 2, shiftX: 0, shiftY: 1 
    },

    toy_man2_2: {
        grade: 2, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_man2_2.png`,
        title: 'Игрушка-капоэйрист',
        discription: 'Маленький человечек с беримбао отлично зайдёт Вам на стену',
        
        w: 1, h: 2, shiftX: 0, shiftY: 1 
    },
    
    toy_man3_3: {
        grade: 3, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_man3_3.png`,
        title: 'Игрушка-капоэйрист',
        discription: 'Премиальная игрушка, такого можно получить только в ивентах и островах на портале',
        
        w: 1, h: 2, shiftX: 0, shiftY: 1 
    },

    toy_man4_3: {
        grade: 3, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_man4_3.png`,
        title: 'Игрушка-капоэйрист',
        discription: 'Премиальная игрушка, такого можно получить только в ивентах и островах на портале',
        
        w: 1, h: 2, shiftX: 0, shiftY: 1 
    },

    toy_man5_3: {
        grade: 3, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_man5_3.png`,
        title: 'Игрушка-капоэйрист',
        discription: 'Премиальная игрушка, такого можно получить только в ивентах и островах на портале',
        
        w: 1, h: 2, shiftX: 0, shiftY: 1 
    },

    toy_catb_3: {
        grade: 3, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_catb_3.png`,
        title: 'Котик-капоэйрист с беримбао',
        discription: 'Милый котик, умеет играть бенгелу, анголу, сао-бенту и иуну',
        
        w: 2, h: 2, shiftX: 0, shiftY: 1 
    },

    toy_catp_3: {
        grade: 3, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_catp_3.png`,
        title: 'Котик-капоэйрист с пандейро',
        discription: 'Милый котик, умеет играть бенгелу, анголу',
        
        w: 2, h: 2, shiftX: 0, shiftY: 1 
    },
    toy_cata_3: {
        grade: 3, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_cata_3.png`,
        title: 'Котик-капоэйрист с атабаком',
        discription: 'Милый котик, умеет играть бенгелу, анголу',
        
        w: 2, h: 2, shiftX: 0, shiftY: 1 
    },

    toy_catb_4: {
        grade: 4, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_catb_4.png`,
        title: 'Котик-капоэйрист с беримбао',
        discription: 'Премиальный котик, такого можно получить только в ивентах и островах на портале',
        
        w: 2, h: 2, shiftX: 0, shiftY: 1 
    },
    toy_cata_4: {
        grade: 4, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_cata_4.png`,
        title: 'Котик-капоэйрист с атабаком',
        discription: 'Премиальный котик, такого можно получить только в ивентах и островах на портале',
        
        w: 2, h: 2, shiftX: 0, shiftY: 1 
    },
    toy_catp_4: {
        grade: 4, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_catp_4.png`,
        title: 'Котик-капоэйрист с пандейро',
        discription: 'Премиальный котик, такого можно получить только в ивентах и островах на портале',
        
        w: 2, h: 2, shiftX: 0, shiftY: 1 
    },
    toy_catg_4: {
        grade: 4, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_catg_4.png`,
        title: 'Котик-капоэйрист с агого',
        discription: 'Премиальный котик, такого можно получить только в ивентах и островах на портале',
        
        w: 2, h: 2, shiftX: 0, shiftY: 1 
    },

    
    toy_cloud_3: {
        grade: 3, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_cloud_3.png`,
        title: 'Безмятежное облачко',
        discription: 'Игрушка-облако, никак не связанная с капоэйрой. Просто ми-ми-ми',
        
        w: 3, h: 2, shiftX: 0, shiftY: 1 
    },

    toy_cloud_4: {
        grade: 4, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_cloud_4.png`,
        title: 'Грозовое облачко',
        discription: 'Синяя игрушка-облако, никак не связанная с капоэйрой. Просто ми-ми-ми',
        
        w: 3, h: 2, shiftX: 0, shiftY: 1 
    },

    toy_cloudp_4: {
        grade: 4, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_cloudp_4.png`,
        title: 'Тусовое облачко',
        discription: 'Пурпурная игрушка-облако, никак не связанная с капоэйрой. Просто ми-ми-ми',
        
        w: 3, h: 2, shiftX: 0, shiftY: 1 
    },

    toy_bart_5: {
        grade: 5, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_bart_5.png`,
        title: 'Барт-капоэйрист',
        discription: 'Игрушка Барт Симпсон, выполняющий стоп-жингу. Такой сувенир можно приобрести только за донатный ресурс',
        
        w: 1, h: 3, shiftX: 0, shiftY: 2 
    },

    toy_donate_5: {
        grade: 5, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_donate_5.png`,
        title: 'Эгида CapoeiraSport',
        discription: 'Символизирует огромную благодарность за материальную помощь порталу. Такой сувенир можно приобрести только за донатный ресурс',
        
        w: 2, h: 2, shiftX: 0, shiftY: 1 
    },

    toy_sharka_5: {
        grade: 5, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_sharka_5.png`,
        title: 'Акула-капоэйрист',
        discription: 'Игрушка Акула-капоэйрист с атабаком. Вырывается из пучин, чтобы выдать свой бит! Такой сувенир можно приобрести только за донатный ресурс',
        
        w: 2, h: 3, shiftX: 0, shiftY: 2 
    },

    toy_sharkb_5: {
        grade: 5, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/toy_sharkb_5.png`,
        title: 'Акула-капоэйрист',
        discription: 'Игрушка Акула-капоэйрист с беримбао. Скалит зубы или же поёт? Такой сувенир можно приобрести только за донатный ресурс',
        
        w: 2, h: 3, shiftX: 0, shiftY: 2 
    },

    kmo_egid_1: {
        grade: 1, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/kmo_egid_1.png`,
        title: 'Эгида Кубка Москвы',
        discription: 'Этот предмет фигурирует в ивенте в честь Кубка Москвы 2022',
        
        w: 1, h: 1, shiftX: 0, shiftY: 0 
    },

    kmo_egid_2: {
        grade: 2, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/kmo_egid_2.png`,
        title: 'Экстра Эгида Кубка Москвы',
        discription: 'Этот продвинутый предмет фигурирует в ивенте в честь Кубка Москвы 2022',
        
        w: 1, h: 1, shiftX: 0, shiftY: 0 
    },

    kmo_lama_4: {
        grade: 4, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/kmo_lama_4.png`,
        title: 'Игрушка Лама',
        discription: 'Этот классная лама выпущена в рамках ивента в честь Кубка Москвы 2022',
        
        w: 2, h: 2, shiftX: 0, shiftY: 1 
    },

    island_1_revord: {
        grade: 2, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/island_1_revord.png`,
        title: 'Игрушечная башня',
        discription: 'Игрушка, которую Вы получили за прохождение Первого Острова',
        
        w: 1, h: 2, shiftX: 0, shiftY: 1 
    },

    island_3_demorevord: {
        grade: 2, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/island_3_demorevord.png`,
        title: 'Новогодняя лисица',
        discription: 'Игрушка, которую Вы получили за прохождение DEMO Тотема Совы',
        
        w: 2, h: 2, shiftX: 0, shiftY: 1 
    },

    pumc_1: {
        grade: 2, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/pumc_1.png`,
        title: 'Оранжевая тыква',
        discription: 'Оранжевая тыква в рамках Halloween 2024',
        
        w: 1, h: 1, shiftX: 0, shiftY: 0 
    },

    pumc_2: {
        grade: 2, type: 'stuff', subtype: 'toy', src: `/../images/ver2/scores/stuff/pumc_2.png`,
        title: 'Красная тыква',
        discription: 'Красная тыква в рамках Halloween 2024',
        
        w: 1, h: 1, shiftX: 0, shiftY: 0 
    },

    // w: 2, h: 2, shiftX: 0, shiftY: 1  
}