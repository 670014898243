export default {
    

    flagml_br2_5: {
        type: 'stuff',
        subtype: 'flag',
        grade: 5,
        title: 'Левый модуль',
        discription: 'Левая часть модульного флага Бразилии. Такой можно приобрести только за донатные ресурсы',
        src: `/../images/ver2/scores/stuff/flagml_br2_5.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 0
    },
    flagmc_br2_5: {
        type: 'stuff',
        subtype: 'flag',
        grade: 5,
        title: 'Центр модуль',
        discription: 'Центральная часть модульного флага Бразилии. Такой можно приобрести только за донатные ресурсы',
        src: `/../images/ver2/scores/stuff/flagmc_br2_5.png`,

        w: 4,
        h: 4,
        shiftX: 0,
        shiftY: 0
    },
    flagmr_br2_5: {
        type: 'stuff',
        subtype: 'flag',
        grade: 5,
        title: 'Правый модуль',
        discription: 'Правая часть модульного флага Бразилии. Такой можно приобрести только за донатные ресурсы',
        src: `/../images/ver2/scores/stuff/flagmr_br2_5.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 0
    },




    flagl_br_b2: {
        type: 'stuff',
        subtype: 'flag',
        grade: 2,
        title: 'Синий флаг',
        discription: 'Синий Бразильский флаг! Спокойный и сильный цвет. Он призван расслаблять и приносить ощущение мира и гармонии',
        src: `/../images/ver2/scores/stuff/flagl_br_b2.png`,

        w: 2,
        h: 5,
        shiftX: 0,
        shiftY: 0
    },

    flagb_br_b2: {
        type: 'stuff',
        subtype: 'flag',
        grade: 2,
        title: 'Синий флаг',
        discription: 'Синий большой Бразильский флаг! Спокойный и сильный цвет. Он призван расслаблять и приносить ощущение мира и гармонии',
        src: `/../images/ver2/scores/stuff/flagb_br_b2.png`,

        w: 6,
        h: 3,
        shiftX: 0,
        shiftY: 0
    },

    flagl_br_g1: {
        type: 'stuff',
        subtype: 'flag',
        grade: 1,
        title: 'Зелёный флаг',
        discription: 'Зелёный Бразильский флаг! Зелёный цвет символизирует дружелюбие и открытость.',
        src: `/../images/ver2/scores/stuff/flagl_br_g1.png`,

        w: 2,
        h: 5,
        shiftX: 0,
        shiftY: 0
    },

    flagb_br_g1: {
        type: 'stuff',
        subtype: 'flag',
        grade: 1,
        title: 'Зелёный флаг',
        discription: 'Зелёный большой Бразильский флаг! Зелёный цвет символизирует дружелюбие и открытость.',
        src: `/../images/ver2/scores/stuff/flagb_br_g1.png`,

        w: 6,
        h: 3,
        shiftX: 0,
        shiftY: 0
    },

    flagl_br_y3: {
        type: 'stuff',
        subtype: 'flag',
        grade: 3,
        title: 'Желтый флаг',
        discription: 'Желтый Бразильский флаг! Желтый цвет символизирует целеустремлённость и уверенность в себе.',
        src: `/../images/ver2/scores/stuff/flagl_br_y3.png`,

        w: 2,
        h: 5,
        shiftX: 0,
        shiftY: 0
    },

    flagb_br_y3: {
        type: 'stuff',
        subtype: 'flag',
        grade: 3,
        title: 'Желтый флаг',
        discription: 'Желтый большой Бразильский флаг! Желтый цвет символизирует целеустремлённость и уверенность в себе.',
        src: `/../images/ver2/scores/stuff/flagb_br_y3.png`,

        w: 6,
        h: 3,
        shiftX: 0,
        shiftY: 0
    },

    flagl_br_r4: {
        type: 'stuff',
        subtype: 'flag',
        grade: 4,
        title: 'Красный флаг',
        discription: 'Красный Бразильский флаг! Яркий красный цвет подчеркнёт Ваш боевой настрой и силу духа!',
        src: `/../images/ver2/scores/stuff/flagl_br_r4.png`,

        w: 2,
        h: 5,
        shiftX: 0,
        shiftY: 0
    },

    flagb_br_w4: {
        type: 'stuff',
        subtype: 'flag',
        grade: 4,
        title: 'Белый флаг',
        discription: 'Белый большой Бразильский флаг! Прекрасно подойдёт Вашей стене, если Вы формируете её в светлом стиле.',
        src: `/../images/ver2/scores/stuff/flagb_br_w4.png`,

        w: 6,
        h: 3,
        shiftX: 0,
        shiftY: 0
    },

    flagb_trefa_4: {
        type: 'stuff',
        subtype: 'flag',
        grade: 4,
        title: 'Trefas флаг',
        discription: 'Эксклюзивный флаг группы Trefas! Можно приобрести на странице группы. Тёмно-красный с оранжеввым великолепно дополнят насыщенность Вашей стены!',
        src: `/../images/ver2/scores/stuff/flagb_trefa_4.png`,

        w: 6,
        h: 3,
        shiftX: 0,
        shiftY: 0
    },

    flagl_trefa_4: {
        type: 'stuff',
        subtype: 'flag',
        grade: 4,
        title: 'Trefas флаг',
        discription: 'Эксклюзивный большой флаг группы Trefas! Можно приобрести на странице группы. Тёмно-красный с оранжеввым великолепно дополнят насыщенность Вашей стены!',
        src: `/../images/ver2/scores/stuff/flagl_trefa_4.png`,

        w: 2,
        h: 5,
        shiftX: 0,
        shiftY: 0
    },

    flagb_escalada_4: {
        type: 'stuff',
        subtype: 'flag',
        grade: 4,
        title: 'Escalada флаг',
        discription: 'Эксклюзивный флаг группы Escalada! Можно приобрести на странице группы. Яркий, цветастый, как представители данной группы!',
        src: `/../images/ver2/scores/stuff/flagb_escalada_4.png`,

        w: 6,
        h: 3,
        shiftX: 0,
        shiftY: 0
    },

    flagl_escalada_4: {
        type: 'stuff',
        subtype: 'flag',
        grade: 4,
        title: 'Escalada флаг',
        discription: 'Эксклюзивный большой флаг группы Escalada! Можно приобрести на странице группы. Яркий, цветастый, как представители данной группы!',
        src: `/../images/ver2/scores/stuff/flagl_escalada_4.png`,

        w: 2,
        h: 5,
        shiftX: 0,
        shiftY: 0
    },

    flagb_tlt_4: {
        type: 'stuff',
        subtype: 'flag',
        grade: 4,
        title: 'Lobo Branco Group флаг',
        discription: 'Эксклюзивный флаг группы Lobo Branco Group! Можно приобрести на странице группы. Строгий и стильный!',
        src: `/../images/ver2/scores/stuff/flagb_tlt_4.png`,

        w: 6,
        h: 3,
        shiftX: 0,
        shiftY: 0
    },

    flagl_tlt_4: {
        type: 'stuff',
        subtype: 'flag',
        grade: 4,
        title: 'Lobo Branco Group флаг',
        discription: 'Эксклюзивный большой флаг группы Lobo Branco Group! Можно приобрести на странице группы. Строгий и стильный!',
        src: `/../images/ver2/scores/stuff/flagl_tlt_4.png`,

        w: 2,
        h: 5,
        shiftX: 0,
        shiftY: 0
    },

    // ВЕСЕННИЙ ЧЕЛЛЕНДЖ
    flagl_spring_g1: {
        type: 'stuff',
        subtype: 'flag',
        grade: 3,
        title: 'Весенний флаг',
        discription: 'Эксклюзивный флаг для закрывшых Весенний челлендж 2023!',
        src: `/../images/ver2/scores/stuff/flagl_spring_g1.png`,

        w: 2,
        h: 5,
        shiftX: 0,
        shiftY: 0
    },

    flagl_spring_r2: {
        type: 'stuff',
        subtype: 'flag',
        grade: 4,
        title: 'Весенний флаг',
        discription: 'Эксклюзивный флаг для набравших более 220 очков на Весеннем челлендже 2023!',
        src: `/../images/ver2/scores/stuff/flagl_spring_r2.png`,

        w: 2,
        h: 5,
        shiftX: 0,
        shiftY: 0
    },

    flagb_alex_4: {
        type: 'stuff',
        subtype: 'flag',
        grade: 4,
        title: 'Aleksandrov флаг',
        discription: 'Эксклюзивный флаг группы Aleksandrov! Можно приобрести на странице группы',
        src: `/../images/ver2/scores/stuff/flagb_alex_4.png`,

        w: 6,
        h: 3,
        shiftX: 0,
        shiftY: 0
    },

    flagb_corgi_4: {
        type: 'stuff',
        subtype: 'flag',
        grade: 4,
        title: 'Corgi флаг',
        discription: 'Эксклюзивный очаровательный флаг группы Corgi! Можно приобрести на странице группы',
        src: `/../images/ver2/scores/stuff/flagb_corgi_4.png`,

        w: 6,
        h: 3,
        shiftX: 0,
        shiftY: 0
    },

    shufic_1: {
        type: 'stuff',
        subtype: 'flag',
        grade: 1,
        title: 'Шуфик календарь',
        discription: 'Специальный календарь в честь 3 сентября! Мы все знаем, что нужно сделать в этот день!',
        src: `/../images/ver2/scores/stuff/shufic_1.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 0
    },

    chushev_1: {
        type: 'stuff',
        subtype: 'flag',
        grade: 5,
        title: 'Мотивационный плакат',
        discription: 'Специальный плакат, который призван мотивировать тебя на дальнейшие свершения!',
        src: `/../images/ver2/scores/stuff/chushev_1.png`,

        w: 2,
        h: 3,
        shiftX: 0,
        shiftY: 0
    },

    // КУБОК МОСКВЫ

    kmo_flagl_b4: {
        type: 'stuff',
        subtype: 'flag',
        grade: 4,
        title: 'Синий флаг Кубка Москвы',
        discription: 'Синий флаг в рамках ивента в честь Кубка Москвы 2022',
        src: `/../images/ver2/scores/stuff/kmo_flagl_b4.png`,

        w: 2,
        h: 5,
        shiftX: 0,
        shiftY: 0
    },
    kmo_flagl_y4: {
        type: 'stuff',
        subtype: 'flag',
        grade: 4,
        title: 'Желтый флаг Кубка Москвы',
        discription: 'Желтый флаг в рамках ивента в честь Кубка Москвы 2022',
        src: `/../images/ver2/scores/stuff/kmo_flagl_y4.png`,

        w: 2,
        h: 5,
        shiftX: 0,
        shiftY: 0
    },


}