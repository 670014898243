import React from 'react';


export const PORTAL_CATEGORYS_TEXTES = {
    'creator': <span>
        Ведущий (и единственный :) ) разработчик портала capoeirasport.com <br />
        Если у вас есть любые предложения по улучшению, информация и недоработках или тёплные пожелания, вы можете адресовать их сюда!
    </span>,
    'admin': 'Администратор портала',
    'sportmanager': <span>
        Занимается подтверждение спортивных разрядов <br /><br />
        Когда Вы подаёте заявку на разряд, данный человек сверяет их с базой разрядов ФКР <br /><br />
        Вы можете адресовать сюда любые вопросы, связанные со спортивными или судейскими разрядами!
    </span>
}


    //  "BatizadoMain": {
    //     "cordaoParam": "orgvalue_900",
    //     "cordaoKeys": {
    //         "1": "kids premiero",
    //         "2": "kids calouro 1",
    //         "3": "kids calouro 2",
    //         "4": "kids aluno 1",
    //         "5": "kids aluno 2",
    //         "6": "kids aluno forca 1",
    //         "7": "kids aluno forca 2",
    //         "8": "kids batizado",
    //         "9": "kids batizado forca",
    //         "10": "kids graduado",

    //         "11": "gray",
    //         "12": "aluno",
    //         "13": "aluno forca",
    //         "14": "batizado",
    //         "15": "batizado forca",
    //         "16": "graduado",
    //         "17": "graduado forca",
    //         "18": "formado",
    //         "19": "formado forca",
    //         "20": "monitor",
    //         "21": "instructor",
    //         "22": "professor"
    //     }
    //  } 
 