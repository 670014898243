import React, { PureComponent } from 'react';

import { withGroupState } from 'src/ver2/pages/group/context';

import Loader from 'src/ver2/components/loader';
import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import { TrefaLayout } from 'src/ver2/containers/layout';
import PurposerComponent from 'src/ver2/components/purpose';
import ButtonPlashka from 'src/ver2/plashki/ui/button';

import { message } from 'antd';



// import { Link } from 'react-router-dom';

import './style.css';

// class EventContactsPlashka
// classname: event_purpose_component

/**
  Это плашка в которой мы назначаем инструктора. Для начала это будут только контакты
 */

class GroupPurpose extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        removeMode: false
    }

    toggleRemoveMode = () => this.setState({ removeMode: !this.state.removeMode });

    onPersonClick = (Id) => {
        message.info(`Добавляем пользователя ${Id}`);
        const { addInstructor } = this.props;
        addInstructor({ UserId: Id, Role: 'instructor' });
    }

    onRemovePersonClick = (Id) => {
        const { removeMode } = this.state;
        const { Instructors } = this.props;
        const { deleteInstructor } = this.props;

        if (!removeMode) return;

        const instructor = Instructors.find(x => x.UserId === Id);

        if (instructor)
            message.info(`Удаляем роль ${instructor.Id}`);
        deleteInstructor(instructor.Id);
        return;
    }

    render() {

        const { isInstructorsReady, Instructors } = this.props;
        const { removeMode } = this.state;

        const InstructorsIds = (Instructors && Instructors.length) ?
            Instructors.map(({ UserId }) => { return { Id: UserId } })
            : []


        if (!isInstructorsReady) return <div className='event_purpose_component'>
            <Loader text='загрузка инструкторов' />
        </div>

        const width = 700;

        return <div className='event_purpose_component'>

            <div className='event_purpose_component_header'>Инструкторы</div>
            <div className='event_purpose_component_purposer'>
                {removeMode ?
                    <>Нажмите на пользователе для удаления</>
                    :
                    (InstructorsIds.length > 1 ?
                        <>Можно добавить не более двух инструкторов</>
                        :
                        <PurposerComponent mode={'contact_noclick'} onPersonClick={this.onPersonClick} allreadyPersons={InstructorsIds} />
                    )}
            </div>

            <TrefaLayout greedsX={[5, width - 10, 5]}
                components={[
                    {
                        component: PersonsLittleListPlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 8888,
                        componentProps: { height: 100, Persons: InstructorsIds, autoSize: true, mode: removeMode ? 'contact_noclick' : 'contact', onPersonClick: this.onRemovePersonClick }

                    }
                ]}
            />

            <br />
            <ButtonPlashka text={removeMode ? 'ВКЛЮЧИТЬ РЕЖИМ ДОБАВЛЕНИЯ' : 'ВКЛЮЧИТЬ РЕЖИМ УДАЛЕНИЯ'} upperStyle={{ width: 270, height: 50 }} onClick={this.toggleRemoveMode} />


        </div>
    }

}


export default withGroupState(`
Instructors, 
isInstructorsReady,
addInstructor,
deleteInstructor
`)(GroupPurpose)