import React, { Component } from 'react';
import { gsap } from 'gsap';
import { navIcon } from 'src/ver2/components/ui/icons';
import { Tooltip } from 'antd';

import './style.css';

/*

    columns = {
        count = 0 -> 1

    }

*/

export class Mountin extends Component {

    constructor(props) {
        super(props);
        const idGenerate = (Math.random() * 2000).toFixed(0);
        this.thisId = `MountinId${idGenerate}`;
    }

    componentDidMount() {
        const tl = gsap.timeline({ repeat: 0 });

        tl.fromTo(`#${this.thisId}>.axes_line_base1`, { opacity: 0, scale: 0, transformOrigin: 'center bottom' }, { opacity: 1, scale: 1, duration: 0.6, ease: 'back' })
            .fromTo(`#${this.thisId}>.axes_line_base2`, { opacity: 0, scale: 0, transformOrigin: 'left top' }, { opacity: 1, scale: 1, duration: 0.6, ease: 'back' }, 0.2)
            .fromTo(`#${this.thisId}>.axes_line_base3`, { opacity: 0, scale: 0, transformOrigin: 'right top' }, { opacity: 1, scale: 1, duration: 0.6, ease: 'back' }, 0.3)

            .fromTo(`#${this.thisId}>.axes_line_helpers_1`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 2.5, yoyo: true, ease: 'elastic' }, 0.6)
            .fromTo(`#${this.thisId}>.axes_line_helpers_2`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 2.5, yoyo: true, ease: 'elastic' }, 0.8)
            .fromTo(`#${this.thisId}>.axes_line_helpers_3`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 2.5, yoyo: true, ease: 'elastic' }, 0.9)
            .fromTo(`#${this.thisId}>.axes_line_helpers_4`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 2.5, yoyo: true, ease: 'elastic' }, 1)
            .from(`#${this.thisId}>.axes_line_helpers_1`, { rotate: 30 }, 0.6)
            .from(`#${this.thisId}>.axes_line_helpers_2`, { rotate: 45 }, 0.8)
            .from(`#${this.thisId}>.axes_line_helpers_3`, { rotate: 60 }, 0.9)
            .from(`#${this.thisId}>.axes_line_helpers_4`, { rotate: 90 }, 1)

            .from(`#${this.thisId}>.pologonGraph_cr1`, { cx: 50, cy: 50, duration: 0.5 }, 1.6)
            .from(`#${this.thisId}>.pologonGraph_cr2`, { cx: 50, cy: 50, duration: 0.5 }, 1.7)
            .from(`#${this.thisId}>.pologonGraph_cr3`, { cx: 50, cy: 50, duration: 0.5 }, 1.8)

            .fromTo(`#${this.thisId}>.pologonGraph_cr1`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 0.5, ease: 'back' }, 1.6)
            .fromTo(`#${this.thisId}>.pologonGraph_cr2`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 0.5, ease: 'back' }, 1.7)
            .fromTo(`#${this.thisId}>.pologonGraph_cr3`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 0.5, ease: 'back' }, 1.8)
            .fromTo(`#${this.thisId}>.pologonGraph`, { opacity: 0 }, { opacity: 1, duration: 0.5 }, 2.5)
            .fromTo(`#${this.thisId}>.pologonGraph_line`, { opacity: 0, strokeWidth: 3 }, { opacity: 1, duration: 0.5 }, 2.5)
            .fromTo(`#${this.thisId}>.pologonGraph_line_polygon`, { opacity: 0 }, { opacity: 1, duration: 0.5 }, 2.5)

            .fromTo(`#${this.thisId}`, { rotateZ: -30, scale: 0.6, transformOrigin: 'center center' }, { rotateZ: 0, scale: 1, duration: 2.5, ease: 'expo' }, 0.5)

    }

    // pologonGraph_cr1

    render() {

        const { width, height, columns } = this.props;
        const centerX = 50,
            centerY = 50,
            lineLength = 50,
            lineLengthMax = 45,
            cos30 = 0.87,
            sin30 = 0.5,
            koefWidth = width / 100;


        const colors = [
            `rgb( ${255 - columns[0].count * 255 / 2}, ${columns[0].count * 255 / 1.2}, 0)`,
            `rgb( ${255 - columns[1].count * 255 / 2}, ${columns[1].count * 255 / 1.2}, 0)`,
            `rgb( ${255 - columns[2].count * 255 / 2}, ${columns[2].count * 255 / 1.2}, 0)`
        ]
        const colors2 = [
            `rgb( 255, 255, 255)`,
            `rgb( 255, 255, 255)`,
            `rgb( 255, 255, 255)`
        ]

        const dots = [
            { x: centerX, y: centerY - lineLengthMax * columns[0].count },
            { x: centerX + lineLengthMax * columns[1].count * cos30, y: centerY + lineLengthMax * sin30 * columns[1].count },
            { x: centerX - lineLengthMax * columns[2].count * cos30, y: centerY + lineLengthMax * sin30 * columns[2].count }
        ]

        const graphTriangle = () => {
            // рисуем background

            const axes = <React.Fragment>
                <line className='axes_line_base axes_line_base1' x1={centerX} y1={centerY} x2={centerX} y2={centerY - lineLength} />
                <line className='axes_line_base axes_line_base2' x1={centerX} y1={centerY} x2={centerX + lineLength * cos30} y2={centerY + lineLength * sin30} />
                <line className='axes_line_base axes_line_base3' x1={centerX} y1={centerY} x2={centerX - lineLength * cos30} y2={centerY + lineLength * sin30} />
            </React.Fragment>

            const oneTriangleAxes = (scale, index) => <React.Fragment>
                <polygon className={`axes_line_helpers axes_line_helpers_${index}`}
                    points={` 
                        ${centerX}, ${centerY - lineLengthMax * scale}  
                        ${centerX + lineLengthMax * scale * cos30}, ${centerY + lineLengthMax * sin30 * scale}  
                        ${centerX - lineLengthMax * scale * cos30}, ${centerY + lineLengthMax * sin30 * scale}
                    `}
                />
            </React.Fragment>

            return <React.Fragment>
                <linearGradient id={`grad1_${this.thisId}`} x1={dots[0].x} y1={dots[0].y} x2={dots[1].x} y2={dots[1].y} gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color={colors[0]} />
                    <stop offset="1" stop-color={colors[1]} />
                </linearGradient>
                <linearGradient id={`grad2_${this.thisId}`} x1={dots[1].x} y1={dots[1].y} x2={dots[2].x} y2={dots[2].y} gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color={colors[1]} />
                    <stop offset="1" stop-color={colors[2]} />
                </linearGradient>
                <linearGradient id={`grad3_${this.thisId}`} x1={dots[2].x} y1={dots[2].y} x2={dots[0].x} y2={dots[0].y} gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color={colors[2]} />
                    <stop offset="1" stop-color={colors[0]} />
                </linearGradient>

                {axes}
                {oneTriangleAxes(0.25, 1)}
                {oneTriangleAxes(0.5, 2)}
                {oneTriangleAxes(0.75, 3)}
                {oneTriangleAxes(1, 4)}

                <polygon className={`pologonGraph_line_polygon`}
                    points={` 
                        ${dots[0].x}, ${dots[0].y}  
                        ${dots[1].x}, ${dots[1].y}  
                        ${dots[2].x}, ${dots[2].y} 
                    `}
                />

                <line className='pologonGraph_line' style={{ stroke: `url(#grad1_${this.thisId})` }} x1={dots[0].x} y1={dots[0].y} x2={dots[1].x} y2={dots[1].y} />
                <line className='pologonGraph_line' style={{ stroke: `url(#grad2_${this.thisId})` }} x1={dots[1].x} y1={dots[1].y} x2={dots[2].x} y2={dots[2].y} />
                <line className='pologonGraph_line' style={{ stroke: `url(#grad3_${this.thisId})` }} x1={dots[2].x} y1={dots[2].y} x2={dots[0].x} y2={dots[0].y} />

                <Tooltip title={columns[0].tooltip} placement='top'>
                    <circle className='pologonGraph_cr1' style={{ stroke: colors[0], fill: colors2[0] }} cx={dots[0].x} cy={dots[0].y} r="3" />
                </Tooltip>
                <Tooltip title={columns[1].tooltip} placement='top'>
                    <circle className='pologonGraph_cr2' style={{ stroke: colors[1], fill: colors2[1] }} cx={dots[1].x} cy={dots[1].y} r="3" />
                </Tooltip>
                <Tooltip title={columns[2].tooltip} placement='top'>
                    <circle className='pologonGraph_cr3' style={{ stroke: colors[2], fill: colors2[2] }} cx={dots[2].x} cy={dots[2].y} r="3" />
                </Tooltip>
            </React.Fragment>

        }


        return <div className='mountin_container' style={{ width, height }}>
            <svg style={{ width, height }} version="1.1" viewBox="0 0 100 100" id={this.thisId}>
                {graphTriangle()}
            </svg>
            <div className='pologonGraph_text pologonGraph_text1' style={{ left: dots[0].x * koefWidth, top: dots[0].y * koefWidth }}> {columns[0].value} </div>
            <div className='pologonGraph_text pologonGraph_text2' style={{ left: dots[1].x * koefWidth, top: dots[1].y * koefWidth }}> {columns[1].value} </div>
            <div className='pologonGraph_text pologonGraph_text3' style={{ left: dots[2].x * koefWidth, top: dots[2].y * koefWidth }}> {columns[2].value} </div>
        </div>

    }
}



export class MountinQuadro extends Component {

    constructor(props) {
        super(props);
        const idGenerate = (Math.random() * 2000).toFixed(0);
        this.thisId = `MountinId${idGenerate}`;
    }

    componentDidMount() {
        const tl = gsap.timeline({ repeat: 0 });

        tl.fromTo(`#${this.thisId}>.axes_line_base1`, { opacity: 0, scale: 0, transformOrigin: 'center bottom' }, { opacity: 1, scale: 1, duration: 0.6, ease: 'back' })
            .fromTo(`#${this.thisId}>.axes_line_base2`, { opacity: 0, scale: 0, transformOrigin: 'left top' }, { opacity: 1, scale: 1, duration: 0.6, ease: 'back' }, 0.2)
            .fromTo(`#${this.thisId}>.axes_line_base3`, { opacity: 0, scale: 0, transformOrigin: 'center top' }, { opacity: 1, scale: 1, duration: 0.6, ease: 'back' }, 0.3)
            .fromTo(`#${this.thisId}>.axes_line_base4`, { opacity: 0, scale: 0, transformOrigin: 'right top' }, { opacity: 1, scale: 1, duration: 0.6, ease: 'back' }, 0.4)

            .fromTo(`#${this.thisId}>.axes_line_helpers_1`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 2.5, yoyo: true, ease: 'elastic' }, 0.6)
            .fromTo(`#${this.thisId}>.axes_line_helpers_2`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 2.5, yoyo: true, ease: 'elastic' }, 0.8)
            .fromTo(`#${this.thisId}>.axes_line_helpers_3`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 2.5, yoyo: true, ease: 'elastic' }, 0.9)
            .fromTo(`#${this.thisId}>.axes_line_helpers_4`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 2.5, yoyo: true, ease: 'elastic' }, 1)
            .from(`#${this.thisId}>.axes_line_helpers_1`, { rotate: 30 }, 0.6)
            .from(`#${this.thisId}>.axes_line_helpers_2`, { rotate: 45 }, 0.8)
            .from(`#${this.thisId}>.axes_line_helpers_3`, { rotate: 60 }, 0.9)
            .from(`#${this.thisId}>.axes_line_helpers_4`, { rotate: 90 }, 1)

            .from(`#${this.thisId}>.pologonGraph_cr1`, { cx: 50, cy: 50, duration: 0.5 }, 1.6)
            .from(`#${this.thisId}>.pologonGraph_cr2`, { cx: 50, cy: 50, duration: 0.5 }, 1.7)
            .from(`#${this.thisId}>.pologonGraph_cr3`, { cx: 50, cy: 50, duration: 0.5 }, 1.8)
            .from(`#${this.thisId}>.pologonGraph_cr4`, { cx: 50, cy: 50, duration: 0.5 }, 1.9)

            .fromTo(`#${this.thisId}>.pologonGraph_cr1`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 0.5, ease: 'back' }, 1.6)
            .fromTo(`#${this.thisId}>.pologonGraph_cr2`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 0.5, ease: 'back' }, 1.7)
            .fromTo(`#${this.thisId}>.pologonGraph_cr3`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 0.5, ease: 'back' }, 1.8)
            .fromTo(`#${this.thisId}>.pologonGraph_cr4`, { opacity: 0, scale: 0, transformOrigin: 'center center' }, { opacity: 1, scale: 1, duration: 0.5, ease: 'back' }, 2.0)

            .fromTo(`#${this.thisId}>.pologonGraph`, { opacity: 0 }, { opacity: 1, duration: 0.5 }, 2.5)
            .fromTo(`#${this.thisId}>.pologonGraph_line`, { opacity: 0, strokeWidth: 3 }, { opacity: 1, duration: 0.5 }, 2.5)
            .fromTo(`#${this.thisId}>.pologonGraph_line_polygon`, { opacity: 0 }, { opacity: 1, duration: 0.5 }, 2.5)

            .fromTo(`#${this.thisId}`, { rotateZ: -30, scale: 0.6, transformOrigin: 'center center' }, { rotateZ: 0, scale: 1, duration: 2.5, ease: 'expo' }, 0.5)

    }

    // pologonGraph_cr1

    render() {

        const { width, height, columns } = this.props;
        const centerX = 50,
            centerY = 50,
            lineLength = 50,
            lineLengthMax = 45,
            koefWidth = width / 100;


        const colors = [
            `rgb( ${255 - columns[0].count * 255 / 2}, ${columns[0].count * 255 / 1.2}, 0)`,
            `rgb( ${255 - columns[1].count * 255 / 2}, ${columns[1].count * 255 / 1.2}, 0)`,
            `rgb( ${255 - columns[2].count * 255 / 2}, ${columns[2].count * 255 / 1.2}, 0)`,
            `rgb( ${255 - columns[3].count * 255 / 2}, ${columns[3].count * 255 / 1.2}, 0)`
        ]
        const colors2 = [
            `rgb( 255, 255, 255)`,
            `rgb( 255, 255, 255)`,
            `rgb( 255, 255, 255)`,
            `rgb( 255, 255, 255)`
        ]

        const dots = [
            { x: centerX, y: centerY - lineLengthMax * columns[0].count },
            { x: centerX - lineLengthMax * columns[1].count, y: centerY },
            { x: centerX, y: centerY + lineLengthMax * columns[2].count },
            { x: centerX + lineLengthMax * columns[3].count, y: centerY },
        ]

        const graphQuadro = () => {
            // рисуем background

            const axes = <React.Fragment>
                <line className='axes_line_base axes_line_base1' x1={centerX} y1={centerY} x2={centerX} y2={centerY - lineLength} />
                <line className='axes_line_base axes_line_base2' x1={centerX} y1={centerY} x2={centerX - lineLength} y2={centerY} />
                <line className='axes_line_base axes_line_base1' x1={centerX} y1={centerY} x2={centerX} y2={centerY + lineLength} />
                <line className='axes_line_base axes_line_base2' x1={centerX} y1={centerY} x2={centerX + lineLength} y2={centerY} />
            </React.Fragment>

            const oneQuadroHelpLines = (scale, index) => <React.Fragment>
                <polygon className={`axes_line_helpers axes_line_helpers_${index}`}
                    points={` 
                        ${centerX}, ${centerY - lineLengthMax * scale}  
                        ${centerX - lineLengthMax * scale}, ${centerY}  
                        ${centerX}, ${centerY + lineLengthMax * scale} 
                        ${centerX + lineLengthMax * scale}, ${centerY} 
                    `}
                />
            </React.Fragment>

            return <React.Fragment>
                <linearGradient id={`grad1_${this.thisId}`} x1={dots[0].x} y1={dots[0].y} x2={dots[1].x} y2={dots[1].y} gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color={colors[0]} />
                    <stop offset="1" stop-color={colors[1]} />
                </linearGradient>
                <linearGradient id={`grad2_${this.thisId}`} x1={dots[1].x} y1={dots[1].y} x2={dots[2].x} y2={dots[2].y} gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color={colors[1]} />
                    <stop offset="1" stop-color={colors[2]} />
                </linearGradient>
                <linearGradient id={`grad3_${this.thisId}`} x1={dots[2].x} y1={dots[2].y} x2={dots[3].x} y2={dots[3].y} gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color={colors[2]} />
                    <stop offset="1" stop-color={colors[3]} />
                </linearGradient>
                <linearGradient id={`grad4_${this.thisId}`} x1={dots[3].x} y1={dots[3].y} x2={dots[0].x} y2={dots[0].y} gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color={colors[3]} />
                    <stop offset="1" stop-color={colors[0]} />
                </linearGradient>

                {axes}
                {oneQuadroHelpLines(0.25, 1)}
                {oneQuadroHelpLines(0.5, 2)}
                {oneQuadroHelpLines(0.75, 3)}
                {oneQuadroHelpLines(1, 4)}

                <polygon className={`pologonGraph_line_polygon`}
                    points={` 
                        ${dots[0].x}, ${dots[0].y}  
                        ${dots[1].x}, ${dots[1].y}  
                        ${dots[2].x}, ${dots[2].y} 
                        ${dots[3].x}, ${dots[3].y} 
                    `}
                />

                <line className='pologonGraph_line' style={{ stroke: `url(#grad1_${this.thisId})` }} x1={dots[0].x} y1={dots[0].y} x2={dots[1].x} y2={dots[1].y} />
                <line className='pologonGraph_line' style={{ stroke: `url(#grad2_${this.thisId})` }} x1={dots[1].x} y1={dots[1].y} x2={dots[2].x} y2={dots[2].y} />
                <line className='pologonGraph_line' style={{ stroke: `url(#grad3_${this.thisId})` }} x1={dots[2].x} y1={dots[2].y} x2={dots[3].x} y2={dots[3].y} />
                <line className='pologonGraph_line' style={{ stroke: `url(#grad4_${this.thisId})` }} x1={dots[3].x} y1={dots[3].y} x2={dots[0].x} y2={dots[0].y} />

                <Tooltip title={columns[0].tooltip} placement='top'>
                    <circle className='pologonGraph_cr1' style={{ stroke: colors[0], fill: colors2[0] }} cx={dots[0].x} cy={dots[0].y} r="3" />
                </Tooltip>
                <Tooltip title={columns[1].tooltip} placement='top'>
                    <circle className='pologonGraph_cr2' style={{ stroke: colors[1], fill: colors2[1] }} cx={dots[1].x} cy={dots[1].y} r="3" />
                </Tooltip>
                <Tooltip title={columns[2].tooltip} placement='top'>
                    <circle className='pologonGraph_cr3' style={{ stroke: colors[2], fill: colors2[2] }} cx={dots[2].x} cy={dots[2].y} r="3" />
                </Tooltip>
                <Tooltip title={columns[3].tooltip} placement='top'>
                    <circle className='pologonGraph_cr3' style={{ stroke: colors[3], fill: colors2[3] }} cx={dots[3].x} cy={dots[3].y} r="3" />
                </Tooltip>
            </React.Fragment>

        }


        return <div className='mountin_container' style={{ width, height }}>
            <svg style={{ width, height }} version="1.1" viewBox="0 0 100 100" id={this.thisId}>
                {graphQuadro()}
            </svg>
            <this.RenderText {...{ index: 1, left: dots[0].x * koefWidth, top: dots[0].y * koefWidth, height: width / 5.5, columnData: columns[0] }} />
            <this.RenderText {...{ index: 2, left: dots[1].x * koefWidth, top: dots[1].y * koefWidth, height: width / 5.5, columnData: columns[1] }} />
            <this.RenderText {...{ index: 3, left: dots[2].x * koefWidth, top: dots[2].y * koefWidth, height: width / 5.5, columnData: columns[2] }} />
            <this.RenderText {...{ index: 4, left: dots[3].x * koefWidth, top: dots[3].y * koefWidth, height: width / 5.5, columnData: columns[3] }} />
        </div>

    }

    RenderText = ({ index, left, top, height, columnData }) => {

        const {
            icon,
            value,
            upperText,
            className = ''
        } = columnData;

        if (!icon) {
            return <div className={`pologonGraph_text pologonGraphQuad_text${index}`} style={{ left, top, height }}> {value} </div>
        }

        return <div className={`pologonGraph_text pologonGraphQuad_text${index}`} style={{ left, top, height }}>
            <div className={`pologonGraph_text_icon`} style={{ left, top, height }}>
                {navIcon(icon, false, { width: height, height: height }, className)}
            </div>
            <div className={`pologonGraph_text_right`} style={{ height }}>
                <div className={`pologonGraph_text_right_top`}>
                    {value}
                </div>
                <div className={`pologonGraph_text_right_bottom`}>
                    {upperText}
                </div>
            </div>
        </div>

        // navIcon
    }

}