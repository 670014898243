import React, { Component } from 'react';
import { SoloMiniSequenceRow } from './index';
import { withCapoeiraSportState } from 'src/ver2/context';
import './component.css';

class SoloMiniSequenceRowWrapped extends Component {

    state = {
        status: null,
        sequence: null
    }

    componentDidMount = () => {
        const { Code } = this.props;
        if (!Code) return;
        this.initLoad();
    }

    initLoad = async () => {
        const { client, Code } = this.props;
        const sequenceLoad = await client.getSoloUserSequences({ Code });
        if ( sequenceLoad && sequenceLoad[0] ) {
            this.setState({ status: 'ok', sequence: sequenceLoad[0]})
        }
    }

    render() {
        const { status, sequence } = this.state;
        if (!status || !sequence) {
            return null;
        }
        return <SoloMiniSequenceRow sequence = {sequence} {...this.props}/>
    }

}


export default withCapoeiraSportState('client, iamuser')(SoloMiniSequenceRowWrapped);