import React, { PureComponent } from 'react';
import { withPersonState } from 'src/ver2/pages/person/context';
import { groupBy, orderBy } from 'lodash';
import Loader from 'src/ver2/components/loader';
import LittleEvent from 'src/ver2/components/event/little';
import LittlePerson from 'src/ver2/components/person/little';
import { coupleNameByCircle } from 'src/ver2/pages/event/plashki/shadule/Brikets/helpers';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import './style.css';

class FightsModal extends PureComponent {

    constructor(props) {
        super(props);

        const { EnemyId } = props;

        this.state = {
            EnemyId,
            ChampinoshipsIdsByData: null,
            Fights: null
        }
    }

    componentDidMount = async () => {
        this.doChampionshipDate();
    }

    componentDidUpdate = async () => {
        this.doChampionshipDate();
    }

    doChampionshipDate = () => {

        let ChampinoshipsIdsByData = {};

        if (this.state.ChampinoshipsIdsByData) return;

        const { client } = this.props;
        const { FightsStatistics } = this.props;
        if (!client.state.events || !client.state.events.length) return;
        if (!FightsStatistics) return;

        const { MyFights } = FightsStatistics;
        const Fights = groupBy(MyFights, 'Competition_id');
        const CompetitionIds = Object.keys(Fights);

        for (let i = 0; i < CompetitionIds.length; i++) {
            const targetEvent = client.state.events.find(x => String(x.ChampionshipId) === String(CompetitionIds[i]));
            if (!targetEvent) return;
            const { FullData, Id, Date } = targetEvent;
            ChampinoshipsIdsByData[CompetitionIds[i]] = { eventId: Id, CompetitionId: CompetitionIds[i], Date, FullData };
        }

        ChampinoshipsIdsByData = orderBy(ChampinoshipsIdsByData, 'FullData', 'desc');
        this.setState({ ChampinoshipsIdsByData, Fights });

    }

    setEnemyId = (EnemyId = null) => {
        this.setState({ EnemyId });
    }

    render() {

        const { ChampinoshipsIdsByData, Fights } = this.state;
        const { FightsStatistics } = this.props;
        if (!FightsStatistics || !ChampinoshipsIdsByData || !Fights) return <Loader text='Подгружаю статистику' />

        const { EnemyId } = this.state;
        
        const className = this.props.isMobile ? 'fight_modal_container fight_modal_container_mobile' : 'fight_modal_container';

        if (EnemyId) {

            let fights = [];
            for (let i = 0; i < FightsStatistics.MyFights.length; i++) {
                if (FightsStatistics.MyFights[i].EnemyId === EnemyId) {
                    fights.push({ ...FightsStatistics.MyFights[i] })
                }
            }

            return <div className={className}>
                <this.PersonFightsRender
                    {...{ personId: EnemyId, fights }}
                />
            </div>
        }

        return <div className={className}>
            {ChampinoshipsIdsByData.map(({ eventId, CompetitionId, Date }) => {
                return <this.OneCompetitionRender
                    {...{ eventId, Date, fights: Fights[CompetitionId] }}
                />
            })}
        </div>
    }

    // рендерим одно соревнование
    OneCompetitionRender = ({ eventId, Date, fights: fights_ = [] }) => {
        let UpperHeader = null;

        UpperHeader = <LittleEvent height={50} key={'elttt'} eventId={eventId} />

        const fights = fights_.reverse();

        let CategoryName = fights[0] ? fights[0].CategoryName : 'Ошибка категории';
        const Category_id = fights[0] ? fights[0].Category_id : 0;

        CategoryName += ` / ${Date}`

        let UpperCategory = <Link to={`/event/${eventId}/?page=shadule&categoryId=${Category_id}`}>
            <div className='fight_modal_container__categoryName'>{CategoryName}</div>
        </Link>

        const className = 'fight_modal_container__competition';

        return <div className={className}>
            {UpperHeader}
            {UpperCategory}
            {this.FightsRender({ specKey: eventId, fights, withGo: true })}
        </div>

    }

    PersonFightsRender = ({ personId, fights }) => {
        return <div className='fight_modal_container__competition'>
            {this.FightsRender({ specKey: personId, fights })}
        </div>
    }

    FightsRender = ({ specKey, fights, withGo = false }) => {

        const { isMobile } = this.props;
        const personHeight = isMobile ? 30 : 55;

        return fights.map(({ EnemyId, Circle_id, IsCircled, Couple_id, IsDouble, ElderCircle, VideoId, DoIWin }) => {
            const CircleName = coupleNameByCircle(Circle_id, Couple_id, IsCircled, IsDouble, ElderCircle);
            return <div className='fight_modal_container__personRow' key={'fmc__p' + specKey + '_' + EnemyId}>
                <div className='fight_modal_container__personRow_person'>
                    <LittlePerson height={personHeight} key={'pltt' + specKey + '_' + EnemyId} personId={EnemyId} />
                </div>
                {withGo ? <div className='fight_modal_container__personRow_cell'>
                    <Icon style={{ cursor: 'pointer' }} onClick={() => { this.setState({ EnemyId }) }} type='down-circle' />
                </div> : null}
                <div className='fight_modal_container__personRow_cell'>
                    {CircleName.map(item => <span>{item}</span>)}
                </div>
                <div className={'fight_modal_container__personRow_cell ' + (DoIWin ? 'fight_modal_container__personRow_cell_win' : ' fight_modal_container__personRow_loose')}>
                    {DoIWin ? 'W' : 'L'}
                </div>
                <div className='fight_modal_container__personRow_cell'>
                    {VideoId ? <div onClick={() => { this.props.showInfoModal({ type: 'video', videoId: VideoId }); }} className='fight_modal_container__personRow_cell_video'>
                        <Icon type='caret-right' />
                    </div> : null}
                </div>
            </div>
        })
    }

}



export default withPersonState(
    `
    FightsStatistics,
    personId,
    client,
    isMobile,
    showInfoModal
    `
)(FightsModal)