import React, { Fragment, PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { Icon, Modal, message } from 'antd';
import { groupBy } from 'lodash';

import './style.css';

/*

    children / хуйня на которую мы тригеримся для модала
    Subject / sequence, medal
    SubjectId / 
    TargetUserId /

     Subject, SubjectId, TargetUserId

*/


class Liketer extends PureComponent {

    state = {
        loaded: false,
        likes: {
            0: 0,
            1: 0,
            2: 0
        },
        didILike: {
            0: false,
            1: false,
            2: false
        }

    }

    componentDidUpdate = ({ }, { modalVis }) => {
        if (!modalVis && this.state.modalVis) {
            this.initLikes();
        }
    }

    initLikes = async () => {
        const { client, iamuser, TargetUserId, Subject, SubjectId } = this.props;
        // const likes = await client.likesConstructor({ ActionData = 'get', TargetId, Data })
        const likesData = await client.likesConstructor({ Data: { Subject, SubjectId, TargetUserId } })
        if (likesData && likesData.likes) {
            let likes = {
                0: 0,
                1: 0,
                2: 0
            }
            let didILike = {
                0: false,
                1: false,
                2: false
            }

            const likesByObj = groupBy(likesData.likes, 'Grade');

            likes[0] = (likesByObj[0] && likesByObj[0].length) ? likesByObj[0].length : 0;
            likes[1] = (likesByObj[1] && likesByObj[1].length) ? likesByObj[1].length : 0;
            likes[2] = (likesByObj[2] && likesByObj[2].length) ? likesByObj[2].length : 0;
            if (iamuser) {
                const { Id } = iamuser;
                if (likesData.likes.find(x => x.UserId === Id && x.Grade === 0)) didILike[0] = true;
                if (likesData.likes.find(x => x.UserId === Id && x.Grade === 1)) didILike[1] = true;
                if (likesData.likes.find(x => x.UserId === Id && x.Grade === 2)) didILike[2] = true;
            }

            this.setState({
                likes, didILike, loaded: true
            })
        }
    }

    setLike = async (Grade = 0) => {
        const { client, iamuser, Subject, SubjectId, TargetUserId } = this.props;
        if (!iamuser) {
            message.warn('Лайк может ставить только зарегистрированный пользователь')
            return;
        }

        const Data = { Subject, SubjectId, TargetUserId, Grade };
        if (Subject === 'medal' && this.props.Place) Data.Place = this.props.Place;

        const res = await client.likesConstructor({ ActionData: 'create', Data })

        if (res.Message && res.noThisGradesLikes) {
            message.warn('У вас нет соответствующих лайков в ресурсах');
            return;
        }
        if (res.Message && res.allreadyGradeLiked) {
            message.warn('Нельзя убрать премиальный лайк');
            return;
        }
        if (res.Message && res.noNeededSocial) {
            message.warn('Нужен социальный уровень = 1');
            return;
        }

        this.initLikes();
    }

    Content = () => {

        const { setLike } = this;
        const {
            likes, didILike
        } = this.state;

        const likes0 = likes[0];
        const didILike0 = didILike[0];
        const like0ClassName = 'liketer_like_plashka_like_container liketer_like_plashka_like_container0' + (didILike0 ? ' liketer_like_plashka_like_container_liked0' : '');

        const likes1avalible = true;
        const likes1 = likes[1];
        const didILike1 = didILike[1];
        const like1ClassName = 'liketer_like_plashka_like_container liketer_like_plashka_like_container1' + (didILike1 ? ' liketer_like_plashka_like_container_liked1' : '');

        const likes2avalible = true;
        const likes2 = likes[2];
        const didILike2 = didILike[2];
        const like2ClassName = 'liketer_like_plashka_like_container liketer_like_plashka_like_container2' + (didILike2 ? ' liketer_like_plashka_like_container_liked2' : '');


        return <div>
            <div className='liketer_like_plashka_likes'>
                <div className={like0ClassName} onClick={() => { setLike(0) }}>
                    <Icon type='like' /> <span>{likes0}</span>
                </div>
                {
                    likes1avalible && <div className={like1ClassName} onClick={() => { setLike(1) }}>
                        <Icon type='like' /> <span>{likes1}</span>
                    </div>
                }
                {
                    likes2avalible && <div className={like2ClassName} onClick={() => { setLike(2) }}>
                        <Icon type='like' /> <span>{likes2}</span>
                    </div>
                }
            </div>
        </div>
    }

    render() {
        const { Content } = this;
        const { modalVis } = this.state;
        const children_ = this.props.children || <div className='liketer_like'>
            <Icon type="like" />
        </div>

        return <Fragment>
            <Modal
                visible={modalVis}
                footer={null}
                title={'Лайки'}
                onCancel={() => { this.setState({ modalVis: false }) }}
                className='liketer_modal'
                width='250px'
                height='100px'
            >
                <Content />
            </Modal>
            <div onClick={() => { this.setState({ modalVis: true }) }}>
                {children_}
            </div>
        </Fragment>
    }
}



export default withCapoeiraSportState(
    `
    iamuser, 
    client, 
    isMobile
    `
)(Liketer);
