import React, { Component } from 'react';
import TrefaText from 'src/ver2/components/trefaText';
import { withCapoeiraSportState } from 'src/ver2/context';
import { Modal } from 'antd';
import PlaceRect from 'src/ver2/containers/lowlavel/placeRect';
import SchoolAvatar from 'src/ver2/components/school/avatar';
import GroupAvatar from 'src/ver2/components/group/avatar';
import Medal from 'src/ver2/components/medal';
import { BigPlate } from 'src/ver2/components/video/bigPlate';
import LinkButton from 'src/ver2/components/ui/buttons/link';
import LittleEvent from 'src/ver2/components/event/little';
import SchoolPlate from 'src/ver2/components/school/schoolPlate';
import ItemInventarComponent from 'src/ver2/components/item/inventar';
import { ItemTypeComponent, ItemSubtypeComponent } from 'src/ver2/components/item/types';

import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import { TrefaLayout } from 'src/ver2/containers/layout';

import { PDFReader, MobilePDFReader } from 'reactjs-pdf-reader';
import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

import './modal.css';

class CapoeiraSportModalState extends Component {

    /////////////// ВСЯКИЕ МОДАЛЫ

    ItemContent = ({ Item }) => {


        const { name, Id, discription, title, w, h } = Item;
        const { isMobile } = this.props;

        let style = this.props.isMobile ?
            {
                fontSize: 17
            }
            :
            {}

        const content = <div className='modal_medal_content'>
            <div className='modal_item_left'>
                <ItemInventarComponent
                    width={isMobile ? 200 : 290}
                    name={name}
                    uid={Id}
                    plashkaType='infomodal'
                    Item={Item}
                />
            </div>
            <div style={style} className='modal_item_right'>
                <h3>{title}</h3>
                <div className='modal_item_right_row'>
                    <ItemTypeComponent size={50} itemName={name} />
                    <ItemSubtypeComponent size={50} itemName={name} />
                    {w ? <span>Ширина: {w}</span> : null}
                    {h ? <span>Высота: {h}</span> : null}
                </div>
                <h4>ОПИСАНИЕ:</h4>
                {discription}
            </div>
        </div>

        return content;
    }

    MedalsContent = ({ medal }) => {


        const { EventId, Place, UserId, Season } = medal;
        let bg = '';
        switch (Place.toString()) {
            case '1': bg = 'gold'; break;
            case '2': bg = 'silver'; break;
            case '3': bg = 'bronze'; break;

            default: bg = 'bronze';
        }

        const trefaText =
            `^a_^text^a_^Медалью награждается ^u_${UserId}^ за ${Place} место, занятое в соревновании сезона ${Season} ^e_${EventId}^ `

        let style = this.props.isMobile ?
            {
                fontSize: 17,
                avatarsSize: 18
            }
            :
            {}

        const content = <div className='modal_medal_content'>
            <div className='modal_medal_left'>
                <Medal
                    Id={UserId + '---'}
                    eventId={EventId}
                    drawWidth={270}
                    bg={bg}
                    noRotate={false}
                    shape={'circle'}
                    develop={true}
                />
            </div>
            <div className='modal_medal_right'>
                <TrefaText style={style} trefaText={trefaText} />
            </div>
        </div>

        return content;
    }

    BirthDayContent = ({ personId }) => {

        const trefaText =
            `^a_^text^a_^Сегодня День Рождения празднует ^u_${personId}^ ^n_^Искренне поздравляем от лица всей администрации портала CapoeiraSport! `

        let style = this.props.isMobile ?
            {
                fontSize: 17,
                avatarsSize: 18
            }
            :
            {}

        const content = <div className='modal_medal_content'>
            <div className='modal_medal_left'>
                <img src={'/../images/ver2/achivments/birthday.png'} />
            </div>
            <div className='modal_medal_right'>
                <TrefaText style={style} trefaText={trefaText} />
            </div>
        </div>

        return content;
    }

    NewNewsContent = ({ Body, Title, Date: Date_, Id, Subject, SubjectId }) => {

        let style = this.props.isMobile ?
            {
                fontSize: 17,
                avatarsSize: 18
            }
            :
            {}

        const { isMobile } = this.props;
        let AfterDate = null;
        if (Subject === 'event') {
            AfterDate = <div> <LittleEvent eventId={SubjectId} height={isMobile ? 18 : 25} addClassName={'event_little_for_news'} /> </div>
        }
        if (Subject === 'school') {
            AfterDate = <div> <SchoolPlate SchoolId={SubjectId} height={isMobile ? 18 : 25} /> </div>
        }

        const content = <div className='modal_newnews_content'>
            <div className='modal_newnews_content_title'>
                {Title}
            </div>
            {(AfterDate && isMobile) ? <div className='modal_newnews_content_downed_type'>
                {AfterDate}
            </div> : null}
            <div className='modal_newnews_content_downed'>
                <span>{moment(Date_, 'YYYY.MM.DDTHH:mm').locale('ru').format('DD.MM.YYYY, HH:mm')}</span>
                <LinkButton
                    link={`/?newsId=${Id}`}
                    size={isMobile ? 'mini' : 'mini_long'}
                    text={<span>ССЫЛКА</span>}
                />
                {(AfterDate && !isMobile) ? <div className='modal_newnews_content_downed_type_right'>
                    {AfterDate}
                </div> : null}
            </div>
            <div className='modal_newnews_content_text'>
                <TrefaText style={style} trefaText={Body} />
            </div>
        </div>

        return content;
    }


    DiscriptionContent = ({ Body, title, Date, header }) => {

        let style = this.props.isMobile ?
            {
                fontSize: 17,
                avatarsSize: 18
            }
            :
            {}

        const content = <div className='modal_newnews_content'>
            <div className='modal_newnews_content_title'>
                {title}
            </div>
            <div className='modal_newnews_content_text'>
                {header ? header : null}
                <TrefaText style={style} trefaText={Body} />
            </div>
        </div>

        return content;
    }

    EventSchoolResultsContent = ({ SchoolId, EventTitle, EventId, Gold, Silver, Bronze, Place, width, height }) => {

        const { client } = this.props;

        const imageUrl = client.getEventsUrl() + EventId + '_logo.png';
        let style = {
            // background: `url(${imageUrl})`,
        }

        const style2 = {
            background: `url(${imageUrl})`,
        }

        if (Place === 1) {
            style.backgroundImage = `url('https://capoeirasport.com/graphics/gold.jpg')`;
            style.border = `2px solid #f7ff66`;
            style.filter = `drop-shadow( 0px 0px 0.45rem #fffb00dd )`;
        }
        if (Place === 2) {
            style.backgroundImage = `url('https://capoeirasport.com/graphics/silver.png')`;
            style.border = `2px solid #ffffff`;
            style.filter = `drop-shadow( 0px 0px 0.45rem #5f9ea0dd )`;
        }
        if (Place === 3) {
            style.backgroundImage = `url('https://capoeirasport.com/graphics/bronze.jpg')`;
            style.border = `2px solid #e7722e`;
            style.filter = `drop-shadow( 0px 0px 0.45rem #e7722edd )`;
        }

        const content = <div className='modal_eventSchoolResults_content' style={{ height, ...style }}>
            <div className='modal_eventSchoolResults_conten_back'></div>
            <div style={{ ...style2 }} className='modal_eventSchoolResults_conten_back2'></div>
            {/* <div style = {{height: width*0.3, ...style}} className='modal_eventSchoolResults_content_event_img'> </div> */}
            {/* <div className = 'modal_eventSchoolResults_content_event_text'>
                {EventTitle}
            </div> */}
            <div className='modal_eventSchoolResults_conten_upper'>
                <div className='modal_eventSchoolResults_content_school'>
                    <SchoolAvatar size={width * 0.7} schoolId={SchoolId} />
                </div>
                <div className='modal_eventSchoolResults_content_event_text'>
                    <span>МЕДАЛЬНЫЙ ЗАЧЕТ ШКОЛЫ</span><br />
                    {EventTitle}
                </div>
                <div style={{ height: width * 0.36 }} className='modal_eventSchoolResults_content_event_medals'>
                    {Gold ? <Medal
                        key={'med' + 1}
                        Id={1}
                        eventId={EventId}
                        drawWidth={width * 0.35}
                        bg={'gold'}
                        noRotate={{ rotate: 0.2 }}
                        shape={'circle'}
                        develop={true}
                        withCount={Gold}
                        withCountClassName={'modal_eventSchoolResults_content_goldbg'}
                    /> : null}
                    {Silver ? <Medal
                        key={'med' + 2}
                        Id={2}
                        eventId={EventId}
                        drawWidth={width * 0.35}
                        bg={'silver'}
                        noRotate={{ rotate: 0.2 }}
                        shape={'circle'}
                        develop={true}
                        withCount={Silver}
                        withCountClassName={'modal_eventSchoolResults_content_silverbg'}
                    /> : null}
                    {Bronze ? <Medal
                        key={'med' + 3}
                        Id={3}
                        eventId={EventId}
                        drawWidth={width * 0.35}
                        bg={'bronze'}
                        noRotate={{ rotate: 0.2 }}
                        shape={'circle'}
                        develop={true}
                        withCount={Bronze}
                        withCountClassName={'modal_eventSchoolResults_content_bronzebg'}
                    /> : null}
                </div>
                <div className='modal_eventSchoolResults_content_event_medals_count'>
                    {Gold ? <span>{`${Gold ? 'Золото: ' + Gold : ''}`}</span> : null}
                    {Silver ? <span>{`${Silver ? 'Серебро: ' + Silver : ''}`}</span> : null}
                    {Bronze ? <span>{`${Bronze ? 'Бронза: ' + Bronze : ''}`}</span> : null}
                </div>
                <div className='modal_eventSchoolResults_content_event_places'>
                    <PlaceRect height={width * 0.2} Place={Place} PlaceName={Place} />

                    <div className='modal_eventSchoolResults_content_event_places_text'>
                        МЕСТО В ОБЩЕМ <br />
                        ШКОЛЬНОМ ЗАЧЕТЕ
                    </div>
                </div>
            </div>
        </div>

        return content;
    }

    EventGroupResultsContent = ({ GroupId, EventTitle, EventId, Gold, Silver, Bronze, width, height }) => {

        const { client } = this.props;

        const imageUrl = client.getEventsUrl() + EventId + '_logo.png';
        let style = {
            // background: `url(${imageUrl})`,
        }
        const style2 = {
            background: `url(${imageUrl})`,
        }

        const content = <div className='modal_eventSchoolResults_content' style={{ height, ...style }}>
            <div style={{ ...style2 }} className='modal_eventSchoolResults_conten_back2'></div>
            {/* <div style = {{height: width*0.3, ...style}} className='modal_eventSchoolResults_content_event_img'> </div> */}
            {/* <div className = 'modal_eventSchoolResults_content_event_text'>
                {EventTitle}
            </div> */}
            <div className='modal_eventSchoolResults_conten_upper'>
                <div className='modal_eventSchoolResults_content_school'>
                    <GroupAvatar size={width * 0.7} groupId={GroupId} />
                </div>
                <div className='modal_eventSchoolResults_content_event_text'>
                    <span>МЕДАЛЬНЫЙ ЗАЧЕТ ГРУППЫ</span><br />
                    {EventTitle}
                </div>
                <div style={{ height: width * 0.36 }} className='modal_eventSchoolResults_content_event_medals'>
                    {Gold ? <Medal
                        key={'med' + 1}
                        Id={1}
                        eventId={EventId}
                        drawWidth={width * 0.35}
                        bg={'gold'}
                        noRotate={{ rotate: 0.2 }}
                        shape={'circle'}
                        develop={true}
                        withCount={Gold}
                        withCountClassName={'modal_eventSchoolResults_content_goldbg'}
                    /> : null}
                    {Silver ? <Medal
                        key={'med' + 2}
                        Id={2}
                        eventId={EventId}
                        drawWidth={width * 0.35}
                        bg={'silver'}
                        noRotate={{ rotate: 0.2 }}
                        shape={'circle'}
                        develop={true}
                        withCount={Silver}
                        withCountClassName={'modal_eventSchoolResults_content_silverbg'}
                    /> : null}
                    {Bronze ? <Medal
                        key={'med' + 3}
                        Id={3}
                        eventId={EventId}
                        drawWidth={width * 0.35}
                        bg={'bronze'}
                        noRotate={{ rotate: 0.2 }}
                        shape={'circle'}
                        develop={true}
                        withCount={Bronze}
                        withCountClassName={'modal_eventSchoolResults_content_bronzebg'}
                    /> : null}
                </div>
                <div className='modal_eventSchoolResults_content_event_medals_count'>
                    {Gold ? <span>{`${Gold ? 'Золото: ' + Gold : ''}`}</span> : null}
                    {Silver ? <span>{`${Silver ? 'Серебро: ' + Silver : ''}`}</span> : null}
                    {Bronze ? <span>{`${Bronze ? 'Бронза: ' + Bronze : ''}`}</span> : null}
                </div>
            </div>
        </div>

        return content;
    }

    PDFRulesContent = () => {

        const { isMobile } = this.props;

        const content = <div className='modal_pdfrules_content'>
            {isMobile ?
                <MobilePDFReader url={"/../images/ver2/pravila.pdf"} showAllPage width={250} />
                :
                <PDFReader url={"/../images/ver2/pravila.pdf"} showAllPage width={900} />
            }
        </div>

        return content;
    }

    VideoContent = ({ videoId }) => {
        const { isMobile, closeInfoModal } = this.props;
        return <BigPlate isMobile={isMobile} videoId={videoId} closeInfoModal={closeInfoModal} />
    }

    PersonsListContent = ({ Persons }) => {
        return <div>
            <TrefaLayout greedsX={[5, 300, 5]}
                components={[
                    {
                        component: PersonsLittleListPlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 4321,
                        componentProps: { height: 100, Persons, autoSize: true }

                    }
                ]}
            />
        </div>
    }

    ParticipatorsListContent = ({ Data }) => {

        const {
            Partis,
            ParticipatorsData,
            Event,
            ListType
        } = Data;

        let dataFunc = () => { }
        let head = <tr><td>ИМЯ</td><td>ФАМИЛИЯ</td></tr>

        if (ListType) {
            if (ListType === 'champList') {
                head = <tr className='modal_list_header'><td>ИМЯ</td><td>ФАМИЛИЯ</td><td>КОРДАО</td><td>КАТЕГОРИЯ</td><td>ОПЛАТА</td></tr>;
                dataFunc = ({ Name, Surname, Cordao, CategoryName, Payment }) => {



                    return <tr key={'plct' + Name + Surname}>
                        <td>
                            {Name}
                        </td>
                        <td>
                            {Surname}
                        </td>
                        <td>
                            {Cordao}
                        </td>
                        <td>
                            {CategoryName}
                        </td>
                        <td>
                            {Payment}
                        </td>
                    </tr>
                }
            }

            if (ListType === 'ilyaSolo') {
                head = <tr className='modal_list_header'><td>ИМЯ</td><td>ФАМИЛИЯ</td><td>ВОЗРАСТ</td><td>ПОЛ</td><td>ПРО</td></tr>;
                dataFunc = ({ Name, Surname, year, Gender, isPro }) => {
                    return <tr key={'plct' + Name + Surname}>
                        <td>
                            {Name}
                        </td>
                        <td>
                            {Surname}
                        </td>
                        <td>
                            {year}
                        </td>
                        <td>
                            {Gender}
                        </td>
                        <td>
                            {isPro}
                        </td>
                    </tr>
                }
            }

        } else {

            if (Event.Type !== 'batizado') {
                dataFunc = ({ Name, Surname }) => {



                    return <tr key={'plct' + Name + Surname}>
                        <td>
                            {Name}
                        </td>
                        <td>
                            {Surname}
                        </td>
                    </tr>
                }
            } else {
                dataFunc = ({ Name, Surname, GroupIamInId, BirthDay, Settings }) => {

                    const { client } = this.props;
                    const { SettingsObj } = Event;
                    const Settings_ = JSON.parse(Settings);

                    const ParticipatorsData = SettingsObj.ParticipatorsMain.ParticipatorsData;
                    const BatizadoMain = SettingsObj.BatizadoMain;
                    const { cordaoParam, tshirtParam } = BatizadoMain;

                    const thsirtData = ParticipatorsData.find(x => x.name === tshirtParam);
                    const cordaoData = ParticipatorsData.find(x => x.name === cordaoParam);

                    let tshirt = '';
                    let age = '';
                    let cordao = '';

                    if (Settings_ && Settings_[tshirtParam] && thsirtData) {
                        const ourShirt = thsirtData.options.find(x => String(x.key) === Settings_[tshirtParam]);
                        if (ourShirt) {
                            tshirt = ourShirt.title;
                        }
                    }
                    if (Settings_ && Settings_[cordaoParam] && cordaoData) {
                        const ourCordao = cordaoData.options.find(x => String(x.key) === Settings_[cordaoParam]);
                        if (ourCordao) {
                            cordao = ourCordao.title;
                        }
                    }

                    age = BirthDay;
                    const group = client.getGroupById(GroupIamInId);

                    return <tr key={'plct' + Name + Surname}>
                        <td>
                            {Name}
                        </td>
                        <td>
                            {Surname}
                        </td>
                        <td>
                            {(!!group && group.Name) || 'нет'}
                        </td>
                        <td>
                            {age}
                        </td>
                        <td>
                            {cordao}
                        </td>
                        <td>
                            {tshirt}
                        </td>
                    </tr>
                }
                head = <tr><td>ИМЯ</td><td>ФАМИЛИЯ</td><td>ГРУППА</td><td>ВОЗРАСТ</td><td>КОРДАО</td><td>МАЙКА</td></tr>
            }
        }

        return <div className='ParticipatorsListContent_content'>
            <table>
                {head}
                {Partis ?
                    Partis.map(dataFunc)
                    : null}
            </table>
        </div>
    }

    BetParticipatorsListContent = ({ Data }) => {
        const { isMobile, closeInfoModal } = this.props;

        const width = isMobile ? isMobile.width - 10 : 500;

        return <div className='ParticipatorsListContent_content'>
            <TrefaLayout greedsX={[width]}
                components={[
                    {
                        component: PersonsLittleListPlashka,
                        cell_x: 1,
                        collapse: 1,
                        componentProps: {
                            height: 400,
                            Persons: Data,
                            autoSize: true,
                            autoSizeMax: 7,
                            mode: 'categorys'
                        }
                    }
                ]}
            />
        </div>
    }

    render() {
        const {
            props: {
                infoModal,
                closeInfoModal,
                isMobile
            }
        } = this;

        let showedComponent = null;
        let modalClassName = 'infoModal';
        let style = {};
        if (infoModal) {
            if (infoModal.type === 'medal') {
                const { medal } = infoModal;
                showedComponent = <this.MedalsContent medal={medal} />
            }

            if (infoModal.type === 'item') {
                const { Item } = infoModal;
                showedComponent = <this.ItemContent Item={Item} />
            }
            if (infoModal.type === 'birthday') {
                const { personId } = infoModal;
                showedComponent = <this.BirthDayContent personId={personId} />
            }
            if (infoModal.type === 'eventNewNews') {
                const { OneNews } = infoModal;
                showedComponent = <this.NewNewsContent {...OneNews} />
                modalClassName = 'infoModalNews';
            }
            if (infoModal.type === 'eventDiscription') {
                const { Discription } = infoModal;
                showedComponent = <this.DiscriptionContent {...Discription} />
            }
            if (infoModal.type === 'pdfrules') {
                showedComponent = <this.PDFRulesContent />
                modalClassName = 'infoModalPDFRules';
                style = { width: 800 }
            }
            if (infoModal.type === 'eventSchoolResults') {
                const { Data, width } = infoModal;
                const height = width * (isMobile ? 2.1 : 1.7);
                style = { width, height };

                modalClassName = 'infoModalResults';

                showedComponent = <this.EventSchoolResultsContent width={width} height={height} {...Data} />
            }
            if (infoModal.type === 'eventGroupResults') {
                const { Data, width } = infoModal;
                const height = width * 1.7;
                style = { width, height };

                modalClassName = 'infoModalResults';

                showedComponent = <this.EventGroupResultsContent width={width} height={height} {...Data} />
            }


            if (infoModal.type === 'video') {
                const { videoId } = infoModal;
                modalClassName = 'videoModalResults';

                showedComponent = <this.VideoContent videoId={videoId} />
            }

            if (infoModal.type === 'personsList') {
                const { Persons } = infoModal;
                // modalClassName = 'videoModalResults';

                showedComponent = <this.PersonsListContent Persons={Persons} />
            }

            if (infoModal.type === 'participatorsList') {
                const { Data } = infoModal;
                // modalClassName = 'videoModalResults';

                showedComponent = <this.ParticipatorsListContent Data={Data} />
            }
            if (infoModal.type === 'betParticipatorsList') {
                const { Data } = infoModal;
                // modalClassName = 'videoModalResults';
                modalClassName = 'infoModalNews';

                showedComponent = <this.BetParticipatorsListContent Data={Data} />
            }

        }

        if (isMobile) {
            let addedMobileStyle = 'infoModalMobileStyle';
            if (infoModal && infoModal.type === 'video') { addedMobileStyle = 'infoModalMobileStyle_video' }
            if (infoModal && infoModal.type === 'eventNewNews') { addedMobileStyle = 'infoModalMobileStyle_news' }
            if (infoModal && infoModal.type === 'betParticipatorsList') { addedMobileStyle = 'infoModalMobileStyle_news' }
            modalClassName += ' ' + addedMobileStyle;
        }

        // if (isMobile) style.width = isMobile.width - 10;

        return (
            <Modal
                visible={infoModal}
                onCancel={closeInfoModal}
                className={modalClassName}
                footer={null}
                zIndex={2000}
            // style={style}
            >
                {showedComponent}
            </Modal>
        );
    }


}


export default (withCapoeiraSportState('infoModal, closeInfoModal, client, isMobile')(CapoeiraSportModalState));