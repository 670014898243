import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import Loader from 'src/ver2/components/loader';
import { Modal } from 'antd';

import LittleOperation from 'src/ver2/components/operations/little.jsx';
import './style.css';

// сюда приходит тип очков 
// operationModalData 

// Person - тот, кого мы смотрим

class OperationsModal extends PureComponent {

    state = {
        status: 'loading',
        operations: []
    }

    componentDidMount = async () => {
        const { 
            Person: { Id }, 
            operationModalData, 
            client
        } = this.props;

        let operations = [];

        const operationsResult = await client.getUsersOperationsByScores ( Id, operationModalData );
        if (operationsResult && operationsResult.length) {
            operations = operationsResult.map ( Operation  => <LittleOperation key = {Operation.Id} Operation = {Operation} />)
        }

        operations.reverse();

        this.setState(
            {
                operations, 
                status: 'loaded'
            }
        )

    }

    render () {

        const { closeOperationModal } = this.props;
        const { status, operations } = this.state;

        let content = null;

        if (status === 'loading') content = <div><Loader/></div>
        if (status === 'loaded') {
            content = operations;
        }

        return <Modal 
        visible = {true}
        onCancel={closeOperationModal}
        footer = {null}
        >
            {content}
        </Modal>
    }
}

export default withCapoeiraSportState('iamuser, client')(OperationsModal);