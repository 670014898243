// это посто сама по себе аввтарка чувака
import React from 'react';

import PersonAvatar from 'src/ver2/components/person/avatar';
import GroupAvatar from 'src/ver2/components/group/avatar';
import SchoolAvatar from 'src/ver2/components/school/avatar';
import CordaoAvatar from 'src/ver2/components/cordao/avatar';

import PersonWrapper from 'src/ver2/components/person/personWrapper';
import Loader from 'src/ver2/components/loader';
import { Link } from 'react-router-dom';

import './style.css';

/*

    Мини-штука, показывающая прямоугольную плашку с юзером

*/

const PersonPlaceRectMini_ = ({ Person: personFromWrapper, personDirect, height = 100, place, rightSuffix, noNameMode = false }) => {

    let width = noNameMode ? height: height * 0.75;

    const Person = personDirect || personFromWrapper;

    if (!Person) return <div style={{ height, width }} className='person_place_rect_mini'>
        <Loader />
    </div>

    const {
        Id, Name, Surname, Cordao, SchoolId, GroupIamInId
    } = Person;

    let style = { height, width };
    if (place === 1) {
        style.backgroundImage = `url('https://capoeirasport.com/graphics/gold.jpg')`;
        style.border = `2px solid #f7ff86`;
        style.filter = `drop-shadow( 0px 0px 0.45rem #fffb00dd )`;
    }
    if (place === 2) {
        style.backgroundImage = `url('https://capoeirasport.com/graphics/silver.png')`;
        style.border = `2px solid #ffffff`;
        style.filter = `drop-shadow( 0px 0px 0.45rem #5f9ea0dd )`;
    }
    if (place === 3) {
        style.backgroundImage = `url('https://capoeirasport.com/graphics/bronze.jpg')`;
        style.border = `2px solid #e7722e`;
        style.filter = `drop-shadow( 0px 0px 0.45rem #e7722edd )`;
    }

    let lineHeight = Math.sqrt(height) * 14 * 0.0055;
    if (height < 120) lineHeight = height * 0.0095;

    if (noNameMode) {
        return <div style={style} className='person_place_rect_mini'>
            <div style={{ height: height * 1.1, width }} className='person_place_rect_mini_upper'>
                <PersonAvatar personId={Id} size={height * 0.6} />
            </div>
            <div style={{ height: height * 0.3, width, fontSize: height * 0.1, lineHeight }} className='person_place_rect_mini_downed'>
                <div className='person_place_rect_mini_downed_data'>
                    <CordaoAvatar size={height * 0.14} {...{ SchoolId, Cordao }} />
                    <SchoolAvatar size={height * 0.14} {...{ schoolId: SchoolId }} />
                    <GroupAvatar size={height * 0.14} {...{ groupId: GroupIamInId }} />
                </div>
            </div>
        </div>
    }

    return <div style={style} className='person_place_rect_mini'>
        <div style={{ height: height * 0.6, width }} className='person_place_rect_mini_upper'>
            <PersonAvatar personId={Id} size={height * 0.5} />
        </div>
        <div style={{ height: height * 0.4, width, fontSize: height * 0.1, lineHeight }} className='person_place_rect_mini_downed'>
            <div className='person_place_rect_mini_downed_data'>
                <CordaoAvatar size={height * 0.14} {...{ SchoolId, Cordao }} />
                <SchoolAvatar size={height * 0.14} {...{ schoolId: SchoolId }} />
                <GroupAvatar size={height * 0.14} {...{ groupId: GroupIamInId }} />
            </div>

            <span> <Link to={`/person/${Id}`}> {Surname} <br /> {Name}</Link></span>
        </div>
        {rightSuffix && <div className='person_place_rect_mini_right'>
            {rightSuffix}
        </div>}
    </div>

}

const componentWrapped = (props) => <PersonWrapper Wrapped={PersonPlaceRectMini_} personId={props.personDirect ? null : props.personId} componentProps={{ ...props }} />
export const PersonPlaceRectMini = componentWrapped;