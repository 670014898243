import React from 'react';
import './style.css';

const SocialIcon = ({ type, src, size }) => {

    let data = {
        imgSrc: '',
        linkSrc: ''
    }

    switch (type) {
        case 'vk': {
            data.imgSrc="/../images/ver2/social/vk.png";
            data.linkSrc='//www.vk.com/' + src + '/';
            break;
        }
        case 'instagram': {
            data.imgSrc="/../images/ver2/social/instagram.png";
            data.linkSrc='//www.instagram.com/' + src + '/';
            break;
        }
        case 'wu': {
            data.imgSrc="/../images/ver2/social/wu.png";
            data.linkSrc=`https://wa.me/${src}`;
            break;
        }
        case 'telegram': {
            data.imgSrc="/../images/ver2/social/telegram.png";
            data.linkSrc=`https://t.me//${src}`;
            break;
        }
        case 'phone': {
            data.imgSrc="/../images/ver2/social/phone.png";
            data.linkSrc=`tel:${src}`;
            break;
        }
        default: {

        }
    }

    return <a href = {data.linkSrc} style = {{width: size, height: size}} className = 'social_icon_main'>
        <img alt = {type} src={data.imgSrc} />
    </a>

}

export default SocialIcon;
