import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { TrefaLayout } from 'src/ver2/containers/layout';
import UiChooser from 'src/ver2/plashki/ui/chooser';
import RaitingBigTable from 'src/ver2/plashki/raiting/bigTable';
import Pump from 'src/ver2/components/halloween';


import './styles.css';
import { Link } from 'react-router-dom';

/*
    Это у нас основная страница рейтинга, тут показываются все - и школы и спортсмены и т.д.
*/


class RaitingPage extends PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            raitingSubject: null,
            season: null,
            type: null,
            category: null
        }

        this.searchDefault = {
            raitingSubject: 'school', // user or school
            season: '2024-2025',
            type: 'comp_pro',
            category: 'absolute'
        }

        this.mainTo = props.mainTo || './raiting';

    }

    linkTo = (param, value) => {
        let { season, type, category, raitingSubject } = this.state;
        const { region } = this.props;
        let linkObject = { season, type, category, raitingSubject };
        if (region) linkObject.page = 'raiting';
        linkObject[param] = value;
        return `?${Object.keys(linkObject).reduce((memo, key, index) => {
            if (!linkObject[key]) return memo;
            return memo + `${index > 0 ? '&' : ''}${key}=${linkObject[key]}`
        }, '')}`
    }

    chooseTab = () => {
        const { location } = this.props;
        const { search } = location;

        // парсим search
        let searchObj = {
            ...this.searchDefault
        }

        const searchAsObj = search.split('?');
        if (searchAsObj && searchAsObj[1]) {
            const search_ = searchAsObj[1].split('&');
            for (let i = 0; i < search_.length; i++) {
                const search__ = search_[i].split('=');
                searchObj[search__[0]] = search__[1];
            }
        }

        this.setState({
            ...searchObj
        })


    }

    componentDidMount() {
        this.chooseTab();
    }

    componentDidUpdate() {
        this.chooseTab();
    }

    render() {

        const width = 1200;
        const { raitingSubject, season, type, category } = this.state;
        const { client, isMobile, region } = this.props;

        let categoryItems = [
        ]

        if (raitingSubject === 'school') {
            if (isMobile) {
                categoryItems.push({ Title: 'Абс', name: 'absolute', linkTo: `${this.mainTo}${this.linkTo('category', 'absolute')}` })
            } else
                categoryItems.push({ Title: 'Абсолютный', name: 'absolute', linkTo: `${this.mainTo}${this.linkTo('category', 'absolute')}` })
        }

        const CategoryNames = client.CategoryNamesBySeason({ season, isMobile });

        const categorysArray = Object.keys(CategoryNames).reverse();
        for (let i = 0; i < categorysArray.length; i++) {
            const categoryKey = categorysArray[i];
            const Title = CategoryNames[categoryKey];

            categoryItems.push(
                { Title, name: categoryKey, linkTo: `${this.mainTo}${this.linkTo('category', categoryKey)}` }
            )
        }

        const showPump = raitingSubject === 'user' && season === '2021-2022' && category === 'm8-9';

        if (isMobile) {
            const { width } = isMobile;

            const section = (width - 4 * 12) / 3;

            return <div style={{ width }} className='raiting_page'>
                {region ? null : <div style={{ textAlign: 'center' }} className='global_discribe_text'>
                    На странице представлен всероссийский рейтинг. <Link to='./regions'>Перейти к рейтингу регионов.</Link>
                </div>}
                {
                    showPump && <Pump width={40} left={`calc(50% - 17px)`} top={330} pumpCode={'hgbuio'} />
                }
                <TrefaLayout greedsX={[12, section, 12, section, 12, section, 12]}
                    components={[
                        {
                            component: UiChooser,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: {
                                height: 95, _title: 'Рейтинг', tab: raitingSubject, Items: [
                                    { Title: 'Личный', name: 'user', linkTo: `${this.mainTo}${this.linkTo('raitingSubject', 'user')}` },
                                    { Title: 'Школьный', name: 'school', linkTo: `${this.mainTo}${this.linkTo('raitingSubject', 'school')}` }
                                ]
                            }
                        },
                        {
                            component: UiChooser,
                            cell_x: 4,
                            collapse: 1,
                            componentProps: {
                                height: 95, _title: 'Сезон', tab: season, Items: [
                                    { Title: '2024-2025', name: '2024-2025', linkTo: `${this.mainTo}${this.linkTo('season', '2024-2025')}` },
                                    { Title: '2023-2024', name: '2023-2024', linkTo: `${this.mainTo}${this.linkTo('season', '2023-2024')}` },
                                    { Title: '2022-2023', name: '2022-2023', linkTo: `${this.mainTo}${this.linkTo('season', '2022-2023')}` },
                                    { Title: '2021-2022', name: '2021-2022', linkTo: `${this.mainTo}${this.linkTo('season', '2021-2022')}` },
                                    { Title: '2019-2021', name: '2019-2021', linkTo: `${this.mainTo}${this.linkTo('season', '2019-2021')}` }
                                ]
                            }
                        },
                        {
                            component: UiChooser,
                            cell_x: 6,
                            collapse: 1,
                            componentProps: {
                                height: 95, _title: 'Тип рейтинга', tab: type, Items: [
                                    { Title: 'Основной(ПРО)', name: 'comp_pro', linkTo: `${this.mainTo}${this.linkTo('type', 'comp_pro')}` },
                                    { Title: 'Новички', name: 'comp_not_pro', linkTo: `${this.mainTo}${this.linkTo('type', 'comp_not_pro')}` }
                                ]
                            }
                        },
                        {
                            component: UiChooser,
                            cell_x: 1,
                            collapse: 7,
                            componentProps: {
                                height: 100, _title: 'Категория', tab: category, Items: categoryItems, mobileRaitingStyle: true,
                            }
                        },
                        {
                            component: RaitingBigTable,
                            cell_x: 2,
                            collapse: 5,
                            id: 25,
                            componentProps: {
                                height: 805, yPadding: true, raitingSubject, season, type, category, region, catName: (raitingSubject === 'user' ? 'УЧАСТНИК' : "ШКОЛА")
                                // height: 805, yPadding: true, raitingSubject, season, type, category, catName: (raitingSubject === 'user' ? 'УЧАСТНИК' : "ШКОЛА")
                            }
                        }

                    ]
                    }
                />
            </div>
        }


        return <div style={{ width }} className='raiting_page'>
            {region ? null : <div style={{ textAlign: 'center' }} className='global_discribe_text'>
                На странице представлен всероссийский рейтинг. <Link to='./regions'>Перейти к рейтингу регионов.</Link>
            </div>}
            {
                showPump && <Pump width={50} left={700} top={210} pumpCode={'hgbuio'} />
            }
            <TrefaLayout greedsX={[124, 178, 24, 800, 24]}
                components={[
                    {
                        component: UiChooser,
                        cell_x: 2,
                        collapse: 1,
                        componentProps: {
                            height: 95, _title: 'Рейтинг', tab: raitingSubject, Items: [
                                { Title: 'Личный', name: 'user', linkTo: `${this.mainTo}${this.linkTo('raitingSubject', 'user')}` },
                                { Title: 'Школьный', name: 'school', linkTo: `${this.mainTo}${this.linkTo('raitingSubject', 'school')}` }
                            ]
                        }
                    },
                    {
                        component: UiChooser,
                        cell_x: 2,
                        collapse: 1,
                        componentProps: {
                            height: 155, _title: 'Сезон', tab: season, Items: [
                                { Title: '2024-2025', name: '2024-2025', linkTo: `${this.mainTo}${this.linkTo('season', '2024-2025')}` },
                                { Title: '2023-2024', name: '2023-2024', linkTo: `${this.mainTo}${this.linkTo('season', '2023-2024')}` },
                                { Title: '2022-2023', name: '2022-2023', linkTo: `${this.mainTo}${this.linkTo('season', '2022-2023')}` },
                                { Title: '2021-2022', name: '2021-2022', linkTo: `${this.mainTo}${this.linkTo('season', '2021-2022')}` },
                                { Title: '2019-2021', name: '2019-2021', linkTo: `${this.mainTo}${this.linkTo('season', '2019-2021')}` }
                            ]
                        }
                    },
                    {
                        component: UiChooser,
                        cell_x: 2,
                        collapse: 1,
                        componentProps: {
                            height: 95, _title: 'Тип рейтинга', tab: type, Items: [
                                { Title: 'Основной(ПРО)', name: 'comp_pro', linkTo: `${this.mainTo}${this.linkTo('type', 'comp_pro')}` },
                                { Title: 'Новички', name: 'comp_not_pro', linkTo: `${this.mainTo}${this.linkTo('type', 'comp_not_pro')}` }
                            ]
                        }
                    },
                    {
                        component: UiChooser,
                        cell_x: 2,
                        collapse: 1,
                        componentProps: {
                            height: 615, _title: 'Категория', tab: category, Items: categoryItems
                        }
                    },
                    {
                        component: RaitingBigTable,
                        cell_x: 4,
                        collapse: 1,
                        id: 25,
                        componentProps: {
                            height: 805, yPadding: true, raitingSubject, season, type, category, region, catName: (raitingSubject === 'user' ? 'УЧАСТНИК' : "ШКОЛА")
                        }
                    }

                ]
                }
            />
        </div>
    }
}


export default withCapoeiraSportState('iamuser, client, getText, isMobile')(RaitingPage);