import React, { Component } from 'react';
//import logo from './logo.svg';
import { Button, Input } from 'antd';

import './component.css';

export default class LoginBar extends Component {
  render() {
    const {
      loginValue, passwordValue,
      loginChange, passwordChange,
      clickOnEnter, clickOnForget, clickOnRegistration,
      getText
    } = this.props;

    return (
      <div className={'registration-div registration-div-all '}>
        <div className={'registration-div-inside '}>
          <form className = {'global_color_main'}>
            {getText('registrationLogin')}
            <Input onChange={loginChange} value={loginValue} size="default" />
            <div className = 'registration_space'/>
            {getText('registrationPassword')}
            <Input.Password onChange={passwordChange} value={passwordValue} size="default" />
          </form>
          <div className='registration-div-forgot'>
            <Button onClick={clickOnEnter} size='default'>{getText('registrationEnter')}</Button>
            <div onClick={clickOnForget} className = 'registrationA'>{getText('registrationForgot')}</div>
            <div onClick={clickOnRegistration} className = 'registrationA'>{getText('registrationRegistration')}</div>
          </div>
        </div>
      </div>
    );
  }

}