import React, { Fragment, PureComponent } from "react";
import { withPersonState } from 'src/ver2/pages/person/context';
import { orderBy } from 'lodash';
import { navIcon } from 'src/ver2/components/ui/icons';
import { Tooltip, Popover, Icon, message } from "antd";
import { Link } from 'react-router-dom';
import { SoloMiniSequenceRow } from 'src/ver2/containers/challenges/components/mini-sequence-row';
import { Geks } from 'src/ver2/components/ui/geks';
import { ElementCard } from 'src/ver2/containers/challenges/components/element-card';
import { gsap } from "gsap";
import LittleQuest from 'src/ver2/components/quest/little';


import './style.css';

const FILTER_USED = 1;
const FILTER_SCORES = 2;
const FILTER_AVAR = 3;
const FILTER_MAX = 4;

export class PersonElements extends PureComponent {

    state = {
        choosedElementKey: null,
        filter: FILTER_AVAR
    }

    clickOnAddElement = async (elementKey) => {
        const { client, reloadElements } = this.props;
        await client.addUserElement(elementKey);
        message.success('Элемент добавлен');
        reloadElements();
    }

    componentDidMount = async () => {
        gsap.from('.person_elements_modal_container_row_cover>div',
            {
                translateX: 0,
                translateY: '10%',
                rotate: 0,
                ease: 'back',

                duration: 1,
                delay: 1
            });

        const { Person } = this.props;

        const result = await this.props.client.getUserFromServer(Person.Id, {});

        const { VSettings = '{}' } = result || {};
        const VSettingsObj = JSON.parse(VSettings);
        const coverId = (VSettingsObj && VSettingsObj.paint && VSettingsObj.paint.cover) ? VSettingsObj.paint.cover : null;

        this.setState({ coverId });

    }

    onChoosedElement = (choosedElementKey) => {
        if (this.state.choosedElementKey === choosedElementKey) {
            return this.setState({ choosedElementKey: null })
        }
        this.setState({ choosedElementKey })
    }

    onFilterClick = (filter) => this.setState({ filter });

    addFiler = () => {

        const {
            SequencesStatistics
        } = this.props;

        const {
            filter
        } = this.state;

        const { UsedElements = {} } = SequencesStatistics;

        let UsedElementsOrdered = orderBy(UsedElements, ['avarageKoef', 'usedCount'], ['desc', 'desc']);

        switch (filter) {
            case FILTER_USED: {
                UsedElementsOrdered = orderBy(UsedElements, ['usedCount', 'avarageKoef'], ['desc', 'desc']);
                break;
            }
            case FILTER_SCORES: {
                UsedElementsOrdered = orderBy(UsedElements, ['SumPoints', 'avarageKoef'], ['desc', 'desc']);
                break;
            }
            case FILTER_AVAR: {
                UsedElementsOrdered = orderBy(UsedElements, ['avarageKoef', 'SumPoints'], ['desc', 'desc']);
                break;
            }
            case FILTER_MAX: {
                UsedElementsOrdered = orderBy(UsedElements, ['MaxKoef', 'SumPoints'], ['desc', 'desc']);
                break;
            }
            default: {

            }
        }

        return UsedElementsOrdered;
    }

    get techTitle() {
        const {
            filter
        } = this.state;

        if (filter === FILTER_USED) {
            return 'ЛЮБИМЫЕ ЭЛЕМЕНТЫ'
        }

        if (filter === FILTER_SCORES) {
            return 'ПРОДУКТИВНЫЕ ЭЛЕМЕНТЫ'
        }

        if (filter === FILTER_AVAR) {
            return 'ТЕХНИЧНЫЕ ЭЛЕМЕНТЫ'
        }

        if (filter === FILTER_MAX) {
            return 'МАКСИМАЛЬНОЕ УСЛОЖНЕНИЕ'
        }

        return '';
    }

    oneUpperTech = ({ UsedElementsOrdered, index }) => {

        if (!UsedElementsOrdered || !UsedElementsOrdered[index]) return null;

        const usedElement = UsedElementsOrdered[index];

        const {
            filter
        } = this.state;
        const { client, isMobile } = this.props;

        const elements = client.elements_as_object;

        let finalMedal = null;
        let finalProps = {
            imgUrl: client.getTechnicLogoUrl(usedElement.element_key),
            title: elements[usedElement.element_key].title,
            styleType: 'blank'
        };

        if (filter === FILTER_USED) {
            const { usedCount } = usedElement;
            if (usedCount >= 4) finalMedal = 'bronze';
            if (usedCount >= 6) finalMedal = 'silver';
            if (usedCount >= 9) finalMedal = 'gold';
        }

        if (filter === FILTER_SCORES) {
            const { SumPoints } = usedElement;
            if (SumPoints >= 40) finalMedal = 'bronze';
            if (SumPoints >= 80) finalMedal = 'silver';
            if (SumPoints >= 120) finalMedal = 'gold';
        }

        if (filter === FILTER_AVAR) {
            const { avarageKoef, usedCount } = usedElement;
            if (usedCount >= 3 && avarageKoef >= 1.15) finalMedal = 'bronze';
            if (usedCount >= 4 && avarageKoef >= 1.2) finalMedal = 'silver';
            if (usedCount >= 5 && avarageKoef >= 1.25) finalMedal = 'gold';
        }

        if (filter === FILTER_MAX) {
            const { MaxKoef } = usedElement;
            if (MaxKoef >= 1.3) finalMedal = 'bronze';
            if (MaxKoef >= 1.4) finalMedal = 'silver';
            if (MaxKoef >= 1.5) finalMedal = 'gold';
        }


        if (finalMedal === 'bronze') {
            finalProps = {
                ...finalProps,
                styleType: 'bronze',
                frameOnly: true,
                fill: {
                    endColor: 'rgba(56, 0, 0, 0.8)',
                    value: 1,
                    noAnimation: true
                }
            }
        }
        if (finalMedal === 'silver') {
            finalProps = {
                ...finalProps,
                styleType: 'silver',
                frameOnly: true,
                fill: {
                    endColor: 'rgba(0, 75, 144, 0.4)',
                    value: 1,
                    noAnimation: true
                }
            }
        }
        if (finalMedal === 'gold') {
            finalProps = {
                ...finalProps,
                styleType: 'gold',
                frameOnly: true,
                fill: {
                    endColor: '#ff8e02',
                    value: 1,
                    noAnimation: true
                }
            }
        }

        const gekWidth = isMobile ? isMobile.width / 4.5 : 110;

        return <Geks width={gekWidth} {...finalProps} lowTitleStyle = {isMobile} />
    }

    render() {

        const {
            client,
            SequencesStatistics,
            isSequencesReady,
            Elements = [],
            isMobile,
            iAmPerson,
            ServerQuests
        } = this.props;

        const {
            choosedElementKey,
            filter,
            coverId
        } = this.state;

        const {
            onChoosedElement,
            onFilterClick,
            clickOnAddElement
        } = this;

        if (!isSequencesReady) return <div>null</div>

        const { UsedElements = {}, FinalStatistics = {} } = SequencesStatistics;

        const { T1, T2, T3 } = FinalStatistics;
        const T1value = T1 / client.getClientElementsStatisctics().TierCounts[1];
        const T2value = T2 / client.getClientElementsStatisctics().TierCounts[2];
        const T3value = T3 / client.getClientElementsStatisctics().TierCounts[3];

        let UsedElementsOrdered = this.addFiler();

        // тут нам надо пересобрать элементы, которые А - не выступали у человека
        // Б - которые он еще не добавил
        let NotUsedElements = [];
        let NotAddElements = [];

        const elements = client.elements_as_object;
        const allElementsKeys = Object.keys(elements);

        for (let i = 0; i < allElementsKeys.length; i++) {
            const el_key = allElementsKeys[i];
            if (UsedElements[el_key]) continue;
            if (Elements.find(x => x.ElementKey === el_key)) { NotUsedElements.push(el_key); continue; }
            NotAddElements.push(el_key);
        }

        let className = "person_elements_modal_container";
        if (isMobile) className += " person_elements_modal_container_mobile";

        const sizes = {
            width: 150,
            no_select: true,
            dont_show_video: true,
            nomargin: true
        }
        // const { VSettings = '' } = Person;
        // const VSettingsObj = JSON.parse(VSettings);
        // const cover_id = (VSettingsObj && VSettingsObj.paint && VSettingsObj.paint.cover) ? VSettingsObj.paint.cover : null;



        let RegularSequence = null;
        if (iAmPerson && ServerQuests && ServerQuests.find(x => x.Id === 8)) {
            //UserQuestId
            const { iamuser } = this.props;
            let UserQuestId = null;
            if (iamuser && iamuser.myQuests && iamuser.myQuests.find(x => x.QuestId === 8)) {
                const myQuest = iamuser.myQuests.find(x => x.QuestId === 8);
                UserQuestId = myQuest.Id;
            }

            if (UserQuestId) {
                RegularSequence = <React.Fragment>
                    <this.Devider text='ЗАДАНИЯ' type='usual' />
                    <LittleQuest UserQuestId={UserQuestId} />
                </React.Fragment>
            } else
                RegularSequence = <React.Fragment>
                    <this.Devider text='ЗАДАНИЯ' type='usual' />
                    <LittleQuest QuestId={8} />
                </React.Fragment>
        }

        if (isMobile) {
            return <div className={className}>

            <div className="person_elements_modal_container_left">
                <this.Devider text='ОБЛОЖКА' type='crossed' />
                <div className="person_elements_modal_container_row_cover">
                    <ElementCard
                        cover_id={coverId}
                        is_login={false}
                        {...{ mobile: isMobile, client, sizes, element_key: 'armada' }}
                    />
                    <ElementCard
                        cover_id={coverId}
                        is_login={false}
                        {...{ mobile: isMobile, client, sizes, element_key: 'compassa' }}
                    />
                    <ElementCard
                        cover_id={coverId}
                        is_login={false}
                        {...{ mobile: isMobile, client, sizes, element_key: 'negativa' }}
                    />
                </div>


                <this.Devider text='ВЫПОЛНЕНИЕ TIER' type='crossed' />
                <div className="person_elements_modal_container_row" style={{ minHeight: 180 }}>
                    <Geks styleType={'filled'} width={ isMobile.width / 3.6 } text={'T1'} title={`${(T1value * 100).toFixed()}%`}
                        fill={{
                            startColor: '#18384A',
                            endColor: '#2D98C2',
                            value: T1value
                        }}
                    />
                    <Geks styleType={'filled'} width={isMobile.width / 3.6} text={'T2'} title={`${(T2value * 100).toFixed()}%`}
                        fill={{
                            startColor: '#18384A',
                            endColor: '#2D98C2',
                            value: T2value
                        }}
                    />
                    <Geks styleType={'filled'} width={isMobile.width / 3.6} text={'T3'} title={`${(T3value * 100).toFixed()}%`}
                        fill={{
                            startColor: '#18384A',
                            endColor: '#2D98C2',
                            value: T3value
                        }}
                    />
                </div>

                <this.Devider text={'ВЫПОЛНЕНИЕ ТИПОВ'} type='crossed' />
                <this.TierTypesStat Tier={1} />
                <this.TierTypesStat Tier={2} />
                <this.TierTypesStat Tier={3} />

                <this.Devider text='ДОСТИЖЕНИЯ' type='crossed' />
                <this.CardQuest type='libra' />
                <this.CardQuest type='pool' />
                <this.CardQuest type='points' />
                <this.CardQuest type='seq' />

                {RegularSequence}

                <this.Devider text={this.techTitle} type='crossed' />

                <div className="person_elements_modal_container_row" style={{ minHeight: 150 }}>
                    {this.oneUpperTech({ UsedElementsOrdered, index: 0 })}
                    {this.oneUpperTech({ UsedElementsOrdered, index: 1 })}
                    {this.oneUpperTech({ UsedElementsOrdered, index: 2 })}
                    {this.oneUpperTech({ UsedElementsOrdered, index: 3 })}
                </div>
                <this.Devider text='СТАТИСТИКА ЭЛЕМЕНТОВ' type='crossed' />
                <div className="person_elements_modal_container_elementslist">
                    <ElementRow headerRow onFilterClick={onFilterClick} filter={filter} />
                    {UsedElementsOrdered.map(({ element_key }, index) => {
                        return <ElementRow key={`pemc_${element_key}_${index}`} {...{ isMobile, onChoosedElement, element_key, choosedElementKey, client, usedElement: UsedElements[element_key] }} />
                    })}
                    {NotUsedElements.map((element_key, index) => {
                        return <ElementRow key={`pemc_${element_key}_${index}`} {...{ element_key, client, notUsed: true }} />
                    })}

                    {iAmPerson && NotAddElements.map((element_key, index) => {
                        return <ElementRow key={`pemc_${element_key}_${index}`} {...{ clickOnAddElement, element_key, client, notAdded: true }} />
                    })}
                </div>

            </div>
        </div>
        }

        return <div className={className}>

            <div className="person_elements_modal_container_left">
                <this.Devider text='ОБЛОЖКА' type='usual' />
                <div className="person_elements_modal_container_row_cover">
                    <ElementCard
                        cover_id={coverId}
                        is_login={false}
                        {...{ mobile: isMobile, client, sizes, element_key: 'armada' }}
                    />
                    <ElementCard
                        cover_id={coverId}
                        is_login={false}
                        {...{ mobile: isMobile, client, sizes, element_key: 'compassa' }}
                    />
                    <ElementCard
                        cover_id={coverId}
                        is_login={false}
                        {...{ mobile: isMobile, client, sizes, element_key: 'negativa' }}
                    />
                </div>


                <this.Devider text='ВЫПОЛНЕНИЕ TIER' type='usual' />
                <div className="person_elements_modal_container_row" style={{ minHeight: 200 }}>
                    <Geks styleType={'filled'} width={100} text={'T1'} title={`${(T1value * 100).toFixed()}%`}
                        fill={{
                            startColor: '#18384A',
                            endColor: '#2D98C2',
                            value: T1value
                        }}
                    />
                    <Geks styleType={'filled'} width={100} text={'T2'} title={`${(T2value * 100).toFixed()}%`}
                        fill={{
                            startColor: '#18384A',
                            endColor: '#2D98C2',
                            value: T2value
                        }}
                    />
                    <Geks styleType={'filled'} width={100} text={'T3'} title={`${(T3value * 100).toFixed()}%`}
                        fill={{
                            startColor: '#18384A',
                            endColor: '#2D98C2',
                            value: T3value
                        }}
                    />
                </div>

                <this.Devider text='ДОСТИЖЕНИЯ' type='usual' />
                <this.CardQuest type='libra' />
                <this.CardQuest type='pool' />
                <this.CardQuest type='points' />
                <this.CardQuest type='seq' />

                {RegularSequence}
            </div>
            <div className="person_elements_modal_container_right">


                <this.Devider text={'ВЫПОЛНЕНИЕ ТИПОВ'} type='crossed' />
                <this.TierTypesStat Tier={1} />
                <this.TierTypesStat Tier={2} />
                <this.TierTypesStat Tier={3} />

                <this.Devider text={this.techTitle} type='crossed' />

                <div className="person_elements_modal_container_row" style={{ minHeight: 200 }}>
                    {this.oneUpperTech({ UsedElementsOrdered, index: 0 })}
                    {this.oneUpperTech({ UsedElementsOrdered, index: 1 })}
                    {this.oneUpperTech({ UsedElementsOrdered, index: 2 })}
                    {this.oneUpperTech({ UsedElementsOrdered, index: 3 })}
                </div>
                <this.Devider text='СТАТИСТИКА ЭЛЕМЕНТОВ' type='crossed' />
                <div className="person_elements_modal_container_elementslist">
                    <ElementRow headerRow onFilterClick={onFilterClick} filter={filter} />
                    {UsedElementsOrdered.map(({ element_key }, index) => {
                        return <ElementRow key={`pemc_${element_key}_${index}`} {...{ onChoosedElement, element_key, choosedElementKey, client, usedElement: UsedElements[element_key] }} />
                    })}
                    {NotUsedElements.map((element_key, index) => {
                        return <ElementRow key={`pemc_${element_key}_${index}`} {...{ element_key, client, notUsed: true }} />
                    })}

                    {iAmPerson && NotAddElements.map((element_key, index) => {
                        return <ElementRow key={`pemc_${element_key}_${index}`} {...{ clickOnAddElement, element_key, client, notAdded: true }} />
                    })}
                </div>
            </div>
        </div>
    }

    Devider = ({ text, type = 'usual' }) => {

        if (type === 'usual') {
            return <div className="person_elements_modal_container_devider person_elements_modal_container_devider_usual">
                {text}
            </div>
        }

        if (type === 'crossed') {
            return <div className="person_elements_modal_container_devider person_elements_modal_container_devider_crossed">
                <div className="person_elements_modal_container_devider_crossed_left" />
                <div className="person_elements_modal_container_devider_crossed_center">
                    {text}
                </div>
                <div className="person_elements_modal_container_devider_crossed_right" />
            </div>
        }

        return null;
    }

    TierTypesStat = ({ Tier }) => {
        const { SequencesStatistics, client, isMobile } = this.props;
        const { FinalStatistics = {} } = SequencesStatistics;
        const { TierTypesMax = {} } = FinalStatistics;

        const { typesByTierScores } = client.getClientElementsStatisctics();
        const typeWidth = isMobile ? 50 : 70;
        const mainWidth = isMobile ? 70: 100;

        const typeCalculation = (type) => {
            let value = 0;
            let targetKey = '';
            let nowValue = 0;
            if (type === 'strike') { targetKey = 'allStrike'; }
            if (type === 'flow') { targetKey = 'allFlow'; }
            if (type === 'freeze') { targetKey = 'allFreeze'; }
            if (type === 'jump') { targetKey = 'allJump'; }

            if (TierTypesMax && TierTypesMax[Tier] && TierTypesMax[Tier][targetKey]) nowValue = TierTypesMax[Tier][targetKey];

            value = nowValue / typesByTierScores[Tier][targetKey];

            let styleType = 'filled';
            let dopProop = {};
            if (value >= 0.67) styleType = 'bronze';
            if (value >= 0.85) styleType = 'silver';
            if (value >= 0.99) styleType = 'gold';

            if (styleType === 'filled') {
                dopProop = {
                    fill: {
                        startColor: '#18384A',
                        endColor: '#2D98C2',
                        value: value
                    }
                }
            }

            return { icon: type, styleType, value, dopProop };
        }

        const OneGekType = (type) => {
            const { icon, styleType, value, dopProop } = typeCalculation(type);
            return <Geks styleType={styleType} icon={icon} width={typeWidth} title={`${(value * 100).toFixed()}%`} {...dopProop} />
        }

        const OneGekTier = () => {
            const value = (typeCalculation('strike').value + typeCalculation('flow').value + typeCalculation('jump').value + typeCalculation('freeze').value) / 4;

            let styleType = 'filled';
            let dopProop = {};
            if (value >= 0.67) styleType = 'bronze';
            if (value >= 0.85) styleType = 'silver';
            if (value >= 0.95) styleType = 'gold';

            if (styleType === 'filled') {
                dopProop = {
                    fill: {
                        startColor: '#18384A',
                        endColor: '#2D98C2',
                        value: value
                    }
                }
            }

            return <Geks styleType={styleType} width={mainWidth} text={`T${Tier}`} title={`${(value * 100).toFixed()}%`} {...dopProop} />
        }

        return <div className="person_elements_modal_container_row" style={{ minHeight: 130 }}>
            {OneGekTier()}
            {OneGekType('strike')}
            {OneGekType('flow')}
            {OneGekType('jump')}
            {OneGekType('freeze')}
        </div>
    }

    CardQuest = ({
        type
    }) => {

        let icon, text, title, lvl, rest, percentnow, styleType;
        const { SequencesStatistics, client } = this.props;
        const { UsedElements = {}, FinalStatistics = {} } = SequencesStatistics;

        if (type === 'pool') {

            const { Pool = 0 } = FinalStatistics;
            const { allPool } = client.getClientElementsStatisctics();

            styleType = 'blank';
            if (Pool >= 40) {
                styleType = 'bronze';
            }
            if (Pool >= 50) {
                styleType = 'silver';
            }
            if (Pool >= 60) {
                styleType = 'gold';
            }

            icon = 'pool';
            text = 'Количество подтверждённых элементов (выполненных более, чем на 0.8)';
            title = 'ПУЛ ЭЛЕМЕНТОВ';
            rest = `${Pool}/${allPool}`;
            percentnow = (100 * Pool / allPool).toFixed();

            lvl = '';
        }

        if (type === 'libra') {

            const { SequencesCount = 0 } = FinalStatistics;

            lvl = '';
            text = 'Необходимо набрать минимум 10 связок для подсчета средних значений статистики';
            styleType = 'blank';
            rest = `${SequencesCount}/${10}`;
            percentnow = (100 * SequencesCount / 10).toFixed();
            if (SequencesCount >= 10) {

                const nowSeq = SequencesCount;
                const restSeq = 25;

                styleType = 'bronze';
                text = 'Наберите 25 связок для более точного подсчета средних значений статистики';
                rest = `${nowSeq}/${restSeq}`;
                percentnow = (100 * nowSeq / restSeq).toFixed();
                lvl = 'LVL 1';
            }
            if (SequencesCount >= 25) {

                const nowSeq = SequencesCount;
                const restSeq = 50;

                text = 'Наберите 50 связок для точного подсчета средних значений статистики';
                styleType = 'silver';
                rest = `${nowSeq}/${restSeq}`;
                percentnow = (100 * nowSeq / restSeq).toFixed();
                lvl = 'LVL 2';
            }
            if (SequencesCount >= 50) {
                text = 'Калибровка полностью пройдена';
                styleType = 'gold';
                rest = 'пройдена';
                percentnow = 100;
                lvl = 'LVL 3';
            }

            icon = 'libra';
            title = 'КАЛИБРОВКА';

        }

        if (type === 'points') {

            const { AllPoints } = FinalStatistics;
            const Scale = [50, 150, 250, 500, 900, 1500, 2000];

            let nowScale = 0;
            let restPoints = 0;
            let lvlPoints = 0;
            // let allPoints = AllPoints;
            for (let i = 0; i < Scale.length; i++) {
                if (AllPoints < Scale[i]) {
                    nowScale = i;
                    restPoints = AllPoints - (Scale[i - 1] || 0);
                    lvlPoints = Scale[i] - (Scale[i - 1] || 0);
                    break;
                }
            }
            if (nowScale < 9) {
                styleType = 'gold';
            }

            if (nowScale < 7) {
                styleType = 'silver';
            }

            if (nowScale < 5) {
                styleType = 'bronze';
            }

            if (nowScale < 3) {
                styleType = 'blank';
            }


            icon = 'points';
            text = 'Суммарное количество очков за все связки';
            title = 'НАБОР ОЧКОВ';
            lvl = `LVL ${nowScale}`;
            rest = `${AllPoints}/${Scale[nowScale]}`
            // styleType = 'bronze';
            percentnow = (100 * restPoints / lvlPoints).toFixed();
        }

        if (type === 'seq') {

            const { SequenceSum } = FinalStatistics;
            const Scale = [25, 75, 150, 250, 400, 650, 1000];

            let nowScale = 0;
            let restPoints = 0;
            let lvlPoints = 0;
            // let allPoints = AllPoints;
            for (let i = 0; i < Scale.length; i++) {
                if (SequenceSum < Scale[i]) {
                    nowScale = i;
                    restPoints = SequenceSum - (Scale[i - 1] || 0);
                    lvlPoints = Scale[i] - (Scale[i - 1] || 0);
                    break;
                }
            }
            if (nowScale < 9) {
                styleType = 'gold';
            }

            if (nowScale < 7) {
                styleType = 'silver';
            }

            if (nowScale < 5) {
                styleType = 'bronze';
            }

            if (nowScale < 3) {
                styleType = 'blank';
            }

            icon = 'seq';
            text = 'Суммарная связность за все связки';
            title = 'СВЯЗНОСТЬ';
            lvl = `LVL ${nowScale}`;
            rest = `${SequenceSum.toFixed()}/${Scale[nowScale]}`
            // styleType = 'bronze';
            percentnow = (100 * restPoints / lvlPoints).toFixed();
        }

        return <div className="person_elements_modal_container_quest_container">
            <div className="person_elements_modal_container_quest_container_icon">
                <Geks styleType={styleType} width={110} icon={icon} />
            </div>
            <div className="person_elements_modal_container_quest_container_right">
                <div className="person_elements_modal_container_quest_container_title">
                    {title}
                </div>
                <div className="person_elements_modal_container_quest_container_text">
                    {text}
                </div>
                <div className="person_elements_modal_container_quest_container_rest">
                    <span>{`${lvl}`}</span>
                    <span>{`${rest}`}</span>
                </div>
                <div className="person_elements_modal_container_quest_container_progress">
                    <div style={{ width: `${percentnow}%` }} className="person_elements_modal_container_quest_container_progress_inside">

                    </div>
                </div>
            </div>
        </div>

    }

}

const addEnder = (koef) => {
    if (koef < 0.8) return 'less08';
    if (koef < 0.9) return '08';
    if (koef < 1.0) return '09';
    if (koef < 1.1) return '10';
    if (koef < 1.2) return '11';
    if (koef < 1.3) return '12';
    if (koef < 1.4) return '13';
    if (koef < 1.5) return '14';
    if (koef >= 1.5) return '15';
}

export class ElementRow extends PureComponent {
    render() {

        const { element_key, client, key, usedElement, notUsed, notAdded, headerRow, choosedElementKey, isMobile, filter, onFilterClick } = this.props;

        if (headerRow) {
            return <div id={`pemcor_header`} className="person_elements_modal_container_onerow person_elements_modal_container_header">
                <div className={'person_elements_modal_container_img person_elements_modal_container_img_notused'}>

                </div>
                <div className="person_elements_modal_container_title">
                    <span>Название</span>
                </div>
                <Tooltip title='Использовано раз'>
                    <div
                        className={filter === FILTER_USED ? "person_elements_modal_container_usedtime person_elements_modal_container_usedtime_choosed" : "person_elements_modal_container_usedtime"}
                        onClick={() => { onFilterClick(FILTER_USED) }}
                    >
                        <span>Исп.</span>
                    </div>
                </Tooltip>
                <Tooltip title='Сумма всех очков за элемент'>
                    <div
                        className={filter === FILTER_SCORES ? "person_elements_modal_container_usedtime person_elements_modal_container_usedtime_choosed" : "person_elements_modal_container_usedtime"}
                        onClick={() => { onFilterClick(FILTER_SCORES) }}
                    >
                        <span>Очки</span>
                    </div>
                </Tooltip>
                <Tooltip title='Средняя техничность'>
                    <div
                        className={filter === FILTER_AVAR ? "person_elements_modal_container_usedtime person_elements_modal_container_usedtime_choosed" : "person_elements_modal_container_usedtime"}
                        onClick={() => { onFilterClick(FILTER_AVAR) }}
                    >
                        <span>Ср.техн.</span>
                    </div>
                </Tooltip>
                <Tooltip title='Максимальная техничность'>
                    <div
                        className={filter === FILTER_MAX ? "person_elements_modal_container_usedtime person_elements_modal_container_usedtime_choosed" : "person_elements_modal_container_usedtime"}
                        onClick={() => { onFilterClick(FILTER_MAX) }}
                    >
                        <span>Макс.техн.</span>
                    </div>
                </Tooltip>
                <Tooltip title='Tier и специальные очки'>
                    <div className={'person_elements_modal_container_options'}>
                        <span>Описание</span>
                    </div>
                </Tooltip>

            </div>
        }

        const elements = client.elements_as_object;
        const element = elements[element_key] || {};
        const { title, type, subtypes, Tier } = element;

        let Options = [
            <Tooltip title='Tier элемента'>
                <span className={`person_elements_modal_container_tier_${Tier}`}>{`T${Tier}`}</span>
            </Tooltip>];
        const iconSize = 18;
        if (type === 'strike') {
            Options.push(
                <Tooltip title='Тип: атака'>
                    {navIcon('strike', false, { width: iconSize, height: iconSize }, 'person_elements_modal_container_options_strike')}
                </Tooltip>)
        }
        if (subtypes) {
            if (subtypes.find(x => x === 'flow')) {
                Options.push(
                    <Tooltip title='Тип: прогибной'>
                        {navIcon('flow', false, { width: iconSize, height: iconSize }, 'person_elements_modal_container_options_flow')}
                    </Tooltip>
                )
            }
            if (subtypes.find(x => x === 'jump')) {
                Options.push(
                    <Tooltip title='Тип: прыжок'>
                        {navIcon('jump', false, { width: iconSize, height: iconSize }, 'person_elements_modal_container_options_jump')}
                    </Tooltip>
                )
            }
            if (subtypes.find(x => x === 'freeze')) {
                Options.push(
                    <Tooltip title='Тип: стойка'>
                        {navIcon('freeze', false, { width: iconSize, height: iconSize }, 'person_elements_modal_container_options_freeze')}
                    </Tooltip>

                )
            }
        }

        if (notAdded) {

            const { clickOnAddElement } = this.props;

            return <div key={key} id={`pemcor_${element_key}`} className="person_elements_modal_container_onerow">
                <div className={'person_elements_modal_container_img person_elements_modal_container_img_notadded'}>
                    <Popover placement={'right'} content={this.ElementCardPoopover({ element_key })}>
                        <img src={client.getTechnicLogoUrl(element_key)} />
                    </Popover>
                </div>
                <div className="person_elements_modal_container_title">
                    <span><Link to={`/technic/${element_key}`}>{title}</Link></span>
                </div>
                <div onClick={() => { clickOnAddElement(element_key) }} className="person_elements_modal_container_addelement">
                    <span>ДОБАВИТЬ</span>
                </div>
                <div className={'person_elements_modal_container_options'}>
                    {Options}
                </div>

            </div>
        }

        if (notUsed) {
            return <div key={key} id={`pemcor_${element_key}`} className="person_elements_modal_container_onerow">
                <div className={'person_elements_modal_container_img person_elements_modal_container_img_notused'}>
                    <Popover placement={'right'} content={this.ElementCardPoopover({ element_key })}>
                        <img src={client.getTechnicLogoUrl(element_key)} />
                    </Popover>
                </div>
                <div className="person_elements_modal_container_title">
                    <span><Link to={`/technic/${element_key}`}>{title}</Link></span>
                </div>
                <div className="person_elements_modal_container_usedtime">
                    <span>{0}</span>
                </div>
                <div className="person_elements_modal_container_usedtime">
                </div>
                <div className={'person_elements_modal_container_usedtime'}>
                </div>
                <div className={'person_elements_modal_container_usedtime'}>
                </div>
                <div className={'person_elements_modal_container_options'}>
                    {Options}
                </div>

            </div>
        }

        /*
                        usedCount: 0,
                goodCount: 0,
                SumPoints: 0,
                MaxPoints: 0,
                avaragePoints: 0,
                avarageKoef: 0,
                MaxKoef: 0,
                isGood: false,
                Tier,
                BasicPoints
        */

        const { usedCount, isGood, SumPoints, MaxKoef, avarageKoef, Sequences = [] } = usedElement;

        let imgClassName = 'person_elements_modal_container_img ';
        if (!isGood) imgClassName += 'person_elements_modal_container_img_notgood '

        let maxKoefClassName = 'person_elements_modal_container_usedtime ';
        maxKoefClassName += `person_elements_modal_container_usedtime_${addEnder(MaxKoef)} `;

        let avarageKoefClassName = 'person_elements_modal_container_usedtime ';
        avarageKoefClassName += `person_elements_modal_container_usedtime_${addEnder(avarageKoef)} `;

        return <Fragment>
            <div key={key} id={`pemcor_${element_key}`} className="person_elements_modal_container_onerow">
                <div className={imgClassName}>
                    <Popover placement={'right'} content={this.ElementCardPoopover({ element_key })}>
                        <img src={client.getTechnicLogoUrl(element_key)} />
                    </Popover>
                </div>
                <div className="person_elements_modal_container_title">
                    <span><Link to={`/technic/${element_key}`}>{title}</Link></span>
                </div>
                <div className="person_elements_modal_container_usedtime">
                    <span>{usedCount}</span>
                </div>
                <div className="person_elements_modal_container_usedtime">
                    <span>{SumPoints.toFixed(1)}</span>
                </div>
                <div className={avarageKoefClassName}>
                    <span>{avarageKoef.toFixed(2)}</span>
                </div>
                <div className={maxKoefClassName}>
                    <span>{MaxKoef.toFixed(1)}</span>
                </div>
                <div className={'person_elements_modal_container_options'}>
                    {Options}
                </div>
                <div className="person_elements_modal_container_usedtime">
                    <Tooltip title='Посмотреть связки'>
                        <Icon onClick={() => { this.props.onChoosedElement(element_key) }} type='right-circle'
                            className={choosedElementKey === element_key ? "person_elements_modal_container_icon person_elements_modal_container_icon_checked" : "person_elements_modal_container_icon"} />
                    </Tooltip>
                </div>
            </div>

            {choosedElementKey === element_key && <div className="person_elements_modal_container_sequences">
                {Sequences.map(({ sequence, perfKoef }) => {
                    let perfKoefClassName = 'person_elements_modal_container_usedtime ';
                    perfKoefClassName += `person_elements_modal_container_usedtime_${addEnder(perfKoef)} `;
                    return <div key={`pemcseq-${sequence.Id}-${element_key}`} className="person_elements_modal_container_sequence">
                        <div className="person_elements_modal_container_perfKoef">
                            <div className={perfKoefClassName}>
                                <span>{perfKoef.toFixed(1)}</span>
                            </div>
                        </div>
                        <div className="person_elements_modal_container_sequence">
                            <SoloMiniSequenceRow color_status key={`round_sequences_plashka_sequence${sequence.Id}`} sequence={sequence} client={client} isMobile={isMobile} noName noIcon = {isMobile} />
                        </div>
                    </div>
                })}
            </div>
            }

        </Fragment>
    }

    ElementCardPoopover = ({ element_key }) => {


        const { isMobile } = this.props;

        const classNamePoopover = isMobile ? 'element-card-poopover-mobile' : 'element-card-poopover';

        return <div key={`ellelelel-${element_key}`} className={classNamePoopover}>

            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
            }} >
                <video
                    playsInline="true"
                    id="my-player"
                    class="video-js"
                    preload="auto"
                    autoPlay="true"
                    muted="true"
                    poster={`https://capoeirasport.com/graphics/video_technics/logo.png`}
                    loop="true"
                    width='100%'
                    controls=""
                    data-setup="{}">
                    <source
                        src={`https://capoeirasport.com/graphics/video_technics/${element_key}.mp4`}
                        type="video/mp4" />
                </video>
            </div>

        </div>
    }
}



export default withPersonState(
    `
    iAmPerson,
    Person,
    client,
    isMobile,
    SequencesStatistics,
    isSequencesReady,
    Elements,
    reloadElements,
    ServerQuests,
    iamuser
    `
)(PersonElements)