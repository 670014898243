import React, { PureComponent } from 'react';
import { withPersonState } from 'src/ver2/pages/person/context';
import { ElementCard } from 'src/ver2/containers/challenges/components/element-card';
import Loader from 'src/ver2/components/loader';
import { Mountin, MountinQuadro } from 'src/ver2/components/ui/graph/mountin';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import { Modal } from 'antd';
import PersonElements from './elements';
import SomeInfo from 'src/ver2/components/info';
import { Geks } from 'src/ver2/components/ui/geks';

import { orderBy } from 'lodash';

import './style.css';

const CALIBRATION_NOT = {
    title: 'КАЛИБРОВКА НЕ ПРОЙДЕНА',
    value: <>

        Статистика по средним очкам и средней связности оценивается относительно 10 Ваших лучших связок
        <br /><br />
        Например, Вы заявили пять связок и получили за них 50, 50, 50, 50 и 50 очков
        <br /><br />
        Ваши средние очки будут равны 250 / 10 = 25 очков
        <br /><br />
        Как только будет оценено 10 Ввших связок - статистика по средним показателям будет актуальна
    </>
}

const CALIBRATION_YES = {
    title: 'КАЛИБРОВКА ПРОЙДЕНА',
    value: <>

        Статистика по средним очкам и средней связности оценивается относительно 10 Ваших лучших связок
        <br /><br />
        Вы заявили 10 или более связок и Ваши средние показатели актуальны
    </>
}

class PersonElementsPlashka extends PureComponent {

    state = {
        listModalData: null,
        lastHeight: -1
    }

    listModalDataChange = (listModalData = false) => this.setState({ listModalData })

    setComponentSelfHeight = ({ height }) => {
        const { setComponentSelfHeight, plashkaId } = this.props;

        if (height === this.state.lastHeight) return;
        this.setState({ lastHeight: height })
        setComponentSelfHeight({ plashkaId, height });

    };

    componentDidMount = () => {
        this.setComponentSelfHeight({ height: 360 });
    }

    render() {

        const { SequencesStatistics, isSequencesReady, isMobile, client, iAmPerson, Elements, Person } = this.props;
        const { listModalData } = this.state;

        const { width, height } = this.props.upperStyle;

        if (!isSequencesReady) {
            return <div style={{ width, height }} className='person_elements_plashka'>
                <Loader text='Загрузка связок' />
            </div>
        }

        const {
            T1,
            T2,
            T3,

            Pool,

            Diversity,
            PointsStatistics,
            SequencityStatistics,
            PerformStatistics,

            allStrike,
            allFlow,
            allFreeze,
            allJump,

            SequencesCount,
            AllPoints,
            SequencityMax
        } = SequencesStatistics.FinalStatistics;


        const allFlowMax = 136;
        const allFreezeMax = 57;
        const allJumpMax = 135;
        const allStrikeMax = 54;


        const columns1 = [
            {
                count: T3 / 14,
                value: `T3 - ${(100 * T3 / 14).toFixed()}%`,
                color: "#0FFEE2",
                tooltip: `Подтверждено ${T3} элементов Тier 3 из 14`
            },
            {
                count: T1 / 23,
                value: `T1 - ${(100 * T1 / 23).toFixed()}%`,
                color: "#0FFEE2",
                tooltip: `Подтверждено ${T1} элементов Тier 1 из 23`
            },
            {
                count: T2 / 28,
                value: `T2 - ${(100 * T2 / 28).toFixed()}%`,
                color: "#0FFEE2",
                tooltip: `Подтверждено ${T2} элементов Тier 2 из 28`
            },
        ]

        const columns2 = [
            {
                count: Pool / 65,
                value: `${Pool}`,
                icon: 'pool',
                upperText: 'ПУЛ',
                tooltip: `Зайдествовано ${Pool} элементов из 65`
            },
            {
                count: PointsStatistics / 80,
                value: `${PointsStatistics.toFixed()}`,
                icon: 'points',
                upperText: 'СР.ОЧКИ',
                tooltip: `Средние очки за 10 лучших связкок: ${PointsStatistics.toFixed(1)}`
            },
            {
                count: SequencityStatistics / 28,
                value: `${SequencityStatistics.toFixed()}`,
                icon: 'seq',
                upperText: 'СР.СВЯЗН',
                tooltip: `Средняя связность за 10 лучших связкок: ${SequencityStatistics.toFixed(1)}`
            },
            {
                count: PerformStatistics ? ((PerformStatistics - 0.9) / 0.16) : 0,
                value: PerformStatistics ? `${(100 * (PerformStatistics - 0.9) / 0.16).toFixed()}%` : `0%`,
                icon: 'tech',
                upperText: 'ТЕХН',
                tooltip: `Средняя техничность из всех связкок: ${PerformStatistics.toFixed(2)}`
            },
        ]

        const columns3 = [
            {
                count: allJump / allJumpMax,
                value: `${(100 * allJump / allJumpMax).toFixed()}%`,
                icon: 'jump',
                upperText: 'ПРЫЖКИ',
                className: 'mountain_jump',
                tooltip: `Получено: ${allJump.toFixed(1)} прыжковых очков из ${allJumpMax} возможных`
            },
            {
                count: allStrike / allStrikeMax,
                value: `${(100 * allStrike / allStrikeMax).toFixed()}%`,
                icon: 'strike',
                upperText: 'АТАКА',
                className: 'mountain_strike',
                tooltip: `Получено: ${allStrike.toFixed(1)} очков атаки из ${allStrikeMax} возможных`
            },
            {
                count: allFreeze / allFreezeMax,
                value: `${(100 * allFreeze / allFreezeMax).toFixed()}%`,
                icon: 'freeze',
                upperText: 'СТОЙКИ',
                className: 'mountain_freeze',
                tooltip: `Получено: ${allFreeze.toFixed(1)} очков стоек из ${allFreezeMax} возможных`
            },
            {
                count: allFlow / allFlowMax,
                value: `${(100 * allFlow / allFlowMax).toFixed()}%`,
                icon: 'flow',
                upperText: 'ПРОГИБЫ',
                className: 'mountain_flow',
                tooltip: `Получено: ${allFlow.toFixed(1)} очков прогибов из ${allFlowMax} возможных`
            },
        ]

        const numberTextHight = isMobile ? 20 : 50;
        const mountinHeight = isMobile ? 220 : 190;
        const gekWidth = isMobile ? 45 : 65;

        const ButtonText = (iAmPerson && (!Elements || Elements.length === 0)) ? 'ДОБАВИТЬ ЭЛЕМЕНТЫ' : 'СТАТИСТИКА';

        let libraStyleType = '';
        if (SequencesCount >= 10) { libraStyleType = 'bronze' }
        if (SequencesCount >= 25) { libraStyleType = 'silver' }
        if (SequencesCount >= 50) { libraStyleType = 'gold' }

        const sizes = {
            width: isMobile ? 25 : 40,
            no_select: true,
            dont_show_video: true,
            nomargin: true
        }

        const { VSettings = '{}' } = Person || {};
        const VSettingsObj = JSON.parse(VSettings);
        const coverId = (VSettingsObj && VSettingsObj.paint && VSettingsObj.paint.cover) ? VSettingsObj.paint.cover : null;

        return <div style={{ width, height }} className='person_elements_plashka'>
            <div className={SequencesCount < 10 ? 'person_elements_plashka_upper person_elements_plashka_upper_notallow' : 'person_elements_plashka_upper'}>
                {/* <NumberText count={ SequencesCount < 10 
                    // ? `${SequencesCount} / 10`
                    ? <SomeInfo content = {`${SequencesCount}/10`} {...CALIBRATION_NOT} />
                    
                    : <SomeInfo content = {`ПРОЙДЕНА`} {...CALIBRATION_YES} />} text={'КАЛИБРОВКА'} height={numberTextHight} /> */}
                {coverId ? <div className="person_elements_modal_container_row_cover">
                    <ElementCard
                        cover_id={coverId}
                        is_login={false}
                        {...{ mobile: isMobile, client, sizes, element_key: 'armada' }}
                    />
                    <ElementCard
                        cover_id={coverId}
                        is_login={false}
                        {...{ mobile: isMobile, client, sizes, element_key: 'compassa' }}
                    />
                    <ElementCard
                        cover_id={coverId}
                        is_login={false}
                        {...{ mobile: isMobile, client, sizes, element_key: 'negativa' }}
                    />
                </div> : null}

                {SequencesCount < 10 ?
                    <SomeInfo content={<Geks styleType={'filled'} width={gekWidth} icon={'libra'} fill={{
                        startColor: '#18384A',
                        endColor: '#2D98C2',
                        value: SequencesCount / 10
                    }} />} {...CALIBRATION_NOT} /> :
                    <SomeInfo content={<Geks styleType={libraStyleType} width={gekWidth} icon={'libra'} />} {...CALIBRATION_YES} />}
                <NumberText count={SequencesCount} text={'ВСЕГО СВЯЗОК'} height={numberTextHight} />
                <NumberText count={AllPoints.toFixed()} text={'ВСЕГО ОЧКОВ'} height={numberTextHight} />
                <NumberText count={SequencityMax.toFixed(1)} text={'МАКС СВЯЗНОСТЬ'} height={numberTextHight} />
                <div onClick={() => { this.listModalDataChange(true) }} className='person_elements_plashka_button_stat'>
                    <span>{ButtonText}</span>
                </div>
            </div>
            <div className='person_elements_plashka_middle'>
                <Mountin {...{ width: mountinHeight, height: mountinHeight, columns: columns1 }} />
                <MountinQuadro {...{ width: mountinHeight, height: mountinHeight, columns: columns2 }} />
                <MountinQuadro {...{ width: mountinHeight, height: mountinHeight, columns: columns3 }} />
            </div>
            <Modal
                className={isMobile ? 'personElementsModalClassMobile' : 'personElementsModalClass'}
                visible={listModalData}
                onCancel={() => { this.listModalDataChange(false) }}
                footer={null}
                title={'СТАТИСТИКА ЭЛЕМЕНТОВ'}
                width={800}
            >
                {listModalData ? <PersonElements /> : null}
            </Modal>
            {/* <div className='person_elements_plashka_downed'>
                <span>СТАТИСТИКА ЭЛЕМЕНТОВ</span>
            </div> */}
        </div>
    }
}

export default withPersonState(
    `
    iAmPerson,
    Person,
    Elements,
    client,
    isMobile,
    SequencesStatistics,
    isSequencesReady,
    `
)(PersonElementsPlashka)