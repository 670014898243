import React, { Component, PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import Loader from 'src/ver2/components/loader';
import ItemInventarComponent from 'src/ver2/components/item/inventar';
import ItemOnWallComponent from 'src/ver2/components/item/onwall';
import { Icon } from 'antd';


import { useDrag, useDrop, DragPreviewImage } from 'react-dnd';

import './style.css';

const KOEF = 1.46;
// const this.props.CELLS_X = 8;
// const this.props.CELLS_Y = 11;

// const this.props.CELLS_X = 9;
// const this.props.CELLS_Y = 13;

// const this.props.CELLS_X = 10;
// const this.props.CELLS_Y = 15;

// const this.props.CELLS_X = 11;
// const this.props.CELLS_Y = 16;

const MODE_NOT_OWNER = 1;
const MODE_OWNER = 2;
const MODE_DRAGGING = 3;
const MODE_DRAGGING_INVENTAR = 4;

const CHOOSE_SCREEN_NOT_SHOW = 1;
const CHOOSE_SCREEN_SHOW = 2;
const CHOOSE_SCREEN_DRAG_FROM = 3;

class PersonWallComponent extends Component {

    // constructor(props) {
    //     super(props);
    // }

    /*
        8 на 12 сетка

        Приходит Items, 
        
        x, y,
        uid,
        name,
        isUsed: true

        туда надо дописать из абстрактного объекта
        src,
        srcUsed,
        w, h, 
        shiftX, shiftY,
        

    */

    constructor(props) {
        super(props);

        const { upperStyle, iamowner } = props;
        const { width, height } = upperStyle;

        const koefH = height / width;

        let trueWidth = width;
        let trueHeight = height;

        if (koefH >= KOEF) {
            trueHeight = trueWidth * KOEF;
        } else {
            trueWidth = trueHeight / KOEF;
        }

        let cellWidth = trueWidth * 0.01 * (10.54 * 8 / props.CELLS_X);
        let marginWidth = trueWidth * 0.01 * (0.7 * 8 / props.CELLS_X);
        // let cellWidth = trueWidth * 0.01 * 7.54;
        // let marginWidth = trueWidth * 0.01 * 1.7;      

        let paddingLeft = (trueWidth - cellWidth * props.CELLS_X - marginWidth * (props.CELLS_X - 1)) / 2;
        let paddingTop = (trueHeight - cellWidth * props.CELLS_Y - marginWidth * (props.CELLS_Y - 1)) / 2;


        this.state = {
            trueWidth,
            trueHeight,
            cellWidth,
            marginWidth,
            paddingTop,
            paddingLeft,

            Items: [],

            targetCell: null, // тут будет x, y той клетки, над которой мы нависли

            mode: MODE_NOT_OWNER,
            chooseScreenMode: CHOOSE_SCREEN_NOT_SHOW
        }
    }

    changeCELLSXY = () => {
        const { CELLS_X, CELLS_Y } = this.props;

        const { trueWidth, trueHeight } = this.state;

        let cellWidth = trueWidth * 0.01 * (10.54 * 8 / CELLS_X);
        let marginWidth = trueWidth * 0.01 * (0.7 * 8 / CELLS_X);
   
        let paddingLeft = (trueWidth - cellWidth * CELLS_X - marginWidth * (CELLS_X - 1)) / 2;
        let paddingTop = (trueHeight - cellWidth * CELLS_Y - marginWidth * (CELLS_Y - 1)) / 2;

        this.setState({
            cellWidth,
            marginWidth,
            paddingTop,
            paddingLeft, 
        })
    }

    parseItems = ({ Items, client }) => {

        let ReturnedItems = [];
        const Items_ = Items || [];

        for (let i = 0; i < Items_.length; i++) {
            ReturnedItems.push({ ...Items_[i] });

            const { name } = Items_[i];
            const AbsctractItem = client.getAbstractItem(name);

            ReturnedItems[ReturnedItems.length - 1] = {
                ...ReturnedItems[ReturnedItems.length - 1],
                ...AbsctractItem
            }
        }

        return ReturnedItems;
    }

    componentDidMount = () => {
        const AddedItems = this.parseItems(this.props);
        const { iamowner } = this.props;
        this.setState({ Items: AddedItems, mode: iamowner ? MODE_OWNER : MODE_NOT_OWNER })
        // this.setState({ mode: MODE_DRAGGING_INVENTAR, dragged: { x: 1, y: 1, uid: 3 } })
    }

    componentDidUpdate = ({ Items, iamowner, CELLS_X }) => {
        if (iamowner !== this.props.iamowner) {
            this.setState({ mode: this.props.iamowner ? MODE_OWNER : MODE_NOT_OWNER })
        }
        if (Items !== this.props.Items) {
            const AddedItems = this.parseItems(this.props);
            this.setState({ Items: AddedItems })
        }
        if (CELLS_X !== this.props.CELLS_X) {
            this.changeCELLSXY();
        }
    }

    // когда мы начинаем тащить предмет - включается mode: MODE_DRAGGING и записывается из какой x y и какой uid мы тащим

    setGraggedOn = ({ x, y, uid }) => {
        if (this.state.mode !== MODE_NOT_OWNER) this.setState({ mode: MODE_DRAGGING, dragged: { x, y, uid } })
    }

    setGraggedOff = () => {
        if (this.state.mode !== MODE_NOT_OWNER) this.setState({ mode: MODE_OWNER, dragged: null })
    }

    setTargetCell = ({ x, y }) => {
        if (this.state.mode !== MODE_NOT_OWNER) {
            if (!this.state.targetCell || this.state.targetCell.x !== x || this.state.targetCell.y !== y)
                this.setState({ targetCell: { x, y } })
        }
    }

    // мы проверяем, можем ли данный предмет сюда бросить
    mayIDropItHere = ({ x, y }) => {
        // для начала мы находим сам предмет

        const { dragged, Items } = this.state;
        if (!dragged) return false;

        const { uid } = dragged;

        const DraggedItem = Items.find(x => x.uid === uid);
        if (!DraggedItem) return false;

        // а теперь для разных подклассов смотрим разные условия для перетаскивания
        // во первых просто должны подходить габариты
        const { w, h, shiftX, shiftY, subtype } = DraggedItem;

        if ((x + w - shiftX) > this.props.CELLS_X || (y + h - shiftY) > this.props.CELLS_Y) {
            return false;
        }

        // если это растение или медаль, его можно поставить только на полку
        if ((subtype === 'plant' || subtype === 'medal_shalve' || subtype === 'toy')) {

            // для начала проверяем, нет ли на этом месте какого-либо предмета
            const PlantItems = Items.filter(x => (x.subtype === 'plant' || x.subtype === 'medal_shalve' || x.subtype === 'toy')) || [];
            for (let i = 0; i < PlantItems.length; i++) {
                const PlantItem = PlantItems[i];

                if (PlantItem.Id === uid) continue;
                if (!PlantItem.isUsed) continue;

                // пока думаем, что все предметы у нас по высоте 1
                if (y !== PlantItem.y) continue;

                if (
                    (x + w) > PlantItem.x &&
                    x < (PlantItem.x + PlantItem.w)
                ) {
                    return false;
                }
            }

            const ShalveItems = Items.filter(x => x.subtype === 'shalve');
            if (!ShalveItems) return false;
            for (let i = 0; i < ShalveItems.length; i++) {
                const ShalveItem = ShalveItems[i];

                if (!ShalveItem.isUsed) continue;

                if (
                    x >= ShalveItem.x &&
                    (x + w) <= ShalveItem.x + ShalveItem.w &&
                    y >= ShalveItem.y &&
                    y < ShalveItem.y + ShalveItem.h
                ) {
                    return true;
                }
            }
            return false;
        }

        // если полка, то мы смотрим другие полки (в т.ч. их длину) и не даём размещать там, где полка есть
        if (subtype === 'shalve') {
            const ShalveItems = Items.filter(x => x.subtype === 'shalve') || [];
            for (let i = 0; i < ShalveItems.length; i++) {
                const ShalveItem = ShalveItems[i];

                if (ShalveItem.Id === uid) continue;
                if (!ShalveItem.isUsed) continue;

                // пока думаем, что все полки у нас по высоте 1
                if (y !== ShalveItem.y) continue;

                if (
                    (x + w) > ShalveItem.x &&
                    x < (ShalveItem.x + ShalveItem.w)
                ) {
                    return false;
                }
            }
            return true;
        }

        return true;

    }

    render() {

        const { status, isMobile, wallStyle } = this.props;
        const { trueWidth, trueHeight } = this.state;

        if (status !== 'loaded') return <div style={{ width: trueWidth, height: trueHeight }} className='person_wall_container'>
            <Loader text='Формирую стену' />
        </div>

        // наше соотношение сторон должно быть 100 x 146
        // смотрим, какой размер нам подходит


        return <div style={{ width: trueWidth, height: trueHeight, ...wallStyle }} className={'person_wall_container'}>
            <this.ChooseModeScreen />
            <this.AllDraw />
            <this.DownButton />
            <this.UpperButton />
        </div>
    }

    /* 
        В целом мы тут всё рисуем - т.е. мы по очереди добавляем ячейки и если в данной ячейке есть объект, мы его в том числе отрисовываем
        С нужным смещением

        Ячейки, как и объект, должны быть драг-дропами, чтобы в них можно было что то размещать
    */

    ChooseModeScreen = () => {
        const { trueWidth, trueHeight, chooseScreenMode, Items } = this.state;

        if (chooseScreenMode === CHOOSE_SCREEN_NOT_SHOW) return null;

        const padding = trueWidth / 70;
        const paddingInside = trueWidth / 70;

        const insideForItems = trueWidth - padding * 4;

        const gap = insideForItems * 0.02;
        const allGaps = gap * 3;

        const itemWidth = (insideForItems - allGaps) / 4;

        const style = {
            width: trueWidth - 2,
            height: trueHeight - padding * 2,
            top: padding,
            left: padding,
            rowGap: gap,
            columnGap: gap,
            padding,
            borderRadius: padding
        }

        return <div style={style} className={'person_wall_choose_mode'}>

            <div className='person_wall_choose_mode_cross' onClick={() => { this.setState({ chooseScreenMode: CHOOSE_SCREEN_NOT_SHOW }) }}>
                <Icon type='cross' />
            </div>

            {Items.map(Item => {
                const { isUsed, name, uid } = Item;
                if (isUsed) return null;
                return <this.ItemInventar key={'123123' + uid} width={itemWidth} name={name} uid={uid} Item={Item} />
            })}
        </div>


    }

    ItemInventar = ({ width, name, uid, Item }) => {

        return <div onClick={() => {
            this.setState({
                chooseScreenMode: CHOOSE_SCREEN_NOT_SHOW,
                mode: MODE_DRAGGING_INVENTAR,
                dragged: { x: -1, y: -1, uid }
            })
        }} >
            <ItemInventarComponent width={width} name={name} uid={uid} Item={Item} />
        </div>
    }

    AllDraw = () => {

        const { moveItem } = this.props;

        const {
            paddingTop,
            paddingLeft,
            cellWidth,
            marginWidth,

            Items,

            mode,
            dragged,
            targetCell

        } = this.state;

        let CellsAnswer = [];
        let ItemsAnswer = [];

        for (let y = 0; y < this.props.CELLS_Y; y++) {
            for (let x = 0; x < this.props.CELLS_X; x++) {

                const left = paddingLeft + x * cellWidth + x * marginWidth;
                const top = paddingTop + y * cellWidth + y * marginWidth;

                if ((mode === MODE_DRAGGING || mode === MODE_DRAGGING_INVENTAR) && dragged && !(dragged.x === x && dragged.y === y) && this.mayIDropItHere({ x, y }))
                    CellsAnswer.push(
                        <WallCell
                            key={`cell_${x}_${y}`}
                            setTargetCell={this.setTargetCell}
                            setGraggedOff={this.setGraggedOff}
                            {...{ x, y, top, left, cellWidth, moveItem, mode, dragged }}
                        />
                    )

                // теперь ищем Item с нужными x, y (потом переделать этот механизм)
                if (Items) {
                    const FindedItems = Items.filter(h => (h.x === x && h.y === y));

                    if (FindedItems && FindedItems.length > 0) {
                        for (let u = 0; u < FindedItems.length; u++) {
                            const FindedItem = FindedItems[u];
                            const { name, isUsed } = FindedItem;

                            if (isUsed)
                                ItemsAnswer.push(
                                    <WallItem
                                        key={`item_${name}_${x}_${y}`}
                                        setGraggedOn={this.setGraggedOn}
                                        setGraggedOff={this.setGraggedOff}
                                        mayDrag={mode !== MODE_NOT_OWNER}
                                        mode={mode}
                                        showInfoModal={this.props.showInfoModal}
                                        {...{ Item: FindedItem, x, y, top, left, cellWidth, marginWidth }}
                                    />
                                )
                        }
                    }
                }

            }
        }

        // это нужно для того, чтобы мы могли видеть как предмет "встанет"
        if (dragged && targetCell) {

            const { uid } = dragged;
            const { x, y } = targetCell;

            const DraggedItem = Items.find(x => x.uid === uid);
            if (DraggedItem) {
                const { name } = DraggedItem;
                const left = paddingLeft + x * cellWidth + x * marginWidth;
                const top = paddingTop + y * cellWidth + y * marginWidth;
                ItemsAnswer.push(
                    <WallItem
                        key={`item_dragged_${name}_${x}_${y}`}
                        mayDrag={true}
                        preview={true}
                        mode={mode}
                        showInfoModal={this.props.showInfoModal}
                        {...{ Item: DraggedItem, x, y, top, left, cellWidth, marginWidth }}
                    />
                )
            }
        }

        return [...ItemsAnswer, ...CellsAnswer]
    }

    DownButton = () => {
        const { mode, cellWidth, trueWidth, trueHeight } = this.state;
        if (mode === MODE_NOT_OWNER) return null;

        const style = {
            width: trueWidth,
            height: cellWidth * 2,
            left: 0,
            top: trueHeight - cellWidth
        }

        if (mode === MODE_DRAGGING_INVENTAR) {
            const className = 'person_wall_downbutton person_wall_downbutton_godrop';

            textButton = 'ОТМЕНИТЬ';
            return <div style={style} className={className} onClick={() => { this.setGraggedOff() }}>
                <span>{textButton}</span>
            </div>
        }

        const [{ isOver, canDrop }, drop] = useDrop({
            accept: 'ITEM',
            drop: (dragged_item) => {
                const { removeItemFromWall } = this.props;
                const { uid } = dragged_item;
                removeItemFromWall({ uid });
            },
            canDrop: (dragged_item) => {
                return true;
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop()
            })
        })

        const className = isOver ? 'person_wall_downbutton person_wall_downbutton_godrop' : 'person_wall_downbutton';

        let textButton = 'ДОБАВИТЬ';
        if (mode === MODE_DRAGGING) {
            textButton = 'УБРАТЬ';
        }

        return <div ref={drop} style={style} className={className} onClick={() => { this.setState({ chooseScreenMode: CHOOSE_SCREEN_SHOW }) }}>
            <span>{textButton}</span>
        </div>

    }

    UpperButton = () => {
        const { changes, saveChanges } = this.props;
        if (!changes) return null;

        const { cellWidth, trueWidth } = this.state;

        const style = {
            width: trueWidth,
            height: cellWidth,
            left: 0,
            top: 0
        }


        return <div style={style} className={'person_wall_upbutton'} onClick={saveChanges}>
            <span>СОХРАНИТЬ</span>
        </div>

    }

}



class WallCell extends PureComponent {

    render() {

        const { cellWidth, top, left, mode } = this.props;
        const style = {
            width: cellWidth,
            height: cellWidth,
            left,
            top
        }

        if (mode === MODE_DRAGGING_INVENTAR) {
            onmouseover = (e) => {
                if (e.target.className === 'person_wall_cell_inventar') {
                    const { setTargetCell, x, y } = this.props;
                    setTargetCell({ x, y });
                    window.event.stopPropagation();
                }
            }

            onclick = (e) => {
                if (e.target.className === 'person_wall_cell_inventar') {
                    const { dragged, x, y, moveItem, setGraggedOff } = this.props;
                    const { uid } = dragged;
                    moveItem({ uid, x, y });
                    setGraggedOff();
                    window.event.stopPropagation();
                }
            }

            return <div style={style} onClick={onclick} className='person_wall_cell_inventar' onMouseOver={onmouseover} >
            </div>
        }

        return <div style={style} className='person_wall_cell'>
            <this.CellToDrop />
        </div>
    }

    CellToDrop = () => {
        const [{ isOver, canDrop }, drop] = useDrop({
            accept: 'ITEM',
            drop: (dragged_item) => {
                const { moveItem, x, y } = this.props;
                const { uid } = dragged_item;
                moveItem({ uid, x, y });
            },
            canDrop: (dragged_item) => {
                return true;
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop()
            })
        })

        if (isOver) {
            const { setTargetCell, x, y } = this.props;
            setTargetCell({ x, y });
        }

        const className = isOver ? 'person_wall_cell_candrop' : 'person_wall_cell_todrop';

        return <div ref={drop} className={className} />

    }
}

/*

    preview означает, что это мы показываем как будет выглядеть предмет

*/

class WallItem extends PureComponent {

    state = {
        loaded: false,
        imgWidth: 0,
        imgHeight: 0
    }

    componentWillMount() {

        const { Item } = this.props;
        const { srcUsed } = Item;

        let Img = new Image();

        let imgWidth = 0;
        let imgHeight = 0;

        Img.onload = () => {
            imgWidth = Img.width;
            imgHeight = Img.height;
            this.setState({
                loaded: true,
                imgWidth,
                imgHeight
            })
        }

        Img.src = srcUsed;
    }

    render() {

        const { loaded, imgWidth, imgHeight } = this.state;

        if (!loaded) return null;

        const { Item, cellWidth, marginWidth, top, left, preview, mode, showInfoModal } = this.props;

        const koef = cellWidth / 100;

        const { srcUsed, shiftX, shiftY, subtype } = Item;

        let style = {
            width: imgWidth * koef,
            height: imgHeight * koef,
            left,
            top: top - (shiftY) * (cellWidth + marginWidth),
            opacity: preview ? 0.6 : 1.0,
        }
        if (subtype === 'shalve' && !preview) style.zIndex = 4;
        if ((subtype === 'plant' || subtype === 'medal_shalve' || subtype === 'toy') && !preview) style.zIndex = 5;
        // if (preview) style.zIndex = 1100;

        if (mode === MODE_NOT_OWNER) {
            return <div title = 'Осмотреть' style={style} className='person_wall_item' onClick={() => {showInfoModal({ type: 'item', Item })}}>
                <ItemOnWallComponent srcUsed={srcUsed} Item={Item} width={style.width} height={style.height} />
            </div>
        }

        if (mode === MODE_DRAGGING_INVENTAR) {
            return <div style={style} className='person_wall_item'>
                <img src={srcUsed} draggable="false" />
            </div>
        }


        return <div style={style} className='person_wall_item'>
            <ItemOnWallComponent srcUsed={srcUsed} Item={Item} width={style.width} height={style.height} />
            <this.Rend />
            {/* <img src={srcUsed} draggable="false" /> */}
        </div>
    }

    Rend = () => {

        const { loaded } = this.state;
        const { mayDrag } = this.props;

        if (!loaded || !mayDrag) return null;

        const { Item, cellWidth, marginWidth, setGraggedOff, setGraggedOn } = this.props;

        const { uid, x, y, shiftX, shiftY, srcUsed, subtype } = Item;

        const style = {
            width: cellWidth,
            height: cellWidth,
            top: shiftY * (cellWidth + marginWidth),
            left: 0
        }

        const [{ isDragging }, drag, preview] = useDrag({
            item: { type: 'ITEM', uid },
            collect: monitor => ({
                isDragging: !!monitor.isDragging(),
            }),
            end: (item, monitor) => {
                setGraggedOff();
            },
            begin: (monitor) => {
                setGraggedOn({ x, y, uid });
            }
        })

        return <React.Fragment>
            <DragPreviewImage connect={preview} src={`/../images/ver2/scores/subtypes/${subtype}.png`} />
            <div ref={drag} style={style} className='person_wall_cell_draggable' />
        </React.Fragment>
    }
}



export default withCapoeiraSportState('client, isMobile, showInfoModal')(PersonWallComponent);