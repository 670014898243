async function getAchivments({ AchivmentId, UserId }) {
    let uri = 'https://' + this.ServerUrl + '/getAchivments';

    if (AchivmentId) uri += '?AchivmentId=' + AchivmentId;
    if (UserId) uri += '?UserId=' + UserId;

    const answer = await this.standartDataResult(uri, { timeout: 1500 });
    if (!answer.error) {
        return answer.result;
    }
    return [];
}

async function getRareAchivments({ Type, Count }) {
    let uri = 'https://' + this.ServerUrl + '/getRareAchivments';

    if (Type) uri += '?Type=' + Type;
    if (Count) uri += '&Count=' + Count;

    const answer = await this.standartDataResult(uri, { timeout: 1500 });
    if (!answer.error) {
        return answer.result;
    }
    return [];
}

async function getAchivmentsPointsTop({ UserId }) {
    let uri = 'https://' + this.ServerUrl + '/getAchivmentsPointsTop';
    if (UserId) uri += '?UserId=' + UserId;

    const answer = await this.standartDataResult(uri, { timeout: 1500 });
    if (!answer.error) {
        return answer.result;
    }
    return [];
}

async function getAchivmentsCount() {
    let uri = 'https://' + this.ServerUrl + '/getAchivmentsCount';

    const answer = await this.standartDataResult(uri, { timeout: 1500 });
    if (!answer.error) {
        return answer.result;
    }
    return [];
}

async function getAchivmentUsers({ Name, Lvl }) {
    let uri = 'https://' + this.ServerUrl + '/getAchivmentUsers';
    if (Name) uri += '?Name=' + Name;
    if (Lvl) uri += '&Lvl=' + Lvl;

    const answer = await this.standartDataResult(uri, { timeout: 1500 });
    if (!answer.error) {
        return answer.result;
    }
    return [];
}

async function processAchivmentByType({ UserId, Type }) {
    let uri = 'https://' + this.ServerUrl + '/processAchivmentByType';
    if (UserId) uri += '?UserId=' + UserId;
    if (Type) { uri += '&Type=' + Type } else { uri += '&Type=all' }

    const answer = await this.standartDataResult(uri, { timeout: 1500 });
    if (!answer.error) {
        return answer.result;
    }
    return [];
}

async function getAchivmentsFromServer() {
    let uri = 'https://' + this.ServerUrl + '/serverAllAchivments';

    const answer = await this.standartDataResult(uri);
    // if (!answer.error) {
    //   return answer.result;
    // }
    // return answer.result;

    console.log('achivments', answer);
    this.ACHIVMENTS = answer.result.ACHIVMENTS;
    // this.state.birthdays = (answer.result && answer.result.length) ? [...answer.result] : [];
    this.reloadPage('achivments');
    return null;
}

export default {
    getAchivments,
    getRareAchivments,
    getAchivmentsFromServer,
    getAchivmentsPointsTop,
    getAchivmentsCount,
    getAchivmentUsers,
    processAchivmentByType
}