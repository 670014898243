import React from 'react';

export default () => <svg width="100px" height="100px"
    viewBox="0 0 100 100" >

    <polygon points="65.77,69.23 22.02,25.48 4.6,42.99 48.26,86.7 " />
    <polygon points="63.08,62.88 48.33,16.68 26.22,23.75 24.71,24.28 " />
    <path d="M66.31 63.19l9.54 -40.58 -24.09 -5.65 -0.09 0.37c4.7,15.34 9.78,30.56 14.64,45.86z" />
    <path d="M68.35 67.91c9.13,-8.46 18.09,-17.1 27.05,-25.75 -5.57,-5.88 -11.17,-12.06 -16.96,-17.7l-10.09 43.45z" />

</svg>