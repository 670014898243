
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { Tooltip } from 'antd';

import './style.css';

/* 
    Мы отправляем сюда 
    scoreType, 
    Count, 
    onClick
*/

class ScoreComponent extends PureComponent {

    render() {

        const { type, amount, size, showInfoModal, onClick, client, noAmount, withLvl } = this.props;
        let style = {}

        // console.log ('ScoreComponent', this.props);

        const onClick_ = onClick ? onClick : () => {
            showInfoModal({ type: 'scores', value: { type, score: { all: amount || 1 } } });
        }

        let lvl = 1;
        const scores = client.scoresTextByType(type);

        if (withLvl) {
            const scLbT = client.scoresLvlByType({ type, score: { all: amount || 1 } });
            lvl = scLbT.lvl;
        }

        return <div className='scores_component' style={{ width: size, height: size }}>
            <Tooltip title={scores.title}>
                <div style={{ height: size * 0.61 }} className='scores_component_upper'>
                    <img onClick={onClick_} src={`/../images/ver2/scores/${type}_${lvl}.png`} {...{ style }} />
                </div>
            </Tooltip>
            {noAmount ? null : <div className='scores_component_amount'>{amount}</div>}
        </div>
    }

}


export default withCapoeiraSportState('client, showInfoModal')(ScoreComponent);