export default {
    // ОБЛОЖКИ
    cover_brazil_1: {
        grade: 1, type: 'paint', subtype: 'cover', src: `/../images/ver2/scores/paint/cover_brazil_1.png`,
        title: 'Обложка Brazil',
        discription: 'Стандартные зелёные обложки с флагом Бразилии'
    },
    cover_brazil_2: {
        grade: 2, type: 'paint', subtype: 'cover', src: `/../images/ver2/scores/paint/cover_brazil_2.png`,
        title: 'Обложка Brazil',
        discription: 'Стандартные синие обложки с флагом Бразилии'
    },
    cover_brazil_3: {
        grade: 3, type: 'paint', subtype: 'cover', src: `/../images/ver2/scores/paint/cover_brazil_3.png`,
        title: 'Обложка Brazil',
        discription: 'Продвинутые красные обложки с флагом Бразилии'
    },
    cover_trefa_2: {
        grade: 2, type: 'paint', subtype: 'cover', src: `/../images/ver2/scores/paint/cover_trefa_2.png`,
        title: 'Обложка Trefas',
        discription: 'Яркие обложки Trefas groupo!'
    },
    cover_flowers_3: {
        grade: 3, type: 'paint', subtype: 'cover', src: `/../images/ver2/scores/paint/cover_flowers_3.png`,
        title: 'Весенние обложки',
        discription: 'Цветастые обложки, наполненные духом и красками высны!'
    }
}